
export const $ = (selector) => {
    return document.querySelector(selector);
}

export const $$ = (selector) => {
    return document.querySelectorAll(selector);
}

export function getParentNodeClass(node, classname) {
    let current = node;
    let cls = current.classList;
    if(cls && cls.contains(classname)) {
        return current;
    }
    while(current.parentNode !== null && current.parentNode !== document.documentElement) {
        current = current.parentNode; cls = current.classList;
        if(cls && cls.contains(classname)) return current;
    }
    return 0;
}

export function getParentNode(node) {
    let current = node;
    let cls = current.classList;
    if(cls && cls.contains('react-flow__node')) {
        return current;
    }
    while(current.parentNode !== null && current.parentNode !== document.documentElement) {
        current = current.parentNode; cls = current.classList;
        if(cls && cls.contains('react-flow__node')) return current;
    }
    return 0;
}

export const getConnector = (id, handleid) => {
    return $('[data-nodeid="'+id+'"][data-handleid="'+handleid+'"]')
}

export const getNode = (id) => {
    return $('[data-id="'+id+'"]')
}

export const getNodeHeight = (id) => {
    const node = $('[data-id="'+id+'"]')
    if(node) return node.offsetHeight; return 0;
}

export const getNodePos = (id) => {
    const node = $('[data-id="'+id+'"]')
    const pos = node.style.transform.match(/[0-9.]+/g)
    return { x: pos[0], y: pos[1] }
}

export const hasSelected = () => {
    return $$('.selected')
}

export const toggleClass = (id, cls) => {
    $('[data-id="'+id+'"]').classList.toggle(cls)
}

export const addCanonical = (url) => {
    // replace if exists
    const old = document.querySelector('link[rel="canonical"]')
    if(old) old.remove()

    // add new
    const link = document.createElement('link')
    link.rel = 'canonical'
    link.href = url
    document.head.appendChild(link)
}