import React, {memo, useContext, useState} from 'react'
import classes from './ButtonsAddControl.module.scss'
import buttonClasses from '../ButtonsGroup/ButtonsGroup.module.scss';
import {isMobile} from "../../../../../library/isMobile";
import {
    PlusOutlined,
    CopyOutlined,
    DeleteOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    HistoryOutlined,
} from "@ant-design/icons";
import {Tooltip} from 'antd';
import Select from "../../Blocks/svg/Select";
import {useTranslation} from "react-i18next";
import {nodeTypesProps} from "../../../library/flowFunctions";
import FlowContext from "../../../FlowContext";

export default memo(({onNodeAdd, onCopy, onDelete, hasSelected, selectionMode, setSelectionMode}) => {
    const {t} = useTranslation();
    const btnClasses = buttonClasses; // for another highlight color
    const [isOpen, setIsOpen] = useState(true);
    const {graphUpdateIndex, localUpdatesCount, controlPanel, setControlPanel, onGraphUndo, onGraphRedo} = useContext(FlowContext);

    const trigger = isMobile ? 'none' : 'hover'; // for tooltips
    const onOpen = (e) => setIsOpen(!isOpen);
    const onSelectionButtonClick = () => {
        setSelectionMode(!selectionMode);
    }

    const nodesAddButtons = Object.entries(nodeTypesProps).map((nodeProps) => {
        const [type, node] = nodeProps;
        return (
            <Tooltip key={'add-' + type} title={t('graph.flow.controls.add.' + type)} placement="right"
                     trigger={trigger}>
                <button
                    draggable="true"
                    onDragStart={(e) => {
                        dragStart(e, type)
                    }}
                    className={`${btnClasses.button}`} // ${btnClasses['bg-' + type]}
                    onClick={(e) => {
                        onNodeAdd(e, type)
                    }}
                ><span>{node.icon}</span></button>
                {/*className={'icon-' + type}*/}
            </Tooltip>
        );
    });

    const dragStart = (event, type) => {
        // console.log('dragStart', type, event);
        event.dataTransfer.setData('Type', type);
    }

    const toggleControlPanel = () => {
        const controlPanelPage = (controlPanel === 'history') ? 'default' : 'history';
        setControlPanel(controlPanelPage);
    }

    const isHistoryActive = controlPanel === 'history';
    const isUndoActive = localUpdatesCount && graphUpdateIndex < localUpdatesCount-1;
    const isRedoActive = localUpdatesCount && graphUpdateIndex > 0;

    return (
        <>
            <div className={`${btnClasses.group_wrapper} ${classes.left_bottom}`}>
                {isOpen ? nodesAddButtons : null}
                <button
                    className={`${btnClasses.button} ${btnClasses.active} ${isOpen ? btnClasses.control : ''}`}
                    onClick={onOpen}
                ><PlusOutlined/></button>
            </div>

            <div className={classes.bottom_left}>
                <div className={`${btnClasses.group_wrapper} ${btnClasses.horizontal}`}>
                    <Tooltip title={t('graph.flow.controls.selection')} placement="topLeft" trigger={trigger}>
                        <button
                            className={`${btnClasses.button} ${selectionMode ? btnClasses.active : ''}`}
                            onClick={onSelectionButtonClick}
                        >
                            <Select/>
                        </button>
                    </Tooltip>
                    <Tooltip
                        title={hasSelected ? t('graph.flow.controls.duplicate') : t('graph.flow.controls.select_required')}
                        placement="topLeft"
                        trigger={trigger}
                    >
                        <button
                            className={`${btnClasses.button} ${hasSelected ? '' : btnClasses.disabled}`}
                            onClick={hasSelected ? onCopy : null}
                        ><CopyOutlined/></button>
                    </Tooltip>
                    <Tooltip
                        title={hasSelected ? t('graph.flow.controls.delete') : t('graph.flow.controls.select_required')}
                        placement="topLeft"
                        trigger={trigger}
                    >
                        <button
                            className={`${btnClasses.button} ${hasSelected ? '' : btnClasses.disabled}`}
                            onClick={hasSelected ? onDelete: null}
                        ><DeleteOutlined/></button>
                    </Tooltip>
                </div>

                <div className={`${btnClasses.group_wrapper} ${btnClasses.horizontal}`}>
                    {/*<Tooltip title={t('graph.flow.controls.history')} placement="top" trigger={trigger}>*/}
                    {/*    <button*/}
                    {/*        className={`${btnClasses.button} ${isHistoryActive ? btnClasses.active : ''}`}*/}
                    {/*        onClick={toggleControlPanel}*/}
                    {/*    >*/}
                    {/*        <HistoryOutlined />*/}
                    {/*    </button>*/}
                    {/*</Tooltip>*/}

                    <Tooltip
                        title={isUndoActive ? t('graph.flow.controls.undo.title') : t('graph.flow.controls.undo.last')}
                        trigger={trigger}
                    >
                        <button
                            className={`${btnClasses.button} ${isUndoActive ? '' : btnClasses.disabled}`}
                            onClick={isUndoActive ? onGraphUndo : null}
                        ><ArrowLeftOutlined /></button>
                    </Tooltip>
                    <Tooltip
                        title={isRedoActive ? t('graph.flow.controls.redo.title') : t('graph.flow.controls.redo.last')}
                        trigger={trigger}
                    >
                        <button
                            className={`${btnClasses.button} ${isRedoActive ? '' : btnClasses.disabled}`}
                            onClick={isRedoActive ? onGraphRedo: null}
                        ><ArrowRightOutlined /></button>
                    </Tooltip>
                </div>
            </div>
        </>
    )
})
