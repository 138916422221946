import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addProductToList,
    fillProductList,
    fillProductShortList,
    patchProductInList,
    removeProductFromList,
    setProductItem,
    setProductListCount,
    setProductShortListCount,
} from "../reducers/ProductReducer";

// init api methods
const base_path = backend_api.product;

function* getProductList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillProductList(response.data.result))
            yield put(setProductListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getProductShortList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillProductShortList(response.data.result))
            yield put(setProductShortListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getProductItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setProductItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createProduct(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addProductToList(response.data.result))
            yield put(setProductItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateProduct(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchProductInList(response.data.result))
            yield put(setProductItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyProduct(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setProductItem(response.data.result))
            yield put(addProductToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreProduct(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeProductFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteProduct(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeProductFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* productSaga() {
    yield takeEvery('getProductShortList', getProductShortList);
    yield takeEvery('getProductList', getProductList);
    yield takeEvery('getProductItem', getProductItem);
    yield takeEvery('createProduct', createProduct);
    yield takeEvery('copyProduct', copyProduct);
    yield takeEvery('updateProduct', updateProduct);
    yield takeEvery('restoreProduct', restoreProduct);
    yield takeEvery('deleteProduct', deleteProduct);
}

