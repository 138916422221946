import React, {useState} from 'react'
import {Collapse} from "react-collapse/lib/Collapse";
import classes from "./CollapseCard.module.scss";
import {Button} from "antd";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";

const CollapseCard = ({icon, title, content, className}) => {

    if (!className) className = 'list-default-table collapse-card margin-bottom-ps margin-top-ps site-layout-background border-radius-lp';

    const [isItemOpened, setItemState] = useState();
    const toggleFilterState = () => {
        setItemState(!isItemOpened);
    }; 

    const minHeight = isItemOpened ? "80px" : " "
    const styleObj = {
        minHeight: isItemOpened ? "80px" : " ",
        transition: "all 200ms ease"
    }

    return (
        <div className={className}>
            <div style={styleObj} className={classes.collapse}>
                <div className={isItemOpened ? classes.active : ''} onClick={() => toggleFilterState()}>
                    <div className="title-controls collapse-row-buttons float-right ">
                        <Button type="text"
                                className="btn-collapse">
                            {isItemOpened ? (<AiOutlineUp/>) : (<AiOutlineDown/>)}
                        </Button>
                    </div>
                    <div className={classes.collapse_title}>
                        <div className={classes.collapse_icon}>{icon}</div>
                        <h3>{title}</h3>
                    </div>
                </div>
                <Collapse isOpened={isItemOpened}>
                    {content}
                </Collapse>
            </div>
        </div>
    )
}

export default CollapseCard