import {commonLocaleRoot} from "../../../../schemas/frontend/effects";
import {BlockArticle} from "./core/text/article";
import {BlockPageCards, BlockPageList} from "./core/page/category";
import {BlockButtonUrl} from "./core/form/button";
import {BlockDesc, BlockHeading, BlockTextIcon} from "./core/text/text";
import {BlockVideoHtml, BlockVideoYouTube} from "./core/media/video";
import {BlockImage} from "./core/media/image";
import {BlockIcon} from "./core/media/icon";
import {BlockLoginForm} from "./core/form/login";
import {BlockCommonForm} from "./core/form/common";
import {BlockAchievementList, BlockWheel} from "./core/game/common";
import {BlockNavMenu, BlockTagList} from "./core/nav/nav";
import {BlockSpoiler} from "./core/content/spoiler";

export const blockLocaleRoot = 'block.core.common';

export const blocks = [
    // {
    //     type: 'core/code/example',
    //     icon: 'address-card',
    //     fields: [
    //         [
    //             {
    //                 description: '',
    //                 placeholder: '',
    //                 name: 'contact_type',
    //                 type: 'menu',
    //                 values: contact_types,
    //                 default: 'phone',
    //                 depends_on: {set_by_var: false},
    //                 width: 12,
    //             },
    //             {
    //                 description: '',
    //                 placeholder: true,
    //                 name: 'contact_type',
    //                 type: 'textarea',
    //                 depends_on: {set_by_var: true},
    //                 width: 12,
    //             },
    //             {
    //                 description: '',
    //                 placeholder: true,
    //                 name: 'value',
    //                 type: 'textarea',
    //                 width: 12,
    //             },
    //         ],
    //         [{
    //             name: 'public_name',
    //             type: 'text',
    //             depends_on: {contact_type: messanger_contact_types}
    //         }],
    //         [{
    //             name: 'set_by_var',
    //             type: 'checkbox',
    //         }],
    //         [{
    //             name: 'add_integration',
    //             type: 'checkbox',
    //             depends_on: {contact_type: messanger_contact_types}
    //         }],
    //         [{
    //             name: 'integration_id',
    //             type: 'menu',
    //             data: 'integration',
    //             data_filters: {'integration.platform': messengers},
    //             depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: false}
    //         }],
    //         [{
    //             name: 'integration_id',
    //             type: 'textarea',
    //             placeholder: '{var:graph.integration.id}',
    //             depends_on: {add_integration: true, contact_type: messanger_contact_types, set_by_var: true},
    //         }]
    //     ]
    // },
    {
        // TODO: deprecated
        type: 'core/text/article',
        icon: 'file-code',
        module: BlockArticle,
        fields: [
            [{
                name: 'title',
                type: 'textarea',
                description: '',
                placeholder: true,
            }],
            [{
                name: 'content',
                type: 'editor',
                label: '',
                description: '',
                placeholder: true,
                extended: true,
            }],
        ]
    },
    {
        type: 'core/text/icon',
        icon: 'file-code',
        module: BlockTextIcon,
        fields: [
            [{
                name: 'title',
                type: 'textarea',
                description: '',
                placeholder: true,
            }],
            [
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'icon.set': 'regular'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'icon.set': 'light'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'icon.set': 'solid'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'icon.set': 'brands'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                }
            ],
            // [{
            //     name: 'level',
            //     type: 'menu',
            //     placeholder: '',
            //     values: ['h1', 'h2', 'h3', 'h4'],
            //     localeRoot: 'block.core.text.heading',
            // }],
            [{
                name: 'content',
                type: 'editor',
                label: '',
                description: '',
                placeholder: true,
                extended: true,
                localeRoot: 'block.core.text.desc',
            }],

            [{
                name: 'classes.show',
                type: 'switcher',
            }],
            [{
                name: 'classes.heading',
                type: 'heading',
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.icon',
                type: 'tags',
                features: ['adding'],
                placeholder: "text-white bg-primary-500 rounded-full...",
                values: [
                    'block',
                    'w-6',
                    'w-10',
                    'w-14',
                    'mr-3',
                    'mb-5',
                    'text-5xl',
                    'text-7xl',
                    'text-9xl',
                    'rounded',
                    'rounded-full',
                    'flex',
                    'flex-row',
                    'items-center',
                    'justify-center',
                    'bg-gray-100',
                    'bg-gray-900',
                    'bg-primary-500',
                    'bg-secondary-500',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-gray-500',
                    "text-gray-700",
                    'text-gray-900'
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.title',
                type: 'tags',
                features: ['adding'],
                placeholder: "mt-6 mb-3 ...",
                values: [
                    'mt-6',
                    'mb-3',
                    'text-h1',
                    'text-h3',
                    'text-h4',
                    'font-semibold',
                    'underline',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-secondary-500',
                    'text-gray-500',
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.desc',
                type: 'tags',
                features: ['adding'],
                placeholder: "prose custom ...",
                values: [
                    'prose',
                    'lg:prose-lg',
                    'dark:prose-invert',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-secondary-500',
                    'text-gray-500',
                    'text-gray-900',
                ],
                depends_on: {'classes.show': true}
            }],
        ]
    },
    {
        type: 'core/text/heading',
        icon: 'file-code',
        container: false,
        module: BlockHeading,
        fields: [
            [{
                name: 'content',
                type: 'textarea',
                description: '',
                placeholder: true,
            }],
            [{
                name: 'level',
                type: 'menu',
                placeholder: '',
                values: ['h1', 'h2', 'h3', 'h4'],
            }],
        ]
    },
    {
        type: 'core/text/desc',
        icon: 'file-code',
        module: BlockDesc,
        fields: [
            // [{
            //     type: 'ai',
            //     name: 'generate',
            //     label: 'Generate',
            //     related: 'content',
            //     btnType: 'text',
            //     description: '',
            //     className: 'btn-bordered padding-none-horizontal',
            //     prompt: 'Always act as TinyMCE editor assistant. Answer ONLY in HTML markup format! Wrap paragraphs with `<p>` tag.',
            //     wrapperClassName: 'margin-bottom-xs',
            // }],
            [{
                name: 'content',
                type: 'editor',
                label: '',
                description: '',
                placeholder: true,
                extended: true,
            }],
        ]
    },
    {
        type: 'core/form/button/url',
        icon: 'arrow-up-right-from-square',
        module: BlockButtonUrl,
        container: false,
        style: {
            default: {},
            hide: ['bg.place']
        },
        fields: [
            [{
                name: 'label',
                type: 'text',
                // description: '',
                placeholder: true,
            }],
            [{
                name: 'url',
                type: 'textarea',
                // description: '',
                placeholder: 'https://example.com/path...',
            }],
            [{
                name: 'is_external',
                type: 'switcher',
                default: false,
                placeholder: '',
            }],
            [
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'icon.set': 'brands'},
                    width: 12,
                },
                {
                    name: 'icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    // localeRoot: 'block.core.media.icon',
                    width: 12,
                }
            ],
            [{
                name: 'color',
                type: 'menu',
                placeholder: '',
                values: [
                    'primary',
                    'secondary',
                    'default',
                    'light',
                    'dark',
                    'success',
                    'danger',
                    'info',
                    'warning',
                ],
                default: 'primary',
            }],
            [
                {
                    name: 'size',
                    type: 'menu',
                    placeholder: '',
                    values: ['small', 'medium', 'large'],
                    default: 'medium',
                },
                {
                    name: 'size_lg',
                    type: 'menu',
                    values: ['small', 'medium', 'large'],
                    default: 'medium',
                },
            ],
            [
                {
                    name: 'variant',
                    type: 'menu',
                    placeholder: '',
                    // description: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                },
            ],
        ],
    },
    {
        type: 'core/form/button/func',
        icon: 'square-check',
        module: BlockButtonUrl,
        container: false,
        style: {
            default: {},
            hide: ['bg.place']
        },
        fields: [
            [{
                name: 'label',
                type: 'text',
                // description: '',
                placeholder: true,
                localeRoot: 'block.core.form.button.url',
            }],
            [
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'icon.set': 'regular'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'icon.set': 'light'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'icon.set': 'solid'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'icon.set': 'brands'},
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                },
                {
                    name: 'icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    // localeRoot: 'block.core.media.icon',
                    localeRoot: 'block.core.form.button.url',
                    width: 12,
                }
            ],
            [{
                name: 'color',
                type: 'menu',
                placeholder: '',
                values: [
                    'primary',
                    'secondary',
                    'default',
                    'light',
                    'dark',
                    'success',
                    'danger',
                    'info',
                    'warning',
                ],
                default: 'primary',
                localeRoot: 'block.core.form.button.url',
            }],


            [{
                name: 'functions.title',
                type: 'heading',
            }],
            [{
                name: 'functions.0.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.0.type',
                type: 'hidden',
                default: 'class_toggle',
                depends_on: {'functions.0.enable': true},
            }],
            [
                {
                    name: 'functions.0.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    // description: '',
                    depends_on: {'functions.0.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
                {
                    name: 'functions.0.spec.selector',
                    type: 'textarea',
                    // description: '',
                    placeholder: '.some-class-name, #b123',
                    depends_on: {'functions.0.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],
            [
                {
                    name: 'functions.0.spec.values',
                    type: 'tags',
                    features: ['adding'],
                    description: '',
                    depends_on: {'functions.0.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],


            [{
                name: 'functions.20.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.20.type',
                type: 'hidden',
                default: 'class_toggle',
                depends_on: {'functions.20.enable': true},
            }],
            [
                {
                    name: 'functions.20.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    // description: '',
                    depends_on: {'functions.20.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
                {
                    name: 'functions.20.spec.selector',
                    type: 'textarea',
                    // description: '',
                    placeholder: '.some-class-name, #b123',
                    depends_on: {'functions.20.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],
            [
                {
                    name: 'functions.20.spec.values',
                    type: 'tags',
                    features: ['adding'],
                    description: '',
                    depends_on: {'functions.20.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],

            [{
                name: 'functions.1.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.1.type',
                type: 'hidden',
                default: 'class_add',
                depends_on: {'functions.2.enable': true},
            }],
            [
                {
                    name: 'functions.1.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    depends_on: {'functions.1.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
                {
                    name: 'functions.1.spec.selector',
                    type: 'textarea',
                    placeholder: '.some-class-name, #b123',
                    depends_on: {'functions.1.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],
            [
                {
                    name: 'functions.1.spec.values',
                    type: 'tags',
                    features: ['adding'],
                    description: '',
                    depends_on: {'functions.1.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],

            [{
                name: 'functions.2.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.2.type',
                type: 'hidden',
                default: 'class_remove',
                depends_on: {'functions.2.enable': true},
            }],
            [
                {
                    name: 'functions.2.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    depends_on: {'functions.2.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
                {
                    name: 'functions.2.spec.selector',
                    type: 'textarea',
                    placeholder: '.some-class-name, #b123',
                    depends_on: {'functions.2.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],
            [
                {
                    name: 'functions.2.spec.values',
                    type: 'tags',
                    features: ['adding'],
                    description: '',
                    depends_on: {'functions.2.enable': true},
                    // localeRoot: 'block.core.form.button.func.functions',
                },
            ],

            [{
                name: 'functions.14.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp',
            }],
            [{
                name: 'functions.14.type',
                type: 'hidden',
                default: 'button',
                depends_on: {'functions.14.enable': true},
            }],
            [{
                name: 'functions.14.spec.text',
                type: 'textarea',
                placeholder: true,
                depends_on: {'functions.14.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.14.spec.payload',
                type: 'textarea',
                placeholder: true,
                depends_on: {'functions.14.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.14.spec.values',
                type: 'textarea',
                placeholder: true,
                depends_on: {'functions.14.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.14.spec.disable_on_active',
                type: 'checkbox',
                depends_on: {'functions.14.enable': true},
            }],

            [{
                name: 'functions.5.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.5.type',
                type: 'hidden',
                default: 'form_submit',
                depends_on: {'functions.5.enable': true},
            }],
            [
                {
                    name: 'functions.5.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    depends_on: {'functions.5.enable': true},
                    wrapperClassName: 'margin-bottom-ps',
                },
                {
                    name: 'functions.5.spec.selector',
                    type: 'textarea',
                    placeholder: '.form, #f123',
                    depends_on: {'functions.5.enable': true},
                    wrapperClassName: 'margin-bottom-ps',
                },
            ],
            [{
                name: 'functions.5.spec.disable_on_active',
                type: 'checkbox',
                depends_on: {'functions.5.enable': true},
            }],

            [{
                name: 'functions.6.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.6.type',
                type: 'hidden',
                default: 'form_combine',
                depends_on: {'functions.6.enable': true},
            }],
            [
                {
                    name: 'functions.6.spec.id',
                    type: 'tags',
                    features: ['adding'],
                    depends_on: {'functions.6.enable': true},
                    wrapperClassName: 'margin-bottom-xp',
                },
                {
                    name: 'functions.6.spec.selector',
                    type: 'textarea',
                    placeholder: '.form, #f123',
                    depends_on: {'functions.6.enable': true},
                    wrapperClassName: 'margin-bottom-xp',
                },
            ],
            [{
                name: 'functions.6.spec.text',
                type: 'textarea',
                depends_on: {'functions.6.enable': true},
                wrapperClassName: 'margin-bottom-xp',
            }],
            [{
                name: 'functions.6.spec.run_event',
                type: 'switcher',
                depends_on: {'functions.6.enable': true},
                // wrapperClassName: 'margin-bottom-xp',
            }],
            [{
                name: 'functions.6.spec.combine',
                type: 'switcher',
                depends_on: {'functions.6.enable': true, 'functions.6.spec.run_event': true},
            }],

            [{
                name: 'functions.8.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.8.type',
                type: 'hidden',
                default: 'alert',
                depends_on: {'functions.8.enable': true},
            }],
            [{
                name: 'functions.8.spec.on.text',
                type: 'textarea',
                depends_on: {'functions.8.enable': true},
            }],
            [{
                name: 'functions.8.spec.off.text',
                type: 'textarea',
                depends_on: {'functions.8.enable': true},
            }],

            [{
                name: 'functions.13.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.13.type',
                type: 'hidden',
                default: 'logout',
                depends_on: {'functions.13.enable': true},
            }],
            [{
                name: 'functions.13.spec.url',
                type: 'textarea',
                placeholder: true,
                depends_on: {'functions.13.enable': true},
            }],

            [{
                name: 'functions.9.enable',
                type: 'switcher',
                description: '',
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.9.type',
                type: 'hidden',
                default: 'redirect',
                depends_on: {'functions.9.enable': true},
            }],
            [{
                name: 'functions.9.spec.url',
                type: 'textarea',
                placeholder: true,
                depends_on: {'functions.9.enable': true},
            }],

            [{
                name: 'active.title',
                type: 'heading',
                wrapperClassName: 'margin-top-xp',
            }],
            [{
                name: 'active.label',
                type: 'text',
                // description: '',
                placeholder: true,
                depends_on: {'active.remove': false},
            }],
            [
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'active.icon.set': 'regular', 'active.remove': false},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'active.icon.set': 'light', 'active.remove': false},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'active.icon.set': 'solid', 'active.remove': false},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'active.icon.set': 'brands', 'active.remove': false},
                    width: 12,
                },
                {
                    name: 'active.icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    depends_on: {'active.remove': false},
                    width: 12,
                }
            ],
            [
                {
                    name: 'active.color',
                    type: 'menu',
                    // placeholder: '',
                    description: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'primary',
                    depends_on: {'active.remove': false},
                },
                {
                    name: 'active.variant',
                    type: 'menu',
                    description: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    depends_on: {'active.remove': false},
                },
            ],
            [{
                name: 'active.remove',
                type: 'switcher',
            }],
            [{
                name: 'active.disable',
                type: 'switcher',
            }],


            [{
                name: 'additional',
                type: 'heading',
            }],

            [{
                name: 'active.default',
                type: 'textarea',
                placeholder: '{var:status|false}',
                depends_on: {'active.remove': false},
            }],
            [
                {
                    name: 'size',
                    type: 'menu',
                    placeholder: '',
                    values: ['small', 'medium', 'large'],
                    default: 'medium',
                    localeRoot: 'block.core.form.button.url',
                },
                {
                    name: 'size_lg',
                    type: 'menu',
                    values: ['small', 'medium', 'large'],
                    default: 'medium',
                    localeRoot: 'block.core.form.button.url',
                },
            ],
            [
                {
                    name: 'variant',
                    type: 'menu',
                    placeholder: '',
                    // description: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    localeRoot: 'block.core.form.button.url',
                },
            ],
        ],
    },
    {
        type: 'core/page/list',
        icon: 'file-lines',
        module: BlockPageList,
        fields: [
            [
                {
                    name: 'parent_id',
                    type: 'textarea',
                    default: '{var:object.id}',
                    placeholder: '{var:object.id}',
                },
                {
                    name: 'section_id',
                    type: 'textarea',
                    placeholder: '{var:object.page.parent_id}',
                },
            ],
            [{
                name: 'query',
                type: 'textarea',
                placeholder: '{var:request.query.q}',
                localeRoot: 'block.core.page.list',
            }],
            [{
                name: 'query_required',
                type: 'switcher',
                // wrapperClassName: 'margin-bottom-xp',
                depends_on: {query: true},
            }],

            [{
                name: 'placeholder',
                type: 'editor',
                extended: true,
                description: '',
                // wrapperClassName: 'margin-bottom-xp',
                depends_on: {query: true},
            }],

            [{
                name: 'order',
                type: 'menu',
                placeholder: '',
                values: ['custom', 'published_at', '-published_at', 'id', '-id', '-hits', 'hits', 'title', '-title'],
                default: '-published_at',
            }],
            [
                {
                    name: 'page_number',
                    type: 'textarea',
                },
                {
                    name: 'page_size',
                    type: 'textarea',
                },
            ],

            [{
                name: 'get_desc',
                type: 'switcher',
            }],
            [{
                name: 'desc_limit',
                type: 'text',
                placeholder: '200',
                depends_on: {get_desc: true},
            }],
        ]
    },
    {
        type: 'core/page/tree',
        icon: 'file-lines',
        module: BlockPageList,
        fields: [
            [{
                name: 'section_id',
                type: 'textarea',
                default: '{var:object.id}',
                placeholder: '{var:object.id}',
                localeRoot: 'block.core.page.list',
            }],
        ]
    },
    {
        type: 'core/page/cards',
        icon: 'folder-image',
        module: BlockPageCards,
        fields: [
            [
                {
                    name: 'parent_id',
                    type: 'textarea',
                    placeholder: '{var:object.id}',
                    localeRoot: 'block.core.page.list',
                    // default: '{var:object.id}',
                },
                {
                    name: 'parent_path',
                    type: 'textarea',
                    placeholder: '/path/to/page',
                    localeRoot: 'block.core.page.list',
                    // default: '{var:object.id}',
                },
            ],
            [{
                name: 'tags',
                type: 'textarea',
                placeholder: '{var:request.query.tag}',
                localeRoot: 'block.core.page.list',
                // default: '{var:object.id}',
            }],
            [{
                name: 'styles',
                type: 'heading',
            }],
            [{
                name: 'get_desc',
                type: 'hidden',
                // default: true,
            }],
            [{
                name: 'style',
                type: 'menu',
                placeholder: '',
                values: ['cards', 'blocks', 'nav', 'raw'],
                // default: 'blocks',
            }],
            [
                {
                    name: 'cards_per_row',
                    type: 'menu',
                    placeholder: '',
                    values: ['1', '2', '3', '4'],
                    depends_on: {style: 'cards'},
                    localized: false,
                },
                {
                    name: 'desc_limit',
                    type: 'text',
                    placeholder: '200',
                    depends_on: {style: 'cards'},
                    localeRoot: 'block.core.page.list',
                },
            ],
            [{
                name: 'show_image',
                type: 'switcher',
                depends_on: {style: ['blocks', 'nav', 'raw']},
            }],

            [{
                name: 'settings',
                type: 'heading',
            }],
            [{
                name: 'desc_limit',
                type: 'text',
                placeholder: '200',
                depends_on: {style: ['blocks', 'raw']},
                localeRoot: 'block.core.page.list',
            }],

            [{
                name: 'order',
                type: 'menu',
                placeholder: '',
                values: ['custom', 'published_at', '-published_at', 'id', '-id', '-hits', 'hits', 'title', '-title'],
                localeRoot: 'block.core.page.list',
                // default: '-published_at',
            }],
            [
                {
                    name: 'page_number',
                    type: 'textarea',
                    localeRoot: 'block.core.page.list',
                },
                {
                    name: 'page_size',
                    type: 'textarea',
                    localeRoot: 'block.core.page.list',
                },
            ],

            [{
                name: 'check_access',
                type: 'switcher',
            }],
            [{
                name: 'closed_by_default',
                type: 'switcher',
                depends_on: {check_access: true},
            }],
            [{
                name: 'check_parent',
                type: 'switcher',
                depends_on: {check_access: true, closed_by_default: false},
            }],
            [{
                name: 'show_closed',
                type: 'switcher',
                depends_on: {check_access: true},
            }],
            [{
                name: 'ignore_closed_styles',
                type: 'switcher',
                depends_on: {check_access: true, show_closed: true},
            }],

            [{
                name: 'active.enable',
                type: 'switcher',
                depends_on: {style: ['cards', 'raw']},
            }],

            [{
                name: 'active.title',
                type: 'heading',
                depends_on: {style: ['cards', 'raw'], 'active.enable': true},
            }],
            [{
                name: 'active.label',
                type: 'text',
                // description: '',
                placeholder: true,
                depends_on: {style: ['cards', 'raw'], 'active.enable': true},
            }],
            [
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'active.icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'active.icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'active.icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'active.icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'active.icon.set': 'brands'},
                    width: 12,
                },
                {
                    name: 'active.icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true},
                    width: 12,
                }
            ],


            [{
                name: 'disabled.title',
                type: 'heading',
                depends_on: {style: ['cards', 'raw'], 'active.enable': true},
            }],


            [{
                name: 'disabled.label',
                type: 'text',
                placeholder: true,
                depends_on: {style: ['cards', 'raw'], 'active.enable': true},
            }],
            [{
                name: 'disabled.path',
                type: 'text',
                placeholder: true,
                depends_on: {style: ['cards', 'raw'], 'active.enable': true},
            }],
            [
                {
                    name: 'disabled.icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'disabled.icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'disabled.icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'disabled.icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'disabled.icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'disabled.icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'disabled.icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true, 'disabled.icon.set': 'brands'},
                    width: 12,
                },
                {
                    name: 'disabled.icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    depends_on: {style: ['cards', 'raw'], 'active.enable': true},
                    width: 12,
                }
            ],

        ]
    },
    {
        type: 'core/nav/tag/list',
        icon: 'tags',
        container: false,
        module: BlockTagList,
        fields: [
            [{
                name: 'parent_id',
                type: 'menu',
                data: 'tag',
            }],
            [{
                name: 'base_page',
                type: 'menu',
                data: 'pageShort',
            }],
            [{
                name: 'base_path',
                type: 'textarea',
                features: ['vars'],
                depends_on: {base_page: false}
            }],
            [{
                name: 'active_id',
                placeholder: '{var:request.query.tag}',
                type: 'text',
            }],

            [{
                name: 'show_title',
                type: 'switcher',
            }],
            // [{
            //     name: 'show_desc',
            //     type: 'switcher',
            // }],
            [{
                name: 'show_photo',
                type: 'switcher',
            }],

            [{
                name: 'order',
                type: 'menu',
                placeholder: '',
                values: ['id', '-id', 'title', '-title'],
                default: '-id',
                localeRoot: 'block.core.page.list',
            }],
            [
                {
                    name: 'page_number',
                    type: 'textarea',
                    localeRoot: 'block.core.page.list',
                },
                {
                    name: 'page_size',
                    type: 'textarea',
                    localeRoot: 'block.core.page.list',
                },
            ],
        ]
    },
    {
        type: 'core/nav/menu/custom',
        icon: 'bars',
        module: BlockNavMenu,
        container: false,
        fields: [
            [{
                name: 'menu',
                type: 'nav',
                data: 'pageShort',
                label: '',
                description: '',
                depends_on: {set_by_var: false}
            }],
            [{
                name: 'menu_var',
                type: 'textarea',
                placeholder: '{var:menu_data}',
                depends_on: {set_by_var: true}
            }],
            [{
                name: 'active_path',
                type: 'textarea',
                // placeholder: '{var:path}'
            }],
            [{
                name: 'orientation',
                type: 'menu',
                values: ['horizontal', 'vertical'],
                // default: 'horizontal',
            }],
            [{
                name: 'set_by_var',
                type: 'switcher',
                // localeRoot: commonLocaleRoot,
            }],
            [{
                name: 'xs_mode',
                type: 'switcher',
                depends_on: {orientation: 'horizontal'},
            }],
            [
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {xs_mode: true, orientation: 'horizontal', 'icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {xs_mode: true, orientation: 'horizontal', 'icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {xs_mode: true, orientation: 'horizontal', 'icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {xs_mode: true, orientation: 'horizontal', 'icon.set': 'brands'},
                    width: 12,
                },
                {
                    name: 'icon.set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    localeRoot: 'block.core.form.button.url',
                    depends_on: {xs_mode: true, orientation: 'horizontal'},
                    width: 12,
                }
            ],

            [{
                name: 'classes.title',
                type: 'heading',
            }],
            [{
                name: 'classes.icon',
                type: 'tags',
                features: ['adding'],
                placeholder: "mr-3 rounded bg-gray-100 text-gray-900 ...",
                values: [
                    'w-6',
                    'mr-3',
                    'rounded',
                    'flex',
                    'flex-row',
                    'items-center',
                    'justify-center',
                    'bg-gray-100',
                    'text-gray-500',
                    "text-gray-700",
                    'text-gray-900'
                ]
            }],
            [{
                name: 'classes.photo',
                type: 'tags',
                features: ['adding'],
                placeholder: "h-10 w-10 mr-3 rounded-full ...",
                values: ['h-10', 'w-10', 'mr-0', 'mr-3', 'xs:mr-3', 'rounded-full'],
            }],
            [{
                name: 'classes.children',
                type: 'tags',
                features: ['adding'],
                depends_on: {orientation: 'horizontal'},
                placeholder: "p-2 absolute bg-white rounded-lg ...",
                values: [
                    'z-20',
                    'absolute',
                    'transition-all',
                    'duration-300',
                    'bg-white',
                    'dark:bg-gray-800',
                    'shadow-lg',
                    'dark:shadow-white',
                    'border-1',
                    'border-gray-100',
                    'dark:border-gray-600',
                    'min-w-full',
                    'rounded-lg',
                    'p-2'
                ],
            }],
            [{
                name: 'children',
                type: 'heading',
                depends_on: {orientation: 'horizontal'},
            }],
            // [{
            //     name: 'by_hover',
            //     type: 'switcher',
            //     depends_on: {orientation: 'horizontal'},
            // }],
            [{
                name: 'classes.custom',
                type: 'tags',
                placeholder: 'custom-classes ...',
                features: ['adding'],
                depends_on: {orientation: 'horizontal'},
                values: [
                    "mb-1.5",
                    "select-none",
                    "cursor-pointer",
                    "overflow-hidden",
                    "dark:text-gray-200",
                    "dark:hover:bg-gray-700",
                    "dark:hover:text-gray-100"
                ],
            }],
            [{
                name: 'classes.hover',
                type: 'tags',
                placeholder: 'custom-hover ...',
                localeRoot: 'block.style',
                features: ['adding'],
                depends_on: {orientation: 'horizontal'},
                values: [
                    "bg-gray-100",
                    "text-gray-900"
                ]
            }],
            [{
                name: 'classes.active',
                type: 'tags',
                placeholder: 'custom-active ...',
                localeRoot: 'block.style',
                features: ['adding'],
                depends_on: {orientation: 'horizontal'},
                values: [
                    "bg-primary-100",
                    "hover:bg-primary-100",
                    "dark:bg-gray-900",
                    "dark:hover:bg-gray-900",
                    "mb-1.5",
                    "overflow-hidden",
                    "select-none",
                    "cursor-pointer"
                ]
            }],
            // [{
            //     name: 'cols',
            //     type: 'menu',
            //     values: ['1', '2', '3', '4'],
            //     depends_on: {orientation: 'horizontal'},
            // }],
        ]
    },

    {
        type: 'core/content/spoiler',
        icon: 'file-code',
        module: BlockSpoiler,
        fields: [
            [{
                name: 'title',
                type: 'textarea',
                description: '',
                placeholder: true,
                localeRoot: 'block.core.text.icon',
            }],
            [
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'icon.name',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'icon.set': 'brands'},
                    width: 12,
                },
                {
                    name: 'icon.active',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {'icon.set': 'regular'},
                    width: 12,
                },
                {
                    name: 'icon.active',
                    type: 'icon',
                    set: 'light',
                    depends_on: {'icon.set': 'light'},
                    width: 12,
                },
                {
                    name: 'icon.active',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {'icon.set': 'solid'},
                    width: 12,
                },
                {
                    name: 'icon.active',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {'icon.set': 'brands'},
                    width: 12,
                },

            ],
            [{
                name: 'icon.set',
                type: 'menu',
                placeholder: '',
                values: [
                    'regular',
                    'light',
                    'solid',
                    'brands',
                ],
                default: 'regular',
                localeRoot: 'block.core.form.button.url',
                // width: 12,
            }],
            [{
                name: 'content',
                type: 'editor',
                label: '',
                description: '',
                placeholder: true,
                extended: true,
                localeRoot: 'block.core.text.desc',
            }],

            [{
                name: 'classes.show',
                type: 'switcher',
                localeRoot: 'block.core.text.icon',
            }],
            [{
                name: 'classes.heading',
                type: 'heading',
                localeRoot: 'block.core.text.icon',
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.title',
                type: 'tags',
                features: ['adding'],
                placeholder: "mt-6 mb-3 ...",
                localeRoot: 'block.core.text.icon',
                values: [
                    "pt-2",
                    "pb-2",
                    "pl-3",
                    "pr-3",
                    "flex",
                    "flex-row",
                    "select-none",
                    "cursor-pointer",
                    'text-h1',
                    'text-h3',
                    'text-h4',
                    'font-medium',
                    'underline',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-secondary-500',
                    'text-gray-500',
                    "rounded-lg",
                    "hover:bg-gray-100",
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.active',
                type: 'tags',
                features: ['adding'],
                placeholder: "mt-6 mb-3 ...",
                localeRoot: 'block.core.text.icon',
                values: [
                    "pt-2",
                    "pb-2",
                    "pl-3",
                    "pr-3",
                    "flex",
                    "flex-row",
                    "select-none",
                    "cursor-pointer",
                    'text-h1',
                    'text-h3',
                    'text-h4',
                    'font-medium',
                    'underline',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-secondary-500',
                    'text-gray-500',
                    "rounded-lg",
                    "bg-gray-100",
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.icon',
                type: 'tags',
                features: ['adding'],
                placeholder: "text-white bg-primary-500 rounded-full...",
                localeRoot: 'block.core.text.icon',
                values: [
                    'block',
                    'w-6',
                    'w-10',
                    'w-14',
                    'mr-3',
                    'mb-5',
                    'text-5xl',
                    'text-7xl',
                    'text-9xl',
                    'rounded',
                    'rounded-full',
                    'flex',
                    'flex-row',
                    'items-center',
                    'justify-center',
                    'bg-gray-100',
                    'bg-gray-900',
                    'bg-primary-500',
                    'bg-secondary-500',
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-gray-500',
                    "text-gray-700",
                    'text-gray-900'
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.desc',
                type: 'tags',
                features: ['adding'],
                placeholder: "prose custom ...",
                localeRoot: 'block.core.text.icon',
                values: [
                    "prose",
                    "prose-invert",
                    "dark:prose-invert",
                    "lg:prose-lg",
                    "pt-4",
                    "pb-4",
                    "pl-3",
                    "pr-3",
                    'text-white',
                    'dark:text-white',
                    'text-primary-500',
                    'text-secondary-500',
                    'text-gray-500',
                    'text-gray-900',
                ],
                depends_on: {'classes.show': true}
            }],
        ]
    },

    {
        type: 'core/media/icon',
        icon: 'font-awesome',
        module: BlockIcon,
        fields: [
            [
                {
                    name: 'icon',
                    type: 'icon',
                    set: 'regular',
                    depends_on: {set: ['regular', null, undefined]},
                    width: 12,
                },
                {
                    name: 'icon',
                    type: 'icon',
                    set: 'light',
                    depends_on: {set: 'light'},
                    width: 12,
                },
                {
                    name: 'icon',
                    type: 'icon',
                    set: 'solid',
                    depends_on: {set: 'solid'},
                    width: 12,
                },
                {
                    name: 'icon',
                    type: 'icon',
                    set: 'brands',
                    depends_on: {set: 'brands'},
                    width: 12,
                },
                {
                    name: 'set',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'regular',
                        'light',
                        'solid',
                        'brands',
                    ],
                    default: 'regular',
                    width: 12,
                }
            ],
        ]
    },
    {
        type: 'core/media/image',
        icon: 'file-lines',
        module: BlockImage,
        container: false,
        fields: [
            [{
                label: '',
                description: '',
                name: 'photo_url',
                type: 'photo',
                aspect: 'slider',
                depends_on: {original_size: false},
            }],
            [{
                label: '',
                description: '',
                name: 'photo_url',
                type: 'photo',
                // aspect: 'slider',
                depends_on: {original_size: true},
            }],
            [{
                name: 'original_size',
                type: 'switcher',
            }],
            [{
                name: 'set_by_var',
                type: 'switcher',
            }],
            [{
                label: '',
                placeholder: '{var:data.photo[0].url|}',
                // description: '',
                name: 'photo_url_var',
                type: 'text',
                features: ['vars'],
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'alt',
                type: 'text',
                // description: '',
                placeholder: true,
            }],
            [{
                name: 'url',
                type: 'textarea',
                features: ['vars'],
                // description: '',
                placeholder: true,
            }],
            [{
                name: 'external',
                type: 'switcher',
                depends_on: {url: true},
            }],
        ]
    },
    {
        type: 'core/media/video/youtube',
        icon: 'video',
        module: BlockVideoYouTube,
        fields: [
            [{
                name: 'url',
                placeholder: 'https://www.youtube.com/watch?v=SomeCode',
                type: 'text',
            }],
            [{
                name: 'orientation',
                type: 'menu',
                values: ['portrait', 'landscape'],
                // placeholder: '',
                // default: 'landscape', // todo: fix default values
            }],

            // [{
            //     name: 'enable_player',
            //     type: 'checkbox',
            // }]
        ]
    },
    {
        type: 'core/media/video/html',
        icon: 'video',
        module: BlockVideoHtml,
        fields: [
            [{
                name: 'files',
                type: 'upload',
                accept: '.mp4,.webm,.mov',
                label: '',
                limit: 1,
                depends_on: {by_url: false},
            }],
            [{
                name: 'url',
                placeholder: 'https://example.com/video.mp4',
                type: 'text',
                depends_on: {by_url: true},
            }],
            [{
                name: 'by_url',
                type: 'switcher',
            }],
            [{
                name: 'enable_poster',
                type: 'switcher',
            }],
            [{
                label: '',
                description: '',
                name: 'poster_url',
                type: 'photo',
                depends_on: {enable_poster: true},
            }],
            [{
                label: '',
                name: 'poster_url',
                type: 'text',
                features: ['vars'],
                depends_on: {enable_poster: true},
            }],
            [{
                name: 'autoplay',
                type: 'switcher',
            }],
            [{
                name: 'enable_player',
                type: 'switcher',
            }],
            [{
                name: 'hide_controls',
                type: 'switcher',
                default: false,
                depends_on: {enable_player: false},
            }],
            [{
                name: 'controls',
                type: 'tags',
                values: [
                    'play-large', // The large play button in the center
                    'restart', // Restart playback
                    'rewind', // Rewind by the seek time (default 10 seconds)
                    'play', // Play/pause playback
                    'fast-forward', // Fast forward by the seek time (default 10 seconds)
                    'progress', // The progress bar and scrubber for playback and buffering
                    'current-time', // The current time of playback
                    'duration', // The full duration of the media
                    'mute', // Toggle mute
                    'volume', // Volume control
                    'captions', // Toggle captions
                    'settings', // Settings menu
                    'pip', // Picture-in-picture (currently Safari only)
                    'airplay', // Airplay (currently Safari only)
                    'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
                    'fullscreen', // Toggle fullscreen
                ],
                depends_on: {enable_player: true},
                localized: true,
            }],
            [{
                name: 'lang',
                type: 'menu',
                values: ['en', 'ru', 'uk'],
                depends_on: {enable_player: true},
                localized: true,
            }],
            [{
                name: 'add_quality',
                type: 'switcher',
                depends_on: {enable_player: true},
            }],
            [{
                name: 'hd_url',
                type: 'text',
                placeholder: 'https://example.com/video-hd.mp4',
                depends_on: {enable_player: true, add_quality: true},
            }],
            [{
                name: 'sd_url',
                type: 'text',
                placeholder: 'https://example.com/video-sd.mp4',
                depends_on: {enable_player: true, add_quality: true},
            }],
        ]
    },
    {
        type: 'core/form/login',
        icon: 'lock',
        container: false,
        module: BlockLoginForm,
        fields: [
            [
                {
                    name: 'contact_type',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'email',
                        'phone',
                        'username',
                        // 'tg_id',
                    ],
                },
            ],
            [
                {
                    name: 'login.title',
                    type: 'heading',
                },
            ],
            [
                {
                    name: 'login.label',
                    type: 'text',
                    placeholder: true,
                    description: '',
                },
                {
                    name: 'login.placeholder',
                    type: 'text',
                    placeholder: true,
                    description: '',
                },
            ],
            [
                {
                    name: 'login.desc',
                    type: 'text',
                    placeholder: true,
                },
            ],
            [
                {
                    name: 'password.title',
                    type: 'heading',
                },
            ],
            [
                {
                    name: 'password.label',
                    type: 'text',
                    placeholder: true,
                    description: '',
                },
                {
                    name: 'password.placeholder',
                    type: 'text',
                    placeholder: true,
                    description: '',
                },
            ],
            [
                {
                    name: 'password.desc',
                    type: 'text',
                    placeholder: true,
                },
            ],
            [
                {
                    name: 'submit.title',
                    type: 'heading',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [
                {
                    name: 'submit.label',
                    type: 'text',
                    placeholder: true,
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [
                {
                    name: 'submit.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'primary',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'submit.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'solid',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [
                {
                    name: 'submit.expand',
                    type: 'switcher',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [
                {
                    name: 'logout.title',
                    type: 'heading',
                },
            ],
            [{
                name: 'functions.redirect.url',
                type: 'text',
                placeholder: true,
                description: '',
            }],
            [
                {
                    name: 'logout.text',
                    type: 'editor',
                    extended: true,
                    description: '',
                },
            ],
            [
                {
                    name: 'logout.enable',
                    type: 'switcher',
                    description: '',
                },
            ],
            [
                {
                    name: 'logout.label',
                    type: 'text',
                    placeholder: true,
                    description: '',
                    depends_on: {'logout.enable': true},
                },
            ],
            [
                {
                    name: 'logout.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'secondary',
                    description: '',
                    depends_on: {'logout.enable': true},
                },
                {
                    name: 'logout.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    description: '',
                    depends_on: {'logout.enable': true},
                },
            ],
            [
                {
                    name: 'logout.expand',
                    type: 'switcher',
                    default: false,
                    placeholder: '',
                    depends_on: {'reset.enable': true},
                },
            ],
            [{
                name: 'functions.redirect.logout',
                type: 'text',
                placeholder: true,
            }],
            [
                {
                    name: 'additional',
                    type: 'heading',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [
                {
                    name: 'orientation',
                    type: 'menu',
                    placeholder: '',
                    values: ['horizontal', 'vertical'],
                    default: 'vertical',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'size',
                    type: 'menu',
                    placeholder: '',
                    values: ['sm', 'md', 'lg'],
                    default: 'md',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [{
                name: 'submit.align',
                type: 'menu',
                values: ['left', 'center', 'right'],
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_401',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_500',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
        ],
    },
    {
        type: 'core/form/common',
        icon: 'pen-to-square',
        container: false,
        module: BlockCommonForm,
        fields: [
            [{
                name: 'title',
                type: 'textarea',
            }],
            [{
                name: 'fields',
                type: 'fields_maker',
                section: 'site',
                label: '',
            }],
            [{
                name: 'functions.title',
                type: 'heading',
            }],
            [{
                name: 'functions.event',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.profile',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'authorize',
                type: 'checkbox',
                depends_on: {native: false, 'functions.profile': true},
                wrapperClassName: 'margin-bottom-xp',
            }],

            [{
                name: 'functions.redirect.enable',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.redirect.url',
                type: 'text',
                placeholder: true,
                depends_on: {native: false, 'functions.redirect.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.redirect.next',
                type: 'checkbox',
                depends_on: {native: false, 'functions.redirect.enable': true},
                wrapperClassName: 'margin-bottom-xp'
            }],

            [{
                name: 'functions.access.heading',
                type: 'heading',
                wrapperClassName: 'margin-top-ps margin-bottom-ps'
            }],

            [{
                name: 'functions.list.enable',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp',
            }],
            [{
                name: 'functions.list.add',
                type: 'text',
                data: 'field',
                placeholder: '{var:data.list_add}',
                features: ['vars'],
                wrapperClassName: 'margin-bottom-ps',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'functions.list.add',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_by_var: false, native: false, 'functions.list.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.list.remove',
                type: 'text',
                data: 'field',
                placeholder: '{var:data.list_remove}',
                features: ['vars'],
                wrapperClassName: 'margin-bottom-ps',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'functions.list.remove',
                type: 'tags',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                depends_on: {set_by_var: false, native: false, 'functions.list.enable': true},
                wrapperClassName: 'margin-bottom-ps',
            }],

            [{
                name: 'functions.access.enable',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.access.page_id',
                type: 'text',
                placeholder: true,
                depends_on: {native: false, 'functions.access.enable': true, 'functions.access.next': false},
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.access.next',
                type: 'checkbox',
                depends_on: {native: false, 'functions.access.enable': true},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.access.limiter',
                type: 'menu',
                values: ['lesson', 'module', 'course'],
                depends_on: {native: false, 'functions.access.enable': true, 'functions.access.next': true},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [{
                name: 'functions.access.redirect',
                type: 'checkbox',
                depends_on: {native: false, 'functions.access.enable': true},
                wrapperClassName: 'margin-bottom-xp'
            }],

            [{
                name: 'functions.additional',
                type: 'heading',
                wrapperClassName: 'margin-top-ps margin-bottom-ps'
            }],
            [{
                name: 'functions.visibility.enable',
                type: 'checkbox',
                depends_on: {native: false},
                wrapperClassName: 'margin-bottom-xp'
            }],
            [
                {
                    name: 'functions.visibility.show',
                    type: 'menu',
                    data: 'field',
                    features: ['creating'],
                    data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                    depends_on: {'functions.visibility.enable': true, 'functions.visibility.auth': false},
                    wrapperClassName: 'margin-bottom-sm',
                    description: '',
                },
                {
                    name: 'functions.visibility.hide',
                    type: 'menu',
                    data: 'field',
                    features: ['creating'],
                    data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                    depends_on: {'functions.visibility.enable': true, 'functions.visibility.auth': false},
                    wrapperClassName: 'margin-bottom-sm',
                    description: '',
                }
            ],
            [{
                name: 'functions.visibility.auth',
                type: 'menu',
                placeholder: true,
                values: [
                    'guest',
                    'user',
                ],
                depends_on: {
                    'functions.visibility.enable': true,
                    'functions.visibility.show': false,
                    'functions.visibility.hide': false,
                },
                wrapperClassName: 'margin-bottom-ps',
            }],
            [{
                name: 'functions.visibility.expression',
                type: 'textarea',
                placeholder: '{var:status} > 0 and {user:id}',
                depends_on: {'functions.visibility.enable': true,}
            }],
            [{
                name: 'content',
                type: 'editor',
                // section: 'block.core.form.common',
                extended: true,
                depends_on: {'functions.visibility.enable': true},
                wrapperClassName: 'margin-bottom-sm',
            }],
            [{
                name: 'native',
                type: 'checkbox',
                wrapperClassName: 'margin-bottom-xp'
            }],

            [{
                name: 'action',
                type: 'text',
                depends_on: {native: true},
                wrapperClassName: 'margin-bottom-xp'
            }],

            [{
                name: 'search_mode',
                type: 'switcher',
                wrapperClassName: 'margin-bottom-xp',
                depends_on: {native: true},
            }],

            [{
                name: 'submit.title',
                type: 'heading',
                wrapperClassName: 'margin-top-ps'
            }],
            [{
                name: 'submit.label',
                type: 'text',
                placeholder: true,
                description: '',
                depends_on: {'submit.disable': false},
            }],
            [
                {
                    name: 'submit.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'primary',
                    description: '',
                    depends_on: {'submit.disable': false},
                },
                {
                    name: 'submit.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'solid',
                    description: '',
                    depends_on: {'submit.disable': false},
                },
            ],
            [{
                name: 'text',
                type: 'editor',
                extended: true,
                // depends_on: {'submit.disable': false},
            }],
            [{
                name: 'alert',
                type: 'textarea',
                extended: true,
                // depends_on: {'submit.disable': false},
            }],
            [{
                name: 'submit.expand',
                type: 'switcher',
                default: true,
                depends_on: {'submit.disable': false},
            }],
            [{
                name: 'submit.disable',
                type: 'switcher',
                default: false,
            }],

            [{
                name: 'policy.title',
                type: 'heading',
            }],
            [{
                name: 'policy.enable',
                type: 'switcher',
            }],
            [
                {
                    name: 'policy.prefix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'policy.enable': true},
                    description: '',
                },
                {
                    name: 'policy.suffix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'policy.enable': true},
                    description: '',
                },
            ],
            [{
                name: 'policy.text',
                type: 'text',
                placeholder: true,
                depends_on: {'policy.enable': true},
                description: '',
            }],
            [{
                name: 'policy.url',
                type: 'text',
                placeholder: true,
                depends_on: {'policy.enable': true},
                description: '',
            }],
            [{
                name: 'contract.enable',
                type: 'switcher',
            }],
            [
                {
                    name: 'contract.prefix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'contract.enable': true},
                    description: '',
                },
                {
                    name: 'contract.suffix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'contract.enable': true},
                    description: '',
                },
            ],
            [{
                name: 'contract.text',
                type: 'text',
                placeholder: true,
                depends_on: {'contract.enable': true},
                description: '',
            }],
            [{
                name: 'contract.url',
                type: 'text',
                placeholder: true,
                depends_on: {'contract.enable': true},
                description: '',
            }],

            [
                {
                    name: 'additional',
                    type: 'heading',
                    description: '',
                },
            ],

            [{
                name: 'name',
                type: 'text',
            }],
            [
                {
                    name: 'orientation',
                    type: 'menu',
                    placeholder: '',
                    values: ['horizontal', 'vertical'],
                    default: 'vertical',
                    description: '',
                },
                {
                    name: 'size',
                    type: 'menu',
                    placeholder: '',
                    values: ['sm', 'md', 'lg'],
                    default: 'md',
                    description: '',
                },
            ],
            [{
                name: 'submit.align',
                type: 'menu',
                values: ['left', 'center', 'right'],
            }],
            [
                {
                    name: 'reset.enable',
                    type: 'switcher',
                    default: false,
                },
            ],
            [
                {
                    name: 'reset.label',
                    type: 'text',
                    placeholder: true,
                    description: '',
                    depends_on: {'reset.enable': true},
                },
            ],
            [
                {
                    name: 'reset.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'secondary',
                    description: '',
                    depends_on: {'reset.enable': true},
                },
                {
                    name: 'reset.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    description: '',
                    depends_on: {'reset.enable': true},
                },
            ],
            [
                {
                    name: 'reset.expand',
                    type: 'switcher',
                    default: false,
                    placeholder: '',
                    depends_on: {'reset.enable': true},
                },
            ],

            [{
                name: 'set_by_var',
                type: 'switcher',
                default: false,
                localeRoot: commonLocaleRoot,
            }],

            [{
                name: 'error.title',
                type: 'heading',
                depends_on: {native: false},
            }],
            [{
                name: 'error_400',
                type: 'textarea',
                depends_on: {native: false},
            }],
            [{
                name: 'error_401',
                type: 'textarea',
                depends_on: {native: false},
            }],
            [{
                name: 'error_403',
                type: 'textarea',
                depends_on: {native: false},
            }],
            [{
                name: 'error_409',
                type: 'textarea',
                depends_on: {native: false},
            }],
            [{
                name: 'error_500',
                type: 'textarea',
                depends_on: {native: false},
            }],
        ],
    },
    {
        type: 'core/form/subscribe',
        icon: 'user-check',
        container: false,
        module: BlockCommonForm,
        fields: [
            [{
                name: 'functions.list.code',
                type: 'text',
                data: 'field',
                placeholder: '{var:data.list}',
                features: ['vars'],
                wrapperClassName: 'margin-bottom-ps',
                depends_on: {set_by_var: true},
            }],
            [{
                name: 'functions.list.code',
                type: 'menu',
                data: 'field',
                features: ['creating'],
                data_filters: {system_field_type: 'list', data_type_admin: 'int'},
                wrapperClassName: 'margin-bottom-ps',
                depends_on: {set_by_var: false},
            }],

            [{
                name: 'functions.visibility.enable',
                type: 'switcher',
            }],

            [{
                name: 'functions.profile',
                type: 'checkbox',
                localeRoot: 'block.core.form.common',
                wrapperClassName: 'hide',
            }],

            [{
                name: 'functions.visibility.auth',
                type: 'hidden',
                value: 'user',
            }],

            [{
                name: 'submit.title',
                type: 'heading',
                wrapperClassName: 'margin-top-ps'
            }],
            [{
                name: 'fields',
                type: 'fields_maker',
                section: 'site',
                label: '',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'text',
                type: 'editor',
                extended: true,
            }],
            [{
                name: 'alert',
                type: 'textarea',
                extended: true,
            }],
            [{
                name: 'functions.redirect.start',
                type: 'text',
                placeholder: true,
            }],
            [{
                name: 'submit.label',
                type: 'text',
                placeholder: true,
                description: '',
            }],
            [
                {
                    name: 'submit.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'primary',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'submit.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'solid',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [{
                name: 'submit.expand',
                type: 'switcher',
                default: true,
                localeRoot: 'block.core.form.common',
            }],

            [{
                name: 'reset.title',
                type: 'heading',
                wrapperClassName: 'margin-top-ps'
            }],
            [{
                name: 'reset.text',
                type: 'editor',
                extended: true,
            }],
            [{
                name: 'reset.alert',
                type: 'textarea',
                extended: true,
            }],

            [{
                name: 'reset.label',
                type: 'text',
                placeholder: true,
                description: '',
            }],
            [{
                name: 'functions.redirect.finish',
                type: 'text',
                placeholder: true,
            }],

            [
                {
                    name: 'reset.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'secondary',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'reset.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [{
                name: 'reset.expand',
                type: 'switcher',
                default: false,
                placeholder: '',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'policy.title',
                type: 'heading',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'policy.enable',
                type: 'switcher',
                localeRoot: 'block.core.form.common',
            }],
            [
                {
                    name: 'policy.prefix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'policy.enable': true},
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'policy.suffix',
                    type: 'text',
                    placeholder: true,
                    depends_on: {'policy.enable': true},
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],
            [{
                name: 'policy.text',
                type: 'text',
                placeholder: true,
                depends_on: {'policy.enable': true},
                description: '',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'policy.url',
                type: 'text',
                placeholder: true,
                depends_on: {'policy.enable': true},
                description: '',
                localeRoot: 'block.core.form.common',
            }],

            [
                {
                    name: 'additional',
                    type: 'heading',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],

            [{
                name: 'set_by_var',
                type: 'switcher',
                default: false,
                localeRoot: commonLocaleRoot,
            }],
            [
                {
                    name: 'orientation',
                    type: 'menu',
                    placeholder: '',
                    values: ['horizontal', 'vertical'],
                    default: 'vertical',
                    description: '',
                    localeRoot: 'block.core.form.common',
                },
            ],

            // {
            //     name: 'size_lg',
            //     type: 'menu',
            //     placeholder: '',
            //     values: ['sm', 'md', 'lg'],
            //     default: 'md',
            //     description: '',
            //     localeRoot: 'block.core.form.common',
            // },

            [{
                name: 'submit.align',
                type: 'menu',
                values: ['left', 'center', 'right'],
                localeRoot: 'block.core.form.common',
            }],

            [{
                name: 'content',
                type: 'editor',
                extended: true,
                wrapperClassName: 'margin-bottom-sm',
                localeRoot: 'block.core.form.common',
            }],


            [{
                name: 'error.title',
                type: 'heading',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_400',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_401',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_403',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_409',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'error_500',
                type: 'textarea',
                localeRoot: 'block.core.form.common',
            }],
        ],
    },
    {
        type: 'core/game/achievement/list',
        icon: 'award',
        module: BlockAchievementList,
        fields: [
            [{
                name: 'codes',
                type: 'tags',
                data: 'field',
                // features: ['creating'],
                data_filters: {system_field_type: 'achievement', data_type_admin: 'int'},
                depends_on: {by_game: false, set_by_var: false},
            }],
            [{
                name: 'codes_var',
                type: 'textarea',
                features: ['vars'],
                depends_on: {by_game: false, set_by_var: true},
            }],
            [{
                name: 'game',
                type: 'menu',
                data: 'site',
                data_query: {types: ['game']},
                depends_on: {by_game: true, set_by_var: false},
            }],
            [{
                name: 'game_var',
                type: 'textarea',
                features: ['vars'],
                depends_on: {by_game: true, set_by_var: true},
            }],
            [{
                name: 'by_game',
                type: 'switcher',
            }],
            [{
                name: 'show_all',
                type: 'switcher',
            }],
            [{
                name: 'obtained_first',
                type: 'switcher',
                depends_on: {show_all: true},
            }],
            [{
                name: 'set_by_var',
                type: 'switcher',
                localeRoot: commonLocaleRoot,
            }],
            // show_title
            [{
                name: 'show_title',
                type: 'switcher',
            }],
            [{
                name: 'styles',
                type: 'heading',
                localeRoot: blockLocaleRoot,
            }],
            [{
                // Class List
                name: 'cl.item',
                type: 'tags',
                features: ['adding'],
                localeRoot: blockLocaleRoot,
            }],
            [{
                name: 'cl.photo',
                type: 'tags',
                features: ['adding'],
                localeRoot: blockLocaleRoot,
            }],
            [{
                name: 'cl.title',
                type: 'tags',
                features: ['adding'],
                localeRoot: blockLocaleRoot,
                depends_on: {show_title: true},
            }],
        ]
    },
    {
        type: 'core/game/wheel',
        icon: 'chart-pie',
        module: BlockWheel,
        fields: [
            [{
                name: 'title',
                type: 'text',
                // description: '',
                placeholder: true,
            }],
            [{
                name: 'sectors',
                type: 'sectors',
                depends_on: {set_by_var: false},
            }],
            [{
                name: 'sectors_var',
                type: 'textarea',
                depends_on: {set_by_var: true},
            }],
            [{
                label: '',
                // description: '',
                name: 'photo_url',
                type: 'photo',
                // depends_on: {set_by_var: false},
            }],

            [{
                name: 'set_by_var',
                type: 'switcher',
                localeRoot: 'block.core.media.image',
                wrapperClassName: 'margin-bottom-sm',
            }],

            [{
                name: 'photo_url_var',
                type: 'text',
                label: '',
                placeholder: '{var:data.photo[0].url|}',
                features: ['vars'],
                localeRoot: 'block.core.media.image',
                depends_on: {set_by_var: true},
            }],

            [{
                name: 'width',
                type: 'text',
                // description: '',
                placeholder: true,
            }],

            [
                {
                    name: 'limit',
                    type: 'text',
                },
                {
                    name: 'rate',
                    type: 'text',
                    placeholder: 1,
                },
            ],
            [{
                name: 'limit_selector',
                type: 'text',
                placeholder: '.wheel-limit',
            }],

            [{
                name: 'stopped.event',
                type: 'checkbox',
                wrapperClassName: 'margin-bottom-sm',
            }],
            [{
                name: 'stopped.remember',
                type: 'checkbox',
                wrapperClassName: 'margin-bottom-sm',
            }],
            [{
                name: 'stopped.show_photo',
                type: 'checkbox',
                wrapperClassName: 'margin-bottom-sm',
            }],
            [{
                name: 'stopped.show_title',
                type: 'checkbox',
                wrapperClassName: 'margin-bottom-sm',
            }],

            [{
                name: 'content',
                type: 'heading',
            }],

            [{
                name: 'submit.enable',
                type: 'switcher',
            }],

            [{
                name: 'submit.label',
                type: 'text',
                placeholder: true,
                description: '',
                depends_on: {'submit.enable': true},
                // localeRoot: 'block.core.form.common',
            }],
            [
                {
                    name: 'submit.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'primary',
                    description: '',
                    depends_on: {'submit.enable': true},
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'submit.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'solid',
                    description: '',
                    depends_on: {'submit.enable': true},
                    localeRoot: 'block.core.form.common',
                },
            ],

            [{
                name: 'reset.enable',
                type: 'switcher',
                // localeRoot: 'block.core.form.common',
            }],
            [{
                name: 'reset.label',
                type: 'text',
                placeholder: true,
                description: '',
                depends_on: {'reset.enable': true},
                localeRoot: 'block.core.form.common',
            }],
            [
                {
                    name: 'reset.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    default: 'secondary',
                    description: '',
                    depends_on: {'reset.enable': true},
                    localeRoot: 'block.core.form.common',
                },
                {
                    name: 'reset.variant',
                    type: 'menu',
                    placeholder: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    default: 'contained',
                    description: '',
                    depends_on: {'reset.enable': true},
                    localeRoot: 'block.core.form.common',
                },
            ],

            [{
                name: 'stop.enable',
                type: 'switcher',
            }],
            [{
                name: 'stop.label',
                type: 'text',
                placeholder: true,
                description: '',
                depends_on: {'stop.enable': true},
            }],
            [
                {
                    name: 'stop.color',
                    type: 'menu',
                    placeholder: '',
                    values: [
                        'primary',
                        'secondary',
                        'default',
                        'light',
                        'dark',
                        'success',
                        'danger',
                        'info',
                        'warning',
                    ],
                    // default: 'danger',
                    description: '',
                    depends_on: {'stop.enable': true},
                },
                {
                    name: 'stop.variant',
                    type: 'menu',
                    placeholder: '',
                    description: '',
                    values: ['contained', 'ghost', 'outlined', 'text'],
                    // default: 'contained',
                    depends_on: {'stop.enable': true},
                }
            ],


            [{
                name: 'submit.desc',
                type: 'editor',
                extended: true,
                // depends_on: {'stop.enable': true},
            }],
            [{
                name: 'stop.desc',
                type: 'editor',
                extended: true,
                // depends_on: {'stop.enable': true},
            }],
            [{
                name: 'stop.fail',
                type: 'editor',
                extended: true,
                // depends_on: {'stop.enable': true},
            }],
            [{
                name: 'submit.reached',
                type: 'editor',
                extended: true,
                // depends_on: {'stop.enable': true},
            }],

            [{
                name: 'classes.heading',
                type: 'heading',
                localeRoot: 'block.core.text.icon',
            }],

            [{
                name: 'classes.show',
                type: 'switcher',
                localeRoot: 'block.core.text.icon',
            }],
            [{
                name: 'classes.photo',
                type: 'tags',
                features: ['adding'],
                placeholder: "flex flex-col align-end ...",
                values: [
                    "pt-2",
                    "pb-2",
                    "pl-3",
                    "pr-3",
                    "text-center",
                    "text-right",
                    "flex",
                    "flex-row",
                    "flex-col",
                    "justify-start",
                    "justify-center",
                    "justify-end",
                    "items-start",
                    "items-center",
                    "items-end",
                    "rounded-full",
                    "cursor-pointer",
                ],
                depends_on: {'classes.show': true}
            }],
            [{
                name: 'classes.content',
                type: 'tags',
                features: ['adding'],
                placeholder: "flex flex-col align-start ...",
                values: [
                    "p-4",
                    "pt-2",
                    "pb-2",
                    "pl-3",
                    "pr-3",
                    "text-left",
                    "text-center",
                    "flex",
                    "flex-row",
                    "flex-col",
                    "justify-start",
                    "justify-center",
                    "justify-end",
                    "items-start",
                    "items-center",
                    "items-end",
                ],
                depends_on: {'classes.show': true}
            }],
        ]
    },
]