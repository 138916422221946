import React, {useEffect} from 'react';
import classes from "./LayoutsModal.module.scss";
import {Card, Modal, Typography} from "antd";
import Fa from "../../../components/Icon/Fa";
import {inArray} from "../../../library/functions";

const {Meta} = Card;

export const ThemesModal = (
    {
        t, // useTranslation()
        section = 'theme',
        list = null,
        publicList = null,
        isVisible = false,
        setVisible = null,
        onInstall = null,
        onOk = null,
        onCancel = null,
        width = 1140,
        title = null,
    }
) => {
    const layoutNames = list ? list.map(item => item.name) : [];
    const publicListFiltered = (publicList || []).filter(item => !inArray(item.name, layoutNames));
    // console.log('publicList', publicList)

    const hideModal = () => {
        setVisible(false);
    };

    const handleInstall = (content) => {
        // console.log('handleInstall', content)
        if (onInstall) onInstall(content);
    };

    const handleCancel = () => {
        hideModal();
        if (onCancel) onCancel()
    };

    useEffect(() => {
        if (isVisible && !publicListFiltered.length) {
            handleInstall(''); // move to form
        }
    }, [isVisible])

    const plusImageUrl = "/image/icons/common/plus.svg";

    return <Modal
        width={width}
        centered={true}
        className={`${classes.modal_wrapper} ${width <= 900 ? classes.small : ''}`}
        title={title}
        open={isVisible}
        onOk={onOk}
        onCancel={handleCancel}
        footer={null}
    >
        <div className={`${classes.list_wrapper} padding-bottom-ps`}>
            <Card
                key="create"
                className={`${classes.card} cursor-pointer`}
                cover={<Fa icon="plus" set="light" wcn={classes.icon}/>}
                bordered={false}
                hoverable
                onClick={() => {
                    handleInstall('')
                }}
            >
                <Meta title={section ? t(section + '.object.create') : t('common.action.create')}/>
            </Card>

            {publicListFiltered.length ? publicListFiltered.map((item, index) => {
                // if (list && inArray(item.name, layoutNames)) return null;
                return <Card
                    key={item.name + index}
                    className={`${classes.card} cursor-pointer`}
                    hoverable
                    bordered={false}
                    cover={<div
                      onClick={() => {
                            handleInstall(item.name)
                        }}
                    >
                        {item.photo_url ? <img
                            alt={item.name}
                            src={item.photo_url ? item.photo_url : plusImageUrl}
                        /> : <Fa
                            icon={item.icon_name}
                            set="light"
                            wcn={classes.icon}
                        />}
                </div>}
                >
                    <Meta title={item.title}/>
                </Card>
            }) : null}
        </div>
    </Modal>
}

