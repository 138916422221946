import createSagaMiddleware from 'redux-saga'
import {fork} from 'redux-saga/effects'
import {configureStore} from "@reduxjs/toolkit";

import {adminSaga} from '../sagas/adminSaga'
import {projectSaga} from "../sagas/projectSaga";
import {templateSaga} from "../sagas/templateSaga";
import {integrationSaga} from "../sagas/integrationSaga";
import {webhookSaga} from "../sagas/webhookSaga";
import {urlSaga} from "../sagas/urlSaga";
import {graphSaga} from "../sagas/graphSaga";
import {globSaga} from "../sagas/globSaga";
import {userFieldSaga} from "../sagas/userFieldSaga";
import {userSaga} from "../sagas/userSaga";
import {contactSaga} from "../sagas/contactSaga";
import {accessSaga} from "../sagas/accessSaga";
import {orderSaga} from "../sagas/orderSaga";
import {utmSaga} from "../sagas/utmSaga";
import {userUtmSaga} from "../sagas/userUtmSaga";
import {discountSaga} from "../sagas/discountSaga";
import {productSaga} from "../sagas/productSaga";
import {productCategorySaga} from "../sagas/productCategorySaga";
import {invoiceSaga} from "../sagas/invoiceSaga";
import {folderSaga} from "../sagas/folderSaga";
import {fieldSaga} from "../sagas/fieldSaga";
import {siteSaga} from "../sagas/siteSaga";
import {domainSaga} from "../sagas/domainSaga";
import {tagSaga} from "../sagas/tagSaga";
import {layoutSaga} from "../sagas/layoutSaga";
import {themeSaga} from "../sagas/themeSaga";
import {pageSaga} from "../sagas/pageSaga";
import {answerSaga} from "../sagas/answerSaga";
import {segmentSaga} from "../sagas/segmentSaga";
import {roleSaga} from "../sagas/roleSaga";
import {senlerSaga} from "../sagas/senlerSaga";
import {smSaga} from "../sagas/smSaga";
import {mailSaga} from "../sagas/mailSaga";
import {dialogSaga} from "../sagas/dialogSaga";
import {employeeSaga} from "../sagas/employeeSaga";
import {sectSaga} from "../sagas/sectSaga";

import StatusReducer from "../reducers/StatusReducer";
import AuthAdminReducer from "../reducers/AdminReducer";
import ProjectReducer from "../reducers/ProjectReducer";
import TemplateReducer from "../reducers/TemplateReducer";
import IntegrationReducer from "../reducers/IntegrationReducer";
import WebhookReducer from "../reducers/WebhookReducer";
import UrlReducer from "../reducers/UrlReducer";
import GraphReducer from "../reducers/GraphReducer";
import GraphNodeReducer from "../reducers/GraphNodeReducer";
import GlobReducer from "../reducers/GlobReducer";
import UserFieldReducer from "../reducers/UserFieldReducer";
import FieldValueReducer from "../reducers/FieldValueReducer";
import UserReducer from "../reducers/UserReducer";
import ContactReducer from "../reducers/ContactReducer";
import AccessReducer from "../reducers/AccessReducer";
import OrderReducer from "../reducers/OrderReducer";
import UtmReducer from "../reducers/UtmReducer";
import UserUtmReducer from "../reducers/UserUtmReducer";
import DiscountReducer from "../reducers/DiscountReducer";
import ProductReducer from "../reducers/ProductReducer";
import ProductCategoryReducer from "../reducers/ProductCategoryReducer";
import InvoiceReducer from "../reducers/InvoiceReducer";
import FolderReducer from "../reducers/FolderReducer";
import FieldReducer from "../reducers/FieldReducer";
import SiteReducer from "../reducers/SiteReducer";
import DomainReducer from "../reducers/DomainReducer";
import TagReducer from "../reducers/TagReducer";
import LayoutReducer from "../reducers/LayoutReducer";
import ThemeReducer from "../reducers/ThemeReducer";
import PageReducer from "../reducers/PageReducer";
import BlockReducer from "../reducers/BlockReducer";
import AnswerReducer from "../reducers/AnswerReducer";
import SegmentReducer from "../reducers/SegmentReducer";
import RoleReducer from "../reducers/RoleReducer";
import SenlerGroupReducer from "../reducers/SenlerGroupReducer";
import SenlerBotReducer from "../reducers/SenlerBotReducer";
import SmGroupReducer from "../reducers/SmGroupReducer";
import SmProductReducer from "../reducers/SmProductReducer";
import MailReducer from "../reducers/MailReducer";
import DialogReducer from "../reducers/DialogReducer";
import EmployeeReducer from "../reducers/EmployeeReducer";
import SectReducer from "../reducers/SectReducer";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        app: StatusReducer,
        admin: AuthAdminReducer,
        user: UserReducer,
        access: AccessReducer,
        contact: ContactReducer,
        userField: UserFieldReducer,
        field: FieldReducer,
        fieldValue: FieldValueReducer,
        userUtm: UserUtmReducer,

        utm: UtmReducer,
        order: OrderReducer,
        discount: DiscountReducer,
        product: ProductReducer,
        productCategory: ProductCategoryReducer,
        invoice: InvoiceReducer,

        project: ProjectReducer,
        folder: FolderReducer,
        webhook: WebhookReducer,
        template: TemplateReducer,
        integration: IntegrationReducer,
        graphNode: GraphNodeReducer,
        graph: GraphReducer,
        mail: MailReducer,
        dialog: DialogReducer,
        glob: GlobReducer,
        url: UrlReducer,

        answer: AnswerReducer,
        segment: SegmentReducer,
        role: RoleReducer,

        employee: EmployeeReducer,
        sect: SectReducer,

        site: SiteReducer,
        domain: DomainReducer,
        page: PageReducer,
        layout: LayoutReducer,
        theme: ThemeReducer,
        tag: TagReducer,
        pb: BlockReducer,  // page blocks

        senlerGroup: SenlerGroupReducer,
        senlerBot: SenlerBotReducer,

        smGroup: SmGroupReducer,
        smProduct: SmProductReducer,
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware).concat(logger),
    middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

function* rootSaga() {
    yield fork(adminSaga);
    yield fork(fieldSaga);
    yield fork(userSaga);
    yield fork(contactSaga);
    yield fork(accessSaga);
    yield fork(userFieldSaga);
    yield fork(userUtmSaga);

    yield fork(utmSaga);
    yield fork(orderSaga);
    yield fork(discountSaga);
    yield fork(productSaga);
    yield fork(productCategorySaga);
    yield fork(invoiceSaga);

    yield fork(projectSaga);
    yield fork(folderSaga);
    yield fork(templateSaga);
    yield fork(integrationSaga);
    yield fork(webhookSaga);
    yield fork(graphSaga);
    yield fork(mailSaga);
    yield fork(dialogSaga);
    yield fork(globSaga);
    yield fork(urlSaga);

    yield fork(siteSaga);
    yield fork(domainSaga);
    yield fork(layoutSaga);
    yield fork(themeSaga);
    yield fork(pageSaga);
    yield fork(tagSaga);

    yield fork(answerSaga);
    yield fork(segmentSaga);
    yield fork(roleSaga);

    yield fork(employeeSaga);
    yield fork(sectSaga);

    yield fork(senlerSaga);
    yield fork(smSaga);

    // или ↓
    // yield all([ adminSaga(), projectSaga(), procesSaga() ])
}

sagaMiddleware.run(rootSaga)

export default store