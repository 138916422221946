import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
}

const UrlReducer = createSlice({
    name: 'url',
    initialState,
    reducers: {
        fillUrlList(state, action) {
            state.list = [...action.payload]
        },
        setUrlItem(state, action) {
            state.item = action.payload;
        },
        setUrlStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addUrlToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeUrlFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0;  // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchUrlInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
            // console.log('patchGraphInList state.item', state.item)
        },
        resetUrlState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        }
    }
})

export default UrlReducer.reducer
export const {
    fillUrlList,
    setUrlItem,
    addUrlToList,
    removeUrlFromList,
    setUrlStoreParam,
    patchUrlInList,
    resetUrlState,
} = UrlReducer.actions