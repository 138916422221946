import React from 'react'
import {BlockButton} from "../../../Components/Button";
import {createMarkup, is} from "../../../../../../library/functions";

export const BlockLoginForm = (spec, params, classes, styles) => {
    if (!spec) return null;

    const logout = spec.logout || {};

    let props = {};
    if (is(styles)) props['style'] = styles;

    return <div className={classes.join('')} {...props}>
        {logout.text ? <div
            className="form-logout-desc prose dark:prose-invert mb-4"
            dangerouslySetInnerHTML={createMarkup(logout.text || {})}
        /> : null}

        {logout.enable ? <BlockButton
            spec={logout}
            classes={logout.expand ? ['w-full'] : []}
        /> : null}
    </div>
}