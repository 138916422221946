import React from 'react';
import {Input} from "antd";
import {FormItemLabel} from "./FormItemLabel";
import {FormItemWrapper} from "./FormItemWrapper";

export const FormInput = (
    {
        type = 'text',
        className = '',
        label = '',
        desc = '',
        maxLength = null,
        placeholder = '',
        inputValue = '',
        inputId = null,
        inputRef = null,
        onChange = null,
        onKeyDown = null,
        autoFocus = false,
    }) => {

    return (

        <FormItemWrapper className={className} desc={desc}>
            {label?<FormItemLabel label={label} htmlFor={inputId} />:''}
            <Input
                type={type}
                id={inputId}
                ref={inputRef}
                maxLength={maxLength}
                showCount={!!maxLength}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={inputValue}
            />
        </FormItemWrapper>
    );
};

