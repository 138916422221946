import React from 'react'
import {createMarkup, deepGet, is} from "../../../../../../library/functions";
import {useTranslation} from "react-i18next";
import {BlockButton} from "../../../Components/Button";
import {Show} from "../../../Components/Solid";
import classes from "./game.module.css";

export const BlockAchievementList = (spec) => {
    const {t} = useTranslation();
    if (!spec) return null;

    const list = [
        {
            title: t('container.example.achievement.0.title'),
            photo_url: t('container.example.achievement.0.photo_url'),
        },
        {
            title: t('container.example.achievement.1.title'),
            photo_url: t('container.example.achievement.1.photo_url'),
        },
        {
            title: t('container.example.achievement.2.title'),
            photo_url: t('container.example.achievement.2.photo_url'),
        }
    ];

    const classes = is(spec.cl) ? spec.cl : {};

    let itemClasses = ['c-item'];
    if (is(classes.item)) itemClasses = [...itemClasses, ...classes.item];

    let imgClasses = ['c-item-photo'];
    if (is(classes.photo)) imgClasses = [...imgClasses, ...classes.photo];

    let titleClasses = ['c-item-title'];
    if (is(classes.title)) titleClasses = [...titleClasses, ...classes.title];

    return <>
        {list.map((item) => <div className={itemClasses.join(' ')}>
            <img className={imgClasses.join(' ')} src={item.photo_url} alt={item.title}/>
            {spec.show_title ? <h3 className={titleClasses.join(' ')}>{item.title}</h3> : null}
        </div>)}
    </>
}

export const BlockWheel = (spec) => {
    const {t} = useTranslation();
    if (!spec) return null;

    const specClasses = is(spec.classes) ? spec.classes : {};

    let imgClasses = ['c-wheel'];
    if (is(specClasses.photo)) imgClasses = [...imgClasses, ...specClasses.photo];

    let contentClasses = ['c-content'];
    if (is(specClasses.content)) contentClasses = [...contentClasses, ...specClasses.content];

    return <Show
        when={deepGet(spec, ['sectors', 'tree']) && spec.sectors.tree.length}
        fallback={<div className="c-empty color-danger">
            {t('container.example.wheel.empty')}
        </div>}
    >
        <div className={imgClasses.join(' ')}>
            <div className="c-photo-wrap not-prose relative inline-block mb-6 rounded-full">
                <div className={`c-arrow ${classes.arrow}`}/>
                <img
                    className="c-photo rounded-full"
                    src={spec.photo_url}
                    alt={spec.title || 'Wheel of Fortune'}
                    width={spec.width || undefined}
                    height={spec.width || undefined}
                />
            </div>
        </div>

        <div className={contentClasses.join(' ')}>
            <Show when={spec?.submit?.enable}>
                <BlockButton
                    classes={["c-button c-spin"]}
                    // type="button"
                    // color={spec.submit.color || 'primary'}
                    // variant={spec.submit.variant || 'contained'}
                    // label={spec.submit.label}
                    spec={{
                        type: 'button',
                        color: spec.submit.color || 'primary',
                        variant: spec.submit.variant || 'contained',
                        label: spec.submit.label,
                    }}
                />
            </Show>
            <Show when={spec?.submit?.desc}>
                <div className="c-desc c-rules pt-4" dangerouslySetInnerHTML={createMarkup(spec.submit.desc)}/>
            </Show>
        </div>
    </Show>
}