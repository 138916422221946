const Fa = (
    {
        icon = 'face-smile',
        set = 'light', // light, regular, brands
        options = '',
        className = '',
        color = '',
        wcn = '', // wrapper class name
        antd = false,
    }
) => {
    if (!set) set = 'light';
    if (set === 'brands' && icon === 'face-smile') icon = 'font-awesome-flag';

    let opts = []
    for (const opt of options.split(' ')) {
        if (opt) opts.push('fa-' + opt);
    }
    if (opts) className += ' ' + opts.join(' ');

    let iconClasses = 'fa-' + set + ' fa-' + icon;
    if (className) iconClasses += ' ' + className;

    let props = {}
    if (color) props.style = {color: color}

    if (antd && !wcn) wcn = 'anticon';
    if (wcn) return <span className={wcn} role="img" aria-label="Icon">
        <i className={iconClasses} {...props}/>
    </span>

    return <span className="icon" role="img" aria-label="Icon"><i className={iconClasses} {...props}/></span>;
}

export default Fa