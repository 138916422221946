import {createSlice} from "@reduxjs/toolkit";

const AccessReducer = createSlice({
    name: 'access',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillAccessList(state, action) {
            state.list = action.payload
        },
        setAccessItem(state, action) {
            state.item = action.payload;
        },
        setAccessListCount(state, action) {
            state.count = action.payload;
        },
        addAccessToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeAccessFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchAccessInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetAccessState(state) {
            state.list = [];
            state.item = {};
            state.count = 0;
        },
    }
})

export default AccessReducer.reducer
export const {
    fillAccessList,
    setAccessItem,
    resetAccessState,
    setAccessListCount,
    patchAccessInList,
    addAccessToList,
    removeAccessFromList
} = AccessReducer.actions