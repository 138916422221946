import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import classes from "../Block/BlockDrawers.module.scss";
import Scrollbar from "react-scrollbars-custom";
import {Col, Drawer, Form} from "antd";

import {deepCopyObject, flip, objDeepExtend} from "../../../library/functions";
import {EffectElement} from "../../Flow/components/ControlPanel/components/Container/EffectElement";
import {CheckFieldDependsOn} from "../../../components/Form/Field/FormFields";
import {renderContainerForm} from "../../../library/effects";
import {resetLayoutActive, setLayoutRows, setRow} from "../../../redux/reducers/BlockReducer";

import {rowFields, colFields, secFields, defaultRowValues, defaultColValues, defaultSecValues} from "./LayoutFields";

const fieldsMap = {
    row: rowFields,
    col: colFields,
    sec: secFields,
}

const valuesMap = {
    row: defaultRowValues,
    col: defaultColValues,
    sec: defaultSecValues,
}

export const LayoutDrawers = ({page, rows, initialTypes = null}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const store = useSelector(store => store);
    const pb = store['pb'];

    const item = pb.row;
    const active = pb.layoutActive;

    const types = flip(initialTypes);
    const itemType = item.type ? types[item.type] : undefined;

    // console.log('rows', rows, valuesMap)
    // const [formValues, setFormValues] = useState({});  // crutch for upload field saving

    const [form] = Form.useForm();

    useEffect(() => {
        if (item.id !== 0) {
            form.setFieldsValue(item.spec);
            // setFormValues(item.spec);
        } else {
            // reset to defaults
            form.setFieldsValue(valuesMap[itemType]);
        }

        // reset to defaults on unmount
        return () => form.setFieldsValue(valuesMap[itemType]);
    }, [page, item.id]);

    const onClose = () => {
        dispatch(setRow({}));
        dispatch(resetLayoutActive());
        form.resetFields();
    };

    const onBlur = (additionalValues) => {
        let values = deepCopyObject(item.spec || {}) || {};

        let formNewValues = form.getFieldsValue();
        objDeepExtend(values, formNewValues);

        if (additionalValues) {
            objDeepExtend(values, additionalValues);
            form.setFieldsValue(values);
        }


        const rowIndex = rows.findIndex(e => e.id === active.row);
        const rowList = [...rows];

        if (!rowList[rowIndex] || !rowList[rowIndex].items) {
            console.debug('Row not found by ID: ', active.row, 'rows: ', rowList);
            return;
        }
        const currentRow = {...rowList[rowIndex]};

        if (itemType === 'row') {
            // save row styles
            rowList[rowIndex] = {...currentRow, spec: values};
        } else {
            const collist = [...currentRow.items];
            const colIndex = currentRow.items.findIndex(e => e.id === active.col);

            if (!collist[colIndex] || !collist[colIndex].items) return;
            const currentCol = {...collist[colIndex]};

            if (itemType === 'col') {
                // save col styles
                collist[colIndex] = {...currentCol, spec: values};
                rowList[rowIndex] = {...currentRow, items: collist};
            } else {
                // save item styles or spec
                const sectionsList = [...collist[colIndex].items];
                const sectionIndex = currentCol.items.findIndex(e => e.id === item.id);

                if (!sectionsList[sectionIndex]) return;

                sectionsList[sectionIndex] = {...item, spec: values};
                collist[colIndex] = {...currentCol, items: sectionsList};
                rowList[rowIndex] = {...currentRow, items: collist};
            }
        }

        dispatch(setRow({...item, spec: values}));
        dispatch(setLayoutRows(rowList));
    };

    const renderCommonField = (effectType, localId, form, specState, runSave, fieldCol, width, key, container) => {
        //console.log('render renderCommonField')
        let field = deepCopyObject(fieldCol);
        const depend_result = CheckFieldDependsOn(field, specState);

        // if (effectType !== 'block/style') console.log('spec', depend_result, field, specState)

        // nodes field
        // field = handleFieldSpecValues(t, store, field);

        return <Col className={depend_result ? '' : 'hide'} span={width} key={key}>
            <EffectElement
                t={t}
                index={key}
                form={form}
                admin={store['admin']}
                project={store['project']}
                field={field}
                localId={localId}
                effectType={effectType}
                spec={specState}
                runSave={runSave}
                container={container}
                section="layout"
            />
        </Col>
    }

    const renderField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            'block/settings',
            item.id,
            form,
            item.spec,
            onBlur,
            fieldCol,
            width,
            key,
            'row-settings'
        );
    }

    const renderDrawerTitle = (localeConst, itemId) => {
        return <span>
            {t(localeConst)} #<span className="copy-on-click">{itemId}</span>
        </span>
    }

    let fieldsSet = {};
    if (itemType) {
        fieldsSet = {fields: [...fieldsMap[itemType].fields]};
        if (item.type === 'box') {
            fieldsSet.fields[0] = [{
                name: 'main',
                type: 'switcher',
            }]
        }
    }

    return (
        <Drawer
            title={renderDrawerTitle('block.drawer.title.' + itemType, item.id)}
            className={`row-settings ${classes.drawer}`}
            open={item.id}
            onClose={onClose}
            width={570}
        >
            <Scrollbar>
                <div id="row-settings" className="padding_default">
                    {renderContainerForm(
                        page.id,
                        item.id,
                        form,
                        item.spec,
                        fieldsSet,
                        renderField
                    )}
                </div>
            </Scrollbar>
        </Drawer>
    )
}