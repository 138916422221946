import React from 'react';
import {Select} from "antd";
const {Option} = Select;

export const OptionsFromObject = ({fieldName = '', items = []}) => {

    let optionsList = [];
    for (const [key, value] of Object.entries(items)) {
        optionsList.push(
            <Option value={key} key={`select-${fieldName}-${key}`}>
                {value}
            </Option>
        )
    }
    return optionsList;
};

