import React from "react";
import {L} from "../components/Locale/Locale";
// import {useTranslation} from "react-i18next";

// export const Lang = (path) => {
//     const {t} = useTranslation();
//     return t(path);
// }

export const Lang = (path) => {
    return (<L path={path}/>)
}

export const l = (path) => {
    return Lang(path)
}

// localized form item texts
export const label = (section, name, place = 'label') => {
    return l(section + '.form.' + name + '.' + place)
}