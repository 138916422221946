import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addEmployeeToList,
    fillEmployeeList,
    patchEmployeeInList,
    removeEmployeeFromList,
    setEmployeeItem,
    setEmployeeListCount,
} from "../reducers/EmployeeReducer";

// init api methods
const base_path = backend_api.employee;

function* getEmployeeList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillEmployeeList(response.data.result))
            yield put(setEmployeeListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getEmployeeItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setEmployeeItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createEmployee(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addEmployeeToList(response.data.result))
            yield put(setEmployeeItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateEmployee(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchEmployeeInList(response.data.result))
            yield put(setEmployeeItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyEmployee(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setEmployeeItem(response.data.result))
            yield put(addEmployeeToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreEmployee(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeEmployeeFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteEmployee(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeEmployeeFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* employeeSaga() {
    yield takeEvery('getEmployeeList', getEmployeeList);
    yield takeEvery('getEmployeeItem', getEmployeeItem);
    yield takeEvery('createEmployee', createEmployee);
    yield takeEvery('copyEmployee', copyEmployee);
    yield takeEvery('updateEmployee', updateEmployee);
    yield takeEvery('restoreEmployee', restoreEmployee);
    yield takeEvery('deleteEmployee', deleteEmployee);
}

