import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {
    is,
    inArray,
    ucFirst,
    createObjectFromObjectsArray,
    momentFromUnix,
    unixFromMoment
} from "../../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {SetItemEditFormOrPreloader} from "../../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const ProductEdit = () => {
    const {t} = useTranslation()
    const section = 'product'
    const Section = ucFirst(section)

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // init states
    const [avatarUrl, setAvatarUrl] = useState('')

    // sync with store
    const {admin, project, product, productCategory} = useSelector(store => store)
    const item = product.item
    const project_item = project.item;
    const categories = createObjectFromObjectsArray(productCategory.list);

    // get form and set values
    const [form] = Form.useForm();

    // itis form state
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 10}),
        title: '',
        description: '',
        is_on: true,
        categories: null,
        type: 'unset',
    })

    const formFields = [
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            placeholder: t('common.form.photo_url.placeholder'),
            desc: null,
        },
        {
            name: "title",
            type: "text",
            placeholder: t('common.placeholder.text'),
            required: true,
        },
        {
            name: "price",
            type: "text",
            placeholder: '0.00',
        },
        {
            name: "old_price",
            type: "text",
            placeholder: t('common.placeholder.unset'),
        },
        {
            name: "type",
            placeholder: '',
            type: "menu",
            values: ["unset", "goods", "excisable", "work", "service", "ria", "ticket", "bet", "gambling_win", "lottery_ticket", "lottery_win", "payment", "fee", "broker_fee", "multiple", "other", "license", "warranty", "insurance", "donation"],
            // default: 'json',
            localized: true,
        },
        {
            name: "category_ids",
            type: "tags",
            data: 'productCategory',
            values: categories,
        },
        {
            name: "is_subscription",
            type: "switcher",
        },

        {
            label: t('common.form.additional.label'),
            desc: t('common.form.additional.desc'),
            name: "additional",
            type: "heading",
        },
        {
            name: "expire_at",
            type: "datetime",
            format: 'DD MMMM YYYY HH:mm:ss',
        },
        {
            name: "sku",
            type: "text",
            placeholder: true,
        },
        {
            name: "author_user_id",
            placeholder: '0',
            type: "text",
        },
        {
            name: "manager_user_id",
            placeholder: '0',
            type: "text",
        },
        // {
        //     name: "params",
        //     type: "textarea",
        // },

        {
            name: "is_on",
            type: "switcher",
        },
        {
            name: "description",
            type: "editor",
            form: form,
            extended: false,
            formValues: formValues,
            desc: '',
        },
    ]

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !productCategory.list.length) {
            dispatch({type: 'getProductCategoryList', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, id, project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let item_object = {...item}
            if (!item.category_ids) item_object.category_ids = []
            else item_object.category_ids = item.category_ids.map(item => String(item))

            if (item.expire_at) item_object.expire_at = momentFromUnix(item.expire_at)
            else item_object.expire_at = ''

            form.setFieldsValue(item_object)
            setFormValues(item_object)
            setAvatarUrl(item.photo_url) // for upload field
        }
        //eslint-disable-next-line
    }, [item])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_item.id}/${section}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        // if (values.params) {
        //     try {
        //         values.params = JSON.parse(values.params)
        //         if (typeof values.params !== 'object') {
        //             notice.error(t(section + '.error.json_parse'))
        //             return false;
        //         }
        //     }
        //     catch (e) {
        //         notice.error(t(section + '.error.json_parse'))
        //         return false;
        //     }
        // } else {
        //     values.params = null
        // }
        if (values.expire_at) values.expire_at = unixFromMoment(values.expire_at)
        else values.expire_at = null

        if (!is(values.photo_url)) values.photo_url = null

        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    let formFieldsResult = [...formFields]
    if (!item.id) formFieldsResult = formFields.filter((object) => !inArray(object.name, ['short_url']))

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFieldsResult}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default ProductEdit