import React from 'react'
import {createMarkup, is} from "../../../../../../library/functions";
import {l} from "../../../../../../library/locale";
import Fa from "../../../../../../components/Icon/Fa";

export const BlockHeading = (spec, params, classes, styles) => {
    if (!spec) return null;
    const paramStyles = params.style || {};

    let props = {};
    let classNames = ['heading'];

    let content = spec.content;

    if (!content) return <em className={classNames.join(' ')}>
        {l('container.core.text.heading.empty')}
    </em>;

    // replace html system code to html entities:
    content = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');

    // convert textarea value to html
    content = content.replace('\n', '<br/>');

    if (!paramStyles.text || !paramStyles.text.size) classNames.push('text-' + spec.level)
    if (!paramStyles.text || !paramStyles.text.weight) classNames.push('font-medium')

    if (is(classes)) classNames.push(...classes);
    if (is(styles)) props['style'] = styles;

    props['dangerouslySetInnerHTML'] = createMarkup(content);

    if (spec.level === 'h1') return <h1 className={classNames.join(' ')} {...props} />
    if (spec.level === 'h2') return <h2 className={classNames.join(' ')} {...props} />
    if (spec.level === 'h3') return <h3 className={classNames.join(' ')} {...props} />
    if (spec.level === 'h4') return <h4 className={classNames.join(' ')} {...props} />
    else return <div className={classNames.join(' ')} {...props} />
}

export const BlockDesc = (spec) => {
    if (!spec) return null;
    return <div dangerouslySetInnerHTML={createMarkup(spec.content)}/>
}

export const BlockTextIcon = (spec) => {
    if (!is(spec)) return null;

    let icon = spec.icon || {}
    const classes = spec.classes || {}

    let iconClassname = null;
    if (classes.icon) iconClassname = classes.icon.join(' ');

    let titleClasses = ['c-title']
    if (classes.title) titleClasses.push(...classes.title)

    let descClasses = ['c-desc']
    if (classes.desc) descClasses.push(...classes.desc)

    return <>
        {icon.name ? <div className="c-icon">
            <Fa
                icon={icon.name}
                set={icon.set ?? 'regular'}
                wcn={iconClassname}
            />
        </div> : null}
        <div className="c-content">
            <h3 className={titleClasses.join(' ')}>{spec.title}</h3>
            <div className={descClasses.join(' ')} dangerouslySetInnerHTML={createMarkup(spec.content)}/>
        </div>
    </>
}