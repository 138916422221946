import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import classes from "./Keyboard.module.scss"
import {ItemTypes} from "./Keyboard";
import Fa from "../../../../../../../components/Icon/Fa";

export const KeyboardButton = ({id, card, index, rowIndex, moveCard, setDragging, onButtonClick}) => {
    const ref = useRef(null)

    // can drop to this item place
    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.button,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            // console.log('Drop hover')

            // get drag (source) item
            const oldIndex = item.index;
            const oldRow = item.rowIndex;

            // get drop (target) item
            const newIndex = index;
            const newRow = rowIndex;

            // Don't replace items with themselves
            if (oldIndex === newIndex && oldRow === newRow) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // console.log('clientOffset', clientOffset)

            // Get pixels to the top
            const hoverClientX = clientOffset.x - hoverBoundingRect.left
            // Only perform the move when the mouse has crossed half of the items width
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging right
            if (oldIndex < newIndex && hoverClientX < hoverMiddleX) {
                return
            }

            // Dragging left
            if (oldIndex > newIndex && hoverClientX > hoverMiddleX) {
                return
            }

            // Time to actually perform the action
            moveCard(item.id, oldIndex, oldRow, newIndex, newRow)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = newIndex
        },
    })

    // can drag this item
    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.button,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),

        // run on dragging start
        item: () => {
            // $('body').classList.add('cursor-grabbing') // not working :(
            setDragging(true);

            // the goal is to compose and store item data
            return {id, index, rowIndex}
        },

        // run on dragging stop
        end: (item, monitor) => {
            // $('body').classList.remove('cursor-grabbing')
            setDragging(false);
            return item
        },
    })

    // set old item invisible (sort style)
    const opacity = isDragging ? 0.25 : 1

    // I don't know what is it, but it is important
    drag(drop(ref))

    const onClick = () => {
        onButtonClick(card, index, rowIndex)
    }

    // console.log('button card', card)

    let icon = null;
    let color = card.color;

    // handle non-text types
    if (card.type !== 'text') color = 'default'
    if (card.type === 'url')  icon = <Fa icon="arrow-up-right-from-square" className="margin-right-xp" set="regular"/>
    if (card.type === 'request')  icon = <Fa icon="user-magnifying-glass" className="margin-right-xp" set="regular"/>
    if (card.type === 'app')  icon = <Fa icon="grid-2-plus" className="margin-right-xp" set="regular"/>

    return (
        <div
            ref={ref}
            className={`ant-btn ant-btn-primary btn-borderless btn-${color} ${classes.button}`}
            style={{opacity}}
            data-handler-id={handlerId}
            onClick={onClick}
        >
            <span>{icon}{card.label}</span>
        </div>
    )
}
