import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {dispatchItemData} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {
    createObjectFromObjectsArray,
    filterArrayByFieldValue,
    inArray,
    isArr
} from "../../library/functions";
import {useTranslation} from "react-i18next";
import {label} from "../../library/locale";
import {notice} from "../../library/notice";


const getFormValues = (components, items) => {
    let result = {
        graph: [],
        glob: [],
        webhook: [],
        field: {
            field: [],
            list: [],
            currency: [],
            achievement: [],
            resource: [],
        }
    };
    // console.log('items', items)
    for (const component of components) {
        // console.log('component', component)
        if (component.component_type === 'field') {
            for (const storage in result.field) {
                if (items[storage][component.component_id]) {
                    result.field[storage].push(String(component.component_id))
                }
            }
        } else {
            result[component.component_type].push(String(component.component_id))
        }
        // if component.component_id
    }
    return result;
}

const convertValuesToComponents = (type = '', result = [], values = []) => {
    if (!type) return result

    for (const value of values) {
        result.push({component_type: type, component_id: parseInt(value)})
    }
    return result;
}

const TemplateComponents = () => {
    const {t} = useTranslation();
    const section = 'template'
    const adminSection = 'template.component'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, template, field, graph, glob, webhook} = useSelector(store => store)
    const project_item = project.item
    const item = template.item
    const list = template.components

    // itis form state
    let emptyFormValues = {}
    const [formValues, setFormValues] = useState({})

    // get field lists by types
    const fields = field.list.filter((object) => inArray(object.system_field_type, ['crm', 'form']));
    const lists = filterArrayByFieldValue(field.list, 'system_field_type', 'list')
    const currencies = filterArrayByFieldValue(field.list, 'system_field_type', 'currency')
    // const moneys = filterArrayByFieldValue(field.list, 'system_field_type', 'money')
    const achievements = filterArrayByFieldValue(field.list, 'system_field_type', 'achievement')
    const resources = filterArrayByFieldValue(field.list, 'system_field_type', 'resource')

    const field_desc = label(adminSection, 'field.field', 'desc')
    const items = {
        graph: createObjectFromObjectsArray(graph.list),
        glob: createObjectFromObjectsArray(graph.list),
        webhook: createObjectFromObjectsArray(webhook.list),

        field: createObjectFromObjectsArray(fields),
        list: createObjectFromObjectsArray(lists),
        currency: createObjectFromObjectsArray(currencies),
        // money: createObjectFromObjectsArray(moneys),
        achievement: createObjectFromObjectsArray(achievements),
        resource: createObjectFromObjectsArray(resources),
    }

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "graph",
            type: "tags",
            values: createObjectFromObjectsArray(graph.list),
        },
        {
            name: "clone",
            type: "heading",
        },
        {
            name: "field.list",
            type: "tags",
            values: createObjectFromObjectsArray(lists),
            desc: field_desc,
            features: ['creating'],
            data: 'field',
            data_filters: {system_field_type: 'list', data_type_admin: 'int'},
        },
        {
            name: "field.field",
            type: "tags",
            values: items.field,
            desc: field_desc,
            // features: ['creating'],
            // data: 'field',
            // data_filters: {system_field_type: 'crm', data_type_admin: 'text'},
        },
        {
            name: "glob",
            type: "tags",
            values: createObjectFromObjectsArray(glob.list),
            features: ['creating'],
            data: 'glob',
        },
        {
            name: "webhook",
            type: "tags",
            values: createObjectFromObjectsArray(webhook.list),
            features: ['creating'],
            data: 'webhook',
        },
        {
            name: "gamification",
            type: "heading",
        },
        {
            name: "field.currency",
            type: "tags",
            values: items.currency,
            desc: field_desc,
            data: 'field',
            features: ['creating'],
            data_filters: {system_field_type: 'currency', data_type_admin: 'int'}
        },
        // {
        //     name: "field.money",
        //     type: "tags",
        //     values: items.money,
        //     desc: field_desc,
        //     data: 'field',
        //     features: ['creating'],
        //     data_filters: {system_field_type: 'money', data_type_admin: 'float'}
        // },
        {
            name: "field.achievement",
            type: "tags",
            values: items.achievement,
            desc: field_desc,
            data: 'field',
            features: ['creating'],
            data_filters: {system_field_type: 'achievement', data_type_admin: 'int'}
        },
        {
            name: "field.resource",
            type: "tags",
            values: items.resource,
            desc: field_desc,
            data: 'field',
            features: ['creating'],
            data_filters: {system_field_type: 'resource', data_type_admin: 'int'}
        },
    ]

    // get data from API first
    useLayoutEffect(() => {
        // get title and folder
        dispatchItemData(dispatch, admin, project_item, section, id)

        if (admin.authorized && project_item.id) {
            // se common filters for all lists
            const filters = {project_id: project_item.id, ordering: '-created_at'}
            // get fields values
            dispatch({type: 'getFieldList', admin, filters})
            dispatch({type: 'getGraphList', admin, filters})
            dispatch({type: 'getGlobList', admin, filters})
            dispatch({type: 'getWebhookList', admin, filters})
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    useLayoutEffect(() => {
        if (admin.authorized && project_item.id) {
            // console.log('admin.authorized', admin.authorized)
            dispatch({type: 'getTemplateComponents', admin, filters: {template_id: id}})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    useLayoutEffect(() => {
        if (id && item.id === id && item.parent_id) {
            notice.warning(t('template.error.installed.components'))
            backToList();
        }

        //eslint-disable-next-line
    }, [item])

    // set field values to FORM when correct data received
    useEffect(() => {
        const values = getFormValues(list, items)
        // console.log('useEffect form values', values, list)
        if (id && item.id === id) {
            form.setFieldsValue(values)
            setFormValues(values)
        } else form.setFieldsValue(emptyFormValues)
        //eslint-disable-next-line
    }, [list])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        // console.log('values', values);

        let result = []
        for (const [name, value] of Object.entries(values)) {
            if (isArr(value)) {
                // console.log('arr value', value)
                convertValuesToComponents(name, result, value)
            } else if (typeof value === 'object') {
                // console.log('object value', value)
                for (const [fieldName, fieldValue] of Object.entries(value)) {
                    convertValuesToComponents(name, result, fieldValue)
                }
            }
        }

        // console.log('result', result);
        dispatch({
            type: 'setTemplateComponents', admin, data: {
                template_id: item.id,
                items: result,
            }
        });
        // and return to list
        backToList();
    }

    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/${section}?folder=${item.folder_id}`);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels generation
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                    formTitle={t(section + '.item.components') + ': ' + item.title}
                />
            </Layout>
        </AppWrapper>
    )
}

export default TemplateComponents