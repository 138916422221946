import React, {useRef} from 'react'
import classes from "./Keyboard.module.scss"
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";

export const KeyboardAddButton = ({onClick}) => {
    const {t} = useTranslation();

    return (
        <>
            <Button
                type="text"
                className={`inverted full-width ${classes.add}`}
                icon={<PlusOutlined/>}
                onClick={onClick}
            > {t('graph.flow.keyboard.button.add.label')} </Button>
        </>
    )
}
