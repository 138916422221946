import React from 'react'
import {useTranslation} from "react-i18next";
import {nodeTypesProps} from "../../../../library/flowFunctions";
import Header from "./Header";

const CatalogHeader = ({type}) => {
    const {t} = useTranslation();
    const nodeIcon = nodeTypesProps[type].icon;
    return <Header type={type} title={t('graph.flow.catalog.' + type + 's')} icon={nodeIcon} showBackToDefault={true}/>
}

export default CatalogHeader