import React, {useEffect, useState} from 'react'
import {Button, Form, Layout, PageHeader, Tabs} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import cryptoRandomString from "crypto-random-string";
import {
    createObjectFromObjectsArray,
    isMainProject,
    ucFirst,
    deepGet,
    toNum,
    downloadFileFromText,
} from "../../../library/functions";
import {dotenv, routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, handleContainers} from "../../../library/containers";
import {useTranslation} from "react-i18next";
import {useHotkeys} from "react-hotkeys-hook";
import {notice} from "../../../library/notice";
import {
    ArrowLeftOutlined,
    BgColorsOutlined,
    CheckOutlined,
    DownloadOutlined,
    LaptopOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {FormFields} from "../../../components/Form/Field/FormFields";
import {FormSubmit} from "../../../components/Form/FormSubmit";
import {setLayoutItem} from "../../../redux/reducers/LayoutReducer";
import {setLayoutRows} from "../../../redux/reducers/BlockReducer";
import {LayoutMaker} from "../Layout/LayoutMaker";

const {Content, Header} = Layout;

let layoutLocalIds = {
    pre: 4,
    cond: 8,
    post: 12,
    layout: -1,
}

const ThemeEdit = () => {
    const {t} = useTranslation()
    const section = 'theme'
    const Section = ucFirst(section);
    const adminSection = section;
    const categorySection = 'site'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)
    const project_id = Number(params.project_id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder
    // let site_id = searchParams.get('site_id')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, theme, site, pb, folder} = useSelector(store => store)
    const item = theme.item
    const project_item = project.item;
    const layoutRows = pb.layout;
    // const categoryList = site.list || []
    // const categories = createObjectFromObjectsArray(categoryList);

    // init states
    const [tab, setTab] = useState(id ? "styles" : "settings")
    const [avatarUrl, setAvatarUrl] = useState('')
    const [folderId, setFolderId] = useState(0)
    const [formValues, setFormValues] = useState({
        title: '',
        css: '',
        framework: '',
        name: cryptoRandomString({length: 10}),
        icon_name: 'table-layout',
        // site_id: site_id || null,
        is_on: true,
        params: {
            folder_id: folder_id,
            style: {
                theme: 'slate',
                dark: false,
            }
        }
    })

    // get form and set values
    const [form] = Form.useForm();
    const [styleForm] = Form.useForm();

    const addStyles = (value) => {
        // add new styles to form.css value end:
        let oldValue = formValues.css;
        if (oldValue) oldValue += '\n\n';
        let newValue = value;
        // remove ```scss or ``` or any non CSS data:
        newValue = newValue.replace(/```(scss|css)?/g, '');
        setFormValues({...formValues, css: oldValue + newValue})
        styleForm.setFieldsValue({css: oldValue + newValue})
    }

    const isMainProj = isMainProject(project_item.id, project);
    const formFields = [
        {
            placeholder: t('common.placeholder.text'),
            name: "title",
            type: "text",
            required: true,
        },
        {
            name: "name",
            type: "text",
            label: t('common.form.name.label'),
            desc: t('common.form.name.desc'),
            placeholder: t('common.form.name.placeholder'),
            required: true,
        },
        // {
        //     name: "site_id",
        //     type: "menu",
        //     data: 'site',
        //     values: categories,
        // },
        {
            name: "params.folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
        {
            name: "is_on",
            type: "switcher",
            label: t('common.form.is_on.label'),
        },
        {
            name: "heading.list",
            type: "heading",
        },
        {
            name: "icon_name",
            type: "icon",
            placeholder: '',
            // section: 'common',
        },
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            placeholder: t('common.form.photo_url.placeholder'),
            isVisible: isMainProj,
            aspect: 1.2
        },
    ]
    const styleFields = [
        // {
        //     name: "heading.theme",
        //     type: "heading",
        // },
        // {
        //     name: "params.style.theme",
        //     type: "menu",
        //     placeholder: '',
        //     values: [
        //         'slate',
        //         'neutral',
        //     ],
        //     localized: true,
        // },
        // {
        //     name: "params.style.dark",
        //     type: "switcher",
        // },

        {
            name: "styles",
            type: "heading",
        },
        {
            name: "css",
            type: "code",
            form: styleForm,
            language: 'scss',
            placeholder: 'body {font-size: 16px;}',
            section: section,
        },
        {
            name: "generate_css",
            type: "ai",
            section: 'page',
            form: form,
            admin: admin,
            project_id: project_item.id,
            formValues: formValues,
            prompt: 'You are React page form code assistant. Answer ONLY with SCSS code to add it to form field value! Any necessary explanation write as a comments! If I asking about something else - just skip it! Newer add any other text which is not SCSS code.',
            onFinish: addStyles,
        },

        {
            name: "framework",
            type: "code",
            form: styleForm,
            language: 'scss',
            placeholder: '.prose {font-size: 1rem;}',
            section: section,
        },
    ]

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        // if (admin.authorized && project_item.id && !categoryList.length) {
        //     dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}});
        // }

        // if (admin.authorized && project_item.id && !page.short.list.length) {
        //     dispatch({type: 'getPageShortList', admin, filters: {project_id: project_item.id}});
        // }

        //eslint-disable-next-line
    }, [admin.authorized && project_item.id, id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}
            const valuesParams = values.params || {}
            if (!item.parent_id) values.parent_id = null

            if (values.params === null) values.params = {}
            // values.site_id = values.site_id ? String(values.site_id) : null;

            form.setFieldsValue(values);
            styleForm.setFieldsValue({css: values.css, framework: values.framework});

            setAvatarUrl(item.photo_url) // for upload field
            if (values.folder_id !== undefined) setFolderId(deepGet(valuesParams, ['folder_id'], 0))
            setFormValues(values)

            dispatch(setLayoutRows(values.layout || []))
            layoutLocalIds.layout = deepGet(valuesParams, ['nextBlockId'], -1)
        }
        else if (item.id && item.id !== id) {
            console.info('Reset layout item')
            dispatch(setLayoutItem({}));
        }

        return () => {
            // reset Local IDs to defaults
            layoutLocalIds = {
                pre: 4,
                cond: 8,
                post: 12,
                layout: -1,
            }

            // and reset layout rows too
            dispatch(setLayoutRows([]))
        }
        //eslint-disable-next-line
    }, [item])

    useHotkeys(['ctrl+s', 'command+s'].join(','), e => {
        e.preventDefault();
        // console.log('useHotkeys', e.code);

        if (!id) return;

        // run key code actions
        switch (e.code) {
            case 'KeyS':
                onFinish(false);
                break;

            default:
                break;
        }
    }, [item, form, layoutRows, styleForm]);

    // compose form functions
    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        let url = `${routes.project_list}/${project_id}/${adminSection}?folder=${folder_id}`
        // if (site_id) url += `&site_id=${site_id}`
        navigate(url);
    }

    const handleLayoutRows = (inputRows) => {
        let rows = [];

        for (const rowData of inputRows) {
            let row = {...rowData};
            row.items = [];

            for (const colData of rowData.items) {
                let col = {...colData};
                col.items = [];

                for (const sectData of colData.items) {
                    col.items.push({
                        ...sectData,
                        items: handleContainers(sectData.items),
                    });
                }

                row.items.push(col);
            }

            rows.push(row);
        }

        return rows;
    }

    const composeItem = () => {
        let values = form.getFieldsValue();
        let stylesValues = styleForm.getFieldsValue();

        if (!values.title || !values.name) {
            notice.error(t('error.validation.fill_required'));

            for (const key of ['title', 'name']) {
                if (!values[key]) form.setFields([{name: key, errors: [t('error.validation.required')]}]);
            }

            if (tab !== 'settings') setTab('settings');
            return false;
        }

        // logic components
        values['css'] = stylesValues.css;
        values['framework'] = stylesValues.framework;

        if (layoutRows) values['layout'] = handleLayoutRows(layoutRows);

        if (!values.params) values.params = {};
        values.params['nextBlockId'] = layoutLocalIds.layout;

        // values.site_id = toNum(values.site_id);
        return values;
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (back = true) => {
        const values = composeItem();
        if (!values) return;

        formCreateOrUpdate(Section, values, null, id, project_item, admin, dispatch, true, !back)
        if(back) backToList(parseInt(values.params.folder_id))
    }

    const downloadItem = () => {
        const values = composeItem();
        if (!values) return;

        const exportFields = [
            // 'id',
            // 'project_id',
            // 'site_id',
            'title',
            'name',
            'type',
            'is_on',
            'layout',
            'icon_name',
            'photo_url',
            'params',
            'framework',
            'css',
        ]

        let itemData = {};
        for (const field of exportFields) {
            itemData[field] = values[field];
        }

        const itemDataJson = JSON.stringify(itemData);
        const fileName = dotenv.app_name + '-' + section + '-' + item.id + '-' + Date.now();
        downloadFileFromText(itemDataJson, fileName, 'json');
    }

    const getNewBlockId = () => {
        const resultId = layoutLocalIds.layout;
        layoutLocalIds.layout = resultId - 1;
        return resultId;
    }

    const renderSettings = () => {
        if (!formFields.length) return null;

        return (<Form
            form={form}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_settings'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={formFields} formValues={formValues}/>
        </Form>);
    }

    const renderStyles = () => {
        if (!formFields.length) return null;

        return (<Form
            form={styleForm}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_settings'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={styleFields} formValues={formValues}/>
        </Form>);
    }

    const renderLayout = () => {
        return (<LayoutMaker
            t={t}
            id={id}
            item={item}
            section={section}
            getNewId={getNewBlockId}
            isOn={tab === 'layout'}
            saveItem={() => onFinish(false)}
            types={{
                row: 'line',
                col: 'set',
                sec: 'box',
            }}
        />);
    };

    return (
        <AppWrapper adaptivePanel={tab !== 'layout'}>
            <Layout className="site-layout site-layout-background">
                <Header className="site-layout-background page-container-horizontal-padding has-banner">
                    <PageHeader
                        title={item.id ?
                            (item.id && item.id === id ?
                                item.title : t('common.action.edit') + ' ' + t(section + '.object.title')) :
                            t('common.action.add') + ' ' + t(section + '.object.title')}
                        className="padding-none-horizontal"
                        extra={[
                            // back
                            <Button
                                key="return-button"
                                className="hidden-sm"
                                type="text"
                                icon={<ArrowLeftOutlined/>}
                                onClick={backToList}
                            >
                                {t('common.action.back')}
                            </Button>,

                            // export
                            <Button
                                key="export-button"
                                type="text"
                                icon={<DownloadOutlined/>}
                                onClick={downloadItem}
                            >
                                <span className="hidden-sm"> {t('common.action.export')}</span>
                            </Button>,

                            // save
                            <Button.Group key="primary-set" className="rounded-items-sm">
                                {id ? <Button
                                    key="save-button"
                                    type="primary"
                                    icon={<CheckOutlined/>}
                                    onClick={() => {
                                        onFinish(false);
                                    }}
                                /> : null}
                                <Button key="primary-button" type="primary" htmlType="submit" onClick={onFinish} className="hidden-sm">
                                    <span className="hidden-sm-important">{t('common.action.save')}</span>
                                </Button>
                            </Button.Group>,
                        ]}
                    />
                </Header>

                <Content
                    id="effects-list-wrapper"
                    className="page-container form-container site-layout-background padding-top-none"
                >
                    <Tabs
                        defaultActiveKey={tab}
                        onChange={setTab}
                        // size="large"
                        // className="margin-bottom-sm"
                        items={[
                            {
                                label: <span>
                                    <BgColorsOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.styles')}</span>
                                </span>,
                                key: 'styles',
                                // children: renderFunctions(),
                            },
                            {
                                label: <span>
                                    <LaptopOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.layout')}</span>
                                </span>,
                                key: 'layout',
                                // children: renderStyles(),
                            },
                            {
                                label: <span>
                                    <SettingOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.settings')}</span>
                                </span>,
                                key: 'settings',
                                // children: renderSettings(),
                            },
                        ]}
                    />

                    <div className={tab === 'styles' ? null : 'hide'}>{renderStyles()}</div>
                    <div className={tab === 'layout' ? null : 'hide'}>{renderLayout()}</div>
                    <div className={tab === 'settings' ? null : 'hide'}>{renderSettings()}</div>

                    <FormSubmit onSubmit={onFinish} onCancel={backToList} className="margin-top-pm"/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default ThemeEdit