import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import classes from './EventContainer.module.scss';
import AccordPanel from "../AccordPanel/AccordPanel";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getConditions, renderSwitchAndOr} from "./EventContainer";
import {condition_jump_effect} from "../../../../../../schemas/frontend/effects";
import FlowContext from "../../../../FlowContext";
import {getFullEffectByType} from "../../../../../../library/effects";

const ConditionContainer = (props) => {
    const {nodeLocalId, localId, title, type, spec, conditions, isCollapsed, isIgnore, containerIndex} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {updateNodeEffect, updateNodeEffectCondition, deleteEffectCondition, setControlPanel, setChosenContainerId} = useContext(FlowContext);
    const switchAndOrValue = (spec.mode === 'or') ? 'or' : 'and';

    let icon = condition_jump_effect.icon;
    let effect = null;

    if (type !== condition_jump_effect.type) {
        effect = getFullEffectByType(type)
        if (effect && effect.icon) icon = effect.icon;
    }

    const choiceCondition = () => {
        setChosenContainerId(localId);
        setControlPanel('conditions');
    }

    return (
        <>
            <AccordPanel
                effectType={type === condition_jump_effect.type ? 'condition' : type}
                nodeLocalId={nodeLocalId}
                localId={localId}
                icon={icon}
                title={title}
                isCollapsed={isCollapsed}
                isIgnore={isIgnore}
                containerIndex={type === condition_jump_effect.type ? containerIndex : null}
                showContextButton={false}
                empty={false}
                sorting={false}
            >
                {renderSwitchAndOr(t, conditions, switchAndOrValue, nodeLocalId, localId, updateNodeEffect)}
                {getConditions(dispatch, t, nodeLocalId, conditions, localId, updateNodeEffectCondition, deleteEffectCondition, switchAndOrValue)}
                <div className={classes.buttons_conditions}>
                    <Button className={classes.add_cond_btn} type="text" icon={<PlusOutlined/>} onClick={choiceCondition}>
                        {t('common.button.condition')}
                    </Button>
                </div>
            </AccordPanel>
        </>
    )
}

export default ConditionContainer