import {useState} from "react";
import {Form, Modal, Upload} from 'antd';
import axios from 'axios';
import ImgCrop from 'antd-img-crop';
import {backend_api} from "../../../config/config";
import {getBase64, uploadFilePreHandle} from "../../../library/forms";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {deepCopyObject, deepGet, deepSet, formRequiredRules, isArr,} from "../../../library/functions";
import {getName, SetFieldInfo} from "./FormFields";
import {UploadOutlined} from "@ant-design/icons";

export const ImagesField = (
    {
        t,
        admin,
        project_id,
        form,
        formValues = null,
        section = 'common',
        label = null,
        placeholder = null,
        storeImageUrl = null,
        name = 'photo_url',
        imageType = 'photo',
        required = false,
        limit = 1,
        aspect = 0,
    }
) => {
    const fieldName = getName(name);
    const isCropRequired = aspect > 0;
    const rules = required ? formRequiredRules : null;

    const [previewImage, setPreviewImage] = useState('');

    const defaultListValue = deepGet(formValues, fieldName, []) || [];
    const [fileList, setFileList] = useState(defaultListValue);

    // const formValues = form.getFieldsValue();
    // let fileList = deepGet(formValues, fieldName, []);
    // console.log('fileList', fileList, 'limit', limit)

    // useEffect(() => {
    //     console.log({
    //         'fieldName': fieldName,
    //         'formValues': formValues,
    //         'values': values,
    //         'fileList': fileList,
    //         'formFileList': formFileList,
    //     })
    //
    //     setFileList(formFileList);
    //     //eslint-disable-next-line
    // }, [formFileList.length]);

    // ============ upload image start ============

    const updateFormValue = (newFileList) => {
        if (isArr(newFileList)) {
            // const formValues = form.getFieldsValue();
            // let newFormValues = deepSet(deepCopyObject(formValues), fieldName, newFileList);
            // form.setFieldsValue(newFormValues);
            form.setFieldValue(fieldName, newFileList);
        }
    }

    const handleUpload = async ({file, onSuccess, onError, onProgress}) => {
        const postForm = uploadFilePreHandle(admin, project_id, section, file, imageType, onProgress);
        // console.log('handleUpload file', file)

        try {
            const response = await axios.post(backend_api.file, postForm.data, postForm.config);

            if (!response.data.ok) {
                apiFalseNotice(response.data);
                onError();
            } else {
                const result = response.data.result;

                // Set the status of the uploaded file to 'done'
                file.status = 'done';
                onSuccess(result, file);
                if (storeImageUrl) storeImageUrl(result.url);

                if (isCropRequired) {
                    // Add the uploaded file to the fileList state
                    const updatedFileList = [...fileList, {
                        uid: file.uid,
                        name: file.name,
                        status: file.status,
                        url: result.url,
                    }];

                    setFileList(updatedFileList);
                    updateFormValue(updatedFileList);
                }
            }
        } catch (error) {
            apiErrorNotice(error);
            onError();
        }
    };

    // ============ preview image start ============

    const cleanPreview = () => {
        setPreviewImage('');
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        let fileUrl = file.url || file.preview;
        setPreviewImage(fileUrl);
    };

    // ============ render components ============

    const previewModalComponent = <Modal
        className="modal-clean"
        title={t('common.header.preview')}
        open={!!previewImage}
        footer={null}
        onCancel={cleanPreview}
        onOk={cleanPreview}
    >
        <div>
            <img alt="Preview Image" src={previewImage}/>
        </div>
    </Modal>

    const uploadButton = <div>
        <UploadOutlined style={{fontSize: 24}}/>
        <div className="label">{SetFieldInfo(t, section, name, placeholder, 'placeholder')}</div>
    </div>;

    const uploadProps = {
        maxCount: limit,
        listType: "picture-card",
        customRequest: handleUpload,
        onPreview: handlePreview,
    };

    if (isCropRequired) {
        return (<div className="form-modal-item-wrapper">
            <Form.Item
                rules={rules}
                name={fieldName}
                label={SetFieldInfo(t, section, name, label, 'label')}
            >
                <ImgCrop
                    rotate
                    aspect={aspect}
                    modalTitle={t('modal.imageEdit.title')}
                    modalCancel={t('common.action.cancel')}
                    modalOk={t('common.action.apply')}
                >
                    <Upload
                        {...uploadProps}
                        // it is necessary to set fileList as a state component because of the ImgCrop component
                        fileList={fileList}
                        // also remove event must affect the state
                        onRemove={(file) => {
                            const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
                            setFileList(updatedFileList);
                            updateFormValue(updatedFileList);
                        }}
                    >
                        {fileList.length >= limit ? null : uploadButton}
                    </Upload>
                </ImgCrop>
            </Form.Item>
            {previewModalComponent}
        </div>);
    }

    // ============ else: native upload logic ============

    const getValueFromEvent = (e) => {
        // console.log("getValueFromEvent event", e);
        // work with incorrect data
        if (isArr(e)) return e;
        if (!e.fileList) return [];

        // work with all upload events like: remove, upload, etc - handle file array
        const result = e.fileList.map((file) => {
            if (file.response) file.url = file.response.url;
            return {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url ?? '',
            };
        });

        // update state for limits check
        setFileList(result || []);

        return result;
    };

    return (<div className="form-modal-item-wrapper">
        <Form.Item
            name={fieldName}
            label={SetFieldInfo(t, section, name, label, 'label')}
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
        >
            <Upload {...uploadProps}>
                {fileList.length < limit ? uploadButton : null}
            </Upload>
        </Form.Item>

        {previewModalComponent}
    </div>)
};
