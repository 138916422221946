import React from 'react';
import Fa from "../../Icon/Fa";

export const TableAvatar = ({imageUrl, className, icon, isOn = true, ...props}) => {
    let classes = "image";
    if (className) classes += ' ' + className;
    if (!isOn) classes += ' disabled';
    let style = {}

    if (imageUrl) style.backgroundImage = `url("${imageUrl}")`
    else classes += ' icon';

    const AvatarIcon = ({icon}) => {
        if (!icon) return null

        return <div className="avatar-content">
            <Fa icon={icon} set="regular" options={'lg'}/>
        </div>
    }

    return (
        <div className={classes} style={style} {...props}>
            <AvatarIcon icon={icon}/>
        </div>
    );
};

