import React, {useContext, useEffect, useRef, useState} from 'react'
import classes from './AccordPanel.module.scss'
import {AiOutlineDown} from "react-icons/ai";
import ContainerHeader from "./ContainerHeader";
import Fa from "../../../../../../components/Icon/Fa";
import {useTranslation} from "react-i18next";
import FlowContext from "../../../../FlowContext";
import {notice} from "../../../../../../library/notice";

const AccordPanel = (
    {
        nodeLocalId,
        effectType,
        localId,
        icon,
        brandIcon,
        title,
        containerIndex = 0,
        showContextButton = false,
        isCollapsed = false,
        isIgnore = false,
        empty = false,
        sorting = true,
        children
    }
) => {
    const {t} = useTranslation();
    const contentRef = useRef();
    const [isOpened, setOpened] = useState(!isCollapsed);
    const {updateNodeContainerParams, isBlock, forbidden, forbiddenMessage} = useContext(FlowContext);
    // const {scrollTo, newEffect} = useContext(RightPanelContext);

    const containerTypeArray = effectType.split('/');
    let blockType = containerTypeArray[0];
    // background-color иконки
    if (containerTypeArray.indexOf('message') !== -1) blockType = 'message';
    else if (containerTypeArray.indexOf('timer') !== -1) blockType = 'timer';
    else if (
        containerTypeArray.indexOf('delete') !== -1 ||
        containerTypeArray.indexOf('clear') !== -1 ||
        containerTypeArray.indexOf('block') !== -1 ||
        containerTypeArray.indexOf('remove') !== -1 ||
        containerTypeArray.indexOf('leave') !== -1
    ) blockType = 'delete';
    else if (containerTypeArray.indexOf('condition') !== -1) blockType = 'condition';

    const containerIcon = <div className={classes.container_icon} data-typeblock={isIgnore ? 'disabled' : blockType}>
        {brandIcon ? <Fa
            icon={brandIcon} set={"brands"} options={'inverse'}
        /> : <Fa
            icon={icon} set={"regular"} options={'inverse'}
        />}
    </div>
    let containerTitle = (title) ? title : t(effectType.split('/').join('.') + '.title');
    if (containerIndex && !title) containerTitle += ' № ' + containerIndex;

    useEffect(() => {
        setOpened(!isCollapsed);
        //eslint-disable-next-line
    }, [isCollapsed])

    const changePanelShow = () => {
        const empty = contentRef.current.getAttribute('data-empty');
        if (empty === 'false') {
            const show = contentRef.current.getAttribute('data-show');
            if (show === 'false') {
                setOpened(true);
                contentRef.current.setAttribute('data-show', 'true');
                updateNodeContainerParams(nodeLocalId, localId, {collapsed: false}, false);
            } else {
                setOpened(false);
                contentRef.current.setAttribute('data-show', 'false');
                updateNodeContainerParams(nodeLocalId, localId, {collapsed: true}, false);
            }
        }
    }

    // useEffect(() => {
    //     const el = document.getElementById(`accord_${localId}`)
    //     if (el && newEffect) {
    //         // console.log('+++ accord el.offsetTop', el.offsetTop, newEffect);
    //         //scrollTo(el.offsetTop);
    //     }
    // }, [])

    const onForbiddenClick = () => {
        notice.error(forbiddenMessage ?? t('error.edit.forbidden'));
    }

    return (
        <div
            id={`accord_${localId}`}
            className={`${classes.accordion_panel} ${isBlock ? classes.block : ''} container-collapse`}
            key={`container-collapse-${localId}`}
        >
            <div
                ref={contentRef}
                data-show={`${isOpened}`}
                data-empty={`${empty}`}
                className={`${classes.accordion_panel_header} user-select-none`}
                style={{cursor: empty ? 'default' : ''}}
                onClick={changePanelShow.bind(this)}
            >
                <div
                    className={classes.accordion_panel_header_corner}
                    style={{opacity: empty ? '0' : '', transform: isOpened ? 'rotate(0deg)' : ''}}
                >
                    <AiOutlineDown className={classes.drop_arrow}/>
                </div>
                <div className={classes.accordion_panel_header_icon}>
                    {containerIcon}
                </div>
                <ContainerHeader
                    nodeLocalId={nodeLocalId}
                    effectLocalId={localId}
                    title={containerTitle}
                    type={effectType}
                    showContextControl={showContextButton}
                    isIgnore={isIgnore}
                    sorting={sorting}
                />
            </div>

            {/*<Collapse isOpened={isOpened}>*/}
            <div className={isOpened ? '' : 'hide'}>
                {forbidden ? <div
                        className={classes.restrict_content}
                        title={t('error.edit.forbidden')}
                        onClick={onForbiddenClick}
                    /> : null}

                {empty ? null : <div className={classes.accordion_panel_content}>
                    {children}
                </div>}
            </div>
            {/*</Collapse>*/}
        </div>
    )
}

export default AccordPanel