import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    public: {
        layout: 'unset',
        list: [],
    },
    short: {
        // short list for select fields
        list: [],
        count: 0,
        status: false,
    },
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
    addItemToEnd: false,
    blockSaga: false, // do not allow update page list until item create / update finished
}

const PageReducer = createSlice({
    name: 'page',
    initialState,
    reducers: {
        blockPageSaga(state, action) {
            state.blockSaga = action.payload;
        },
        fillPageList(state, action) {
            state.list = action.payload
        },
        setPagePublicLayout(state, action) {
            state.public.layout = action.payload
        },
        fillPagePublicList(state, action) {
            state.public.list = action.payload
        },
        fillPageShortList(state, action) {
            state.short.list = action.payload
        },
        setPageShortListCount(state, action) {
            state.short.count = action.payload;
        },
        setPageShortListStatus(state, action) {
            state.short.status = action.payload;
        },
        setPageItem(state, action) {
            state.item = action.payload;
        },
        setPageListCount(state, action) {
            state.count = action.payload;
        },
        setPageStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        setPageItemParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state.item[key] = value;
            }
        },
        addPageToList(state, action) {
            if (state.addItemToEnd) state.list = [...state.list, action.payload];
            else state.list = [action.payload, ...state.list];

            // also add to short list:
            state.short.list = [...state.short.list, action.payload];
            state.short.count = state.short.count + 1;

            state.item = action.payload;
            state.count = state.count + 1;
        },
        removePageFromList(state, action) {
            const deleted_item = action.payload;

            state.list = state.list.filter(p => p.id !== deleted_item.id)
            state.count = state.count - 1;

            state.short.list = state.short.list.filter(p => p.id !== deleted_item.id)
            state.short.count = state.short.count - 1;

            if (state.item.id === deleted_item.id) {
                state.item = deleted_item
            }
        },
        reorderPageInSite(state, action) {
            const {item, list} = action.payload;

            const oid = item.id;

            // short list
            const shortList = [...list];
            const shortIndex = shortList.findIndex(o => o.id === oid);

            if (shortIndex !== -1) {
                shortList[shortIndex] = {...shortList[shortIndex], ...item};
                state.short.list = shortList;
            }

            if (state.item.id === oid) {
                state.item = item
            }

            // item
            // state.item = item
        },
        patchPageInList(state, action) {
            const oid = action.payload.id;

            // general list
            const list = [...state.list];
            const index = list.findIndex(o => o.id === oid);

            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }

            // short list
            const shortList = [...state.short.list];
            const shortIndex = shortList.findIndex(o => o.id === oid);

            if (shortIndex !== -1) {
                shortList[shortIndex] = {...shortList[shortIndex], ...action.payload};
                state.short.list = shortList;
            }

            // item
            state.item = action.payload
        },
        resetPageState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default PageReducer.reducer
export const {
    blockPageSaga,
    fillPageList,
    setPageItem,
    reorderPageInSite,
    setPageListCount,
    setPageStoreParam,
    setPageItemParam,
    patchPageInList,
    addPageToList,
    removePageFromList,
    fillPageShortList,
    setPageShortListCount,
    setPageShortListStatus,
    fillPagePublicList,
    setPagePublicLayout,
    resetPageState,
} = PageReducer.actions