import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {
    createObjectFromObjectsArray,
    inArray,
    momentFromUnix,
    objectLength,
    ucFirst,
    unixFromMoment
} from "../../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {SetItemEditFormOrPreloader} from "../../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const EmployeeEdit = () => {
    const {t} = useTranslation()
    const section = 'employee'
    const Section = ucFirst(section)

    // data from URL params
    const params = useParams()
    const id = Number(params.id)
    const project_id = Number(params.project_id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, employee} = useSelector(store => store)
    const item = employee.item
    const project_item = project.item;

    // get form and set values
    const [form] = Form.useForm();

    // itis form state
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 10}),
        title: '',
        description: '',
        is_on: true,
        categories: null,
        type: 'unset',
    })

    const formFields = [
        // {
        //     name: "params",
        //     type: "textarea",
        // },

        {
            name: "is_on",
            type: "switcher",
            desc: '',
        },
        {
            name: "description",
            type: "editor",
            form: form,
            extended: false,
            formValues: formValues,
            desc: '',
            label: '',
        },
    ]

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        //eslint-disable-next-line
    }, [admin.authorized, id, project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let item_object = {...item}
            if (!item.category_ids) item_object.category_ids = []
            else item_object.category_ids = item.category_ids.map(item => String(item))

            if (item.expire_at) item_object.expire_at = momentFromUnix(item.expire_at)
            else item_object.expire_at = ''

            form.setFieldsValue(item_object)
            setFormValues(item_object)
        }
        //eslint-disable-next-line
    }, [item])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_id}/${section}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        // if (values.params) {
        //     try {
        //         values.params = JSON.parse(values.params)
        //         if (typeof values.params !== 'object') {
        //             notice.error(t(section + '.error.json_parse'))
        //             return false;
        //         }
        //     }
        //     catch (e) {
        //         notice.error(t(section + '.error.json_parse'))
        //         return false;
        //     }
        // } else {
        //     values.params = null
        // }
        if (values.expire_at) values.expire_at = unixFromMoment(values.expire_at)
        else values.expire_at = null

        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    let formFieldsResult = [...formFields]
    if (!id) backToList()

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFieldsResult}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default EmployeeEdit