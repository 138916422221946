import {
    deepCopyObject,
    deepGet,
    getRandomInt,
    inArray,
    isArr,
    stripHtmlTags
} from "../../../library/functions";
import {
    condition_jump_effect,
    popularEffectsTypes,
    graph_jump_effect
} from "../../../schemas/frontend/effects";
import {createSpecFromFields, getFullEffectByType} from "../../../library/effects";

import {
    ThunderboltOutlined,
    BranchesOutlined,
    ClockCircleOutlined,
    MessageOutlined,
    SettingOutlined,
    FormOutlined,
} from "@ant-design/icons";
import {notice} from "../../../library/notice";
import {popularConditionTypes} from "../../../schemas/frontend/conditions";
import {upd} from "../../../library/immutable";

// ======= lib constants =======

const graphParams = ['graphId', 'localIdNext'];
const edgeParams = {
    'localId': 'id',
    'srcNode': 'source',
    'srcPort': 'sourceHandle',
    'dstNode': 'target',
    'dstPort': 'targetHandle',
};
const nodeRequiredParams = {
    'localId': 'id',
    'type': 'type',
};

export const nodeTypesProps = {
    event: {icon: <ThunderboltOutlined/>},
    condition: {icon: <BranchesOutlined/>},
    message: {icon: <MessageOutlined/>},
    timer: {icon: <ClockCircleOutlined/>},
    action: {icon: <SettingOutlined/>},
    note: {icon: <FormOutlined/>},
}


// ======= common lib =======

const arraySwap = (obj) => {
    let ret = {};
    for (let key in obj) {
        ret[obj[key]] = key;
    }
    return ret;
}

const showGraphError = (message, variable) => {
    const errorPrefix = 'Graph process error:';
    console.error(errorPrefix, message, variable);
}

export const getNodeTypes = () => {
    // get all nodeTypes
    let nodeTypes = Object.keys(nodeTypesProps);

    // move message to start
    const note = nodeTypes.pop();
    return [note, ...nodeTypes];
}

export const findIndexByLocalId = (array, localId) => {
    const localIdInt = parseInt(localId);
    return array.findIndex(element => element && element.localId === localIdInt);
}

export const findIndexByFlowId = (array, localId) => {
    const localIdStr = String(localId);
    return array.findIndex(element => element && element.id === localIdStr);
}

export const findIndexByLocalIdOrFlowId = (array, localId) => {
    if (array[0] && array[0].localId) return findIndexByLocalId(array, localId);
    else return findIndexByFlowId(array, localId);
}

export const getElementByFlowId = (array, localId) => {
    const localIdStr = String(localId);
    return array.find(element => element && element.id === localIdStr);
}

export const getElementByLocalId = (array, localId) => {
    const localIdInt = parseInt(localId);
    return array.find(element => element && element.localId === localIdInt);
}

export const removeElementsFromArray = (indexes, array) => {
    return array.filter((arrayValue, index) => {
        return !inArray(index, indexes);
    })
}

export const findRelatedNodeData = (node, group, type = 'event') => {
    const relatedNodes = deepGet(node, 'ui.related', [])
    const relatedNodeIndex = relatedNodes.findIndex(elem => elem.group === group && elem.type === type)

    if (relatedNodeIndex < 0) return undefined
    return {...relatedNodes[relatedNodeIndex], index: relatedNodeIndex}
}

// const newLocalIdNext = (arrayLocalID, graphItem, saveProcess, message='') => {
//     if(message) console.log('>>> newLocalIdNext:', message);
//     if (arrayLocalID.length === 0) {
//         if (graphItem.current && graphItem.current.logic && graphItem.current.logic.localIdNext) {
//             arrayLocalID.push(graphItem.current.logic.localIdNext);
//         } else arrayLocalID.push(1);
//     }
//     let localIdNext = Math.max(...arrayLocalID);
//     arrayLocalID.push(localIdNext + 1);
//     saveProcess('newLocalIdNext');
//     return localIdNext;
// }

// ======= graph lib =======


export const fixNodeCoordinates = (node, graphParams) => {
    if (graphParams && node.ui && node.ui.position) {
        // console.log('graph position', graphParams.position);
        // console.log('node position 1', node.ui.position);

        node.ui.position.x = (node.ui.position.x - graphParams.position.x) / graphParams.position.zoom;
        node.ui.position.y = (node.ui.position.y - graphParams.position.y) / graphParams.position.zoom;

        // console.log('node position 2', node.ui.position); // deprecated
    }
    return node;
}

export const composeNodeBaseObj = (type, x, y, graphParams = null, nodeParams = null) => {
    let node = {
        type: type,
        ui: {
            position: {x, y}
        },
    }
    if (graphParams) node = fixNodeCoordinates(node, graphParams);
    return {...node, ...nodeParams};
}

export const copyGraphElements = (selectedNodes, selectedEdges, nodesState, setNodesState, edgesState, setEdgesState, newLocalId, newStepId) => {
    let nodes = [...selectedNodes]
    let edges = [...selectedEdges]

    let localIds = {}, newNodes = [], newEdges = [];
    // console.log('copyGraphElements:', 'nodes', nodes, 'edges', edges);
    const nodesShift = getRandomInt(150, 170);

    const replacedLocalId = (oldLocalId) => {
        const oldId = parseInt(oldLocalId);
        if (oldId in localIds) return localIds[oldId];
        const newId = newLocalId();
        localIds[oldId] = newId;
        return newId;
    }

    for (const selectedNode of nodes) {
        // for PASTE nodes
        let node = deepCopyObject(selectedNode);
        // for DUPLICATE nodes
        if (selectedNode.id && !selectedNode.localId) {
            const nodeIndex = findIndexByLocalId(nodesState, selectedNode.id);
            if (nodeIndex < 0) continue;
            node = deepCopyObject(nodesState[nodeIndex]);
        }

        // replace node local ID
        const nodeOrigLocalId = node.localId
        node.localId = replacedLocalId(node.localId);

        // UI always must be added
        if (!node.ui) node['ui'] = {}

        // start of ports
        for (const port of node.ports) {
            port['localId'] = replacedLocalId(port.localId);
        }

        // for related nodes
        if (node.ui.related && node.ui.related.length) {
            for (const relatedNodeInfo of node.ui.related) {
                // for DUPLICATE mode // TODO: think about replace it to Flow file like copyPaste mode
                if (selectedNode.id) {
                    // check related node exists in state
                    let relatedNodeIndex = findIndexByLocalId(nodesState, relatedNodeInfo.nodeLocalId);
                    if (!relatedNodeIndex) continue;

                    // and add related nodes to duplicate loop (for localId update)
                    nodes.push({id: String(relatedNodeInfo.nodeLocalId)});
                    // console.log('related node pushed', nodes)

                    // also add related edges
                    const relatedEdge = edgesState.find(e => e.srcNode === nodeOrigLocalId && e.dstNode === relatedNodeInfo.nodeLocalId)
                    if (relatedEdge) edges.push({id: String(relatedEdge.localId)}); // add as DUPLICATE mode
                    // console.log('relatedEdge', nodeOrigLocalId, relatedNodeInfo.nodeLocalId, relatedEdge, edges)
                }

                // change local ID of related new elements
                relatedNodeInfo['nodeLocalId'] = replacedLocalId(relatedNodeInfo.nodeLocalId);
            }
        }

        // do not forget effect specs
        if (node.effects) {
            for (const effect of node.effects) {
                const effectType = effect.type;
                effect['localId'] = replacedLocalId(effect.localId);
                if (!effect.spec) continue;

                for (const specParam of ['truePorts', 'localIds']) {
                    if (specParam in effect.spec) {
                        let targets = [];
                        for (const specTarget of effect.spec[specParam]) {
                            if (specTarget) {
                                if (effectType === 'effect/core/var/jump/to') {
                                    // do not replace non selected local IDs!
                                    const newTargetIndex = findIndexByLocalIdOrFlowId(selectedNodes, specTarget);
                                    if (newTargetIndex < 0) {
                                        targets.push(specTarget);
                                        continue;
                                    }
                                }

                                let newTarget = replacedLocalId(specTarget);
                                if (typeof specTarget === 'string') newTarget = String(newTarget);

                                targets.push(newTarget);
                            }
                        }
                        // store to spec param
                        effect.spec[specParam] = targets;
                    }
                }
                // ======= change conditions local IDs ========
                if ('conditions' in effect.spec) {
                    for (const condition of effect.spec.conditions) {
                        condition['localId'] = replacedLocalId(condition.localId);
                    }
                }

                // ======= change buttons local IDs ========
                if ('keyboard' in effect.spec) {
                    for (const keyboardRow of effect.spec.keyboard) {
                        for (const keyboardButton of keyboardRow) {
                            keyboardButton['id'] = replacedLocalId(keyboardButton.id);
                        }
                    }
                }
            }
            // console.log('node.effects', node.effects)
        }
        // for event block types
        if (node.events) {
            for (const event of node.events) {
                event['localId'] = replacedLocalId(event.localId);
                event['filterEffect'] = replacedLocalId(event.filterEffect);
            }
        }
        // finally, update step and position
        if (node.ui.step) node.ui.step = newStepId(); // node.ui is must be added already
        if (node.ui.position) node.ui.position.y += nodesShift;
        // node.ui.position.x += 100; // do not use for vertical alignment by line

        // store result
        newNodes.push(node);
    }
    for (const selectedEdge of edges) {
        // for PASTE edges
        let edge = deepCopyObject(selectedEdge);
        let pasteMode = true;
        // for DUPLICATE edges
        if (selectedEdge.id && !selectedEdge.localId) {
            const edgeIndex = findIndexByLocalId(edgesState, selectedEdge.id);
            if (edgeIndex < 0) continue;
            edge = deepCopyObject(edgesState[edgeIndex]);
            pasteMode = false;
        }

        // check is node in selection
        let srcNodeIndex = -1;
        let dstNodeIndex = -1;

        if (pasteMode) {
            srcNodeIndex = nodes.findIndex(n => n.localId === edge.srcNode);
            dstNodeIndex = nodes.findIndex(n => n.localId === edge.dstNode);
        } else {
            srcNodeIndex = nodes.findIndex(n => n.id === String(edge.srcNode));
            dstNodeIndex = nodes.findIndex(n => n.id === String(edge.dstNode));
        }

        // paste only selected arrows
        if (pasteMode && (dstNodeIndex < 0 || srcNodeIndex < 0)) continue;
        // duplicate only arrows with new nodes on one side
        if (dstNodeIndex >= 0 || srcNodeIndex >= 0) {
            edge.localId = replacedLocalId(edge.localId);
            if (srcNodeIndex >= 0) {
                edge.srcNode = replacedLocalId(edge.srcNode);
                edge.srcPort = replacedLocalId(edge.srcPort);
            }
            if (dstNodeIndex >= 0) {
                edge.dstNode = replacedLocalId(edge.dstNode);
                edge.dstPort = replacedLocalId(edge.dstPort);
            }
            // push only if all required conditions correct
            newEdges.push(edge);
        }
        // console.log(
        //     'dstNodeIndex', dstNodeIndex,
        //     'srcNodeIndex', srcNodeIndex,
        //     'newNodes', newNodes,
        //     'newEdges', newEdges,
        // );
    }

    // update graph
    if (newNodes.length) setNodesState([...nodesState, ...newNodes]);
    if (newEdges.length) setEdgesState([...edgesState, ...newEdges]);

    return {localIds, nodes: newNodes, edges: newEdges};
}

export const updateGraphNodesCoords = (nodes, nodesState, setNodesState, flowNodes = null) => {
    let graphNodes = [...nodesState];
    for (const flowNode of nodes) {
        // init vars
        let nodeLocalId = flowNode.id;
        let nodePosition = flowNode.position;
        if (flowNodes && flowNodes[0].id) { // recheck data source (graphNodes has no id, but localId)
            const flowStateNode = getElementByFlowId(flowNodes, nodeLocalId);
            if (flowStateNode) nodePosition = flowStateNode.position;
        }

        // find node index in graph state
        const nodeIndex = findIndexByLocalId(graphNodes, nodeLocalId);
        if (nodeIndex < 0) continue;

        // update params and save to list
        const updatedNode = {...graphNodes[nodeIndex]};
        updatedNode['ui'] = {...updatedNode.ui, position: nodePosition};
        graphNodes[nodeIndex] = updatedNode;
        // console.log('updateGraphNodesCoords', nodeIndex, updatedNode, graphNodes);
    }
    // save to state
    setNodesState(graphNodes);
}

export const updateGraphNode = (nodeParams, ui, nodesState, setNodesState = null, setChosenNode = null) => {
    let nodes = [...nodesState];
    const nodeIndex = findIndexByLocalId(nodes, nodeParams.localId);
    // console.log('updateGraphNode nodeParams', nodeParams.localId, nodeIndex, nodeParams, ui, nodes)

    if (nodeIndex >= 0) {
        const node = {...nodes[nodeIndex]};

        // check changes
        if (node === nodeParams) { // TODO: replace to JSON
            return node;
        }
        // else{
        //     console.log('node', node);
        //     console.log('nodeParams', nodeParams);
        // }

        // set new common params
        for (const nodeParam in nodeParams) {
            if (nodeParam !== 'localId') node[nodeParam] = nodeParams[nodeParam];
        }

        // set new UI params
        if (!node.ui) node['ui'] = ui;
        else if (ui) node['ui'] = {...node.ui, ...ui};

        nodes[nodeIndex] = node;

        if (setChosenNode) setChosenNode(node);
        if (setNodesState) setNodesState(nodes);

        return node;
    } else return undefined;
}

export const composeNewGraphEffectByType = (type, newLocalId, effectLocalId) => {
    let effect;
    // system effects
    if (type === condition_jump_effect.type) effect = condition_jump_effect;
    else if (type === graph_jump_effect.type) effect = graph_jump_effect;
    // normal effects
    else effect = getFullEffectByType(type);
    const spec = createSpecFromFields(effect.fields);

    // return graph effect
    return {
        type: effect.type,
        localId: effectLocalId > 0 ? effectLocalId : newLocalId(),
        spec
    }
}

export const createGraphEffect = (type, spec, newLocalId, effectParams = null, effectLocalId = 0) => {
    if (!type) {
        console.error('createGraphEffect: Effect type is required');
        return null;
    }

    // for cloned conditions
    if (spec && 'conditions' in spec) {
        // spec is already "let"
        for (const condition of spec.conditions) {
            condition['localId'] = newLocalId();
        }
    }

    let effect = composeNewGraphEffectByType(type, newLocalId, effectLocalId);
    effect['spec'] = {...effect['spec'], ...spec};

    if (effectParams) effect = {...effect, ...effectParams};
    return effect
}

export const addGraphEventToNode = (
    eventType,
    nodeLocalId,
    nodesState,
    newLocalId,
    isWithFilters = true,
    setNodesState = null,
    setChosenNode = null,
    effectParams = null,
    effectSpecValues = null,
    eventSpec = null,
) => {
    // get node from state
    let nodes = [...nodesState];
    const nodeIndex = findIndexByLocalId(nodes, nodeLocalId); // there is already converting to int inside
    if (nodeIndex < 0 || !nodes[nodeIndex]) return undefined;
    const node = deepCopyObject(nodes[nodeIndex]);

    // get out port from node
    const defaultOutPort = node.ports.find(port => port.name === 'defaultOutput');

    // set new filter effect
    let effect, effectSpec;

    if (isWithFilters) {
        // for ui events (with conditions)
        effectSpec = createSpecFromFields(condition_jump_effect.fields);
        if (effectSpecValues) effectSpec = {...effectSpec, ...deepCopyObject(effectSpecValues)};
        effectSpec['truePorts'] = [defaultOutPort.localId];
        effect = createGraphEffect(condition_jump_effect.type, effectSpec, newLocalId, effectParams)
    } else {
        // for system events (without conditions)
        effectSpec = createSpecFromFields(graph_jump_effect.fields);
        effectSpec['localIds'] = [defaultOutPort.localId];
        effect = createGraphEffect(graph_jump_effect.type, effectSpec, newLocalId, effectParams)
    }


    if (!effect) return null;

    /// set new event
    let event = {
        localId: newLocalId(),
        type: eventType,
        filterEffect: effect.localId,
        filter: [],
    }

    if (eventSpec) event['spec'] = eventSpec

    // init lists if not set in node
    if (!node['events']) node['events'] = [];
    if (!node['effects']) node['effects'] = [];

    // push new items
    node['events'].push(event);
    node['effects'].push(effect);

    // save node to nodes list
    nodes[nodeIndex] = node;

    // store changes to states
    if (setChosenNode) setChosenNode(node);
    if (setNodesState) setNodesState(nodes);
    return node;
}

export const createConditionEffect = (
    type = popularConditionTypes.object_text,
    condSpec = {},
    effectSpec = {},
    effectLocalId,
    newLocalId,
) => {
    // condition effect spec
    const condition = getFullEffectByType(type);
    let conditionSpec = {...createSpecFromFields(condition.fields), ...condSpec};

    // condition effect
    let conditionEffectSpec = createSpecFromFields(condition_jump_effect.fields);
    conditionEffectSpec = {
        ...conditionEffectSpec,
        conditions: [{type: condition.type, spec: conditionSpec}], ...effectSpec
    }

    // condition node
    return createGraphEffect(condition_jump_effect.type, conditionEffectSpec, newLocalId, null, effectLocalId)
}

export const addGraphActionToNode = (
    effectType,
    effectSpecValues,
    nodeLocalId,
    nodesState,
    setNodesState,
    newLocalId,
    setChosenNode = null,
    effectParams = null,
) => {
    let nodes = [...nodesState];
    let effectSpec = effectSpecValues ? deepCopyObject(effectSpecValues) : effectSpecValues;

    const nodeIndex = findIndexByLocalId(nodes, nodeLocalId); // there is already converting to int inside
    if (nodeIndex < 0 || !nodes[nodeIndex]) return undefined;

    const node = deepCopyObject(nodes[nodeIndex]);
    const effectsList = node['effects'];
    let relatedNodeIndex, relatedNode, eventOutput;

    if (effectType === condition_jump_effect.type) {
        const successPortLocalId = newLocalId();
        const successPort = {
            "name": "condition",
            "localId": successPortLocalId,
            "type": "output",
            "group": "branching",
        }
        node.ports.splice(node.ports.length - 1, 0, successPort); // add to pre last element
        effectSpec['truePorts'] = [successPortLocalId];
    }

    // for messages keyboard
    if (effectSpec.keyboard && effectSpec.keyboard.length) {
        if (node.ui && node.ui.related) {
            const relatedNodeInfo = findRelatedNodeData(node, 'reactions', 'event')
            if (relatedNodeInfo) {
                relatedNodeIndex = findIndexByLocalId(nodes, relatedNodeInfo.nodeLocalId);
                if (relatedNodeIndex >= 0 && nodes[relatedNodeIndex]) {
                    relatedNode = deepCopyObject(nodes[relatedNodeIndex]);
                    eventOutput = relatedNode.ports.find(port => port.name === 'defaultOutput');
                }
            }
        }

        for (const buttonsRowKey in effectSpec.keyboard) {
            for (const buttonKey in effectSpec.keyboard[buttonsRowKey]) {
                const button = effectSpec.keyboard[buttonsRowKey][buttonKey]
                const buttonLocalId = newLocalId()
                effectSpec.keyboard[buttonsRowKey][buttonKey]['id'] = buttonLocalId

                // for messages blocks only
                if (node.type === 'message') {
                    node.ports.push({
                        ...composePort('button', buttonLocalId, 'output', 'buttons'),
                        ui: {type: button.color, title: button.label}
                    })
                }

                if (relatedNode) {
                    // create keyword condition effect
                    const effectLocalId = newLocalId()
                    const relatedConditionEffect = createConditionEffect(
                        popularConditionTypes.object_text,
                        {values: [button.label]},
                        {truePorts: [buttonLocalId, eventOutput.localId]},
                        effectLocalId,
                        newLocalId,
                    )

                    relatedNode = upd(relatedNode, [
                        {go: ['effects']},
                        {push: [relatedConditionEffect]}, // add condition effect
                        // event filters
                        {find: ['type', graph_jump_effect.type]}, // find graph_jump_effect
                        {go: ['spec', 'localIds']},
                        {push: [effectLocalId]}, // add new button filter
                    ], 'Related Node undefined')
                }
            }
        }
    }

    const effect = createGraphEffect(effectType, effectSpec, newLocalId, effectParams);
    if (!effect) {
        notice.error('Effect was not created');
        console.error('Effect was not created', effectType, effectSpec);
        return null;
    }

    if (effectsList === undefined) {
        // for events
        node['effects'] = [effect];
    } else {
        // normal mode
        const list_len = effectsList.length;
        const last_index = list_len - 1;
        const last_element = effectsList[last_index];

        if (list_len && last_element.type === graph_jump_effect.type && effect.type !== graph_jump_effect.type) {
            effectsList.splice(last_index, 0, effect); // add to pre last element
        } else {
            effectsList.push(effect);
        }
    }

    // update control panel
    if (setChosenNode) setChosenNode(node);
    // replace updated nodes
    nodes[nodeIndex] = node;
    if (relatedNode) nodes[relatedNodeIndex] = relatedNode;
    // save nodes state
    setNodesState(nodes);
    return node;
}

export const addGraphConditionToEffect = (
    conditionType,
    nodeLocalId,
    effectLocalId,
    nodesState,
    setNodesState,
    newLocalId,
    setChosenNode = null,
) => {
    let nodes = [...nodesState];
    const nodeIndex = findIndexByLocalId(nodes, nodeLocalId);
    if (nodeIndex < 0 || !nodes[nodeIndex]) return undefined;

    let node = deepCopyObject(nodes[nodeIndex]);
    if (!node['effects']) return undefined;
    const effectsList = node['effects'];

    const effectIndex = findIndexByLocalId(effectsList, effectLocalId);
    if (effectIndex < 0 || !effectsList[effectIndex]) return undefined;

    const effect = effectsList[effectIndex];
    if (!effect.spec || !effect.spec.conditions) effect['spec']['conditions'] = []; // for miracle cases

    const condition = composeNewGraphEffectByType(conditionType, newLocalId);
    effect['spec']['conditions'].push(condition);

    if (setChosenNode) setChosenNode(node);
    nodes[nodeIndex] = node;
    setNodesState(nodes);
    return node;
}

export const composePort = (name, localId, type, group = '') => {
    let newPort = {
        name,
        localId,
        type,
    }
    if (group) newPort['group'] = group
    return newPort;
}


export const createGraphNodeAndReturnIds = (newNode, nodesState, setNodesState, newLocalId, newStepId = null, graphParams = null) => {
    let nodes = [...nodesState];
    let node = {...newNode, localId: newLocalId()};
    node = fixNodeCoordinates(node, graphParams);

    if (!node['type']) {
        showGraphError('Node creating - Required field [type] not found or zero. Node:', node);
        return null;
    }

    // add to graph
    let defaultEffects = null;
    const inputPortLocalId = newLocalId();
    const outputPortLocalId = newLocalId();
    if (node.type === 'note') {
        node['ports'] = [];
    } else {
        // for labels
        if (newStepId) node.ui['step'] = newStepId();
        node['ports'] = [
            composePort('defaultInput', inputPortLocalId, 'input', 'waitAll'),
            composePort('defaultOutput', outputPortLocalId, 'output'),
        ]
    }
    if (node.type === 'condition') {
        const successPortLocalId = newLocalId();
        const failurePortLocalId = newLocalId();

        node['ports'].push(composePort('condition', successPortLocalId, 'output', 'branching'))
        node['ports'].push(composePort('failure', failurePortLocalId, 'output', 'branching'))

        if (newStepId) {
            defaultEffects = [
                createGraphEffect(graph_jump_effect.type, {localIds: [outputPortLocalId]}, newLocalId),
                createGraphEffect(condition_jump_effect.type, {truePorts: [successPortLocalId]}, newLocalId),
                createGraphEffect(graph_jump_effect.type, {localIds: [failurePortLocalId]}, newLocalId),
            ]
        } else { // system cond = does not need step ID
            defaultEffects = [
                createGraphEffect(condition_jump_effect.type, {truePorts: [successPortLocalId]}, newLocalId),
            ]
        }
    } else if (node.type === 'action') {
        defaultEffects = [
            createGraphEffect(graph_jump_effect.type, {localIds: [outputPortLocalId]}, newLocalId),
        ]
    } else if (node.type === 'message') {
        defaultEffects = [
            createGraphEffect(popularEffectsTypes.message_send, {options: true}, newLocalId),
            createGraphEffect(graph_jump_effect.type, {localIds: [outputPortLocalId]}, newLocalId),
        ]
    } else if (node.type === 'edit') {
        node['type'] = 'message'
        defaultEffects = [
            createGraphEffect(popularEffectsTypes.message_edit, {options: false}, newLocalId),
            createGraphEffect(graph_jump_effect.type, {localIds: [outputPortLocalId]}, newLocalId),
        ]
    } else if (node.type === 'timer') {
        defaultEffects = [
            createGraphEffect(popularEffectsTypes.delay_run, {}, newLocalId),
            createGraphEffect(graph_jump_effect.type, {localIds: [outputPortLocalId]}, newLocalId),
        ]
    }

    if (defaultEffects) {
        node['effects'] = defaultEffects; // can not pass default effects in props
    }

    nodes.push(node);
    // console.log('Just created Graph Node:', node, '\nAll nodes:', nodes);
    setNodesState(nodes);

    return {
        nodeId: String(node['localId']),
        targetId: inputPortLocalId,
        sourceId: outputPortLocalId,
    }
}

export const checkEdgeExists = (edge, edges) => {
    // console.log('checkEdgeExists', edge, edges);
    const keys = Object.keys(edgeParams);
    const existedEdge = edges.findIndex(e => {
        return e[keys[1]] === edge[keys[1]] &&
            e[keys[2]] === edge[keys[2]] &&
            e[keys[3]] === edge[keys[3]] &&
            e[keys[4]] === edge[keys[4]];
    })
    return existedEdge >= 0;
}

export const addGraphEdges = (newEdges, edgesState, setEdgesState, newLocalId, selfConnectionError = '') => {
    let edges = [...edgesState];

    for (const newEdge of newEdges) {
        // set edge params
        const edge = {};
        for (const edgeParam in edgeParams) {
            if (newEdge[edgeParam] && typeof newEdge[edgeParam] === 'string') {
                edge[edgeParam] = parseInt(newEdge[edgeParam]);
            } else edge[edgeParam] = newEdge[edgeParam];
        }

        // not forget about UI
        if (newEdge.ui) {
            edge['ui'] = newEdge.ui;
        }

        // check is already exists
        const existedEdge = checkEdgeExists(edge, edges);
        if (existedEdge) {
            console.info('Creating: the edge already exists', edge);
        } else if (edge.srcNode === edge.dstNode) {
            if (selfConnectionError) notice.warning(selfConnectionError);
            console.info('Creating: can not connect self', edge);
        }
        // add to graph
        else {
            edge['localId'] = newLocalId();
            edges.push(edge);
            // console.log('Just added GraphEdge:', newEdge, edge, edges);
        }
    }
    if (edgesState.length !== edges.length) setEdgesState(edges);
}

export const changeGraphEdge = (oldEdge, newEdge, edgesState, setEdgesState) => {
    // console.log('oldEdge', oldEdge);
    // console.log('newEdge', newEdge);

    let edges = [...edgesState];
    const edgeParamsSwapped = arraySwap(edgeParams);
    const edgeIndex = findIndexByLocalId(edges, oldEdge.id);

    if (edgeIndex < 0) {
        showGraphError('The edge is undefined in orig graph. Edge:', newEdge);
        return undefined;
    }

    const edge = {...edges[edgeIndex]};
    for (const edgeParam in edgeParamsSwapped) {
        if (newEdge[edgeParam]) edge[edgeParamsSwapped[edgeParam]] = parseInt(newEdge[edgeParam]);
    }

    // check is already exists first
    const existedEdge = checkEdgeExists(edge, edges);
    if (existedEdge) {
        console.info('Changing: the edge already exists');
    } else {
        edges[edgeIndex] = edge;
        setEdgesState(edges);
    }

    return edge;
}

export const deleteGraphElements = (deletedElements, elementsState, setElementsState) => {
    let indexes = [];
    let elements = [...elementsState];

    for (const el of deletedElements) {
        const localId = el.id ?? el.localId;
        if (!localId) continue;
        const elementIndex = findIndexByLocalId(elements, localId);

        // delete also related nodes
        const element = elements[elementIndex]
        if (element.ui && element.ui.related && element.ui.related.length) {
            for (const related of element.ui.related) {
                // skip for not node (fow now)
                const relatedLocalId = related.nodeLocalId
                if (!relatedLocalId) continue

                const relatedElementIndex = findIndexByLocalId(elements, relatedLocalId);
                indexes.push(relatedElementIndex);
            }
        }

        indexes.push(elementIndex);
    }
    // check for errors
    if (!indexes.length) return undefined;

    elements = removeElementsFromArray(indexes, elements);
    setElementsState(elements);
    return indexes;
}

export const deleteEdgesByNodes = (nodes, elementsState, setElementsState, nodesState = true) => {
    let indexes = [];
    let edges = [...elementsState];
    for (const node of nodes) {
        const nodeId = node.localId ?? parseInt(node.id);
        edges = edges.filter(edge => edge.srcNode !== nodeId && edge.dstNode !== nodeId);

        if (nodesState) { // extended mode
            const nodeObject = getElementByLocalId(nodesState, nodeId)
            const nodePortsIds = nodeObject && nodeObject.ports ? nodeObject.ports.map(port => port.localId) : []
            if (nodePortsIds) {
                edges = edges.filter(edge => !inArray(edge.srcPort, nodePortsIds) && !inArray(edge.dstPort, nodePortsIds))
            }
        }
    }
    setElementsState(edges);
    return indexes;
}


export const isNodeIgnored = (node) => {
    let ignored = false;
    if (node.ignore) ignored = true;
    else if (node.effects && node.effects.length) {
        const firstEffect = node.effects[0];
        if (firstEffect.type === graph_jump_effect.type && firstEffect.spec && firstEffect.spec.type === 'ignore') {
            ignored = true;
        }
    }
    return ignored
}


export const composeFlowFromGraph = (nodes, edges, selected, edgesParams) => {
    let graph = {
        nodes: [],
        edges: [],
    }
    for (const edge of edges) {
        if (edge.ui && edge.ui.hidden) continue // do not render system edges
        let e = {};

        for (const edgeParam in edgeParams) {
            if (!edge[edgeParam]) {
                showGraphError('Required field [' + edgeParam + '] not found or zero. Edge:', edge)
                return null;
            }
            e[edgeParams[edgeParam]] = String(edge[edgeParam]);
        }

        // reselection items by state
        if (selected && selected.edges.length) {
            const selectedEdgeIndex = findIndexByFlowId(selected.edges, edge.localId);
            if (selectedEdgeIndex >= 0) e.selected = true;
        }

        e = {...e, ...edgesParams};
        graph.edges.push(e);
    }

    for (const node of nodes) {
        if (node.ui && node.ui.hidden) continue // do not render system nodes

        let n = {
            id: '0',
            type: 'default',
            data: {},
            position: {x: 0, y: 0},
        };
        // check required
        for (const nodeParam in nodeRequiredParams) {
            if (!node[nodeParam]) {
                showGraphError('Required field [' + nodeParam + '] not found or zero. Node:', node);
                return null;
            }
            n[nodeRequiredParams[nodeParam]] = String(node[nodeParam]);
        }

        // reselection items by state
        if (selected && selected.nodes.length) {
            const selectedNodeIndex = findIndexByFlowId(selected.nodes, node.localId);
            if (selectedNodeIndex >= 0) n.selected = true;
        }

        // set node fields
        if (node.text) {
            // main node text
            n.data['label'] = node.text;
        } else if (n.type === 'message') {
            let fileCaption = deepGet(node, 'effects.0.spec.caption')
            let messageText = deepGet(node, 'effects.0.spec.text')
            if (!messageText && fileCaption) messageText = fileCaption

            if (messageText) {
                messageText = stripHtmlTags(messageText);
                const messageTextLines = messageText.split('\n')
                const messageTextResult = messageTextLines[0]
                n.data['label'] = messageTextResult.length > 70 ? messageTextLines[0].substring(0, 70) + '...' : messageTextResult;
            }

        } else if (n.type === 'event') {
            let messageKeywords = deepGet(node, 'effects.0.spec.conditions.0.spec.values')
            if (messageKeywords) {
                if (typeof messageKeywords === 'string') {
                    n.data['label'] = messageKeywords;
                } else if (isArr(messageKeywords)) {
                    const messageTextLines = messageKeywords.slice(0, 3);
                    n.data['label'] = messageTextLines.join(', ');
                }
            }
        }

        let messageImage = deepGet(node, 'effects.0.spec.code')
        if (!messageImage) messageImage = deepGet(node, 'effects.0.spec.url')
        if (messageImage && typeof messageImage === 'string' && messageImage.match(/^https:\/\/(.+)\.jpg$/)) {
            n.data['image'] = messageImage;
        } else messageImage = deepGet(node, 'effects.0.spec.url')

        // for visualising ignored blocks
        if (isNodeIgnored(node)) n.data['ignored'] = true;

        if (node.ports) {
            const pushTo = {
                waitAll: 'inputs',
                reactions: 'reactions',
                branching: 'branching',
                buttons: 'buttons',
                inputs: 'inputs',
                outputs: 'outputs',
            };
            n.data['ports'] = {
                inputs: [],
                outputs: [],
                buttons: [],
                reactions: [],
                branching: [],
            };

            // set conditions ports colors and fix names
            const branchingPorts = node.ports.filter(port => port.group === 'branching');
            const branchingPortsLength = branchingPorts.length;
            let nodePorts = branchingPortsLength ? deepCopyObject(node.ports) : node.ports;

            let failurePortIndex = -1;
            if (branchingPortsLength) {
                // failurePortIndex = findIndexByLocalId(nodePorts, branchingPorts[branchingPortsLength-1].localId);
                failurePortIndex = nodePorts.findIndex(port => port.name === 'failure');
                if (failurePortIndex >= 0) {
                    const failurePort = nodePorts[failurePortIndex];
                    if (!failurePort.ui) failurePort['ui'] = {};
                    failurePort.ui['type'] = 'danger'; // port color
                }
            }
            if (branchingPortsLength === 2) {
                //const successPortIndex = findIndexByLocalId(nodePorts, branchingPorts[0].localId);
                const successPortIndex = nodePorts.findIndex(port => port.name === 'condition');
                if (successPortIndex >= 0) {
                    const successPort = nodePorts[successPortIndex];
                    if (!successPort.ui) successPort['ui'] = {};
                    successPort.ui['type'] = 'success'; // port color
                    successPort['name'] = 'yes'; // port button label

                    const failurePort = nodePorts[failurePortIndex];
                    failurePort['name'] = 'no';
                }
            }

            // add buttons ports to message
            // nodePorts = getMessagePorts(nodePorts, node, nodes)

            // loop ports and add to result array
            for (const port of nodePorts) {
                const portId = String(port.localId);
                const findBy = {input: 'targetHandle', output: 'sourceHandle'};
                const portEdgeIndex = graph.edges.findIndex(edge => {
                    // hidden edges is for system reasons
                    return edge[findBy[port.type]] === portId && (!edge.ui || !edge.ui.hidden)
                });
                const portResult = {
                    id: portId,
                    name: port.name,
                    group: port.group ? port.group : port.type + 's',
                    hasEdge: portEdgeIndex >= 0,
                }
                if (port.ui) {
                    if (port.ui.type) portResult['type'] = port.ui.type;
                    if (port.ui.title) portResult['title'] = port.ui.title;
                    if (port.ui.nodeLocalId) portResult['nodeLocalId'] = port.ui.nodeLocalId;

                    if (portEdgeIndex >= 0 && port.type === 'output' && inArray(port.ui.type, ['success', 'danger', 'warning'])) {
                        graph.edges[portEdgeIndex]['data'] = {type: port.ui.type}; // buttons: true
                        //graph.edges[portEdgeIndex]['label'] = portResult.title;
                    }
                }

                // console.log('portResult', portId, port, portResult);
                n.data['ports'][pushTo[portResult.group]].push(portResult);
            }
        }

        // check flow required
        if (!('ui' in node)) {
            showGraphError('Required field [ui] not found. Node:', node);
            return null;
        }
        if (node.ui && !('position' in node.ui)) {
            showGraphError('Required field [position] not found. Node:', node);
            return null;
        }

        // last params
        n.position = node.ui.position;
        n.data['title'] = node.ui.title ? node.ui.title : '';
        n.data['step'] = node.ui.step ? node.ui.step : 0;

        // for notes
        n.data['preset'] = ('preset' in node.ui && node.ui.preset) ? node.ui.preset : "default";
        n.data['fontSize'] = ('fontSize' in node.ui && node.ui.fontSize) ? node.ui.fontSize : 14;

        // related
        n.data['related'] = ('related' in node.ui && node.ui.related) ? node.ui.related : [];

        // store result
        graph.nodes.push(n);
    }

    return graph;
}