import React from "react";
import {Tree} from "antd";
import {deepCopyObject} from "../../library/functions";
import {getNotEmptyValues} from "../../library/containers";
import {DownOutlined} from "@ant-design/icons";

export const defaultTreeData = {
    nextKey: 1,
    tree: [],
}

const loopTree = (data, key, callback) => {
    if (!data) return;

    for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
            return callback(data[i], i, data);
        }
        if (data[i].children) {
            loopTree(data[i].children, key, callback);
        }
    }
};

export const getTreeItemByKey = (treeState, key) => {
    const tree = treeState.tree;
    let item = null;

    loopTree(tree, key, (i) => item = i);
    return item;
}

export const addTreeItemByParentKey = (treeState, parentKey, child) => {
    const treeData = treeState || defaultTreeData;

    if (!treeData.tree) return treeData;
    let tree = deepCopyObject(treeData.tree);

    const newItem = {...getNotEmptyValues(child), key: treeData.nextKey};
    const nextKey = treeData.nextKey + 1;

    if (!parentKey) {
        tree.push(newItem);
        return {nextKey, tree};
    } else {
        loopTree(tree, parentKey, (item) => {
            item.children = item.children || [];
            item.children.push(newItem);  // Add newItem instead of item itself
        });
    }

    return {nextKey, tree};
}

export const updateTreeItemByKey = (treeState, key, newItem) => {
    let tree = deepCopyObject(treeState.tree);
    if (!tree) return treeState;

    loopTree(tree, key, (item, index, arr) => {
        arr[index] = getNotEmptyValues({...item, ...newItem});
        if (key === 0) arr[index]['key'] = 0;
    });

    return {...treeState, tree};
}

export const removeTreeItemByKey = (treeState, key) => {
    let tree = deepCopyObject(treeState.tree);
    if (!tree) return treeState;

    loopTree(tree, key, (item, index, arr) => {
        arr.splice(index, 1);
    });

    return {...treeState, tree};
}


export const DraggableTree = (
    {
        value,
        onChange,
        onSelect,
        onBlur,
        expanded = null,
        onExpand = null,
        renderer = null,
        oneLevel = false,
    }
) => {

    let val = defaultTreeData;
    if (value) val = deepCopyObject(value);

    // console.log('DraggableTree Render - value', val);

    const onDrop = (info) => {
        // console.log('onDrop', info);

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const data = [...val.tree];

        // Find dragObject
        let dragObj;
        loopTree(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap && !oneLevel) {
            // Drop on the content
            loopTree(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 &&
            // Has children
            info.node.props.expanded &&
            // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loopTree(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });

        } else {
            let ar = [];
            let i;

            loopTree(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });

            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        const newValue = {...val, tree: data};

        if (onChange) onChange(newValue);
        if (onBlur) onBlur(newValue);
    };

    return (
        <Tree
            className="draggable-tree"
            treeData={val.tree}
            draggable
            blockNode
            onDrop={onDrop}
            onSelect={onSelect}
            showLine={true}
            switcherIcon={<DownOutlined/>}
            expandedKeys={expanded}
            onExpand={onExpand}
            titleRender={renderer}
        />
    );
};