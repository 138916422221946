import React from 'react';

export const FormItemLabel = ({label = '', className = '', htmlFor = ''}) => {
    let classes = "ant-form-item-label";
    if (className) classes += ' ' + className;

    if (label === '') return null;

    return (
        <div className={classes}>
            {htmlFor ? <label htmlFor={htmlFor}>{label}</label> : <label>{label}</label>}
        </div>
    );
};

