import axios from 'axios';
import {api, backend_api} from "../config/config";
import {call} from "redux-saga/effects";
import {objectLength, objectToQueryString} from "./functions";

export async function makePostRequest(token, url, data) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        ...api.headers,
        Authorization: `${token.token_type} ${token.access_token}`
      }
    });

    return response.data;

  } catch (error) {
    throw error;
  }
}

export const askChatGpt = (admin, project_id, message, prompt = '') => {
    return makePostRequest(admin.token, backend_api.ai, {project_id, message, prompt});
}

export const request = (token, url, method='post', data = {}) => {
    let params = {
        method: method,
        url: url,
        headers: {...api.headers, 'Authorization': `${token.token_type} ${token.access_token}`}
    }
    if (method==='get' && data && objectLength(data)) {
        let query_string = objectToQueryString(data);
        if (query_string !== '') params.url += '?' + query_string;
    }
    else if (data){
        params.data = data
    }
    return call(axios, params); // axios(params);
}

export const openRequest = (url, method='post', data = {}) => {
    let params = {
        method: method,
        url: url,
        headers: {...api.headers}
    }
    if (method==='get' && objectLength(data)) {
        let query_string = objectToQueryString(data);
        if (query_string !== '') params.url += '?' + query_string;
    }
    else if (data) {
        params.data = data
    }
    return call(axios, params); // axios(params);
}
