import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Layout, Modal, Typography} from "antd";
import {CheckOutlined, FilterOutlined, PlusOutlined} from "@ant-design/icons";

import {l} from "../../library/locale";
import {dotenv, images, routes} from "../../config/config";
import {deepGet, objectLength, orderObjectsByExternalValues, replace} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {SearchBox} from "../../components/Search/SearchBox";
import {ResponsiveText} from "../../components/Common/ResponsiveText";
import {Banner} from "../../components/Banner/Banner";
import {ProjectListTable} from "./ProjectListTable";
import {NoForm} from "../../components/Form/NoForm";
import {FormInput} from "../../components/Form/FormInput";
import {notice} from "../../library/notice";
import {setJustCreated} from "../../redux/reducers/ProjectReducer";
import {useNavigate, useSearchParams} from "react-router-dom";

const {Content} = Layout;
const {Title} = Typography;

export const orderProjectList = (projectList, userField, ordering = 'default') => {
    let list = [];
    let not_included_items = []
    const orderFieldValue = deepGet(userField, 'projects_list.value', {})
    let projects_order = [
        ...deepGet(orderFieldValue, 'order', []),
        ...deepGet(orderFieldValue, 'shared', [])
    ]

    if (projects_order && ordering === 'default') {
        // check and store new items
        not_included_items = projectList.filter(obj => !projects_order.includes(obj.id))
        if (not_included_items) {
            const not_included_ids = not_included_items.map((object) => object['id'])
            projects_order = [...not_included_ids, ...projects_order]
        }
        // order list
        list = orderObjectsByExternalValues(projectList, projects_order, 'id')
    } else {
        return projectList
    }
    return list;
}

const ProjectList = () => {
    const section = 'project'
    const default_ordering = '-created_at';

    const [searchParams] = useSearchParams();
    const welcome = searchParams.get('welcome')

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputRef = useRef()

    const {admin, project, userField} = useSelector(store => store)

    const [visibleModal, setVisible] = useState(false);
    const [ordering, setOrdering] = useState('default');
    const [filters, setFilters] = useState({});
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    if (project.justCreated > 0) {
        dispatch(setJustCreated(0));
        navigate(`${routes.project_list}/quiz/${project.justCreated}/segment`)
    }

    const orderFieldValue = deepGet(userField, 'projects_list.value', {})
    let list = {
        own: [],
        shared: []
    }

    for (const category in list) {
        const orderPropName = (category === 'own') ? 'order' : 'shared';
        const listReducerProp = (category === 'own') ? 'list' : 'shared';

        let not_included_items = []
        let projects = deepGet(project, listReducerProp, [])
        let current_order = deepGet(orderFieldValue, orderPropName, [])

        if (current_order && ordering === 'default') {
            // check and store new items
            not_included_items = projects.filter(obj => !current_order.includes(obj.id))
            if (not_included_items) {
                const not_included_ids = not_included_items.map((object) => object['id'])
                current_order = [...not_included_ids, ...current_order]
            }
            // order list
            list[category] = orderObjectsByExternalValues(projects, current_order, 'id')
        }
    }


    const [isFiltersOpened, setFiltersStatus] = useState(false);
    const toggleFiltersStatus = () => {
        setFiltersStatus(!isFiltersOpened);
    };

    window.project = project;
    window.admin = admin;

    // useEffect(() => {
    //     setList([])
    //     //eslint-disable-next-line
    // }, [filters])

    useEffect(() => {
        if (admin.authorized) {
            if (welcome) {
                // save to backend
                dispatch({
                    type: 'storeUserField',
                    admin,
                    data: {
                        project_id: dotenv.main_project,
                        user_id: admin.user.id,
                        field_name: 'boarding',
                        value: 1
                    }
                })

                // remove get params from path:
                replace(routes.project_list)
            }
            else {
                const order = (ordering === 'default') ? default_ordering : ordering

                let filters_result = {ordering: order}
                if (filters) filters_result = {...filters_result, ...filters}

                dispatch({type: 'getProjectList', admin, filters: filters_result})

                // set modal options to default if opened
                if (visibleModal) {
                    setVisible(false);
                    setConfirmLoading(false);
                    setInputValue('')
                }
            }
        }

        // ====  project custom sorting CHANGE ====  // TODO: maybe restore saving
        // if (projects_order && not_included_items && not_included_items.length > 0) {
        //     // console.log('Sorted Ids: ', sortedIds)
        //
        //     const newUserFieldData = {
        //         field_name: 'projects_list',
        //         value: {order: projects_order}
        //     }
        //     // save to store by Reducer
        //     dispatch(setUserField(newUserFieldData))
        //
        //     // save to backend by Saga
        //     dispatch({
        //         type: 'storeUserField',
        //         admin,
        //         data: {
        //             project_id: dotenv.main_project,
        //             user_id: admin.user.id,
        //             ...newUserFieldData
        //         }
        //     })
        // }

        //eslint-disable-next-line
    }, [admin.authorized, project.list.length, ordering, filters])


    useEffect(() => {
        if (admin.authorized && project.shared.length <= 0) {
            const order = (ordering === 'default') ? default_ordering : ordering

            let filters_result = {ordering: order}
            if (filters) filters_result = {...filters_result, ...filters}

            dispatch({type: 'getSharedProjectList', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project.shared.length, ordering, filters])

    useEffect(() => {
        if (admin.authorized) {
            dispatch({
                type: 'getUserField',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_name: 'projects_list',
                }
            })
        }
    }, [admin.authorized])


    const searchHandler = (value) => {
        let result = {}
        if (filters) result = {...filters}

        if (value) result.title = value
        else if (result.title !== undefined) delete result.title
        setFilters(result)
    }

    // const navigate = useNavigate()
    const newProject = () => {
        showModal()
        // navigate('/project/edit/0')
    }

    const showModal = () => {
        setVisible(true);

        setTimeout(() => {
            if (inputRef && inputRef.current) {
                const {input} = inputRef.current
                input.focus()
            }
        }, 500);
    };

    const handleOk = () => {
        setConfirmLoading(true);

        if (inputValue) {
            const data = {
                title: inputValue,
                photo_url: images.avatar.project,
                is_on: true,
                params: null,
            }
            dispatch({type: 'createProject', admin, data})
        } else {
            notice.warning(l('error.validation.required_name'));
            showModal();
        }

        setTimeout(() => {
            setConfirmLoading(false);
        }, 1000);
    };

    const handleCancel = () => {
        setInputValue('');
        setVisible(false);
    };

    const onChange = (e) => {
        setInputValue(e.target.value)
    }

    const onSubmit = (e) => {
        onChange(e)
        handleOk()
    }

    const onKeyDown = (e) => {
        if (e.code === 'Enter') {
            onSubmit(e)
        }
    }

    const filterFields = [
        [
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'affected',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off']
            }
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Banner image="tech"/>

                <CustomHeader
                    key="projects-header"
                    className={'padding-none-horizontal'}
                    title={l('project.list.title')}
                    isFiltersOpened={isFiltersOpened}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    searchHandler={searchHandler}
                    toggleFiltersStatus={toggleFiltersStatus}
                    showMobileFilterButtons={true}
                    actions={[
                        <SearchBox searchHandler={searchHandler} key="header-search-button"/>,

                        <Button
                            key="header-filters-button"
                            type="text"
                            className={isFiltersOpened ? 'hidden-xs active' : 'hidden-xs'}
                            icon={<FilterOutlined/>}
                            onClick={toggleFiltersStatus}>
                            {l('page_header.buttons.filter.title')} {/*active ˆ = filters open*/}
                        </Button>,

                        <Button
                            key="header-primary-button"
                            type="primary"
                            icon={<PlusOutlined/>}
                            onClick={newProject}>
                            <ResponsiveText md={l('project.list.button.create.title')}
                                            sm={l('page_header.buttons.create.title')}/>
                        </Button>,
                    ]}
                />

                <Modal
                    className="modal-clean"
                    title={l('project.list.button.create.title')}
                    open={visibleModal}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    footer={[
                        // // TODO оставлю тут пока, чтобы потом в сотрудниках не создавать повторно
                        // <Button
                        //     key="user"
                        //     type="text"
                        //     // size="large"
                        //     className="float-left inverted"
                        //     icon={<UserOutlined/>}>
                        //      Добавить себя
                        // </Button>,
                        <Button
                            key="modal-back"
                            type="text"
                            // size="large"
                            onClick={handleCancel}
                            className="inverted"
                        >{l('common.action.cancel')} </Button>,
                        <Button
                            key="modal-submit"
                            type="primary"
                            // size="large"
                            onClick={handleOk}
                            loading={confirmLoading}
                            icon={<CheckOutlined/>}
                        >{l('common.action.create')}</Button>
                    ]}
                >

                    <NoForm>
                        <FormInput
                            inputId="projectTitleInput"
                            className="margin-none"
                            label={l('project.form.title.label')}
                            desc={l('project.form.title.desc_new')}
                            autoFocus={true}
                            inputRef={inputRef}
                            inputValue={inputValue} // must be state
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                    </NoForm>
                </Modal>

                <Content className="page-container site-layout-background">
                    {/*<Button onClick={()=>{setOrdering('title')}} >Название возр.</Button>*/}
                    {/*<Button onClick={()=>{setOrdering('-title')}} >Название убыв.</Button>*/}
                    {/*<Button onClick={()=>{setOrdering('created_at')}} >Дата возр.</Button>*/}
                    {/*<Button onClick={()=>{setOrdering('-created_at')}} >Дата убыв.</Button>*/}
                    <ProjectListTable
                        admin={admin}
                        list={list['own']}
                        filters={filters}
                        orderFieldValue={orderFieldValue}
                        orderParam={'order'}
                    />

                    {list['shared'].length ? <>
                        <div className="margin-top-md margin-bottom-pm">
                            <Title level={2} className="semibold">
                                {l('project.list.shared')}
                            </Title>
                        </div>

                        <ProjectListTable
                            admin={admin}
                            list={list['shared']}
                            filters={filters}
                            orderFieldValue={orderFieldValue}
                            orderParam={'shared'}
                        />
                    </> : null}

                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default ProjectList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link