import React from 'react'
import SectionsActions from "./ContentActions/SectionsActions";
import CatalogHeader from "../../Header/CatalogHeader";

const Actions = ({children, isBlock = false}) => {
    return (
        <>
            { children }
            <CatalogHeader type="action"/>
            <SectionsActions isBlock={isBlock}/>
        </>
    )
}

export default Actions