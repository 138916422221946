import React, {useEffect, useState, useLayoutEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout, Menu} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {PageListTable} from "./PageListTable";
import {
    assocArrayByField,
    createObjectFromObjectsArray,
    objectLength,
    ucFirst,
    toNum,
} from "../../../library/functions";
import {useTranslation} from "react-i18next";
import {setPageStoreParam} from "../../../redux/reducers/PageReducer";
import {notice} from "../../../library/notice";
import {UploadOutlined} from "@ant-design/icons";
import ImportModal from "../../../components/Modal/ImportModal";

const {Content} = Layout;

const PageList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'page'
    const Section = ucFirst(section)
    const categorySection = 'site'

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // data from GET params
    const [searchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')
    const layout_name = searchParams.get('layout_name')

    // store and state
    const {admin, project, page, site, layout} = useSelector(store => store)
    const categoryList = site.list || []
    const categories = assocArrayByField(categoryList, 'id');
    const sites = createObjectFromObjectsArray(categoryList)
    const layouts = createObjectFromObjectsArray(layout.short.list, 'name')
    const pages = createObjectFromObjectsArray(page.short.list)

    const [filters, setFilters] = useState({})
    const [loaded, setLoaded] = useState(false)

    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleModal, setVisibleModal] = useState(false);

    // init universal vars
    const project_item = project.item
    const list = page.list
    const rowsTotal = page.count || 0
    const currentPage = page.page || 1
    const pageSize = page.pageSize || 10
    const sorter = page.sorter

    // console.log('pages', 'rowsTotal', rowsTotal, 'currentPage', currentPage, 'pageSize', pageSize, 'sorter', sorter)

    useLayoutEffect(() => {
        let defaultFilters = {}

        if (site_id) defaultFilters['site_id'] = site_id;
        if (layout_name) defaultFilters['layout_names'] = [layout_name];

        if (objectLength(defaultFilters)) setFilters({...defaultFilters, ...filters})
        //eslint-disable-next-line
    }, [site_id])

    useEffect(() => {
        // console.log('rowsTotal', rowsTotal, loaded)
        if (loaded) {
            dispatch(setPageStoreParam({
                page: 1,
                pageSize: pageSize,
                sorter: sorter,
            }))
        }

        // do not run this effect on first load
        setLoaded(true)
    }, [rowsTotal])

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                // profile_types: ['user', 'bot', 'chat', 'channel'],
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}})
            dispatch({type: 'getLayoutShortList', admin, filters: {project_id: project_item.id, ordering: 'id'}});

            // console.log('Get pages list - TABLE')
            dispatch({type: 'get' + ucFirst(section) + 'ShortList', admin, filters: {project_id: project_item.id}})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize])

    const filterFields = [
        [
            {
                name: 'site_id',
                type: 'menu',
                localized: false,
                values: sites,
                placeholder: t('common.placeholder.menu')
            },
            {
                name: 'layout_names',
                type: 'tags',
                localized: false,
                values: layouts,
                section: section,
                placeholder: t('common.placeholder.tags')
            },
            {
                name: 'parent_ids',
                type: 'tags',
                localized: false,
                values: pages,
                section: section,
                placeholder: t('common.placeholder.tags')
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off']
            },
            {
                name: 'is_main',
                type: 'menu',
                localized: true,
                section: section,
                values: ['yes', 'no'],
                placeholder: t('common.placeholder.menu')
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            },
            {
                name: 'is_free',
                type: 'switcher'
            },
        ]
    ]

    const searchHandler = (value) => {
        let filters_result = {name: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordEdit = () => {
        let url = `${routes.project_list}/${project_item.id}/${section}/edit/0?tab=form`;

        if (filters.site_id) url += `&site_id=${filters.site_id}`;
        if (filters.layout_names && filters.layout_names.length) url += `&layout_name=${filters.layout_names[0]}`;

        navigate(url)
    }

    const recordCreate = (data = null) => {
        if (!toNum(filters.site_id)) {
            notice.error(t(section + '.error.import_site_id'));
            return;
        }

        if (!data || typeof data !== 'object') {
            notice.info(t('common.import.error.empty'));
            return;
        }

        data['project_id'] = project_item.id;
        data['site_id'] = toNum(filters.site_id);
        data['name'] = null;  // allow import one item many times
        data['is_on'] = false;

        dispatch({type: 'create' + Section, admin, data});
    }

    const recordImport = () => {
        if (!fileContent) {
            notice.info(t('common.import.error.empty'));
            return;
        }
        try {
            const data = JSON.parse(fileContent);
            console.log('Import Content', data);
            recordCreate(data);
        } catch (e) {
            console.log('Import data parse error', e);
            notice.error(t('common.import.error.format'));
        }
    };

    const onMenuClick = (e) => {
        if (e.key === 'import') {
            setVisibleModal(true);
            setFileList([]);
            setFileContent('');
        }
    };

    const createMenu = (<Menu
            onClick={onMenuClick}
            items={[
                {
                    key: 'import',
                    label: t('common.action.import'),
                    icon: <UploadOutlined/>
                },
            ]}
        />
    );

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordEdit}
                    primaryMenu={createMenu}
                />
                <Content className="page-container site-layout-background">
                    <PageListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                        categoriesMap={categories}
                    />
                </Content>

                <ImportModal
                    t={t}
                    onOk={recordImport}
                    section="common"
                    isVisibleModal={isVisibleModal}
                    setVisibleModal={setVisibleModal}
                    fileList={fileList}
                    setFileList={setFileList}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    fileFormat="json"
                />
            </Layout>
        </AppWrapper>
    )
}
export default PageList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link