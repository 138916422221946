import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {fillSmGroupList} from "../reducers/SmGroupReducer";
import {fillSmProductList} from "../reducers/SmProductReducer";
import {toNum} from "../../library/functions";

// init api methods
const base_path = backend_api.platform;

function* getSmGroupList(arg) {
    try {
        if (!toNum(arg.filters.integration_id)) return null;
        const response = yield request(arg.admin.token, base_path + '/sm/group/list', 'get', arg.filters);

        if (response.data.ok) yield put(fillSmGroupList(response.data.result))
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSmProductList(arg) {
    try {
        if (!toNum(arg.filters.integration_id)) return null;
        const response = yield request(arg.admin.token, base_path + '/sm/product/list', 'get', arg.filters);

        if (response.data.ok) yield put(fillSmProductList(response.data.result))
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}


export function* smSaga() {
    yield takeEvery('getSmGroupList', getSmGroupList);
    yield takeEvery('getSmProductList', getSmProductList);
}

