export const layout_graph = {
  "nextLocalId": 17,
  "nextStepId": 5,
  "nodes": [
    {
      "type": "action",
      "ui": {
        "position": {
          "x": 166,
          "y": 160
        },
        "step": 2
      },
      "localId": 4,
      "ports": [
        {
          "name": "defaultInput",
          "localId": 5,
          "type": "input",
          "group": "waitAll"
        },
        {
          "name": "defaultOutput",
          "localId": 6,
          "type": "output"
        }
      ],
      "effects": [
        {
          "type": "effect/core/graph/jump/to",
          "localId": 7,
          "spec": {
            "localIds": [
              6
            ],
            "type": "jump",
            "ignore_next": false
          }
        }
      ],
      "text": "Handler"
    },
    {
      "type": "action",
      "ui": {
        "position": {
          "x": 487,
          "y": 163
        },
        "step": 3
      },
      "localId": 8,
      "ports": [
        {
          "name": "defaultInput",
          "localId": 9,
          "type": "input",
          "group": "waitAll"
        },
        {
          "name": "defaultOutput",
          "localId": 10,
          "type": "output"
        }
      ],
      "effects": [
        {
          "type": "effect/core/var/condition/result",
          "localId": 16,
          "spec": {
            "mode": "and",
            "conditions": [],
            "result_var": ""
          }
        },
        {
          "type": "effect/core/graph/jump/to",
          "localId": 11,
          "spec": {
            "localIds": [
              10
            ],
            "type": "jump",
            "ignore_next": false
          }
        }
      ],
      "text": "Conditions"
    },
    {
      "type": "action",
      "ui": {
        "position": {
          "x": 813,
          "y": 159
        },
        "step": 4
      },
      "localId": 12,
      "ports": [
        {
          "name": "defaultInput",
          "localId": 13,
          "type": "input",
          "group": "waitAll"
        },
        {
          "name": "defaultOutput",
          "localId": 14,
          "type": "output"
        }
      ],
      "effects": [
        {
          "type": "effect/core/graph/jump/to",
          "localId": 15,
          "spec": {
            "localIds": [
              14
            ],
            "type": "jump",
            "ignore_next": false
          }
        }
      ],
      "text": "Background"
    }
  ],
  "edges": [],
  "ui": {
    "position": {
      "x": 0,
      "y": 0,
      "zoom": 1
    },
    "chosenNodeId": 0
  }
}
