import {createSlice} from "@reduxjs/toolkit";

const TagReducer = createSlice({
    name: 'tag',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillTagList(state, action) {
            state.list = action.payload
        },
        setTagItem(state, action) {
            state.item = action.payload;
        },
        setTagListCount(state, action) {
            state.count = action.payload;
        },
        addTagToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeTagFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchTagInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetTagState(state) {
            state.item = {};
            state.list = [];
            state.count = 0;
        },
    }
})

export default TagReducer.reducer

export const {
    fillTagList,
    setTagItem,
    resetTagState,
    setTagListCount,
    patchTagInList,
    addTagToList,
    removeTagFromList,
} = TagReducer.actions