import {createObjectFromObjectsArray, deepGet, isArr, objectLength} from "./functions";

export const handleFieldSpecValues = (t, store, fieldInit) => {
    let isShortList = false;
    let storeName = fieldInit.data;

    if (storeName && storeName.endsWith('Short')) {
        isShortList = true;
        storeName = storeName.slice(0, -5);
    }

    if (fieldInit && storeName && store[storeName]) {
        let field = {...fieldInit};

        let store_items;
        if (isShortList) store_items = store[storeName].short.list;
        else store_items = store[field.data].list;

        let filtered_items = store_items;

        if (!isArr(store_items)) {
            store_items = []
            filtered_items = []
        }

        if (field.data_filters && objectLength(field.data_filters) && store_items.length) {
            filtered_items = store_items.filter((store_item, index) => {
                for (const [key, value] of Object.entries(field.data_filters)) {
                    if (isArr(value) && value.length) {
                        if (!value.includes(deepGet(store_item, key))) {
                            return false;
                        }
                    } else if (deepGet(store_item, key) !== value) {
                        return false;
                    }
                }
                return true;
            });
        }

        // init items
        let items = field.values;

        if (field.data === 'field' || field.data === 'glob' || field.data === 'webhook') {
            items = createObjectFromObjectsArray(filtered_items, 'name', 'title');
        } else if (field.data === 'integration') {
            items = {};
            for (const item of filtered_items) {
                if (field.data_value_field) {
                    const optionValue = deepGet(item, field.data_value_field, 'none')
                    items[optionValue] = t('integration.' + optionValue + '.title')
                } else {
                    const platform_name = deepGet(item, 'integration.platform', 'none')
                    const platform = t('integration.' + platform_name + '.title')
                    // let titleParts = [platform + ':'];
                    let titleParts = [];
                    if (item.title) titleParts.push(item.title);
                    else titleParts.push('#' + item.integration.id);
                    if (!field.data_filters || isArr(field.data_filters['integration.platform'])) titleParts.push(' - ' + platform);
                    // titleParts.push(<span> {' - ' + platform} <span>);
                    items[item.integration.id] = titleParts.join(' ');
                }
            }
        } else if (field.data === 'user') {
            items = {};
            for (const item of filtered_items) {
                let titleParts = [item.id + ': ', item.first_name];
                if (item.title) titleParts.push(item.title);
                if (item.last_name) titleParts.push(item.last_name);
                items[item.id] = titleParts.join(' ') ?? String(item.id);
            }
        } else {
            let optionValueKey = 'id';
            if (field.data_value_field) optionValueKey = field.data_value_field
            items = createObjectFromObjectsArray(filtered_items, optionValueKey, 'title');
        }

        // console.log('data items', items)
        // store final result to effect schema
        field.values = items;
        return field;
    }

    return fieldInit;
}

export const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const uploadFilePreHandle = (
    admin,
    project_id,
    siteSection,
    file,
    imageType = null,
    onProgress = null,
    imgFormat = null,
    formData = null,
) => {
    // console.log('uploadFilePreHandle admin', admin)
    const formBodyData = new FormData();
    const formConfig = {
        headers: {
            ...(admin ? admin.header_authorized : {}),
            "content-type": "multipart/form-data",
        },
        onUploadProgress: event => {
            if (onProgress) onProgress({percent: (event.loaded / event.total) * 100});
        }
    };

    if (formData) {
        for (const [key, value] of Object.entries(formData)) {
            formBodyData.append(key, value);
        }
    } else {
        formBodyData.append("project_id", String(project_id));
        formBodyData.append("section", siteSection);
    }

    if (imageType) {
        // const thumb_params_obj = {size: [100]} // 100px * 100px
        // imageType values: photo = 1920, card = 600, user  = 356, field = 192, avatar = 86
        const thumb_params_obj = {type: imageType, format: imgFormat}
        const thumb_params = JSON.stringify(thumb_params_obj)

        formBodyData.append("processing", thumb_params);
    }

    formBodyData.append("file", file);
    return {config: formConfig, data: formBodyData}
};