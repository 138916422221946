import React, {useEffect} from 'react'
import {Form, Input, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {l, label} from "../../library/locale";
import {dotenv, routes} from "../../config/config";
import {getItemValues} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormTitle} from "../../components/Form/FormTitle";

const {Content} = Layout;

const PasswordForm = () => {
    const section = 'admin'

    // data from URL params
    const params = useParams()
    const user_id = Number(params.user_id)

    const backToPath = `${routes.project_list}/${dotenv.main_project}${routes.local.user_list}/profile/${user_id}`

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    // sync with store
    const {admin} = useSelector(store => store);
    const item = admin.object;

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        'username',
        'password',
    ]
    let formValues = getItemValues(item, formFields)

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized) {
            dispatch({type: 'getAdminItemByUserId', admin, user_id: user_id});
        }

        //eslint-disable-next-line
    }, [admin.authorized])

    useEffect(() => {
        // set field values if correct data received
        if (item.id) {
            form.setFieldsValue(formValues)
        }

        //eslint-disable-next-line
    }, [item.id])


    const backToList = () => navigate(backToPath);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        let data = getItemValues(values, formFields)
        data['user_id'] = user_id

        // console.log('data', data)
        dispatch({type: 'updateAdmin', admin, data});

        data.password = ''
        form.setFieldsValue(data)
        backToList()
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    className="" // old-school-container
                    name="settings"
                    layout="vertical"
                >
                    <FormTitle onBack={backToList}>
                        {l('admin.header.edit')}
                    </FormTitle>

                    <Content className="page-container form-container site-layout-background">

                        <FormItemWrapper desc={label(section, 'username', 'desc')}>
                            <Form.Item
                                label={label(section, 'username', 'label')}
                                name='username'
                                rules={[
                                    {
                                        required: true,
                                        message: l("error.validation.required")
                                    }
                                ]}
                            >
                                <Input placeholder={t(section + '.form.username.label')}/>
                            </Form.Item>
                        </FormItemWrapper>


                        <FormItemWrapper desc={label(section, 'password', 'desc')}>
                            <Form.Item
                                name="password"
                                label={label(section, 'password', 'label')}
                            >
                                <Input.Password placeholder={t(section + '.form.password.label')} />
                            </Form.Item>
                        </FormItemWrapper>

                        <FormSubmit/>
                    </Content>
                </Form>
            </Layout>
        </AppWrapper>
    )
}

export default PasswordForm