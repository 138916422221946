import React from 'react'
import MenuAccordion from "./MenuAccordion";
import {ucFirst} from "../../library/functions";


export const MenuAccordions = ({items, currentProjectId, isOpened}) => {

    return (
        <>
            {
                Object.entries(items).map(([name, item]) => {
                    if (item.items && !item.items.length) return null;

                    const itemKey = `menuItem${ucFirst(name)}`
                    return <MenuAccordion
                        name={name}
                        key={itemKey}
                        idAttr={itemKey}
                        items={item.items}
                        IconHeader={item.iconHeader}
                        currentProjectId={currentProjectId}
                        isOpened={isOpened}
                    />
                })
            }
        </>
    )
}

