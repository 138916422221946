import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    layoutActive: {
        row: 0,
        col: 0,
        sec: 0,
    },
    active: {
        sec: 0,
        con: 0,
        group: 0,
        block: 0,
    },
    layout: [],
    page: [],

    row: {},
    block: {},
}

const BlockReducer = createSlice({
    name: 'pb', // page blocks
    initialState, // : pbInitialState,
    reducers: {
        // setBlockState(state, action) {
        //     state[action.payload.state] = action.payload.items
        // },
        setActive(state, action) {
            state.active = {...state.active, ...action.payload}
        },
        resetActive(state) {
            state.active = {...initialState.active}
        },
        setLayoutActive(state, action) {
            state.layoutActive = {...initialState.layoutActive, ...action.payload}
        },
        resetLayoutActive(state) {
            state.layoutActive = {...initialState.layoutActive}
        },

        setBlock(state, action) {
            state.block = action.payload
        },
        setRow(state, action) {
            state.row = action.payload
        },
        setPageRows(state, action) {
            state.page = action.payload
        },
        setLayoutRows(state, action) {
            state.layout = action.payload
        },
        resetBlockState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },

        // addBlockToList(state, action) {
        //     if (state.addItemToEnd) state.list = [...state.list, action.payload];
        //     else state.list = [action.payload, ...state.list];
        //
        //     // also add to short list:
        //     state.short.list = [...state.short.list, action.payload];
        //     state.short.count = state.short.count + 1;
        //
        //     state.item = action.payload;
        //     state.count = state.count + 1;
        // },
        // removeBlockFromList(state, action) {
        //     const deleted_item = action.payload;
        //
        //     state.list = state.list.filter(p => p.id !== deleted_item.id)
        //     state.count = state.count - 1;
        //
        //     state.short.list = state.short.list.filter(p => p.id !== deleted_item.id)
        //     state.short.count = state.short.count - 1;
        //
        //     if (state.item.id === deleted_item.id) {
        //         state.item = deleted_item
        //     }
        // },
        // patchBlockInList(state, action) {
        //     const oid = action.payload.id;
        //
        //     // general list
        //     const list = [...state.list];
        //     const index = list.findIndex(o => o.id === oid);
        //
        //     if (index !== -1) {
        //         list[index] = {...list[index], ...action.payload};
        //         state.list = list;
        //     }
        //
        //     // short list
        //     const shortList = [...state.short.list];
        //     const shortIndex = shortList.findIndex(o => o.id === oid);
        //
        //     if (shortIndex !== -1) {
        //         shortList[shortIndex] = {...shortList[shortIndex], ...action.payload};
        //         state.short.list = shortList;
        //     }
        //
        //     // item
        //     state.item = action.payload
        // },


    }
})

export default BlockReducer.reducer
export const {
    // setBlockState,

    setActive,
    resetActive,
    setLayoutActive,
    resetLayoutActive,

    setBlock,
    setRow,

    setPageRows,
    setLayoutRows,

    // patchBlockInList,
    // removeBlockFromList,
    // addBlockToList,
    resetBlockState,
} = BlockReducer.actions