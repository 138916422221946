import {createSlice} from "@reduxjs/toolkit";

const FieldValueReducer = createSlice({
    name: 'fieldValue',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillFieldValueList(state, action) {
            state.list = action.payload
        },
        setFieldValueItem(state, action) {
            state.item = action.payload;
        },
        addFieldValueToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        patchFieldValueInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.field_id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            else {
                state.list = [...state.list, action.payload]
            }
            state.item = action.payload
        },
        removeFieldValueFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.field_id !== deleted_item.field_id)
        },
        resetFieldValueState(state) {
            state.list = [];
            state.item = {};
        }
    }

})

export default FieldValueReducer.reducer
export const {
    fillFieldValueList,
    setFieldValueItem,
    addFieldValueToList,
    patchFieldValueInList,
    resetFieldValueState,
    removeFieldValueFromList,
} = FieldValueReducer.actions