// time: 48 min +

export const coreContent = {
    'spoiler/item': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/content/spoiler',
                            spec: {
                                icon: {
                                    name: "plus",
                                    active: 'minus',
                                    set: "regular",
                                },
                                classes: {
                                    title: [
                                        "text-h4",
                                        "font-medium",
                                        "flex",
                                        "flex-row",
                                        "select-none",
                                        "cursor-pointer",
                                        "gap-4",
                                        "pt-2",
                                        "pb-2",
                                        "pl-3",
                                        "pr-3",
                                        "rounded-lg",
                                        "hover:bg-gray-100",
                                    ],
                                    active: [
                                        "text-h4",
                                        "font-medium",
                                        "flex",
                                        "flex-row",
                                        "select-none",
                                        "gap-4",
                                        "cursor-pointer",
                                        "pt-2",
                                        "pb-2",
                                        "pl-3",
                                        "pr-3",
                                        "rounded-lg",
                                        "bg-gray-100",
                                    ],
                                    icon: [
                                        "block",
                                        "pl-2",
                                        "pr-2",
                                        "text-h4"
                                    ],
                                    desc: [
                                        "prose",
                                        "dark:prose-invert",
                                        "lg:prose-lg",
                                        "pt-4",
                                        "pb-4",
                                        "pl-3",
                                        "pr-3",
                                    ],
                                },
                                title: "{locale:container.example.text.header}",
                                content: "{locale:container.example.text.short_desc}"
                            },
                            style: {
                                classes: {}
                            }
                        },
                    ]
                }
            ],
            style: {
                width: {
                    container: 'sm',
                },
                padding: {
                    left: '6',
                    right: '6',
                    top: '10',
                    bottom: '10',
                }
            },
        }]
    },
}