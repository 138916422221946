import React, {useEffect} from 'react'
import classes from './ProjectQuiz.module.scss'
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Card, Layout, Row} from "antd";
import {Banner} from "../../components/Banner/Banner";
import {Content} from "antd/es/layout/layout";
import {l} from "../../library/locale";
import {routes, images, dotenv} from "../../config/config";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {directCreateOrUpdate} from "../../library/containers";
import {findObjectByField, inArray, ucFirst} from "../../library/functions";
import {CustomHeader} from "../../components/Layout/CustomHeader";

const {Meta} = Card;

const ProjectQuiz = () => {
    // set Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // data from GET params
    const params = useParams()
    const step = params.step
    const Step = ucFirst(step)
    const object_id = Number(params.id)

    const {admin, segment, role, answer} = useSelector(store => store)
    const project_item = {id: dotenv.main_project}
    const segments = segment.list
    const roles = role.list

    const business_roles = ['ceo', 'cmo', 'cto', 'other'];
    const education_roles = ['speaker', 'producer', 'cmo', 'cto', 'other'];

    const answers = answer.list
    const current = {
        segment: findObjectByField(answers, 'name', 'segment', {id: 0, value: null}),
        role: findObjectByField(answers, 'name', 'role', {id: 0, value: null}),
    };

    // get data from API
    useEffect(() => {
        if (admin.authorized) {
            dispatch({type: 'get' + Step + 'List', admin, filters: {project_id: project_item.id}})
            dispatch({
                type: 'getAnswerList',
                admin,
                filters: {
                    project_id: project_item.id,
                    user_id: admin.user.id,
                    object_ids: [object_id],
                    names: ['segment', 'role'],
                }
            });
        }

        //eslint-disable-next-line
    }, [admin.authorized, step])

    const onSubmit = (value) => {
        const data = {
            id: current[step].id,
            project_id: project_item.id,
            user_id: admin.user.id,
            object_id: object_id,
            name: step,
            value: value
        }
        directCreateOrUpdate('Answer', data, admin, dispatch);

        if (step === 'segment' && inArray(value, ['business', 'education'])) {
            navigate(`${routes.project_list}/quiz/${object_id}/role`)
        } else {
            navigate(`${routes.project_list}/${object_id}${routes.local.default}`)
        }
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background project-quiz-page">
                {/*<Banner image="universe"/>*/}
                <CustomHeader
                    key="universe-header"
                    className={'padding-none-horizontal'}
                    title={l('project.quiz.' + step + '.title')}
                />
                <Content className="page-container site-layout-background">
                    <Row gutter={20} justify="left" className={`${classes.row}`}>
                        {step === 'segment' ?
                            segments.map((item, index) => {
                                const itemKey = `${step}-item-${item.id}`
                                return <Card key={itemKey}
                                             className={classes.card}
                                             hoverable
                                             bordered={false}
                                             onClick={() => {
                                                 onSubmit(item.name)
                                             }}
                                             cover={<img alt="item.name" src={images.universe.segment[item.name]}/>} >
                                    <Meta
                                        title={l('project.quiz.' + step + '.' + item.name + '.title')}
                                        description={l('project.quiz.' + step + '.' + item.name + '.desc')}
                                    />
                                </Card>
                            }) : null
                        }

                        {step === 'role' ?
                            roles.map((item, index) => {
                                if (
                                    (current.segment.value === 'education' && inArray(item.name, education_roles)) ||
                                    (current.segment.value !== 'education' && inArray(item.name, business_roles))
                                ) {
                                    const itemKey = `${step}-item-${item.id}`
                                    return <Card key={itemKey} className={classes.card}
                                                 hoverable
                                                 bordered={true}
                                                 onClick={() => {
                                                     onSubmit(item.name)
                                                 }}>
                                        <Meta title={l('project.quiz.' + step + '.' + item.name + '.title')}/>
                                    </Card>
                                } else {
                                    return null;
                                }
                            }) : null
                        }
                    </Row>
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default ProjectQuiz