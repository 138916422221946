import {createSlice} from "@reduxjs/toolkit";

const SegmentReducer = createSlice({
    name: ' segment',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillSegmentList(state, action) {
            state.list = [...action.payload]
        },
        setSegmentItem(state, action) {
            state.item = action.payload;
        },
        addSegmentToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        patchSegmentInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        removeSegmentFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
    }
})

export default SegmentReducer.reducer
export const {
    fillSegmentList,
    setSegmentItem,
    addSegmentToList,
    patchSegmentInList,
    removeSegmentFromList,
} = SegmentReducer.actions