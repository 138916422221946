import {createSlice} from "@reduxjs/toolkit";

const DomainReducer = createSlice({
    name: 'domain',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillDomainList(state, action) {
            state.list = action.payload
        },
        setDomainItem(state, action) {
            state.item = action.payload;
        },
        setDomainListCount(state, action) {
            state.count = action.payload;
        },
        addDomainToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeDomainFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchDomainInList(state, action) {
            let list = [];

            if (action.payload.is_main) {
                list = state.list.map(item => {
                    return {...item, is_main: false}
                })
            } else {
                list = [...state.list];
            }

            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetDomainState(state) {
            state.item = {};
            state.list = [];
            state.count = 0;
        },
    }
})

export default DomainReducer.reducer
export const {
    fillDomainList,
    setDomainItem,
    resetDomainState,
    setDomainListCount,
    patchDomainInList,
    addDomainToList,
    removeDomainFromList
} = DomainReducer.actions