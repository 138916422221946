import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    item: {},
    url: "",
    count: 0,
    price: 0,
    amount: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
    timestamp: 0, // update stat by list receive on delete or copy
    statuses: [
        'created',
        'active',
        'finished',
        'canceled',
        'refunded',
    ],
    loading: {
        form: false,
        balance: false,
        tariff: false,
        messages: false,
        project: false,
        promo: false,
        ai: false,
    },
}

const OrderReducer = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderFormLoading(state, action) {
            state.loading = {...state.loading, ...action.payload};
        },
        fillOrderList(state, action) {
            state.list = action.payload
        },
        setOrderItem(state, action) {
            state.item = action.payload;
        },
        setOrderListCount(state, action) {
            state.count = action.payload;
        },
        setOrderStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        setOrderUrl(state, action) {
            state.url = action.payload;
        },
        addOrderToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
            state.timestamp = Date.now();
        },
        removeOrderFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
            state.timestamp = Date.now();
        },
        patchOrderInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
            state.timestamp = Date.now();
        },
        resetOrderState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default OrderReducer.reducer
export const {
    fillOrderList,
    setOrderItem,
    resetOrderState,
    setOrderStoreParam,
    setOrderFormLoading,
    setOrderUrl,
    setOrderListCount,
    patchOrderInList,
    addOrderToList,
    removeOrderFromList
} = OrderReducer.actions