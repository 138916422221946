import React, {useState} from 'react';
import classes from "./Banner.module.scss"
import {dotenv, images} from "../../config/config";

export const Banner = ({image = 'universe'}) => {
    if (!dotenv.show_banners) return null;

    return (
        <div className={classes.banner_wrapper}>
            <div className={classes.banner} style={{backgroundImage: 'url('+images.universe.banner[image]+')'}} />
        </div>
    );
};

