import {createSlice} from "@reduxjs/toolkit";

const IntegrationReducer = createSlice({
    name: 'integration',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillIntegrationList(state, action) {
            state.list = [...action.payload]
        },
        setIntegrationItem(state, action) {
            state.item = action.payload;
        },
        addIntegrationToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeIntegrationFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0;  // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchIntegrationInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
            // console.log('patchGraphInList state.item', state.item)
        },
        resetIntegrationState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default IntegrationReducer.reducer
export const {
    fillIntegrationList,
    setIntegrationItem,
    addIntegrationToList,
    removeIntegrationFromList,
    patchIntegrationInList,
    resetIntegrationState,
} = IntegrationReducer.actions