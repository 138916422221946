import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import classes from './EventContainer.module.scss';
import AccordPanel from "../AccordPanel/AccordPanel";
import {useTranslation} from "react-i18next";
import {Button, Divider, Radio, Switch} from "antd";
import {PlusOutlined, CloseOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import Container from "./Container";
import {events, specialEventTypes} from "../../../../../../schemas/frontend/events";
import FlowContext from "../../../../FlowContext";
import {copyValueToClipboard} from "../../../../../../library/clipboard";
import {FormItemWrapper} from "../../../../../../components/Form/FormItemWrapper";
import {FormItemLabel} from "../../../../../../components/Form/FormItemLabel";
import {dotenv} from "../../../../../../config/config";
import Fa from "../../../../../../components/Icon/Fa";

export const getConditions = (dispatch, t, nodeLocalId, conditions, effectLocalId, updateNodeEffectCondition, deleteEffectCondition, switchAndOrValue) => {

    const deleteCondition = (condLocalId) => {
        deleteEffectCondition(nodeLocalId, effectLocalId, condLocalId);
    }

    const changeNegativeStatus = (condLocalId, currentStatus) => {
        updateNodeEffectCondition(nodeLocalId, effectLocalId, condLocalId, {negative: !currentStatus});
    }

    if (conditions) {
        return conditions.map((condition, index_condition) => {
            let helpUrl = dotenv.docs_url
            let parts = condition.type.split('/');

            let result = ['graph'];
            result = result.concat(parts.slice(0, 3));
            helpUrl += '/' + result.join('/') + '/' + parts.slice(3).join('-');

            const condTitle = (condition.title) ? condition.title : t(parts.join('.') + '.title');


            return (<div
                id={condition.localId}
                key={`conditions_block_${condition.localId}`}
                data-checked='true'
                className={classes.conditions_block}
            >
                <div className={classes.wrapper_head_panel}>
                    <div className={classes.action_title}>{condTitle} <Button
                        size="small"
                        type="text"
                        href={helpUrl}
                        target={'_blank'}
                        className="inverted font-size-sm"
                        icon={<Fa icon="question-circle"/>}
                    /></div>
                    <div className={classes.action_mode}>
                        <Switch
                            checked={condition.negative}
                            className={`switch-danger ${classes.button_fix}`}
                            size='small'
                            onChange={(e) => {
                                changeNegativeStatus(condition.localId, condition.negative);
                            }}
                        />
                        <div id={`text_${condition.localId}`} className={classes.action_mode_title}>
                            {t('common.not').toUpperCase()}
                        </div>
                    </div>
                    <div className={classes.action_close} onClick={deleteCondition.bind(this, condition.localId)}>
                        <CloseOutlined/></div>
                </div>
                <Container
                    nodeLocalId={nodeLocalId}
                    ownerLocalId={effectLocalId} // parent effect local ID
                    localId={condition.localId}
                    type={condition.type}
                    spec={condition.spec}
                    isCollapsed={!!condition.collapsed}
                />
                <Divider className="cond-divider" dashed={true}>
                    {switchAndOrValue === 'or' ? t('common.or') : t('common.and')}
                </Divider>
            </div>)
        })
    } else {
        return null;
    }
}

export const renderSwitchAndOr = (t, conditions, switchAndOrValue, nodeLocalId, effectLocalId, updateNodeEffect) => {
    const onChangeRadio = (e) => {
        updateNodeEffect(nodeLocalId, effectLocalId, {mode: e.target.value});
    }

    if (conditions && conditions.length > 0) {
        return (<div className={classes.divider_switch}>
            <Radio.Group
                className={`${classes.divider_radio} float-right`}
                onChange={onChangeRadio}
                value={switchAndOrValue}
            >
                <Radio value="and">{t('common.and').toUpperCase()}</Radio>
                <Radio value="or">{t('common.or').toUpperCase()}</Radio>
            </Radio.Group>
            <div className={classes.divider_label}>{t('graph.container.right.condition.divider_type')}</div>
        </div>)
    } else {
        return null;
    }
}

const EventContainer = (props) => {
    const {nodeLocalId, localId, title, type, spec, conditions, filterEffect, isCollapsed, isIgnore} = props;
    const {admin, project, webhook} = useSelector(store => store)

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {
        updateNodeEffect, updateNodeEffectCondition, deleteEffectCondition, setControlPanel, setChosenContainerId
    } = useContext(FlowContext);
    const switchAndOrValue = filterEffect.spec.mode;

    let webhookItem = {};
    const isWebhookType = type === specialEventTypes.webhook;

    if (isWebhookType && webhook.list.length && spec && spec.webhookId) {
        webhookItem = webhook.list.find(item => item.id === spec.webhookId)
        if (!webhookItem) webhookItem = {}
    }

    useEffect(() => {
        if (isWebhookType
            && admin.authorized && project.item.id
            && spec && spec.webhookId
            && (!webhookItem.id)
        ) {
            dispatch({type: 'getWebhookItem', admin, id: spec.webhookId});
        }
        //eslint-disable-next-line
    }, [admin.authorized, project.item.id, spec, webhook.list.length])

    const choiceCondition = () => {
        setChosenContainerId(filterEffect.localId);
        setControlPanel('conditions');
    }

    const webhookControls = () => {
        if (!webhookItem.webhook_url) return null
        const webhookDomId = 'webhook-url-' + localId

        return <FormItemWrapper className="ant-form-vertical" desc={t('common.form.webhook.desc')}>
            <FormItemLabel htmlFor={webhookDomId} label={t('common.form.webhook.label')}/>
            <input
                id={webhookDomId}
                type="text"
                className="ant-input"
                readOnly={true}
                placeholder={t('common.form.webhook.placeholder')}
                onClick={copyValueToClipboard}
                value={webhookItem.webhook_url ?? ''}
            />
        </FormItemWrapper>
    }

    let event = events.find(ev => ev.type === type);
    if (!event) event = {icon: "trash", title: t('graph.flow.container.deprecated.title')}
    return (<>
        <AccordPanel
            effectType={type}
            nodeLocalId={nodeLocalId}
            localId={localId}
            icon={event.icon}
            title={title ? title : event.title}
            isCollapsed={isCollapsed}
            isIgnore={isIgnore}
            showContextButton={false}
            empty={false}
        >
            {isWebhookType ? webhookControls() : null}
            {renderSwitchAndOr(t, conditions, switchAndOrValue, nodeLocalId, filterEffect.localId, updateNodeEffect)}
            {getConditions(dispatch, t, nodeLocalId, conditions, filterEffect.localId, updateNodeEffectCondition, deleteEffectCondition, switchAndOrValue)}
            <div className={classes.buttons_conditions}>
                <Button className={classes.add_cond_btn} type="text" icon={<PlusOutlined/>}
                        onClick={choiceCondition}>
                    {t('common.button.condition')}
                </Button>
            </div>
        </AccordPanel>
    </>)
}

export default EventContainer