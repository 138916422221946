import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {NoForm} from "../Form/NoForm";
import {FormInput} from "../Form/FormInput";
import {useTranslation} from "react-i18next";

const InputModal = (
    {
        children,
        isVisible,
        setVisible,
        onModalOk = null,
        maxLength = null,
        title = '',
        placeholder = '',
        className = '',
        initValue = '',
        desc = '',
        loading = false,
    }
) => {
    let classes = 'modal-clean';
    if (className) classes += ' ' + className;

    const {t} = useTranslation();
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState(initValue);

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                if (inputRef && inputRef.current) {
                    const {input} = inputRef.current
                    input.focus()
                }
            }, 500);
        }
    }, [isVisible]);

    const handleOk = () => {
        if (onModalOk) onModalOk(inputValue);
        handleCancel();
    };

    const handleCancel = () => {
        setInputValue('');
        setVisible(false);
    };

    const onChange = (e) => {
        setInputValue(e.target.value)
    }

    const onSubmit = (e) => {
        onChange(e)
        handleOk()
    }

    const onKeyDown = (e) => {
        if (e.code === 'Enter') {
            onSubmit(e)
        }
    }

    return (
        <Modal
            centered={true}
            className={classes}
            title={title}
            open={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="modal-back"
                    type="text"
                    onClick={handleCancel}
                    className="inverted"
                >{t('common.action.cancel')}</Button>,
                <Button
                    key="modal-submit"
                    type="primary"
                    onClick={handleOk}
                    icon={<CheckOutlined/>}
                    loading={loading}
                >{t('common.action.apply')}</Button>
            ]}
        >
            <NoForm>
                <FormInput
                    className="margin-none"
                    label=""
                    desc={desc}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    autoFocus={true}
                    inputRef={inputRef}
                    inputValue={inputValue} // must be state
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
                {children}
            </NoForm>
        </Modal>
    );
};

export default InputModal;

