import React from 'react'
import classes from "./BlockList.module.scss";
import {Button} from "antd";
import {
    deepCopyObject,
    deepGet,
    is,
    objectLength,
} from "../../../../library/functions";
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import {Draggable,} from "react-beautiful-dnd";
import {blocks} from "../Templates/blocks";
import {defaultBlockStyle} from "../StyleFields";
import {styleComposer} from "../StyleComposer";
import {useDispatch, useSelector} from "react-redux";
import {setActive, setBlock} from "../../../../redux/reducers/BlockReducer";

export const BlockList = ({sectionId, container, group, onChange, getNewId}) => {
    const list = deepGet(group, 'items', []);
    const dispatch = useDispatch();

    const {pb} = useSelector(store => store)
    const active = pb.active;

    const getBlockSchema = (type) => {
        if (!type) return {fields: []};
        const res = blocks.find(item => item.type === type);

        if (res) return res;
        return {fields: []};
    }

    const openBlock = (item) => {
        // console.time("runtime");

        let itemData = {...item};
        if (!itemData.style || !objectLength(itemData.style)) itemData['style'] = {...defaultBlockStyle};
        if (!itemData.wrap || !objectLength(itemData.wrap)) itemData['wrap'] = {...defaultBlockStyle};

        dispatch(setBlock(itemData));
        dispatch(setActive({sec: sectionId, con: container.id, group: group.id, block: item.id}));
    }

    const onCopy = (index) => {
        let blocklist = [...list];
        let newBlock = deepCopyObject(blocklist[index]);
        newBlock.id = getNewId();
        blocklist.splice(index + 1, 0, newBlock);
        onChange(blocklist);
    }

    const onDelete = (index) => {
        let newList = [...list];
        newList.splice(index, 1);
        onChange(newList);
    }

    const onIgnore = (index, item) => {
        let newList = [...list];
        newList[index] = {...item, ignore: !item.ignore};
        onChange(newList);
    }

    const renderControls = (item, index) => {
        const isIgnored = item.ignore || false;

        return <div className="float-right">
            <Button.Group className={classes.controls}>
                <Button
                    className={`system ${classes.controlButton}`}
                    type="default"
                    icon={<EditOutlined />}
                    onClick={() => openBlock(item)}
                />

                <Button
                    className={`system ${classes.controlButton}`}
                    type="default"
                    icon={isIgnored ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                    onClick={() => onIgnore(index, item)}
                />

                <Button
                    className={`system ${classes.controlButton}`}
                    type="default"
                    icon={<CopyOutlined/>}
                    onClick={() => onCopy(index)}
                />
                <Button
                    className={`system ${classes.controlButton}`}
                    type="default"
                    icon={<DeleteOutlined/>}
                    onClick={() => onDelete(index)}
                />
            </Button.Group>
        </div>
    }

    const renderBlock = (blockSchema, item, classMap, styleMap) => {
        if (!blockSchema || !blockSchema.module) return <div title='Block type error'/>;

        if (blockSchema.container === false) {
            return blockSchema.module(item.spec, {style: item.style, wrap: item.wrap}, classMap.item, styleMap.item)
        } else {
            return <div className={classMap.item.join(' ')} style={styleMap.item}>
                {blockSchema.module(item.spec, {style: item.style, wrap: item.wrap})}
            </div>
        }
    }

    return list.map((item, index) => {
        if (!item || !item.id) return null;

        const blockSchema = getBlockSchema(item.type);

        // console.log('Draggable key', `b${sectionId}-${item.id}-${index}`)
        return <Draggable
            // key={item.id}
            key={`b${sectionId}-${item.id}-${index}`}
            draggableId={String(item.id)}
            index={index}
        >
            {(provided, snapshot) => {
                let {classMap, styleMap} = styleComposer(
                    item,

                    {
                        wrap: ['p-block-wrap', classes.blockWrapper],
                        bg: ['p-block', classes.blockContent],
                        item: [], // .p-block-content (optional) - for 100% height on grid view
                    },

                    {
                        horizontal: false,
                        wrap: {},
                        bg: {},
                        item: {},
                    }
                );

                // if (active.block === item.id) {
                //     console.log('item', item)
                //     console.log('classMap', classMap)
                //     console.log('styleMap', styleMap)
                // }

                if (active.block === item.id) classMap.wrap.push(classes.current);
                if (snapshot.isDragging) classMap.wrap.push(classes.dragging);
                if (item.ignore) classMap.wrap.push(classes.ignored);

                // TODO: fix key duplicate for Draggable (on page reload)
                // console.log('item.id', item.id, index)

                return (<div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}

                    // key={`block${item.id}-${index}`}
                    key={item.id}
                    data-id={item.id}
                    className={classMap.wrap.join(' ')}
                    onDoubleClick={() => openBlock(item)}
                    onClick={() => dispatch(setActive({
                        sec: sectionId,
                        con: container.id,
                        group: group.id,
                        block: item.id
                    }))}
                    // onContextMenu={(e) => {
                    //     e.preventDefault();
                    //     openBlock(item);
                    // }}
                >

                    <div className={classMap.bg.join(' ')} style={styleMap.bg}>
                        {renderControls(item, index)}
                        {renderBlock(blockSchema, item, classMap, styleMap)}
                    </div>
                </div>)
            }}
        </Draggable>
    })
}