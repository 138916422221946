import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {Layout} from "antd";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {FieldListTable} from "./FieldListTable";
import {ListHeader} from "../../components/List/Header/ListHeader";
import {ListFolderManager} from "../../components/List/Folder/ListFolderManager";
import {useTranslation} from "react-i18next";
import {inArray} from "../../library/functions";

const {Content} = Layout;

const FieldList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'field'
    const default_ordering = '-created_at';

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store and state
    const {admin, project, field} = useSelector(store => store)
    const [ordering, setOrdering] = useState('default')
    const [filters, setFilters] = useState({})

    // init universal vars
    const project_item = project.item
    const list = field.list.filter(item => inArray(item.system_field_type, ['crm', 'form']))

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            // console.log('admin.authorized', admin.authorized)
            const order = (ordering === 'default') ? default_ordering : ordering
            let filters_result = {project_id: project_item.id, ordering: order, system_field_types: ['crm', 'form']}
            if (filters) filters_result = {...filters_result, ...filters}
            dispatch({type: 'getFieldList', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, ordering, section])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordCreate = (folder_id = 0) => {
        navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0?folder=${folder_id}`)
    }

    const filterFields = [
        [
            {
                name: 'data_types',
                type: 'tags',
                placeholder: t('common.placeholder.tags'),
                localized: true,
                values: [
                    "text",
                    "float",
                    "array",
                    "json",
                    "date",
                    "time",
                    "datetime"
                ]
            },
            {
                name: 'system_field_types',
                type: 'tags',
                localized: true,
                placeholder: t('common.placeholder.tags'),
                values: ['crm', 'form']
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'published',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimaryWithFolder={recordCreate}
                />
                <Content className="page-container site-layout-background">
                    <FieldListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        setFilters={setFilters}
                        setOrdering={setOrdering}
                        recordCreate={recordCreate}
                    />
                    <ListFolderManager section={section} project_item={project_item} list={list}/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default FieldList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link