import React, {useEffect, useState} from 'react'
import {
    filterArrayByFieldValue,
    getIntArrayFromSearchArray,
    inArray, objectLength,
    onFolderOpen, replace,
    ucFirst
} from "../../../library/functions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {l} from "../../../library/locale";
import FolderTable from "./FolderTable";
import {Button} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {routes} from "../../../config/config";
import Preloader from "../../../containers/System/Preloader";
import {setFolderList, setFolderSection} from "../../../redux/reducers/FolderReducer";
import {setSiteList, setSiteSection} from "../../../redux/reducers/SiteReducer";


const FoldersList = (
    {
        section,
        admin,
        project,
        filters,
        items,
        columns,
        recordCreate,
        folderSaga = 'Folder',
        sectionSaga = null,
        onDelete = null,
        onRestore = null,
    }
) => {
    const saga = sectionSaga ? sectionSaga : ucFirst(section);
    const adminSection = section;
    const navigate = useNavigate();

    // path params
    const params = useParams()
    const project_id = Number(params.project_id);

    // get params
    const [searchParams, setSearchParams] = useSearchParams();
    let openedFolders = getIntArrayFromSearchArray(searchParams, 'folder', []);
    let firstFolder = searchParams.get('folder');

    // init hooks
    const dispatch = useDispatch();
    const {folder, site} = useSelector(store => store);
    const storeItem = (folderSaga === 'Site') ? site : folder;
    let folders = storeItem.list;

    // init states
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [foldersResult, setFoldersResult] = useState([])
    const [folderItems, setFolderItems] = useState({})
    // const [openedFolders, setOpenedFolders] = useState([])

    const correctProject = project.id && project.id === project_id;
    // console.log('PROJECT', project.id, project_id, correctProject)

    useEffect(() => {
        // console.log('useLayoutEffect RESET LIST')
        // reset reducer folders list
        if (folderSaga === 'Site') {
            dispatch(setSiteList(null));
            dispatch(setSiteSection(section));
        } else {
            dispatch(setFolderList(null));
            dispatch(setFolderSection(section));
        }
        //eslint-disable-next-line
    }, [project_id])

    // get data from API
    useEffect(() => {
        // console.log('useEffect 1')
        if (admin.authorized && correctProject) {
            // get new folders list or current section
            let folder_filters = {project_id: project_id, section: section};
            if (filters.is_deleted) folder_filters['is_deleted'] = filters.is_deleted;
            // if (folderSaga === 'Site') folder_filters['types'] = ['game'];
            dispatch({type: 'get' + folderSaga + 'List', admin, filters: folder_filters});
        }

        //eslint-disable-next-line
    }, [admin.authorized, correctProject, filters]);

    useEffect(() => {
        // console.log('useEffect 2', folder.section, folder.section === section, folders)
        if (correctProject && storeItem.section === section && folders) {
            // set folders list
            let foldersListResult = [{id: 0, title: l(section + '.list.no_folder')}];
            if (folders.length) foldersListResult = [...foldersListResult, ...folders.filter(
                folder => (folder.type === undefined || folder.type === 'game')
            )];
            setFoldersResult(foldersListResult);
        }

        //eslint-disable-next-line
    }, [items, storeItem]);

    useEffect(() => {
        // set folders
        let foldersLengths = {}, folderItemsTemp = {};
        if (foldersResult.length) {
            for (const folder of foldersResult) {
                folderItemsTemp[folder.id] = filterArrayByFieldValue(items, 'folder', folder.id);
                foldersLengths[folder.id] = folderItemsTemp[folder.id].length;
            }
            setFolderItems(folderItemsTemp);
        }

        // console.log('useEffect 3', objectLength(folderItemsTemp), folderItemsTemp)
        if (objectLength(folderItemsTemp)) {
            // console.log('folderItems', firstFolder, folderItemsTemp);
            if (firstFolder === 'auto') {
                for (let [folderId, folderContent] of Object.entries(folderItemsTemp)) {
                    if (folderContent.length > 0) {
                        // console.log('==== NAV: items ====', folderId, folderContent.length)
                        navigateToFolder(folderId);
                        return // end page rendering
                    }
                }
                // console.log('==== NAV: default ====', folderItemsTemp)
                navigateToFolder(0);
            }
        }
        //eslint-disable-next-line
    }, [foldersResult]);

    const onFolderOpenLocal = (id, isOpen) => {
        return onFolderOpen(id, isOpen, searchParams, setSearchParams);
    };

    const setFolderToPath = (folderId) => {
        const newPath = `${routes.project_list}/${project_id}/${adminSection}?folder=${folderId}`
        window.history.replaceState(null, '', newPath)
        // firstFolder = folderId
        // openedFolders = [parseInt(folderId)]
        // setOpenedFolders([parseInt(folderId)])
        // console.log('openedFolders', folderId, firstFolder, openedFolders)
    };

    const navigateToFolder = (folderId) => {
        // window.location.replace(`${routes.project_list}/${project.id}/${adminSection}?folder=${folderId}`);
        // navigate(`${routes.project_list}/${project_id}/${adminSection}?folder=${folderId}`);
        replace(`${routes.project_list}/${project_id}/${adminSection}?folder=${folderId}`);
    };

    const recordCopy = (id) => dispatch({type: 'copy' + saga, admin, id})

    const recordDelete = (id) => {
        if (onDelete) onDelete(id);
        else dispatch({type: 'delete' + saga, admin, id});
    }

    const recordRestore = (id) => {
        if (onRestore) onRestore(id);
        else dispatch({type: 'restore' + saga, admin, data: {id: id, project_id: project.id}})
    }

    const deleteSelected = () => {
        for (const rowKey of selectedRowKeys) recordDelete(parseInt(rowKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const rowKey of selectedRowKeys) recordRestore(parseInt(rowKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    if (!project.id || firstFolder === 'auto') {
        // console.log('preloader', project.id, firstFolder)
        return <Preloader/>
    } // can not open folders without project.id

    let folderComponents = [];
    for (const folder of foldersResult) {
        // console.log('folderItems[folder.id]', folder.id, folderItems[String(folder.id)], folderItems)
        const isOpened = (inArray(folder.id, openedFolders));
        folderComponents.push(
            <div key={folder.id + '-folder'} className="margin-bottom-ps">
                <FolderTable
                    folder={folder}
                    columns={columns}
                    items={folderItems[folder.id]}
                    opened={isOpened}
                    folderType={folderSaga === 'Site' ? 'game_folder' : 'folder'}
                    recordCreate={recordCreate}
                    onFolderOpen={onFolderOpenLocal}
                    rowSelection={rowSelection}
                />
            </div>
        )
    }

    return (<>
        {folderComponents}
        <div className={isCheckboxButtons ? "button-flying" : "hide"}>
            <Button
                className={filters.is_deleted ? "hide" : ""}
                onClick={deleteSelected}
                icon={<DeleteOutlined/>}>{l('common.action.delete')}</Button>
            <Button
                className={filters.is_deleted ? "" : "hide"}
                onClick={restoreSelected}
                icon={<DeleteOutlined/>}>{l('common.action.restore')}</Button>
        </div>
    </>);
}

export default FoldersList