import React, {memo, useContext} from 'react';
import classes from "./Node.module.scss";
import {PortButtons, PortsGroup} from "../Port/PortsGroup";
import {useTranslation} from "react-i18next";
import {nodeTypesProps} from "../../library/flowFunctions";
import NodeButtonsWrapper from "../Blocks/NodeButtons/NodeButtonsWrapper";
import NodeButtons from "../Blocks/NodeButtons/NodeButtons";
import FlowContext from "../../FlowContext";
import NodeGraphTitle from "../ControlPanel/components/NodeTitle/NodeGraphTitle";
import {convertTextToHtmlEditable} from "../../../../library/functions";

// console.log('props', props); // example:
// props = {
//   "id": "2",
//   "data": {
//     "test": "param"
//   },
//   "type": "message",
//   "xPos": 280,
//   "yPos": 30,
//   "selected": true,
//   "isConnectable": true,
//   "sourcePosition": "bottom",
//   "targetPosition": "top",
//   "dragging": true,
//   "zIndex": 1000
// }

export default memo(({id = 0, type = '', data = {}, dragging = true}) => {

    let {onCopy, onDelete, onIgnore} = useContext(FlowContext);
    const {t} = useTranslation();
    const ports = data.ports;

    // node id (localId) and other is required fields
    if (!id) {
        console.error('[ID] is required field. Received: ', id);
        return null;
    }
    if (!type) {
        console.error('[Type] is required field. Received: ', type);
        return null;
    }
    if (!ports) {
        console.error('[Ports] is required field. Received: ', ports);
        return null;
    }

    const nodeIcon = nodeTypesProps[type].icon;
    const nodeTitle = ('title' in data && data.title) ? data.title : t('graph.flow.node.' + type + '.title');

    let nodeText = data.label;
    if (!nodeText){
        if (data.step) nodeText = t('graph.flow.node.step') + data.step;
        else nodeText = t('graph.flow.node.text') + id;
    }

    return (
        <div className="graph-node-wrapper" data-ignored={!!data.ignored} >
            <NodeButtonsWrapper>
                <NodeButtons type={type} onCopy={onCopy} onDelete={onDelete} onIgnore={onIgnore} isIgnored={data.ignored}/>
            </NodeButtonsWrapper>

            <PortsGroup allPorts={ports} groupName="inputs" portType="target"/>

            <div className={classes.node_background}/>
            <div className={classes.graph_node}>
                <div className={`${classes.node_title} ${classes[type + '_title']}`}>
                    <div>
                        {nodeIcon}
                        <div className={classes.node_title_text}>{nodeTitle}</div>
                    </div>
                    <div className={classes.node_id}>#{id}</div>
                </div>
                <div className={classes.node_text}>
                    <span dangerouslySetInnerHTML={{__html: convertTextToHtmlEditable(nodeText)}} />
                    {/*<NodeGraphTitle chosenNode={{localId: id, text: nodeText}} />*/}
                </div>

                {data.image ? <div className={classes.node_image_wrapper}>
                    <div className={classes.node_image} style={{backgroundImage: 'url("' + data.image + '")'}} />
                </div> : null}
            </div>

            <PortsGroup allPorts={ports} groupName="outputs"/>
            <PortButtons allPorts={ports} locale={t} groupName="branching"/>
            <PortButtons allPorts={ports} locale={t} groupName="buttons"/>

            <div className={classes.node_selected}/>
        </div>
    );
});
