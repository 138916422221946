export const corePage = {
    'courses': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/cards',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: 'custom',
                                style: 'cards',
                                get_desc: true,
                                desc_limit: '90',
                                cards_per_row: '3',
                                show_image: true,

                                tags: "",
                                check_access: true,
                                show_closed: true,

                                check_parent: false,
                                closed_by_default: true,

                                active: {
                                  enable: false,
                                  label: "{locale:common.action.open}",
                                  icon: {
                                    name: "eye",
                                    set: "regular"
                                  }
                                },
                                disabled: {
                                  label: "{locale:container.example.pages.access.get}",
                                  icon: {
                                    name: "lock",
                                    set: "regular"
                                  }
                                },
                            },
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'lg',
                },
                padding: {
                    top: '6',
                    bottom: '6',
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    'lessons': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/cards',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: 'custom',
                                style: 'blocks',
                                get_desc: true,
                                show_image: true,
                                desc_limit: '115',
                                cards_per_row: '3',

                                tags: "",
                                check_access: true,
                                show_closed: true,

                                check_parent: true,
                                closed_by_default: false,

                                active: {
                                  enable: false,
                                  label: "{locale:common.action.open}",
                                  icon: {
                                    name: "eye",
                                    set: "regular"
                                  }
                                },
                                disabled: {
                                  label: "{locale:container.example.pages.access.get}",
                                  icon: {
                                    name: "lock",
                                    set: "regular"
                                  }
                                },
                            },
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'lg',
                },
                padding: {
                    top: '6',
                    bottom: '6',
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    'list': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/list',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: '-published_at',
                                page_size: '100',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    left: '6',
                    right: '6',
                    top: '6',
                    bottom: '10',
                }
            },
        }]
    },
    'article': {
        content: [{
            type: 'con',
            items: [
                 {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.pages.article.title}',
                                level: 'h1',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.pages.article.content}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ],
                },
            ],
            style: {
                width: {
                    container: 'md',
                },
                padding: {
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    'category': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.pages.category.title}',
                                level: 'h1',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.text.short_desc}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                        {
                            type: 'core/page/list',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: '-published_at',
                                page_number: '{var:request.query.page|1}',
                                page_size: '20',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    left: '6',
                    right: '6',
                    bottom: '10',
                }
            },
        }]
    },
    'topic': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/list',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: '-published_at',
                                page_number: '{var:request.query.page|1}',
                                page_size: '100',
                                get_desc: true,
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'lg',
                },
                padding: {
                    left: '6',
                    right: '6',
                    top: '6',
                    bottom: '6',
                }
            },
        }]
    },
    'newest': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/cards',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: '-published_at',
                                style: 'blocks',
                                get_desc: true,
                                show_image: true,
                                desc_limit: '115',
                                cards_per_row: '3',
                                page_number: '{var:request.query.page|1}',
                                page_size: '5',

                                tags: "",
                                check_access: true,
                                show_closed: true,
                                closed_by_default: false,

                                active: {
                                  enable: false,
                                  label: "{locale:common.action.open}",
                                  icon: {
                                    name: "eye",
                                    set: "regular"
                                  }
                                },
                                disabled: {
                                  label: "{locale:container.example.pages.access.get}",
                                  icon: {
                                    name: "lock",
                                    set: "regular"
                                  }
                                },
                            },
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'md',
                },
                padding: {
                    top: '6',
                    bottom: '6',
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    'popular': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/cards',
                            spec: {
                                parent_id: '{var:object.id}',
                                order: '-hits',
                                style: 'blocks',
                                get_desc: true,
                                show_image: true,
                                desc_limit: '115',
                                cards_per_row: '3',
                                page_size: '5',

                                tags: "",
                                check_access: true,
                                show_closed: true,
                                closed_by_default: false,

                                active: {
                                  enable: false,
                                  label: "{locale:common.action.open}",
                                  icon: {
                                    name: "eye",
                                    set: "regular"
                                  }
                                },
                                disabled: {
                                  label: "{locale:container.example.pages.access.get}",
                                  icon: {
                                    name: "lock",
                                    set: "regular"
                                  }
                                },
                            },
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'md',
                },
                padding: {
                    top: '6',
                    bottom: '6',
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
}