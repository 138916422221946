import React, {useState} from 'react'
import classes from '../../Sections.module.scss'
import '../../TabsStyles.scss'
import SearchContainers from "../../../Search/SearchContainers";
import {Tabs} from 'antd';
import Scrollbar from "react-scrollbars-custom";
import Category from "./Categories/Category";
import {useTranslation} from 'react-i18next';
import {events_categories, events} from "../../../../../../../../schemas/frontend/events";
import {getItemStorage, setItemStorage} from "../../../../../../../../library/functions";
import List from "./Categories/List/List";

const sectionType = 'events';
const {TabPane} = Tabs;

const SectionsEvents = ({isBlock = false}) => {
    const {t} = useTranslation()

    const activeMode = getItemStorage(sectionType);
    const [activeTab, setActiveTab] = useState(activeMode ?? 0);
    const [valueState, setValueState] = useState('');

    const sections = [];
    events.forEach(cat => {
        const section = cat.type.split('/')[1];
        if (!sections.includes(section)) sections.push(section);
    })

    if (!sections.length) return null;

    const changeActiveTab = (index_button) => {
        setItemStorage(sectionType, index_button);
        setActiveTab(index_button);
    }

    const TabPanes = () => {
        if (sections.length < 2) {
            const catList = events_categories[sections[0]];
            return <Category section={sections[0]} catList={catList}/>
        } else {
            return (
                <Tabs className="control-panel-tabs" defaultActiveKey={activeTab} onChange={changeActiveTab}> {
                    sections.map((section, index) => {          // section = core или platform
                        const catList = events_categories[section];
                        return (
                            <TabPane tab={t(`event.${section}.title`)} key={index}>
                                <Category section={section} catList={catList}/>
                            </TabPane>
                        )
                    })
                } </Tabs>
            )
        }
    }

    const result = <div className={`${classes.wrapper_content_sections} container-catalog-wrapper`}>
        <SearchContainers
            valueState={valueState}
            setValueState={setValueState}
            title={t('graph.flow.catalog.search.event.placeholder')}
        />
        <div className={classes.wrapper_sections_list}>
            {valueState ? <List containers={events}/> : TabPanes()}
        </div>
    </div>

    return isBlock ? <div className={classes.block_mode_wrapper}>{result}</div> : <Scrollbar>{result}</Scrollbar>
}

export default SectionsEvents