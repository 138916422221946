import React from 'react'
import classes from "../Card/Card.module.scss";
import {createMarkup} from "../../library/functions";
import {dotenv, routes} from "../../config/config";
import {AiOutlineCheck, AiOutlineClockCircle, AiOutlineEdit} from "react-icons/ai";
import {Button, Col, Row, Tooltip} from "antd";
import {l} from "../../library/locale";
import {NavigateBtn} from "../Navigate/Navigate";
import {useTranslation} from "react-i18next";

const CardAccount = ({item, status, bio, changeStatus}) => {
    const {t} = useTranslation();

    return (
        <div className="account-block margin-bottom-ps margin-top-ps site-layout-background border-radius-lp">
            <div className={classes.card}>
                <Row gutter={1}>
                    <Col xs={24}>
                        {/* DESKTOP only */}
                        <div className="hidden-xs">
                            <div className={`${classes.image} float-left`}>
                                <img src={item.photo_url} alt={'Avatar'}/>
                            </div>
                            <div className={`float-right`}>
                                <NavigateBtn
                                    link={routes.admin.settings}
                                    icon={<AiOutlineEdit/>}
                                    className={classes.btn_edit}
                                >{l('common.action.edit')}</NavigateBtn>
                            </div>
                            <div className={classes.offer}>
                                <h2>{item.first_name} {item.last_name}</h2>
                                <p className={classes.offer_text}>
                                    <span dangerouslySetInnerHTML={createMarkup(bio)}/>
                                </p>
                                <p>
                                    {dotenv.is_gamificated ? <Tooltip title={l('common.status.change')} placement={'right'}>
                                        <Button
                                            onClick={changeStatus}
                                            icon={status ? <AiOutlineCheck/> : <AiOutlineClockCircle/>}
                                            className={` ${classes.status} btn-colored ${status ? "btn-success" : "btn-gray"}`}
                                        >
                                            {status ? t('account.status.free') : t('account.status.working')}
                                        </Button>
                                    </Tooltip> : null}
                                </p>
                            </div>
                        </div>

                        {/* MOBILE only */}
                        <div className="visible-xs">
                            <div className={classes.row_mob}>

                                <div className={`${classes.image} float-left`}>
                                    <img alt="Avatar" src={item.photo_url}/>
                                </div>

                                <div className={`float-right`}>
                                    <NavigateBtn
                                        link={routes.admin.settings}
                                        icon={<AiOutlineEdit/>}
                                        className={classes.btn_edit}
                                    />
                                </div>

                                <div className={classes.offer}>
                                    <h3>{item.first_name} {item.last_name}</h3>
                                </div>

                            </div>
                            <div>
                                <div className={classes.offer_text}>
                                    <span dangerouslySetInnerHTML={createMarkup(bio)}/>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>

                <Col xs={24} sm={10}>

                </Col>

                {/*<div className="hidden-xs">*/}
                {/*    <NavigateLink*/}
                {/*        link={routes.admin.settings}*/}
                {/*        children={l('common.action.change')}*/}
                {/*        className="float-right"*/}
                {/*    />*/}
                {/*    /!*<span>cash.multy.expert</span>*!/*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default CardAccount

export const ServiceDescription = ({about}) => {
    return (
        <div className={classes.content}>
            <div dangerouslySetInnerHTML={createMarkup(about)}/>
            {/*<p className="margin-top-sm">*/}
            {/*    <NavigateLink*/}
            {/*        link={routes.admin.settings}*/}
            {/*        children={l('common.action.change')}*/}
            {/*    />*/}
            {/*</p>*/}

        </div>
    )
}



