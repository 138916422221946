import React from 'react'
import './ButtonLeftMenu.scss'
import {NavigateBtn, NavigateLink} from "../../../../../../components/Navigate/Navigate";

export const ButtonsLeftMenu = ({menuBtn, emptyPathOnly = false}) => {
    const path = window.location.pathname.split('/')
    return (
        <>
            {menuBtn.map((el, index) => {
                const isCurrentSection = el.name === path[1] && (!emptyPathOnly || path.length === 3)
                const itemPath = el.path ? el.path : '/' + el.name

                let classes = 'button_left_menu';
                if (isCurrentSection) classes += ' active';
                if (el.restricted) classes += ' disabled';

                return <NavigateLink
                    className={classes}
                    key={`${el.title}_${index}`}
                    link={itemPath}
                    restricted={el.restricted}
                    external={el.external}
                >
                    <div className='menu_button_title'>
                        <div className='title_menu_button_icon'>
                            <el.buttonIcon/>
                        </div>
                        <div className='title_menu_button_text'>{el.title}</div>
                    </div>
                </NavigateLink>

            })}
        </>
    )
}
