import React, {useEffect} from 'react';
import classes from './LoginAdmin.module.scss';
import {Button, Form, Input} from 'antd';
import {useNavigate} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";
import {dotenv, images, routes} from "../../config/config";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Scrollbar from "react-scrollbars-custom";


const ForgotPassword = () => {

    const {t, i18n} = useTranslation()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const admin = useSelector(state => state.admin)

    window.store = admin;

    useEffect(() => {
        const input_username = document.querySelector('[placeholder="Логин"]')
        if (input_username) input_username.focus()                   // ставим фокус в первое поле
//eslint-disable-next-line
    }, [])

    const onFinish = (values) => dispatch({type: 'forgotPassword', header: admin.header, username: values.email});
    const onFinishFailed = (errorInfo) => console.log('Forms Failed:', errorInfo.values);

    return (
        <Scrollbar>
            <div className={`${classes.container} ${classes.container_password}`}>
                <div className={classes.form_container}>
                    <div className={classes.wrapper_form}>
                        <a href={routes.root} className={classes.form_logo_multy}>
                            <img src={images.logo_small} alt='logo color'/>
                        </a>
                        <div className={classes.form_head}>{t('auth.form.recovery')}</div>
                        <button className={classes.forgot_go_back} onClick={() => {
                            navigate(-1)
                        }}>
                            <AiOutlineArrowLeft className={classes.arrow_left}/>
                            <div>{t('auth.fields.back.title')}</div>
                        </button>
                        <Form
                            className={classes.auth_form}
                            name='basic'
                            labelCol={{span: 4}}
                            wrapperCol={{span: 20}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete='off'
                        >
                            <Form.Item className={`${classes.form_item} ${classes.form_item_fields}`} name='email'
                                       rules={[{required: true, message: t('auth.fields.email_login.error.required')}]}>
                                <Input data-lpignore='true' className={classes.input_field} type={'text'}
                                       placeholder={t('auth.fields.login.title')}/>
                            </Form.Item>

                            <Form.Item className={`${classes.form_item} ${classes.form_item_fields}`}>
                                <div className={classes.wrapper_buttons}>
                                    <Button className={classes.form_button_submit} type="primary" htmlType="submit">
                                        {t('auth.fields.submit.title')}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        <div className={classes.have_account}>
                            <div className={classes.have_account_answer}>{t('auth.links.support.answer')}</div>
                            <div className={classes.have_account_action}>
                                <a href={dotenv.support_url}>{t('auth.links.support.action')}</a>
                            </div>
                        </div>
                        <div className={classes.have_account} style={{marginTop: 0}}>
                            <div className={classes.have_account_answer}>{t('auth.links.register.answer')}</div>
                            <div className={classes.have_account_action}
                                 onClick={() => {
                                     navigate(routes.reg, {replace: true})
                                 }}>{t('auth.links.register.action')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Scrollbar>
    )
}

export default ForgotPassword