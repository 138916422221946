import {resetAccessState} from "../reducers/AccessReducer";
import {resetContactState} from "../reducers/ContactReducer";
import {resetDialogState} from "../reducers/DialogReducer";
import {resetDiscountState} from "../reducers/DiscountReducer";
import {resetDomainState} from "../reducers/DomainReducer";
import {resetFieldState} from "../reducers/FieldReducer";
import {resetEmployeeState} from "../reducers/EmployeeReducer";
import {resetFieldValueState} from "../reducers/FieldValueReducer";
import {resetFolderState} from "../reducers/FolderReducer";
import {resetGlobState} from "../reducers/GlobReducer";
import {resetGraphState} from "../reducers/GraphReducer";
import {resetIntegrationState} from "../reducers/IntegrationReducer";
import {resetInvoiceState} from "../reducers/InvoiceReducer";
import {resetLayoutState} from "../reducers/LayoutReducer";
import {resetMailState} from "../reducers/MailReducer";
import {resetOrderState} from "../reducers/OrderReducer";
import {resetPageState} from "../reducers/PageReducer";
import {resetSiteState} from "../reducers/SiteReducer";
import {resetProductCategoryState} from "../reducers/ProductCategoryReducer";
import {resetProductState} from "../reducers/ProductReducer";
import {resetRoleState} from "../reducers/RoleReducer";
import {resetSenlerBotState} from "../reducers/SenlerBotReducer";
import {resetSenlerGroupState} from "../reducers/SenlerGroupReducer";
import {resetTagState} from "../reducers/TagReducer";
import {resetTemplateState} from "../reducers/TemplateReducer";
import {resetThemeState} from "../reducers/ThemeReducer";
import {resetUrlState} from "../reducers/UrlReducer";
import {resetUserState} from "../reducers/UserReducer";
import {resetUserUtmState} from "../reducers/UserUtmReducer";
import {resetUtmState} from "../reducers/UtmReducer";
import {resetWebhookState} from "../reducers/WebhookReducer";

export const resetReducers = (dispatch) => {
    console.info('Reset all sections');

    dispatch(resetAccessState());
    dispatch(resetContactState());
    dispatch(resetDialogState());
    dispatch(resetDiscountState());
    dispatch(resetDomainState());
    dispatch(resetEmployeeState());
    dispatch(resetFieldState());
    dispatch(resetFieldValueState());
    dispatch(resetFolderState());
    dispatch(resetGlobState());
    dispatch(resetGraphState());
    dispatch(resetIntegrationState());
    dispatch(resetInvoiceState());
    dispatch(resetLayoutState());
    dispatch(resetMailState());
    dispatch(resetOrderState());
    dispatch(resetPageState());
    dispatch(resetProductCategoryState());
    dispatch(resetProductState());
    dispatch(resetRoleState());
    dispatch(resetSenlerBotState());
    dispatch(resetSenlerGroupState());
    dispatch(resetSiteState());
    dispatch(resetTagState());
    dispatch(resetTemplateState());
    dispatch(resetThemeState());
    dispatch(resetUrlState());
    dispatch(resetUserState());
    dispatch(resetUserUtmState());
    dispatch(resetUtmState());
    dispatch(resetWebhookState());
}