import React, {useContext, useRef} from 'react'
import classes from './SearchNode.module.scss'
import {Input} from 'antd';
import {isNode, useReactFlow} from 'react-flow-renderer';
import {message} from 'antd';
import {isMobile, isMobile1120} from "../../../../../library/isMobile";
import {SearchOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useHotkeys} from "react-hotkeys-hook";
import {deepGet} from "../../../../../library/functions";
//import {FlowContext} from "../../../FlowContext";

const SearchNode = ({elements, nodes, title}) => {
    const {t} = useTranslation();
    const searchInput = useRef();
    // let { checkEditable } = useContext(FlowContext);
    const {setCenter} = useReactFlow();
    const {Search} = Input;

    message.config({top: 60, duration: 3});

    const [visible, setVisible] = React.useState(false);

    useHotkeys('ctrl+f,command+f,f', e => {
        searchInput.current.focus();
    }, [elements, searchInput]);

    const onKeyDown = (e) => {
        if (((e.ctrlKey || e.metaKey) && e.keyCode === 27) || e.keyCode === 27) e.target.blur();
    }

    const setGraphCenter = (node) => {
        // console.log('setCenter', node);
        setCenter(node.position.x + 100, node.position.y + 50, {zoom: 1});
        // setViewport({zoom: 1});
        if (isMobile1120 || isMobile) setVisible(false);
    }

    const onSearch = (val) => {
        if (!val) {
            // const messageText = t('graph.flow.search.error.empty');
            message.error(t('graph.flow.search.error.empty'));
            return;
        }

        let obj = null;

        if (/^#(\d+)$/.test(val)) {
            let value = val.replace('#', '');
            let localId = parseInt(value);
            let isFound = false;

            for (const node of nodes) {
                if (!node) continue;

                if (node.localId === localId) {
                    value = node.localId;
                    isFound = true;
                    break;
                }

                if (node.events) {
                    for (const event of node.events) {
                        if (event.localId === localId) {
                            value = node.localId;
                            isFound = true;
                            break;
                        }
                    }
                    if (isFound) break;
                }
                if (node.effects) {
                    for (const effect of node.effects) {
                        if (effect.localId === localId) {
                            value = node.localId;
                            isFound = true;
                            break;
                        }
                    }
                    if (isFound) break;
                }
            }

            if (isFound) val = String(value);

        }
        else if (/^№(\s*)(\d+)$/.test(val)) {
            let value = val.replace(/[^0-9]+/, '');
            let stepId = parseInt(value);

            for (const node of nodes) {
                let stepNumber = deepGet(node, 'ui.step', 0);
                if (!stepNumber) continue;

                if (stepNumber === stepId) {
                    val = String(node.localId);
                    break;
                }
            }
        }

        if (/^\d+$/.test(val)) {
            let node = elements.find(el => el.id === val)
            if (node) {
                // console.log('Node founded by local ID', val, elements);
                setGraphCenter(node)
                return;
            }
        }

        elements.forEach(el => {
            if (!obj && isNode(el) && el.data.label) {
                const text = el.data.label.toLowerCase();
                if (text) {
                    if (text.indexOf(val.toLowerCase()) !== -1) obj = el;
                }
            }
        })

        if (!obj) {
            for (const node of nodes) {
                const nodeStr = JSON.stringify(node);
                if (nodeStr.indexOf(val) !== -1) {
                    obj = elements.find(el => el.id === String(node.localId));
                    break;
                }
            }
        }

        if (obj) {
            // console.log('Node founded by text');
            setGraphCenter(obj)
            // return;
        } else {
            message.error(t('graph.flow.search.error.not_found_text', {
                postProcess: 'sprintf',
                sprintf: [val]
            }));
            // return;
        }
        // message.error(t('graph.flow.search.error.not_found_number', {postProcess: 'sprintf', sprintf: [val]}));
    }

    const showSearchField = () => setVisible(!visible)

    return (
        <div className={`graph-search-wrapper ${classes.graph_search_wrapper}`} title={title}>
            <div className={classes.wrapper_search_form}>
                <button
                    className={`${classes.search_button} ${classes.hidden_xl}`}
                    onClick={showSearchField}>
                    <SearchOutlined/>
                </button>
                <Search
                    className={classes.hidden_lg}
                    ref={searchInput}
                    placeholder={t('graph.flow.search.placeholder_xs')}
                    onSearch={onSearch}
                    onKeyDown={onKeyDown}
                    autoComplete='off'
                />
            </div>
            <div className={`${classes.show_search_field} ${classes.hidden_xl}`}
                 style={{display: visible ? '' : 'none'}}>
                <div className={classes.vert_line}/>
                <Search
                    id='input_search_node'
                    placeholder={t('graph.flow.search.placeholder_xs')}
                    onSearch={onSearch}
                    onKeyDown={onKeyDown}
                    defaultValue=''
                />
            </div>
        </div>
    )
}

export default SearchNode