import React from 'react';
import classes from "./LayoutsModal.module.scss";
import {Card, Form, Modal, Typography} from "antd";
import Fa from "../../../components/Icon/Fa";
import Preloader from "../../System/Preloader";
// import {CaretRightOutlined, CheckOutlined, RightOutlined} from "@ant-design/icons";
// import {FormFields} from "../../../components/Form/Field/FormFields";
// import {getItemValues} from "../../../library/functions";

const {Meta} = Card;
const {Title} = Typography

export const SitesModal = (
    {
        t, // useTranslation()
        list = null,
        isVisible = false,
        setVisible = null,
        isLoading = false,
        onOk = null,
        onCancel = null,
        width = 1140,
    }
) => {
    const localeSection = 'sites';

    const hideModal = () => {
        if (setVisible) setVisible(false);
    };

    const handleOk = (content) => {
        if (onOk) onOk(content);
        // hideModal();
    };

    const handleCancel = () => {
        hideModal();
        if (onCancel) onCancel()
    };

    return <Modal
        width={width}
        centered={true}
        className={`${classes.modal_wrapper} ${width <= 900 ? classes.small : ''}`}
        title={null}
        open={isVisible}
        onOk={onOk}
        onCancel={handleCancel}
        footer={null}
        // footer={[
        //     <Button
        //         key="modal-back"
        //         type="text"
        //         // size="large"
        //         onClick={handleCancel}
        //         className="inverted"
        //     >{t('common.action.cancel')}</Button>,
        //     <Button
        //         key="modal-submit"
        //         type="primary"
        //         // size="large"
        //         onClick={handleOk}
        //         loading={isLoading}
        //         // icon={<CaretRightOutlined />}
        //     >{t('common.action.continue')}</Button>
        // ]}
    >

        {isLoading ? <Preloader/> : <><div className="margin-bottom-ps">
                <Title level={3}>
                    {t('site.modal.title')}
                </Title>
                <p className="font-size-sm text-secondary">
                    {t('site.modal.desc')}
                </p>
            </div>

            {list && <div className={`${classes.list_wrapper} padding-top-sm padding-bottom-sm`}>
                {list.map((item, index) => {
                    return <Card
                        key={index}
                        className={`${classes.card} cursor-pointer`}
                        hoverable
                        bordered={false}
                        onClick={() => {
                            handleOk(item.name)
                        }}
                        cover={item.icon_url ? <img
                            alt={item.name}
                            src={item.icon_url ? item.icon_url : "/image/icons/common/plus.svg"}
                        /> : <Fa
                            icon={item.icon_name}
                            set="light"
                            wcn={classes.icon}
                        />}
                    >
                        <Meta
                            title={t(`${localeSection}.${item.name}.title`)}
                            description={t(`${localeSection}.${item.name}.desc`)}
                        />
                    </Card>
                })}
            </div>}

            {/*<div className="margin-top-pm margin-bottom-pm">
                <Title level={3}>
                    {t('layout.list.public.title')}
                </Title>
                <p className="font-size-sm text-secondary">
                    {t('layout.list.public.desc')}
                </p>
            </div>

            <Form
                form={form}
                // initialValues={formValues}
                initialValues={{
                    'title': project.title,
                    'subdomain': '',
                    'layout': 'landing',
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFailed}
                // onValuesChange={onValuesChange}
                className="flex flex-row flex-wrap gap-ps"
                name="settings"
                layout="vertical"
            >
                <FormFields
                    t={t}
                    section="site"
                    fields={[
                        {
                            name: 'title',
                            type: 'text',
                            placeholder: true,
                            desc: '',
                            wrapperClassName: 'flex-grow-1',
                        },
                        {
                            name: 'subdomain',
                            type: 'text',
                            placeholder: 'random',
                            desc: '',
                            wrapperClassName: 'flex-grow-1',
                        },
                        {
                            name: 'layout',
                            type: 'hidden',
                        },
                    ]}
                />
            </Form>*/}
        </>}
    </Modal>
}

