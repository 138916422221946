import React from 'react'
import classes from './NodeButtons.module.scss'
import {Button} from "antd";
import {CopyOutlined, DeleteOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const NodeButtons = ({type, onCopy, onDelete, isIgnored, onIgnore}) => {
    let classList = classes.node_button_group
    if (type !== 'note') {
        classList += ' hidden-xs-important'
    }

    return (
        <Button.Group className={classList}>
            <Button
                className={classes.node_button}
                type="default"
                icon={isIgnored ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                onClick={onIgnore} size="small"
            />

            <Button
                className={classes.node_button}
                type="default"
                icon={<CopyOutlined/>}
                onClick={onCopy}
                size="small"
            />
            <Button
                className={classes.node_button}
                type="default"
                icon={<DeleteOutlined/>}
                onClick={onDelete}
                size="small"
            />
        </Button.Group>
    )
}

export default NodeButtons