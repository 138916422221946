import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {routes} from "../../config/config";
import {l} from "../../library/locale";
import {ucFirst} from "../../library/functions";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import ListTable from "../../components/List/Table/ListTable";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../library/clipboard";
import {AiOutlineLink, AiOutlineSetting} from "react-icons/ai";
import {setUrlStoreParam} from "../../redux/reducers/UrlReducer";
import {SorterWrapper} from "../../components/List/Table/SorterWrapper";

const {Text} = Typography;

export const UrlListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 10,
    }
) => {
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${record.id}`)
    const recordOpen = (record) => recordEdit(record)
    // saga
    // const recordCopy = (id) => dispatch({type: 'copyGraph', admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar align-center',
            title: <SorterWrapper>&nbsp;&nbsp;{l('url.list.table.head.id')}</SorterWrapper>,
            sorter: true,
            width: 90,
            render: (value, record) => {
                return <div title={value}>
                    <TableAvatar
                        icon={record.status ? 'link-horizontal' : 'link-horizontal-slash'}
                        isOn={record.status}
                    />
                </div>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            title: <SorterWrapper>{l('common.form.title.label')}</SorterWrapper>,
            sorter: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'name',
            className: 'cursor-pointer text-secondary',
            title: <SorterWrapper>{l('common.form.name.label')}</SorterWrapper>,
            sorter: true,
            responsive: ['lg'],
            width: 200,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.short_url, l('common.result.url_copied'))
                };
            },
        },
        {
            dataIndex: 'url',
            className: 'cursor-pointer text-secondary',
            title: <SorterWrapper>{l('url.list.table.head.url')}</SorterWrapper>,
            sorter: true,
            responsive: ['xl'],
            width: 250,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.url, l('common.result.copied.neuter'))
                };
            },
        },
        {
            dataIndex: 'is_on',
            className: 'cursor-pointer',
            title: <SorterWrapper>{l('common.form.status')}</SorterWrapper>,
            sorter: true,
            responsive: ['sm'],
            width: 115,
            render: (status) => {
                return <Tooltip title={l('common.action.click_for_toggle')}>{status ?
                    <Text>{l('common.status.short.on')}</Text> :
                    <Text type="danger">{l('common.status.short.off')}</Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordChangeStatus(record.id, !record.status)
                };
            },
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('table.menu.copy_url')}>
                        <Button
                            type="text"
                            onClick={() => copyToClipboard(record.short_url, l('common.result.url_copied'))}>
                            <AiOutlineLink/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('table.menu.edit')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuCopyUrl',
            label: l('table.menu.copy_url'),
            action: (record) => {
                copyToClipboard(record.short_url, l('common.result.url_copied'))
            }
        },
        {
            key: 'tableMenuStatus',
            label: l('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        const origUrl = record.url.length > 70 ? record.url.substring(0, 70) + '...' : record.url

        data.push({
            id: record.id,
            name: record.name,
            url: origUrl,
            short_url: record.short_url,
            key: record.id + '-listRow',
            title: record.title,
            status: record.is_on,
            is_on: record.is_on,
            deleted: record.deleted_at > 0,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setUrlStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{l('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{l('common.action.restore')}</Button>
            </div>
        </div>
    )
}

