import React from 'react'
import {createMarkup, is} from "../../../../../../library/functions";
import Fa from "../../../../../../components/Icon/Fa";

export const BlockSpoiler = (spec) => {
    if (!is(spec)) return null;

    let icon = spec.icon
    const classes = spec.classes || {}

    let iconClassname = null;
    if (classes.icon) iconClassname = classes.icon.join(' ');

    let titleClasses = ['c-button']
    if (classes.title) titleClasses.push(...classes.title)

    let descClasses = ['c-desc']
    if (classes.desc) descClasses.push(...classes.desc)
    // if (!classes.desc.includes('hidden') && !spec.open) descClasses.push('hidden')

    const titleClassname = titleClasses.join(' ')

    return <>
        <div class={titleClassname}>
            <div class="c-title grow">{spec.title}</div>
            {icon.name ? <div class="c-icon">
                <Fa
                    icon={icon.active || 'minus'}
                    set={icon.set || 'regular'}
                    wcn={iconClassname}
                />
            </div> : null}
        </div>

        <div class="c-desc-wrap">
            <div class={descClasses.join(' ')} dangerouslySetInnerHTML={createMarkup(spec.content)}/>
        </div>
    </>
}