import React from 'react';
import {Button, Upload} from "antd";
import {
    DeleteOutlined,
    DownloadOutlined,
    InboxOutlined,
    QuestionCircleOutlined,
    UploadOutlined
} from "@ant-design/icons";
import CollapseCard from "../Collapse/CollapseCard";
import cardClasses from "../Collapse/CollapseCard.module.scss";
import {SimpleModal} from "./SimpleModal";
import {notice} from "../../library/notice";

const {Dragger} = Upload;

const InputModal = (
    {
        t,
        fileList,
        setFileList,
        fileContent,
        setFileContent,
        isVisibleModal,
        setVisibleModal,
        onOk = null,
        section = 'common',
        exampleUrl = null,
        addInstructions = false,
        fileFormat = 'csv',
    }
) => {

    const handleFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = () => setFileContent(reader.result);
        reader.readAsText(file);
        return false; // Prevent the default behavior of uploading the file to the current URL
    };

    const handleFileChange = (info) => {
        let fileList = [...info.fileList];

        // Limit the fileList to only one file
        fileList = fileList.slice(-1);

        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        if (fileList.length > 0) {
            handleFileRead(fileList[0].originFileObj);

            const isCSVFile = fileList[0].name.toLowerCase().endsWith('.' + fileFormat);
            if (isCSVFile) {
                handleFileRead(fileList[0].originFileObj);
                setFileList(fileList);
            } else {
                notice.error(t(section + '.import.error.format'));
            }
        }
    };

    // Simulate an API call to replace the existing file with the new one
    const handleCustomRequest = async ({file, onSuccess}) => {
        // Simulate an API call to replace the existing file with the new one
        onSuccess('success', file);
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        fileList: fileList,
        beforeUpload: handleFileRead,
        onChange: handleFileChange,
        customRequest: handleCustomRequest,
        showUploadList: {
            showRemoveIcon: true,
            showDownloadIcon: false,
        },
    };

    const recordImport = () => {
        if (!fileContent) {
            notice.info(t('graph.list.button.import.empty'));
            return;
        }

        if (onOk) onOk(fileContent);
    };

    const exampleButton = exampleUrl ? <Button
        icon={<DownloadOutlined/>}
        href={exampleUrl}
        target="_blank"
        type="text"
    >{t(section + '.import.modal.template')}</Button> : null;


    return (
        <SimpleModal
            isVisible={isVisibleModal}
            setVisible={setVisibleModal}
            onOk={recordImport}
            actionLabel="apply"
            title={t(section + '.import.modal.title')}
            width={1200}
            leftFooterText={exampleButton}
        >
            {fileList.length === 0 ? <>
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined/>
                        </p>
                        <p className="ant-upload-text">{t(section + '.import.upload.label')}</p>
                    </Dragger>

                    {addInstructions ? <CollapseCard
                        title={t(section + '.import.upload.instructions')}
                        className={cardClasses.inline_card}
                        icon={<QuestionCircleOutlined/>}
                        content={<div
                            dangerouslySetInnerHTML={{__html: t(section + '.import.modal.desc')}}
                        />}
                    /> : null}
                </> :
                <div className="file-card margin-top-sm">
                    <InboxOutlined className="file-icon"/>
                    <div className="file-name">{fileList[0].name}</div>
                    <Button onClick={() => setFileList([])} icon={<DeleteOutlined/>} type="text"/>
                </div>
            }
        </SimpleModal>
    );
};

export default InputModal;

