import React from 'react'
import Fa from "../../../../components/Icon/Fa";
import {inArray, is} from "../../../../library/functions";

const colors = {
    // 'contained' | 'ghost' | 'outlined' | 'text',
    default: {
        default: {
            main: 'gray-200',
            hover: 'gray-300',
            text: 'gray-900',
        },
        ghost: {
            // also: outlined
            main: 'gray-400',
            hover: 'gray-500',
            label: 'gray-500',

            dark: {
                main: 'gray-400',
                hover: 'gray-300',
            }
        },
        text: {
            main: 'gray-600',
            hover: 'gray-800',

            dark: {
                main: 'gray-400',
                hover: 'gray-300',
            }
        },
    },
    primary: {
        default: {
            main: 'primary-500',
            hover: 'primary-600',
            text: 'white',
        },
        ghost: {
            // also: text and outlined
            dark: {
                main: 'primary-400',
                hover: 'primary-500',
            }
        },
    },
    secondary: {
        default: {
            main: 'secondary-500',
            hover: 'secondary-600',
            text: 'white',
        },
        ghost: {
            label: 'secondary-600',
            dark: {
                main: 'secondary-400',
                hover: 'secondary-500',
            }
        }
    },
    success: {
        default: {
            main: 'success-500',
            hover: 'success-600',
            text: 'white',
        }
    },
    info: {
        default: {
            main: 'info-500',
            hover: 'info-600',
            text: 'white',
        }
    },
    warning: {
        default: {
            main: 'warning-500',
            hover: 'warning-600',
            text: 'white',
        }
    },
    danger: {
        default: {
            main: 'danger-500',
            hover: 'danger-600',
            text: 'white',
        }
    },
    light: {
        default: {
            main: 'white',
            hover: 'gray-100',
            text: 'gray-900',
        },

        ghost: {
            // other style for not filled btn
            main: 'gray-100',
            hover: 'gray-300',
            // text: 'gray-100',
        },
        text: {
            main: 'gray-300',
            hover: 'gray-100',
        }
    },
    dark: {
        default: {
            main: 'gray-900',
            hover: 'gray-800',
            text: 'white',

            dark: {
                main: 'gray-950',
                hover: 'gray-900',
            }
        },

        ghost: {
            hover: 'gray-700',
        },
    }
}

const sizes = {
    small: {
        x: '3',
        y: '1',
        i: '1',
        t: 'sm',
    },

    medium: {
        x: '5',
        y: '1.5',
        i: '2',
        t: 'md',
    },

    large: {
        x: '8',
        y: '3',
        i: '3',
        t: 'h4',
    }
}

export const BlockButton = ({spec, params, classes, styles}) => {
    // variants: ['contained', 'ghost', 'outlined', 'text']
    if (!spec) return null;

    const paramStyles = (params && params.style) || {};
    const paramBorder = paramStyles.border || {};

    let props = {};
    let classNames = [
        'button',
        'button-' + (spec.color || 'default'),
        'button-' + (spec.size || 'medium'),
        'button-lg-' + (spec.size_lg || 'medium'),
        'align-bottom',
        'transition',
        'duration-150',
        'ease-in-out'
    ];

    if (!paramStyles.flex || !paramStyles.flex.direction) {
        classNames.push('inline-block');
    }

    // text-size
    const sizeObj = spec.size ? sizes[spec.size] : sizes.medium;
    classNames.push('text-' + sizeObj.t);

    if (paramStyles.padding) {
        if (!paramStyles.padding.left) classNames.push('pl-' + sizeObj.x);
        if (!paramStyles.padding.right) classNames.push('pr-' + sizeObj.x);
        if (!paramStyles.padding.top) classNames.push('pt-' + sizeObj.y);
        if (!paramStyles.padding.bottom) classNames.push('pb-' + sizeObj.y);
    } else {
        classNames.push(...[
            'pl-' + sizeObj.x,
            'pr-' + sizeObj.x,
            'pt-' + sizeObj.y,
            'pb-' + sizeObj.y,
        ]);
    }

    if (spec.size_lg) {
        classNames.push(...[
            'xs:pl-' + sizes[spec.size_lg].x,
            'xs:pr-' + sizes[spec.size_lg].x,
            'xs:pt-' + sizes[spec.size_lg].y,
            'xs:pb-' + sizes[spec.size_lg].y,
        ]);
    }

    if (!paramBorder.radius) {
        classNames.push('rounded');
    }

    // variants and colors
    let color = spec.color;
    const variant = spec.variant || 'contained';

    if (!colors[color]) color = 'default';
    let colorItem = colors[color].default;

    // dif variants can have dif colors
    let getVariantColor = null;

    if (colors[color][variant]) getVariantColor = variant;
    else if (['text', 'outlined'].includes(variant) && colors[color]['ghost']) getVariantColor = 'ghost';

    if (variant && getVariantColor) {
        colorItem = {...colorItem, ...colors[color][getVariantColor]};
    }

    if (spec.variant === 'text' || spec.variant === 'ghost') {
        classNames.push('bg-transparent');

        let textColor = colorItem.label || colorItem.main;
        let hoverColor = colorItem.hover;

        if (!paramStyles.text || !paramStyles.text.color) {
            classNames.push('text-' + textColor);
            classNames.push('hover:text-' + hoverColor);

            if (colorItem.dark) {
                classNames.push('dark:text-' + colorItem.dark.main);
                classNames.push('dark:hover:text-' + colorItem.dark.hover);
            }
        }
    }

    if (spec.variant === 'text') {
        classNames.push('border-none');
    } else {
        if (!paramBorder.width) {
            classNames.push('border-solid');
            classNames.push('border-2');
        }

        if (!paramBorder.color) {
            classNames.push('border-' + colorItem.main);
            classNames.push('hover:border-' + colorItem.hover);

            if (colorItem.dark) {
                classNames.push('dark:border-' + colorItem.dark.main);
                classNames.push('dark:hover:border-' + colorItem.dark.hover);
            }
        }
    }

    if (spec.variant === 'outlined' || spec.variant === 'ghost') {
        classNames.push('bg-transparent');
    }

    if (spec.variant === 'contained') {
        if (!paramStyles.text || !paramStyles.text.color) {
            classNames.push('text-' + colorItem.text);
        }

        if (!paramStyles.bg || !paramStyles.bg.color) {
            classNames.push('bg-' + colorItem.main);
            classNames.push('hover:bg-' + colorItem.hover);

            if (colorItem.dark) {
                classNames.push('dark:bg-' + colorItem.dark.main);
                classNames.push('dark:hover:bg-' + colorItem.dark.hover);
            }
        }
    }

    if (is(classes)) classNames.push(...classes);
    if (is(styles)) props['style'] = styles;

    return spec ?
        <div
            className={classNames.join(' ')}
            // type={btnType}
            // size={spec.size}
            {...props}
        >
            {spec.icon && spec.icon.name ? <Fa
                icon={spec.icon.name}
                set={spec.icon && spec.icon.set || 'regular'}
                wcn={'icon mr-' + (spec.label ? sizeObj.i : '0')}
            /> : null}
            <span class="btn-label">{spec.label}</span>
        </div> : null
}