import React, {useEffect} from 'react';
import classes from "./LayoutsModal.module.scss";
import {Card, Modal, Typography} from "antd";
import Fa from "../../../components/Icon/Fa";
import {inArray} from "../../../library/functions";

const {Meta} = Card;
const {Title} = Typography

export const LayoutsModal = (
    {
        t, // useTranslation()
        site_id = 0,
        section = 'page',
        list = null,
        publicList = null,
        isVisible = false,
        setVisible = null,
        onAdd = null,
        onInstall = null,
        onOk = null,
        onCancel = null,
        width = 1140,
        title = null,
    }
) => {
    const isPageMode = section === 'page';
    const layoutNames = list ? list.map(item => item.name) : [];
    const publicListFiltered = (publicList || []).filter(item => !inArray(item.name, layoutNames));
    // console.log('list', list)

    const hideModal = () => {
        setVisible(false);
    };

    const handleAdd = (content) => {
        if (onAdd) onAdd(content);
        hideModal();
    };

    const handleInstall = (content) => {
        // console.log('handleInstall', content)
        if (onInstall) onInstall(content);
    };

    const handleCancel = () => {
        hideModal();
        if (onCancel) onCancel()
    };

    useEffect(() => {
        if (isVisible && !isPageMode && !publicListFiltered.length) {
            handleInstall(''); // move to form
        }
    }, [isVisible])

    /*const antTabs = Object.entries(containersMap).map(([name, item]) => {
        const categoryName = 'container.' + name.split('/').join('.')
        return {
            label: <span><Fa icon={item.icon}/>
                <span className="hidden-xs">{t(categoryName + '.title')}</span>
            </span>,
            key: name,
            children: Object.entries(item.list).map(([localType, container]) => {
                const blockName = categoryName + '.' + localType.split('/').join('.')
                return <Card
                    key={name + '/' + localType}
                    className={`${classes.card} cursor-pointer`}
                    hoverable
                    bordered={false}
                    onClick={() => {
                        handleAdd(container.content)
                    }}
                    cover={<img alt={item.name} src={'/image/layout/catalog/' + name + '/' + localType + '.jpg'}/>}>
                    <Meta title={t(blockName + '.title')}/>
                </Card>
            }),
        }
    })*/

    const plusImageUrl = "/image/icons/common/plus.svg";

    return <Modal
        width={width}
        centered={true}
        className={`${classes.modal_wrapper} ${width <= 900 ? classes.small : ''}`}
        title={title}
        open={isVisible}
        onOk={onOk}
        onCancel={handleCancel}
        footer={null}
    >
        {(isPageMode && publicListFiltered.length) ? <div className="margin-bottom-pm">
            <Title level={3}>
                {t('layout.list.installed.title')}
            </Title>
            <p className="font-size-sm text-secondary">
                {t('layout.list.installed.desc')}
            </p>
        </div> : null}

        {isPageMode && list && <div className={`${classes.list_wrapper} padding-bottom-ps`}>
            {list.map((item, index) => {
                if (!item.is_on) return null;
                if (site_id && item.site_id && item.site_id !== site_id) return null;

                return <Card
                    key={item.name + index}
                    className={`${classes.card} cursor-pointer`}
                    hoverable
                    bordered={false}
                    onClick={() => {
                        handleAdd(item.name)
                    }}
                    cover={item.photo_url ? <img
                        alt={item.name}
                        src={item.photo_url ? item.photo_url : plusImageUrl}
                    /> : <Fa
                        icon={item.icon_name}
                        set="light"
                        wcn={classes.icon}
                    />}
                >
                    <Meta title={item.title}/>
                </Card>
            })}

            <Card
                key="none"
                className={`${classes.card} cursor-pointer`}
                cover={<Fa icon="plus" set="light" wcn={classes.icon}/>}
                bordered={false}
                hoverable
                onClick={() => {
                    handleAdd('')
                }}
            >
                <Meta title={t('page.item.menu.raw')}/>
            </Card>
        </div>}

        {(isPageMode && publicListFiltered.length) ? <div className="margin-top-pm margin-bottom-pm">
            <Title level={3}>
                {t('layout.list.public.title')}
            </Title>
            <p className="font-size-sm text-secondary">
                {t('layout.list.public.desc')}
            </p>
        </div> : null}

        {(publicListFiltered.length) ? <div className={`${classes.list_wrapper} padding-bottom-ps`}>
            {!isPageMode ? <Card
                key="create"
                className={`${classes.card} cursor-pointer`}
                cover={<Fa icon="plus" set="light" wcn={classes.icon}/>}
                bordered={false}
                hoverable
                onClick={() => {
                    handleInstall('')
                }}
            >
                <Meta title={section ? t(section + '.object.create') : t('common.action.create')}/>
            </Card> : null}

            {publicListFiltered.map((item, index) => {
                // if (list && inArray(item.name, layoutNames)) return null;
                return <Card
                    key={item.name + index}
                    className={`${classes.card} cursor-pointer`}
                    hoverable
                    bordered={false}
                    cover={<div
                      onClick={() => {
                            handleInstall(item.name)
                        }}
                    >
                        {item.photo_url ? <img
                            alt={item.name}
                            src={item.photo_url ? item.photo_url : plusImageUrl}
                        /> : <Fa
                            icon={item.icon_name}
                            set="light"
                            wcn={classes.icon}
                        />}
                </div>}
                >
                    <Meta title={item.title}/>
                </Card>
            })}

            {isPageMode ? <Card
                key="create"
                className={`${classes.card} cursor-pointer`}
                cover={<Fa icon="plus" set="light" wcn={classes.icon}/>}
                bordered={false}
                hoverable
                onClick={() => {
                    handleInstall('')
                }}
            >
                <Meta title={t('common.action.create')}/>
            </Card> : null}
        </div> : null}

        {/*<Tabs
            defaultActiveKey="core/cover"
            className={classes.tabs}
            tabPosition="left"
            items={antTabs}
            // style={{height: 500}}
        />*/}
    </Modal>
}

