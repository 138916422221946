import React from 'react';
import {FormTitle} from "./FormTitle";
import {l} from "../../library/locale";
import {FormFields} from "./Field/FormFields";
import {FormSubmit} from "./FormSubmit";
import {Form, Layout} from "antd";
import Preloader from "../../containers/System/Preloader";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

export const ItemEditForm = (
    {
        t,
        item,
        form,
        section,
        formFields,
        formValues,
        onFinish,
        onFailed,
        backToList,
        formTitle = null,
        onValuesChange = null
    }
) => {
    return (
        <Form
            form={form}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            onValuesChange={onValuesChange}
            className=""
            name="settings"
            layout="vertical"
        >
            <FormTitle onBack={backToList}>
                {formTitle === null ? <>{item.id ? l('common.action.edit') : l('common.action.add')} {l(section + '.object.title')}</> : formTitle}
            </FormTitle>

            <Content className="page-container form-container site-layout-background">
                {formFields.length ? <>
                    <FormFields t={t} form={form} section={section} fields={formFields} formValues={formValues}/>
                    <FormSubmit onCancel={backToList}/>
                </> : null}
            </Content>
        </Form>
    );
};

export const SetItemEditFormOrPreloader = (
    {
        id,
        item,
        section,
        form,
        formFields,
        formValues,
        onFinish,
        onFailed,
        backToList,
        formTitle = null,
        onValuesChange = null
    }
) => {
    const {t} = useTranslation();
    if (!id || item.id === id) return <ItemEditForm
        t={t}
        item={item}
        form={form}
        section={section}
        formFields={formFields}
        formValues={formValues}
        onFinish={onFinish}
        onFailed={onFailed}
        backToList={backToList}
        formTitle={formTitle}
        onValuesChange={onValuesChange}
    />
    else return <Preloader/>
}

