import {put, takeEvery} from 'redux-saga/effects'
import {
    setAccessToken,
    setAuthorized,
    setAdminItem,
    setAdminUser,
    setLogging,
    setCorrect, setAdminObject
} from '../reducers/AdminReducer'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {openRequest, request} from "../../library/api";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.admin;
const restore_path = base_path + '/restore';
const token_path = base_path + '/token';

function* getAdminData(token) {
    try {
        const response_admin = yield request(token, base_path, 'get');
        const admin_data = yield response_admin.data.result

        yield put(setAdminUser({user: admin_data.user}))

        yield put(setAuthorized(true))
        yield put(setCorrect(true))
        yield put(setLogging(false))

        yield put(setAdminItem(admin_data))

    } catch (error) {
        yield put(setCorrect(false))
        apiErrorNotice(error)
    }
}

function* putAccessToken(data) {
    try {
        const response_token = yield openRequest(token_path, 'post', data);
        const token = yield response_token.data.result;
        yield put(setAccessToken(token))
        yield getAdminData(token, data.password)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getAccessToken(arg) {
    const user = arg.data;
    yield putAccessToken({username: user.username, password: user.password})
}

function* createAdmin(arg) {
    try {
        const create_admin = yield openRequest(base_path, 'post', arg.values)
        const admin_data = yield create_admin.data.result
        const admin_auth = {user: admin_data, password: arg.values.password}
        yield put(setAdminItem(admin_auth))
        yield put(setAdminUser({user: admin_data.user}))
        yield putAccessToken({
            username: admin_data.username,
            password: arg.values.password,
            search_query: arg.search_query
        })
    } catch (error) {
        apiErrorNotice(error)
    }
}

function* changeUserInfo(arg) {
    yield put(setAdminUser({user: arg.form_data}))
}

function* restorePassword(arg) {
    const password = yield openRequest(restore_path, 'post', {username: arg.username})
    console.log('>>> Restore Password:', password.data)
    // TODO add notices
}

function* getAdminItem(arg) {
    try {
        const response_admin = yield request(arg.admin.token, base_path, 'get');
        if (response_admin.data.ok) {
            yield put(setAdminItem(response_admin.data.result))
            yield put(setAdminUser(response_admin.data.result))
        }
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}

function* getAdminItemByUserId(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.user_id, 'get');
        if (response.data.ok) yield put(setAdminObject(response.data.result))
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}

// login & password update
function* updateAdmin(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);
        if (response.data.ok) {
            notice.success(l("admin.notice.success"))
            yield put(setAdminItem(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}


function* updateAdminParams(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);
        if (response.data.ok) {
            yield put(setAdminItem(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}


function* updateAdminUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path+'/user', 'patch', arg.data);
        if (response.data.ok) {
            notice.success(l("admin.notice.success"))
            yield put(setAdminUser(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}


function* connectAdminToLocalUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path+'/connection', 'patch', arg.data);
        if (response.data.ok) {
            notice.success(l("admin.notice.success"))
            yield put(setAdminUser(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
    }
}

export function* adminSaga() {
    yield takeEvery('getAccessToken', getAccessToken);
    yield takeEvery('createAdmin', createAdmin);
    yield takeEvery('changeUserInfo', changeUserInfo);
    yield takeEvery('forgotPassword', restorePassword);
    yield takeEvery('getAdminItem', getAdminItem);
    yield takeEvery('getAdminItemByUserId', getAdminItemByUserId);
    yield takeEvery('updateAdmin', updateAdmin);
    yield takeEvery('updateAdminUser', updateAdminUser);
    yield takeEvery('updateAdminParams', updateAdminParams);
    yield takeEvery('connectAdminToLocalUser', connectAdminToLocalUser);
}

