import React from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Divider, Tooltip, Typography} from 'antd';
import {useDispatch} from "react-redux";
import {dotenv, routes} from "../../../config/config";
import {deepGet, getUtcDateTime, ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import FoldersList from "../../../components/List/Table/FoldersList";
import {
    DeleteOutlined,
    ExportOutlined,
    EyeOutlined,
    FileOutlined,
    FormOutlined,
    GlobalOutlined,
    LayoutOutlined,
    SettingOutlined,
    EyeInvisibleOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const SiteListTable = (
    {
        admin,
        project,
        section,
        adminSection,
        list,
        filters,
        recordCreate,
    }
) => {
    const {t} = useTranslation();
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // let list_items = createObjectFromObjectsArray(list);

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}/${adminSection}/edit/${record.id}`)
    const pagesOpen = (record) => navigate(`${routes.project_list}/${project.id}${routes.local.page_list}?site_id=${record.id}`)
    const recordOpen = (record) => navigate(`${routes.project_list}/${project.id}${routes.local.site_list}/page/-1?site_id=${record.id}`)
    const openDomainsList = (record) => navigate(`${routes.project_list}/${project.id}${routes.local.domain_list}?folder=auto&site_id=${record.id}`)
    const openLayoutsList = (record) => navigate(`${routes.project_list}/${project.id}${routes.local.layout_list}?folder=auto&site_id=${record.id}`)
    // saga
    // const recordCopy = (id) => dispatch({type: 'copy'+ Section, admin, id}) // TODO: saga + copy all pages API (?)
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, currentStatus) => {
        const data = {
            id: id,
            project_id: project.id,
            published_at: currentStatus ? 0 : 1,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const openNewDomain = (record) => {
        navigate(`${routes.project_list}/${project.id}${routes.local.domain_list}/edit/0?site_id=${record.id}`)
    }

    const openRecord = (record) => {
        window.open(record.url, '_blank')
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar align-center',
            width: 90,
            responsive: ['sm'],
            render: (value, record) => {
                return <div title={value}>
                    <TableAvatar
                        imageUrl={record.photo_url}
                        className="w-34"
                        icon={record.status ? 'display' : 'display-slash'}
                        isOn={record.status}
                    />
                </div>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title_xs',
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['xs'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
            render: (text, record) => record.status ? <Text>{text}</Text> : <Text type="danger">{text}</Text>,
        },
        {
            dataIndex: 'domain',
            responsive: ['xl'],
            // width: 150,
            render: (text, record) => <span className="text-secondary copy-on-click">
                {text}
            </span>,
        },
        {
            dataIndex: 'type',
            width: 170,
            responsive: ['sm'],
            render: (text, record) => <span className="text-secondary">
                {t(section + '.form.type.value.' + text)}
            </span>,
        },
        // {
        //     dataIndex: 'domains',
        //     width: 100,
        //     responsive: ['sm'],
        //     className: 'text-secondary text-underline cursor-pointer',
        //     onCell: (record, rowIndex) => {
        //         return {
        //             onClick: (ev) => openDomainsList(record),
        //             onAuxClick: (ev) => openDomainsList(record, true),
        //             onContextMenu: (ev) => (ev.preventDefault()),
        //         };
        //     },
        //     render: () => <span>{t(section + '.list.icon.domains')}</span>,
        // },
        // {
        //     dataIndex: 'status',
        //     className: 'cursor-pointer',
        //     responsive: ['sm'],
        //     width: 115,
        //     render: (status) => {
        //         return <Tooltip title={t('common.action.click_for_toggle')}>{status ?
        //             <Text>{t('common.status.short.on')}</Text> :
        //             <Text type="danger">{t('common.status.short.off')}</Text>}
        //         </Tooltip>
        //     },
        //     onCell: (record, rowIndex) => {
        //         return {
        //             onClick: (ev) => recordChangeStatus(record.id, record.status)
        //         };
        //     },
        // },
        {
            key: 'actions',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={record.status ? t('table.icon.off') : t('table.icon.on')}>
                        <Button
                            type="text"
                            size="large"
                            onClick={() => recordChangeStatus(record.id, record.status)}
                            icon={record.status ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        />
                    </Tooltip>
                    {record.domain ? <Tooltip title={t(section + '.list.icon.open')}>
                        <Button
                            type="text"
                            href={record.url}
                            target="_blank"
                            icon={<ExportOutlined />}
                            size="large"
                        />
                    </Tooltip> : <Tooltip title={t(section + '.list.icon.add_domain')}>
                        <Button type="text" icon={<GlobalOutlined/>} size="large" onClick={() => openNewDomain(record)} />
                    </Tooltip>}
                    <Tooltip title={t('table.menu.edit')}>
                        <Button type="text" size="large" icon={<SettingOutlined/>} onClick={() => {
                            recordEdit(record)
                        }}/>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t(section + '.list.menu.edit'),
            action: (record) => recordEdit(record),
            icon: <SettingOutlined className="color-secondary"/>
        },
        // {
        //     key: 'tableMenuCopy',
        //     label: t('table.menu.duplicate'),
        //     action: (record) => recordCopy(record.id)
        // },
        {
            key: 'tableMenuOpen',
            action: (record) => recordOpen(record),
            render: () => t(section + '.list.menu.open'),
            icon: <FileOutlined className="color-secondary"/>,
        },

        {
            key: 'tableMenuPreview',
            action: (record) => record.domain ? openRecord(record) : openNewDomain(record),
            render: (record) => record.domain ? t(section + '.list.menu.preview') : t(section + '.list.menu.add_domain'),
            icon: <ExportOutlined className="color-secondary"/>
        },
        {
            key: 'tableMenuDomains',
            label: t(section + '.list.menu.domains'),
            action: (record) => openDomainsList(record),
            icon: <GlobalOutlined className="color-secondary"/>
        },
        {
            key: 'tableMenuLayouts',
            label: t(section + '.list.menu.layouts'),
            action: (record) => openLayoutsList(record),
            icon: <LayoutOutlined className="color-secondary"/>
        },
        {
            key: 'tableMenuPages',
            action: (record) => pagesOpen(record),
            render: () => t(section + '.list.menu.pages'),
            icon: <UnorderedListOutlined className="color-secondary" />
        },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, record.status),
            icon: <EyeOutlined className="color-secondary"/>
        },
        {
            type: 'divider',
            render: () => <Divider className="margin-none-vertical" type="horizontal"/>,
            action: () => null,
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id),
            icon: <DeleteOutlined className="color-secondary"/>
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const now = getUtcDateTime();
        const record = list[i]
        data.push({
            id: record.id,
            type: record.type,
            parent_id: record.parent_id,
            // parent_title: deepGet(list_items, String(record.parent_id), '—'),
            key: record.id + '-listRow',
            title: record.title,
            title_xs: record.title,
            folder: record.params && record.params.folder_id || 0,
            status: record.published_at > 0 && record.published_at < now,
            deleted: record.deleted_at > 0,
            domain: record.domain,
            url: record.domain ? dotenv.sites_proto + record.domain : null,
            photo_url: deepGet(record, 'params.favicon_url', null),
        });
    }

    // ==== Render ====
    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

