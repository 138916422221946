import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    short: {
        // short list for select fields
        list: [],
        count: 0,
    },
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
}

const ProductReducer = createSlice({
    name: 'product',
    initialState,
    reducers: {
        fillProductList(state, action) {
            state.list = action.payload
        },
        setProductItem(state, action) {
            state.item = action.payload;
        },
        setProductListCount(state, action) {
            state.count = action.payload;
        },

        fillProductShortList(state, action) {
            state.short.list = action.payload
        },
        setProductShortListCount(state, action) {
            state.short.count = action.payload;
        },
        setProductStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addProductToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeProductFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchProductInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetProductState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default ProductReducer.reducer
export const {
    resetProductState,
    setProductItem,
    fillProductList,
    setProductListCount,
    fillProductShortList,
    setProductShortListCount,
    setProductStoreParam,
    patchProductInList,
    addProductToList,
    removeProductFromList
} = ProductReducer.actions