import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addContactToList,
    fillContactList,
    patchContactInList,
    removeContactFromList,
    setContactItem,
    setContactListCount,
} from "../reducers/ContactReducer";

// init api methods
const base_path = backend_api.user_contact;

function* getContactList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillContactList(response.data.result))
            yield put(setContactListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getContactItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setContactItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createContact(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addContactToList(response.data.result))
            yield put(setContactItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateContact(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchContactInList(response.data.result))
            yield put(setContactItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreContact(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeContactFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteContact(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeContactFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* contactSaga() {
    yield takeEvery('getContactList', getContactList);
    yield takeEvery('getContactItem', getContactItem);
    yield takeEvery('createContact', createContact);
    yield takeEvery('updateContact', updateContact);
    yield takeEvery('restoreContact', restoreContact);
    yield takeEvery('deleteContact', deleteContact);
}

