import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {inArray, momentFromUnix, objectLength, ucFirst, unixFromMoment} from "../../library/functions";
import cryptoRandomString from "crypto-random-string";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";
import {notice} from "../../library/notice";
import {contact_types} from "../../schemas/frontend/effects";

const UrlEdit = () => {
    const {t} = useTranslation()
    const section = 'url'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, url, integration} = useSelector(store => store)
    const item = url.item
    const project_item = project.item;
    const integration_items = createObjectFromIntegrations(t, integration.list);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            label: t('common.form.title.label'),
            placeholder: t('common.form.title.placeholder'),
            desc: t('common.form.title.desc'),
            name: "title",
            type: "text",
            required: true,
        },
        {
            name: "name",
            type: "text",
            placeholder: true,
            required: true,
        },
        {
            name: "url",
            placeholder: 'https://example.com/path...',
            type: "text",
        },
        {
            name: "error_url",
            placeholder: 'https://example.com/error',
            type: "text",
        },
        {
            name: "expire_at",
            type: "datetime",
            format: 'DD MMMM YYYY HH:mm:ss',
        },
        {
            name: "event",
            type: "heading",
        },
        {
            name: "user_id",
            placeholder: '0',
            type: "text",
        },
        {
            name: "integration_id",
            type: "menu",
            data: 'integrations',
            values: integration_items,
        },
        {
            name: "data",
            type: "textarea",
        },
        // {
        //     name: 'short_url',
        //     type: "text",
        //     placeholder: true,
        //     readonly: true,
        // },
        {
            name: "contact",
            type: "heading",
        },
        {
            name: "params.contact.type",
            placeholder: '',
            type: "menu",
            values: [
                'unset',
                'app',
                'hash',
                ...contact_types,
            ],
            // default: 'unset',
            localized: true,
        },
        {
            name: "params.contact.store",
            placeholder: '',
            type: "menu",
            values: [
                'query',
                'form',
                'json',
                'data',
            ],
            // default: 'json',
            localized: true,
        },
        {
            name: "params.contact.path",
            placeholder: 'object.user.id',
            type: "tags",
            values: [
                'id',
                'uid',
                'sid',
                'user.id',
                'object.id',
                'object.vk_user_id',
                'object.user.id',
                'vk_user_id',
                'vk_uid',
                'tg_id',
                'tg_uid',
                'email',
                'phone',
                'customer_phone',
            ],
            features: ['adding'],
        },
        {
            name: "additional",
            type: "heading",
        },
        {
            label: t('common.form.is_on.label'),
            name: "is_on",
            type: "switcher",
        },
        {
            name: "params.pass_query",
            type: "switcher",
        },
        {
            name: "params.result.ok",
            placeholder: true,
            type: "text",
        },
        {
            name: "params.result.error",
            placeholder: true,
            type: "text",
        },
        {
            label: t('common.form.description.label'),
            placeholder: t('common.form.description.placeholder'),
            desc: t('common.form.description.desc'),
            name: "description",
            type: "textarea",
        },
    ]

    // itis form state
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 10}),
        title: '',
        is_on: true,
        description: '',
        params: {
            result: {
                ok: 'ok',
                error: '',
            },
            contact: {
                type: 'unset',
                store: 'json',
                path: [],
            },
        },
        url_url: item.url_url ?? '',
    })

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, objectLength(item), project_item.id])

    // set urls values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let item_object = {...item}
            if (item.integration_id) item_object.integration_id = item.integration_id.toString()
            if (item.data !== null) item_object.data = JSON.stringify(item.data, null, 2)
            if (item.expire_at) item_object.expire_at = momentFromUnix(item.expire_at)
            else item_object.expire_at = ''
            form.setFieldsValue(item_object)
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_item.id}/${section}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        if (values.data) {
            try {
                values.data = JSON.parse(values.data)
                if (typeof values.data !== 'object') {
                    notice.error(t('url.error.json_parse'))
                    return false;
                }
            }
            catch (e) {
                notice.error(t('url.error.json_parse'))
                return false;
            }
        } else {
            values.data = null
        }
        
        if (values.expire_at) values.expire_at = unixFromMoment(values.expire_at)
        else values.expire_at = null

        if (!values.integration_id) values['integration_id'] = null
        
        formCreateOrUpdate('Url', values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    let formFieldsResult = [...formFields]
    if (!item.id) formFieldsResult = formFields.filter((object) => !inArray(object.name, ['short_url']))

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFieldsResult}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default UrlEdit