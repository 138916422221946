export const mail_graph = {
    "nextLocalId": 19,
    "nextStepId": 4,
    "nodes": [
        {
            "type": "event",
            "start_mode": "reopen",
            "ui":
                {
                    "position":
                        {
                            "x": 164,
                            "y": 164
                        },
                    "step": 1
                },
            "localId": 1,
            "ports": [
                {
                    "name": "defaultInput",
                    "localId": 2,
                    "type": "input",
                    "group": "waitAll"
                },
                {
                    "name": "defaultOutput",
                    "localId": 3,
                    "type": "output"
                }
            ],
            "text": ""
        },
        {
            "type": "action",
            "ui":
                {
                    "position":
                        {
                            "x": 681,
                            "y": 161
                        },
                    "step": 2
                },
            "localId": 4,
            "ports": [
                {
                    "name": "defaultInput",
                    "localId": 5,
                    "type": "input",
                    "group": "waitAll"
                },
                {
                    "name": "defaultOutput",
                    "localId": 6,
                    "type": "output"
                }
            ],
            "effects": [
                {
                    "type": "effect/core/chat/message/send",
                    "localId": 8,
                    "spec":
                        {
                            "options": false,
                            "keyboard": [],
                            "json_keyboard": "[[{\n  \"label\": \"String\",\n  \"type\": \"text\",\n  \"color\": \"primary\",\n  \"payload\": \"1\"\n}]]",
                            "remove_keyboard": false,
                            "inline_keyboard": true,
                            "permanent_keyboard": false,
                            "keyboard_as_json": false,
                            "allow_link_preview": false,
                            "do_nothing_on_empty": true,
                            "reply": false,
                            "reply_id": "{var:object.id}",
                            "pass_topic": false,
                            "topic_id": "{var:object.topic.id}",
                            "forward": false,
                            "forward_ids": ["{var:object.id}"],
                            "attach": false,
                            "attachment": [],
                            "protect_content": false
                        }
                },
                {
                    "type": "effect/core/graph/jump/to",
                    "localId": 7,
                    "spec":
                        {
                            "localIds": [6],
                            "type": "jump",
                            "ignore_next": false
                        }
                }
            ]
        },
        {
            "type": "condition",
            "ui":
                {
                    "position":
                        {
                            "x": 420,
                            "y": 163
                        },
                    "step": 3
                },
            "localId": 9,
            "ports": [
                {
                    "name": "defaultInput",
                    "localId": 10,
                    "type": "input",
                    "group": "waitAll"
                },
                {
                    "name": "defaultOutput",
                    "localId": 11,
                    "type": "output"
                },
                {
                    "name": "condition",
                    "localId": 12,
                    "type": "output",
                    "group": "branching"
                },
                {
                    "name": "failure",
                    "localId": 13,
                    "type": "output",
                    "group": "branching"
                }
            ],
            "effects": [
                {
                    "type": "effect/core/graph/jump/to",
                    "localId": 14,
                    "spec":
                        {
                            "localIds": [11],
                            "type": "jump",
                            "ignore_next": false
                        }
                },
                {
                    "type": "effect/core/graph/condition/jump",
                    "localId": 15,
                    "spec":
                        {
                            "truePorts": [12],
                            "mode": "and",
                            "conditions": []
                        }
                },
                {
                    "type": "effect/core/graph/jump/to",
                    "localId": 16,
                    "spec":
                        {
                            "localIds": [13],
                            "type": "jump",
                            "ignore_next": false
                        }
                }
            ]
        }
    ],
    "edges": [
        {
            "localId": 17,
            "srcNode": 9,
            "srcPort": 12,
            "dstNode": 4,
            "dstPort": 5
        },
        {
            "localId": 18,
            "srcNode": 1,
            "srcPort": 3,
            "dstNode": 9,
            "dstPort": 10
        }
    ],
    "ui":
        {
            "position":
                {
                    "x": 0,
                    "y": 0,
                    "zoom": 1
                },
            "chosenNodeId": 1
        }
}
