import React from 'react'
import {is} from "../../../../../../library/functions";

export const BlockImage = (spec, params, classes, styles) => {
    if (!spec) return null;

    let classNames = ['c-image'];
    if (is(classes)) classNames.push(...classes);

    let props = {};
    if (is(styles)) props['style'] = styles;

    return spec.photo_url ? <img
        class={classNames.join(' ')}
        src={spec.photo_url}
        alt={spec.alt || ''}
        {...props}
    /> : <span class={classNames.join(' ')} {...props} >
        {spec.alt || ''}
    </span>
}