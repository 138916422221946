import React, {useState} from 'react'
import {
    ucFirst
} from "../../../library/functions";
import {useDispatch} from "react-redux";
import {l} from "../../../library/locale";
import FolderTable from "./FolderTable";
import {Button} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import Preloader from "../../../containers/System/Preloader";


export const FolderListSimple = (
    {
        section,
        admin,
        project,
        folder,
        filters,
        items,
        columns,
        recordCreate,
        className = '',
        createRecordComponent = null,
        onFolderChange = null,
        sectionSaga = null,
        onDelete = null,
        onRestore = null,
    }
) => {
    const saga = sectionSaga ? sectionSaga : ucFirst(section);

    // init hooks
    const dispatch = useDispatch();

    // init states
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isOpened, setIsOpened] = useState(folder.opened)


    const onFolderOpenLocal = (id, status) => {
        if (onFolderChange) onFolderChange(id, status);
        return setIsOpened(status);
    };


    const recordDelete = (id) => {
        if (onDelete) onDelete(id);
        else dispatch({type: 'delete' + saga, admin, id});
    }

    const recordRestore = (id) => {
        if (onRestore) onRestore(id);
        else dispatch({type: 'restore' + saga, admin, data: {id: id, project_id: project.id}})
    }

    const deleteSelected = () => {
        for (const rowKey of selectedRowKeys) recordDelete(parseInt(rowKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const rowKey of selectedRowKeys) recordRestore(parseInt(rowKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    if (!project.id) {
        // console.log('preloader', project.id, firstFolder)
        return <Preloader/>
    } // can not open folders without project.id


    return (<>
        <div key={folder.id + '-folder'} className="margin-bottom-ps">
            <FolderTable
                folder={folder}
                columns={columns}
                items={items}
                opened={isOpened}
                className={className}
                folderType={folder.type}
                recordCreate={recordCreate}
                createRecordComponent={createRecordComponent}
                onFolderOpen={onFolderOpenLocal}
                rowSelection={rowSelection}
            />
        </div>
        <div className={isCheckboxButtons ? "button-flying" : "hide"}>
            <Button
                className={filters.is_deleted ? "hide" : ""}
                onClick={deleteSelected}
                icon={<DeleteOutlined/>}>{l('common.action.delete')}</Button>
            <Button
                className={filters.is_deleted ? "" : "hide"}
                onClick={restoreSelected}
                icon={<DeleteOutlined/>}>{l('common.action.restore')}</Button>
        </div>
    </>);
}