import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addFolderToList,
    fillFolderList,
    patchFolderInList,
    removeFolderFromList,
    setFolderItem,
    clearFolderItem,
} from "../reducers/FolderReducer";

// init api methods
const base_path = backend_api.folder;

function* getFolderList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillFolderList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getFolderItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/get/' + arg.id, 'get');

        if (response.data.ok) yield put(setFolderItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createFolder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addFolderToList(response.data.result))
            yield put(clearFolderItem()) // hide folder modal
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateFolder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchFolderInList(response.data.result))
            yield put(clearFolderItem()) // hide folder modal
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteFolder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeFolderFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* folderSaga() {
    yield takeEvery('getFolderList', getFolderList);
    yield takeEvery('getFolderItem', getFolderItem);
    yield takeEvery('createFolder', createFolder);
    yield takeEvery('updateFolder', updateFolder);
    yield takeEvery('deleteFolder', deleteFolder);
}

