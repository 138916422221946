import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import cryptoRandomString from "crypto-random-string";
import {createObjectFromObjectsArray, objectLength, ucFirst} from "../../../library/functions";
import {routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {SetItemEditFormOrPreloader} from "../../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const ProductCategoryEdit = () => {
    const {t} = useTranslation()
    const section = 'productCategory'
    const adminSection = 'category'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, productCategory} = useSelector(store => store)
    const item = productCategory.item
    const list = productCategory.list
    const project_item = project.item;
    let list_items = createObjectFromObjectsArray(list);
    if (list_items[id]) delete list_items[id]

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            label: t('common.form.title.label'),
            placeholder: t('common.form.title.placeholder'),
            desc: t('common.form.title.desc'),
            name: "title",
            type: "text",
            required: true,
        },
        // TODO: Finish parent_ids logic on backend first
        /*{
            name: "parent_id",
            type: "menu",
            data: 'productCategory',
            values: list_items,
        },*/
        {
            name: "type",
            type: "menu",
            placeholder: '',
            values: ['income', 'expense'],
            localized: true,
        },
        {
            name: "is_on",
            type: "switcher",
            label: t('common.form.is_on.label'),
        },
        {
            label: t('common.form.description.label'),
            placeholder: t('common.form.description.placeholder'),
            desc: t('common.form.description.desc'),
            name: "description",
            type: "textarea",
        },
    ]

    // itis form state
    const [formValues, setFormValues] = useState({
        name: cryptoRandomString({length: 10}),
        title: '',
        is_on: true,
        description: '',
        type: 'income',
        parent_id: null,
    })

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !list.length) {
            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, objectLength(item), project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}
            if (!item.parent_id) {
                values.parent_id = null
            }
            form.setFieldsValue(values)
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_item.id}/${adminSection}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate('ProductCategory', values, formFields, id, project_item, admin, dispatch, true)
        backToList()
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={section} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default ProductCategoryEdit