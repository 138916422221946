// time: 48 min +

export const coreCover = {
    'button/url': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h2",
                                content: "{locale:container.example.text.header}"
                            },
                            style: {
                                padding: {
                                    bottom: "6"
                                },
                                text: {
                                    size: 'h1'
                                },
                                classes: {
                                    custom: ['md:text-4xl']
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.cover.desc}"
                            },
                        },
                    ],
                    style: {
                        padding: {
                            top: "10",
                            bottom: "5"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                },
                {
                    type: "group",
                    items: [
                        {
                            type: "core/form/button/url",
                            spec: {
                                icon: {
                                    set: "brands",
                                    name: "vk"
                                },
                                size: "medium",
                                color: "light",
                                label: "{locale:container.example.cover.button_1}",
                                variant: "contained",
                                is_external: false
                            },
                            wrap: {
                                flex: {
                                    direction: "inline"
                                },
                            },
                        },
                        {
                            type: "core/form/button/url",
                            spec: {
                                icon: {
                                    set: "brands",
                                    name: "telegram"
                                },
                                size: "medium",
                                color: "light",
                                label: "{locale:container.example.cover.button_2}",
                                variant: "ghost",
                                is_external: false
                            },
                            wrap: {
                                flex: {
                                    direction: "inline"
                                },
                            },
                        }
                    ],
                    style: {
                        flex: {
                            direction: "row",
                            justify: "center",
                            wrap: true,
                            gap: "4"
                        },
                        padding: {
                            top: "5",
                            bottom: "10"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                },
            ],
            style: {
                bg: {
                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-12-08_10-27-46_ZQn.jpg",
                    color: "black",
                    place: "item",
                    opacity: "50"
                },
                text: {
                    align: "center",
                    color: "white"
                },
                padding: {
                    top: "20",
                    left: "6",
                    right: "6",
                    bottom: "20"
                }
            }
        }]
    },
    'button/light': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h2",
                                content: "{locale:container.example.text.header}"
                            },
                            style: {
                                padding: {
                                    bottom: "6"
                                },
                                text: {
                                    size: 'h1'
                                },
                                classes: {
                                    custom: ['md:text-4xl']
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.cover.desc}"
                            },
                        },
                    ],
                    style: {
                        padding: {
                            top: "10",
                            bottom: "5"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                },
                {
                    type: "group",
                    items: [
                        {
                            type: "core/form/button/url",
                            spec: {
                                icon: {
                                    set: "regular",
                                },
                                size: "medium",
                                color: "primary",
                                label: "{locale:container.example.cover.button_1}",
                                variant: "contained",
                                is_external: false
                            },
                            style: {
                                border: {
                                    shadow: 'md',
                                }
                            },
                            wrap: {
                                flex: {
                                    direction: "inline"
                                },
                            },
                        },
                        {
                            type: "core/form/button/url",
                            spec: {
                                icon: {
                                    set: "regular",
                                },
                                size: "medium",
                                color: "light",
                                label: "{locale:container.example.cover.button_2}",
                                variant: "contained",
                                is_external: false
                            },
                            style: {
                                border: {
                                    shadow: 'md',
                                }
                            },
                            wrap: {
                                flex: {
                                    direction: "inline"
                                },
                            },
                        }
                    ],
                    style: {
                        flex: {
                            direction: "row",
                            justify: "center",
                            wrap: true,
                            gap: "4"
                        },
                        padding: {
                            top: "5",
                            bottom: "10"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                },
            ],
            style: {
                bg: {
                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-12-08_10-05-13_1Ne.jpg",
                    color: "white",
                    place: "item",
                    opacity: "80"
                },
                text: {
                    align: "center",
                    color: "slate-900"
                },
                padding: {
                    top: "20",
                    left: "6",
                    right: "6",
                    bottom: "20"
                }
            }
        }]
    },
    'simple/dark': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h2",
                                content: "{locale:container.example.text.header}"
                            },
                            style: {
                                padding: {
                                    bottom: "6"
                                },
                                text: {
                                    size: 'h1'
                                },
                                classes: {
                                    custom: ['md:text-4xl']
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.cover.desc}"
                            },
                        },
                    ],
                    style: {
                        padding: {
                            top: "10",
                            bottom: "10"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                }
            ],
            style: {
                bg: {
                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-12-08_10-26-18_JQy.jpg",
                    color: "black",
                    place: "item",
                    opacity: "50"
                },
                text: {
                    align: "center",
                    color: "white"
                },
                padding: {
                    top: "16",
                    left: "4",
                    right: "4",
                    bottom: "16"
                }
            }
        }]
    },
    'simple/light': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h2",
                                content: "{locale:container.example.text.header}"
                            },
                            style: {
                                padding: {
                                    bottom: "6"
                                },
                                text: {
                                    size: 'h1'
                                },
                                classes: {
                                    custom: ['md:text-4xl']
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.cover.desc}"
                            },
                        },
                    ],
                    style: {
                        padding: {
                            top: "10",
                            bottom: "10"
                        },
                        width: {
                            container: 'md',
                        }
                    }
                }
            ],
            style: {
                bg: {
                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-12-08_10-13-20_Pgh.jpg",
                    color: "white",
                    place: "item",
                    opacity: "80"
                },
                text: {
                    align: "center",
                    color: "slate-900"
                },
                padding: {
                    top: "16",
                    left: "4",
                    right: "4",
                    bottom: "16"
                }
            }
        }]
    },
}