import React, {useEffect, useState, useContext} from 'react'
import {Menu, Dropdown, Typography} from 'antd';
import {DownOutlined, PlusOutlined} from '@ant-design/icons';
import classes from './ButtonsPublishExit.module.scss'
import {isMobile920} from "../../../../../library/isMobile";
import {routes} from "../../../../../config/config";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Fa from "../../../../../components/Icon/Fa";
import FlowContext from "../../../FlowContext";

const {Text} = Typography;

const DropBox = ({dropMenuId, processList, createNewGraph}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {admin, graph} = useSelector(store => store)
    const [activeMenu, setActiveMenu] = useState(null);
    const {resetGraphPosition} = useContext(FlowContext);

    if (!processList.length || dropMenuId < 0) return null;

    if (activeMenu === null || (activeMenu && activeMenu.id !== processList[dropMenuId].id)) {
        // console.log('setActiveMenu', activeMenu, dropMenuId, processList[dropMenuId].id)
        setActiveMenu(processList[dropMenuId]);
        return null;
    }

    if (processList.findIndex(graph => graph.id === 0) === -1) { // добавить опцию добавления схемы, если еще нет
        processList.push({
            id: 0,
            pid: 0,
            title: <Text type="secondary"><PlusOutlined/> {t('graph.flow.list.add')}</Text>
        })
    }

    const changeActiveMenu = (graph_id, project_id) => {
        resetGraphPosition();
        setActiveMenu(null);

        if (graph_id) {
            dispatch({type: 'getGraphItem', admin, id: graph_id});    // получаем процесс (graph) с заданным ID
            navigate(`${routes.project_list}/${project_id}/graph/${graph_id}`, {replace: true})
        } else {
            createNewGraph();
        }
    }

    // const item = processList.find(el => el.id === activeMenu.id);

    const items = processList.map((item, index) => {
            return ({
                key: index,
                label: <div
                    onClick={changeActiveMenu.bind(this, item.id, item.pid)}
                    className={index === dropMenuId ? classes.item_active : ''}
                    // className={`${item.is_on ? '' : classes.item_disabled} ${index === dropMenuId ? classes.item_active : ''}`}
                >
                    {/*{item.id ? <Fa icon={item.icon_name} className={classes.item_icon}/> : null}*/}
                    {item.title}
                </div>,
            })
        }
    )

    // const class_name = isMobile920 ? classes.wrapper_drop_mobile : classes.wrapper_drop

    return (
        <Dropdown menu={{items}} trigger={['click']} className={classes.wrapper_drop}>
            <div className='ant-dropdown-link'>
                <span className={classes.graph_title}>{graph.item.title}</span>
                <DownOutlined className={classes.downOutlined}/>
            </div>
        </Dropdown>
    )
}

export default DropBox