import React from 'react';
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

export const SorterWrapper = ({children, className = ''}) => {
    return (
        <span className={`column-sorter-wrapper ${className}`}>{children}
            <ArrowUpOutlined/>
            <ArrowDownOutlined/>
        </span>
    );
};

