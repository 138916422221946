import React, {useEffect, useState} from 'react'

import "./Tailwind.scss";
import classes from "./BlockList.module.scss";

import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {arrayReorder} from "../../../../library/functions";
import {BlockContainers} from "./BlockContainers";

// let columns = {};

export const BlockSect = (
    {
        t,
        isOn,
        screen,
        section,
        getNewId,
        setVisibleModal,
        setOpenedSection,
        onChange,
        className = null,
    }
) => {

    const [columns, setColumns] = useState({});

    useEffect(() => {
        let columns = {};
        const colSection = section.id;

        if (section.items && section.items.length > 0) {
            for (const row of section.items) {
                const colRow = row.id;

                for (const col of row.items) {
                    const colId = col.id;
                    if (!columns[colId]) columns[colId] = {};
                    columns[colId] = {
                        section: colSection,
                        row: colRow,
                    };
                }
            }

            setColumns(columns);
        }
    }, [section]);

    if (!section) return 'The section is empty';

    const sectionId = section['id'] || '0';
    const sectionRows = section['items'] || [];

    const onDragEnd = (result) => {
        // console.log('onDragEnd', result.source.droppableId, columns);
        let newRows = [...sectionRows];

        if (result.type === 'block') {
            // source
            const sourceDroppableId = result.source.droppableId;
            const sourceId = Number(sourceDroppableId.replace('c', ''));

            const sourceRowId = columns[sourceId].row;
            const sourceRowIndex = newRows.findIndex(item => item.id === sourceRowId);

            const sourceCols = [...newRows[sourceRowIndex].items];
            const sourceColIndex = sourceCols.findIndex(item => item.id === sourceId);

            // destination - part 1
            const destinationDroppableId = result.destination.droppableId;
            const destinationId = Number(destinationDroppableId.replace('c', ''));

            let sourceBlocks = [...sourceCols[sourceColIndex].items];
            const currentBlock = sourceBlocks[result.source.index];

            // console.log('sourceId', sourceId, 'destinationId', destinationId);
            // console.log('sourceBlocks', sourceBlocks, 'currentBlock', currentBlock);
            // console.log('sourceRowIndex', sourceRowIndex, 'sourceColIndex', sourceColIndex);

            // Check if the item was dropped into a different container
            if (sourceId !== destinationId) {
                // destination - part 2
                const destinationRowId = columns[destinationId].row;
                const destinationRowIndex = newRows.findIndex(item => item.id === destinationRowId);

                const destinationCols = [...newRows[destinationRowIndex].items];
                const destinationColIndex = destinationCols.findIndex(item => item.id === destinationId);

                // remove item from source
                sourceBlocks.splice(result.source.index, 1);
                // sourceCols[sourceColIndex] = {...sourceCols[sourceColIndex], items: sourceBlocks};
                // newRows[sourceRowIndex] = {...newRows[sourceRowIndex], items: sourceCols};

                // add item to destination
                let destinationBlocks = [...destinationCols[destinationColIndex].items];
                destinationBlocks.splice(result.destination.index, 0, currentBlock);
                // destinationCols[destinationColIndex] = {...destinationCols[destinationColIndex], items: destinationBlocks};
                // newRows[destinationRowIndex] = {...newRows[destinationRowIndex], items: destinationCols};

                if (sourceRowId !== destinationRowId) {
                    sourceCols[sourceColIndex] = {...sourceCols[sourceColIndex], items: sourceBlocks};
                    newRows[sourceRowIndex] = {...newRows[sourceRowIndex], items: sourceCols};

                    destinationCols[destinationColIndex] = {
                        ...destinationCols[destinationColIndex],
                        items: destinationBlocks
                    };
                    newRows[destinationRowIndex] = {...newRows[destinationRowIndex], items: destinationCols};
                } else {
                    sourceCols[sourceColIndex] = {...sourceCols[sourceColIndex], items: sourceBlocks};
                    sourceCols[destinationColIndex] = {...sourceCols[destinationColIndex], items: destinationBlocks};
                    newRows[sourceRowIndex] = {...newRows[sourceRowIndex], items: sourceCols};
                }

            } else {
                sourceBlocks = arrayReorder(
                    sourceBlocks,
                    result.source.index,
                    result.destination.index
                );

                sourceCols[sourceColIndex] = {...sourceCols[sourceColIndex], items: sourceBlocks};
                newRows[sourceRowIndex] = {...newRows[sourceRowIndex], items: sourceCols};
            }

        } else if (result.type === 'group') {
            const sourceDroppableId = result.source.droppableId;
            const sourceId = Number(sourceDroppableId.replace('r', ''));
            const sourceIndex = newRows.findIndex(item => item.id === sourceId);

            const destinationDroppableId = result.destination.droppableId;
            const destinationId = Number(destinationDroppableId.replace('r', ''));
            const destinationIndex = newRows.findIndex(item => item.id === destinationId);

            let blocklist = [...newRows[sourceIndex].items];

            // Check if the item was dropped into a different container
            if (sourceId !== destinationId) {
                // Remove the item from the source container
                const sourceItems = [...newRows[sourceIndex].items];
                sourceItems.splice(result.source.index, 1);
                newRows[sourceIndex] = {...newRows[sourceIndex], items: sourceItems};

                // and remove row if empty
                // if (sourceItems.length === 0) newList.splice(sourceIndex, 1);
                // else newList[sourceIndex] = {...newList[sourceIndex], items: sourceItems};

                // Add the item to the destination container
                const destinationItems = [...newRows[destinationIndex].items];
                destinationItems.splice(result.destination.index, 0, blocklist[result.source.index]);
                newRows[destinationIndex] = {...newRows[destinationIndex], items: destinationItems};
            } else {
                blocklist = arrayReorder(
                    blocklist,
                    result.source.index,
                    result.destination.index
                );

                newRows[sourceIndex] = {...newRows[sourceIndex], items: blocklist};
            }
        } else {
            // result.type === 'con'
            newRows = arrayReorder(
                newRows,
                result.source.index,
                result.destination.index
            );
        }

        onChange(newRows);
    }

    const getScreenClasses = (screen) => {
        // 'ts': '375',
        // 'xs': '670',
        // 'sm': '768',
        // 'md': '992',
        // 'lg': '> 1200',
        // 'xl': '> 1600',

        // screens: {
        //     xs: '576px',
        //     sm: '768px',
        //     md: '992px',
        //     lg: '1200px',
        //     xl: '1600px',
        // },

        let classes = [];

        const screens = ['xs', 'sm', 'md', 'lg', 'xl'];
        if (screen !== 'ts') {
            for (const s of screens) {
                classes.push('screen-' + s);

                // stop if smaller
                if (screen === s) break;
            }
        }

        return classes.join(' ');
    }

    const screenClasses = getScreenClasses(screen);

    return <div className={'p-root ' + className}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="section" direction="vertical" type="con">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`p-sec page-root tw ${screenClasses}`}
                    >
                        <BlockContainers
                            getNewId={getNewId}
                            rows={sectionRows}
                            sectionId={sectionId}
                            onChange={onChange}
                            isOn={isOn}
                        />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

        <div className={classes.containerAdd}>
            <Button
                type="text"
                className="system"
                icon={<PlusOutlined/>}
                onClick={() => {
                    setOpenedSection(sectionId);
                    setVisibleModal(true);
                }}
            >
                {t('block.container.add.button')}
            </Button>
        </div>
    </div>
}