import React from 'react';
import {Handle, Position} from "react-flow-renderer";
import classes from "./Port.module.scss";
import {CheckOutlined, CloseOutlined, MehOutlined} from "@ant-design/icons";

const buttonIcons = {
    success: <CheckOutlined/>,
    failure: <CloseOutlined/>,
    danger: <CloseOutlined/>,
    warning: <MehOutlined/>,
}

export const PortsGroup = ({allPorts, groupName = 'outputs', portType = 'source'}) => {
    const items = allPorts[groupName];
    if (!items.length) return null;
    const ports = items.map((port) => {
        // let nodeId = port.nodeLocalId ? port.nodeLocalId : nodeLocalId;
        let portClassname = `${classes.port} ${classes[portType + '_port']}`;
        if (port.hasEdge) portClassname += ' ' + classes.port_has_edge;
        return (
            <Handle
                // isValidConnection={false} // TODO: add function (?)
                id={port.id}
                key={'port-' + port.id}
                title={`Port #${port.id}`}
                type={portType}
                // data-nodeid={nodeId}
                connected={String(port.hasEdge)}
                position={portType === "target" ? Position.Left : Position.Right}
                className={portClassname}
                data-handletype={portType}
            />
        )
    });

    let wrapperClassname = `${classes.ports_wrapper} ${classes[groupName + '_ports']}`;
    return <div className={wrapperClassname}>
        {ports}
    </div>
};

export const PortButtons = ({allPorts, locale, groupName = 'outputs'}) => {
    const items = allPorts[groupName];
    if (!items.length) return null;
    const ports = items.map((port, index) => {
        // init constants
        const id = port.id;
        const number = index + 1;
        let type = port.type ? port.type : 'default';
        let name = port.name ? port.name : 'default';
        // let nodeId = port.nodeLocalId ? port.nodeLocalId : nodeLocalId;

        // and vars
        let buttonIcon = null;
        let portDefaultTitle = locale('graph.flow.port.' + name + '.label');
        if (name === 'button' || name === 'condition') portDefaultTitle += ' ' + number;

        // set classes
        let portClassname = `${classes.port} ${classes.source_port}  ${classes.buttons_ports}`;
        if (port.hasEdge) portClassname += ' ' + classes.port_has_edge;

        if (type in buttonIcons) {
            buttonIcon = buttonIcons[type];
            portClassname += ' ' + classes['port_' + type];
        }

        return (
            <div className={classes.node_button_wrapper} key={`node_button_wrapper_${id}`}>
                <div className={classes.node_button}>
                    <div className={classes.node_button_text}>
                        {port.title ? port.title : portDefaultTitle}
                    </div>
                    <span className={port.type ? 'color-' + port.type : ''}>
                        {buttonIcon}
                    </span>
                </div>
                <Handle
                    id={id}
                    title={`Port #${port.id}`}
                    type="source"
                    position="right"
                    data-handletype="source"
                    //data-nodeid={nodeId}
                    connected={String(port.hasEdge)}
                    className={portClassname}
                />
            </div>
        )
    });

    return <div className={classes.node_buttons_wrapper}>
        {ports}
    </div>
};


// export const PortButtons = () => {
//         if(btns.length > 0) {
//             const buttons = btns.map((btn, i) => {
//                 let id = btn.name, connected = data.outputs[id].connected, single_button;
//                 if(btn.type === 'success') {
//                     single_button =
//                         <div id={divid+'_border_'+i} className="button_out_text" key={`button_wrapper_${id}_${i}`}>
//                             <div id={divid + '_' + i} style={{width: '100%', position: 'relative', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
//                                 <div id={btnid + '_' + i} className='button_text' key={`button_text_${id}_${i}`}>{btn.text?btn.text:outSuccessText}</div><CheckOutlined style={{color:'#67C23A'}}/>
//                             </div>
//                             <Handle id={outSuccess} data-type='source' type='source' position='right' connected={connected} className={`handle_selected ${class_mobile} ${btn.type === 'success' ? 'btn_yes' : btn.type === 'failure' ? 'btn_no' : ''}`}
//                                     key={`button_out_${id}_${i}`} data-button={i} style={{ right: (conn_offset-10), top:0}}/>
//                         </div>
//                 } else if(btn.type !== 'failure') {
//                     single_button =
//                         <div id={divid+'_border_'+i} className="button_out_text" key={`button_wrapper_${id}_${i}`}>
//                             <div id={divid + '_' + i} style={{width: '100%', position: 'relative'}}>
//                                 <div id={btnid + '_' + i} className='button_text' key={`button_text_${id}_${i}`}>{btn.text?btn.text:t('graph.flow.node.condition.button.label')}</div>
//                             </div>
//                             <Handle id={id} data-type='source' type='source' position='right' connected={connected} className={'handle_selected '+class_mobile}
//                                     key={`button_out_${id}_${i}`} data-button={i} style={{ right: (conn_offset-10), top:0}}/>
//                         </div>
//                 }
//                 return single_button;
//             })
//             return buttons;
//         } else {
//             return null;
//         }
//     }
