import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    changeGraphStatus,
    addGraphToList,
    fillGraphList,
    patchGraphInList,
    removeGraphFromList,
    setGraphItem,
} from "../reducers/GraphReducer";
import {setLoadingStatus} from "../reducers/StatusReducer";
import {setCorrect} from "../reducers/AdminReducer";
import {downloadFileFromText, deepGet} from "../../library/functions";
import {fillGraphNodeList} from "../reducers/GraphNodeReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.process;

function* getGraphList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillGraphList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        // if (error.response.status === 401) {
        //     yield put(setCorrect(false))
        //     return null
        // }
        apiErrorNotice(error)
        return null
    }
}

function* getGraphItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/get/' + arg.id, 'get');

        if (response.data.ok) {
            yield put(setGraphItem(response.data.result));
            yield put(setLoadingStatus('ready'));
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
            return null
        }
        apiErrorNotice(error)
        return null
    }
}

function* getGraphNodeList(arg) {
    try {
        if (!arg.filters || !arg.filters.graph_id) return null

        const response = yield request(arg.admin.token, base_path + '/get/' + arg.filters.graph_id, 'get');
        if (response.data.ok) {
            const result = deepGet(response, 'data.result.logic.nodes',[])
            // console.log('getGraphNodeList result', result)
            yield put(fillGraphNodeList(result));
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
            return null
        }
        apiErrorNotice(error)
        return null
    }
}

function* exportGraphLogic(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/get/' + arg.id, 'get');

        if (response.data.ok) {
            const graphItem = response.data.result;
            const graphDataText = JSON.stringify({title: graphItem.title, logic: graphItem.logic});
            const fileName = 'graph-' + graphItem.id + '-' + Date.now();
            downloadFileFromText(graphDataText, fileName, 'json');
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
            return null
        }
        apiErrorNotice(error)
        return null
    }
}

function* runGraphForUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/run/user', 'post', arg.data);

        if (response.data.ok) {
            notice.success(l("graph.notice.run.success"))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createGraph(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(setGraphItem(response.data.result))
            yield put(addGraphToList(response.data.result))
            yield put(setLoadingStatus('ready'));
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyGraph(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/get/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        newItem.is_on = false  // disable new graph
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setGraphItem(response.data.result))
            yield put(addGraphToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateGraph(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchGraphInList(response.data.result))
            yield put(changeGraphStatus({editing: false}))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreGraph(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeGraphFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteGraph(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeGraphFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* graphSaga() {
    yield takeEvery('getGraphList', getGraphList);
    yield takeEvery('getGraphItem', getGraphItem);
    yield takeEvery('exportGraphLogic', exportGraphLogic);
    yield takeEvery('getGraphNodeList', getGraphNodeList);
    yield takeEvery('runGraphForUser', runGraphForUser);
    yield takeEvery('copyGraph',    copyGraph);
    yield takeEvery('createGraph',  createGraph);
    yield takeEvery('updateGraph',  updateGraph);
    yield takeEvery('restoreGraph', restoreGraph);
    yield takeEvery('deleteGraph',  deleteGraph);
}

