import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
        item: {},
        count: 0,
        page: 0,
        pageSize: 10,
        sorter: null,
        filters: {},
}

const DiscountReducer = createSlice({
    name: 'discount',
    initialState: initialState,
    reducers: {
        fillDiscountList(state, action) {
            state.list = action.payload
        },
        setDiscountItem(state, action) {
            state.item = action.payload;
        },
        setDiscountListCount(state, action) {
            state.count = action.payload;
        },
        setDiscountStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addDiscountToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeDiscountFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchDiscountInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetDiscountState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default DiscountReducer.reducer
export const {
    fillDiscountList,
    setDiscountItem,
    resetDiscountState,
    setDiscountListCount,
    patchDiscountInList,
    addDiscountToList,
    removeDiscountFromList
} = DiscountReducer.actions