import React from 'react'
import classes from "./ContentCard.module.scss";

export const ContentCard = ({children, className = '', flat_xs= false}) => {
    let classNames = `site-layout-background ${classes.card}`;
    if (className) classNames += ' ' + className
    if (flat_xs) classNames += ' ' + classes.flat_on_xs

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}


