import {createSlice} from "@reduxjs/toolkit";

const ContactReducer = createSlice({
    name: 'contact',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillContactList(state, action) {
            state.list = action.payload
        },
        setContactItem(state, action) {
            state.item = action.payload;
        },
        setContactListCount(state, action) {
            state.count = action.payload;
        },
        addContactToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeContactFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchContactInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetContactState(state) {
            state.list = [];
            state.item = {};
            state.count = 0;
        },
    }
})

export default ContactReducer.reducer
export const {
    fillContactList,
    setContactItem,
    resetContactState,
    setContactListCount,
    patchContactInList,
    addContactToList,
    removeContactFromList
} = ContactReducer.actions