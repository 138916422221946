import {notice} from "./notice";
import {l} from "./locale";

export const copyValueToClipboard = (e) => {
    copyToClipboard(e.target.value);
}

export const copyToClipboard = (text, notification_text = 'default') => {
    // let str = text
    // const el = document.createElement('textarea');
    // document.body.appendChild(el);
    // el.value = str; el.setAttribute('readonly', '');
    // el.style.position = 'absolute'; el.style.left = '-9999px';
    // el.select(); document.execCommand('copy'); // TODO: deprecated
    // document.body.removeChild(el);

    navigator.clipboard.writeText(text).then(() => {
        if (notification_text === 'default') {
            notice.success(l('common.result.copied.neuter'));
        } else if (notification_text) {
            notice.success(notification_text);
        }
    })
}

export const readFromClipboard = (callback) => {
    // TODO: it is not working in firefox
    navigator.clipboard.readText().then(text => {
        callback(text);
    }).catch(err => {
        console.error('Failed to read clipboard contents: ', err);
    });
}