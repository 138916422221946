import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CheckOutlined, DeleteOutlined, PlusOutlined, RollbackOutlined} from "@ant-design/icons";
import {Button, Modal, Row} from "antd";
import {notice} from "../../../library/notice";

import {NoForm} from "../../Form/NoForm";
import {FormInput} from "../../Form/FormInput";
import {clearFolderItem, setFolderItem} from "../../../redux/reducers/FolderReducer";
import {filterArrayByFieldValue} from "../../../library/functions";
import {useTranslation} from "react-i18next";

export const ListFolderManager = ({section, list}) => {
    // init hook functions
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // init store
    const {admin, project, folder} = useSelector(store => store)
    const project_item = project.item
    const folders = folder.list
    const item = folder.item

    // init states
    const [isModalOpen, setModalStatus] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef()

    useLayoutEffect(() => {
        // set modal options to default if opened on folders list update
        if (isModalOpen) {
            hideModal()
        }
        //eslint-disable-next-line
    }, [folders && folders.length])

    useEffect(() => {
        // set modal options to default if opened

        if (item.id === undefined) {
            hideModal()
        } else {
            setInputValue(item.title)
            showModal()
        }

        //eslint-disable-next-line
    }, [item.id])

    const folderCreate = (title = 'New Folder') => {
        const data = {
            project_id: project_item.id,
            title: title,
            section: section,
        }
        dispatch({type: 'createFolder', admin, data});
    }

    const folderUpdate = (id = 0, title = 'Folder') => {
        const data = {
            project_id: project_item.id,
            id: id,
            title: title,
        }
        dispatch({type: 'updateFolder', admin, data});
    }

    const folderCreateOpen = () => {
        dispatch(setFolderItem({id: 0}))
        setInputValue('')
        showModal()
    }

    // ====== USE THIS IN CONTAINERS ======
    // const folderEditOpen = (id) => {
    //     dispatch(setFolderItemById(id))
    // }

    const showModal = () => {
        setModalStatus(true)

        setTimeout(() => {
            if (inputRef && inputRef.current) {
                const {input} = inputRef.current
                input.focus()
            }
        }, 500)
    }

    const hideModal = () => {
        dispatch(clearFolderItem())
        setInputValue('')
        setModalStatus(false);
        setConfirmLoading(false);
    }

    const handleOk = () => {
        setConfirmLoading(true);

        if (folder.item.id && inputValue) folderUpdate(folder.item.id, inputValue)
        else if (inputValue) folderCreate(inputValue)
        else {
            notice.warning(t('error.validation.required_name')).then();
            showModal();
        }

        setTimeout(() => {
            setConfirmLoading(false);
        }, 1000);
    };

    const handleCancel = () => {
        hideModal()
    };

    const onModalInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const onModalFormSubmit = (e) => {
        onModalInputChange(e)
        handleOk()
    }

    const onModalInputKeyDown = (e) => {
        if (e.code === 'Enter') {
            onModalFormSubmit(e)
        }
    }

    const deleteFolder = () => {
        const folderItems = filterArrayByFieldValue(list, 'folder_id', folder.item.id)
        if (folderItems.length > 0) {
            notice.error(t('folder.error.delete_empty'))
        } else {
            dispatch({type: 'deleteFolder', admin, id: folder.item.id});
        }
    }

    const restoreFolder = () => {
        const data = {
            project_id: folder.item.project_id,
            id: folder.item.id,
            deleted_at: 0,
        }
        dispatch({type: 'updateFolder', admin, data});
    }

    const deleted = folder.item.deleted_at > 0;

    return (
        <>
            <Row className="float-right margin-top-ps">
                <Button
                    type="text"
                    size="large"
                    className="inverted"
                    onClick={folderCreateOpen}
                    icon={<PlusOutlined/>}
                >{t('folder.add')}</Button>
            </Row>
            <Modal
                className="modal-clean"
                title={t('folder.settings')}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="user"
                        type="text"
                        className={folder.item.id ? 'float-left inverted' : 'hide'}
                        onClick={deleted ? restoreFolder : deleteFolder}
                        icon={deleted ? <RollbackOutlined/> : <DeleteOutlined/>}>
                        {deleted ? t('folder.restore') : t('folder.delete')}
                    </Button>,
                    <Button
                        key="modal-back"
                        type="text"
                        onClick={handleCancel}
                        className="inverted"
                    >{t('common.action.cancel')} </Button>,
                    <Button
                        key="modal-submit"
                        type="primary"
                        onClick={handleOk}
                        loading={confirmLoading}
                        icon={<CheckOutlined/>}
                    >{folder.item.id ? t('common.action.apply') : t('common.action.create')}</Button>
                ]}
            >

                <NoForm>
                    <FormInput
                        inputId="projectTitleInput"
                        className="margin-none"
                        // label={t('common.form.name.label')}
                        placeholder={folder.item.id ? t('folder.enter_edit.placeholder') : t('folder.enter_new.placeholder')}
                        autoFocus={true}
                        inputRef={inputRef}
                        inputValue={inputValue} // must be state
                        onChange={onModalInputChange}
                        onKeyDown={onModalInputKeyDown}
                    />
                </NoForm>
            </Modal>
        </>
    )
}