import React from 'react'

const Select = () => {
    return (
        <span role="img" aria-label="select" className="anticon">
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 1.88867H2.75C2.33516 1.88867 2 2.22383 2 2.63867V19C2 19.4149 2.33516 19.75 2.75 19.75H8.1875C8.29063 19.75 8.375 19.6657 8.375 19.5625V18.25C8.375 18.1469 8.29063 18.0625 8.1875 18.0625H3.6875V3.57617H18.0625V8.18755C18.0625 8.29067 18.1469 8.37505 18.25 8.37505H19.5625C19.6656 8.37505 19.75 8.29067 19.75 8.18755V2.63867C19.75 2.22383 19.4148 1.88867 19 1.88867Z" fill='currentColor'/>
                <path d="M22 7L7.75 6.75C7.33516 6.75 7 7.08516 7 7.5V22C7 22.4148 7.33516 22.75 7.75 22.75H13.1875C13.2906 22.75 13.375 22.6656 13.375 22.5625V21.25C13.375 21.1469 13.2906 21.0625 13.1875 21.0625H8.6875L8.5 8.5L21.0625 8.6875V13.1875C21.0625 13.2906 21.1469 13.375 21.25 13.375H22.5625C22.6656 13.375 22.75 13.2906 22.75 13.1875V7.75C22.75 7.33516 22.4148 7 22 7Z" fill='currentColor'/>
                <path d="M20.7855 15.7151L18.7156 16.9908C18.7156 16.9908 21.8271 20.0875 21.9259 20.1878C22.0247 20.288 22.0247 20.453 21.9259 20.5533L20.5749 21.9248C20.4762 22.0251 20.3137 22.0251 20.2149 21.9248C20.1161 21.8245 17.0014 18.7311 17.0014 18.7311L15.7385 20.8389C15.5887 20.9909 15.3306 20.9003 15.3051 20.6869L12.0018 12.2897C11.9826 12.1215 12.1228 11.9824 12.2853 12.0018L20.6358 15.2752C20.6832 15.2811 20.728 15.3004 20.7651 15.3309C20.8023 15.3614 20.8302 15.4019 20.8458 15.4477C20.8614 15.4936 20.8641 15.5429 20.8534 15.5902C20.8427 15.6375 20.8192 15.6807 20.7855 15.7151Z" fill='currentColor'/>
            </svg>
        </span>
    )
}

export default Select