import React from 'react';
import {Button, PageHeader, Layout} from "antd";
import {l} from "../../library/locale";
import {ArrowLeftOutlined, CheckOutlined} from "@ant-design/icons";

const {Header} = Layout;

export const FormTitle = ({children, className = '', onBack = null, onPrimary=null}) => {
    let classes = "padding-none-horizontal heading-only-xs";
    if (className) {
        classes += ' ' + className;
    }
    return (
        <Header className="site-layout-background page-container-horizontal-padding has-banner">
            <PageHeader
                title={children}
                className={classes}
                extra={[
                    // back
                    <Button key="header-return-button" type="text" icon={<ArrowLeftOutlined/>} className="hidden-sm"
                            onClick={onBack}>
                        {l('common.action.back')}
                    </Button>,

                    // primary
                    <Button key="header-primary-button" type="primary" htmlType="submit" className="hidden-sm"
                            icon={<CheckOutlined/>} onClick={onPrimary}>
                        {l('common.action.save')}
                    </Button>,
                ]}
            />
        </Header>
    );
};

