import React from 'react'
import classes from './NodeButtonsWrapper.module.scss'

const NodeButtonsWrapper = ({children, className = 'nodrag', style={}}) => {

    return (
        <div className={`${classes.node_buttons_wrapper} ${className} `} style={style}>
            {children}
        </div>
    )
}

export default NodeButtonsWrapper