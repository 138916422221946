import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {images, routes} from "../../../config/config";
import {momentFromUnix, ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import ListTable from "../../../components/List/Table/ListTable";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../../library/clipboard";
import {AiOutlineCopy, AiOutlineSetting} from "react-icons/ai";
import {SorterWrapper} from "../../../components/List/Table/SorterWrapper";
import {setProductStoreParam} from "../../../redux/reducers/ProductReducer";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const EmployeeListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 100,
    }
) => {

    const {t} = useTranslation()
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${record.id}`)
    const recordOpen = (record) => recordEdit(record)
    // saga
    const recordCopy = (id) => dispatch({type: 'copy'+ Section, admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'avatar',
            title: <SorterWrapper>&nbsp;&nbsp;{t('common.table.header.id')}</SorterWrapper>,
            width: 80,
            sorter: true,
            // responsive: ['sm'],
            render: (image, record) => <TableAvatar imageUrl={record.image} icon="user"/>,
            // onCell: (record) => {
            //     return {
            //         onClick: (ev) => recordOpen(record.id)
            //     };
            // },
        },
        {
            dataIndex: 'title',
            className: '',
            title: <SorterWrapper>{t('common.table.header.first_name')}</SorterWrapper>,
            sorter: true,
            render: (title, record) => {
                return record.status ? <Text>{title}</Text> : <Text type="danger">{title}</Text>
            },
            // onCell: (record, rowIndex) => {
            //     return {
            //         onClick: (ev) => recordOpen(record)
            //     };
            // },
        },
        {
            dataIndex: 'created_at',
            className: 'text-secondary',
            title: <SorterWrapper>{t(section + '.list.header.created')}</SorterWrapper>,
            sorter: true,
            responsive: ['md'],
            width: 130,
            render: (title, record) => momentFromUnix(record.created, 'DD MMM YYYY'),
        },
        {
            dataIndex: 'is_on',
            className: 'cursor-pointer',
            title: <SorterWrapper>{t(section + '.list.header.access')}</SorterWrapper>,
            sorter: true,
            responsive: ['sm'],
            width: 115,
            render: (status) => {
                return <Tooltip title={t('common.action.click_for_toggle')}>{status ?
                    <Text>{t('common.status.male.opened')}</Text> :
                    <Text type="danger">{t('common.status.male.closed')}</Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordChangeStatus(record.id, !record.status)
                };
            },
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        // {
        //     key: 'tableMenuEdit',
        //     label: t('table.menu.edit'),
        //     action: (record) => recordEdit(record)
        // },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status),
            render: (record) => record.status ? t('common.action.off') : t('common.action.on')
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            image: record.photo_url && record.photo_url !== '' ? record.photo_url : images.universe.sections.user,
            title: record.title,
            status: record.is_on,
            is_on: record.is_on,
            deleted: record.deleted_at > 0,
            created: record.created_at,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setProductStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.restore')}</Button>
            </div>
        </div>
    )
}

