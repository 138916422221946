import {createSlice} from "@reduxjs/toolkit";

const SectReducer = createSlice({
    name: 'sect',
    initialState: {
        list: [],
        item: {},
        count: 0,
        page: 0,
        pageSize: 10,
        sorter: null,
        filters: {},

        integration: [],
        container: [],
        event: [],
        condition: [],
        effect: [],
        pixel: [],
        plugin: [],
        sites: [],
        other: [],
    },
    reducers: {
        fillSectList(state, action) {
            state.list = action.payload
        },
        setSectItem(state, action) {
            state.item = action.payload;
        },
        setSectListCount(state, action) {
            state.count = action.payload;
        },
        setSectStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addSectToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeSectFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchSectInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
    }
})

export default SectReducer.reducer
export const {
    fillSectList,
    setSectItem,
    setSectListCount,
    setSectStoreParam,
    patchSectInList,
    addSectToList,
    removeSectFromList
} = SectReducer.actions