import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    ExportOutlined,
    GlobalOutlined,
    LinkOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {dotenv, images, routes} from "../../../config/config";
import {deepGet, is, ucFirst} from "../../../library/functions";
import {copyToClipboard} from "../../../library/clipboard";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import ListTable from "../../../components/List/Table/ListTable";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import {SorterWrapper} from "../../../components/List/Table/SorterWrapper";
import {setPageStoreParam} from "../../../redux/reducers/PageReducer";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const PageListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 10,
        categoriesMap,
    }
) => {
    const {t} = useTranslation();
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordOpen = (record) => {
        let url = `${routes.project_list}/${project.id}/${section}/edit/${record.id}`;
        if (filters.site_id) url += `?site_id=${filters.site_id}`;
        navigate(url)
    }

    const recordEdit = (record) => {
        let url = `${routes.project_list}/${project.id}/${section}/edit/${record.id}?tab=settings`;
        if (filters.site_id) url += `&site_id=${filters.site_id}`;
        navigate(url)
    }

    // const recordOpen = (record) => recordEdit(record)
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const openNewDomain = (record) => {
        let newDomainUrl = `${routes.project_list}/${project.id}${routes.local.domain_list}/edit/0`;
        if (record.site_id) newDomainUrl += `?site_id=${record.site_id}`;
        navigate(newDomainUrl);
    }

    const openRecord = (record) => {
        window.open(record.url, '_blank')
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar',
            title: <SorterWrapper>&nbsp;&nbsp;{t('common.table.header.id')}</SorterWrapper>,
            width: 70,
            sorter: true,
            responsive: ['sm'],
            render: (id, record) => <TableAvatar imageUrl={record.photo_url} icon="laptop" title={'ID: ' + record.id}/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            title: <SorterWrapper>{t('common.table.header.label')}</SorterWrapper>,
            sorter: true,
            render: (title, record) => {
                return <>
                    <div className={record.is_main ? 'text-underline' : null}>
                        {record.status ? <Text type={record.is_example ? "secondary" : null}>
                            {title}
                        </Text> : <Text type="danger">{title}</Text>}
                    </div>
                    <div className="color-gray-7 font-size-sm">
                        <span className="margin-right-xs visible-xs-inline">{record.site_title}: </span>
                        {record.is_main ? t(section + '.list.main.title') : record.path}
                    </div>
                </>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'site_title',
            title: t('common.table.header.site'),
            responsive: ['xl'],
            width: 200,
            render: (text, record) => <span className="text-secondary">
                {text}
            </span>,
        },
        {
            dataIndex: 'hits',
            title: <SorterWrapper>{t('common.table.header.hits')}</SorterWrapper>,
            sorter: true,
            className: ['align-center'],
            responsive: ['xl'],
            width: 150,
            render: (text, record) => <span className="text-secondary">
                {text}
            </span>,
        },
        {
            dataIndex: 'is_on',
            className: 'cursor-pointer',
            title: <SorterWrapper>{t('common.form.status')}</SorterWrapper>,
            sorter: true,
            responsive: ['md'],
            width: 115,
            render: (status) => {
                return <Tooltip title={t('common.action.click_for_toggle')}>{status ?
                    <Text>{t('common.status.short.on')}</Text> :
                    <Text type="danger">{t('common.status.short.off')}</Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordChangeStatus(record.id, !record.status)
                };
            },
        },
        {
            title: t('common.form.actions'),  // Действия
            key: 'actions',
            className: 'align-right',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={t(section + '.list.icon.path')}>
                        <Button
                            type="text"
                            onClick={() => copyToClipboard(record.path)}
                            icon={<LinkOutlined />}
                            size="large"
                        />
                    </Tooltip>
                    {record.domain ? <Tooltip title={t(section + '.list.icon.open')}>
                        <Button
                            type="text"
                            href={record.url}
                            target="_blank"
                            icon={<ExportOutlined />}
                            size="large"
                        />
                    </Tooltip> : (record.site_id ? <Tooltip title={t(section + '.list.icon.add_domain')}>
                        <Button type="text" icon={<GlobalOutlined/>} size="large" onClick={()=>openNewDomain(record)} />
                    </Tooltip> : null)}
                    <Tooltip title={t('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}
                            icon={<CopyOutlined />}
                            size="large"
                        />
                    </Tooltip>
                    <Tooltip title={t('table.menu.edit')}>
                        <Button type="text" icon={<SettingOutlined />} size="large" onClick={() => {
                            recordEdit(record)
                        }} />
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuOpen',
            action: (record) => record.domain ? openRecord(record) : openNewDomain(record),
            render: (record) => record.domain ? t(section + '.list.menu.open') : t(section + '.list.menu.add_domain'),
        },
        {
            key: 'tableMenuPath',
            label: t(section + '.list.menu.copy_path'),
            action: (record) => copyToClipboard(record.path),
        },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuCopy',
            label: t('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        const path = is(record.path) ? '/' + record.path.join('/') : ''
        const site = deepGet(categoriesMap, [record.site_id], {})
        const domain = deepGet(site, 'domain', false);
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            photo_url: is(record.photo_url) ? record.photo_url : images.universe.sections.page,
            site_id: record.site_id,
            site_title: site.title ?? '—',
            title: record.title,
            status: record.is_on,
            is_on: record.is_on,
            is_main: record.is_main,
            is_example: record.is_example,
            deleted: record.deleted_at > 0,
            hits: record.hits ?? 0,
            path: path,
            domain: domain,
            url: domain ? dotenv.sites_proto + domain + path : null,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setPageStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====
    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.restore')}</Button>
            </div>
        </div>
    )
}

