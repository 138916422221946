import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addDomainToList,
    fillDomainList,
    patchDomainInList,
    removeDomainFromList,
    setDomainItem,
    setDomainListCount,
} from "../reducers/DomainReducer";
import {l} from "../../library/locale";
import {notice} from "../../library/notice";

// init api methods
const base_path = backend_api.domain;

function* getDomainList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillDomainList(response.data.result))
            yield put(setDomainListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getDomainItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setDomainItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createDomain(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addDomainToList(response.data.result))
            yield put(setDomainItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateDomain(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchDomainInList(response.data.result))
            yield put(setDomainItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* installCertForDomain(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/certificate', 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchDomainInList(response.data.result))
            yield put(setDomainItem(response.data.result))
            notice.success(l('domain.message.ssl'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyDomain(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '_copy'

        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setDomainItem(response.data.result))
            yield put(addDomainToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreDomain(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeDomainFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteDomain(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeDomainFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* domainSaga() {
    yield takeEvery('getDomainList', getDomainList);
    yield takeEvery('getDomainItem', getDomainItem);
    yield takeEvery('createDomain', createDomain);
    yield takeEvery('copyDomain', copyDomain);
    yield takeEvery('updateDomain', updateDomain);
    yield takeEvery('restoreDomain', restoreDomain);
    yield takeEvery('deleteDomain', deleteDomain);
    yield takeEvery('installCertForDomain', installCertForDomain);
}

