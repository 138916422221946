import React from 'react'
import {deepGet, is} from "../../../../../../library/functions";

export const BlockVideoYouTube = (spec) => {
    if (!spec || !spec.url) return null;

    if (!spec.url.startsWith('https://')) return spec.url;

    // cut video id from spec.url by regexp:
    const idMatch = spec.url.match(/(?:v=|youtu\.be\/)([^&?]+)/)
    let videoId = idMatch && idMatch.length > 1 ? idMatch[1] : undefined;

    const classes = spec.orientation === 'portrait' ? 'aspect-w-9 aspect-h-16' : 'aspect-w-16 aspect-h-9';

    if (!videoId) {
        if (!spec.url.startsWith('https:') && spec.url.match(/^[\w_\-]+$/i)) videoId = spec.url
        else return 'Wrong video ID';
    }

    return <div className={`inline-block overlay-parent ${classes}`}>
        <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder="0"
            allowFullScreen
        />

        <div className="overlay-body"/>
    </div>
}

export const BlockVideoHtml = ({files, url, by_url, controls, poster_url}) => {
    let videoUrl = deepGet(files, '0.url', null);
    if (url && (!videoUrl || by_url)) videoUrl = url;

    if (url && !url.startsWith('https://')) return url;

    return <div className="inline-block overlay-parent">
        <video key={videoUrl} className="full-width" controls={controls || null} poster={poster_url || null}>
            <source src={videoUrl} type="video/mp4"/>
            Sorry. Your browser does not support the video tag
        </video>

        <div className="overlay-body"/>
    </div>
}