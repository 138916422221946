import React, {useState} from 'react';
import {Button, Modal, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import {backend_api} from "../../../config/config";
import {AiOutlineUpload} from "react-icons/ai";
import {l} from "../../../library/locale";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {uploadFilePreHandle} from "../../../library/forms";

export const AvatarUpload = (
    {
        storeImageUrl,
        project_id = '0',
        admin = {},
        siteSection = 'temp',
        imageType = 'avatar',
        buttonLabel = '',
        fileList = [],
        setFileList = null,
        limit = 1,
        aspect= 1,
    }
) => {
    const [isModalVisible, setModalStatus] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const showModal = (content = '') => {
        setModalStatus(true);
        setModalContent(content);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        setModalStatus(false);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        showModal(<img src={src} alt="Image Preview"/>)
    };

    const onImagesChange = ({file: newFile, fileList: newFileList}) => {
        // console.log('newFile',newFile)
        if (setFileList) setFileList(newFileList);
    }

    const onRemove = (file) => {
        let newFileList = fileList.filter((item) => item.status === 'removed');
        if (setFileList) setFileList(newFileList);
        storeImageUrl('')
    }

    const uploadImage = async options => {
        const {onSuccess, onError, file} = options;
        const postForm = uploadFilePreHandle(admin, project_id, siteSection, file, imageType);

        try {
            const res = await axios.post(backend_api.file, postForm.data, postForm.config);
            const result = res.data;
            onSuccess("Ok");

            if (!result.ok) apiFalseNotice(res);
            else storeImageUrl(result.result.url);

        } catch (error) {
            onError({error});
            apiErrorNotice(error);
        }
    };

    const buttonLabelResult = buttonLabel ? buttonLabel : l('common.form.photo_url.placeholder');
    const uploadComponent = () => {
        // console.log('render', fileList)
        return (
            <Upload
                accept="image/*"
                listType="picture-card"
                className={fileList.length ? "upload-replace image-uploader" : "image-uploader"}
                maxCount={limit}
                fileList={fileList}
                onPreview={onPreview}
                onChange={onImagesChange}
                onRemove={onRemove}
                customRequest={uploadImage}
            >
                <div>
                    <AiOutlineUpload size="22"/>
                    <div className="label">{fileList.length ? l('common.action.replace') : buttonLabelResult}</div>
                </div>
            </Upload>
        );
    }

    return (
        <div className="upload-images-wrapper">
            {(aspect > 0) ? <ImgCrop aspect={aspect} rotationSlider={true}>
                {uploadComponent()}
            </ImgCrop> : uploadComponent()}

            <Modal
                className="modal-clean"
                title={l('common.header.preview')}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="img-modal-back"
                        type="text"
                        onClick={handleCancel}
                    > {l('common.action.cancel')} </Button>,
                    <Button
                        key="img-modal-submit"
                        type="primary"
                        onClick={handleOk}
                    > {l('common.ok')} </Button>
                ]}>
                <p>{modalContent}</p>
            </Modal>
        </div>
    );
};

