import classes from "./Components/BlockList.module.scss";
import {deepGet, inArray, is} from "../../../library/functions";

const widthMap = {
    'height': ['height', 'amount'],
    'width': ['width', 'amount'],
    'minWidth': ['width', 'min'],
    'maxWidth': ['width', 'max'],
}

const addContainerClasses = (classes, value) => {
    if (!value) return classes;

    classes.push(...['mr-auto', 'ml-auto', 'container-' + value]);
}

const addClasses = (settings, classMap, param = 'item') => {
    if (!classMap[param]) return;

    // flex props
    const flex = deepGet(settings, ['flex'], {});

    const itemLayout = flex.direction
    const inlineBlock = itemLayout === 'inline';

    if (inlineBlock) classMap[param].push('inline-block');
    else if (itemLayout === 'grid') {
        classMap[param].push(itemLayout);
        const grid = deepGet(settings, ['grid'], {});

        if (grid.default) classMap[param].push('grid-cols-' + grid.default);
        if (grid.sm) classMap[param].push('sm:grid-cols-' + grid.sm);
        if (grid.md) classMap[param].push('md:grid-cols-' + grid.md);
        if (grid.lg) classMap[param].push('lg:grid-cols-' + grid.lg);
    }
    else if (itemLayout) {
        classMap[param].push('flex', 'flex-' + itemLayout);
        if (flex.sm) classMap[param].push('sm:flex-' + flex.sm);

        if (deepGet(settings, ['grow', 'content'])) classMap[param].push('flex-grow-content');
        if (flex.wrap) classMap[param].push('flex-wrap');
    }

    if (!inlineBlock && itemLayout) {
        if (flex.gap) classMap[param].push('gap-' + flex.gap);

        if (flex.content) classMap[param].push('content-' + flex.content);
        if (flex.justify) classMap[param].push('justify-' + flex.justify);
        if (flex.items) classMap[param].push('items-' + flex.items);
    }

    const span = deepGet(settings, ['span']);
    if (span) {
        if (span.default) classMap[param].push('col-span-' + span.default);
        if (span.sm) classMap[param].push('sm:col-span-' + span.sm);
        if (span.md) classMap[param].push('md:col-span-' + span.md);
        if (span.lg) classMap[param].push('lg:col-span-' + span.lg);
    }

    // spaces props
    for (const spaceType of ['padding', 'margin']) {
        if (settings[spaceType]) {
            for (let [key, value] of Object.entries(settings[spaceType])) {
                if (value) classMap[param].push(spaceType[0] + key[0] + '-' + value);
            }
        }
    }

    // border props
    const border = deepGet(settings, ['border'], {});
    const borderRadius = border.radius;
    if (borderRadius) {
        classMap[param].push('rounded-' + borderRadius);
        classMap[param].push('overflow-hidden');

        classMap.bg.push('rounded-' + borderRadius);
    }

    if (border.shadow) {
        if (border.shadow === 'default') classMap[param].push('shadow');
        else classMap[param].push('shadow-' + border.shadow);
        classMap[param].push('dark:shadow-white');
    }

    const borderWidth = border.width;
    if (borderWidth && borderWidth !== 'none') {
        classMap[param].push('border-' + borderWidth);
        classMap[param].push('border-' + (border.color || 'transparent'));
        classMap[param].push('border-' + (border.style || 'solid'));
    }
    else if (borderWidth === 'none') {
        classMap[param].push('border-none');
    }
    else if (borderWidth !== 'none') {
        if (border.color) classMap[param].push('border-' + border.color);
        if (border.style) classMap[param].push('border-' + border.style);
    }

    // text props
    const text = deepGet(settings, ['text'], {});
    for (const textProp of ['align', 'color', 'size']) {
        const textValue = text[textProp];
        if (textValue) classMap[param].push('text-' + textValue);
    }

    if (text.weight) classMap[param].push('font-' + text.weight);
    if (text.effects && text.effects.length) classMap[param].push(...text.effects);

    addContainerClasses(classMap[param], deepGet(settings, ['width', 'container']));

    // custom classes
    const classes = deepGet(settings, ['classes'], {});

    if (classes.prose) classMap[param].push(...['prose', 'lg:prose-lg', 'prose-img:rounded']);
    if (classes.hidden) classMap[param].push('hidden');

    const resp = deepGet(settings, ['resp'], {});
    if (resp['hide']) classMap[param].push(resp['hide'] + ':hidden');
    if (resp['show']) {
        if (inlineBlock) classMap[param].push(resp['show'] + ':inline-block');
        else classMap[param].push(resp['show'] + ':block');
    }

    // if (is(classes['custom'])) classMap[param].push(...classes['custom']);
    const customClasses = deepGet(classes, ['custom'], []);
    if (inArray('active', customClasses) && is(classes['active'])) {
        classMap[param].push(...classes['active']);
    } else {
        if (customClasses.length) classMap[param].push(...classes['custom']);
        if (is(classes.hover)) classMap[param].push(...classes['hover'].map(c => 'hover:' + c));
    }
}

const applyBgParams = (settings, classMap, styleMap, param) => {
    const bg = deepGet(settings, ['bg'], {});

    if (bg.color) {
        const bgColorPlace = bg.place || param;
        if (!classMap[bgColorPlace]) console.info('Error value of bgColorPlace:', bgColorPlace)
        else classMap[bgColorPlace].push('bg-' + bg.color);

        if (bg.opacity) classMap[bgColorPlace].push('bg-opacity-' + bg.opacity);
    }

    if (bg.url) {
        classMap.bg.push('bg-cover bg-center bg-no-repeat');
        styleMap.bg['backgroundImage'] = 'url("' + bg.url + '")';
    }
}

const setDimensions = (settings, styleMap) => {
    if (!settings) return;

    for (let [wKey, wVal] of Object.entries(widthMap)) {
        let amount = deepGet(settings, wVal);
        if (amount) {
            amount = amount.replace(/\s+/g, ''); // remove spaces
            const regexp = /^\d+\s*(px|em|rem|%|vw|vh|cm|mm)$/;

            if (amount === 'auto' || regexp.test(amount)) {
                styleMap.item[wKey] = amount;
            } else {
                amount = amount.replace(/\D/g, '');
                if (amount !== '') {
                    styleMap.item[wKey] = amount + 'px';
                }
            }
        }
    }
}

export const styleComposer = (item, classMap, styleMap) => {
    for (const field of ['style', 'wrap']) {
        const settings = deepGet(item, [field], {});

        if (!is(settings)) continue;

        const param = (field === 'wrap') ? 'wrap' : 'item';
        addClasses(settings, classMap, param);

        applyBgParams(settings, classMap, styleMap, param);
    }

    setDimensions(item.style, styleMap);
    for (const field of ['flex-row', 'flex-row-reverse', 'grid', 'inline-block']) {
        if (inArray(field, classMap.item)) {
            styleMap['horizontal'] = true;
            break;
        }
    }

    return {classMap, styleMap}
}


export const getCustomClasses = (style) => {
    return [
        ...(style.custom || []),
        ...(style.hover || []).map(c => 'hover:' + c)
    ];
}

export const getBaseClasses = (style, classes) => {
    // const activeClasses = style.active || [];
    const normalClasses = [
        ...(style.custom || []),
        ...(style.hover || []).map(c => 'hover:' + c)
    ];

    const activeSet = new Set(normalClasses);
    return classes.filter(c => !activeSet.has(c)); //.concat(activeClasses);
}