import React from 'react';
import {Select} from "antd";
const {Option} = Select;

export const LocaledOptions = ({t, section = '', fieldName = '', items = []}) => {
    let optionsList = []
    for (const item of items) {
        optionsList.push(
            <Option value={item} key={`select-${fieldName}-${item}`}>
                {t(`${section}.form.${fieldName}.value.${item}`)}
            </Option>
        )
    }
    return optionsList;
};

