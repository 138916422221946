import React, {useContext, useEffect, useState} from 'react'
import classes from './ContainerList.module.scss'
import {useDispatch} from "react-redux";
import FlowContext from "../../../../FlowContext";
import {MenuField} from "../../../../../../components/Form/Field/FormFields";
import {useTranslation} from "react-i18next";
import {FormItemWrapper} from "../../../../../../components/Form/FormItemWrapper";
import {changeGraphStatus} from "../../../../../../redux/reducers/GraphReducer";
import {Form} from "antd";


const EventModeSelect = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // consts
    const locale_section = 'event';
    const field_name = 'start_mode';

    const [form] = Form.useForm();
    const {chosenNode, onUpdate} = useContext(FlowContext);
    //const [formValues, setFormValues] = useState([]);

    const localId = chosenNode.localId;
    const field_value = chosenNode[field_name] ?? 'clone';
    // console.log('chosenNode start_mode: ', field_value, chosenNode.localId)

    useEffect(() => {
        const start_mode = chosenNode[field_name] ?? 'clone';
        form.setFieldsValue({start_mode})

        //eslint-disable-next-line
    }, [chosenNode])

    const formOnChange = () => {
        let values = form.getFieldsValue();
        const start_mode = values.start_mode;
        onUpdate({localId: chosenNode.localId, start_mode: values.start_mode});
        if (start_mode !== chosenNode.start_mode) {
            dispatch(changeGraphStatus({edited: true, redrawn: true}));
        }
    }

    return <Form
        form={form}
        name={`event_form_${localId}`}
        className={classes.extra_pre_fields}
        onValuesChange={formOnChange}
        layout="vertical"
    >
        <FormItemWrapper
            key={`${localId}_${field_name}`}
            desc={t(`${locale_section}.form.${field_name}.desc.${field_value}`)}
        >
            <MenuField
                t={t}
                values={[
                    'reopen',
                    'engage',
                    'clone',
                    'restrict',
                    'restart',
                    'occupy',
                ]}
                name={field_name}
                label={t(`${locale_section}.form.${field_name}.label`)}
                section={locale_section}
                container="effects-list-wrapper"
                placeholder=""
            />
        </FormItemWrapper>
    </Form>
}

export default EventModeSelect