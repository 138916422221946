import React from "react";
import classes from "./ProgressBar.module.scss"

export const ProgressBar = (
    {
        label = '',
        current = 0,
        max = 100,
        details_as_label = false,
        danger_on_low = false,
        lg = false,
        className = '',
    }
) => {
    const percents = (current / max) * 100;
    const loadWidth = 100 / percents * 100;

    if (details_as_label) label = current + ' / ' + max;
    let loadClassName = classes.load;
    if (danger_on_low && percents < 15) loadClassName += ' ' + classes.danger;

    return (
        <div className={`${classes.progress} ${className} ${lg ? classes.lg : ''} user-select-none`}>
            <div className={`${classes.text} ${classes.label}`}>{label}</div>
            {details_as_label ? null : <div className={`${classes.text} ${classes.details}`}>{current} / {max}</div>}
            <div className={`progress-bar-percents ${loadClassName}`}>
                <div className={`progress-bar-load-width ${classes.inner}`}>
                    <div className={`${classes.text} ${classes.label} ${classes.inner_text} float-left`}>
                        {label}
                    </div>
                    {details_as_label ? null :
                        <div className={`${classes.text} ${classes.details} ${classes.inner_text} float-right`}>
                            {current} / {max}
                        </div>}
                </div>
            </div>

            <style>
                {
                    '.progress-bar-percents {width: ' + percents + '%; }' +
                    '.progress-bar-load-width {width: ' + loadWidth + '%; }'
                }
            </style>
        </div>
    )
}



