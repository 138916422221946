const screens = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
]

const spaceValues = {
    '0': '0',
    '1': '4',
    '2': '8',
    '3': '12',
    '4': '16',
    '5': '20',
    '6': '24',
    '8': '32',
    '10': '40',
    '16': '64',
    '20': '80',
    '32': '128',
    '64': '256',
    'auto': 'Auto',
};

const gapValues = {
    '0': '0px',
    '1': '4px',
    '2': '8px',
    '3': '12px',
    '4': '16px',
    '5': '20px',
    '6': '24px',
    '8': '32px',
    '10': '40px',
    '16': '64px',
    '20': '80px',
    '32': '128px',
};

const borderSizeValues = {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '3': '3px',
    '4': '4px',
    // '8': '8px',
};

const positionValues = [
    't',
    'b',
    'l',
    'r',
    'd',
]

const cornerValues = [
    'tl',
    'tr',
    'bl',
    'br',
]

const borderStyleValues = [
    'none',
    'solid',
    'dashed',
    'dotted',
]

const radiusValues = [
    'none',
    'full',
    'xs',
    'sm',
    'md',
    'lg',
    'lp',
    'xl',
    '2xl',
    '3xl',
];

const shadowValues = [
    'default',
    'sm',
    'md',
    'lg',
    'xl',
    'none',
    'inner',
];

const bgPlaces = [
    'bg',
    'item',
];

const bgOpacityValues = {
    '0': '0',
    '10': '10%',
    '20': '20%',
    '30': '30%',
    '40': '40%',
    '50': '50%',
    '60': '60%',
    '70': '70%',
    '80': '80%',
    '90': '90%',
    '100': '100%',
}

const textSizes = {
    '2xs': '8px - 2XS',
    'xs': '12px - XS',
    'sm': '14px - SM',
    'md': '16px - MD',
    'lg': '18px - LG',

    'h4': '20px - H4',
    'h3': '24px - H3',
    'h2': '28px - H2',
    'h1': '32px - H1',

    'xl': '26px - XL',
    '2xl': '30px - 2XL',
    '3xl': '36px - 3XL',
    '4xl': '40px - 4XL',

    // Old version:

    // 'h4': '24px - H4',
    // 'h3': '27px - H3',
    // 'h2': '32px - H2',
    // 'h1': '42px - H1',
    //
    // '2xl': '24px - 2XL',
    // '3xl': '30px - 3XL',
    // '4xl': '36px - 4XL',

    '5xl': '48px - 5XL - Icon XS',
    '6xl': '64px - 6XL - Icon SM',
    '7xl': '72px - 7XL - Icon MD',
    '8xl': '96px - 8XL - Icon LG',
    '9xl': '128px - 9XL - Icon XL',
};

const textWeightValues = [
    'light',
    'normal',
    'medium',
    'semibold',
    'bold',
]

const textEffectValues = [
    'italic',
    'underline',
    'line-through',
    'no-underline',
    'not-italic',
]

const justifyValues = ['start', 'center', 'end', 'stretch', 'between', 'evenly', 'around'];

const gridRowValues = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '10': '10',
    '12': '12',
}

const widthValues = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
};

export const defaultBlockStyle = {
    margin: {
        top: null,
        bottom: null,
        left: null,
        right: null,
    },
    padding: {
        top: null,
        bottom: null,
        left: null,
        right: null,
    },
    bg: {
        url: null,
        place: null,
        color: null,
        opacity: null,
    },
    border: {
        width: null,
        radius: null,
        shadow: null,
        size: null,
        color: null,
        style: null,
        position: [],
        corner: [],
    },
    width: {
        container: null,
        amount: '',
        min: '',
        max: '',
    },
    height: {
        amount: '',
    },
    text: {
        color: null,
        align: null,
        size: null,
        weight: null,
        effects: [],
    },
    flex: {
        direction: null,
        sm: null,
        items: null,
        justify: null,
        content: null,
        gap: null,
        wrap: false,
    },
    grow: {
        content: false,
        self: false,
    },
    grid: {
        default: null,
        sm: null,
        md: null,
        lg: null,
    },
    span: {
        default: null,
        sm: null,
        md: null,
        lg: null,
    },
    resp: {
        show: null,
        hide: null,
    },
    classes: {
        active: [],
        hover: [],
        custom: [],
        // inline: false,
        hidden: false,
        prose: false,
    },
};


export const defaultBlockLogic = {
    timetable: {
        enable: false,
        local: false,
        schedule: [{
            days: [],
            from: {
                hour: null,
                min: null,
            },
            to: {
                hour: null,
                min: null,
            }
        }],
    },

    show: {
        enable: false,
        expression: '',
        list: [],
        role: [],
    },
    hide: {
        enable: false,
        expression: '',
        list: [],
        role: [],
    },
    classes: {
        enable: false,
        expression: '',
        true: '',
        false: '',
    },
    wrap: {
        enable: false,
        expression: '',
        true: '',
        false: '',
    },
}

export const blockLogicFields = {
    fields: [
        [{
            name: 'access.title',
            type: 'heading',
        }],
        [{
            name: 'timetable.enable',
            type: 'switcher',
            // description: '',
        }],
        [{
            name: 'timetable.schedule',
            type: 'schedule',
            label: '',
            description: '',
            // section: 'block.logic',
            depends_on: {'timetable.enable': true}
        }],
        [{
            name: 'timetable.local',
            type: 'switcher',
            // description: '',
            depends_on: {'timetable.enable': true}
        }],

        [{
            name: 'show.enable',
            type: 'switcher',
            // description: '',
        }],
        [{
            name: 'show.role',
            type: 'tags',
            localized: true,
            values: ['guest', 'user', 'admin'],
            depends_on: {'show.enable': true}
        }],
        [{
            name: 'show.list',
            type: 'tags',
            data: 'field',
            features: ['creating'],
            data_filters: {system_field_type: 'list', data_type_admin: 'int'},
            data_value_field: 'name',
            depends_on: {'show.enable': true}
        }],
        [{
            name: 'show.expression',
            type: 'textarea',
            placeholder: '{var:status} > 0 and {user:id}',
            depends_on: {'show.enable': true}
        }],

        [{
            name: 'hide.enable',
            type: 'switcher',
            // description: '',
        }],
        [{
            name: 'hide.role',
            type: 'tags',
            localized: true,
            values: ['guest', 'user', 'admin'],
            depends_on: {'hide.enable': true}
        }],
        [{
            name: 'hide.list',
            type: 'tags',
            data: 'field',
            features: ['creating'],
            data_filters: {system_field_type: 'list', data_type_admin: 'int'},
            data_value_field: 'name',
            depends_on: {'hide.enable': true}
        }],
        [{
            name: 'hide.expression',
            type: 'textarea',
            placeholder: '{var:status} > 0 and {user:id}',
            depends_on: {'hide.enable': true}
        }],


        [{
            name: 'classes.title',
            type: 'heading',
        }],

        [{
            name: 'classes.enable',
            type: 'switcher',
            // description: '',
        }],
        [{
            name: 'classes.expression',
            type: 'textarea',
            placeholder: '{var:status} > 0 and {user:id}',
            depends_on: {'classes.enable': true},
        }],
        [
            {
                name: 'classes.true',
                type: 'textarea',
                placeholder: 'active bg-gray-100',
                depends_on: {'classes.enable': true}
            },
            {
                name: 'classes.false',
                type: 'textarea',
                placeholder: 'disabled bg-gray-200',
                depends_on: {'classes.enable': true}
            },
        ],

        [{
            name: 'wrap.enable',
            type: 'switcher',
            // description: '',
        }],
        [{
            name: 'wrap.expression',
            type: 'textarea',
            placeholder: '{var:status} > 0 and {user:id}',
            depends_on: {'wrap.enable': true},
        }],
        [
            {
                name: 'wrap.true',
                type: 'textarea',
                placeholder: 'active bg-gray-100',
                depends_on: {'wrap.enable': true}
            },
            {
                name: 'wrap.false',
                type: 'textarea',
                placeholder: 'disabled bg-gray-200',
                depends_on: {'wrap.enable': true}
            },
        ],
    ]
}


const blockFieldsMap = {
    margin: [
        [{
            name: 'margin.title',
            type: 'heading',
            // description: '',
        }],
        [
            {
                name: 'margin.left',
                type: 'menu',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'margin.top',
                type: 'menu',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'margin.bottom',
                type: 'menu',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'margin.right',
                type: 'menu',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
        ],
    ],
    padding: [
        [{
            name: 'padding.title',
            type: 'heading',
            // description: '',
        }],
        [
            {
                name: 'padding.left',
                type: 'menu',
                // label: '',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'padding.top',
                type: 'menu',
                // label: '',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'padding.bottom',
                type: 'menu',
                // label: '',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
            {
                name: 'padding.right',
                type: 'menu',
                // label: '',
                placeholder: '0',
                description: '',
                localized: false,
                values: spaceValues,
            },
        ],
    ],
    width: [
        [{
            name: 'width.title',
            type: 'heading',
            // description: '',
        }],
        [
            {
                name: 'width.min',
                type: 'text',
                description: '',
            },
            {
                name: 'width.max',
                type: 'text',
                description: '',
            },
        ],
        [
            {
                name: 'width.amount',
                type: 'text',
                description: '',
            },
            {
                name: 'height.amount',
                type: 'text',
                description: '',
            },
        ],
    ],
    bg: {
        inner: [
            [{
                name: 'bg.title',
                type: 'heading',
            }],
            [
                {
                    name: 'bg.color',
                    type: 'tw_color',
                },
                {
                    name: 'bg.opacity',
                    type: 'menu',
                    localized: false,
                    values: bgOpacityValues,
                }
            ],
            [{
                name: 'bg.place',
                type: 'menu',
                localized: true,
                values: bgPlaces,
            }],
            [
                {
                    name: 'bg.url',
                    type: 'photo',
                    label: '',
                    description: '',
                    // aspect: 'slider',
                    width: 11,
                },
                {
                    name: 'bg.url',
                    type: 'textarea',
                    label: '',
                    description: '',
                    width: 13,
                },
            ],
        ],
        wrap: [
            [{
                name: 'bg.title',
                type: 'heading',
            }],
            [
                {
                    name: 'bg.color',
                    type: 'tw_color',
                    description: '',
                },
                {
                    name: 'bg.opacity',
                    type: 'menu',
                    localized: false,
                    values: bgOpacityValues,
                    description: '',
                }
            ],
            [
                {
                    name: 'bg.url',
                    type: 'photo',
                    label: '',
                    description: '',
                    // aspect: 'slider',
                    // width: 9,
                },
                {
                    name: 'bg.url',
                    type: 'textarea',
                    label: '',
                    description: '',
                    // width: 15,
                },
            ],
        ],
    },
    border: [
        [{
            name: 'border.title',
            type: 'heading',
        }],
        [
            {
                name: 'border.width',
                type: 'menu',
                description: '',
                localized: false,
                values: borderSizeValues,
            },
            {
                name: 'border.color',
                type: 'tw_color',
                description: '',
            }
        ],
        [
            {
                name: 'border.position',
                type: 'tags',
                description: '',
                localized: true,
                values: positionValues,
            },
            {
                name: 'border.style',
                type: 'menu',
                description: '',
                localized: true,
                values: borderStyleValues,
            },
        ],
        [
            {
                name: 'border.radius',
                type: 'menu',
                description: '',
                localized: true,
                values: radiusValues,
            },
            {
                name: 'border.shadow',
                type: 'menu',
                description: '',
                localized: true,
                values: shadowValues,
            },
        ],
        [
            {
                name: 'border.corner',
                type: 'tags',
                description: '',
                localized: true,
                values: cornerValues,
            },
        ],
    ],
    text: [
        [{
            name: 'text.title',
            type: 'heading',
            // description: '',
        }],
        [
            {
                name: 'text.color',
                type: 'tw_color',
                // description: '',
            },
            {
                name: 'text.align',
                type: 'menu',
                values: ['center', 'left', 'right'],
            },
        ],
        [
            {
                name: 'text.size',
                type: 'menu',
                // description: '',
                localized: false,
                values: textSizes,
            },
            {
                name: 'text.weight',
                type: 'menu',
                // description: '',
                localized: true,
                values: textWeightValues,
            }
        ],
        [{
            name: 'text.effects',
            type: 'tags',
            // description: '',
            localized: true,
            values: textEffectValues,
        }],
        [{
            name: 'classes.prose',
            type: 'switcher',
        }],
    ],
    flex: {
        title: [
            [{
                name: 'flex.title',
                type: 'heading',
            }],
        ],
        base: [
            [{
                name: 'flex.direction',
                type: 'menu',
                values: ['grid', 'row', 'col', 'inline', 'row-reverse', 'col-reverse'],
            },],
            [{
                name: 'flex.sm',
                type: 'menu',
                values: ['row', 'col', 'row-reverse', 'col-reverse'],
                depends_on: {'flex.direction': ['row', 'col', 'row-reverse', 'col-reverse']},
            }]
        ],
        width: [
            [{
                name: 'grid.width',
                type: 'heading',
            }],
            [
                {
                    name: 'span.default',
                    type: 'menu',
                    localized: false,
                    values: widthValues,
                },
                {
                    name: 'span.sm',
                    type: 'menu',
                    localized: false,
                    values: widthValues,
                },
                {
                    name: 'span.md',
                    type: 'menu',
                    localized: false,
                    values: widthValues,
                },
                {
                    name: 'span.lg',
                    type: 'menu',
                    localized: false,
                    values: widthValues,
                }
            ]
        ],
        child: [
            [
                {
                    name: 'flex.gap',
                    type: 'menu',
                    localized: false,
                    values: gapValues,
                    depends_on: {'flex.direction': ['col', 'row', 'grid', 'row-reverse', 'col-reverse']},
                },
                {
                    name: 'flex.items',
                    type: 'menu',
                    values: ['start', 'center', 'end', 'stretch'],
                    depends_on: {'flex.direction': ['col', 'row', 'grid', 'row-reverse', 'col-reverse']},
                    // default: 'center',
                },
            ],
            [
                {
                    name: 'flex.justify',
                    type: 'menu',
                    values: justifyValues,
                    depends_on: {'flex.direction': ['col', 'row', 'row-reverse', 'col-reverse']},
                    // default: 'center',
                },
                {
                    name: 'flex.content',
                    type: 'menu',
                    values: justifyValues,
                    depends_on: {'flex.direction': ['col', 'row', 'row-reverse', 'col-reverse']},
                    // default: 'center',
                },
            ],
            [{
                name: 'grow.content',
                type: 'switcher',
                // description: '',
                depends_on: {'flex.direction': ['col', 'row', 'row-reverse', 'col-reverse']},
            }],
            [{
                name: 'flex.wrap',
                type: 'switcher',
                // description: '',
                depends_on: {'flex.direction': ['row', 'col', 'row-reverse', 'col-reverse']},
            }],
            [{
                name: 'grid.cols',
                type: 'heading',
                depends_on: {'flex.direction': ['grid']},
            }],
            [
                {
                    name: 'grid.default',
                    type: 'menu',
                    localized: false,
                    values: gridRowValues,
                    depends_on: {'flex.direction': ['grid']},
                },
                {
                    name: 'grid.sm',
                    type: 'menu',
                    localized: false,
                    values: gridRowValues,
                    depends_on: {'flex.direction': ['grid']},
                },
                {
                    name: 'grid.md',
                    type: 'menu',
                    localized: false,
                    values: gridRowValues,
                    depends_on: {'flex.direction': ['grid']},
                },
                {
                    name: 'grid.lg',
                    type: 'menu',
                    localized: false,
                    values: gridRowValues,
                    depends_on: {'flex.direction': ['grid']},
                },
            ],
        ]
    },
    classes: [
        [{
            name: 'classes.hidden',
            type: 'switcher',
        }],
        [
            {
                name: 'resp.show',
                type: 'menu',
                values: screens,
            },
            {
                name: 'resp.hide',
                type: 'menu',
                values: screens,
            }
        ],
        [{
            name: 'width.container',
            type: 'menu',
            localized: true,
            values: screens,
        }],
    ],
    custom: [
        [{
            name: 'classes.custom',
            type: 'tags',
            placeholder: 'custom-classes ...',
            features: ['adding'],
            values: [
                'cursor-pointer',
                'container-xs',
                'container-md',
                'prose-invert',
                'uppercase',
                'underline',
                'w-full',
                'w-10',
                'h-10',
                'mt-auto',
                'mb-auto',
                'ml-auto',
                'mr-auto',
                'grow',
                'backdrop-blur-sm',
            ],
        }],
    ],
    hover: [
        [{
            name: 'classes.hover',
            type: 'tags',
            placeholder: 'custom-hover ...',
            features: ['adding'],
        }],
    ],
    active: [
        [{
            name: 'classes.active',
            type: 'tags',
            placeholder: 'custom-active ...',
            features: ['adding'],
        }],
    ],

    visibility: [
        [{
            name: 'visibility',
            type: 'heading',
        }],
    ],
    additional: [
        [{
            name: 'additional',
            type: 'heading',
        }],
    ],
    extra: [
        [{
            name: 'extra',
            type: 'heading',
        }],
    ]
}

export const blockWrapFields = {
    fields: [
        ...blockFieldsMap.margin,
        ...blockFieldsMap.padding,

        ...blockFieldsMap.flex.width,

        ...blockFieldsMap.bg.wrap,
        ...blockFieldsMap.border,

        ...blockFieldsMap.flex.title,
        ...blockFieldsMap.flex.base,
        ...blockFieldsMap.flex.child,

        ...blockFieldsMap.extra,
        ...blockFieldsMap.classes,
        ...blockFieldsMap.custom,
    ]
};

export const blockStyleFields = {
    fields: [
        ...blockFieldsMap.padding,
        ...blockFieldsMap.width,
        ...blockFieldsMap.text,
        ...blockFieldsMap.bg.inner,
        ...blockFieldsMap.border,

        ...blockFieldsMap.flex.title,
        ...blockFieldsMap.flex.base,
        ...blockFieldsMap.flex.child,

        ...blockFieldsMap.visibility,
        ...blockFieldsMap.classes,

        ...blockFieldsMap.additional,
        ...blockFieldsMap.custom,
        ...blockFieldsMap.hover,
        ...blockFieldsMap.active,
    ]
};

export const containerStyleFields = {
    fields: [
        ...blockFieldsMap.padding,
        ...blockFieldsMap.width,

        ...blockFieldsMap.text,
        ...blockFieldsMap.bg.inner,
        ...blockFieldsMap.border,

        ...blockFieldsMap.flex.title,
        ...blockFieldsMap.flex.base,
        ...blockFieldsMap.flex.child,

        ...blockFieldsMap.visibility,
        ...blockFieldsMap.classes,

        ...blockFieldsMap.additional,
        ...blockFieldsMap.custom,
        ...blockFieldsMap.hover,
    ]
};

export const containerWrapFields = {
    fields: [
        ...blockWrapFields.fields
    ]
};