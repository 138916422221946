import React, {useEffect, useState} from 'react';
import {Button, Modal} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import classes from "./SimpleModal.module.scss";
import {useTranslation} from "react-i18next";

export const SimpleModal = (
    {
        children,
        title = '',
        // opened = false,
        isVisible= false,
        setVisible = null,
        loadingOnOk= false,
        onOk = null,
        onCancel = null,
        okButtonLabel = null,
        actionLabel = 'apply',
        disabled = false,
        width = 550,
        leftFooterText = null,
        maskClosable = true,
        loading= false,
        showFooter = true,
        className = '',
        hideCancelXs = false,
        getContainer = null,
    }
) => {

    const {t} = useTranslation();
    // const [isVisible, setVisible] = useState(opened)
    const [loadingState, setLoading] = useState(false)

    const hideModal = () => {
        if (setVisible) setVisible(false);
        setLoading(false);
    };

    const handleOk = () => {
        if (onOk) onOk();
        if (loadingOnOk) setLoading(true);
        else hideModal();
    };

    const handleCancel = () => {
        hideModal();
        if (onCancel) onCancel()
    };

    useEffect(() => {
        if (!isVisible) setLoading(false);
    }, [isVisible]);

    return <Modal
        width={width}
        centered={true}
        className={`${classes.modal_wrapper} ${width <= 900 ? classes.small : ''}${className ? ' ' + className : ''}`}
        title={title}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={maskClosable}
        getContainer={getContainer}
        footer={showFooter ? [
            leftFooterText ? <div key="modal-left" className="float-left">{leftFooterText}</div> : null,
            <Button
                key="modal-back"
                type="text"
                onClick={handleCancel}
                className={hideCancelXs ? 'inverted hidden-xs' : "inverted"}
            >{t('common.action.cancel')}</Button>,
            <Button
                key="modal-submit"
                type="primary"
                onClick={handleOk}
                icon={<CheckOutlined/>}
                loading={loadingState || loading}
                disabled={disabled}
            >{okButtonLabel === null ? t('common.action.' + actionLabel) : okButtonLabel}</Button>
        ] : null}
    >{children}</Modal>
}

