import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {addDomainToList} from "../reducers/DomainReducer";
import {fillThemeShortList} from "../reducers/ThemeReducer";
import {fillLayoutShortList} from "../reducers/LayoutReducer";
import {
    setSiteItem,
    clearSiteItem,
    addSiteToList,
    fillSiteList,
    patchSiteInList,
    removeSiteFromList,
    setSiteStartPage,
    blockSiteSaga,
} from "../reducers/SiteReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.site;

function* getSiteList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillSiteList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSiteItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');

        if (response.data.ok) yield put(setSiteItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* installSite(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/install', 'post', arg.data);

        if (response.data.ok) {
            const result = response.data.result || {}
            if (result.domain) yield put(addDomainToList(result.domain))

            yield put(setSiteStartPage(result.page || {}))
            yield put(addSiteToList(result.site || {}))

            // reset themes and layouts list (will be received by page open)
            yield put(fillThemeShortList([]))
            yield put(fillLayoutShortList([]))

        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createSite(arg) {
    try {
        yield put(blockSiteSaga(true)) // lock list refresh
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            if (arg.folder_mode) yield put(clearSiteItem()) // hide site modal
            else yield put(setSiteItem(response.data.result))

            yield put(addSiteToList(response.data.result))
        } else apiFalseNotice(response)

        // unlock list refresh
        yield put(blockSiteSaga(false))
    } catch (error) {
        yield put(blockSiteSaga(false))
        apiErrorNotice(error)
        return null
    }
}

function* updateSite(arg) {
    try {
        yield put(blockSiteSaga(true)) // lock list refresh
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            if (arg.folder_mode) yield put(clearSiteItem()) // hide site modal
            else yield put(setSiteItem(response.data.result))

            yield put(patchSiteInList(response.data.result))
            if (arg.show_notice) notice.success(l("site.notice.saved"))
        } else apiFalseNotice(response)

        // unlock list refresh
        yield put(blockSiteSaga(false))
    } catch (error) {
        yield put(blockSiteSaga(false))
        apiErrorNotice(error)
        return null
    }
}

function* restoreSite(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeSiteFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteSite(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeSiteFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* siteSaga() {
    yield takeEvery('getSiteList', getSiteList);
    yield takeEvery('getSiteItem', getSiteItem);
    yield takeEvery('installSite', installSite);
    yield takeEvery('createSite', createSite);
    yield takeEvery('updateSite', updateSite);
    yield takeEvery('deleteSite', deleteSite);
    yield takeEvery('restoreSite', restoreSite);
}

