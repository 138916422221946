import React from 'react'
import {is} from "../../../../../../library/functions";
import {useTranslation} from "react-i18next";

export const BlockCommonForm = (spec, params, classes, styles) => {
    const {t} = useTranslation();
    if (!spec) return null;

    let props = {};
    if (is(styles)) props['style'] = styles;

    return <div className={classes.join('')} {...props}>
        <div className="bg-gray border-radius-md padding-top-sm padding-bottom-sm padding-left-pm padding-right-pm box-shadow">
            {t('container.example.form.preview')}
        </div>
    </div>
}