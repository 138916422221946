import {defaultTreeData} from "../../../../../../components/Tree/Tree";

export const coreNav = {
    'menu/horizontal': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/nav/menu/custom",
                            spec: {
                                menu: {
                                    nextKey: 1,
                                    tree: []
                                },
                                orientation: "horizontal",
                                xs_mode: true,
                                icon: {
                                    name: "bars",
                                    set: "regular"
                                },
                                classes: {
                                    icon: [
                                        // "mr-3",
                                        "text-gray-700",
                                        'w-6',
                                        'flex',
                                        'flex-row',
                                        'items-center',
                                        'justify-center',
                                    ],
                                    photo: [
                                        "h-10",
                                        "rounded",
                                        // "mr-3"
                                    ],
                                    children: [
                                        'z-20',
                                        'absolute',
                                        'transition-all',
                                        'duration-300',
                                        'bg-white',
                                        'dark:bg-gray-800',
                                        'shadow-lg',
                                        'dark:shadow-white',
                                        'border-1',
                                        'border-gray-100',
                                        'dark:border-gray-600',
                                        'min-w-full',
                                        'rounded-lg',
                                        'p-2'
                                    ],
                                    custom: [
                                        "overflow-hidden",
                                        "select-none",
                                        "cursor-pointer",
                                        "dark:text-gray-200",
                                        "dark:hover:bg-gray-700",
                                        "dark:hover:text-gray-100"
                                    ],
                                    hover: [
                                        "bg-gray-100",
                                        "text-gray-900"
                                    ],
                                    active: [
                                        "select-none",
                                        "cursor-pointer",
                                        "overflow-hidden",
                                        "bg-primary-100",
                                        "hover:bg-primary-100",
                                        "dark:bg-gray-900",
                                        "dark:hover:bg-gray-900",
                                    ]
                                }
                            },
                            style: {
                                padding: {
                                    left: "5",
                                    top: "2",
                                    bottom: "2",
                                    right: "5"
                                },
                                classes: {
                                    custom: [
                                        "select-none",
                                        "cursor-pointer",
                                        "dark:text-gray-200",
                                        "dark:hover:bg-gray-700",
                                        "dark:hover:text-gray-100"
                                    ],
                                    hover: [
                                        "bg-gray-100",
                                        "text-gray-900"
                                    ],
                                    active: [
                                        "select-none",
                                        "cursor-pointer",
                                        "bg-gray-100",
                                        "hover:bg-gray-100",
                                        "dark:bg-gray-900",
                                        "dark:hover:bg-gray-900",
                                        "duration-300",
                                        "transition-all"
                                    ]
                                },
                                border: {
                                    radius: "lg"
                                },
                                flex: {
                                    gap: "3",
                                    direction: "row",
                                    items: "center",
                                    justify: "stretch",
                                }
                            },
                            wrap: {}
                        }
                    ],
                    style: {},
                    wrap: {}
                }
            ],
            style: {
                padding: {
                    left: "6",
                    top: "4",
                    bottom: "4",
                    right: "6"
                },
                width: {
                    container: "lg"
                }
            },
            wrap: {}
        }]
    },
    'menu/vertical': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/nav/menu/custom',
                            spec: {
                                menu: defaultTreeData,
                                orientation: 'vertical',
                                // active_path: '{var:request.pathStr}',
                                xs_mode: true,
                                icon: {
                                    name: "bars",
                                    set: "regular"
                                },
                                classes: {
                                    icon: [
                                        "text-gray-700",
                                        "w-6",
                                        "flex",
                                        "flex-row",
                                        "justify-center",
                                    ],
                                    photo: [
                                        "h-10",
                                        // "w-10",
                                        "rounded",
                                    ]
                                }
                            },
                            style: {
                                padding: {
                                    left: "4",
                                    top: "2",
                                    bottom: "2",
                                    right: "3"
                                },
                                classes: {
                                    custom: [
                                        "select-none",
                                        "cursor-pointer",
                                        "dark:text-gray-200",
                                        "dark:hover:bg-gray-700",
                                        "dark:hover:text-gray-100",
                                    ],
                                    hover: [
                                        "bg-gray-100",
                                        "text-gray-900"
                                    ],
                                    active: [
                                        "select-none",
                                        "cursor-pointer",
                                        "bg-primary-100",
                                        "hover:bg-primary-100",
                                        "dark:bg-gray-900",
                                        "dark:hover:bg-gray-900",
                                    ]
                                },
                                border: {
                                    radius: "lg"
                                },
                                flex: {
                                    gap: "3",
                                    direction: "row",
                                    items: "start",
                                    justify: "stretch",
                                }
                            },
                        },
                    ],
                }
            ],
            style: {
                padding: {
                    top: "6",
                    bottom: "6",
                    left: "6",
                    right: "6"
                },
                width: {
                    container: "xs"
                }
            }
        }]
    },
    'menu/user': {
        content: [{
            type: "con",
            items: [
                {
                    type: "group",
                    items: [
                        {
                            type: "core/nav/menu/custom",
                            spec: {
                                menu: {
                                    nextKey: 5,
                                    tree: [
                                        {
                                            title: "{user:first_name|{locale:container.example.menu.profile}}",
                                            photo_url_var: "{user:photo_url|}",
                                            key: 1,
                                            children: [
                                                {
                                                    title: "{locale:container.example.menu.opened}",
                                                    url: '',
                                                    access: "user",
                                                    key: 3,
                                                    icon: "graduation-cap"
                                                },
                                                {
                                                    title: "{locale:container.example.menu.logout}",
                                                    url: '/',
                                                    logout: true,
                                                    icon: "arrow-right-from-bracket",
                                                    key: 4
                                                }
                                            ],
                                            photo_url: "https://storage.yandexcloud.net/multy/Universe/Sections/user-off.jpg",
                                            access: "user",
                                            xs_short: true
                                        },
                                        {
                                            title: "{locale:container.example.menu.login}",
                                            url: "/login",
                                            access: "guest",
                                            icon: "arrow-right-to-bracket",
                                            key: 2
                                        }
                                    ]
                                },
                                orientation: "horizontal",
                                xs_mode: false,
                                icon: {
                                    name: "bars",
                                    set: "regular"
                                },
                                classes: {
                                    icon: [
                                        "w-6",
                                        "flex",
                                        "flex-row",
                                        "justify-center",
                                        "text-gray-700",
                                        'dark:text-gray-400'
                                    ],
                                    photo: [
                                        "h-10",
                                        "w-10",
                                        "rounded-full"
                                    ],
                                    children: [],
                                    custom: [
                                        "overflow-hidden",
                                        "select-none",
                                        "cursor-pointer",
                                        "dark:text-gray-200",
                                        "dark:hover:bg-gray-700",
                                        "dark:hover:text-gray-100"
                                    ],
                                    hover: [
                                        "bg-gray-100",
                                        "text-gray-900"
                                    ],
                                    active: [
                                        "select-none",
                                        "cursor-pointer",
                                        "overflow-hidden",
                                        "bg-primary-100",
                                        "hover:bg-primary-100",
                                        "dark:bg-gray-900",
                                        "dark:hover:bg-gray-900",
                                    ]
                                }
                            },
                            style: {
                                padding: {
                                    left: "5",
                                    top: "2",
                                    bottom: "2",
                                    right: "5"
                                },
                                classes: {
                                    custom: [
                                        "select-none",
                                        "cursor-pointer",
                                        "dark:text-gray-200",
                                        "dark:hover:bg-gray-700",
                                        "dark:hover:text-gray-100"
                                    ],
                                    hover: [
                                        "bg-gray-100",
                                        "text-gray-900"
                                    ],
                                    active: [
                                        "select-none",
                                        "cursor-pointer",
                                        "bg-gray-100",
                                        "hover:bg-gray-100",
                                        "dark:bg-gray-900",
                                        "dark:hover:bg-gray-900",
                                        "duration-300",
                                        "transition-all"
                                    ]
                                },
                                border: {
                                    radius: "lg"
                                },
                                flex: {
                                    gap: "3",
                                    direction: "row",
                                    items: "center",
                                    justify: "stretch",
                                }
                            },
                            wrap: {}
                        }
                    ],
                    style: {},
                    wrap: {}
                }
            ],
            style: {
                padding: {
                    left: "6",
                    top: "4",
                    bottom: "4",
                    right: "6"
                },
            },
            wrap: {}
        }]
    },
    'page/category': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/cards',
                            spec: {
                                parent_id: '{var:object.page.parent_id}',
                                style: 'nav',
                                cards_per_row: '3',
                                show_image: true,
                                get_desc: true,
                                desc_limit: '115',
                                order: 'custom',
                                check_access: true,
                                show_closed: true,
                                active: {
                                    label: '{locale:common.action.open}',
                                    icon: {
                                        name: 'eye',
                                        set: 'regular'
                                    }
                                },
                                disabled: {
                                    label: '{locale:container.example.pages.access.get}',
                                    icon: {
                                        name: 'lock',
                                        set: 'regular'
                                    }
                                }
                            },
                            style: {
                                text: {
                                    size: 'md'
                                }
                            },
                            wrap: {}
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    // left: '6',
                    // right: '6',
                    // top: '6',
                    // bottom: '10',
                }
            },
        }]
    },
    'tag/list': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/nav/tag/list',
                            spec: {
                                active_id: '{var:request.query.tag}',
                                order: '-id',
                                show_title: true,
                                show_photo: false,

                                get_desc: false,
                                desc_limit: '115',
                            },
                            style: {
                                text: {
                                    size: 'lg'
                                },
                                padding: {
                                    left: '3',
                                    top: '3',
                                    bottom: '3',
                                    right: '3'
                                },
                                border: {
                                    width: '1',
                                    color: 'secondary-200',
                                    position: [
                                        'b',
                                        'd'
                                    ]
                                },
                                classes: {
                                    custom: [
                                        'hover:text-gray-900',
                                        'hover:bg-primary-100',
                                        'hover:border-primary-100',
                                        'hover:rounded-lg',
                                        'active:rounded-lg',
                                        'active:text-white',
                                        'active:bg-primary-500',
                                        'active:border-primary-500',
                                    ]
                                }
                            }
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    top: '6',
                    bottom: '6',
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    'page/tree': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/page/tree',
                            spec: {
                                section_id: '{var:params.main_page_id}',
                            },
                            style: {
                                text: {
                                    size: 'md',
                                },
                                classes: {
                                    custom: [],
                                }
                            }
                        }
                    ]
                }
            ],
            style: {
                padding: {
                    // left: '6',
                    // right: '6',
                    // top: '6',
                    // bottom: '10',
                }
            },
        }]
    },
}