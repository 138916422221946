import React, {useEffect, useRef, useState} from 'react';
import {convertTextToHtml, toNum} from "../../../library/functions";
import {Input} from "antd";
import {backend_api, dotenv} from "../../../config/config";
import {uploadFilePreHandle} from "../../../library/forms";
import axios from "axios";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {useSelector} from "react-redux";

const {TextArea} = Input;

const eyeSlashIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path d="M10.94,6.08A6.93,6.93,0,0,1,12,6c3.18,0,6.17,2.29,7.91,6a15.23,15.23,0,0,1-.9,1.64,1,1,0,0,0-.16.55,1,1,0,0,0,1.86.5,15.77,15.77,0,0,0,1.21-2.3,1,1,0,0,0,0-.79C19.9,6.91,16.1,4,12,4a7.77,7.77,0,0,0-1.4.12,1,1,0,1,0,.34,2ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.39,6.8a14.62,14.62,0,0,0-3.31,4.8,1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20a9.26,9.26,0,0,0,5.05-1.54l3.24,3.25a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm6.36,9.19,2.45,2.45A1.81,1.81,0,0,1,12,14a2,2,0,0,1-2-2A1.81,1.81,0,0,1,10.07,11.48ZM12,18c-3.18,0-6.17-2.29-7.9-6A12.09,12.09,0,0,1,6.8,8.21L8.57,10A4,4,0,0,0,14,15.43L15.59,17A7.24,7.24,0,0,1,12,18Z"/></svg>'

// {onChange, form, id, value, fieldName = 'text', initialValue='', onExitEdit}

const sandbox_iframes_exclusions = [
    'youtube.com',
    'youtu.be',
    'vimeo.com',
    'player.vimeo.com',
    'dailymotion.com',
    'embed.music.apple.com',
    'open.spotify.com',
    'giphy.com',
    'dai.ly',
    'codepen.io',
    'kinescope.io',
    'rutube.ru',
    'vk.com',
]

const TinyMCE = (props) => {
    // const t = props.t; // useTranslation()
    let initialValue = props.initialValue || '';
    const {t, id, value, onChange, onBlur, extended = false, section = 'editor'} = props;

    const editorRef = useRef(null);
    const {admin, project} = useSelector(store => store);

    const setValue = (editor, val) => {
        // console.log('initialValue 1', !!val, !val.startsWith('<'), val)
        //
        // if (val && !val.startsWith('<')) {
        //     val = convertTextToHtml(val, 'div');
        // }
        //
        // console.log('initialValue 2', val)

        editorRef.current.setContent(val);  // set value once
    }

    const handleImageUpload = async (blobInfo, success, failure) => {
        let url = 'undefined';
        const file = blobInfo.blob();
        const project_id = toNum(project.item.id);
        const postForm = uploadFilePreHandle(admin, project_id, section, file, 'photo', null, props.imgFormat);

        try {
            const res = await axios.post(backend_api.file, postForm.data, postForm.config);
            const result = res.data;

            if (!result.ok) {
                apiFalseNotice(res);
            } else {
                url = result.result.url
                success(url)
            }
        } catch (error) {
            apiErrorNotice(error);
        }

        return new Promise((resolve, reject) => {
            resolve(url)
        });
    };

    const setValueToForm = (editor) => {
        if (onChange) props.onChange(editor.getContent());
    }

    const setupEditor = (editor) => {
        editor.on('change', () => {
            // console.log('onChange', editor.getContent());
            setValueToForm(editor);
        });

        editor.on('blur', () => {
            setValueToForm(editor);
            // console.log('onBlur', id, editor.getContent());
            if (onBlur) onBlur();
        });

        // setupCustomButtons
        editor.ui.registry.addButton('blur', {
            // span is better because it supports innerHTML
            icon: 'blur',
            tooltip: props.t('editor.blur'),
            onAction: (buttonApi) => {
                const selectedText = editor.selection.getContent({format: 'html'});
                const isWrapped = editor.selection.getNode().nodeName === 'SPAN' && editor.selection.getNode().classList.contains('blur');

                if (isWrapped) {
                    // Remove the <span> tag from the selected text
                    editor.dom.remove(editor.selection.getNode(), true);
                    // buttonApi.setActive(false);
                } else {
                    // Wrap the selected text with the <span> tag
                    const wrappedText = `<span class="blur">${selectedText}</span>`;
                    editor.selection.setContent(wrappedText);
                    // buttonApi.setActive(true);
                }
            },
            onSetup: (buttonApi) => {
                editor.formatter.register('blur', {inline: 'span', classes: 'blur'});
            },
        });

        // Define the custom icon also
        editor.ui.registry.addIcon('blur', eyeSlashIcon);
    }

    useEffect(() => {
        const initEditor = () => {
            let editorParams = {
                // selector: 'textarea.editor-message',
                plugins: 'autoresize autolink lists link image code media emoticons searchreplace codesample',

                // toolbar here ↓
                toolbar: 'undo redo | bold italic underline strikethrough blur | emoticons link ',

                forced_root_block: 'div', // false is deprecated. must be not empty string
                valid_elements: [
                    'div',
                    'p',
                    'br',
                    'b', 'strong',
                    'i', 'em',
                    'u', 'ins',
                    's', 'strike', 'del',
                    'pre',
                    'blockquote[class|expandable]',
                    'code[class]',
                    'a[href|target|title|class|rel]',
                    'span[class=blur|tg-spoiler]', 'blur', 'tg-spoiler', 'tg-emoji'
                ].join(','),
                allow_conditional_comments: false,
                link_target_list: false,

                // // br fixed
                // force_br_newlines: true,
                // // // force_p_newlines: false,  // param is deprecated.
                // // This option controls whether or not the editor will convert new lines to <br> tags.
                // convert_newlines_to_brs : false,
                // // When set to false, line breaks will not be removed from output
                // remove_linebreaks : false,
            }
            if (extended) editorParams = {
                // selector: 'textarea.editor-extended',
                plugins: 'autoresize autolink lists link image code media emoticons searchreplace code codesample fullscreen',

                // toolbar here ↓
                toolbar: 'undo redo | bold italic underline strikethrough | emoticons link image media | ' +
                    'bullist numlist outdent indent codesample | blocks | fullscreen searchreplace removeformat code',
                // toolbar here ↑

                sandbox_iframes: true,
                sandbox_iframes_exclusions: sandbox_iframes_exclusions,

                block_formats: t('editor.p') + '=p; ' +
                    t('editor.h2') + '=h2; ' +
                    t('editor.h3') + '=h3; ' +
                    t('editor.div') + '=div;' +
                    t('editor.blockquote') + '=blockquote;' +
                    t('editor.pre') + '=pre',
            }

            // const selector = extended ? 'editor-extended' : 'editor-message';
            const config = {
                // selector: '.' + selector,
                // target: fieldRef,
                selector: '#' + id,
                setup: setupEditor,

                ...editorParams,
                license_key: 'gpl',
                toolbar_mode: 'sliding',
                height: 70,
                autoresize_bottom_margin: 0,
                max_height: 700,
                inline: false,
                menubar: false,
                statusbar: false,
                contextmenu: 'link | bold italic underline strikethrough emoticons codeformat | copy paste pastetext | fullscreen removeformat code',
                formats: {
                    underline: {inline: 'u'},
                    strikethrough: {inline: 's'},
                    blur: {block: 'blur'},
                },
                link_title: false,
                convert_urls: false, // do not convert local urls
                extended_valid_elements: 'blur',
                entity_encoding: 'raw',
                paste_as_text: true,
                // contextmenu: false,
                placeholder: props.placeholder || t('common.type_here'),
                browser_spellcheck: true,
                language: dotenv.default_lang,
                content_css: ['/font/roboto.css', '/style/editor.css'],
                automatic_uploads: true,
                images_upload_handler: handleImageUpload,

                media_alt_source: true,
                media_live_embeds: false,

                // default value:
                // init_instance_callback: (editor) => {
                //     console.log('init_instance_callback', value)
                //     editorRef.current = editor;
                // }
            }

            // const all = window.tinymce.get()
            // console.log('Editor - All: ', all)

            // console.log('window.tinymce', window.tinymce)
            const existingEditor = window.tinymce.get(id)

            if (existingEditor) {
                console.log('Editor - Removed by Existed: ', existingEditor.id, existingEditor.initialized)
                try {
                    window.tinymce.remove('#' + existingEditor.id);
                } catch (e) {
                    console.warn('Error removing existed TinyMCE editor:', e);
                }
            }

            setTimeout(() => {
                console.log('Editor - Init:', id);
                try {
                    window.tinymce.init(config).then((editors) => {
                        // const currentEditor = window.tinymce.get(id);

                        const currentEditor = editors[0];
                        // console.log('currentEditor', currentEditor && currentEditor.id);

                        if (currentEditor) {
                            // console.info('Editor - Set content:', currentEditor.id, currentEditor.initialized);
                            editorRef.current = currentEditor;
                            // editorRef.current.setContent(initialValue);
                        }
                        // else {
                        //     console.log('Editor - Not initialized:', id);
                        // }
                    });

                } catch (e) {
                    console.warn('Error removing TinyMCE editor:', e);
                }
            }, 100)

            // window.tinymce.init(config);
            // editorRef.current = window.tinymce.get(id);
            // console.log('Editor - Init:', editorRef.current.id)
        };

        // Ensure TinyMCE script is loaded before initialization
        if (window.tinymce) {
            initEditor();
        } else {
            let it = 0;
            const intervalId = setInterval(() => {
                if (window.tinymce) {
                    clearInterval(intervalId);
                    initEditor();
                }

                if (it++ > 30) {
                    console.error('TinyMCE is not loaded within time limit.');
                    clearInterval(intervalId);
                }
            }, 100);
        }

        return () => {
            if (editorRef.current) {
                console.log('Editor - Removed by Return: ', editorRef.current.id)
                try {
                    window.tinymce.remove('#' + editorRef.current.id);
                    // console.log('Removed', '#' + editorRef.current.id)
                } catch (e) {
                    console.warn('Error removing TinyMCE editor:', e);
                }

            } else {
                console.log('Editor Removing ERROR - Not Initialized', id)
            }
        };
    }, []);

    useEffect(() => {
        if (editorRef.current) {
            // console.log('Set Value by initialValue', editorRef.current && editorRef.current.id)
            setValue(editorRef.current, initialValue);  // set value once
        }
    }, [initialValue])

    let onTextAreaChange = (e) => {
        const value = e.target.value;
        if (onChange) onChange(value);
    }

    return (
        <TextArea
            id={id}
            value={value}
            className="hide"
            onChange={onTextAreaChange}
            // className={'hide ' + (extended ? 'editor-extended' : 'editor-message')}
        />
    );
};

export default TinyMCE