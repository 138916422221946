import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
}

const UtmReducer = createSlice({
    name: 'utm',
    initialState,
    reducers: {
        fillUtmList(state, action) {
            state.list = action.payload
        },
        setUtmItem(state, action) {
            state.item = action.payload;
        },
        setUtmListCount(state, action) {
            state.count = action.payload;
        },
        setUtmStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addUtmToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeUtmFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchUtmInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetUtmState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        }
    }
})

export default UtmReducer.reducer
export const {
    fillUtmList,
    setUtmItem,
    setUtmListCount,
    setUtmStoreParam,
    patchUtmInList,
    addUtmToList,
    removeUtmFromList,
    resetUtmState,
} = UtmReducer.actions