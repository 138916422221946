import {Form, Upload} from 'antd';
import axios from 'axios';
import {backend_api} from "../../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {
    formRequiredRules,
    deepGet,
    isArr,
} from "../../../library/functions";
import {getName, SetFieldInfo} from "./FormFields";
import {UploadOutlined} from "@ant-design/icons";

const {Dragger} = Upload;

export const UploadField = (
    {
        t,
        admin,
        project_id,
        formValues = null,
        section = 'common',
        name = 'files',
        required = false,
        localeRoot = null,
        placeholder = null,
        label = null,
        accept = null, // ['mp4', 'webm'],
        storeFileUrl = null,
        onBlur = null,
        limit = 1,
    }
) => {
    if (!localeRoot) localeRoot = section;

    const fieldName = getName(name);
    const rules = required ? formRequiredRules : null;

    // let defaultListValue = deepGet(formValues, fieldName, []) || [];
    // console.log('formValues', formValues)

    const handleUpload = async ({file, onSuccess, onError, onProgress}) => {
        try {
            if (accept) {
                // Define allowed file formats
                const allowedFormats = accept.split(',');

                // Get the file extension
                const fileExtension = file.name.split('.').pop();

                // Check if the file format is allowed
                if (!allowedFormats.includes('.' + fileExtension.toLowerCase())) {
                    apiFalseNotice(t('error.upload.format'));
                    file.status = 'error';
                    onError(); return false;
                }
            }

            const urlResponse = await axios.post(backend_api.upload, {
                section,
                project_id,
                filetype: file.type,
                filename: file.name
            }, {
                headers: {
                    ...admin.header_authorized,
                }
            });

            if (!urlResponse.data.ok) {
                apiFalseNotice(urlResponse.data);
                onError(); return false;
            }

            const urlResult = urlResponse.data.result;
            const fileName = urlResult.fields.key.split('/').pop();
            const fileUrl = urlResult.url;
            // console.log('urlResponse', urlResult.fields)

            const formData = new FormData();

            // Append additional data fields from urlResult.fields
            Object.entries(urlResult.fields).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // console.log('formData', urlResult.endpoint, urlResult.fields)

            // append file after key (it is important)
            formData.append('file', file);

            const uploadResponse = await axios.post(urlResult.endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: event => {
                    onProgress({percent: (event.loaded / event.total) * 100});
                }
            });

            if (uploadResponse.status >= 300) {
                apiErrorNotice(t('error.upload.common'));
                onError(); return false;
            }

            // Set the status of the uploaded file to 'done'
            file.status = 'done';
            onSuccess({
                url: fileUrl,
                name: fileName,
                content_type: file.content_type,
            }, file);

            if (storeFileUrl) storeFileUrl(fileUrl);

        } catch (error) {
            apiErrorNotice(error);
            onError(); return false;
        }

        return true;
    };

    // ============ upload logic ============

    const getValueFromEvent = (e) => {
        // console.log("getValueFromEvent event", e);
        // work with incorrect data
        if (isArr(e)) return e;
        if (!e.fileList || !e.fileList.length) {
            if (storeFileUrl) storeFileUrl('')
            return [];
        }

        // work with all upload events like: remove, upload, etc - handle file array
        const result = e.fileList.map((file) => {
            if (file.response) file.url = file.response.url;
            return {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url ?? '',
            };
        });

        return result || [];
    };

    return (<div className="form-modal-item-wrapper">
        <Form.Item
            name={fieldName}
            valuePropName="fileList"
            getValueFromEvent={getValueFromEvent}
            rules={rules}
            label={SetFieldInfo(t, localeRoot, name, label, 'label')}
        >
            <Dragger
                maxCount={limit}
                listType="text"
                customRequest={handleUpload}
                accept={accept}
                onChange={onBlur}
                // TODO: think about other way to hide upload area
                // disabled={fileList.length >= limit}
            >
                <p className="label">
                    <UploadOutlined style={{fontSize: 20}} className="margin-right-xp"/>
                    {SetFieldInfo(t, localeRoot, name, placeholder, 'placeholder')}
                </p>
                {/*{placeholder ? <p className="ant-upload-hint">
                    {SetFieldInfo(t, localeRoot, name, placeholder, 'placeholder')}
                </p> : null}*/}
            </Dragger>
        </Form.Item>
    </div>)
};
