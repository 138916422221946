import {createSlice} from "@reduxjs/toolkit";
import {toNum} from "../../library/functions";

const initialState = {
    id: 0,
    loading: false,
    status: 'opened',  // 'saved', 'updated', 'opened', 'active', 'closed'
    list: [],
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
}

const MailReducer = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        fillMailList(state, action) {
            state.list = action.payload
        },
        setMailItem(state, action) {
            state.item = action.payload;
        },
        setMailId(state, action) {
            state.id = toNum(action.payload);
        },
        setMailStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        setMailItemParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state.item[key] = value;
            }
        },
        setMailStat(state, action) {
            if (!state.item.stat) state.item['stat'] = {};
            state.item.stat = {...state.item.stat, ...action.payload};
        },
        addMailToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeMailFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
            state.count = state.count - 1;
        },
        patchMailInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        resetMailState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        }
    }
})

export default MailReducer.reducer
export const {
    fillMailList,
    setMailItem,
    setMailItemParam,
    setMailStat,
    setMailId,
    setMailStoreParam,
    patchMailInList,
    addMailToList,
    removeMailFromList,
    resetMailState
} = MailReducer.actions