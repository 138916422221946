import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {MailListTable} from "./MailListTable";
import {inArray, objectLength, ucFirst} from "../../../library/functions";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const MailList = () => {
    // init section name
    const section = 'mail'
    const adminSection = 'mail'
    const Section = ucFirst(section)

    // init hook functions
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store and state
    const {admin, project, mail} = useSelector(store => store)
    const [filters, setFilters] = useState({})

    // init universal vars
    const project_item = project.item
    const list = mail.list
    const sorter = mail.sorter
    const rowsTotal = mail.count || 0
    const currentPage = mail.page || 1
    const pageSize = mail.pageSize || 10
    const activeItems = list.filter(item => inArray(item.status, ['scheduled', 'start', 'planning', 'running']));

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            // set filters
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize])


    useEffect(() => {
        let intervalId = null;
        // const now = getUtcDateTime()
        const intervalTime = 5000;
        // let intervalTime = status.scheduled ? 20000 : 2000;
        // console.log('status.scheduled', status.scheduled, intervalTime);

        const startInterval = () => {
            if (activeItems.length > 0) {
                intervalId = setInterval(() => {
                    for (let item of activeItems) {
                        updateItemStat(item.id);
                    }
                }, intervalTime);
            }
        };

        const stopInterval = () => {
            clearInterval(intervalId);
        };

        startInterval(); // Start the interval initially based on the current conditions

        return () => stopInterval(); // Clean up the interval on component unmount
    }, [activeItems.length]);

    const updateItemStat = (id) => {
        dispatch({type: 'get' + Section + 'Stat', admin, id, listOnly: true})
    }

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordEdit = () => {
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}/edit/0`)
    }

    const filterFields = [
        [
            {
                name: 'statuses',
                type: 'tags',
                section: section,
                placeholder: t('common.placeholder.tags'),
                values: [
                    // "draft",
                    "scheduled",
                    "planning",
                    "running",
                    "sent",
                    "paused",
                    // "template",
                    // "event",
                ],
                localized: true,
            },
            {
                name: 'start',
                type: 'date-range',
                section: section,
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            // {
            //     name: 'deleted',
            //     type: 'date-range'
            // },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordEdit}
                />
                <Content className="page-container site-layout-background">
                    <MailListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        adminSection={adminSection}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                    />
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default MailList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link