import React, {useLayoutEffect} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {dispatchItemData, directCreateOrUpdate} from "../../library/containers";
import {
    deepGet,
    ucFirst
} from "../../library/functions";
import {useTranslation} from "react-i18next";
import {FormTitle} from "../../components/Form/FormTitle";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {notice} from "../../library/notice";
import FieldsMaker from "../../components/Form/Field/FieldsMaker";

const {Content} = Layout;

const TemplateFields = () => {
    const {t} = useTranslation();
    const section = 'template'
    const adminSection = 'template'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, template} = useSelector(store => store)
    const project_item = project.item
    const item = template.item

    // init form
    const [form] = Form.useForm()

    // get data from API
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id)

        if (admin.authorized && project_item.id) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id}});
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    // ====== FORM FUNCTIONS ======

    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${item.folder_id}`);
    }

    const onFinish = () => {
        const values = form.getFieldsValue();
        directCreateOrUpdate(ucFirst(section), {
            id: item.id, project_id: project_item.id, logic: Object.values(values)
        }, admin, dispatch)
        backToList();
    }

    const onFailed = (errorInfo) => {
        // console.log('Form Failed:', errorInfo);
        notice.error(t(section + '.error.form_failed', {
            postProcess: 'sprintf',
            sprintf: [
                // deepGet(errorInfo, 'errorFields.0.name.0', '?'),
                deepGet(errorInfo, 'errorFields.0.errors', []).join('. '),
            ]
        }));
    }

    // ====== RENDER ======

    // set html title tag value:
    const page_title = t(section + '.fields.title') + ': ' + item.title
    // document.title = page_title + ' | ' + dotenv.public_name

    return (<AppWrapper>
        <Layout className="site-layout site-layout-background">
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFailed}
                name="settings"
                layout="vertical"
            >
                <FormTitle onBack={backToList}>{page_title}</FormTitle>

                <Content className="page-container form-container site-layout-background form-logic-fields ">
                    <FieldsMaker
                        t={t}
                        id={id}
                        form={form}
                        item={item}
                        section={section}
                    />

                    <FormSubmit onCancel={backToList}/>
                </Content>
            </Form>
        </Layout>
    </AppWrapper>)
}

export default TemplateFields