import React from 'react'
import SectionsConditions from "./ContentConditions/SectionsConditions";
import CatalogHeader from "../../Header/CatalogHeader";

const Conditions = ({ children, isBlock = false }) => {
    return (
        <>
            { children }
            <CatalogHeader type="condition"/>
            <SectionsConditions isBlock={isBlock}/>
        </>
    )
}

export default Conditions