import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    public: {
        list: [],
    },
    short: {
        // short list for select fields
        list: [],
        count: 0,
    },
    item: {},
    count: 0,
}

const LayoutReducer = createSlice({
    name: 'layout',
    initialState: initialState,
    reducers: {
        fillLayoutList(state, action) {
            state.list = action.payload
        },
        fillLayoutPublicList(state, action) {
            state.public.list = action.payload
        },
        fillLayoutShortList(state, action) {
            state.short.list = action.payload
        },
        // short count:
        setLayoutShortListCount(state, action) {
            state.short.count = action.payload;
        },
        setLayoutItem(state, action) {
            state.item = action.payload;
        },
        setLayoutListCount(state, action) {
            state.count = action.payload;
        },
        addLayoutToProject(state, action) {
            const item = action.payload;
            // state.item = item;  // do not change item to prevent page form from changing
            if (state.short.list.length > 0 && !state.short.list.find(p => p.name === item.name)) {
                state.short.list = [item, ...state.short.list];
                state.short.count = state.short.count + 1;
            }
        },
        addLayoutToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
            state.count = state.count + 1

            if (state.short.list.length > 0) {
                state.short.list = [action.payload, ...state.short.list]
                state.short.count = state.short.count + 1
            }
        },
        removeLayoutFromList(state, action) {
            const deleted_item = action.payload;

            state.list = state.list.filter(p => p.id !== deleted_item.id)
            state.count = state.count - 1;

            state.short.list = state.short.list.filter(p => p.id !== deleted_item.id)
            state.short.count = state.short.count - 1;
        },
        patchLayoutInList(state, action) {
            const oid = action.payload.id;

            // general list
            const list = [...state.list];
            const index = list.findIndex(o => o.id === oid);

            if (index >= 0) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }

            // short list
            const shortList = [...state.short.list];
            const shortIndex = shortList.findIndex(o => o.id === oid);

            if (shortIndex >= 0) {
                shortList[shortIndex] = {...shortList[shortIndex], ...action.payload};
                state.short.list = shortList;
            }

            state.item = action.payload
        },
        clearLayoutItem(state) {
            state.item = {};
        },
        resetLayoutState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default LayoutReducer.reducer
export const {
    fillLayoutList,
    setLayoutItem,
    clearLayoutItem,
    resetLayoutState,
    setLayoutListCount,
    patchLayoutInList,
    addLayoutToList,
    addLayoutToProject,
    removeLayoutFromList,
    fillLayoutShortList,
    fillLayoutPublicList,
    setLayoutShortListCount,
} = LayoutReducer.actions