import axios from "axios";
import {useEffect} from 'react'
import {useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {backend_api, routes} from "../../config/config";
import {notice} from "../../library/notice";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import Preloader from "../System/Preloader";

const projectIdMap = {
    'ig': 'state',
}

const PlatformOAuth = () => {
    const navigate = useNavigate()
    const admin = useSelector(store => store['admin'])

    const params = useParams()
    const platform_name = params.platform

    // data from GET params
    const [searchParams] = useSearchParams();
    const project_id = searchParams.get(projectIdMap[platform_name]);

    useEffect(() => {
        if (admin.authorized) {
            if (!project_id) {
                notice.error('Project ID is undefined');
                setTimeout(() => {
                    navigate(routes.project_list);
                }, 2000);
            } else {
                const url = `${routes.project_list}/${project_id}${routes.local.integration_list}/${platform_name}`

                let apiUrl = backend_api.oauth + '/' + platform_name + '/' + project_id;
                apiUrl += '?' + searchParams.toString();

                axios.get(apiUrl, {headers: {...admin.header_authorized}}).then(res => {
                    const result = res.data;
                    if (!result.ok) {
                        apiFalseNotice(res);
                    }

                    navigate(url);
                    // setTimeout(() => {
                    //     navigate(url);
                    // }, 1500);

                }).catch(error => {
                    apiErrorNotice(error)

                    navigate(url);
                    // setTimeout(() => {
                    //     navigate(url);
                    // }, 2000);
                });
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized])

    return <Preloader/>
}

export default PlatformOAuth;