import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    item: {},
    count: 0,
    page: 0,
    pageSize: 10,
    sorter: null,
    filters: {},
    folders: [],
    projects: [],
    imported: 0,
}

const UserReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fillUserList(state, action) {
            state.list = action.payload
        },
        setUserItem(state, action) {
            state.item = action.payload;
        },
        setUserListCount(state, action) {
            state.count = action.payload;
        },
        setUserStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addUserToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeUserFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchUserInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetUserState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        }
    }
})

export default UserReducer.reducer
export const {
    fillUserList,
    setUserItem,
    resetUserState,
    setUserStoreParam,
    setUserListCount,
    patchUserInList,
    addUserToList,
    removeUserFromList
} = UserReducer.actions