import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    store: [],
    item: {},
    count: 0,
    components: [],
    status: 'default',
}

const TemplateReducer = createSlice({
    name: 'template',
    initialState,
    reducers: {
        fillTemplateComponentsList(state, action) {
            state.components = action.payload
        },
        fillTemplateList(state, action) {
            state.list = action.payload
        },
        fillTemplateStoreList(state, action) {
            state.store = action.payload
        },
        setTemplateItem(state, action) {
            state.item = action.payload;
        },
        setTemplateStatus(state, action) {
            state.status = action.payload;
        },
        setTemplateListCount(state, action) {
            state.count = action.payload;
        },
        addTemplateToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeTemplateFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchTemplateInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetTemplateState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        }
    }
})

export default TemplateReducer.reducer
export const {
    fillTemplateComponentsList,
    fillTemplateList,
    fillTemplateStoreList,
    setTemplateItem,
    setTemplateStatus,
    resetTemplateState,
    setTemplateListCount,
    patchTemplateInList,
    addTemplateToList,
    removeTemplateFromList
} = TemplateReducer.actions