import React from 'react';
import Fa from "../../../components/Icon/Fa";
import classes from "./ContainerModal.module.scss";
import {containersMap} from "../Block/Templates/containers";
import {Card, Modal, Tabs} from "antd";

const {Meta} = Card;

export const ContainersModal = (
    {
        t, // useTranslation()
        title = '',
        isVisible = false,
        setVisible = null,
        onAdd = null,
        onOk = null,
        onCancel = null,
        onImport = null,
        width = 1200,
    }
) => {

    const hideModal = () => {
        setVisible(false);
    };

    const handleAdd = (content) => {
        if (onAdd) onAdd(content);
        hideModal();
    };

    const handleCancel = () => {
        hideModal();
        if (onCancel) onCancel()
    };

    const antTabs = Object.entries(containersMap).map(([name, item]) => {
        const categoryName = 'container.' + name.split('/').join('.')
        return {
            label: <span><Fa icon={item.icon}/>
                <span className="hidden-xs">{t(categoryName + '.title')}</span>
            </span>,
            key: name,
            children: Object.entries(item.list).map(([localType, container]) => {
                const blockName = categoryName + '.' + localType.split('/').join('.')
                return <Card
                    key={name + '/' + localType}
                    className={`${classes.card} cursor-pointer`}
                    hoverable
                    bordered={false}
                    onClick={() => {
                        if (localType === 'import') {
                            if (onImport) onImport()
                        } else {
                            handleAdd(container.content)
                        }
                    }}
                    cover={<img alt={item.name} src={'/image/layout/catalog/' + name + '/' + localType + '.jpg'}/>}>
                    <Meta title={t(blockName + '.title')}/>
                </Card>
            }),
        }
    })

    return <Modal
        width={width}
        centered={true}
        className={`${classes.modal_wrapper} ${width <= 900 ? classes.small : ''}`}
        title={title}
        open={isVisible}
        onOk={onOk}
        onCancel={handleCancel}
        footer={null}
    >
        <Tabs
            defaultActiveKey="core/cover"
            className={classes.tabs}
            tabPosition="left"
            items={antTabs}
            // style={{height: 500}}
        />
    </Modal>
}

