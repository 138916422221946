import {createSlice} from "@reduxjs/toolkit";

const StatusReducer = createSlice({
    name: 'app',
    initialState: {
        status: 'idle', // values: idle, loading, ready
    },
    reducers: {
        setLoadingStatus(state, action) {
            state.status = action.payload;
        },
    }
})

export default StatusReducer.reducer
export const {
    setLoadingStatus,
} = StatusReducer.actions