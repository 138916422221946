import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {deepGet, is, stripHtmlTags, toNum} from "../../../../../../library/functions";
import {getBaseClasses, getCustomClasses} from "../../../StyleComposer";
import Fa from "../../../../../../components/Icon/Fa";
import {DownOutlined, RightOutlined} from "@ant-design/icons";
import {BlockButton} from "../../../Components/Button";
import {useSearchParams} from "react-router-dom";

export const BlockNavMenu = (spec, item, classes, styles) => {
    const blockId = String(item.id);
    const uniqueClass = `b${blockId}`;

    const list = deepGet(spec, 'menu.tree', []) || [];
    const isHorizontal = spec.orientation === 'horizontal';

    let props = {};
    if (is(styles)) props['style'] = styles;

    const blockClasses = deepGet(item, ['style', 'classes'], {});
    const classMap = {...blockClasses};

    const baseClasses = getBaseClasses(classMap, classes);
    const specClasses = spec.classes || {};

    classMap['base'] = baseClasses;
    classMap['spec'] = {
        'default': baseClasses.concat(getCustomClasses(specClasses)),
        'active': baseClasses.concat(specClasses.active || []),

        'children': specClasses.children || [],
        'photo': specClasses.photo || [],
        'icon': specClasses.icon || [],
    }

    // for horizontal mode
    let activeKeys = [];
    if (list.length > 1 && list[1]) activeKeys.push(list[1].key);

    const renderList = (items) => {
        return (
            <ul className={`c-list level-1`}>
                {items && items.map((item) => {
                    if (!item || item.access === 'guest') return;
                    // console.log('item', item)

                    const isActive = activeKeys.includes(item.key);
                    const hasChildren = item.children && item.children.length > 0;

                    // console.log('currentParts', isActive, item.title, itemPath, currentPath, currentParts)
                    let itemClasses = 'c-item';
                    let labelClasses = 'c-item-label grow';

                    if (item.xs_short) labelClasses += ' hidden xs:block';

                    let wrapperClasses = 'c-item-wrapper';
                    if (item.classes && item.classes.length > 0) wrapperClasses += ' ' + item.classes.join(' ');

                    let toggleClasses = 'c-item-toggle float-right p-1 mt-auto mb-auto rounded-full bg-transparent';
                    let arrowClasses = 'c-item-arrow text-xs text-gray-500 transition-all duration-300 fill-box transform';

                    let iconClasses = 'c-item-icon';
                    if (classMap.spec.icon.length > 0) iconClasses += ' ' + classMap.spec.icon.join(' ');

                    let photoClasses = 'c-item-photo';
                    if (classMap.spec.photo.length > 0) photoClasses += ' ' + classMap.spec.photo.join(' ');

                    if (isActive) {
                        itemClasses += ' ' + classMap.base.concat(classMap.active).join(' ');
                        itemClasses += ' active';
                    } else {
                        itemClasses += ' ' + classes.join(' ');
                    }

                    return (<li className={wrapperClasses}>
                        <a href={null} className={itemClasses} {...props}>
                            {item.icon && <Fa icon={item.icon} set={'regular'} wcn={iconClasses}/>}
                            {item.photo_url && <img src={item.photo_url} className={photoClasses} alt="Icon"/>}

                            <span className={labelClasses}>{item.title}</span>
                            {hasChildren && <span className={toggleClasses}>
                                <RightOutlined className={arrowClasses}/>
                            </span>}
                        </a>
                    </li>)
                })}
            </ul>
        );
    };

    const renderMenu = (items) => {
        let listClasses = `p-block-wrap ${uniqueClass} c-list level-1`;
        listClasses += ' flex flex-row flex-wrap items-center justify-center gap-1 z-10';

        return (
            <ul id={uniqueClass} className={listClasses}>
                {items && items.map((item) => {
                    if (!item || item.access === 'guest') return;

                    const isActive = activeKeys.includes(item.key);
                    const hasChildren = item.children && item.children.length > 0;

                    let wrapperClasses = 'c-item-wrapper relative';
                    if (item.classes && item.classes.length > 0) wrapperClasses += ' ' + item.classes.join(' ');

                    let itemClasses = 'c-item';
                    let labelClasses = 'c-item-label grow whitespace-nowrap overflow-hidden text-ellipsis';

                    if (item.xs_short) labelClasses += ' hidden xs:block';

                    let toggleClasses = 'c-item-toggle float-right ml-3 mr-[-0.25rem] tiny:mr-0 mt-auto mb-auto';
                    let arrowClasses = 'c-item-arrow text-xs text-gray-500 fill-box transform';

                    let iconClasses = 'c-item-icon';
                    if (classMap.spec.icon.length > 0) iconClasses += ' ' + classMap.spec.icon.join(' ');

                    let photoClasses = 'c-item-photo';
                    if (classMap.spec.photo.length > 0) photoClasses += ' ' + classMap.spec.photo.join(' ');

                    if (isActive) {
                        itemClasses += ' ' + classMap.base.concat(classMap.active).join(' ');
                        itemClasses += ' active';
                    } else {
                        itemClasses += ' ' + classes.join(' ');
                    }

                    return (<li className={wrapperClasses}>
                        <a className={itemClasses} {...props}>
                            {item.icon && <Fa icon={item.icon} set={'regular'} wcn={iconClasses}/>}
                            {item.photo_url && <img src={item.photo_url} className={photoClasses} alt="Icon"/>}

                            <div className={labelClasses}>
                                <div>{item.title}</div>

                                {item.desc ? <div className="c-item-desc text-gray-500 text-sm">
                                    {stripHtmlTags(item.desc)}
                                </div> : null}
                            </div>

                            {hasChildren && <span className={toggleClasses}>
                                <DownOutlined className={arrowClasses}/>
                            </span>}
                        </a>
                    </li>)
                })}
            </ul>
        );
    };

    if (spec.set_by_var && spec.menu_var) return <div className={classMap.spec.default.join(' ')}>
        {spec.menu_var}
    </div>;

    else if (!list || list.length === 0) return <div className="c-list-empty">Empty</div>;

    if (!isHorizontal) return renderList(list);
    if (!spec.xs_mode) return renderMenu(list); // horizontal menu (desktop)

    return <>
        <div className="real:hidden xs:block">{renderMenu(list, 0, [])}</div>
        <BlockButton
            spec={{
                type: 'button',
                variant: 'outlined',
                color: 'default',
                size: 'medium',
                icon: {
                    name: spec?.icon?.name,
                    set: spec?.icon?.set,
                }
            }}
            classes={[
                'real:xs:hidden',
                'pt-1.5', 'pb-1', 'pl-3', 'pr-3',
                'text-gray-500', 'dark:text-gray-200',
                'border-gray-100', 'dark:border-gray-600',
            ]}
            params={{
                wrap: {},
                style: {
                    padding: {
                        left: '3',
                        right: '3',
                        bottom: '1',
                        top: '1',
                    },
                },
            }}
        />
    </>
}

export const BlockTagList = (spec, params, classes, styles) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    let props = {};
    let classNames = ['c-item'];

    if (is(classes)) classNames.push(...classes);
    if (is(styles)) props['style'] = styles;

    // data from GET params
    const [searchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')

    const {admin, project, tag} = useSelector(store => store)
    const [list, setList] = useState(tag.list || [])

    useEffect(() => {
        if (admin.authorized && project.item.id && !list.length) {
            dispatch({
                type: 'getTagList', admin, filters: {
                    project_id: project.item.id,
                    ordering: spec.order,
                }
            })
        }
    }, [admin, project.item.id]);

    useEffect(() => {
        if (spec) {
            const page_size = toNum(spec.page_size) || 0
            let tags = []
            let i = 0

            for (const item of tag.list) {
                const parent_id = toNum(spec.parent_id) || 0;
                if (item.parent_id !== parent_id) continue;
                if (!item.is_on) continue;

                tags.push(item)

                i++;
                if (page_size && i >= page_size) break;
            }

            // sort tags by title:
            if (spec.order === 'title') tags.sort((a, b) => {
                return a.title.localeCompare(b.title)
            })
            else if (spec.order === '-title') tags.sort((a, b) => {
                return b.title.localeCompare(a.title)
            })
            else if (spec.order === 'id') tags.sort((a, b) => {
                return a.id - b.id
            })
            else if (spec.order === '-id') tags.sort((a, b) => {
                return b.id - a.id
            })

            setList(tags)
        } else {
            console.log('BlockTagList: no spec')
            setList([])
        }

        //eslint-disable-next-line
    }, [tag.list.length, site_id, `${spec.order}-${spec.page_number}-${spec.page_size}-${spec.parent_id}`])

    return spec && list && list.length ? <>
        {list.map((item) => {
            let photo_url = deepGet(item, ['params', 'photo_url', 'default']);
            return <div key={item.id} className={classNames.join(' ')} {...props}>
                <a className="c-item-link">
                    {(spec.show_photo && photo_url) && <img className="c-item-photo" src={photo_url} alt={item.title}/>}

                    {spec.show_title &&
                        <div className={"c-item-title" + (spec.show_title && spec.show_photo ? ' mt-3' : '')}>
                            {item.title}
                        </div>}

                    {spec.show_desc && item.description && <div className="c-item-desc">
                        {stripHtmlTags(item.description)}
                    </div>}
                </a>
            </div>
        })}
    </> : <div>{
        t('container.example.tag.no_data')
    }</div>
}

// export const BlockTagCards = (spec) => {
//     const {t} = useTranslation();
//     // console.log('spec', spec)
//
//     const lorem = t('container.example.pages.lorem')
//     const list = [
//         {
//             title: t('container.example.pages.list.0'),
//             description: lorem,
//             photo_url: t('container.example.pages.photo.0'),
//         },
//         {
//             title: t('container.example.pages.list.1'),
//             description: lorem,
//             photo_url: t('container.example.pages.photo.1'),
//         },
//         {
//             title: t('container.example.pages.list.3'),
//             description: lorem,
//             photo_url: t('container.example.pages.photo.3'),
//         },
//     ];
//
//     if (spec.cards_per_row !== '3') {
//         list.push({
//             title: t('container.example.pages.list.4'),
//             description: lorem,
//             photo_url: t('container.example.pages.photo.4'),
//         })
//     }
//
//     const renderDesc = (description) => {
//         let desc = stripHtmlTags(description);
//         desc = desc.trim()
//         if (spec.desc_limit !== '0') {
//             const limit = toNum(spec.desc_limit) || 200;
//             desc = desc.substring(0, limit) + (desc.length > limit ? '...' : '');
//         }
//         return desc;
//     }
//
//     let renderItemContent = (item) => {
//         return <li className="c-item">
//             <a
//                 // href={'/' + item.path.join('/')}
//                 className="c-item-link"
//             >
//                 <div className="c-item-photo">
//                     {item.photo_url ? <img src={item.photo_url} alt={item.title}/> : null}
//                 </div>
//                 <div className="c-item-title">{item.title}</div>
//                 <div className="c-item-desc">{renderDesc(item.description)}</div>
//             </a>
//         </li>
//     }
//
//     if (spec.style === 'blocks') {
//         renderItemContent = (item) => {
//             return <li className="c-item">
//                 <a
//                     // href={'/' + item.path.join('/')}
//                     className="c-item-link flow-root w-full mb-4 rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out"
//                 >
//
//                     <div
//                         className="c-item-photo inline-block w-32 h-24 float-left mr-4
//                             rounded-lg overflow-hidden bg-secondary-300 bg-cover bg-center bg-no-repeat"
//                         style={{
//                             'backgroundImage': `url(${item.photo_url})`,
//                         }}
//                     />
//
//                     <div className="c-item-text pt-0.5 pb-1 pl-0 pr-5">
//                         <div className="c-item-title text-h4 mb-2">{item.title}</div>
//                         <div className="c-item-desc text-md text-secondary-700">{renderDesc(item.description)}</div>
//                     </div>
//                 </a>
//             </li>
//         }
//     } else if (spec.style === 'cards') {
//         renderItemContent = (item) => {
//             const rowLen = rowWidthMap[spec.cards_per_row] ?? '4';
//             return <li className={"c-item col-span-12 xs:col-span-6 sm:col-span-" + rowLen}>
//                 <a
//                     // href={'/' + item.path.join('/')}
//                     className="c-item-link mb-4 border-1 border-gray-100 rounded-lg overflow-hidden shadow-md h-full
//                     flex flex-col items-start hover:bg-gray-100 transition duration-300 ease-in-out"
//                 >
//                     <div
//                         className="c-item-photo aspect-w-4 aspect-h-3 bg-secondary-300 bg-cover bg-center bg-no-repeat"
//                         style={{
//                             'backgroundImage': `url(${item.photo_url})`,
//                         }}
//                     />
//                     <div className="c-item-text pt-2 pb-2 pl-5 pr-5">
//                         <div className="c-item-title text-h4 mt-2 mb-2">{item.title}</div>
//                         <div className="c-item-desc text-md text-secondary-700">{renderDesc(item.description)}</div>
//                     </div>
//                 </a>
//             </li>
//         }
//     }
//
//     let listClasses = 'c-list';
//     if (spec.style === 'cards') listClasses += ' grid grid-cols-12 gap-4';
//
//     return list && list.length ? <ul className={listClasses}>
//         {list.map((item) => renderItemContent(item))}
//     </ul> : <div className="c-list-empty"/>
// }