import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addGlobToList,
    fillGlobList,
    patchGlobInList,
    removeGlobFromList,
    setGlobItem,
    setGlobListCount,
} from "../reducers/GlobReducer";

// init api methods
const base_path = backend_api.glob;

function* getGlobList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillGlobList(response.data.result))
            yield put(setGlobListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getGlobItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setGlobItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createGlob(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addGlobToList(response.data.result))
            yield put(setGlobItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateGlob(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchGlobInList(response.data.result))
            yield put(setGlobItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyGlob(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '_copy'

        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setGlobItem(response.data.result))
            yield put(addGlobToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreGlob(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeGlobFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteGlob(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeGlobFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* globSaga() {
    yield takeEvery('getGlobList', getGlobList);
    yield takeEvery('getGlobItem', getGlobItem);
    yield takeEvery('createGlob', createGlob);
    yield takeEvery('copyGlob', copyGlob);
    yield takeEvery('updateGlob', updateGlob);
    yield takeEvery('restoreGlob', restoreGlob);
    yield takeEvery('deleteGlob', deleteGlob);
}

