import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {
    createObjectFromObjectsArray,
    getItemValues,
    inArray,
    isArr,
} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {ItemEditForm} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import Preloader from "../System/Preloader";
import {notice} from "../../library/notice";
import {setFieldValueItem} from "../../redux/reducers/FieldValueReducer";

const UserFieldEdit = () => {
    const {t} = useTranslation()
    const adminSection = 'user'

    // data from URL params
    const params = useParams()
    const user_id = Number(params.user_id)
    const field_id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let fieldDataType = searchParams.get('type') ?? null;
    let section = searchParams.get('section') ?? 'field';
    if (!section) section = 'field';
    let fieldSection = (section === 'field') ? 'crm' : section;

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, field, fieldValue} = useSelector(store => store)
    const project_item = project.item
    const project_id = project_item.id
    const item = fieldValue.item

    let fieldList = field.list ?? []
    if (fieldDataType) fieldList = fieldList.filter(e => e.data_type_admin === fieldDataType)
    else if (fieldSection) fieldList = fieldList.filter(e => e.system_field_type === fieldSection)
    if (fieldSection !== 'crm' && fieldSection !== 'form') fieldDataType = 'int'

    const valueFieldIds = fieldValue.list.map(e => e.field_id)
    if (fieldValue.list && fieldValue.list.length) fieldList = fieldList.filter(e => !inArray(e.id, valueFieldIds))

    let valueFieldType = 'textarea';
    if (field_id && item.field_id === field_id) {
        fieldDataType = item.field.data_type_admin;
        fieldSection = item.field.system_field_type;
        if (fieldSection === 'crm' || fieldSection === 'form')  section = 'field';
        else section = fieldSection;
    }

    if (inArray(fieldDataType, ['int', 'float'])) valueFieldType = 'text';
    // else if (fieldSection === 'datetime') valueFieldType = 'datetime';

    // const renderValueLabel = () => {
    //     if (fieldSection === 'list') return t('user.object.field.level')
    //     else if (fieldDataType === 'int') return t('user.object.field.count')
    //     else return t('user.object.field.value')
    // }

    const renderFieldSection = () => {
        // console.log('fieldSection', fieldSection)
        if (fieldSection === 'list') return 'group'
        else if (section === 'field') return 'user.field'
        return fieldSection
    }

    // get form and set values
    const [form] = Form.useForm()
    let formFields = [
        {
            // label: renderValueLabel(),
            name: "value",
            type: valueFieldType,
            placeholder: t('common.placeholder.text'),
            desc: (section === 'field') ? t('user.field.form.' + fieldDataType + '.desc') : t(renderFieldSection() + '.form.value.desc'),
        },
    ]

    if (!field_id) {
        const dataFilters = fieldDataType ? {
            system_field_type: fieldSection,
            data_type_admin: fieldDataType
        } : null;

        formFields.unshift({
            name: 'field_id',
            type: 'menu',
            label: section === 'field' ? t('user.field.form.field_id.label') : null,
            desc: t('user.field.form.field_id.desc'),
            placeholder: t('common.placeholder.menu'),
            required: true,
            data: 'field',
            data_filters: dataFilters,
            features: fieldDataType || (fieldSection && section !== 'form') ? ['creating'] : [],
            values: createObjectFromObjectsArray(fieldList, 'id', 'title')
        })
    }

    const renderDefaultValue = () => {
        if (inArray(fieldSection, ['list', 'achievement'])) return '1'
        else if (fieldDataType === 'int' || fieldDataType === 'float') return '0'
        else if (fieldDataType === 'json') return '{}'
        else if (fieldDataType === 'array') return '[]'
        else return ''
    }

    // init form state
    const [formValues, setFormValues] = useState({
        field_id: null,
        value: renderDefaultValue(),
    })

    useLayoutEffect(() => {
        dispatch(setFieldValueItem({}))
        //eslint-disable-next-line
    }, [])

    // get data from API first
    useLayoutEffect(() => {
        if (admin.authorized && project_id) {
            // todo: editing of deleted values
            // const is_active = !valueFieldIds.length || inArray(field_id, valueFieldIds);

            if (field_id) {
                dispatch({type: 'getFieldValue', admin, filters: {project_id, user_id, field_id}});
            } else if (!field_id && field.status !== 'ready') {
                dispatch({type: 'getFieldList', admin, filters: {project_id, deleted_since: 0}})
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_id, field_id])

    // set fields values to STATE if correct data received
    useEffect(() => {
        if (field_id && item.field_id && item.field_id === field_id) {
            const formValuesNew = getItemValues(item, formFields)
            setFormValues(formValuesNew)
        }
        //eslint-disable-next-line
    }, [item])

    // set fields values to FORM if correct data received
    useEffect(() => {
        if (typeof formValues.value === 'object') formValues.value = JSON.stringify(formValues.value, null, 2)

        form.setFieldsValue(formValues)

        //eslint-disable-next-line
    }, [formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        let result = {...values}
        if (result.value === '') result.value = renderDefaultValue()

        try {
            if (fieldDataType === 'int') result.value = parseInt(result.value)
            else if (fieldDataType === 'float') result.value = parseFloat(result.value)
            else if (inArray(fieldDataType, ['json', 'array'])) {
                result.value = JSON.parse(result.value)
                if (fieldDataType === 'array') {
                    if (!isArr(result.value)) {
                        notice.error(t('user.field.error.value.array'))
                        return false
                    }
                }
                else if (fieldDataType === 'json' && typeof result.value !== 'object' || isArr(result.value)) {
                    notice.error(t('user.field.error.value.json'))
                    return false
                }
            }
        } catch (e) {
            notice.error(t('user.field.error.value.common') + ': ' + e.message)
            return false
        }

        dispatch({type: 'storeFieldValue', admin, data: {project_id, user_id, field_id, ...result}});
        backToList()
    }
    const backToList = () => {
        navigate(`${routes.project_list}/${project_id}/${adminSection}/profile/${user_id}`)
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                {(!field_id || item.field_id) ? <ItemEditForm
                    t={t}
                    item={{...item, id: item.field_id}}
                    form={form}
                    section={renderFieldSection()}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                    formTitle={null}
                /> : <Preloader/>}
            </Layout>
        </AppWrapper>
    )
}

export default UserFieldEdit