import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {useDispatch} from "react-redux";
import {dotenv, routes} from "../../../config/config";
import {deepGet, getUtcDateTime, ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import {AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSafetyCertificate, AiOutlineSetting} from "react-icons/ai";
import FoldersList from "../../../components/List/Table/FoldersList";

const {Text} = Typography;

export const DomainListTable = (
    {
        t,
        admin,
        project,
        section,
        adminSection,
        list,
        filters,
        recordCreate,
        categoriesMap,
    }
) => {
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // let list_items = createObjectFromObjectsArray(list);

    // navigate
    const recordEdit = (record) => {
        let url = `${routes.project_list}/${project.id}/${section}/edit/${record.id}`
        if (filters.site_id) url += `?site_id=${filters.site_id}`
        navigate(url)
    }
    const recordOpen = (record) => recordEdit(record)
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id}) // TODO: saga + copy all pages API (?)
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }
    const installCertificate = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'installCertFor' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, newStatus) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: newStatus,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar align-center',
            width: 90,
            responsive: ['sm'],
            render: (value, record) => {
                return <Tooltip title={t('site.list.icon.open')}>
                    <a
                        title={value}
                        type="text"
                        href={dotenv.sites_proto + record.domain}
                        target="_blank"
                    >
                        <TableAvatar
                            icon={record.status ? 'globe' : 'signal-slash'}
                            isOn={record.status}
                        />
                    </a>
                </Tooltip>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
            render: (text, record) => (<>
                <Text className={record.is_main ? 'text-underline' : null}>{text}</Text><br/>
                <Text type="secondary">{record.domain}</Text>
            </>),
        },
        {
            dataIndex: 'title_xs',
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['xs'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
            render: (text, record) => record.status ? <Text>{text}</Text> : <Text type="danger">{text}</Text>,
        },
        {
            dataIndex: 'site_title',
            responsive: ['sm'],
            render: (text, record) => <span className="text-secondary">
                {text}
            </span>,
        },
        // {
        //     dataIndex: 'status',
        //     className: 'cursor-pointer',
        //     responsive: ['sm'],
        //     width: 115,
        //     render: (status) => {
        //         return <Tooltip title={t('common.action.click_for_toggle')}>{status ?
        //             <Text>{t('common.status.short.on')}</Text> :
        //             <Text type="danger">{t('common.status.short.off')}</Text>}
        //         </Tooltip>
        //     },
        //     onCell: (record, rowIndex) => {
        //         return {
        //             onClick: (ev) => recordChangeStatus(record.id, !record.status)
        //         };
        //     },
        // },
        {
            key: 'actions',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    {/*<Tooltip title={ t(section + '.list.icon.ssl')}>*/}
                    {/*    <Button*/}
                    {/*        type="text"*/}
                    {/*        onClick={() => installCertificate(record.id)}*/}
                    {/*    >*/}
                    {/*        <AiOutlineSafetyCertificate/>*/}
                    {/*    </Button>*/}
                    {/*</Tooltip>*/}
                    <Tooltip title={record.status ? t('table.icon.off') : t('table.icon.on')}>
                        <Button
                            type="text"
                            onClick={() => recordChangeStatus(record.id, !record.status)}>
                            {record.status ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('table.menu.edit')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        // {
        //     key: 'tableMenuCopy',
        //     label: t('table.menu.duplicate'),
        //     action: (record) => recordCopy(record.id)
        // },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        // {
        //     key: 'tableMenuSSL',
        //     label: t(section + '.list.menu.ssl'),
        //     action: (record) => installCertificate(record.id)
        // },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const now = getUtcDateTime();
        const record = list[i]
        data.push({
            id: record.id,
            type: record.type,
            domain: record.type === 'domain' ? record.name : record.name + '.' + dotenv.sites_bace_domain,
            site_title: deepGet(categoriesMap, [String(record.site_id)], '—'),
            key: record.id + '-listRow',
            title: record.title,
            title_xs: record.title,
            folder: record.params && record.params.folder_id || 0,
            status: record.is_on,
            deleted: record.deleted_at > 0,
            is_main: record.is_main,
        });
    }

    // ==== Render ====
    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

