import React, {useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from "react-redux";
import FlowContext from "./FlowContext";
import {
    arrayMove,
    deepCopyObject,
    getUtcDateTime,
    getObjectFromStorage,
    setObjectToStorage,
    deepGet,
    inArray,
    isObjectsEqual,
    objectLength,
    isQuotaExceededError,
} from "../../library/functions";
import {
    addGraphEdges,
    addGraphActionToNode,
    updateGraphNode,
    findIndexByLocalId,
    createGraphEffect,
    getElementByLocalId,
    removeElementsFromArray,
    addGraphEventToNode,
    addGraphConditionToEffect,
    composePort,
    findRelatedNodeData,
    createConditionEffect,
} from "./library/flowFunctions";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import {dotenv} from "../../config/config";
import {useTranslation} from "react-i18next";
import Preloader from "../System/Preloader";
import {notice} from "../../library/notice";
import {Io, upd} from "../../library/immutable";
import {condition_jump_effect, graph_jump_effect} from "../../schemas/frontend/effects";
import {changeGraphStatus, patchGraphContainer, setGraphItem} from "../../redux/reducers/GraphReducer";
import {specialEventTypes} from "../../schemas/frontend/events";
import {popularConditionTypes} from "../../schemas/frontend/conditions";
import {mail_graph} from "../../schemas/backend/mail_graph";

let graphParams = {
    onload: false,
    nextLocalId: 1,
    nextStepId: 1,
    connecting: 0,
    reconnecting: 0,
    localUpdatesCount: 0,
    portSelectionForClick: false,
    portSelectionForSelect: false,
    doNotRerenderFlow: false,
    connectingSource: null,
    mouse: {
        x: 160,
        y: 160,
    },
    position: {
        x: 0,
        y: 0,
        zoom: 1,
    }
};

// const connectionLineStyle = {stroke: '#ccc'};

const storageName = dotenv.app_name + ':graphs';

export const composeGraphItem = (project_id, example = mail_graph, title = 'New graph') => {
    return {
        project_id: project_id,
        folder_id: 0,
        title: title,
        icon_name: 'message-lines',
        logic: example,
        is_on: false,
    }
}

export const GraphBlock = forwardRef((
    {
        graphItem = null,
        onSave = null,
        onChange = null,
        blockType = 'action',
        blockLocalId = 0,
        nodesState = [],
        setNodesState = null,
        forbidden = false,
        forbiddenMessage = null,
    }, ref
) => {
    // common hooks
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {graph} = useSelector(store => store)

    // graph flow states
    const [renderStatus, setRenderStatus] = useState('loading'); // loading, ready, error
    // const [nodesState, setNodesState] = useState([]);  // for multiple blocks of one graph in one page
    const [edgesState, setEdgesState] = useState([]);
    const [chosenNode, setChosenNode] = useState({});

    const [autoSave, setAutoSave] = useState('');
    const [controlPanel, setControlPanel] = useState('default');
    const [chosenContainerId, setChosenContainerId] = useState(0);
    const [graphUpdateIndex, setGraphUpdateIndex] = useState(0);

    // window vars
    window.graph = graphParams;
    window.nodes = nodesState;

    // store vars
    // const graphItem = graph.item;
    // console.log('graphItem', graphItem)

    // init graph
    useLayoutEffect(() => {
        // console.info('Block: useEffect Layout', graphItem.id, deepGet(graphItem.logic, 'nodes.1.effects.0.spec.text'))

        // restore default on another scheme
        graphParams.nextStepId = 1;
        let redrawnStatus = false;

        // set vars
        let graphItemLogic = graphItem.logic;
        // if (!graphItemLogic) graphItemLogic = composeGraphItem(graphItem.project_id, example, 'New ' + section)
        // console.log('graphItemLogic', !graphItemLogic, graphItemLogic)

        // check local updates
        // const savedGraphs = getObjectFromStorage(storageName);
        // const localSave = deepGet(savedGraphs, graphItem.project_id + '.' + graphItem.id + '.0');
        // if (localSave && localSave.updated_at > graphItem.updated_at && !isObjectsEqual(graphItemLogic, localSave.graph.logic)) {
        //     // restore local logic if unsaved changes found
        //     graphItemLogic = localSave.graph.logic;
        //     redrawnStatus = true; // set edited (not saved) status
        // }

        if (graphItemLogic && objectLength(graphItemLogic)) {
            if (graphItemLogic.localIdNext) graphParams.nextLocalId = graphItemLogic.localIdNext; // Deprecated
            else if (graphItemLogic.nextLocalId) graphParams.nextLocalId = graphItemLogic.nextLocalId;

            // for UI numbers
            if (graphItemLogic.nextStepId) graphParams.nextStepId = graphItemLogic.nextStepId;

            setEdgesState(graphItemLogic.edges); // first: try to avoid rerender
            setNodesState(graphItemLogic.nodes);

            setAutoSave('local:open');

            const blocks = graphItemLogic.nodes || [];
            const block = blocks.find(block => block.type === blockType && (!blockLocalId || blockLocalId === block.localId));
            // console.log('graphItemLogic', graphItemLogic, blocks, block)
            setChosenNode(block || {})
        }

        // reset edited status on new graph opening
        setRenderStatus('ready');
        dispatch(changeGraphStatus({edited: false, redrawn: redrawnStatus}));
    }, [graphItem.logic]);

    // store graph changes to state
    useEffect(() => {
        // save if allowed
        if (autoSave) saveGraph();
        if (onChange && renderStatus === 'ready') onChange();  // onChange(nodesState, edgesState);
        // console.info('Block: useEffect nodesState', graphItem.id, deepGet(nodesState, '1.effects.0.spec.text'))
    }, [nodesState, edgesState]);

    const composeGraphDataForSave = () => {
        return {
            id: graphItem.id,
            project_id: graphItem.project_id,
            logic: {
                nextLocalId: graphParams.nextLocalId,
                nextStepId: graphParams.nextStepId,
                nodes: nodesState,
                edges: edgesState,
                ui: {
                    position: graphParams.position,
                    chosenNodeId: chosenNode && chosenNode.localId ? chosenNode.localId : 0,
                }
            },
        };
    }

    // get graph data by external parent component
    useImperativeHandle(ref, () => ({
        composeGraphDataForSave
    }));

    // useEffect for container data
    useEffect(() => {
        const containerData = graph.container;
        if (containerData.graphId === graphItem.id && containerData.localId) {
            const listName = containerData.listName
            let nodes = [...nodesState]
            const nodeIndex = findIndexByLocalId(nodes, chosenNode.localId)
            if (nodeIndex >= 0) {
                let node = {...nodes[nodeIndex]}
                let list = [...node[containerData.listName]]
                const containerIndex = findIndexByLocalId(chosenNode[listName], containerData.localId)
                if (nodeIndex >= 0) {
                    let container = {...list[containerIndex]}
                    container['spec'] = container.spec ? {...container.spec, ...containerData.specValues} : containerData.specValues
                    list[containerIndex] = container
                    node[listName] = list
                }
                // save changes
                nodes[nodeIndex] = node
                setChosenNode(node)
                setNodesState(nodes)
                // console.log('Graph node Update', graph.node, node)
            }
        }
        // reset node editing
        dispatch(patchGraphContainer({}))
    }, [graph.container.localId])

    if (!graphItem) return <Preloader/>;

    const saveGraph = (isManual = false) => {
        // autoSave values: db:manual, db:auto, local: ... [activity type name]
        /* activity names:
            * open - graph just opened (for canceling fist operation)
            * ...
        */

        const autoSaveMode = autoSave;
        if (autoSaveMode === 'db:manual') isManual = true;
        const autoSaveParams = autoSaveMode.split(':');
        const autoSaveStore = autoSaveParams[0];
        const data = composeGraphDataForSave();
        // if (!isManual && isObjectsEqual(graphItem.logic, data.logic)) return true;

        // console.log('saveGraph', graphItem.id, deepGet(data.logic, 'nodes.1.effects.0.spec.text'))
        if (onSave) onSave(data.logic);

        // reset to default = turn off
        setAutoSave('');

        // if (isManual) {
        //     dispatch({type: 'updateGraph', admin, data});
        //     console.log('Saved Graph:', data.logic);
        // } else
        // if (autoSaveMode === 'db:auto') {
        //     console.log('TODO: Autosave Graph:', data);
        // } else

        // TODO: test it by different item changing cases.
        // if (autoSaveStore === 'local') {
        //     const autoSaveAction = autoSaveParams[1] ? autoSaveParams[1] : 'auto';
        //     const graphObject = {action: autoSaveAction, updated_at: getUtcDateTime(), graph: data}
        //     let allGraphs = {[graphItem.project_id]: {[graphItem.id]: [graphObject]}} // {[projectId]: {[graphId]: [graphObject]}}
        //     let savedGraphs = getObjectFromStorage(storageName);
        //     if (savedGraphs) {
        //         allGraphs = savedGraphs;
        //         if (!allGraphs[graphItem.project_id]) allGraphs[graphItem.project_id] = {};
        //         if (!allGraphs[graphItem.project_id][graphItem.id]) allGraphs[graphItem.project_id][graphItem.id] = [];
        //         const graphUpdates = allGraphs[graphItem.project_id][graphItem.id].slice(0, 10); // not more 12 items in saved array
        //         graphParams.localUpdatesCount = graphUpdates.length; // save count for buttons
        //
        //         const lastUpdate = graphUpdates[0]; // do not save already saved data
        //         if (lastUpdate && isObjectsEqual(graphObject.graph.logic, lastUpdate.graph.logic)) return true;
        //
        //         // add new update version to storage
        //         graphUpdates.unshift(graphObject);
        //         allGraphs[graphItem.project_id][graphItem.id] = graphUpdates;
        //     }
        //
        //     // console.log('Autosave Graph To LocalStorage:', autoSaveMode, nodesState, allGraphs[graphItem.project_id][graphItem.id]);
        //     try {
        //         setObjectToStorage(storageName, allGraphs);
        //     } catch (err) {
        //         if (isQuotaExceededError(err)) {
        //             clearLocalUpdates(false);
        //         } else {
        //             console.error('Error saving to localStorage:', err);
        //         }
        //     }
        //
        //     setGraphUpdateIndex(-1);
        // }

        if (isManual) notice.success(t('graph.item.saved'), 1);
        return true;
    }

    const newLocalId = () => {
        const localId = graphParams.nextLocalId;
        graphParams.nextLocalId = localId + 1;
        // console.log('new local ID:', localId, 'next local ID:', graphParams.nextLocalId);
        return localId;
    }

    const getChosenNode = () => {
        return getElementByLocalId(nodesState, chosenNode.localId)
    }

    // ============= system & hotkeys ============

    const clearLocalUpdates = (showNotice = true) => {
        // let allLocalGraphs = getObjectFromStorage(storageName);
        // allLocalGraphs[graphItem.project_id][graphItem.id] = [];
        // setObjectToStorage(storageName, allLocalGraphs);
        setObjectToStorage(storageName, {});
        if (showNotice) notice.success('Local history is clean')
        else console.info('Local history is clean')

        // turn off buttons
        setGraphUpdateIndex(0)
        // console.log('clearLocalUpdates', allLocalGraphs[graphItem.project_id][graphItem.id]);
    }

    const restoreLocalUpdate = (index) => {
        const allLocalGraphs = getObjectFromStorage(storageName);
        const savedUpdates = allLocalGraphs[graphItem.project_id][graphItem.id]; // must be already not empty
        graphParams.localUpdatesCount = savedUpdates.length; // save count for buttons

        if (!savedUpdates.length) {
            notice.warning(t('graph.flow.error.restore.empty'));
            return false;
        } else if (index < 0) {
            setGraphUpdateIndex(-1);
            notice.warning(t('graph.flow.error.restore.last'));
            return false;
        } else if (index >= savedUpdates.length) {
            setGraphUpdateIndex(savedUpdates.length);
            notice.warning(t('graph.flow.error.restore.first'));
            return false;
        }

        const lastGraphUpdate = savedUpdates[index];
        // console.log('restoreLocalUpdate', index, lastGraphUpdate);

        if (!lastGraphUpdate) {
            notice.warning(t('graph.flow.error.restore.undefined'));
            console.log('graph.flow.error.restore', savedUpdates, [graphItem.project_id, graphItem.id, graphUpdateIndex, 'graph']);
            return false;
        }

        const restoredNodes = deepGet(lastGraphUpdate, 'graph.logic.nodes', [])
        const chosenNodeId = deepGet(lastGraphUpdate, 'graph.logic.ui.chosenNodeId', 0)
        if (chosenNodeId && restoredNodes.length) {
            const chosenNodeItem = getElementByLocalId(restoredNodes, chosenNodeId);
            setChosenNode({});
            setTimeout(() => {
                // crutch for textarea and buttons fields
                setChosenNode(chosenNodeItem);
            }, 100)
            setControlPanel('default');
        } else {
            // reset ChosenNode to defaults
            setChosenNode({});
        }
        setNodesState(restoredNodes);
        setEdgesState(deepGet(lastGraphUpdate, 'graph.logic.edges', []));

        setGraphUpdateIndex(index);
        setRedrawnStatus(true);
        setEditedStatusDirect(true);
    }

    const onGraphUndo = () => {
        restoreLocalUpdate(graphUpdateIndex + 1);
    }

    const onGraphRedo = () => {
        restoreLocalUpdate(graphUpdateIndex - 1);
    }

    // ============= effects containers ============

    const setRedrawnStatus = (updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else dispatch(changeGraphStatus({redrawn: status}));
    }

    const setEditedStatus = (node, updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) {
            notice.error(errorMessage);
        } else if (!isObjectsEqual(node, updatedNode)) {
            dispatch(changeGraphStatus({edited: status, redrawn: status}));
        }
    }

    const setEditedStatusDirect = (updatedNode, status = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else dispatch(changeGraphStatus({edited: status, redrawn: status}));
    }

    const setEditedAndRedrawnStatus = (updatedNode, status = true, addRedrawnCond = true, errorMessage = 'Node was not updated') => {
        if (!updatedNode) notice.error(errorMessage);
        else {
            let newStore = {edited: status};
            if (addRedrawnCond) newStore['redrawn'] = status; // for conditions but not actions
            dispatch(changeGraphStatus(newStore));
        }
    }

    const addNodeEffect = (type) => {
        if (chosenNode.localId) {
            setAutoSave('local:container_added');

            const updatedNode = addGraphActionToNode(type, {}, chosenNode.localId, nodesState, setNodesState, newLocalId, setChosenNode);
            // console.log('Effect added to Node', updatedNode);
            setEditedAndRedrawnStatus(updatedNode, true, chosenNode.type === 'condition', 'Node Effect was not added');
            if (updatedNode) setControlPanel('default');
            else notice.error('Effect not added by unexpected error');
        }
    }

    const addNodeEvent = (type) => {
        if (chosenNode.type === 'event') {
            // console.log('addNodeEvent', type);
            setAutoSave('local:event_added');

            const updatedNode = addGraphEventToNode(type, chosenNode.localId, nodesState, newLocalId, true, setNodesState, setChosenNode);
            // console.log('Event added to Node', updatedNode);
            setEditedStatusDirect(updatedNode, true, 'Node Event was not added');
            if (updatedNode) {
                setControlPanel('default');
                // do not forget add webhook to new event
                // if (type === specialEventTypes.webhook) createWebhook(updatedNode.events[updatedNode.events.length - 1].localId)
            } else notice.error('Event not added by unexpected error');
        }
    }

    const addNodeEffectCondition = (type) => {
        // console.log('Condition added to NodeEffect', chosenContainerId, type);
        setAutoSave('local:condition_added');
        const updatedNode = addGraphConditionToEffect(type, chosenNode.localId, chosenContainerId, nodesState, setNodesState, newLocalId, setChosenNode);
        // console.log('Result', updatedNode);
        if (updatedNode === undefined) {
            notice.error('Condition effect elements is undefined. You found a miracle!');
        } else {
            setControlPanel('default');
            setEditedStatusDirect(updatedNode, true, 'Node Condition was not added');
        }
    }

    const cloneNodeEffect = (nodeLocalId, effectLocalId) => {
        const node = getElementByLocalId(nodesState, nodeLocalId);
        let origEvent = null;
        let origAction = getElementByLocalId(node.effects, effectLocalId);
        if (!origAction && node.events) {
            // then it can be event
            origEvent = getElementByLocalId(node.events, effectLocalId);
            if (origEvent) origAction = getElementByLocalId(node.effects, origEvent.filterEffect);
        }
        if (origEvent) {
            setAutoSave('local:event_cloned');
            const updatedNode = addGraphEventToNode(
                origEvent.type,
                chosenNode.localId,
                nodesState,
                newLocalId,
                true,
                setNodesState,
                setChosenNode,
                null,
                origAction.spec,
            );
            setEditedStatusDirect(updatedNode, true, 'Node Event was not added');
        } else if (origAction) {
            // console.log('cloneNodeEffect origEffect:', origAction);
            setAutoSave('local:container_cloned');

            const updatedNode = addGraphActionToNode(origAction.type, origAction.spec, nodeLocalId, nodesState, setNodesState, newLocalId, setChosenNode);
            setEditedAndRedrawnStatus(updatedNode, true, node.type === 'condition', 'Node Effect was not cloned');

            // console.log('cloneNodeEffect newEffect:', updatedNode);
        } else notice.error('Can not get effect for clone. You found a miracle!');
    }

    const deleteNodeEffect = (nodeLocalId, localId) => {
        const node = getElementByLocalId(nodesState, nodeLocalId);
        let effectLocalId = localId;

        let nodeEvents = null; // init false value before check
        if (node.type === 'event' && node.events && node.events.length) {
            const eventIndex = findIndexByLocalId(node.events, localId);
            effectLocalId = node.events[eventIndex].filterEffect;

            // remove event
            nodeEvents = removeElementsFromArray([eventIndex], node.events);
        }

        // find effect or filterEffect index
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);

        // get effect by index
        const effect = node.effects[effectIndex];
        let nodePorts = node.ports;
        let removePorts = []; // and delete ports on the way
        let removePortsIds = []; // for delete edges later
        let relatedNodeInfo = {}

        // delete condition ports
        if (node.type !== 'event' && effect.type === condition_jump_effect.type) {
            for (const truePortLocalId of effect.spec['truePorts']) {
                const portIndex = findIndexByLocalId(nodePorts, truePortLocalId);
                if (portIndex >= 0) {
                    removePorts.push(portIndex);
                    removePortsIds.push(truePortLocalId);
                }
            }
        }

        // delete message ports
        if (node.type === 'message' && effect.spec && effect.spec.keyboard && effect.spec.keyboard.length) {
            for (const buttonsRow of effect.spec.keyboard) {
                for (const button of buttonsRow) {
                    const portIndex = findIndexByLocalId(nodePorts, button.id);
                    if (portIndex >= 0) {
                        removePorts.push(portIndex);
                        removePortsIds.push(button.id);
                    }
                }
            }

            relatedNodeInfo = findRelatedNodeData(node, 'reactions', 'event')
            // if (relatedNodeInfo && removePortsIds.length) {}
        }

        // remove edges to
        let edges = [...edgesState]

        if (removePorts.length) {
            nodePorts = removeElementsFromArray(removePorts, nodePorts);

            // remove edges of removed ports
            edges = edges.filter(edge => !inArray(edge.srcPort, removePortsIds))
        }

        // remove effect
        const nodeEffects = removeElementsFromArray([effectIndex], node.effects);

        // set new params
        let nodeNewParams = {effects: nodeEffects, ports: nodePorts};
        if (nodeEvents) nodeNewParams['events'] = nodeEvents;

        setAutoSave('local:container_deleted');

        // update curren node params
        let nds = new Io(nodesState);
        nds.remember('nodes')
        nds.find(['localId', nodeLocalId])
        // update node in nodesState list
        nds.set(nodeNewParams)
        // update chosenNode
        setChosenNode(nds.space)

        // store result
        let nodes = nds.data

        // for messages keyboards
        if (relatedNodeInfo.nodeLocalId && removePortsIds.length) {
            // check buttons length
            const messagePorts = nodePorts.filter(p => p.group === 'buttons')
            if (messagePorts.length) {
                nds.restore('nodes')
                nds.find(['localId', relatedNodeInfo.nodeLocalId])

                // remove condition
                if (nds.space.effects) {
                    let removedFiltersLocalIds = []
                    // remove conditions
                    const eventEffects = nds.space.effects.filter(el => () => {
                        const res = !inArray(deepGet(el, 'spec.truePorts.0'), removePortsIds)
                        if (res) removedFiltersLocalIds.push(el.localId)
                        return res
                    })
                    nds.set({effects: eventEffects})

                    nds.go(['effects'])
                    nds.find(['type', graph_jump_effect.type])

                    nds.go(['spec'])
                    const localIds = nds.space.localIds.filter(el => !inArray(el, removedFiltersLocalIds))
                    nds.set({'localIds': localIds})

                    // store result
                    nodes = nds.data
                }
            }
            // remove event if last port deleted
            else {
                nds.go(['ui'])
                nds.remove({from: 'related', by: 'nodeLocalId', value: relatedNodeInfo.nodeLocalId})

                // remove event node and store result
                nodes = nds.data.filter(node => node.localId !== relatedNodeInfo.nodeLocalId)
                // nds.remove({by: 'localId', value: relatedNodeInfo.nodeLocalId})
                edges = edges.filter(edge => edge.dstNode !== relatedNodeInfo.nodeLocalId)
            }
        }

        // save edges
        if (edges.length !== edgesState.length) setEdgesState(edges)
        // save nodes
        const updatingResult = (nodes !== undefined)
        if (updatingResult) setNodesState(nodes)

        // store params to node
        // const updatedNode = updateGraphNode(nodeNewParams, null, nodesState, setNodesState, setChosenNode);
        setEditedAndRedrawnStatus(updatingResult, true, inArray(node.type, ['condition', 'message']), 'Node Effect was not deleted');
    }

    const batchNodeEffectsUpdate = (nodeLocalId, effectsParams) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        let effects = [];
        for (const effect of node.effects) {
            if (effect.type === graph_jump_effect.type) continue;
            const newEffect = {...effect, ...effectsParams};
            effects.push(newEffect);
        }

        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: effects
        }, null, nodesState, setNodesState, setChosenNode);
        // do not change edited status for container collapsing
    }

    const updateNodeContainerParams = (nodeLocalId, containerLocalId, effectParams = {}, changeStatus = false, updateFlow = false) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const containerType = (node.type === 'event') ? 'events' : 'effects';

        // console.log('updateNodeContainerParams', containerLocalId, node[containerType])
        const containerIndex = findIndexByLocalId(node[containerType], containerLocalId);
        let affectedNode = deepCopyObject(node); // Is it possible to optimize?
        const container = affectedNode[containerType][containerIndex];

        for (const effectParam in effectParams) {
            if (inArray(effectParam, ['spec', 'ui'])) {
                container[effectParam] = {...container[effectParam], ...effectParams[effectParam]};
            } else {
                container[effectParam] = effectParams[effectParam];
            }
        }

        // set new params
        let newNodeParams = {localId: nodeLocalId, [containerType]: affectedNode[containerType]};

        // rename node ports also
        if ('title' in effectParams && containerType !== 'events' && container.spec && container.spec.truePorts) {
            let nodePorts = deepCopyObject(node.ports);
            for (const truePortLocalId of container.spec.truePorts) {
                const portIndex = findIndexByLocalId(nodePorts, truePortLocalId);
                if (portIndex >= 0) {
                    if (!nodePorts[portIndex]['ui']) nodePorts[portIndex]['ui'] = {};
                    nodePorts[portIndex]['ui']['title'] = effectParams.title;
                }
            }
            newNodeParams['ports'] = nodePorts;
        }

        // turn off flow updating
        if (!updateFlow) graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);

        // and update node
        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        if (changeStatus) setEditedStatus(node, updatedNode, true, 'Node Container was not updated');
    }

    const updateNodeEffectParams = (nodeLocalId, containerLocalId, effectParams = {}, changeStatus = false, updateFlow = false) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);

        // console.log('updateNodeEffectParams', containerLocalId, node)
        const containerIndex = findIndexByLocalId(node['effects'], containerLocalId);
        let affectedNode = deepCopyObject(node); // Is it possible to optimize?
        const container = affectedNode['effects'][containerIndex];

        for (const effectParam in effectParams) {
            if (inArray(effectParam, ['spec', 'ui'])) {
                container[effectParam] = {...container[effectParam], ...effectParams[effectParam]};
            } else {
                container[effectParam] = effectParams[effectParam];
            }
        }

        // set new params
        let newNodeParams = {localId: nodeLocalId, effects: affectedNode.effects};

        // turn off flow updating
        if (!updateFlow) graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);

        // and update node
        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        if (changeStatus) setEditedStatus(node, updatedNode, true, 'Node Effect was not updated');
    }

    const updateNodeEffect = (nodeLocalId, effectLocalId, spec = {}) => {
        setAutoSave('local:container_updated');
        // console.log('updateNodeEffect', 'node', nodeLocalId, 'effect', effectLocalId, spec)
        updateNodeEffectParams(nodeLocalId, effectLocalId, {spec: spec}, true);
    }

    const moveNodeContainer = (nodeLocalId, containerLocalId, forward = true) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const containerType = (node.type === 'event') ? 'events' : 'effects';
        let containerList = [...node[containerType]]; // «let» for moving items later

        const containerCurrentIndex = findIndexByLocalId(containerList, containerLocalId);
        const containerNewIndex = forward ? containerCurrentIndex + 1 : containerCurrentIndex - 1;

        if (containerList[containerNewIndex] === undefined) {
            notice.warning(t('graph.flow.error.move.last'));
            return false;
        }

        let newNodeParams = {
            localId: nodeLocalId,
            [containerType]: arrayMove(containerList, containerCurrentIndex, containerNewIndex),
        }

        // turn off flow updating
        graphParams.doNotRerenderFlow = true;

        const updatedNode = updateGraphNode(newNodeParams, null, nodesState, setNodesState, setChosenNode);
        setEditedStatus(node, updatedNode, true, 'Node Effect was not updated');
    }

    const updateNodeEffectCondition = (nodeLocalId, effectLocalId, conditionLocalId, condParams) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);
        let affectedNode = deepCopyObject(node);

        const affectedEffect = affectedNode.effects[effectIndex];
        const conditions = affectedEffect.spec.conditions;
        const condIndex = findIndexByLocalId(conditions, conditionLocalId);
        if (condIndex >= 0) conditions[condIndex] = {...conditions[condIndex], ...condParams};

        // turn off flow updating
        graphParams.doNotRerenderFlow = true; // console.log('doNotRerenderFlow', true);
        // but turn on autosave
        setAutoSave('local:condition_updated');

        // and save node to state
        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: affectedNode.effects
        }, null, nodesState, setNodesState, setChosenNode);

        // set new save button status
        setEditedStatus(node, updatedNode, true, 'Node Condition was not updated');
    }

    const deleteEffectCondition = (nodeLocalId, effectLocalId, conditionLocalId) => {
        let node = getElementByLocalId(nodesState, nodeLocalId);
        const effectIndex = findIndexByLocalId(node.effects, effectLocalId);
        let affectedNode = deepCopyObject(node);

        const affectedEffect = affectedNode.effects[effectIndex];
        const conditions = affectedEffect.spec.conditions;

        const condIndex = findIndexByLocalId(conditions, conditionLocalId);
        if (condIndex > -1) conditions.splice(condIndex, 1); // delete item by index

        setAutoSave('local:condition_deleted');

        const updatedNode = updateGraphNode({
            localId: nodeLocalId,
            effects: affectedNode.effects
        }, null, nodesState, setNodesState, setChosenNode);

        // set new save button status
        setEditedStatus(node, updatedNode, true, 'Node Condition was not deleted');
    }

    // ============= keyboard ============

    const createButtonSystemNodes = (localId, button) => {
        // console.log('create hidden nodes')
        if (!localId || !chosenNode || !chosenNode.ports) return false

        // system ports
        const successPortLocalId = localId; // button id = button port localId = condition true port localId
        const messageSourcePort = chosenNode.ports.find(port => port.name === 'defaultOutput');

        // event node
        const eventInputLocalId = newLocalId();
        const eventNodeLocalId = newLocalId();
        const eventOutputLocalId = newLocalId();
        // event filters
        const filterEffectLocalId = newLocalId(); // graph/jump/to effect
        const firstConditionLocalId = newLocalId(); // condition/jump effect

        // console.log('createButtonSystemNodes', {
        //     successPortLocalId,
        //     eventNodeLocalId,
        //     eventOutputLocalId,
        //     filterEffectLocalId,
        //     firstConditionLocalId
        // })

        // TODO: move to external function
        let conditionType = popularConditionTypes.object_text;
        let conditionSpec = {values: [button.label]};

        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        } else if (button.type === 'request') {
            conditionType = popularConditionTypes.object_attachment;
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }
        // TODO: end move part

        let eventNode = {
            type: 'event',
            localId: eventNodeLocalId,
            events: [
                {
                    localId: newLocalId(),
                    type: specialEventTypes.message,
                    filterEffect: filterEffectLocalId,
                },
                {
                    localId: newLocalId(),
                    type: specialEventTypes.button,
                    filterEffect: filterEffectLocalId,
                },
                {
                    localId: newLocalId(),
                    type: specialEventTypes.url,
                    filterEffect: filterEffectLocalId,
                },
            ],
            effects: [
                // pseudo filter for event => jump to all filters in one step
                createGraphEffect(
                    graph_jump_effect.type,
                    {localIds: [firstConditionLocalId]},
                    newLocalId,
                    null,
                    filterEffectLocalId
                ),
                // first (keyword) filter
                createConditionEffect(
                    conditionType,
                    conditionSpec,
                    {truePorts: [successPortLocalId, eventOutputLocalId]},
                    firstConditionLocalId,
                    newLocalId,
                )
            ],
            ports: [
                composePort('defaultInput', eventInputLocalId, 'input'),
                composePort('defaultOutput', eventOutputLocalId, 'output'), // for backend state deleting
            ],
            ui: {hidden: true},
        }
        // console.log('Button: New event', eventNode)

        // setAutoSave('local:keyboard_created'); // TODO: replace it to Keyboard component!

        // set related nodes to chosenNode
        let nodes = upd(nodesState, [
            // push hidden node
            {push: [eventNode]},
            // find and open chosenNode
            {find: ['localId', chosenNode.localId]},
            {remember: 'chosenNode'},
            // save (related) hidden nodes IDs in chosenNode
            {go: {path: 'ui.related', empty: []}},
            {push: [{nodeLocalId: eventNodeLocalId, group: 'reactions', type: "event"}]},
            // add related port to chosenNode
            {restore: 'chosenNode'},
            {go: {path: 'ports', empty: []}},
            {
                push: [{
                    ...composePort('button', successPortLocalId, 'output', 'buttons'),
                    ui: {type: button.color, title: button.label}
                }]
            },
        ], 'Chosen Node or it Params is undefined. Graph is broken')

        // save everything to state
        setNodesState(nodes)

        // and finally connect them all by edges
        const newEdges = [
            { // from message to event. This hidden arrow is necessary for _event_waiting_ logic
                srcNode: chosenNode.localId,
                srcPort: messageSourcePort.localId,
                dstNode: eventNodeLocalId,
                dstPort: eventInputLocalId,
                ui: {hidden: true},
            },
        ]
        // console.log('Button: New edges', newEdges)
        addGraphEdges(newEdges, edgesState, setEdgesState, newLocalId, 'System edges self connect')
    }

    const addKeyboardButton = (localId, button) => {
        if (!localId) return

        // const relatedNodeLocalId = deepGet(getChosenNode(), 'ui.relative.reactions.event', 0);
        const relatedNodeInfo = findRelatedNodeData(getChosenNode(), 'reactions', 'event')
        if (!relatedNodeInfo) {
            notice.error('Related Node undefined!')
            return;
        }

        const relatedNodeLocalId = relatedNodeInfo.nodeLocalId;
        const relatedNode = getElementByLocalId(nodesState, relatedNodeLocalId);
        const eventOutput = relatedNode.ports.find(port => port.name === 'defaultOutput');

        const effectLocalId = newLocalId();

        let conditionType = popularConditionTypes.object_text;
        let conditionSpec = {values: [button.label]};

        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        }
        // all request button types
        else if (button.type === 'request') {
            conditionType = popularConditionTypes.object_attachment;
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }

        let relatedConditionEffect = createConditionEffect(
            conditionType,
            conditionSpec,
            {truePorts: [localId, eventOutput.localId]},
            effectLocalId,
            newLocalId,
        )

        // setAutoSave('local:button_added');

        let nodes = upd(nodesState, [
            // related effect
            {remember: 'nodes'},
            {find: ['localId', relatedNodeLocalId]}, // find related condition node
            {go: ['effects']},
            {push: [relatedConditionEffect]}, // add condition effect
            // event filters
            {find: ['type', graph_jump_effect.type]}, // find graph_jump_effect
            {go: ['spec', 'localIds']},
            {push: [effectLocalId]}, // add new button filter
            // message ports
            {restore: 'nodes'},
            {find: ['localId', chosenNode.localId]}, // find chosenNode
            {go: ['ports']}, // add related port
            {
                push: [{
                    ...composePort('button', localId, 'output', 'buttons'),
                    ui: {type: button.color, title: button.label}
                }]
            },
        ], 'Chosen or Related Node undefined')
        setNodesState(nodes)
    }

    const editKeyboardButton = (localId, button) => {
        const relatedNode = findRelatedNodeData(getChosenNode(), 'reactions', 'event')
        if (!relatedNode) {
            notice.error('Related Node undefined!');
            return;
        }

        const relatedNodeLocalId = relatedNode.nodeLocalId;
        // console.log('editKeyboardButton', relatedNodeLocalId, chosenNode)

        if (!localId) return
        if (!relatedNodeLocalId) notice.error('Related condition node not found')
        // setAutoSave('local:button_updated');

        let conditionSpec = {values: [button.label]};
        if (button.type === 'url') {
            if (!button.spec) notice.error('URL Button spec is undefined!')
            else conditionSpec = {values: [button.spec.url]};
        }
        // all request button types
        else if (button.type === 'request') {
            conditionSpec = {values: [deepGet(button, 'spec.object', 'contact')]};
        }

        setNodesState(upd(nodesState, [
            // update condition
            {remember: 'nodes'},
            {find: ['localId', relatedNodeLocalId]},
            {go: ['effects']},
            {find: ['spec.truePorts.0', localId]},
            {go: 'spec.conditions.0.spec'},
            {set: conditionSpec},
            // update button port
            {restore: 'nodes'},
            {find: ['localId', chosenNode.localId]}, // find chosenNode
            {go: ['ports']},
            {find: ['localId', localId]},
            // update button color and label
            {go: ['ui']},
            {set: {type: button.color, title: button.label}},
        ], 'Related item undefined'))
    }

    const removeKeyboardButton = (localId, keyboard, effectLocalId) => {
        // let nodes = [...nodesState]
        const chosenNodeItem = getChosenNode();
        const relatedNode = findRelatedNodeData(chosenNodeItem, 'reactions', 'event')
        if (!relatedNode) {
            notice.error('Related Node undefined!')
            return;
        }
        const relatedNodeLocalId = relatedNode.nodeLocalId;

        // remove message ports fist
        let nds = new Io(nodesState);
        nds.find(['localId', chosenNode.localId])
        nds.remove({from: 'ports', by: 'localId', value: localId})
        nds.go(['ports'])
        let nodes = nds.data
        const messagePorts = nds.space.filter(p => p.group === 'buttons')

        // remove related edges
        setEdgesState(edgesState.filter(edge => edge.srcPort !== localId))

        if (messagePorts.length) {
            // remove related condition and port
            if (!localId) return

            // setAutoSave('local:button_deleted');

            // get filter ID
            let iNodes = new Io(nodesState);
            iNodes.find(['localId', relatedNodeLocalId])
            iNodes.go(['effects'])
            iNodes.find(['spec.truePorts.0', localId])
            const filterLocalId = iNodes.space.localId;
            if (!filterLocalId) {
                // notice.error('Filter ID undefined')
                return
            }

            nodes = upd(nodes, [
                // update condition
                {remember: 'nodes'},
                {find: ['localId', relatedNodeLocalId]},
                {remove: {from: 'effects', by: 'spec.truePorts.0', value: localId}},
                // update event filter
                {go: ['effects']},
                {find: ['type', graph_jump_effect.type]},
                {go: ['spec']},
                {remove: {from: 'localIds', value: filterLocalId}},
                // update button port
                {restore: 'nodes'},
                {find: ['localId', chosenNode.localId]}, // find chosenNode
                // {remove: {from: 'ports', by: 'localId', value: localId}}, // relocated above
                // fix keyboard
                {go: ['effects']},
                {find: ['localId', effectLocalId]},
                {go: ['spec']},
                {set: {keyboard}}
            ], 'Related item undefined')

            setNodesState(nodes)
        } else {
            // cleanup related ids
            nodes = upd(nodes, [
                // {remember: 'nodes'},
                {find: ['localId', chosenNode.localId]},
                // {remove: {from: 'ports', by: 'localId', value: localId}}, // relocated above
                {go: ['ui']},
                {remove: {from: 'related', by: 'nodeLocalId', value: relatedNodeLocalId}},
                // {restore: 'nodes'},
                // {remove: {by: 'localId', value: relatedEventId}},
                // {remove: {by: 'localId', value: relatedCondId}},
            ], 'Chosen Node undefined')

            // and delete related nodes
            nodes = nodes.filter(node => !inArray(node.localId, [relatedNodeLocalId]))
            // console.log('removeKeyboardButton from event', relatedNodeLocalId, 'nodes:', nodes)
            // store to state
            setNodesState(nodes)
        }
    }

    return (
        <FlowContext.Provider value={{
            isBlock: true,
            forbidden: forbidden,
            forbiddenMessage: forbiddenMessage,

            // nodes & common
            newLocalIdNext: newLocalId,
            saveGraph: saveGraph,

            // update history logic
            graphUpdateIndex: graphUpdateIndex,
            setGraphUpdateIndex: setGraphUpdateIndex,
            localUpdatesCount: graphParams.localUpdatesCount,
            restoreLocalUpdate: restoreLocalUpdate,
            onGraphUndo: onGraphUndo,
            onGraphRedo: onGraphRedo,

            // effect containers
            addNodeEvent,
            addNodeEffect,
            addNodeEffectCondition,
            batchNodeEffectsUpdate,
            updateNodeEffectParams,
            updateNodeContainerParams,
            updateNodeEffect,
            moveNodeContainer,
            updateNodeEffectCondition,
            deleteEffectCondition,
            cloneNodeEffect,
            deleteNodeEffect,

            // keyboard buttons
            createButtonSystemNodes,
            addKeyboardButton,
            editKeyboardButton,
            removeKeyboardButton,

            // states
            nodesState,
            edgesState,
            chosenNode,
            chosenContainerId,
            setChosenContainerId,
            controlPanel,
            setControlPanel,
            setAutoSave,
        }}>
            <ControlPanel chosenNode={chosenNode} isInline={true}/>
        </FlowContext.Provider>
    )
});
