import {createSlice} from "@reduxjs/toolkit";

const InvoiceReducer = createSlice({
    name: 'invoice',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillInvoiceList(state, action) {
            state.list = action.payload
        },
        setInvoiceItem(state, action) {
            state.item = action.payload;
        },
        setInvoiceListCount(state, action) {
            state.count = action.payload;
        },
        addInvoiceToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeInvoiceFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchInvoiceInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetInvoiceState(state) {
            state.item = {};
            state.list = [];
        },
    }
})

export default InvoiceReducer.reducer
export const {
    fillInvoiceList,
    setInvoiceItem,
    resetInvoiceState,
    setInvoiceListCount,
    patchInvoiceInList,
    addInvoiceToList,
    removeInvoiceFromList
} = InvoiceReducer.actions