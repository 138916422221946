import React from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"

import {Button, Tooltip} from 'antd';
import {routes} from "../../config/config";
import {l} from "../../library/locale";
import {AiOutlineCopy, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSetting} from "react-icons/ai";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../library/clipboard";
import FoldersList from "../../components/List/Table/FoldersList";

export const GlobListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        recordCreate,
    }
) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // navigate
    // const recordOpen    = (id) => navigate(`${routes.project_list}/${project.id}/${section}/${id}`)
    const recordEdit = (id) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${id}`)
    const recordOpen = (id) => recordEdit(id)
    // saga
    const recordCopy = (id) => dispatch({type: 'copyGlob', admin, id, project_id: project.id})
    const recordDelete = (id) => dispatch({type: 'deleteGlob', admin, id});
    const recordRestore = (id) => {
        let params = {id: id, project_id: project.id}
        dispatch({type: 'restoreGlob', admin, data: params})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'updateGlob', admin, data})
    }

    const columns = [
        {
            title: l('common.form.avatar'), // Аватар
            dataIndex: 'icon',
            className: 'cursor-pointer avatar',
            responsive: ['sm'],
            width: 70,
            render: (icon, record) => <TableAvatar icon={icon} isOn={record.status}/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: l('common.form.title.label'),
            className: 'cursor-pointer table-row-title title-link',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'value',
            title: l('common.form.name.label'),
            className: 'text-secondary max-width-270 text-ellipsis',
            width: 170,
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.value)
                };
            },
            render: (text, record) => (
                <Tooltip placement="topLeft" title={l('common.action.click_for_copy')}>
                    {text}
                </Tooltip>
            ),
        },
        {
            dataIndex: 'name',
            title: l('common.form.name.label'),
            className: 'text-secondary max-width-270',
            width: 170,
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.variable)
                };
            },
            render: (text, record) => (
                <Tooltip title={l('common.action.click_for_copy_var')}>
                    {record.name}
                </Tooltip>
            ),
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            className: 'drag-hide',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={record.status ? l('table.icon.off') : l('table.icon.on')}>
                        <Button
                            type="text"
                            onClick={() => recordChangeStatus(record.id, !record.status)}>
                            {record.status ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}>
                            <AiOutlineCopy/>
                        </Button>
                    </Tooltip>
                    {/*<Tooltip title={l('table.icon.settings')}>*/}
                    {/*    <Button type="text" onClick={() => {*/}
                    {/*        recordEdit(record.id)*/}
                    {/*    }}><AiOutlineSetting/></Button>*/}
                    {/*</Tooltip>*/}
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column drag-hide',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        // {
        //     key: 'tableMenuOpen',
        //     label: l('table.menu.open'),
        //     action: (record) => recordOpen(record.id)
        // },
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuStatus',
            label: l('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuCopy',
            label: l('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id, record.name)
        })
    }

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        // handle value and literal
        let value, variable;
        if (record.number) {
            value = record.number;
            variable = `{global:${record.name}.number}`
        } else if (record.value && !record.text) {
            value = JSON.stringify(record.value);
            variable = `{global:${record.name}.value}`
        } else {
            value = record.text ? record.text : '—';
            variable = `{global:${record.name}}`
        }

        data.push({
            id: record.id,
            key: record.id + '-listRow',
            icon: record.icon_name ? record.icon_name : 'user-plus',
            title: record.title,
            name: record.name,
            value: value,
            variable: variable,
            status: record.is_on,
            folder: record.folder_id,
            deleted: record.deleted_at > 0,
        });
    }

    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

