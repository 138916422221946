import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemData} from "../../library/containers";
import {
    deepGet,
    deepSet,
    deepCopyObject,
    objectLength,
} from "../../library/functions";
import {useTranslation} from "react-i18next";
import Preloader from "../System/Preloader";
import {FormTitle} from "../../components/Form/FormTitle";
import {FormFields} from "../../components/Form/Field/FormFields";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {handleFieldSpecValues} from "../../library/forms";
import {useHotkeys} from "react-hotkeys-hook";
import {dispatchTables, handleTemplateFields, removeDeprecatedField} from "../../library/effects";
import {notice} from "../../library/notice";

const {Content} = Layout;

const TemplateData = () => {
    const {t} = useTranslation();
    const section = 'template'
    const adminSection = 'template'

    // data from URL params
    const url_params = useParams()
    const id = Number(url_params.id)
    const project_id = Number(url_params.project_id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const store = useSelector(store => store)
    const {admin, project, template} = store;
    const project_item = project.item
    const item = template.item
    const isCreated = !item.parent_id;
    // const integration_items = createObjectFromIntegrations(t, integration.list);

    // itis form state
    let emptyFormValues = {}
    const [formValues, setFormValues] = useState({})
    const [formFields, setFormFields] = useState([])
    const [dataTables, setDataTables] = useState({})
    const [removeFieldName, setRemoveFieldName] = useState('')

    // get form and set values
    const [form] = Form.useForm()

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id)
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    // set field values to STATE if correct data received
    useEffect(() => {
        if (item && id && item.id === id) {
            // console.log('useEffect', item.id)
            // const fields = Object.values(item.logic ?? {});

            let values = item.values ? deepCopyObject(item.values) : {};
            let fields = deepCopyObject(item.logic) ?? [];

            handleTemplateFields(t, item, fields, values, isCreated, setDataTables, setRemoveFieldName, 'values.');

            const integration = deepGet(item, 'params.integration', {});
            if (integration['show']) {
                fields.push({
                    name: 'params.integration.id',
                    type: integration['single'] ? 'menu' : "tags",
                    data: 'integration',
                    required: !!integration['required'],
                    // values: integration_items,
                });
            }

            fields.push(
                // {
                //     type: 'heading',
                //     label: t('template.form.params.title'),
                //     desc: '',
                // },
                {
                    name: 'title',
                    type: "text",
                    desc: t('template.form.title.desc_user'),
                    placeholder: true,
                }
            );

            setFormFields(fields);
            setFormValues({values: values, params: item.params, title: item.title});
        }
        //eslint-disable-next-line
    }, [item.id])

    // set field values to FORM when correct data received
    useEffect(() => {
        // console.log('formValues', formValues)
        if (id && item.id === id) form.setFieldsValue(formValues)
        else form.setFieldsValue(emptyFormValues)
        //eslint-disable-next-line
    }, [item, formValues])

    // set field values to FORM when correct data received
    useEffect(() => {
        // console.log('Dispatch data tables:', dataTables)
        dispatchTables(dispatch, admin, project_id, dataTables)
        //eslint-disable-next-line
    }, [objectLength(dataTables)])

    useEffect(() => {
        removeDeprecatedField(formFields, setFormFields, formValues, setFormValues, removeFieldName, setRemoveFieldName)
    }, [removeFieldName])

    const saveData = (values) => {
        // console.log('saveData values', values);
        for (const name in values.values) {
            if (typeof values.values[name] === 'string' && values.values[name]) {
                try {
                    values.value = JSON.parse(values[name])
                } catch (e) {
                    // console.log(e)
                }
            }
        }

        let params = deepCopyObject(item.params);
        const integrationIds = deepGet(values, 'params.integration.id');
        if (deepGet(params, 'integration.show') && integrationIds) {
            // params.integration['id'] = integrationIds;
            params = deepSet(params, ['integration', 'id'], integrationIds);
            // console.log('params', params)
        }

        formCreateOrUpdate('Template',
            {values: values.values, params: params, title: values.title},
            formFields,
            id, project_item,
            admin, dispatch, true
        )
    }

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        saveData(values);
        backToList();
    }

    useHotkeys('ctrl+s, command+s', e => {
        e.preventDefault();
        const values = form.getFieldsValue();
        saveData(values);
        notice.success(t('common.status.request.sent'));
    });

    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${item.folder_id}`);
    }

    const renderFormFields = (formFields, formValues) => {
        let fields = [];
        for (const fieldInit of formFields) {
            fields.push(handleFieldSpecValues(t, store, fieldInit));
        }

        return (!id || item.id === id) && form.getFieldValue ?
            <FormFields
                t={t}
                form={form}
                admin={admin}
                section={section}
                project_id={project_item.id}
                fields={fields}
                formValues={formValues}
            /> : <Preloader/>
    }

    // console.log('formValues', formValues)

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    className=""
                    name="settings"
                    layout="vertical"
                >
                    <FormTitle onBack={backToList}>{item.title}</FormTitle>

                    <Content className="page-container form-container site-layout-background">
                        {formFields.length ? renderFormFields(formFields, formValues) : null}
                        <FormSubmit onCancel={backToList}/>
                    </Content>
                </Form>
            </Layout>
        </AppWrapper>
    )
}

export default TemplateData

