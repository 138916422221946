import {useEffect, useLayoutEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setLogOut} from "../../redux/reducers/AdminReducer";
import {useNavigate} from "react-router-dom";
import {removeItemStorage} from "../../library/functions";
import {routes} from "../../config/config";
import Preloader from "../System/Preloader";

const LogOut = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const admin = useSelector(store => store.admin)

    useEffect(() => {
        if (admin.authorized) {
            console.log('LogOut');

            removeItemStorage();
            dispatch(setLogOut());

            // setTimeout(() => {
            //     navigate(routes.auth);
            // }, 500);
        }

        //eslint-disable-next-line
    }, [admin.authorized])

    setTimeout(() => {
        if (!admin.authorized) navigate(routes.auth);
    }, 500)

    return <Preloader/>
}

export default LogOut;