import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addDialogMessageToList,
    addDialogToList,
    fillDialogList,
    patchDialogInList,
    removeDialogFromList,
    setDialogItem,
    setDialogListCount,
} from "../reducers/DialogReducer";

// init api methods
const base_path = backend_api.dialog;

function* getDialogList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillDialogList(response.data.result))
            yield put(setDialogListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getDialogItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get', arg.filters);
        if (response.data.ok) yield put(setDialogItem({
            list: response.data.result,
            count: response.data.count,
        }))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* sendDialogMessage(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/effect', 'post', arg.data);

        if (response.data.ok) yield put(addDialogMessageToList(response.data.result))
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createDialogRecord(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addDialogToList(response.data.result))
            yield put(setDialogItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateDialogRecord(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchDialogInList(response.data.result))
            yield put(setDialogItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyDialogRecord(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setDialogItem(response.data.result))
            yield put(addDialogToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreDialog(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeDialogFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteDialog(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeDialogFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* dialogSaga() {
    yield takeEvery('getDialogList', getDialogList);
    yield takeEvery('getDialogItem', getDialogItem);
    yield takeEvery('sendDialogMessage', sendDialogMessage);
    yield takeEvery('createDialogRecord', createDialogRecord);
    yield takeEvery('copyDialogRecord', copyDialogRecord);
    yield takeEvery('updateDialogRecord', updateDialogRecord);
    yield takeEvery('restoreDialog', restoreDialog);
    yield takeEvery('deleteDialog', deleteDialog);
}

