import React from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"

import {Button, Tooltip} from 'antd';
import {images, routes} from "../../../config/config";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import FoldersList from "../../../components/List/Table/FoldersList";
import {copyToClipboard} from "../../../library/clipboard";
import {useTranslation} from "react-i18next";
import {CopyOutlined, SettingOutlined, TrophyOutlined} from "@ant-design/icons";

const GameCommonListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        recordCreate,
    }
) => {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // navigate
    const recordOpen = (id) => navigate(`${routes.project_list}/${project.id}${routes.local.user_list}?gf_id=${id}`)
    const recordEdit = (id) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${id}`)
    const getRatingUrl = (name) => {
        return `${routes.project_list}/${project.id}/rating/${name}/1`
    }
    // const recordOpen = (id) => recordEdit(id)
    // saga
    const recordDelete = (id) => dispatch({type: 'deleteField', admin, filters: {id: id, project_id: project.id}})
    const recordCopy = (id) => dispatch({type: 'copyField', admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restoreField', admin, data: params})
    }
    const recordChangeAccess = (id, is_public) => {
        const access = is_public ? 'admin' : 'public';
        const params = {id: id, project_id: project.id, read_access: access}
        dispatch({type: 'updateField', admin, data: params})
    }

    const columns = [
        {
            title: t('common.form.avatar'), // Аватар
            dataIndex: 'image',
            className: 'cursor-pointer avatar',
            width: 70,
            render: (image) => <TableAvatar imageUrl={image} icon="cog"/>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordEdit(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: t('common.form.title.label'),
            className: 'cursor-pointer table-row-title title-link',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'name',
            title: t('common.form.name.label'),
            className: 'text-secondary max-width-270',
            width: 170,
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.variable),
                    onContextMenu: (ev) => copyToClipboard(record.name),
                };
            },
            render: (text, record) => (
                <Tooltip title={t('common.action.click_for_copy_var')}>
                    {record.name}
                </Tooltip>
            ),
        },
        {
            title: t('common.form.actions'),  // Действия
            key: 'actions',
            responsive: ['sm'],
            className: 'align-right',
            width: 155,
            render: (text, record) => (
                <div className="table-row-buttons">
                    {record.public ? <Tooltip title={t('field.table.icon.rating')}>
                        <Button
                            type="text"
                            href={getRatingUrl(record.name)}
                            target="_blank"
                            className="fix-icon-padding"
                            size="large"
                            icon={<TrophyOutlined/>}
                        />
                    </Tooltip> : null}
                    <Tooltip title={t('table.icon.duplicate')}>
                        <Button
                            type="text"
                            size="large"
                            onClick={() => recordCopy(record.id)}
                            icon={<CopyOutlined/>}
                        />
                    </Tooltip>
                    <Tooltip title={t('table.icon.settings')}>
                        <Button type="text" size="large" icon={<SettingOutlined/>} onClick={() => {
                            recordEdit(record.id)
                        }}/>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t('table.menu.edit'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuRating',
            action: (record) => recordChangeAccess(record.id, record.public),
            render: (record) => record.public ? t('field.table.menu.private') : t('field.table.menu.public')
        },
        {
            key: 'tableMenuCopy',
            label: t('table.menu.duplicate'),
            action: (record) => recordCopy(record.id),
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            image: record.photo_url ? record.photo_url : images.universe.sections[section],
            title: record.title,
            name: record.name,
            variable: '{field:'+record.name+'}',
            type: record.system_field_type,
            folder: record.folder_id,
            deleted: record.deleted_at > 0,
            public: record.read_access === 'public',
        });
    }

    return <FoldersList
        section={section}
        sectionSaga={'Field'}
        folderSaga={'Site'}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
        onDelete={recordDelete}
        onRestore={recordRestore}
    />
}

export default GameCommonListTable
