import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addMailToList,
    fillMailList,
    patchMailInList,
    removeMailFromList,
    setMailItemParam,
    setMailStoreParam,
    setMailItem,
    setMailId,
    setMailStat,
} from "../reducers/MailReducer";

// init api methods
const base_path = backend_api.mail;

function* getMailList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillMailList(response.data.result))
            yield put(setMailStoreParam({count: response.data.count}))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getMailItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) {
            const mail = response.data.result
            yield put(setMailItem(mail))
            // yield put(setMailId(mail.id))
            yield put(setMailStoreParam({loading: false, status: 'opened'}));
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getMailStat(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/stat/' + arg.id, 'get');
        if (response.data.ok) {
            const mail = response.data.result
            if (!arg.listOnly) yield put(setMailItemParam({status: mail.status, stat: mail.stat}))
            yield put(patchMailInList(mail))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getMailFiltersStat(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/filters', 'post', arg.data);
        if (response.data.ok) {
            yield put(setMailStat(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* runMailAction(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/action', 'patch', arg.data);
        if (response.data.ok) {
            const mail = response.data.result
            yield put(setMailItemParam({status: mail.status}))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createMail(arg) {
    try {
        yield put(setMailStoreParam({loading: true}));
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            const mail = response.data.result
            yield put(addMailToList(mail))
            yield put(setMailItem(mail))
            yield put(setMailId(mail.id))

            yield put(setMailStoreParam({loading: false, status: 'saved'}));
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateMail(arg) {
    try {
        yield put(setMailStoreParam({loading: true}));
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            const mail = response.data.result
            yield put(patchMailInList(mail))
            yield put(setMailItem(mail))

            yield put(setMailStoreParam({loading: false, status: 'saved'}));
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyMail(arg) {
    try {
        yield put(setMailStoreParam({loading: true}));
        const response = yield request(arg.admin.token, base_path + '/clone', 'post', arg.data);
        if (response.data.ok) {
            const mail = response.data.result
            yield put(setMailId(mail.id))
            yield put(addMailToList(mail))
            yield put(setMailItem(mail))

            yield put(setMailStoreParam({loading: false, status: 'saved'}));
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreMail(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeMailFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteMail(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeMailFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* mailSaga() {
    yield takeEvery('getMailList', getMailList);
    yield takeEvery('getMailItem', getMailItem);
    yield takeEvery('createMail', createMail);
    yield takeEvery('copyMail', copyMail);
    yield takeEvery('updateMail', updateMail);
    yield takeEvery('restoreMail', restoreMail);
    yield takeEvery('deleteMail', deleteMail);
    yield takeEvery('getMailStat', getMailStat);
    yield takeEvery('getMailFiltersStat', getMailFiltersStat);
    yield takeEvery('runMailAction', runMailAction);
}

