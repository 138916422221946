import React from 'react';
import Fa from "../../Icon/Fa";
import {useTranslation} from "react-i18next";

const platform_icons_names = {
    tg: 'telegram'
}

export const LabelPlatformsIcons = ({platforms, className = ''}) => {
    const {t} = useTranslation();


    let result = []
    for (const platform of platforms) {
        const icon_name = platform_icons_names[platform] ?? platform;
        result.push(<span key={icon_name} title={t('common.form.platform.tooltip') + ': ' + t(`integration.${platform}.title`)}>
            <Fa icon={icon_name} set="brands" />
        </span>)
    }
    return <div className={`label-platform-icons ${className}`}>{result}</div>
};
