import {createSlice} from "@reduxjs/toolkit";

const ProductCategoryReducer = createSlice({
    name: 'productCategory',
    initialState: {
        list: [],
        item: {},
        count: 0,
        sorter: null,
    },
    reducers: {
        fillProductCategoryList(state, action) {
            state.list = action.payload
        },
        setProductCategoryItem(state, action) {
            state.item = action.payload;
        },
        setProductCategoryStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        setProductCategoryListCount(state, action) {
            state.count = action.payload;
        },
        addProductCategoryToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeProductCategoryFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchProductCategoryInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetProductCategoryState(state) {
            state.item = {};
            state.list = [];
            state.count = 0;
            state.sorter = null;
        },
    }
})

export default ProductCategoryReducer.reducer
export const {
    fillProductCategoryList,
    setProductCategoryItem,
    resetProductCategoryState,
    setProductCategoryListCount,
    patchProductCategoryInList,
    addProductCategoryToList,
    removeProductCategoryFromList,
    setProductCategoryStoreParam,
} = ProductCategoryReducer.actions