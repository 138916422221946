import React, {useContext, useEffect, useState} from 'react'
import update from "immutability-helper";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import classes from "./Keyboard.module.scss";
import {KeyboardButton} from "./KeyboardButton";
import {KeyboardEmptyRow} from "./KeyboardEmptyRow";
import {KeyboardAddButton} from "./KeyboardAddButton";
import KeyboardModal from "./KeyboardModal";
import FlowContext from "../../../../../FlowContext";
import {findRelatedNodeData} from "../../../../../library/flowFunctions";
import {deepGet, inArray} from "../../../../../../../library/functions";

export const ItemTypes = {
    button: 'button',
    row: 'row',
}

export const eventButtonTypes = ['text', 'url', 'request'];

export const composeButtonCard = (
    values = {},
    label = '',
    type = 'text',
    color = 'primary',
    spec = {
        url: '',
        app: '',
        object: 'contact',
        params: '',
    },
    payload = '',
) => {
    return {id: 0, label, type, color, spec, payload, ...values}
}

const defaultCards = [
    [
        {
            id: 1,
            truePorts: [1],
            label: "Button 1",
            type: "text",
            color: "primary",
            spec: {},
            payload: "Button 1"
        },
        {
            id: 2,
            truePorts: [2],
            label: "Button 2",
            type: "url",
            color: "success",
            spec: {},
            payload: "Button 2"
        },
        {
            id: 3,
            truePorts: [3],
            label: "Button 3",
            type: "text",
            color: "danger",
            spec: {},
            payload: "Button 3"
        },
    ],
    [
        {
            id: 4,
            truePorts: [4],
            label: "Button 4",
            type: "text",
            color: "default",
            spec: {},
            payload: "Button 4"
        },
    ]
]

const Keyboard = ({localId, onChange, initValue = []}) => {
    const {
        chosenNode,
        newLocalIdNext,
        createButtonSystemNodes,
        addKeyboardButton,
        editKeyboardButton,
        removeKeyboardButton
    } = useContext(FlowContext);

    const [isDragging, setDragging] = useState(false)
    const [chosenCard, setChosenCard] = useState(null) // {index: 0, rowIndex: 0}
    const [cards, setCards] = useState(initValue)

    // useEffect(() => {
    //     // this is deleting buttons on inputs edit
    //     setCards(initValue)
    // }, [initValue]);

    useEffect(() => {
        onChange(cards)
    }, [cards]);

    const onRowDelete = (rowIndex) => {
        const newRows = update(cards, {
            $unset: [rowIndex]
        }).filter(item => item)

        // console.log('onDelete row', rowIndex, newRows)
        setCards(newRows)
    }

    const isCardHasEvents = (node, card) => {
        let result = node && node.type === 'message' && inArray(card.type, eventButtonTypes);
        if (card.type === 'url' && deepGet(card, 'spec.turn_off_event') === true) result = false;
        // console.log('isCardHasEvents', result, card, node);
        return result;
    }

    const addButtonCard = (values = {}) => {
        const localId = newLocalIdNext();
        const card = composeButtonCard({...values, id: localId})
        const newCardRow = [card]
        // console.log('addButtonCard', newCardRow)
        setCards(update(cards, {$push: [newCardRow]}))

        if (isCardHasEvents(chosenNode, card)) {
            // const relativeNode = deepGet(chosenNode, 'ui.relative.reactions.event')
            const relatedNode = findRelatedNodeData(chosenNode, 'reactions', 'event')
            if (!relatedNode) createButtonSystemNodes(localId, card)
            else addKeyboardButton(localId, card)
        }
    }

    const editButtonCard = (values) => {
        // console.log('editButtonCard values', values)
        // editing
        if (chosenCard.id && cards[chosenCard.rowIndex]) {
            const card = {...cards[chosenCard.rowIndex][chosenCard.index], ...values}
            setCards(update(cards, {[chosenCard.rowIndex]: {[chosenCard.index]: {$set: card}}}))
            if (isCardHasEvents(chosenNode, card)) editKeyboardButton(chosenCard.id, card);
            setChosenCard(null);
        }
        // creating
        else addButtonCard(values)
    }

    const deleteButtonCard = () => {
        if (!chosenCard) return
        const index = chosenCard.index
        const rowIndex = chosenCard.rowIndex

        setChosenCard(null) // close modal first

        let newCards = [...cards]
        const newRow = update(newCards[rowIndex], {$unset: [index]}).filter(item => item)
        newCards = update(cards, {[rowIndex]: {$set: newRow}}).filter(row => row.length)
        setCards(newCards)

        const card = cards[rowIndex][index]
        if (isCardHasEvents(chosenNode, card)) {
            removeKeyboardButton(chosenCard.id, newCards, localId)
        }
    }

    const showModal = () => {
        setChosenCard({id: 0})
    }

    const onButtonClick = (card, index, rowIndex) => {
        try {
            const stateCardId = cards[rowIndex][index].id;
            if (stateCardId !== card.id) return

            // console.log('onButtonClick', index, rowIndex, card)
            setChosenCard({id: card.id, index, rowIndex})
        } catch (e) {
            console.warn('onButtonClick:', 'stateCard not found')
        }
    }

    const keyboardRenderer = (cards, setCards, isDragging, setDragging, chosenCard, setChosenCard, newLocalIdNext) => {
        // it is important to be inside function (I think)
        const moveCard = (id, oldIndex, oldRow, newIndex, newRow) => {
            // console.log('moveCard', {oldIndex, newIndex, oldRow, newRow})

            let newCards = [...cards]
            // const draggedCardNew = cards[newRow].find(item => item && item.id === id) // for long dragging to new row
            const draggedCardOld = cards[oldRow] ? cards[oldRow].find(item => item && item.id === id) : undefined;

            if (oldRow === newRow) {
                const draggedCard = cards[newRow][oldIndex]
                newCards[newRow] = update(cards[newRow], { // arrayMove function does not help
                    $splice: [
                        [oldIndex, 1],
                        [newIndex, 0, draggedCard],
                    ],
                })
                // console.log('newRowCards', oldIndex, newIndex, newCards[newRow].map(item => item ? item.id : null))
            } else if (draggedCardOld) {
                const draggedCard = cards[oldRow].find(item => item && item.id === id)
                newCards[oldRow] = newCards[oldRow].filter(item => item && item.id !== id)

                if (cards[newRow]) {
                    newCards[newRow] = update(cards[newRow], {
                        $push: [draggedCardOld],
                    })
                } else {
                    newCards.push([draggedCardOld])
                }

                const lastNewRowIndex = newCards[newRow].length - 1

                newCards[newRow] = update(newCards[newRow], {
                    $splice: [
                        [lastNewRowIndex, 1],
                        [newIndex, 0, draggedCardOld]
                    ],
                })
                // console.log('oldRowCards', newCards[oldRow].map(item => item ? item.id : null))
            }

            newCards = newCards.filter(row => row.length) // todo: replace to onDrop (end)
            // console.log('newCards', newCards)
            setCards(newCards)
        }

        const renderCard = (card, index, rowIndex) => {
            if (!card) return
            return (
                <KeyboardButton
                    key={'drag-button-' + card.id}
                    id={card.id}
                    card={card}
                    index={index}
                    rowIndex={rowIndex}
                    moveCard={moveCard}
                    setDragging={setDragging}
                    onButtonClick={onButtonClick}
                />
            )
        }

        const renderEmptyRow = (rowIndex) => {
            return (
                <KeyboardEmptyRow
                    index={0}
                    rowIndex={rowIndex}
                    moveCard={moveCard}
                    onDelete={onRowDelete}
                />
            )
        }

        return <div className={classes.wrapper}>
            {cards.map((rowCards, rowIndex) => {
                return <div key={'drag-row-' + rowIndex} className={classes.row}>
                    {rowCards.length ?
                        rowCards.map((card, index) => renderCard(card, index, rowIndex)) :
                        renderEmptyRow(rowIndex)
                    }
                </div>
            })}
            {isDragging ? renderEmptyRow(cards.length) : <KeyboardAddButton onClick={showModal}/>}
            <KeyboardModal
                cards={cards}
                chosenCard={chosenCard}
                setChosenCard={setChosenCard}
                onModalOk={editButtonCard}
                onDelete={deleteButtonCard}
            />
        </div>
    }

    return (
        <DndProvider backend={HTML5Backend}>
            {keyboardRenderer(
                cards,
                setCards,
                isDragging,
                setDragging,
                chosenCard,
                setChosenCard,
                newLocalIdNext
            )}
        </DndProvider>
    )
}

export default Keyboard