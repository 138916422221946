import {t} from "i18next";
import {notice} from "./notice";
import {deepGet} from "./functions";

export const apiFalseNotice = (response) => {
    if (deepGet(response, 'data.error.const')) {
        notice.error(t(response.data.error.const))
    }
    // backend error message
    else if (deepGet(response, 'data.error.message')) {
        notice.error(response.data.error.message)
    }
    else if (response) {
        notice.error(response)
    }
}

export const apiErrorNotice = (error) => {
    // locale constant
    if (error.response){
        const contentType = error.response.headers['content-type'];
        if (contentType && contentType.includes('xml')) {
            // Handle XML response
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(error.response.data, 'text/xml');
            const errorMessage = xmlDoc.getElementsByTagName('Message')[0].textContent;
            notice.error(errorMessage);

        } else if (deepGet(error.response, 'data.error.const')) {
            // console.log('error.const', error)
            notice.error(t(error.response.data.error.const))
        }
        else if (error.response.status===401) {
            notice.error(t('error.login.required'))
            // TODO: navigate to /auth
        }
        else if (deepGet(error.response, 'data.error.details') && error.response.data.error.details[0].type === 'value_error.any_str.min_length') {
            notice.error(t('validation.'+error.response.data.error.details[0].type))
        }
        // locale constant from validation error
        else if (deepGet(error.response, 'data.error.details.0.loc.1')) {
            notice.error(t('error.validation.request') + ': ' + error.response.data.error.details[0].loc[1])
        }
        else if (deepGet(error.response, 'data.error.details.0.type')) {
            notice.error(t('validation.'+error.response.data.error.details[0].type))
        }
        // backend error message
        else if (deepGet(error.response, 'data.error.message')) {
            notice.error(error.response.data.error.message)
        }
        else if (error.response.status === 500) {
            notice.error(t('error.server'))
        }
        // frontend error details
        else if (error.response) {
            // console.log('error.response', error.response)
            notice.error(`${error.response.status}: ${error.response.statusText}`)
        }
    }
    // frontend other error
    else {
        console.log('frontend other error', error)
        notice.error(`${error}`)
    }

    // redirect to login if not authorized
    // if (error.response.status===401){
    //     const navigate = useNavigate()
    //     navigate(routes.auth)
    // }
}