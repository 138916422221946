import React, {useContext, useEffect, useState} from 'react'
import classes from './ContainerList.module.scss'
import EventContainer from "../Container/EventContainer";
import ConditionContainer from "../Container/ConditionContainer";
import Container from "../Container/Container";
import AddContainerButtons from "../AddContainerButtons/AddContainerButtons";
import {useDispatch, useSelector} from "react-redux";
import FlowContext from "../../../../FlowContext";
import {inArray, parseBool} from "../../../../../../library/functions";
import {findAndDispatchData} from "../../../../../../library/effects";
import {conditionEffectTypes, graph_jump_effect} from "../../../../../../schemas/frontend/effects";
import EventModeSelect from "./EventModeSelect";

const ComponentTypes = {
    event: <EventContainer/>,
    condition: <ConditionContainer/>,
    action: <Container/>,
    message: <Container/>,
    timer: <Container/>,
}

const ContainerList = () => {
    const dispatch = useDispatch();
    const {chosenNode, isBlock} = useContext(FlowContext);
    const {admin, project} = useSelector(store => store);
    // const [dataTables, setDataTables] = useState({});
    const [containers, setContainersList] = useState([]);

    useEffect(() => {
        // console.log('ContainerList useEffect', chosenNode)
        if (chosenNode.localId) composeNodeContainers();
        else setContainersList([])
        return () => setContainersList([])
        //eslint-disable-next-line
    }, [chosenNode])  // for other block and fields

    // useEffect(() => {
    //     dispatchTables(dataTables)
    //     //eslint-disable-next-line
    // }, [dataTables])

    const findAndDispatchConditionsData = (conditions) => {
        if (conditions) {
            for (const condition of conditions) {
                findAndDispatchData(dispatch, admin, project.item.id, condition.type, condition.spec);
            }
        }
    }

    const composeNodeContainers = () => {
        if (chosenNode) {
            let nodeType = chosenNode.type;
            const containers = [];
            let index = 0;

            const nodeContent = (nodeType === 'event') ? chosenNode.events : chosenNode.effects;
            if (!nodeContent) return null;

            for (const effect of nodeContent) {
                // for effects with conditions
                let nodeTypeResult = nodeType;

                if (effect.type === graph_jump_effect.type) continue;
                // else if (nodeType === 'event' && condition_jump_effect.type) continue;

                let conditions = [];
                let conditionsEffect = {};

                // find conditions for event container
                if (effect.filterEffect) {
                    conditionsEffect = chosenNode.effects.find(filter => filter.localId === effect.filterEffect);
                    if (conditionsEffect) conditions = conditionsEffect.spec.conditions;
                }

                // find conditions for cond container
                if (inArray(effect.type, conditionEffectTypes)) {
                    conditions = effect.spec.conditions;
                    if (nodeTypeResult !== 'condition') nodeTypeResult = 'condition';
                }

                // handle any conditions
                if (conditions.length) findAndDispatchConditionsData(conditions);

                index++;
                const key = `${nodeType}_${effect.localId}_${index}`;
                const props = {
                    nodeLocalId: chosenNode.localId,
                    localId: effect.localId,
                    title: effect.title,
                    type: effect.type,
                    spec: effect.spec,
                    isIgnore: parseBool(effect.ignore),
                    isCollapsed: parseBool(effect.collapsed),
                    containerIndex: index,

                    // for events and conditions
                    filterEffect: conditionsEffect,
                    conditions: conditions,
                };

                // dispatch menu and tags lists from DB
                findAndDispatchData(dispatch, admin, project.item.id, effect.type, effect.spec);

                const effectContainer = ComponentTypes[nodeTypeResult];
                if (effectContainer) containers.push({...effectContainer, key, props});
            }
            setContainersList(containers);
            // return containers;
        }
    };

    return <>
        <div id={isBlock ? 'effects-list-' + chosenNode.localId : 'effects-list-wrapper'} className={isBlock ? null : classes.effects_list_wrapper}>
            <div className="container-list">
                {chosenNode.type === 'event' ? <EventModeSelect />: null}
                {containers}
                {/*{composeNodeContainers()}*/}
            </div>
        </div>
        <AddContainerButtons chosenNode={chosenNode}/>
    </>
}

export default ContainerList