import React, {useState, useEffect} from "react";
import {Card, PageHeader, Layout, Button, Form} from "antd";
import {Collapse} from "react-collapse/lib/Collapse";
import {FilterOutlined} from "@ant-design/icons";
import {l} from "../../library/locale";
import {convertUnixParamsToMoment, isArr, objectLength, unixFromMoment} from "../../library/functions";
import {AiOutlineCaretDown, AiOutlineCaretRight} from "react-icons/ai";
import {CollapseFilter} from "../Collapse/CollapseFilter";
import {SearchBox} from "../Search/SearchBox";

const {Header} = Layout;

export const utmFields = [
    [
        {
            name: 'sources',
            type: 'tags',
            features: ['adding'],
        },
        {
            name: 'mediums',
            type: 'tags',
            features: ['adding'],
        },
        {
            name: 'campaigns',
            type: 'tags',
            features: ['adding'],
        },
        {
            name: 'contents',
            type: 'tags',
            features: ['adding'],
        },
        {
            name: 'terms',
            type: 'tags',
            features: ['adding'],
        },
        {
            name: 'groups',
            type: 'tags',
            features: ['adding'],
        },
    ]
]

const utmFiltersDefault = [
    {
        name: 'utm_tail',
        type: 'text',
        placeholder: 'ref=affiliate&partner=123',
    },

]

export const CustomHeader = (
    {
        title,
        className,
        actions,
        section = '',
        filters = null,
        setFilters = null,
        addUtms = false,
        searchHandler = null,
        toggleFiltersStatus = null,
        searchPlaceholder = null,
        filterFields = [],
        isFiltersOpened = false,
        showMobileFilterButtons = false
    }
) => {
    const utmFilters = addUtms ? [...utmFields[0], ...utmFiltersDefault] : [];
    if (section === 'user') utmFilters.push({
        name: 'utm_visited',
        type: 'date-range'
    })

    const [filtersForm] = Form.useForm();
    const [utmsForm] = Form.useForm();

    const [isSectionOpened, setSectionStatus] = useState(true);
    const toggleSectionStatus = () => setSectionStatus(!isSectionOpened);

    const [isUtmsOpened, setUtmsStatus] = useState(false);
    const toggleUtmsStatus = () => setUtmsStatus(!isUtmsOpened);

    useEffect(() => {
        // console.log('filters', filters);
        if (filters) {
            let utms = {};
            let values = {...filters};

            if (addUtms) {
                filtersForm.resetFields();

                for (const field of utmFilters) {
                    const val = values[field.name]
                    if (val !== undefined) utms[field.name] = isArr(val) ? val : [val];
                    delete values[field.name];
                }

                if (objectLength(utms)) {
                    utmsForm.setFieldsValue(utms);
                    setUtmsStatus(true);
                }
            }

            filtersForm.resetFields();

            if (objectLength(values)) {
                const filtersResult = convertUnixParamsToMoment(values)
                filtersForm.setFieldsValue(filtersResult)
            } else if (objectLength(filters)) setSectionStatus(false)
        }
    }, [filters])

    const onApply = () => {
        let filters_result = {}
        const values = filtersForm.getFieldsValue();

        for (let [field, value] of Object.entries(values)) {
            if (value) {
                if (value._isAMomentObject) value = unixFromMoment(value) // if date -> convert to UTC
                filters_result[field] = value
            }
        }

        // add utms
        if (addUtms) {
            const utms = utmsForm.getFieldsValue();
            for (let [field, value] of Object.entries(utms)) {
                if (value) {
                    if (value._isAMomentObject) value = unixFromMoment(value) // if date -> convert to UTC
                    filters_result[field] = value
                } else if (filters_result[field]) delete filters_result[field]
            }
        }

        // for search text field
        if (filters && filters.title) filters_result.title = filters.title

        // get filtered list
        setFilters(filters_result)
    }

    return (
        <Header className="site-layout-transparent page-container-horizontal-padding has-banner">
            <PageHeader
                title={title}
                className={className}
                extra={actions}
            />

            {showMobileFilterButtons ? <div className="header-filter-buttons-xs visible-xs-inline">
                <SearchBox
                    searchHandler={searchHandler}
                    searchPlaceholder={searchPlaceholder}
                    showInputDefault={true}
                    className="float-left"
                    wrapperClasses="float-left margin-top-xxs"
                    showCloseButton={false}
                />
                <Button
                    key="header-filters-button-xs"
                    type="text"
                    className={(isFiltersOpened || objectLength(filters)) ? 'active float-right' : 'float-right'}
                    onClick={toggleFiltersStatus}
                    icon={<FilterOutlined/>}
                >
                    {l('page_header.buttons.filter.title')} {/*active ˆ = filters open*/}
                </Button>
            </div> : null}

            {filters ? <>
                <Collapse isOpened={isFiltersOpened}>
                    <div className="padding-bottom-pm">
                        <Card
                            type="inner"
                            className="filters collapsible"
                            title={(
                                <div className="filter-panel-header cursor-pointer"
                                     onClick={toggleSectionStatus}>
                                    {isSectionOpened ? (<AiOutlineCaretDown/>) : (
                                        <AiOutlineCaretRight/>
                                    )} {l('page_header.filters.panel')}
                                </div>
                            )}
                        >
                            <Collapse isOpened={isSectionOpened}>
                                <CollapseFilter
                                    form={filtersForm}
                                    onFinish={onApply}
                                    filterFields={filterFields}
                                />
                            </Collapse>
                        </Card>
                    </div>
                </Collapse>

                {addUtms ? <Collapse isOpened={isFiltersOpened}>
                    <div className="padding-bottom-pm">
                        <Card
                            type="inner"
                            className="filters collapsible"
                            title={(
                                <div className="filter-panel-header cursor-pointer"
                                     onClick={toggleUtmsStatus}>
                                    {isUtmsOpened ? (<AiOutlineCaretDown/>) : (
                                        <AiOutlineCaretRight/>
                                    )} {l('page_header.filters.utm')}
                                </div>
                            )}
                        >
                            <Collapse isOpened={isUtmsOpened}>
                                <CollapseFilter
                                    form={utmsForm}
                                    onFinish={onApply}
                                    filterFields={[utmFilters]}
                                />
                            </Collapse>
                        </Card>
                    </div>
                </Collapse> : null}
            </> : null}
        </Header>
    )
}