import React, {useEffect, useState} from 'react'
import {Layout} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dotenv, routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Banner} from "../../components/Banner/Banner";
import {l} from "../../library/locale";
import {AiOutlineContainer, AiOutlineInbox, AiOutlineShop, AiOutlineStar, AiOutlineTrophy} from "react-icons/ai";
import {Content} from "antd/es/layout/layout";
import {CollapseAccordion} from "../../components/Collapse/CollapseAccordion";
import CardAccount, {ServiceDescription} from "../../components/Card/CardAccout";
import CardProgress from "../../components/Card/CardProgress";
import {setUserField} from "../../redux/reducers/UserFieldReducer";
import {AchievementsCards} from "../../components/Card/AchievementsCard/AchievementsCards";
import {orderObjectsByFieldValues} from "../../library/functions";

const user_fields = [
    'status','bio','about', // profile
    'all_sections','firstshopping','millionmessages', // achievements
]

const AdminItem = () => {

    const [bio, setBio] = useState('')
    const [about, setAbout] = useState('')
    const [status, setStatus] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const goToEdit = (e) => {
        e.preventDefault()
        navigate(routes.admin.settings);
    }

    // sync with store
    const {admin, userField} = useSelector(store => store)
    const item = admin.user
    window.store = admin;

    let achievements = []
    for (const [key, field] of Object.entries(userField)) {
        if (field.system_field_type === 'achievement') achievements.push(field)
    }
    achievements = orderObjectsByFieldValues(achievements, 'id')

    const collapse_account_items = [
         {
            title: l('account.collapse.description_services'),   // Описание услуг
            collapseIcon: AiOutlineContainer(),
            content: <ServiceDescription about={about}/>
        },
        // {
        //     title: l('account.collapse.achievements'),           // Достижения
        //     collapseIcon: AiOutlineTrophy(),
        //     content: <AchievementsCards achievements={achievements}/>
        // },
        // {
        //     title: l('account.collapse.certificates'),           // Сертификаты
        //     collapseIcon: AiOutlineStar(),
        //     content: " "
        // },
        // {
        //     title: l('account.collapse.cases'),                 // Кейсы
        //     collapseIcon: AiOutlineInbox(),
        //     content: " "
        // },
        // {
        //     title: l('account.collapse.templates'),             // Шаблоны
        //     collapseIcon: AiOutlineShop(),
        //     content: " "
        // }
    ]

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized) dispatch({type: 'getAdminItem', admin}); // get data once
        //eslint-disable-next-line
    }, [admin.authorized])

    // editor init values
    // const [editorValue, setEditorValue] = useState('');
    useEffect(() => {
        if (admin.authorized) {
            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: user_fields,
                }
            })
        }
        //eslint-disable-next-line
    }, [admin.authorized])

    useEffect(() => {
        if (userField) {
            for (let key in userField) {
                if (key === 'bio') {
                    setBio(userField.bio.value)
                }
                if (key === 'status') {
                    setStatus(userField.status.value)
                }
                if (key === 'about') {
                    setAbout(userField.about.value)
                }
            }
        }

    }, [userField])

    const changeStatus = () => {
        // init field post params
        const newUserFieldData = {
            field_name: 'status',
            value: !status
        }

        // save to store by Reducer
        dispatch(setUserField(newUserFieldData))

        // save to backend by Saga
        dispatch({
            type: 'storeUserField',
            admin,
            data: {
                project_id: dotenv.main_project,
                user_id: item.id,
                ...newUserFieldData
            }
        })
    }


//     useEffect(() => {
//         if (!admin.authorized) return navigate(routes.account)        // юзер нажал CTRL+F5 или F5 - уходим по адресу этой же страницы. роутинг сам проверит, авторизован пользователь или нет. и отправит его снова сюда или на авторизацию
// //eslint-disable-next-line
//     }, [admin])

    return (
        <AppWrapper className={"bg-gray-light"}>
            <Layout className="site-layout site-layout-background account-item">
                <Banner image="ship"/>
                <Content className="page-container site-layout-background">

                    <CardAccount
                        item={item}
                        userField={userField}
                        status={status}
                        about={about}
                        bio={bio}
                        goToEdit={goToEdit}
                        changeStatus={changeStatus}
                    />

                    {/*<CardProgress/>*/}

                    <CollapseAccordion items={collapse_account_items}/>

                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default AdminItem