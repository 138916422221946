import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import classes from './NodeTitle.module.scss'
import {Input} from "antd";
import FlowContext from "../../../../FlowContext";
import {fieldsHotkeys} from "../../../../../../components/Form/Field/FormFields";
import {changeGraphStatus} from "../../../../../../redux/reducers/GraphReducer";

const {TextArea} = Input;
const title_max_length = 2048;

const NodeGraphTitle = ({chosenNode}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {updateNodeTitle} = useContext(FlowContext);
    const [text, setText] = useState('');

    useEffect(() => {
        setText(chosenNode.text);
    }, [chosenNode.text]);

    const onBlur = () => {
        updateNodeTitle(chosenNode.localId, text);
    }

    const onKeyDown = (e) => {
        fieldsHotkeys(e);
    }

    const onChangeText = (e) => {
        setText(e.target.value);
        dispatch(changeGraphStatus({edited: true, redrawn: true}));
    };

    if (chosenNode) {
        return (
            <div className={classes.node_graph_title_wrapper}>
                <TextArea
                    bordered={false}
                    value={text}
                    data-value={text}
                    placeholder={t('common.form.block_title.placeholder')}
                    maxLength={title_max_length}
                    autoSize={{minRows: 1, maxRows: 20}}
                    onBlur={onBlur}
                    onChange={onChangeText}
                    onKeyDown={onKeyDown}
                />
            </div>
        )
    } else return null;
}

export default NodeGraphTitle