import React from 'react'
import './Preloader.scss'
import styled from "styled-components";

const ContainerPreloader = styled.div
    `padding: 10% 30%;`

const Preloader = () => {
    return (
        <ContainerPreloader>
            <div className="loader">Loading...</div>
        </ContainerPreloader>
    )
}

export default Preloader