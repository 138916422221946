import React, {useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {useDispatch} from "react-redux";
import {routes} from "../../config/config";
import {l} from "../../library/locale";
import {AiOutlineCopy, AiOutlineSetting} from "react-icons/ai";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import FoldersList from "../../components/List/Table/FoldersList";

const {Text} = Typography;

export const GraphListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        recordCreate,
    }
) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // navigate
    const recordOpen = (id) => navigate(`${routes.project_list}/${project.id}/${section}/${id}`)
    const recordEdit = (id) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${id}`)

    // saga
    const recordDelete = (id) => dispatch({type: 'deleteGraph', admin, id})
    const recordCopy = (id) => dispatch({type: 'copyGraph', admin, id})
    const recordRestore = (id) => {
        const data = {id: id, project_id: project.id}
        dispatch({type: 'restoreGraph', admin, data})
    }

    const downloadGraph = (id) => {
        dispatch({type: 'exportGraphLogic', admin, id})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'updateGraph', admin, data})
    }

    const columns = [
        {
            title: l('common.form.avatar'), // Аватар
            dataIndex: 'icon',
            className: 'cursor-pointer avatar',
            width: 70,
            responsive: ['sm'],
            render: (icon, record) => <TableAvatar icon={icon} isOn={record.status}/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: l('common.form.name.label'),
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: l('common.form.name.label'),
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['xs'],
            render: (value, record) => {
                return <div>{record.status ?
                    <Text>{value}</Text> :
                    <Text type="danger">{value}</Text>}
                </div>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            title: l('common.form.status'),
            dataIndex: 'status',
            className: 'cursor-pointer',
            responsive: ['sm'],
            width: 150,
            render: (status) => {
                return <Tooltip title={l(section + '.list.table.menu.status.desc')}>{status ?
                    <Text>{l('common.status.neuter.on')}</Text> :
                    <Text type="danger">{l('common.status.neuter.off')}</Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordChangeStatus(record.id, !record.status)
                };
            },
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            className: 'drag-hide',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}>
                            <AiOutlineCopy/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('table.icon.settings')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record.id)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column drag-hide',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuOpen',
            label: l('table.menu.open'),
            action: (record) => recordOpen(record.id)
        },
        {
            key: 'tableMenuStatus',
            label: l('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuExport',
            label: l('table.menu.export'),
            action: (record) => downloadGraph(record.id)
        },
        {
            key: 'tableMenuCopy',
            label: l('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            icon: record.icon_name ? record.icon_name : 'square-terminal',
            title: record.title,
            status: record.is_on,
            folder: record.folder_id,
            deleted: record.deleted_at > 0,
        });
    }

    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

