import React, {useEffect} from 'react'
import Preloader from "./Preloader";
import {useSelector} from "react-redux";
import {redirect, navigate} from "../../library/functions";

const AfterReg = ({toURL}) => {
    const {admin} = useSelector(store => store)

    useEffect(() => {
        if (admin.authorized && admin.correct && !admin.logging) {
            setTimeout(() => {
                if (toURL.startsWith('/')) navigate(toURL)
                else redirect(toURL)
            }, 1000)
        }
        //eslint-disable-next-line
    }, [admin])

    return <Preloader/>
}

export default AfterReg