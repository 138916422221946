import React, {useEffect, useState} from 'react';
import {backend_api} from "../../../config/config";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import {Modal, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {getBase64, uploadFilePreHandle} from "../../../library/forms";
import {deepSet} from "../../../library/functions";
import {getName} from "./FormFields";
import {AiOutlineUpload} from "react-icons/ai";

export const PhotoField = (
    {
        t,
        project_id = 0,
        admin = {},
        label = '',
        name = 'photos',
        siteSection = 'temp',
        imageType = 'photo',
        // initialValue = '',
        value = '',
        onFileUpload = null,
        onChange = null,
        limit = 1,
        aspect = 0,
    }
) => {
    // console.log('project_id', project_id);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);

    // const fileList = value ? [{url: value}] : [];
    // console.log('fileList', fileList, '\ninitialValue', initialValue, '\nvalue', value);

    useEffect(() => {
        // console.log('initialValue', initialValue);
        if (value) setFileList([{url: value}]);
        else setFileList([]);
    }, [value]);

    // useEffect(() => {
    //     console.log('filesInit useEffect:', filesInit);
    //     // for ant form field value
    //     if (filesInit && filesInit.filelist && filesInit.filelist.length) {
    //         setFileList(filesInit.filelist);
    //     }
    //     // for backend spec
    //     else if (typeof filesInit === 'string' && filesInit) {
    //         setFileList([{url: filesInit}])
    //     }
    //     // normal array mode
    //     else if (Array.isArray(filesInit)) {
    //         setFileList(filesInit);
    //     }
    // }, []);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const storeImageUrl = (url) => {
        // console.log('store image url')
        if (onChange) onChange(url);
        if (onFileUpload) onFileUpload(deepSet({}, getName(name), url));
    };

    const onRemove = (file) => {
        // const newFileList = fileList.filter((item) => item.status === 'removed')
        // setFileList(newFileList);
        storeImageUrl('')
    }

    const handleChange = ({file: changedFile, fileList: changedFileList}) => {
        if (changedFile.status === 'done' && changedFile.xhr && changedFile.xhr.url) {
            const changedFileIndex = changedFileList.findIndex(file => file.uid === changedFile.uid);
            if (changedFileIndex !== -1) {
                const affected_file = changedFileList[changedFileIndex];
                affected_file['url'] = changedFile.xhr.url;
            }
        }
        // console.log('changedFile', changedFile)
        // console.log('changedFileList', changedFileList);
        // setFileList(changedFileList);
        // if (onFileUpload) onFileUpload();
    }

    const runCustomRequest = async options => {
        const {file, onSuccess, onError, onProgress} = options;
        const postForm = uploadFilePreHandle(admin, project_id, siteSection, file, imageType, onProgress);

        try {
            const response = await axios.post(backend_api.file, postForm.data, postForm.config);
            const data = response.data;

            if (!data.ok) {
                onError();
                apiFalseNotice(response);
            } else {
                // main save
                const url = data.result.url;
                storeImageUrl(url);
                // native logic
                let newFile = {...file, url: url};
                onSuccess(response, newFile);
            }
        } catch (error) {
            onError();
            apiErrorNotice(error);
        }
    };

    const uploadButton = <div>
        <UploadOutlined style={{fontSize: 24}}/>
        <div className="label">{label !== '' ? label : t('common.action.upload')}</div>
    </div>;

    const replaceButton = (limit === 1) ? <div>
        <AiOutlineUpload size="22"/>
        <div className="label">{t('common.action.replace')}</div>
    </div> : null;

    const uploadComponent = (
        <Upload
            name={name}
            accept="image/*"
            listType="picture-card"
            fileList={fileList}
            customRequest={runCustomRequest}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={onRemove}
            maxCount={limit}
        >
            {fileList.length >= limit ? replaceButton : uploadButton}
        </Upload>
    );

    let aspectSlider = false;
    if (aspect === 'slider') {
        aspect = 1;
        aspectSlider = true;
    }

    return (
        <>
            {(aspect > 0) ? <ImgCrop aspect={aspect} aspectSlider={aspectSlider} rotationSlider={true}>
                {uploadComponent}
            </ImgCrop> : uploadComponent}

            <Modal
                className="modal-clean"
                title={t('common.header.preview')}
                open={previewOpen}
                onCancel={handleCancel}
                footer={null}>
                <div>
                    <img alt="Preview Image" src={previewImage}/>
                </div>
            </Modal>
        </>
    );
};

