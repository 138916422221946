import {createSlice} from "@reduxjs/toolkit";

const FieldReducer = createSlice({
    name: 'field',
    initialState: {
        list: [],
        item: {},
        status: 'default', // 'loading', 'error', 'ready'
    },
    reducers: {
        fillFieldList(state, action) {
            state.list = [...action.payload]
        },
        setFieldItem(state, action) {
            state.item = action.payload;
        },
        setFieldStatus(state, action) {
            state.status = action.payload;
        },
        addFieldToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list] // reverse sorting
        },
        patchFieldInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        removeFieldFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0;  // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        resetFieldState(state) {
            state.list = [];
            state.item = {};
            state.status = 'default';
        },
    }
})

export default FieldReducer.reducer
export const {
    fillFieldList,
    setFieldItem,
    setFieldStatus,
    addFieldToList,
    patchFieldInList,
    removeFieldFromList,
    resetFieldState,
} = FieldReducer.actions