import React, {useState} from 'react'
import './MenuAccordion.scss'
import {AiOutlineDown} from "react-icons/ai";
import {inArray} from "../../library/functions";
import {l} from "../../library/locale";
import {routes} from "../../config/config";
import {Collapse} from "react-collapse/lib/Collapse";
import {useNavigate, useSearchParams} from "react-router-dom";

export const sectionsWithFolders = [
    'graph',
    'glob',
    'field',
    'group',
    'currency',
    'money',
    'role',
    'achievement',
    'resource',
    'template',
    'site',
    'domain',
    'layout',
    'theme',
    'tag',
]

const MenuAccordion = ({items, name, IconHeader, idAttr, currentProjectId, isOpened}) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')

    const path = window.location.pathname.split('/');
    let sectionFromPath = (currentProjectId ? path[3] : path[2]) + (window.location.hash ?? '')

    if (inArray(sectionFromPath, ['page', 'domain'])) {
        sectionFromPath = 'site';
        // console.log('sectionFromPath', sectionFromPath, items, name)
    }

    const isCurrentCategory = inArray(sectionFromPath, items)
    const isOpenedByDefault = isCurrentCategory || isOpened;

    if (site_id) sectionFromPath = 'pages'

    const [isItemOpened, setItemState] = useState(isOpenedByDefault);
    const toggleFilterState = () => setItemState(!isItemOpened);

    const getPathFromName = (itemName) => {
        let result = routes.project_list
        if (currentProjectId) result += '/' + currentProjectId
        else result = routes.admin.core

        const routes_local_path = routes.local[itemName + '_list']
        const local_path = routes_local_path ? routes_local_path : '/' + itemName
        result += local_path

        if (inArray(itemName, sectionsWithFolders)) result += '?folder=auto'
        return result
    }

    return (
        <div className={`menu-accordion${isItemOpened ? ' opened' : ''}${isCurrentCategory ? ' active' : ''}`}
             data-accordion=''>
            <div className='accordion_header' onClick={toggleFilterState}>
                <div className='accordion_header_icon'><IconHeader/></div>
                <div className='accordion_header_title'>{l(`menu.${name}.title`)}</div>
                <div className='accordion_header_corner'>
                    <AiOutlineDown className='arrow'/>
                </div>
            </div>
            <Collapse isOpened={isItemOpened}>
                <div id={idAttr} className='accordion_content'>
                    <ul className='accordion_list_container'>
                        {items.map((item, index) => {
                            let itemPath, itemLabel, isActive;

                            if (item === undefined) return null;
                            if (item === 'divider') {
                                return <li key={`${item}_${index}`} className="accordion_list_item divider"/>
                            }
                            else if (typeof item === 'object') {
                                itemPath = item.link;
                                itemLabel = item.label;

                                // only for sites
                                // check searchParams.site_id with last number in itemPath:
                                isActive = site_id === itemPath.split('=').pop();
                            }
                            else {
                                itemPath = getPathFromName(item);
                                itemLabel = l(`menu.${name}.${item}`)
                                isActive = item === sectionFromPath;
                            }

                            return <li key={`${item}_${index}`} className="accordion_list_item">
                                <a
                                    href={itemPath}
                                    className={isActive ? 'active' : ''}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(itemPath)
                                    }}
                                >{itemLabel}</a>
                            </li>
                        })}
                    </ul>
                </div>
            </Collapse>
        </div>
    )
}

export default MenuAccordion