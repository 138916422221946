import {createSlice} from "@reduxjs/toolkit";

const FolderReducer = createSlice({
    name: 'folder',
    initialState: {
        section: null,
        list: null,
        item: {},
    },
    reducers: {
        setFolderSection(state, action) {
            state.section = action.payload;
        },
        setFolderList(state, action) {
            state.list = action.payload;
        },
        fillFolderList(state, action) {
            state.list = [...action.payload];
            // state.status = 'loaded';
        },
        setFolderItem(state, action) {
            state.item = action.payload;
            // state.status = 'loaded';
        },
        setFolderItemById(state, action) {
            const index = state.list ? state.list.findIndex(o => o.id === action.payload) : -1;
            if (index !== -1) {
                state.item = state.list[index]
            }
        },
        addFolderToList(state, action) {
            state.item = action.payload;
            state.list = state.list ? [...state.list, action.payload] : [action.payload]
        },
        patchFolderInList(state, action) {
            const list = state.list ? [...state.list] : [];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        removeFolderFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        clearFolderItem(state) {
            state.item = {};
        },
        resetFolderState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default FolderReducer.reducer
export const {
    fillFolderList,
    setFolderList,
    setFolderItem,
    setFolderSection,
    setFolderItemById,
    addFolderToList,
    patchFolderInList,
    removeFolderFromList,
    clearFolderItem,
    resetFolderState,
} = FolderReducer.actions