import {createSlice} from "@reduxjs/toolkit";

const GraphReducer = createSlice({
    name: 'graph',
    initialState: {
        list: [],
        item: {},
        node: {}, // {graphId: 1, localId: 2, data: {}}
        container: {}, // {graphId: 1, localId: 2, listName: 'events',  specValues: {}}
        status: {
            editing: false,
            edited: false,
            redrawn: false,
        },
    },
    reducers: {
        changeGraphStatus(state, action) {
            // console.log('changeGraphStatus', action.payload);
            state.status = {...state.status, ...action.payload};
        },

        fillGraphList(state, action) {
            state.list = [...action.payload]
        },
        setGraphItem(state, action) {
            state.item = action.payload;
            // console.log('setGraphItem state.item', state.item)
        },
        patchGraphContainer(state, action) {
            state.container = action.payload;
        },
        addGraphToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list] // reverse sorting
            // console.log('addGraphToList state.item', state.item)
        },
        patchGraphInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
            // console.log('patchGraphInList state.item', state.item)
        },
        removeGraphFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0;  // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        resetGraphState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default GraphReducer.reducer
export const {
    fillGraphList,
    setGraphItem,
    addGraphToList,
    patchGraphInList,
    removeGraphFromList,
    patchGraphContainer,
    resetGraphState,
    changeGraphStatus,
} = GraphReducer.actions