import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addWebhookToList,
    fillWebhookList,
    patchWebhookInList,
    removeWebhookFromList,
    setWebhookItem,
} from "../reducers/WebhookReducer";

// init api methods
const base_path = backend_api.webhook;

function* getWebhookList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillWebhookList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getWebhookItem(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id,
            'get',
        );

        if (response.data.ok) yield put(setWebhookItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createWebhook(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(setWebhookItem(response.data.result))
            yield put(addWebhookToList(response.data.result)) 
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateWebhook(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(setWebhookItem(response.data.result))
            yield put(patchWebhookInList(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreWebhook(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeWebhookFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteWebhook(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id,
            'delete');

        if (response.data.ok) yield put(removeWebhookFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* webhookSaga() {
    yield takeEvery('getWebhookList', getWebhookList);
    yield takeEvery('getWebhookItem', getWebhookItem);
    yield takeEvery('createWebhook',  createWebhook);
    yield takeEvery('updateWebhook',  updateWebhook);
    yield takeEvery('restoreWebhook', restoreWebhook);
    yield takeEvery('deleteWebhook',  deleteWebhook);
}

