import {createSlice} from "@reduxjs/toolkit";

const GraphNodeReducer = createSlice({
    name: 'graphNode',
    initialState: {
        list: [],
    },
    reducers: {
        fillGraphNodeList(state, action) {
            state.list = [...action.payload]
        },
        clearGraphNodeReducer(state) {
            state.list = [];
        },
    }
})

export default GraphNodeReducer.reducer
export const {
    fillGraphNodeList,
} = GraphNodeReducer.actions