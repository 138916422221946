import React, {useState} from 'react'
import classes from './Search.module.scss';
import {Input} from 'antd';
import {AiOutlineSearch} from "react-icons/ai";
import {$$} from "../../../../../../library/dom";

const {Search} = Input;

const SearchContainers = ({title, valueState, setValueState}) => {
    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            setValueState('');
            onSearch('');
        }
    }

    const onChange = (e) => {
        const value = e.target.value.toLowerCase();
        setValueState(value);
        onSearch(value);
    }

    const onSearch = (value) => {
        const containers = $$('.container-catalog-wrapper .containers-list-wrapper>div');
        // let children = containers.children;
        for (let i = 0; i < containers.length; i++) {
            const child = containers[i];
            const text = child.textContent.toLowerCase();
            if (value) {
                child.classList.add("hide-important");
                if (text.includes(value)) {
                    child.classList.remove("hide-important");
                }
            }
            else{
                child.classList.remove("hide-important");
            }
        }
    }

    return (
        <div className={classes.wrapper}>
            <Search
                allowClear={!!valueState}
                size="large"
                className={classes.input}
                placeholder={title}
                onChange={onChange}
                suffix={valueState?null:<AiOutlineSearch className={classes.icon}/>}
                style={{width: '100%'}}
                value={valueState}
                onKeyDown={onKeyDown}
            />
        </div>
    )
}

export default SearchContainers