import {createSlice} from "@reduxjs/toolkit";

const GlobReducer = createSlice({
    name: 'glob',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillGlobList(state, action) {
            state.list = action.payload
        },
        setGlobItem(state, action) {
            state.item = action.payload;
        },
        setGlobListCount(state, action) {
            state.count = action.payload;
        },
        addGlobToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeGlobFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchGlobInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetGlobState(state) {
            state.item = {};
            state.list = [];
        },
    }
})

export default GlobReducer.reducer
export const {
    fillGlobList,
    setGlobItem,
    resetGlobState,
    setGlobListCount,
    patchGlobInList,
    addGlobToList,
    removeGlobFromList
} = GlobReducer.actions