import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Col, Popconfirm, Row, Tooltip} from "antd";

import "../Block/Components/Tailwind.scss";
import classes from './LayoutMaker.module.scss';
import {deepGet, inArray} from "../../../library/functions";
import {notice} from "../../../library/notice";

import Preloader from "../../System/Preloader";
import ImportModal from "../../../components/Modal/ImportModal";
import {DeleteOutlined, PlusOutlined, SettingOutlined} from "@ant-design/icons";
import {BlockSect} from "../Block/Components/BlockSect";
import {ContainersModal} from "../Add/ContainersModal";
import {BlockDrawers} from "../Block/BlockDrawers";
import {pushPageContainer} from "../Block/BlockSections";
import {LayoutDrawers} from "./LayoutDrawers";
import {copyToClipboard} from "../../../library/clipboard";
import {resetActive, setLayoutActive, setLayoutRows, setRow} from "../../../redux/reducers/BlockReducer";

export const LayoutMaker = (
    {
        t,
        id,
        item,
        getNewId,
        isOn = true,
        saveItem = null,
        section = 'layout',
        types = null
    }
) => {
    const isTheme = section !== 'layout';
    if (!types) types = {
        row: 'row',
        col: 'col',
        sec: 'sec',
    };

    // init hooks
    const dispatch = useDispatch()

    // sync with store
    const {pb} = useSelector(store => store);
    const layoutRows = pb.layout;
    const activeIds = pb.active;

    const [openedSection, setOpenedSection] = useState(null);
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [sectionsMap, setSectionsMap] = useState({});

    // import states
    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleImportModal, setVisibleImportModal] = useState(false);

    useEffect(() => {
        if (layoutRows) makeSectionsMap(layoutRows);
    }, [layoutRows]);

    const makeSectionsMap = (rows) => {
        for (const row of rows) {
            for (const col of row.items) {
                for (const section of col.items) {
                    sectionsMap[section.id] = {
                        row: row.id,
                        col: col.id,
                    }
                }
            }
        }

        setSectionsMap(sectionsMap);
    }

    const updateFormByValues = (rows) => {
        dispatch(setLayoutRows(rows));
    }

    // ====== LAYOUT ======

    const addRow = (mdWidths, smWidths = null) => {
        // console.log('onCardClick', ColWidths);
        // const fieldName = cryptoRandomString({length: 10});
        let newRow = {
            id: getNewId(),
            type: types.row,
            spec: {},
            items: [],
        };

        let editable = false;
        if (mdWidths.length === 1 && mdWidths[0] === 12 && (!layoutRows || !layoutRows.length)) {
            editable = true;
        }

        for (const i in mdWidths) {
            const w = mdWidths[i];
            const s = smWidths ? smWidths[i] : w;

            let sectionSpec = {editable: editable}
            if (types.sec === 'box') sectionSpec = {main: editable};

            newRow.items.push({
                id: getNewId(),
                type: types.col,
                spec: {
                    width: {
                        default: 12,
                        sm: s,
                        md: w,
                        lg: w,
                    },
                },
                items: [
                    {
                        id: getNewId(),
                        type: types.sec,
                        spec: sectionSpec,
                        items: [],
                    }
                ],
            });
        }

        const newRows = layoutRows ? [...layoutRows, newRow] : [newRow];
        updateFormByValues(newRows);
    }

    const addCol = (rowIndex) => {
        let newRows = [...layoutRows];
        const newCols = [...newRows[rowIndex].items];
        const newCol = {
            id: getNewId(),
            type: types.col,
            spec: {
                classes: [],
                width: {default: 12, sm: 12, md: 12, lg: 12},
            },
            items: [
                {
                    id: getNewId(),
                    type: types.sec,
                    spec: {},
                    items: [],
                }
            ],
        }

        newCols.push(newCol);
        newRows[rowIndex] = {...newRows[rowIndex], items: newCols};
        updateFormByValues(newRows);
    }

    const addSection = (rowIndex, colIndex) => {
        let newRows = [...layoutRows];
        const newCols = [...newRows[rowIndex].items];
        const newSections = [...newCols[colIndex].items];
        const newSection = {
            id: getNewId(),
            type: types.sec,
            spec: {},
            items: [],
        }

        newSections.push(newSection);
        newCols[colIndex] = {...newCols[colIndex], items: newSections};
        newRows[rowIndex] = {...newRows[rowIndex], items: newCols};
        updateFormByValues(newRows);
    }

    const copyRow = (key) => {
        const newId = getNewId();
        const newField = {...layoutRows[key], id: newId, title: newId};
        const newFields = [...layoutRows, newField];
        updateFormByValues(newFields);
    }

    const deleteRow = (index) => {
        let newFields = [...layoutRows];
        newFields.splice(index, 1);
        updateFormByValues(newFields);
    }

    const deleteCol = (rowIndex, colIndex) => {
        let newRows = [...layoutRows];
        const newCols = [...newRows[rowIndex].items];
        newCols.splice(colIndex, 1);
        newRows[rowIndex] = {...newRows[rowIndex], items: newCols};
        updateFormByValues(newRows);
    }

    const deleteSection = (rowIndex, colIndex, sectionIndex) => {
        let newRows = [...layoutRows];
        const newCols = [...newRows[rowIndex].items];
        const newSections = [...newCols[colIndex].items];

        newSections.splice(sectionIndex, 1);

        newCols[colIndex] = {...newCols[colIndex], items: newSections};
        newRows[rowIndex] = {...newRows[rowIndex], items: newCols};
        updateFormByValues(newRows);
    }

    const editRow = (item) => {
        dispatch(setRow(item));
        dispatch(setLayoutActive({row: item.id}));
    }

    const editCol = (item, rowId) => {
        dispatch(setRow(item));
        dispatch(setLayoutActive({row: rowId, col: item.id}));
    }

    const editSection = (item, rowId, colId) => {
        dispatch(setRow(item));
        dispatch(setLayoutActive({row: rowId, col: colId, sec: item.id}));
    }

    // ====== SECTIONS CONTAINERS ======

    const blockNotFound = (localId, itemsList, blockType = 'Item') => {
        console.error(blockType + ' not found by id', localId, itemsList);
        return undefined;
    }

    const getSectionPathById = (sectionId) => {
        let path = {'row': -1, 'col': -1, 'sec': -1};

        const sectionItem = sectionsMap[String(sectionId)];
        if (!sectionItem) return blockNotFound(sectionId, sectionsMap, 'Section path');

        const rowIndex = layoutRows.findIndex(item => item.id === sectionItem.row);
        if (rowIndex === -1) return blockNotFound(sectionItem.row, layoutRows, 'Row');
        path.row = rowIndex;

        const rowCols = layoutRows[rowIndex].items;
        const colIndex = rowCols.findIndex(item => item.id === sectionItem.col);
        if (colIndex === -1) return blockNotFound(sectionItem.col, rowCols, 'Col');
        path.col = colIndex;

        const colSections = rowCols[colIndex].items;
        const sectionIndex = colSections.findIndex(item => item.id === sectionId);
        if (sectionIndex === -1) return blockNotFound(sectionId, colSections, 'Section');
        path.sec = sectionIndex;

        return path;
    }

    const setSectionItemsByPath = (items, path) => {
        // console.log('setSectionItemsByPath', path, items)
        let newRows = [...layoutRows];

        let newSections = [...newRows[path.row].items[path.col].items];
        newSections[path.sec] = {
            ...newSections[path.sec],
            items: items,
        }

        let newCols = [...newRows[path.row].items];
        newCols[path.col] = {
            ...newCols[path.col],
            items: newSections,
        }

        newRows[path.row] = {
            ...newRows[path.row],
            items: newCols,
        }

        updateFormByValues(newRows);
    }

    const onSectionChange = (items, sectionId = null) => {
        const path = getSectionPathById(sectionId || activeIds.sec);
        if (path === undefined) return;

        setSectionItemsByPath(items, path);
    }

    const getRowsBySectionId = (sectionId) => {
        const path = getSectionPathById(sectionId);
        if (path === undefined) return;

        return [path, [
            ...layoutRows[path.row].items[path.col].items[path.sec].items
        ]];
    }

    const addContainer = (newContainers) => {
        if (!openedSection) {
            console.error('Block opened Section is empty', activeIds);
            return;
        }

        let [path, newItems] = getRowsBySectionId(openedSection);
        pushPageContainer(t, newItems, newContainers, getNewId);

        setSectionItemsByPath(newItems, path);
        dispatch(resetActive()); // reset active Ids
    }

    const importRows = () => {
        if (!fileContent) {
            notice.info(t('common.import.error.empty'));
            return;
        }
        try {
            const data = JSON.parse(String(fileContent));
            if (deepGet(data, [0, 'items']) === undefined) {
                notice.error(t('common.import.error.content'));
                return;
            }

            console.log('Import Content', data);
            addContainer(data);

        } catch (e) {
            console.log('Import data parse error', e);
            notice.error(t('common.import.error.format'));
        }
    };


    const openImportModal = () => {
        setVisibleModal(false);
        setVisibleImportModal(true);
        setFileList([]);
        setFileContent('');
    }

    // ====== RENDER ======

    const renderRowTitle = (rowType, rowId, rowSpec) => {
        let spec = rowSpec || {};

        let prefix = '.';
        let title = rowType + rowId;

        let tooltip = 'className';
        let value = prefix + title;

        if (spec.id) {
            title = spec.id;
            value = '#' + spec.id + value;
            tooltip = 'selector';
        }

        return <Tooltip title={t('layout.notice.copy.' + tooltip)}>
            <span className="pointer" onClick={() => copyToClipboard(value)}>
                {title}
            </span>
        </Tooltip>
    }

    if (id && item.id !== id) return (<Preloader/>);
    // if (layoutRows === null || (id && item.id !== id)) return (<Preloader/>);

    let list = [];
    if (activeIds.sec) {
        const [blockPath, sectionItems] = getRowsBySectionId(activeIds.sec);
        if (sectionItems) list = sectionItems;
    }

    return (<div>
        {layoutRows && layoutRows.map((row, rowIndex) => (
            <Card
                key={row.id}
                type="inner"
                size="small"
                className={"margin-bottom-sm " + classes.row}
                title={<>
                    <div className="float-right">
                        <Button
                            type="text"
                            className="inverted"
                            icon={<PlusOutlined/>}
                            onClick={() => addCol(rowIndex)}
                        />

                        <Popconfirm
                            title={t('layout.notice.delete.approve')}
                            onConfirm={() => deleteRow(rowIndex)}
                            icon={null}
                            placement="topRight"
                            okText={t('common.yes')}
                            cancelText={t('common.no')}
                            cancelButtonProps={{type: 'text'}}
                            getPopupContainer={() => document.getElementById('root')}
                        >
                            <Button
                                type="text"
                                icon={<DeleteOutlined/>}
                            />
                        </Popconfirm>

                        <Button
                            type="text"
                            icon={<SettingOutlined/>}
                            onClick={() => editRow(row)}
                        />
                    </div>
                    {renderRowTitle(types.row, row.id, row.spec)}
                </>}
            >
                <Row gutter={12}>
                    {row.items && row.items.map((col, colIndex) => {
                        if (col === null || col === undefined) return null;
                        const colWidth = deepGet(col, 'spec.width', {});
                        return (<Col
                            key={col.id}
                            className="margin-bottom-xp"
                            span={colWidth.default ? Number(colWidth.default) * 2 : 24}
                            sm={colWidth.sm ? Number(colWidth.sm) * 2 : null}
                            md={colWidth.md ? Number(colWidth.md) * 2 : null}
                            lg={colWidth.lg ? Number(colWidth.lg) * 2 : null}
                        >
                            <Card
                                key={col.id}
                                size="small"
                                type="inner"
                                className={"bg-gray-light " + classes.col}
                                title={<>
                                    <div className="float-right">
                                        <Button
                                            type="text"
                                            className="inverted"
                                            icon={<PlusOutlined/>}
                                            onClick={() => addSection(rowIndex, colIndex)}
                                        />
                                        <Popconfirm
                                            title={t('layout.notice.delete.approve')}
                                            onConfirm={() => deleteCol(rowIndex, colIndex)}
                                            icon={null}
                                            placement="topRight"
                                            okText={t('common.yes')}
                                            cancelText={t('common.no')}
                                            cancelButtonProps={{type: 'text'}}
                                            getPopupContainer={() => document.getElementById('root')}
                                        >
                                            <Button
                                                type="text"
                                                icon={<DeleteOutlined/>}
                                            />
                                        </Popconfirm>
                                        <Button
                                            type="text"
                                            icon={<SettingOutlined/>}
                                            onClick={() => editCol(col, row.id)}
                                        />
                                    </div>
                                    {renderRowTitle(types.col, col.id, col.spec)}
                                </>}
                            >
                                {col.items && col.items.map((section, sectionIndex) => {
                                    let specClasses = deepGet(section, ['spec', 'classes'], []);
                                    if (!specClasses) specClasses = [];

                                    let isEditable = deepGet(section, ['spec', 'editable']);
                                    if (section.type === 'box') isEditable = deepGet(section, ['spec', 'main']);

                                    return (
                                        <Card
                                            key={section.id}
                                            size="small"
                                            type="inner"
                                            className={[
                                                'p-sec-wrap',
                                                "bg-white",
                                                classes.col,
                                                inArray('h-full', specClasses) ? classes.h_full : null,
                                                isEditable ? classes.editable : null,
                                            ].join(' ')}
                                            title={<>
                                                <div className="float-right">
                                                    <Popconfirm
                                                        title={t('layout.notice.delete.approve')}
                                                        onConfirm={() => deleteSection(rowIndex, colIndex, sectionIndex)}
                                                        icon={null}
                                                        placement="topRight"
                                                        okText={t('common.yes')}
                                                        cancelText={t('common.no')}
                                                        cancelButtonProps={{type: 'text'}}
                                                        getPopupContainer={() => document.getElementById('root')}
                                                    >
                                                        <Button
                                                            type="text"
                                                            icon={<DeleteOutlined/>}
                                                        />
                                                    </Popconfirm>

                                                    <Button
                                                        type="text"
                                                        icon={<SettingOutlined/>}
                                                        onClick={() => editSection(section, row.id, col.id)}
                                                    />
                                                </div>
                                                {renderRowTitle(types.sec, section.id, section.spec)}
                                            </>}
                                        >
                                            <BlockSect
                                                t={t}
                                                isOn={isOn}
                                                section={section}
                                                getNewId={getNewId}
                                                setVisibleModal={setVisibleModal}
                                                setOpenedSection={setOpenedSection}
                                                onChange={(rows) => onSectionChange(rows, section.id)}
                                            />
                                        </Card>
                                    )
                                })}
                            </Card>
                        </Col>)
                    })}
                </Row>
            </Card>
        ))}

        <div className={classes.addButtons}>
            <Button
                type="primary"
                className={isTheme ? 'btn-bordered' : null}
                onClick={() => addRow([12])}
                icon={<PlusOutlined/>}
            >
                {t('layout.row.add.single')}
            </Button>
            <Button type="default" onClick={() => addRow([3, 9], [4, 8])}>{t('layout.row.add.sidebar_left')}</Button>
            <Button type="default" onClick={() => addRow([9, 3], [8, 4])}>{t('layout.row.add.sidebar_right')}</Button>
            <Button type="default" onClick={() => addRow([6, 6])}>{t('layout.row.add.two')}</Button>
            <Button type="default" onClick={() => addRow([4, 4, 4])}>{t('layout.row.add.three')}</Button>
            <Button type="default" onClick={() => addRow([3, 3, 3, 3])}>{t('layout.row.add.four')}</Button>
        </div>

        <ContainersModal
            t={t}
            title={t('container.title')}
            isVisible={isVisibleModal}
            setVisible={setVisibleModal}
            onAdd={addContainer}
            onImport={openImportModal}
        />

        <BlockDrawers
            page={item}
            section={section}
            sectionItems={list}
            onChange={onSectionChange}
            savePage={id ? saveItem : null}
        />

        <LayoutDrawers
            page={item}
            rows={layoutRows}
            initialTypes={types}
            // onChange={updateFormByValues}
        />

        <ImportModal
            t={t}
            onOk={importRows}
            section="common"
            isVisibleModal={isVisibleImportModal}
            setVisibleModal={setVisibleImportModal}
            fileList={fileList}
            setFileList={setFileList}
            fileContent={fileContent}
            setFileContent={setFileContent}
            fileFormat="json"
        />
    </div>)
}