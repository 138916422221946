export const coreText = {
    heading: {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.text.header}',
                                level: 'h2',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            }
                        },
                    ]
                }
            ],
            style: {
                padding: {
                    left: '6',
                    right: '6',
                }
            },
        }]
    },
    desc: {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.text.short_desc}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ]
                }
            ],
            style: {
                padding: {
                    left: '6',
                    right: '6',
                    top: '6',
                    bottom: '10',
                }
            },
        }]
    },
    section: {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.text.header}',
                                level: 'h2',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.text.short_desc}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ],
                },
            ],
            style: {
                padding: {
                    top: "6",
                    bottom: '10',
                    left: '6',
                    right: '6',
                },
                width: {
                    container: 'md',
                }
            },
        }]
    },
    'image/right': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.text.header}',
                                level: 'h3',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.text.short_desc}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ],
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.illustration}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_03-53-12_QIZ.jpg"
                            },
                            style: {
                                border: {
                                    radius: "lg"
                                },
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    max: "300"
                                },
                            },
                            wrap: {
                                flex: {
                                    direction: "row"
                                },
                            },
                        },
                    ],
                },
            ],
            style: {
                flex: {
                    direction: "col-reverse",
                    sm: "row",
                    gap: "10",
                    items: "stretch",
                    justify: "center",
                    content: "center",
                },
                grow: {
                    content: true,
                },
                padding: {
                    top: "10",
                    bottom: '10',
                    left: '6',
                    right: '6',
                },
                width: {
                    container: 'sm',
                }
            },
        }]
    },
    'image/left': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.illustration}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-55-27_Q5A.jpg"
                            },
                            style: {
                                border: {
                                    radius: "lg"
                                },
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    max: "300"
                                },
                            },
                            wrap: {
                                flex: {
                                    direction: "row"
                                },
                            },
                        },
                    ],
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{locale:container.example.text.header}',
                                level: 'h3',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '{locale:container.example.text.short_desc}',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ],
                },
            ],
            style: {
                flex: {
                    direction: "col",
                    sm: "row",
                    gap: "10",
                    items: "stretch",
                    justify: "center",
                    content: "center",
                },
                grow: {
                    content: true,
                },
                padding: {
                    top: "10",
                    bottom: '10',
                    left: '6',
                    right: '6',
                },
                width: {
                    container: 'sm',
                }
            },
        }]
    },
    article: {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/text/heading',
                            spec: {
                                content: '{var:object.title}',
                                level: 'h1',
                            },
                            style: {
                                padding: {
                                    bottom: "4",
                                    top: "6",
                                }
                            }
                        },
                        {
                            type: 'core/text/desc',
                            spec: {
                                content: '<div>{var:object.description|}</div>',
                            },
                            style: {
                                classes: {
                                    prose: true,
                                }
                            }
                        },
                    ],
                },
            ],
            style: {
                padding: {
                    top: "6",
                    bottom: '10',
                    left: '6',
                    right: '6',
                },
                width: {
                    container: 'md',
                }
            },
        }]
    },
}