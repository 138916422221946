import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Affix, Button, Col, Form, Input, Layout, PageHeader, Popconfirm, Row, Tabs, Typography} from "antd";
import {Collapse} from "react-collapse/lib/Collapse";
import classes from './MailEdit.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createMarkup,
    createObjectFromObjectsArray,
    deepGet,
    filterArrayByFieldValue,
    getUtcDateTime,
    inArray,
    momentFromUnix,
    toNum,
    ucFirst,
    unixFromMoment
} from "../../../library/functions";
import moment from "moment/moment";
import {routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {useTranslation} from "react-i18next";
import {
    ArrowLeftOutlined,
    CalendarOutlined,
    CaretRightOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FilterOutlined,
    FormOutlined,
    LineChartOutlined,
    LoadingOutlined,
    MessageOutlined,
    PauseOutlined,
    RetweetOutlined,
    RobotOutlined,
    SendOutlined,
    UpOutlined
} from "@ant-design/icons";
import {composeGraphItem, GraphBlock} from "../../Flow/Block";
import {DateTimeField, FormFields} from "../../../components/Form/Field/FormFields";
import {messengers, platforms, profile_types} from "../../../schemas/frontend/effects";
import {createObjectFromIntegrations} from "../../Integrations/IntegrationEdit";
import {setGraphItem} from "../../../redux/reducers/GraphReducer";
import Preloader from "../../System/Preloader";
import {mail_graph} from "../../../schemas/backend/mail_graph";
import {setLoadingStatus} from "../../../redux/reducers/StatusReducer";
import {setMailId, setMailItem, setMailItemParam, setMailStoreParam} from "../../../redux/reducers/MailReducer";
import {ProgressBar} from "../../../components/ProgressBar/ProgressBar";
import {SimpleModal} from "../../../components/Modal/SimpleModal";
import {notice} from "../../../library/notice";

const {Header, Content} = Layout
const {Title} = Typography

const statExcluded = ['done', 'action']
const defaultStat = {
    users: 0, selected: 0,

    // sent: 0,
    // messages: 0,
    // delivered: 0,
    // errors: 0,

    // opened: 0,
    // answered: 0,
    // url: 0,
    // button: 0,
}

const MailEdit = () => {
    const {t} = useTranslation()
    const section = 'mail';
    const adminSection = 'mail';
    const Section = ucFirst(section);
    const now = getUtcDateTime();
    const today = new Date();

    // data from URL params
    const params = useParams()
    const id = toNum(params.id)
    const project_id = toNum(params.project_id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {app, admin, project, mail, field, integration} = useSelector(store => store)
    const project_item = project.item;
    const item = mail.item
    const stat = mail.item.stat || defaultStat

    const status = {
        draft: inArray(item.status, ['draft', 'template']), // event also can be somewhere
        planning: inArray(item.status, ['start', 'planning']),
        running: inArray(item.status, ['running']),
        scheduled: inArray(item.status, ['scheduled']),
        active: inArray(item.status, ['start', 'planning', 'running', 'scheduled', 'paused', 'sent', 'editing']),
        done: inArray(item.status, ['paused', 'sent', 'editing']),
        paused: item.status === 'paused',
        sent: item.status === 'sent',
    }

    const timeMode = deepGet(item, 'params.time_mode', 'now')
    const connectedUserId = deepGet(admin, 'params.connection.' + project_item.id + '.user', 0);

    const lists = filterArrayByFieldValue(field.list, 'system_field_type', 'list');
    const fields_map = createObjectFromObjectsArray(lists, 'name');
    const integrations = createObjectFromIntegrations(t, integration.list, true, messengers);

    const [mailTitle, setMailTitle] = useState('');
    const [mailTitleEdit, setMailTitleEdit] = useState(false);

    const graphRef = useRef(null);
    const [nodesState, setNodesState] = useState([]);

    const [timeTab, setTimeTab] = useState(timeMode);  // now, schedule, event
    const [isNowMode, setNowMode] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isEdited, setEdited] = useState(false);
    const [isVisibleConfirm, setVisibleConfirm] = useState(false);

    // init collapsible sections state
    const [opened, setOpened] = useState({
        what: true, whom: true, where: true, when: true, how: true, actions: true, process: true, reactions: true,
    })

    // toggle collapsible sections
    const toggleCollapsed = (key) => setOpened({...opened, [key]: !opened[key]})

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: 'filters.conditions.0.type',
            type: 'hidden',
            default: 'list/member',
        },
        {
            name: 'filters.conditions.0.spec.codes',
            type: 'tags',
            data: 'field',
            values: fields_map,
            features: ['creating'],
            data_filters: {system_field_type: 'list', data_type_admin: 'int'},
            disabled: status.active,
        },
        {
            name: 'show_all_filters',
            type: 'checkbox',
        },
        {
            name: 'filters.conditions.1.type',
            type: 'hidden',
            default: 'list/member',
        },
        {
            name: 'filters.conditions.1.negative',
            type: 'hidden',
            default: true,
        },
        {
            name: 'filters.conditions.1.spec.codes',
            type: 'tags',
            data: 'field',
            values: fields_map,
            features: ['creating'],
            data_filters: {system_field_type: 'list', data_type_admin: 'int'},
            disabled: status.active,
            depends_on: {show_all_filters: true},
        },
        {
            name: 'filters.conditions.2.type',
            type: 'hidden',
            default: 'user/type',
        },
        {
            name: 'filters.conditions.2.spec.types',
            type: 'tags',
            values: profile_types,
            localized: true,
            disabled: status.active,
            depends_on: {show_all_filters: true},
        },
        {
            name: 'filters.mode',
            type: 'hidden',
            default: 'and',
        },
    ]

    // init form state
    const [formValues, setFormValues] = useState({
        filters: {
            mode: 'and', conditions: {
                '0': {
                    type: 'list/member', spec: {
                        codes: [],
                    }
                }, '1': {
                    type: 'list/member', negative: true, spec: {
                        codes: [],
                    }
                }, '2': {
                    type: 'user/type', spec: {
                        types: ['user'],
                    }
                }
            }
        },
    })

    const formOnChange = () => {
        let currentSpec = form.getFieldsValue();
        console.log('formOnChange', currentSpec)
        setEdited(true);
        // console.log('formOnChange new SPEC: ', currentSpec);
        setFormValues({...formValues, ...currentSpec}); // for initialValues
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // navigate to edit if new mail was created (by create or clone)
    useLayoutEffect(() => {
        if (mail.id && id !== mail.id) {
            // console.log('Mail: Navigate', id, '→', mail.id)
            navigate(`${routes.project_list}/${project_id}/${adminSection}/edit/${mail.id}`);
        }
    }, [mail.id]);

    // get data from API first
    useEffect(() => {
        // console.log('Mail: useEffect id', id)

        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch(setGraphItem({}));
            dispatch(setLoadingStatus('loading'));
            dispatch({type: 'get' + Section + 'Item', admin, id});
        }

        if (!id) {
            dispatch(setMailItem({}));
            // dispatch(setGraphItem({})); // reset graph item too
        }

        // common filters for all sections
        const filters = {project_id: project_item.id}

        if (admin.authorized && project_item.id && !field.list.length) {
            dispatch({type: 'getFieldList', admin, filters})
        }

        if (admin.authorized && project_item.id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {...filters, platforms: messengers}})
        }

        return () => {
            // console.log('Mail: useEffect id return', id)
            setEdited(false);
            dispatch(setMailStoreParam({status: 'closed'}));
            dispatch(setMailId(0)); // reset mail ID on back to list or clone finish
            // dispatch(setMailItemParam({id: 0})); // reset mail item on back to list (bad way - broke interval)
            // dispatch(setGraphItem({})); // reset graph item on back to list
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    // set values to FORM if correct data received
    useEffect(() => {
        // console.log('Mail: useEffect item.id', item.id, 'graph_id', item.graph_id)
        if (id && item.id === id) {
            let values = {...item};
            if (!item.parent_id) values.parent_id = null;

            // convert to str every integration id:
            if (values.integration_ids) values.integration_ids = values.integration_ids.map(id => String(id)); else values.integration_ids = [];

            // if (timeMode === 'now') values.start_at = '';
            if (values.start_at) values.start_at = momentFromUnix(values.start_at);

            // update stat on every item open!
            if (!deepGet(values, 'stat.selected')) getFiltersStat();

            // setFormValues(values);
            form.setFieldsValue(values);

            setMailTitle(values.title);
            dispatch(setLoadingStatus('ready'));
        }

        if ((!id && !mailTitle) || (item.id && !item.title)) {
            setMailTitle(t(adminSection + '.form.title.value') + ' ' + moment().format('DD MMM HH:mm'));
            dispatch(setLoadingStatus('ready'));
        }

        //eslint-disable-next-line
    }, [item.id])

    // update filters counters on filters change
    useEffect(() => {
        // console.log('formValues', formValues)
        if (formValues && !status.active) {
            // change send button label
            setNowMode(isTrueNow(formValues.start_at));
            getFiltersStat();
            // console.log('status.active', item.status, status.active)

        }
    }, [mail.id, formValues]);

    // change time tab on item params update
    useEffect(() => {
        setTimeTab(timeMode);
    }, [timeMode]);

    useEffect(() => {
        setNowMode(isTrueNow(form.getFieldValue('start_at')));
    }, [timeTab]);

    // periodically update item stat of not draft and not sent mails
    useEffect(() => {
        let intervalId = null;
        const intervalTime = 2000;
        // let intervalTime = status.scheduled ? 20000 : 2000;
        // console.log('status.scheduled', status.scheduled, intervalTime);

        const startInterval = () => {
            if (id && item.id && id === item.id && status.active && !status.done) {
                // console.log('!startInterval SUCCESS');
                intervalId = setInterval(() => {
                    updateItemStat();
                }, intervalTime);
            }
            // else {
            //     console.log('item', item)
            //     console.log('startInterval', !!(id && item.id && id === item.id && status.active && !status.done), id, item.id, item.status, status.active, status.done)
            // }
        };

        const stopInterval = () => {
            if (intervalId) clearInterval(intervalId);
        };

        startInterval(); // Start the interval initially based on the current conditions

        return () => {
            // console.log('stopInterval', id, item.id, item.status)
            stopInterval(); // Clean up the interval on component unmount
        }
    }, [id, item.id, item.status]);

    // compose form functions
    const backToList = () => navigate(`${routes.project_list}/${project_id}/${adminSection}`);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);

    const getGraph = () => {
        if (graphRef.current) return graphRef.current.composeGraphDataForSave(); else return {};
    };

    const getFiltersStat = () => {
        const filters = form.getFieldValue('filters');
        const integration_ids = form.getFieldValue('integration_ids');

        if (integration_ids && integration_ids.length > 0) {
            dispatch({
                type: 'get' + Section + 'FiltersStat', admin, data: {
                    project_id: project_item.id, filters: filters, integration_ids: integration_ids,
                }
            });
        } else {
            dispatch(setMailItemParam({stat: defaultStat}));
        }
    }

    const prepareFormValues = () => {
        // if (values.start_at) values.start_at = unixFromMoment(values.start_at)
        const graphItem = getGraph();
        let values = {
            ...form.getFieldValue(), project_id: project_id, // graph_id: item.graph_id,
            logic: graphItem.logic, title: mailTitle,
        };

        if (values.start_at) values.start_at = unixFromMoment(values.start_at); else if ('start_at' in values) delete values.start_at;

        // newer pass next fields to API:
        const exclude_fields = ['graph_id', 'stat']
        exclude_fields.forEach(field => {
            if (field in values) delete values[field];
        });

        if (!values.params) values.params = {};
        values.params['time_mode'] = timeTab;

        setMailTitleEdit(false);
        return values;
    }

    const onTitleUpdate = () => {
        if (item.id && mailTitle !== item.title) {
            dispatch({
                type: 'update' + Section, admin, id, data: {
                    id: item.id, title: mailTitle, project_id: project_id,
                }
            });
        }
        setMailTitleEdit(false);
    }

    // console.log('+++ Rerender', mail.status)

    const onGraphChange = () => {
        if (mail.status !== 'active') {
            // console.log('onGraph fix status:', mail.status)
            dispatch(setMailStoreParam({status: 'active'}));
        }
        else {
            // console.log('onGraph set edited:', mail.status)
            setEdited(true);
        }
    }

    const saveItem = (start = false, statusName = 'start') => {
        let values = prepareFormValues()

        if (start) {
            values['status'] = statusName
            values['start_by'] = admin.user.id
        } else if (values.status) {
            delete values.status;
        }

        // console.log('Mail: saveItem', id, 'graph_id', item.graph_id, 'text', deepGet(values.logic, 'nodes.1.effects.0.spec.text'))
        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        setEdited(false);
    }

    const isTrueNow = (start_moment) => {
        if (timeTab === 'now') {
            return true;
        }

        const start_time = start_moment ? unixFromMoment(start_moment) : now;
        return start_time <= now
    }

    const onSend = () => {
        // if (!id) setPageStatus('loading');
        setOpened({...opened, where: false, whom: false, how: true, process: true});

        if (isTrueNow(form.getFieldValue('start_at'))) {
            setVisibleConfirm(true);
        } else {
            saveItem(true)
            backToList()
        }
    }

    const onSave = () => {
        // setIsSaving(true);
        saveItem();
    }

    const saveAndBack = () => {
        saveItem()
        backToList()
    }

    const onOk = () => {
        saveItem(true)
        cancelConfirm()
    }

    const cancelConfirm = () => {
        setVisibleConfirm(false);
    }

    const updateItemStat = () => {
        dispatch({type: 'get' + Section + 'Stat', admin, id: item.id});
    }

    const onClone = () => {
        if (!id) {
            console.error('onClone', 'ID is empty: ', id)
            return;
        }

        let data = {id}

        const graphItem = getGraph();
        data['logic'] = graphItem.logic;
        // console.log('Mail: onClone', id, 'graph_id', item.graph_id, 'text', deepGet(graphItem.logic, 'nodes.1.effects.0.spec.text'))

        if (mailTitle !== item.title) data['title'] = mailTitle
        dispatch({type: 'copy' + Section, admin, data})
    }

    const onTitleChange = (event) => {
        setMailTitle(event.target.value)
    };

    const RenderSectionTitle = (name, icon, count = null) => {
        const suffix = item.status === 'sent' ? 'title_sent' : 'title'
        return <Title
            level={3}
            className="collapsible-subheader user-select-none margin-top-sm"
            onClick={() => toggleCollapsed(name)}
        >
            {icon} {t(adminSection + '.section.' + name + '.' + suffix)}{count == null ? null : ': ' + count} <span
            className="float-right">
                {opened[name] ? <UpOutlined/> : <DownOutlined/>}
            </span>
        </Title>
    }

    const itemError = () => {
        if (!item.id) {
            console.log('Item Receiving Error', item);
            notice.error(t(section + '.error.form.no_id'));
            return true;
        }

        return false;
    }

    const runAction = (action) => {
        if (itemError()) return;
        dispatch({type: 'run' + Section + 'Action', admin, data: {id: item.id, action}})
    }

    const testGraph = () => {
        if (itemError()) return;
        const bot_ids = form.getFieldValue('integration_ids')
        if (!bot_ids || !bot_ids.length) {
            notice.error(t(section + '.error.form.no_bot'));
            return;
        }

        for (const bot_id of bot_ids) {
            const botId = toNum(bot_id)
            if (botId) dispatch({
                type: 'runGraphForUser', admin, data: {
                    id: item.graph_id,
                    user_id: connectedUserId,
                    integration_id: botId,
                    project_id: project_id,
                    mail_id: item.id,
                    entity_id: 5, // 10 for condition
                }
            })
        }
    }

    const onCancel = () => {
        runAction('cancel')
    }

    const onPause = () => {
        runAction('pause')
    }

    const onResume = () => {
        runAction('resume')
    }

    const onDeleteMessages = () => {
        runAction('delete')
    }

    const onEditStart = () => {
        saveItem(true, 'editing')
    }

    function cancelEditing() {
        saveItem(true, 'sent')
    }

    const disabledDate = current => {
        // Disable dates before 'now'
        return current && current < today.startOf('day');
    };

    const disabledTime = (current, type) => {
        // Disable times before 'now' if the date is today
        if (current && current.isSame(today, 'day')) {
            const currentHour = today.hours();
            const currentMinute = today.minutes();

            if (type === 'hour') {
                return currentHour <= current.hours();
            } else if (type === 'minute') {
                return currentHour === current.hours() && currentMinute <= current.minutes();
            }
        }

        return false;
    };

    let graphItem = {};
    // TODO: review for the performance:
    if (!id || !item.logic) graphItem = composeGraphItem(project_item.id, mail_graph, 'New ' + section); else if (item && item.logic) graphItem = {
        id: item.graph_id, project_id: project_item.id, logic: item.logic,
    }
    // console.log('___ RERENDER:', 'id:', id, 'item.id:', item.id, 'item.logic:', !!item.logic)

    const renderHeaderButtons = () => {
        let desktop = [
            <Button
                key="header-return-button"
                type="text"
                icon={<ArrowLeftOutlined/>}
                className="hidden-sm"
                onClick={backToList}
                children={t('common.action.back')}
            />,
            null,
            null
        ];

        let mobile = [
            <Button
                key="header-return-button-sm"
                type="text"
                className="visible-sm"
                icon={<ArrowLeftOutlined/>}
                onClick={backToList}
            />,
            null,
            null
        ];

        if (!status.sent) {
            desktop[1] = <Button
                key="header-save-button"
                type="text"
                icon={<CheckOutlined/>}
                className="hidden-sm"
                onClick={onSave}
                loading={mail.loading}
                children={t('common.action.save')}
            />

            mobile[1] = <Button
                key="header-save-button-sm"
                type="text"
                className="visible-sm"
                icon={<CheckOutlined/>}
                onClick={onSave}
                loading={mail.loading}
            />
        }


        if (item.id && !isEdited && connectedUserId && inArray(item.status, ['draft', 'editing'])) {
            const bot_ids = form.getFieldValue('integration_ids')
            if (bot_ids && bot_ids.length) {
                desktop[1] = <Button
                    key="header-test-button"
                    type="text"
                    className="hidden-sm"
                    icon={<SendOutlined />}
                    onClick={testGraph}
                    loading={mail.loading}
                    children={t('common.action.test')}
                />

                mobile[1] = <Button
                    key="header-test-button-sm"
                    type="text"
                    className="visible-sm"
                    icon={<SendOutlined />}
                    onClick={testGraph}
                    loading={mail.loading}
                />
            }
        }

        if (!id || inArray(item.status, ['draft', 'start', 'planning'])) {
            desktop[2] = <Button
                key="header-primary-button"
                type="primary"
                icon={isNowMode ? <CaretRightOutlined/> : <ClockCircleOutlined/>}
                className="hidden-sm"
                onClick={onSend}
                loading={status.planning}
                disabled={toNum(stat.selected) <= 0}
            >
                {isNowMode ? t('common.action.send') : t('common.action.schedule')}
            </Button>

            mobile[2] = <Button
                key="header-primary-button-sm"
                type="primary"
                className="visible-sm"
                icon={isNowMode ? <CaretRightOutlined/> : <ClockCircleOutlined/>}
                onClick={onSend}
                loading={status.planning}
                disabled={toNum(stat.selected) <= 0}
            />

        } else if (item.status === 'editing') {
            desktop[2] = <Popconfirm
                key="header-edit-confirm"
                getPopupContainer={() => document.getElementById('root')}
                title={t(section + '.section.actions.edit.approve')}
                onConfirm={onOk}
                icon={null}
                placement="leftTop"
                okText={t('common.yes')}
                cancelText={t('common.no')}
                cancelButtonProps={{type: 'text'}}
            >
                <Button
                    key="header-edit-button"
                    type="primary"
                    icon={<FormOutlined/>}
                    className="hidden-sm"
                    loading={mail.loading}
                    // disabled={status.planning}
                    children={t('common.action.edit')}
                />
            </Popconfirm>

            mobile[2] = <Popconfirm
                key="header-edit-confirm-sm"
                getPopupContainer={() => document.getElementById('root')}
                title={t(section + '.section.actions.edit.approve')}
                onConfirm={onOk}
                icon={null}
                placement="topRight"
                okText={t('common.yes')}
                cancelText={t('common.no')}
                cancelButtonProps={{type: 'text'}}
            >
                <Button
                    key="header-edit-button-sm"
                    type="primary"
                    className="visible-sm"
                    icon={<FormOutlined/>}
                    loading={mail.loading}
                />
            </Popconfirm>

        } else if (item.status === 'sent') {
            desktop[2] = <Button
                key="header-clone-button"
                type="primary"
                icon={<RetweetOutlined/>}
                className="hidden-sm btn-bordered"
                onClick={onClone}
                loading={mail.loading}
                children={t('common.action.repeat')}
            />

            mobile[2] = <Button
                key="header-clone-button-sm"
                type="primary"
                icon={<RetweetOutlined/>}
                className="visible-sm btn-bordered"
                onClick={onClone}
                loading={mail.loading}
            />

        } else if (status.scheduled) {
            desktop[2] = <Button
                danger
                key="header-primary-cancel"
                icon={<CloseOutlined/>}
                className="hidden-sm"
                onClick={onCancel}
                children={t('common.action.cancel')}
            />

            mobile[2] = <Button
                danger
                key="header-primary-pause-sm"
                icon={<CloseOutlined/>}
                className="visible-sm"
                onClick={onCancel}
            />

        } else if (status.running) {
            desktop[2] = <Button
                key="header-primary-pause"
                danger
                icon={<PauseOutlined/>}
                className="hidden-sm"
                onClick={onPause}
                children={t('common.action.stop')}
            />

            mobile[2] = <Button
                danger
                key="header-primary-pause-sm"
                icon={<PauseOutlined/>}
                className="visible-sm"
                onClick={onPause}
            />

        } else if (item.status === 'paused') {
            desktop[2] = <Button
                key="header-primary-resume"
                danger
                icon={<CaretRightOutlined/>}
                className="hidden-sm"
                onClick={onResume}
                children={t('common.action.resume')}
            />

            mobile[2] = <Button
                danger
                icon={<CaretRightOutlined/>}
                key="header-primary-resume-sm"
                className="visible-sm"
                onClick={onResume}
            />
        }

        return [...desktop, ...mobile]
    }

    return (<AppWrapper onScroll={(v) => {
        setIsScrolled(v.scrollTop > 0)
    }}>
        <Layout className="site-layout site-layout-background">
            <Affix
                offsetTop={1}
                className={classes.affix}
                // onChange={()=>{headerRef.current.style.marginTop = '-1px'}}
            >
                <Header
                    // ref={headerRef}
                    style={{marginTop: -1}}
                    className={`site-layout-background page-container-horizontal-padding has-banner margin-bottom-md${isScrolled ? ' box-shadow' : ''}`}>
                    <PageHeader
                        title={mailTitleEdit ? <>
                            <Input
                                value={mailTitle}
                                onChange={onTitleChange}
                                // defaultValue={item.title || formValues.title}
                                //className="ant-input ant-input-lg"
                                size="large"
                                placeholder={t(adminSection + '.form.title.placeholder')}
                                className={classes.header_input}
                                onPressEnter={onTitleUpdate}
                            />
                            <Button
                                type="text"
                                onClick={onTitleUpdate}
                                icon={<CheckOutlined/>}
                                className={`inverted ${classes.header_input_ok}`}
                                loading={mail.loading}
                            />
                        </> : <div onClick={() => {
                            setMailTitleEdit(true)
                        }} className="cursor-pointer">
                            <EditOutlined className={classes.header_edit_icon}/>
                            <Title level={3} className={`inline ${classes.header_title}`}>
                                {mailTitle}
                            </Title>
                        </div>}
                        className="padding-none-horizontal"
                        extra={renderHeaderButtons()}
                    />
                </Header>
            </Affix>

            <Content id="effects-list-wrapper" className="site-layout-background page-container-horizontal-padding">
                {(app.status === 'ready') ? <Row gutter={50}>
                    <Col xs={24} sm={12}>
                        <section id="what" className="margin-bottom-md">
                            {RenderSectionTitle('what', <MessageOutlined/>)}
                            <Collapse isOpened={opened.what}>
                                <GraphBlock
                                    type="action"
                                    ref={graphRef}
                                    graphItem={graphItem}
                                    onChange={onGraphChange}
                                    nodesState={nodesState}
                                    setNodesState={setNodesState}
                                    // onSave={onSave}
                                    // forbidden={id && status.sent}
                                    // forbiddenMessage={t('mail.error.form.forbidden')}
                                />
                            </Collapse>
                        </section>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form
                            form={form}
                            initialValues={formValues}
                            onFinish={onSave}
                            onFinishFailed={onFailed}
                            onValuesChange={formOnChange}
                            className=""
                            name="settings"
                            layout="vertical"
                        >

                            {item.status === 'planning' ? <section id="process" className="margin-bottom-md">
                                {RenderSectionTitle('planning', <LoadingOutlined/>)}
                                <Collapse isOpened={opened.process}>
                                    <ProgressBar max={stat.selected} current={stat.sent}/>
                                </Collapse>
                            </section> : null}

                            {(status.running || status.scheduled || status.paused) ?
                                <section id="process" className="margin-bottom-md">
                                    {RenderSectionTitle('process', <CloudUploadOutlined/>)}
                                    <Collapse isOpened={opened.process}>
                                        <ProgressBar max={stat.selected} current={stat.sent}/>
                                    </Collapse>
                                </section> : null}

                            {item.status === 'sent' ? <section id="how-sent" className="margin-bottom-md">
                                {RenderSectionTitle('how', <LineChartOutlined/>)}
                                <Collapse isOpened={opened.how}>
                                    {Object.entries(stat).map(([name, value]) => {
                                        if (inArray(name, statExcluded)) return null;
                                        return <div key={name} className="margin-bottom-ps">
                                            <span className="float-right"> {value}</span>
                                            {t(adminSection + '.section.how.stat.' + name)}
                                        </div>
                                    })}
                                    <Button
                                        type="text"
                                        onClick={updateItemStat}
                                        icon={<RetweetOutlined/>}
                                        className="padding-none-horizontal inverted"
                                    >
                                        {t('common.action.update_stat')}
                                    </Button>
                                </Collapse>
                            </section> : null}

                            {item.status === 'editing' ? <section id="actions-editing" className="margin-bottom-sm">
                                {RenderSectionTitle('actions', <LineChartOutlined/>)}
                                <Collapse isOpened={opened.actions}>
                                    <Button
                                        type="primary"
                                        className="btn-bordered margin-bottom-sm"
                                        onClick={cancelEditing}
                                        icon={<CloseOutlined/>}
                                    >
                                        {t(section + '.section.actions.cancel_edit.label')}
                                    </Button>
                                </Collapse>
                            </section> : null}

                            <section id="when" className="margin-bottom-md">
                                {RenderSectionTitle('when', <CalendarOutlined/>)}
                                <Collapse isOpened={opened.when}>
                                    {status.active ? <DateTimeField
                                        t={t}
                                        name="start_at"
                                        className="margin-none"
                                        //bordered={false}
                                        disabled={true}
                                        label={''}
                                        section={adminSection + '.section.when'}
                                        format={'DD MMMM YYYY HH:mm'}
                                        placeholder={t('common.placeholder.datetime')}
                                        disabledDate={disabledDate}
                                        disabledTime={disabledTime}
                                    /> : <Tabs
                                        className="tabs-buttons"
                                        defaultActiveKey={timeTab}
                                        onChange={setTimeTab}
                                        items={[{
                                            key: 'now',
                                            label: t(adminSection + '.section.when.tab.now'),
                                            children: <span className={"text-secondary"}>
                                                      {t(adminSection + '.section.when.form.now.desc')}
                                                </span>,
                                        }, {
                                            key: 'schedule',
                                            label: t(adminSection + '.section.when.tab.schedule'),
                                            children: (<>
                                                <DateTimeField
                                                    t={t}
                                                    name="start_at"
                                                    className="margin-none"
                                                    label={''}
                                                    section={adminSection + '.section.when'}
                                                    format={'DD MMMM YYYY HH:mm'}
                                                    placeholder={t('common.placeholder.datetime')}
                                                />
                                                {/* TODO: fix blinking when is off */}
                                                {/*{isNowMode ? <div*/}
                                                {/*    className="color-danger margin-top-xp"*/}
                                                {/*    dangerouslySetInnerHTML={createMarkup(t(adminSection + '.form.start_at.desc'))}*/}
                                                {/*/> : null}*/}
                                            </>),
                                        }, // {
                                            //   label: `Tab 3`,
                                            //   key: 'event',
                                            //   children: `Content of Tab Pane 3`,
                                            // },
                                        ]}
                                    />}
                                </Collapse>
                            </section>


                            {item.status === 'sent' && item.start_at && item.start_at > (now - 24 * 3600) ?
                                <section id="actions" className="margin-bottom-sm">
                                    {RenderSectionTitle('actions', <LineChartOutlined/>)}
                                    <Collapse isOpened={opened.actions}>
                                        <Popconfirm
                                            getPopupContainer={() => document.getElementById('app-page-wrapper')}
                                            title={t(section + '.section.actions.edit.confirm')}
                                            onConfirm={onEditStart}
                                            icon={null}
                                            // placement="topLeft"
                                            okText={t('common.action.confirm')}
                                            cancelButtonProps={{type: 'text'}}
                                        >
                                            <Button
                                                type="primary"
                                                icon={<FormOutlined/>}
                                                className="margin-right-ps btn-bordered"
                                            >
                                                {t('common.action.edit')}
                                            </Button>
                                        </Popconfirm>

                                        <Popconfirm
                                            getPopupContainer={() => document.getElementById('app-page-wrapper')}
                                            title={t(section + '.section.actions.delete.confirm')}
                                            onConfirm={onDeleteMessages}
                                            icon={null}
                                            placement="topRight"
                                            okText={t('common.action.confirm')}
                                            cancelButtonProps={{type: 'text'}}
                                        >
                                            <Button
                                                type="primary"
                                                danger={true}
                                                // onClick={() => console.log('action.delete')}
                                                icon={<DeleteOutlined/>}
                                                className="btn-bordered"
                                            >
                                                {t('common.action.delete')}
                                            </Button>
                                        </Popconfirm>

                                        <div
                                            className="margin-top-md font-size-sm"
                                            dangerouslySetInnerHTML={createMarkup(t(section + '.section.actions.desc'))}
                                        />
                                    </Collapse>
                                </section> : null}

                            <section id="where" className="margin-bottom-md">
                                {RenderSectionTitle('where', <RobotOutlined/>)}
                                <Collapse isOpened={opened.where}>
                                    <FormFields t={t} section={'effect.core.timer.branching.schedule'} fields={[{
                                        name: 'integration_ids',
                                        type: 'tags',
                                        values: integrations,
                                        data: 'integration',
                                        data_filters: {'integration.platform': messengers},
                                        placeholder: true, // required: !status.active,
                                        disabled: status.active,
                                    }]} formValues={formValues}/>
                                </Collapse>
                            </section>

                            <section id="whom" className="margin-bottom-md">
                                {RenderSectionTitle('whom', <FilterOutlined/>, stat.selected)}
                                <Collapse isOpened={opened.whom}>
                                    <FormFields
                                        t={t}
                                        section={'effect.core.timer.branching.schedule'}
                                        fields={formFields}
                                        formValues={formValues}
                                    />
                                </Collapse>
                            </section>

                            {item.status === 'sent' ? null : <section id="how" className="margin-bottom-md">
                                {RenderSectionTitle('how', <LineChartOutlined/>)}
                                <Collapse isOpened={opened.how}>
                                    {Object.entries(stat).map(([name, value]) => {
                                        if (inArray(name, statExcluded)) return null;
                                        return <div key={name} className="margin-bottom-ps">
                                            <span className="float-right"> {value}</span>
                                            {t(adminSection + '.section.how.stat.' + name)}
                                        </div>
                                    })}
                                </Collapse>
                            </section>}

                            {item.id && item.status && !status.draft && !status.sent ? <Button
                                type="text"
                                onClick={updateItemStat}
                                icon={<RetweetOutlined/>}
                                className="padding-none-horizontal inverted"
                            >
                                {t('common.action.update_stat')}
                            </Button> : null}
                        </Form>
                    </Col>
                </Row> : <Preloader/>}

                <Form.Item>
                    <Button type="text" onClick={backToList} icon={<ArrowLeftOutlined/>}>
                        {t('common.action.back')}
                    </Button>

                    {(item.status === 'sent') ? null : <Button
                        type="primary"
                        htmlType="submit"
                        icon={<CheckOutlined/>}
                        onClick={saveAndBack}
                        // loading={inArray(item.status, ['start', 'planning'])}
                    >{t('common.action.save')}</Button>}

                    {/*<Button type="text" onClick={backToList} icon={<CloseOutlined />}>*/}
                    {/*    {t('common.action.cancel')}*/}
                    {/*</Button>*/}

                    {/*<Button type="text" icon={<SnippetsOutlined />}>*/}
                    {/*    {t(section + '.button.to_templates')}*/}
                    {/*</Button>*/}

                </Form.Item>

                <SimpleModal
                    title={t(section + '.confirm.title')}
                    isVisible={isVisibleConfirm}
                    setVisible={setVisibleConfirm}
                    actionLabel="approve"
                    onOk={onOk}
                    onCancel={cancelConfirm}
                    loading={mail.loading}
                >
                    <div dangerouslySetInnerHTML={createMarkup(t(section + '.confirm.desc', {
                        postProcess: 'sprintf', sprintf: [toNum(stat.selected), // toNum(stat.delivered),
                        ]
                    }))}/>
                </SimpleModal>

            </Content>

            {/*<SetItemEditFormOrPreloader*/}
            {/*    id={id}*/}
            {/*    item={item}*/}
            {/*    section={section} // for labels*/}
            {/*    form={form}*/}
            {/*    formFields={formFields}*/}
            {/*    formValues={formValues}*/}
            {/*    onFinish={onFinish}*/}
            {/*    onFailed={onFailed}*/}
            {/*    backToList={backToList}*/}
            {/*/>*/}
        </Layout>
    </AppWrapper>)
}

export default MailEdit