import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addUrlToList,
    fillUrlList,
    patchUrlInList,
    removeUrlFromList,
    setUrlStoreParam,
    setUrlItem,
} from "../reducers/UrlReducer";

// init api methods
const base_path = backend_api.url;

function* getUrlList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUrlList(response.data.result))
            yield put(setUrlStoreParam({count: response.data.count}))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUrlItem(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id,
            'get',
        );

        if (response.data.ok) yield put(setUrlItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createUrl(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(setUrlItem(response.data.result))
            yield put(addUrlToList(response.data.result)) 
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateUrl(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(setUrlItem(response.data.result))
            yield put(patchUrlInList(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreUrl(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeUrlFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteUrl(arg) {
    try {
        const response = yield request(
            arg.admin.token,
            base_path + '/' + arg.id,
            'delete');

        if (response.data.ok) yield put(removeUrlFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* urlSaga() {
    yield takeEvery('getUrlList', getUrlList);
    yield takeEvery('getUrlItem', getUrlItem);
    yield takeEvery('createUrl',  createUrl);
    yield takeEvery('updateUrl',  updateUrl);
    yield takeEvery('restoreUrl', restoreUrl);
    yield takeEvery('deleteUrl',  deleteUrl);
}

