import {Dropdown, Menu} from "antd";
import classes from "./ListTable.module.scss";
import React from "react";

export const ListTableRowMenu = (
    {
        items,
        record,
        label = '...',
        className=undefined,
        placement='bottomRight',
    }
) => {

    const menuItems = items.map((item, index) => {
        if (!item) return null

        let label = item.label;
        if (item.render) {
            label = item.render(record)
        }

        return (
            {
                key: `row_menu_item_${index}`,
                icon: item.icon || null,
                label: <div onClick={() => {
                    item.action(record)
                }}>{label}</div>,
            }
        )
    })

    return (
        <Dropdown
            menu={{items: menuItems}}
            trigger={['click']}
            placement={placement}
            getPopupContainer={() => document.getElementById('app-page-wrapper')}
        >
            <div className={`user-select-none ${className === undefined ? classes.action_content : className}`}>{label}</div>
        </Dropdown>
    )
}

export default ListTableRowMenu