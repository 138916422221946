// Immutable object
import {notice} from "./notice";
import {deepGet, inArray} from "./functions";

export class Io {
    constructor(data) {
        // Array.isArr(obj)
        this.data = Array.isArray(data) ? [...data] : {...data}
        this.space = this.data
        this.memory = {}
        this.path = []
    }

    remember(name = 'space') {
        this.memory[name] = this.space;
        return [this.space, this.memory]
    }

    restore(name = 'space') {
        this.space = this.memory[name];
        return [this.space, this.memory]
    }

    get(propName) {
        return propName ? this.space[propName] : this.space;
    }

    set(values) {
        // set object cursor
        let s = this.space
        for (const key in values) {
            if (key.indexOf('.') > 0) {
                // deep set value
                const pathKeys = key.split('.')
                const lastKey = pathKeys.pop()
                for (const k of pathKeys) {
                    if (s[k] === undefined) s[k] = {}
                    else s[k] = Array.isArray(s[k]) ? [...s[k]] : {...s[k]}
                    s = s[k]
                }
                s[lastKey] = values[key]
            }
            else {
                // normal value updating
                s[key] = values[key]
            }
            // reset object cursor
            s = this.space
        }

        return this.space
    }

    go(path = '') {
        let empty = {}
        let pathItems = path

        if (typeof path === 'object' && !Array.isArray(path)) {
            // console.log('go object', path)
            pathItems = path.path ?? false
            empty = path.empty
        }
        else if (typeof path === 'number') {
            // console.log('go number', path)
            pathItems = [path]
        }

        if (typeof pathItems === 'string') {
            pathItems = pathItems.split('.')
            // console.log('go: string', pathItems)
        } else if (!Array.isArray(pathItems)) {
            console.error('Io: unsupported path type for go() function', typeof pathItems, pathItems)
            return
        }

        // pathItems.forEach(s => (this.data[s] = this.data[s] || {}) && (this.data = this.data[s]));
        for (const s of pathItems) {
            if (this.space[s] === undefined) this.space[s] = empty
            else this.space[s] = Array.isArray(this.space[s]) ? [...this.space[s]] : {...this.space[s]}
            this.space = this.space[s]
        }

        return this.space
    }

    push(items) {
        if (!Array.isArray(this.space)) {
            this.space = []
            console.error('Push: space is not array', this.space, this.data)
            // return this.space
        }
        for (const item of items) {
            this.space.push(item)
        }
        return this.space
    }

    unshift(items) {
        if (!Array.isArray(this.space)) console.error('Unshift: space is not array', this.space)
        for (const item of items) {
            this.space.unshift(item)
        }
        return this.space
    }

    remove(props) {
        // props = {from: 'effects', by: 'spec.truePorts.0', value: localId} - recommended
        // or props = {by: 'spec.truePorts.0', value: localId} - (for root)
        if (props.by) {
            if (!props.from) this.space = this.space.filter(elem => deepGet(elem, props.by) !== props.value)
            else this.space[props.from] = this.space[props.from].filter(elem => deepGet(elem, props.by) !== props.value)
        }
        else {
            if (!props.from) this.space = this.space.filter(elem => elem !== props.value)
            else this.space[props.from] = this.space[props.from].filter(elem => elem !== props.value)
        }


        return this.space
    }

    clone(args) {
        return new Io(this.data);
    }

    find(args) {
        const [prop, value] = args
        // console.log('this.data find', this.data)
        const index = this.space.findIndex(elem => deepGet(elem, prop) === value)
        if (index < 0) {
            console.error('Io Find: item is not found', prop, value, this.space)
            return undefined
        }
        this.go([index])
        return index
    }

    replace(args) {
        const [prop, value, item] = args
        // console.log('this.data find', this.data)
        const index = this.space.findIndex(elem => deepGet(elem, prop) === value)

        if (index < 0) {
            console.error('Io Replace: item is not found', prop, value, this.space)
            return undefined
        }
        this.space[index] = item
        return index
    }
}

export const upd = (data, commands, error = 'Item is undefined', logCommands = false) => {
    let result = new Io(data);

    for (const commandObject of commands) {
        const [[command, arg]] = Object.entries(commandObject);
        const commandResult = result[command](arg)
        if (inArray(command, ['find', 'replace']) && commandResult === undefined) {
            if (error) notice.error(error)
            return undefined
        }
        if (logCommands) console.info('Immutable command:', command, arg, commandResult)
    }
    return result.data
}

// let res = new Io(graph);

// res.go(['nodes'])
//
// res.push([{localId: 3, text: {value: 'Item 4'}}])
// res.unshift([{localId: 0, text: {value: 'Item 1'}}])
//
// res.find(['localId', 2])
// res.go(['text'])
// res.set({value: 'New Name: Item 3'})

//
// let res = upd(nodes, [
//     // {go: 'nodes'},
//     {push: [{localId: 3, text: {value: 'Item 3'}}]},
//     {find: ['localId', 2]},
//     {set: {spec: {}}},
//     {go: 'text'},
//     {set: {value: 'New Name: Item 2'}},
// ], 'Node undefined')
//
// console.log('Result:', res)


// res = deepSet(graph, 'nodes.{localId:2}.text', 'New Name')