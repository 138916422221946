import {coreText} from "./core/text/list";
import {corePage} from "./core/page/list";
import {coreForm} from "./core/form/list";
import {coreCode} from "./core/code/list";
import {coreMedia} from "./core/media/list";
import {coreCover} from "./core/cover/list";
import {coreFeatures} from "./core/features/list";
import {coreTariffs} from "./core/tariffs/list";
import {coreGame} from "./core/game/list";
import {coreNav} from "./core/nav/list";
import {coreContent} from "./core/content/list";

export const containersMap = {
    'core/cover': {
        icon: 'image-landscape',
        list: coreCover,
    },
    'core/text': {
        icon: 'align-left',
        list: coreText
    },
    'core/form': {
        icon: 'pen-to-square',
        list: coreForm,
    },
    'core/media': {
        icon: 'photo-film',
        list: coreMedia,
    },
    'core/features': {
        icon: 'thumbs-up',
        list: coreFeatures,
    },
    'core/tariffs': {
        icon: 'money-bill',
        list: coreTariffs,
    },
    'core/page': {
        icon: 'file-lines',
        list: corePage,
    },
    'core/nav': {
        icon: 'compass',
        list: coreNav,
    },
    'core/interactive': {
        icon: 'chevron-square-down',
        list: coreContent,
    },
    'core/game': {
        icon: 'trophy-star',
        list: coreGame,
    },
    'core/code': {
        icon: 'code',
        list: coreCode,
    },
}

