import {createSlice} from "@reduxjs/toolkit";

const RoleReducer = createSlice({
    name: ' role',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillRoleList(state, action) {
            state.list = [...action.payload]
        },
        setRoleItem(state, action) {
            state.item = action.payload;
        },
        addRoleToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        patchRoleInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        removeRoleFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        resetRoleState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default RoleReducer.reducer
export const {
    fillRoleList,
    setRoleItem,
    addRoleToList,
    patchRoleInList,
    removeRoleFromList,
    resetRoleState,
} = RoleReducer.actions