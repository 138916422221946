import React from 'react'

const FontTsize = ({size}) => {
    return (
        <span role="img" aria-label="font-size" className="anticon anticon-font-size" style={{fontSize:size}}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="line-height" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M648 160H104c-4.4 0-8 3.6-8 8v128c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-64h168v560h-92c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-92V232h168v64c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8z"/>
            </svg>
        </span>
    )
}

export default FontTsize