import React, {useContext} from 'react'
import classes from './Header.module.scss'
import {useTranslation} from "react-i18next";
import {copyToClipboard} from "../../../../../../library/clipboard";
import {AiOutlineArrowLeft} from "react-icons/ai";
import FlowContext from "../../../../FlowContext";
import {isNodeIgnored} from "../../../../library/flowFunctions";

const Header = ({nodeLocalId = 0, title = '', type = 'none', icon = null, showBackToDefault = false}) => {
    const {t} = useTranslation();
    const {chosenNode, setControlPanel} = useContext(FlowContext);
    let blockColor = type;
    if (isNodeIgnored(chosenNode)) blockColor = 'none'

    return (
        <div className={classes.cpanel_header_wrapper}>
            <div className={classes.cpanel_header_title_wrapper}>
                {showBackToDefault ? <div className={classes.cpanel_header_arrow} onClick={() => {
                    setControlPanel('default');
                }}><AiOutlineArrowLeft/></div> : null}

                <div className={classes.cpanel_header_icon} data-node-type={blockColor}>{icon}</div>

                <div className={classes.cpanel_header_title}>
                    <div className={classes.text}>{title}</div>
                    {nodeLocalId ? <div title={t('graph.flow.panel.header.click_to_copy')} className={classes.node_id}
                                        onClick={() => {
                                            copyToClipboard(nodeLocalId);
                                        }}>
                        <span className="hidden-xs">{t('graph.flow.panel.header.local_id')}: #{nodeLocalId}</span>
                        <span className="visible-xs">{t('graph.flow.panel.header.local_id_xs')}: #{nodeLocalId}</span>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}

export default Header