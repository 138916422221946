import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip} from 'antd';
import {DeleteOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {routes} from "../../../config/config";
import {l} from "../../../library/locale";
import {ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import ListTable from "../../../components/List/Table/ListTable";
import {copyToClipboard} from "../../../library/clipboard";
import {AiOutlineCopy, AiOutlineSetting} from "react-icons/ai";
import {SorterWrapper} from "../../../components/List/Table/SorterWrapper";
import {setOrderStoreParam} from "../../../redux/reducers/OrderReducer";

const statusColors = {
    active: 'color-warning',
    finished: 'color-success',
    canceled: 'color-danger',
    refunded: 'color-primary',
};

const getColorByStatus = (status) => {
    return statusColors[status] ?? ''
}

export const renderStatusesMenu = (record, section, statuses, onChange) => {
    return <Tooltip title={l('common.action.click_for_toggle')}>
        <ListTableRowMenu
            items={statuses.map((status) => {
                if (status === record.status) return null;
                return {
                    key: 'status-' + status,
                    label: l(section + '.form.status.value.' + status),
                    action: () => onChange(record.id, status)
                }
            })}
            record={record}
            label={<span
                className={getColorByStatus(record.status)}>{l(section + '.form.status.value.' + record.status)}</span>}
            className={''}
            placement={'bottomLeft'}
        />
    </Tooltip>
}

export const OrderListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        statuses = [],
        currentPage = 1,
        pageSize = 10,
    }
) => {
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordUrl = (id, sect, action) => `${routes.project_list}/${project.id}/${sect}/${action}/${id}`
    const recordEdit = (id) => navigate(recordUrl(id, section, 'edit'))
    const recordOpen = (id) => recordEdit(id)
    const userOpen = (id) => {
        if (!id) return;
        navigate(recordUrl(id, 'user', 'profile'))
    }
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            status: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer text-secondary align-center',
            title: <SorterWrapper>&nbsp;&nbsp;{l('common.table.header.id')}</SorterWrapper>,
            sorter: true,
            width: 60,
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.id, l('common.result.copied.neuter'))
                };
            },
        },
        {
            dataIndex: 'key',
            className: '',
            title: l('order.list.table.title'),
            responsive: ['xs'],
            render: (value, record) => (
                <>
                    <div className="cursor-pointer" onClick={() => recordOpen(record.id)}>
                        {record.title}
                    </div>
                    <div>
                        <span className="inline cursor-pointer color-secondary margin-right-sm">
                            {renderStatusesMenu(record, section, statuses, recordChangeStatus)}
                        </span>
                        <span className="inline color-secondary">
                            {record.amount} / {record.price} {l('common.money.symbol')}
                        </span>
                    </div>
                </>
            ),
        },
        {
            dataIndex: 'title',
            responsive: ['sm'],
            className: 'cursor-pointer',
            title: <SorterWrapper>{l('common.table.header.label')}</SorterWrapper>,
            sorter: true,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
            render: (value, record) => {
                return <a
                    className="inline full-width color-default"
                    href={recordUrl(record.id, section, 'edit')}
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                >{value}</a>
            },
        },
        {
            dataIndex: 'user_id',
            className: 'cursor-pointer color-secondary',
            title: l('common.table.header.client'),
            responsive: ['xl'],
            render: (value, record) => {
                return <span>{record.user.name}</span>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => userOpen(record.user_id)
                };
            },
        },
        {
            dataIndex: 'amount',
            className: 'cursor-pointer text-secondary',
            title: <SorterWrapper>{l('common.table.header.payed')}</SorterWrapper>,
            sorter: true,
            responsive: ['xxl'],
            width: 100,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'price',
            className: 'cursor-pointer text-secondary',
            title: <SorterWrapper>{l('common.table.header.price')}</SorterWrapper>,
            sorter: true,
            responsive: ['sm'],
            width: 100,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'status',
            className: 'cursor-pointer',
            title: <SorterWrapper>{l('common.form.status')}</SorterWrapper>,
            sorter: true,
            responsive: ['sm'],
            width: 115,
            render: (value, record) => {
                return renderStatusesMenu(record, section, statuses, recordChangeStatus)
            },
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}>
                            <AiOutlineCopy/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('table.menu.edit')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record.id)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuCopy',
            label: l('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: parseInt(record.id),
            key: record.id + '-listRow',
            price: record.price,
            amount: record.amount,
            user_id: record.user_id,
            user: record.user ?? {'name': '—'},
            title: record.title,
            status: record.status,
            deleted: record.deleted_at > 0,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setOrderStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{l('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{l('common.action.restore')}</Button>
            </div>
        </div>
    )
}

