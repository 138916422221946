import React from 'react'
import CollapseCard from "./CollapseCard";


export const CollapseAccordion = ({items}) => {

    return (
        <>
            {items.map((item, index) => {
                    if (!item.content) return
                    return <CollapseCard
                        key={`collapse_item_${index}`}
                        title={item.title}
                        icon={item.collapseIcon}
                        content={item.content}
                    />
                }
            )}
        </>
    )
}