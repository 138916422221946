// time: 48 min +

export const coreCode = {
    'space': {
        content: [{
            type: 'con',
            style: {
                padding: {
                    bottom: '20',
                }
            },
            items: []
        }]
    },
    'import': {}
}