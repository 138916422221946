import React from 'react'
import {useTranslation} from "react-i18next";
import {stripHtmlTags, toNum} from "../../../../../../library/functions";

const rowWidthMap = {
    '1': {sm: '12', md: '12'},
    '2': {sm: '6', md: '6'},
    '3': {sm: '6', md: '4'},
    '4': {sm: '6', md: '3'},
}

export const BlockPageList = (spec) => {
    const {t} = useTranslation();
    return spec ? <>
        <ul>
            {[0, 1, 2].map((item) => {
                return <li key={item}><a className="cursor-pointer">{t('container.example.pages.list.' + item)}</a></li>
            })}
        </ul>
    </> : null
}

export const BlockPageCards = (spec) => {
    const {t} = useTranslation();
    // console.log('spec', spec)

    const lorem = t('container.example.pages.lorem')
    const list = [
        {
            title: t('container.example.pages.list.0'),
            description: lorem,
            photo_url: t('container.example.pages.photo.0'),
            active: true,
        },
        {
            title: t('container.example.pages.list.1'),
            description: lorem,
            photo_url: t('container.example.pages.photo.1'),
            closed: true,
        },
        {
            title: t('container.example.pages.list.3'),
            description: lorem,
            photo_url: t('container.example.pages.photo.3'),
        },
    ];

    if (spec.cards_per_row !== '3') {
        list.push({
            title: t('container.example.pages.list.4'),
            description: lorem,
            photo_url: t('container.example.pages.photo.4'),
        })
    }

    const renderDesc = (description) => {
        let desc = stripHtmlTags(description);
        desc = desc.trim()
        if (spec.desc_limit !== '0') {
            const limit = toNum(spec.desc_limit) || 200;
            desc = desc.substring(0, limit) + (desc.length > limit ? '...' : '');
        }
        return desc;
    }

    let renderItemContent = (item) => {
        return <li className="c-item">
            <a
                // href={'/' + item.path.join('/')}
                className="c-item-link"
            >
                <div className="c-item-photo">
                    {item.photo_url ? <img src={item.photo_url} alt={item.title}/> : null}
                </div>
                <div className="c-item-title">{item.title}</div>
                <div className="c-item-desc">{renderDesc(item.description)}</div>
            </a>
        </li>
    }

    // visual dev divider
    if (spec.style === 'blocks') {
        renderItemContent = (item) => {
            return <li className="c-item">
                <a
                    // href={'/' + item.path.join('/')}
                    className="c-item-link flow-root w-full mb-4 rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out"
                >

                    <div
                        className="c-item-photo inline-block w-32 h-24 float-left mr-4
                            rounded-lg overflow-hidden bg-secondary-300 bg-cover bg-center bg-no-repeat"
                        style={{
                            'backgroundImage': `url(${item.photo_url})`,
                        }}
                    />

                    <div className="c-item-text pt-0.5 pb-1 pl-0 pr-5">
                        <div className="c-item-title text-h4 mb-2">{item.title}</div>
                        <div className="c-item-desc text-md text-secondary-700">{renderDesc(item.description)}</div>
                    </div>
                </a>
            </li>
        }
    }
    // visual dev divider
    else if (spec.style === 'cards') {
        renderItemContent = (item) => {
            const colW = rowWidthMap[spec.cards_per_row]?.md ?? 3;
            const colWSm = rowWidthMap[spec.cards_per_row]?.sm ?? 6;
            let itemClasses = `c-item col-span-12 xs:col-span-${colWSm} sm:col-span-${colW}`;

            return <li className={itemClasses}>
                <a
                    // href={'/' + item.path.join('/')}
                    className="c-item-link mb-4 border-1 border-gray-100 rounded-lg overflow-hidden shadow-md h-full
                    flex flex-col items-start hover:bg-gray-100 transition duration-300 ease-in-out"
                >
                    <div
                        className="c-item-photo aspect-w-4 aspect-h-3 bg-secondary-300 bg-cover bg-center bg-no-repeat"
                        style={{
                            'backgroundImage': `url(${item.photo_url})`,
                        }}
                    />
                    <div className="c-item-text pt-2 pb-2 pl-5 pr-5">
                        <div className="c-item-title text-h4 mt-2 mb-2">{item.title}</div>
                        <div className="c-item-desc text-md text-secondary-700">{renderDesc(item.description)}</div>
                    </div>
                </a>
            </li>
        }
    }
    // visual dev divider
    else if (spec.style === 'nav') {
        renderItemContent = (item) => {
            const isAllowed = !item.closed;

            let itemClasses = "c-item mb-1";
            let linkClasses = 'c-item-link flow-root rounded-md w-full pt-2 pb-2 pl-5 pr-5 ' +
                'transition duration-300 ease-in-out ' +
                `hover:bg-gray-100 dark:hover:bg-gray-900 `;

            let titleClasses = 'c-item-title';
            let imageClasses = 'c-item-photo inline-block w-32 h-22 float-left mr-4 ' +
                'rounded-lg overflow-hidden bg-secondary-300 bg-cover bg-center bg-no-repeat';

            // is item active
            if (item.active) {
                itemClasses += ' active';
                linkClasses += ' bg-primary-100 hover:bg-primary-100 dark:bg-primary-800 dark:hover:bg-primary-800' +
                    ' dark:text-white';
            }

            if (!isAllowed) {
                itemClasses += ' closed';
                imageClasses += ' opacity-75 grayscale'; // opacity-50
                linkClasses += ` text-gray-400 dark:text-gray-500`;
            } else {
                linkClasses += ` text-gray-700 hover:text-gray-900`;
                linkClasses += ` dark:text-gray-200`;
            }

            return <li class={itemClasses}>
                <div class={linkClasses}>

                    {spec.show_image && <div
                        class={imageClasses}
                        style={{'backgroundImage': `url(${item.photo_url})`}}
                    />}

                    <span class={titleClasses}>{item.title}</span>
                </div>
            </li>
        }

    }

    let listClasses = 'c-list';
    if (spec.style === 'cards') listClasses += ' grid grid-cols-12 gap-4';

    return list && list.length ? <ul className={listClasses}>
        {list.map((item) => renderItemContent(item))}
    </ul> : <div className="c-list-empty"/>
}