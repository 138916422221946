import {dotenv} from "../../../../../../config/config";

export const coreMedia = {
    'image': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/media/image',
                            spec: {
                                photo_url: 'https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-12-08_10-05-13_1Ne.jpg',
                                alt: 'Powered by ' + dotenv.public_name,
                            },
                        }
                    ]
                }
            ],
            style: {
                classes: {
                    custom: ['w-full'],
                },
                width: {
                    container: 'sm',
                },
                padding: {
                    top: '8',
                    bottom: '8',
                    // left: '6',
                    // right: '6',
                },
                flex: {
                    direction: "row",
                    gap: "3",
                    items: "stretch",
                    justify: "center",
                    content: "center",
                },
            }
        }]
    },
    'banner': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/media/image',
                            spec: {
                                photo_url: 'https://multyai.storage.yandexcloud.net/project/3/flow/2/2024-02-18_15-03-10_MZ2.jpg',
                                alt: 'Powered by ' + dotenv.public_name,
                                original_size: true,
                            },
                        }
                    ]
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    justify: "center",
                    content: "center",
                },
            }
        }]
    },
    'video/html': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/media/video/html',
                            spec: {
                                files: [{
                                  "uid": "example-1",
                                  "url": "https://storage.yandexcloud.net/multy/Video/Welcome/Welcome.mp4",
                                  "name": "Welcome.mp4",
                                  "status": "done"
                                }],
                                enable_player: true,
                                lang: dotenv.default_lang,
                            },
                        }
                    ],
                    style: {
                        flex: {
                            direction: 'row',
                        },
                        grow: {
                            content: true,
                        }
                    }
                }
            ],
            style: {
                width: {
                    container: 'md',
                },
                padding: {
                    top: '8',
                    bottom: '8',
                    left: '4',
                    right: '4',
                }
            }
        }]
    },
    'video/youtube': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/media/video/youtube',
                            spec: {
                                url: 'https://www.youtube.com/watch?v=ejKtS0umAoE',
                                // url: 'https://www.youtube.com/watch?v=7mekPUoxD00',
                            },
                        }
                    ]
                }
            ],
            style: {
                width: {
                    container: 'md',
                },
                padding: {
                    top: '8',
                    bottom: '8',
                    left: '4',
                    right: '4',
                }
            }
        }]
    },
}