import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {EmployeeListTable} from "./EmployeeListTable";
import {objectLength, ucFirst} from "../../../library/functions";
import InputModal from "../../../components/Modal/InputModal";
import {useTranslation} from "react-i18next";
import {notice} from "../../../library/notice";

const {Content} = Layout;

const EmployeeList = () => {
    // init section name
    const section = 'employee'
    const Section = ucFirst(section)

    // init hook functions
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store and state
    const {admin, project, employee} = useSelector(store => store)
    const [filters, setFilters] = useState({})
    const [isVisibleModal, setVisibleModal] = useState(false);

    // init universal vars
    const project_item = project.item
    const list = employee.list
    const rowsTotal = employee.count || 0
    const currentPage = employee.page || 1
    const pageSize = employee.pageSize || 100
    const sorter = employee.sorter

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                // profile_types: ['user', 'bot', 'chat', 'channel'],
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }


    const recordAdd = (value) => {
        if (!value) {
            notice.error(t(section + '.error.email_empty'))
            return false
        }

        dispatch({
            type: 'create' + Section, admin, data: {
                project_id: project_item.id,
                email: value
            }
        });
    }

    const filterFields = [
        [
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off'],
                section: section,
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    // onSearch={searchHandler}
                    onPrimary={() => setVisibleModal(true)}
                    primaryLabel={t('common.action.add')}
                />
                <Content className="page-container site-layout-background">
                    <EmployeeListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                    />
                </Content>

                <InputModal
                    initValue={''}
                    isVisible={isVisibleModal}
                    setVisible={setVisibleModal}
                    onModalOk={recordAdd}
                    title={t('employee.modal.add.title')}
                    placeholder={t('employee.modal.add.placeholder')}
                    desc={t('employee.modal.add.desc')}
                />
            </Layout>
        </AppWrapper>
    )
}
export default EmployeeList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link