import React from "react";

export const ResponsiveText = ({sm='', md=''}) => {
    const sm_span = sm ? (<span className="visible-sm-inline">{sm}</span>) : '';
    const md_span = md ? (<span className="hidden-sm">{md}</span>) : '';
    return (<span>
        {sm_span}
        {md_span}
    </span>)
}

