import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {setCorrect} from "../reducers/AdminReducer";
import {fillUserFieldSet, removeUserField, setUserField} from "../reducers/UserFieldReducer";
import {
    fillFieldValueList,
    patchFieldValueInList,
    removeFieldValueFromList,
    setFieldValueItem
} from "../reducers/FieldValueReducer";

// init api methods
const base_path = backend_api.user_field;


function* getUserFieldSet(arg) {
    try {
        const response = yield request(arg.admin.token, base_path+'/values', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUserFieldSet(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUserField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/item', 'get', arg.filters);

        if (response.data.ok) {
            if (arg.filters.field_name) response.data.result['field'] = {name: arg.filters.field_name}
            yield put(setUserField(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect(false))
        else apiErrorNotice(error)
        return null
    }
}

function* storeUserField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            if (arg.data.field_name) response.data.result['field'] = {name: arg.data.field_name}
            yield put(setUserField(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
    }
}

function* deleteUserField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'delete', arg.filters);

        if (response.data.ok) yield put(removeUserField(response.data.result));
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
    }
}

// =========== field values section ===========

function* getFieldValueList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillFieldValueList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
    }
}

function* getFieldValue(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/item', 'get', arg.filters);

        if (response.data.ok) yield put(setFieldValueItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect(false))
        else apiErrorNotice(error)
        return null
    }
}

function* storeFieldValue(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(patchFieldValueInList(response.data.result))
            yield put(setFieldValueItem(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
    }
}

function* restoreFieldValue(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeFieldValueFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteFieldValue(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'delete', arg.filters);
        if (response.data.ok) yield put(removeFieldValueFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
    }
}

export function* userFieldSaga() {
    yield takeEvery('getUserFieldSet', getUserFieldSet);
    yield takeEvery('getUserField', getUserField);
    yield takeEvery('storeUserField', storeUserField);
    yield takeEvery('deleteUserField', deleteUserField);

    yield takeEvery('getFieldValueList', getFieldValueList);
    yield takeEvery('getFieldValue', getFieldValue);
    yield takeEvery('storeFieldValue', storeFieldValue);
    yield takeEvery('restoreFieldValue', restoreFieldValue);
    yield takeEvery('deleteFieldValue', deleteFieldValue);
}

