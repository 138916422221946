import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import {useTranslation} from "react-i18next";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {
    compareObjects,
    getSearchFilters,
    isArr,
    objectLength,
    setSearchFilters,
    ucFirst
} from "../../../library/functions";
import {setOrderStoreParam} from "../../../redux/reducers/OrderReducer";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {OrderListTable} from "./OrderListTable";

const {Content} = Layout;

const OrderList = () => {
    // init section name
    const section = 'order'

    // init hook functions
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const searchFilters = getSearchFilters(searchParams);

    // store and state
    const {admin, project, order} = useSelector(store => store)
    const [filtersClickCount, setFiltersClickCount] = useState(0);
    const filters = order.filters

    // init universal vars
    const project_item = project.item
    const list = order.list
    const rowsTotal = order.count || 0
    const currentPage = order.page || 1
    const pageSize = order.pageSize || 10
    const sorter = order.sorter

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                add_users: true,
                // profile_types: ['user', 'bot', 'chat', 'channel'],
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) {
                // filters_result = {...filters_result, ...filters}
                for (const [key, val] of Object.entries(filters)) {
                    if (val === '') filters_result[key] = ' '
                    else filters_result[key] = val
                }
            }

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize, filtersClickCount, order.timestamp])

    useEffect(() => {
        if (!compareObjects(filters, searchFilters)) storeFilters(searchFilters)

        //eslint-disable-next-line
    }, [JSON.stringify(searchFilters)])

    const storeFilters = (newFilters) => {
        // for params contain _id covert type to array of ints:
        for (let key in newFilters) {
            if (key.includes('_id') || key.includes('type')) {
                if (!isArr(newFilters[key])) newFilters[key] = [newFilters[key]]
            }
        }
        dispatch(setOrderStoreParam({filters: newFilters, page: 1}))
    }

    const onFiltersSubmit = (newFilters) => {
        setFilters(newFilters)
        setFiltersClickCount((prev) => prev + 1)
    }

    const setFilters = (newFilters) => {
        // console.log('setFilters', newFilters)
        setSearchFilters(newFilters, setSearchParams)
    }

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordEdit = () => {
        navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0`)
    }

    const filterFields = [
        [

            {
                name: 'user_ids',
                type: 'tags',
                features: ['adding'],
                section: 'order',
                placeholder: true,
            },
            {
                name: 'manager_user_ids',
                type: 'tags',
                features: ['adding'],
                section: 'order',
                placeholder: true,
            },
            {
                name: 'statuses',
                type: 'tags',
                section: 'order',
                localized: true,
                values: order.statuses
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={onFiltersSubmit}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordEdit}
                    addUtms={true}
                />
                <Content className="page-container site-layout-background padding-top-none">
                    <div className="margin-bottom-pm margin-top-xp font-size-md color-secondary"
                         style={{paddingLeft: 3}}>
                        <span className="inline margin-right-md">
                            {objectLength(filters) ?
                                t(section + '.list.counter.filtered') :
                                t(section + '.list.counter.total')
                            }: {rowsTotal}
                        </span>
                        {/*<div className="float-right">*/}
                            <span className="inline margin-right-md">
                                {t(section + '.list.counter.amount')}: {order.amount || 0}
                            </span>
                            <span className="inline">
                                {t(section + '.list.counter.price')}: {order.price || 0}
                            </span>
                        {/*</div>*/}
                    </div>

                    <OrderListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        statuses={order.statuses}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                    />
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default OrderList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link