import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {l} from "../../../library/locale";
import {Button, Dropdown} from "antd";
import {EllipsisOutlined, FilterOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {SearchBox} from "../../Search/SearchBox";
import {CustomHeader} from "../../Layout/CustomHeader";
import {objectLength} from "../../../library/functions";

export const ListHeader = (
    {
        section,
        title = null,
        filters = null,
        setFilters = null,
        onSearch = null,
        onPrimary = null,
        primaryIcon = null,
        primaryLabel = null,
        searchPlaceholder = null,
        onPrimaryWithFolder = null,
        primaryMenu = null,
        addUtms = false,
        filterFields = [],
    }
) => {
    const [isFiltersOpened, setFiltersStatus] = useState(filters && objectLength(filters) > 0);
    const [isXsFiltersOpened, setXsFiltersStatus] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const toggleFiltersStatus = () => {
        setFiltersStatus(!isFiltersOpened);
    }

    const toggleXsFiltersStatus = () => {
        setXsFiltersStatus(!isXsFiltersOpened);
    }

    const onPrimaryClick = () => {
        if (onPrimaryWithFolder) {
            const folder_id = searchParams.get('folder') ? Number(searchParams.get('folder')) : 0;
            onPrimaryWithFolder(folder_id);
        } else onPrimary();
    }

    return (
        <CustomHeader
            key={section + "-header"}
            className={'padding-none-horizontal'}
            title={title ?? l(section + '.list.title')}
            section={section}
            filters={filters}
            addUtms={addUtms}
            setFilters={setFilters}
            filterFields={filterFields}
            searchHandler={onSearch}
            searchPlaceholder={searchPlaceholder}
            isFiltersOpened={isFiltersOpened}
            toggleFiltersStatus={toggleFiltersStatus}
            showMobileFilterButtons={isXsFiltersOpened}
            actions={[
                onSearch ? <SearchBox
                    key="header-search-button"
                    wrapperClasses={(!onPrimary && !onPrimaryWithFolder) ? 'large' : null}
                    searchHandler={onSearch}
                    searchPlaceholder={searchPlaceholder}
                /> : null,

                filters ? <Button
                    key="header-filters-button"
                    type="text"
                    className={(isFiltersOpened || objectLength(filters)) ? 'hidden-xs active' : 'hidden-xs'}
                    icon={<FilterOutlined/>}
                    onClick={toggleFiltersStatus}>
                    {l('page_header.buttons.filter.title')}
                </Button> : null,

                filters ? <Button
                    key="header-filters-xs"
                    type="text"
                    className={`visible-xs inverted${isXsFiltersOpened ? ' active' : ''}`}
                    icon={onSearch ? <SearchOutlined/> : <FilterOutlined/>}
                    onClick={onSearch ? toggleXsFiltersStatus : toggleFiltersStatus}
                /> : null,

                (!onPrimary && !onPrimaryWithFolder) ? null : <Button
                    key="header-primary-button"
                    icon={primaryIcon ?? <PlusOutlined/>}
                    type="primary"
                    onClick={onPrimaryClick}>
                    <span className="hidden-xs-important">
                        {primaryLabel ?? l('page_header.buttons.create.title')}
                    </span>
                </Button>,

                primaryMenu ? <Dropdown
                    key="header-primary-menu"
                    className="btn-default-gray"
                    trigger="click"
                    // type="primary"
                    overlay={primaryMenu}><Button icon={<EllipsisOutlined/>}/></Dropdown> : null,
            ]}
        />
    )
}