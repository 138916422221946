import React from 'react';
import {Input, Switch} from "antd";
import {l} from "../../../library/locale";
import {FormItemLabel} from "../FormItemLabel";

export const LabelSwitch = (
    {
        label = '',
        checked=false,
        onChange=null,
        onClick=null,
        className=''
    }) => {



    const switchTag = (<>
        <Switch checked={checked} onChange={onChange} onClick={onClick} className={className}/>
        {label}
    </>);

    return (
        <FormItemLabel label={switchTag} />
    );
};

