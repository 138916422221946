import React from 'react';
import classes from './FieldsMakerHelper.module.scss';
import {Form, Input, Typography, Row, Col, Button} from "antd";
import {
    arrayMatchesCount,
    arrayToObject,
    deepCopyObject,
    inArray,
} from "../../../library/functions";

import {
    getName,
    SetFieldInfo,
    fieldsHotkeys,
    DescriptionField,
    EditorField,
    SwitchField,
    TagsField,
    TextAreaField,
    TextField,
    WarningField,
    HiddenField,
    MenuField,
    NumberField,
    CheckboxField
} from "./FormFields";

import {CopyOutlined, DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import {Draggable} from "react-beautiful-dnd";
import {FormItemWrapper} from "../FormItemWrapper";

const {TextArea} = Input;
const {Title} = Typography;

// =========== Form Handler ===========

const nonEditableFields = ['heading', 'description', 'warning', 'instructions'];

export const FieldsMakerHelper = (
    {
        t,
        form = null,
        formFields = {},
        section = 'template',
        localeSection = 'maker',
        onDeleteField = null,
        onCopyField = null,
        containerSelector = null,
    }
) => {
    let fieldList = []

    const functions = {
        "text": TextField,
        "textarea": TextAreaField,
        "heading": HeadingEditableField,
        "description": DescriptionField,
        "instructions": EditorField,
        "menu": TagsField,
        "select": TagsField,
        "tags": TagsField,
        "warning": WarningField,
        "editor": TextAreaField,
        "message": TextAreaField,
        "number": TextField,
        "hidden": TextField,
        "avatar": TextField,
        "images": TextField,
        "upload": TextField,
        "phone": TextField,
        "email": TextField,
        "password": TextField,
        "switcher": SwitchField,
        "checkbox": SwitchField,
    }
    // console.log('formFields', formFields)

    const validateNameValue = (rule, value) => {
        const regex = /^[a-zA-Z0-9_]+$/;
        const forbiddenValues = formFields.map(item => item.name);

        if (!value) {
            return Promise.reject(t(localeSection + ".fields.name.rules.required"));
        } else if (!regex.test(value)) {
            return Promise.reject(t(localeSection + ".fields.name.rules.latin"));
        } else if (value && arrayMatchesCount(value, forbiddenValues) > 1) {
            return Promise.reject(t(localeSection + ".fields.name.rules.unique"));
            //return Promise.reject(`This name is already used`);
        } else {
            return Promise.resolve();
        }
    };

    for (const [key, fieldItem] of Object.entries(formFields)) {
        const fieldName = key;
        let field = deepCopyObject(fieldItem);

        field['t'] = t; // add translation function to field
        if (field.section === undefined) field['section'] = localeSection

        field.name = fieldName + '.placeholder'
        field.required = false
        field.placeholder = ''
        field.label = ''
        field.desc = ''

        if (inArray(field.type, ['menu', 'tags', 'select'])) {
            field.name = fieldName + '.values';
            field.features = ['adding'];
            field.form = form;
            if (containerSelector) field.container = containerSelector;
        } else if (field.type === 'heading') {
            field.name = fieldName + '.label'
        } else if (field.type === 'instructions') {
            field.name = fieldName + '.defaultValue';
            field.extended = true;
            field.form = form; // this NOT works for init value on field COPY
            field.formValues = arrayToObject(formFields); // this works
        } else if (inArray(field.type, ['checkbox', 'switcher', 'hidden'])) {
            field.name = fieldName + '.defaultValue'
        }

        if (functions[field.type] === undefined) field.type = 'text'
        const fieldComponent = functions[field.type](field);

        fieldList.push(<Draggable
            key={'logic-form-field-' + fieldName}
            draggableId={field.name}
            index={parseInt(key)}
        >
            {(provided) => (<div
                ref={provided.innerRef}
                className={`${classes.logicFieldWrapper} site-layout-background`}
                {...provided.draggableProps}
            >
                <Row>
                    <Col xs={20} sm={22} md={23}>
                        <Row>
                            <Col xs={12} sm={18}>
                                {(inArray(field.type, nonEditableFields)) ? null :
                                    <TextAreaFieldBorderless
                                        name={fieldName + '.label'}
                                        placeholder={t('common.form.label')}
                                        className={classes.logicFieldLabel}
                                        t={t}
                                    />
                                }

                                {field.type === 'instructions' ? <HeadingEditableField
                                    t={t}
                                    name={fieldName + '.label'}
                                /> : null}

                            </Col>
                            <Col xs={12} sm={6}>
                                <TextAreaFieldBorderless
                                    name={fieldName + '.name'}
                                    placeholder={t('common.form.name.label')}
                                    className={classes.logicFieldName}
                                    rules={[
                                        // {required: true, message: t(localeSection + ".fields.name.rules.required")},
                                        {validator: validateNameValue}
                                    ]}
                                    t={t}
                                />

                                {/*<Form.Item name={getName(fieldName + '.name')} className={classes.logicFieldName}>*/}
                                {/*    <Input*/}
                                {/*        placeholder={t('common.form.name.label')}*/}
                                {/*        onKeyDown={fieldsHotkeys}*/}
                                {/*    />*/}
                                {/*</Form.Item>*/}
                            </Col>
                        </Row>

                        <div className={classes.logicFieldInput}>
                            {fieldComponent}
                        </div>
                        <HiddenField name={fieldName + '.type'} required={true}/>

                        <div className="flex flex-wrap justify-content-end">
                            {field.type !== 'hidden' ? <TextAreaFieldBorderless
                                section={localeSection}
                                name={fieldName + '.desc'}
                                placeholder={t('common.form.desc')}
                                className={`${classes.logicFieldDesc} flex-grow-1`}
                                t={t}
                            /> : null}

                            {(section === 'site' && !inArray(field.type, ['hidden', 'checkbox', 'switcher'])) ? <TextAreaFieldBorderless
                                section={localeSection}
                                name={fieldName + '.defaultValue'}
                                placeholder={t('common.form.defaultValue')}
                                className={`${classes.logicFieldDesc} ${classes.logicFieldDefault}`}
                                t={t}
                            /> : null}

                            <span className={`${classes.logicFieldType} float-right`}>
                                {(inArray(field.type, nonEditableFields)) ? null :
                                    <CheckboxField
                                        name={fieldName + '.required'}
                                        label=""
                                        desc={t('common.form.required.desc')}
                                        className="float-left"
                                    />
                                }
                                {t('maker.fields.field.' + field.type)}
                            </span>
                        </div>
                    </Col>
                    <Col xs={4} sm={2} md={1}>
                        {onDeleteField ? (
                            <Row>
                                <Button
                                    className={`inverted ${classes.logicFieldButton}`}
                                    icon={<DeleteOutlined/>}
                                    onClick={() => onDeleteField(key)}
                                    // size="large"
                                    type="text"
                                />
                            </Row>
                        ) : null}
                        {onCopyField ? (
                            <Row>
                                <Button
                                    className={`inverted ${classes.logicFieldButton}`}
                                    icon={<CopyOutlined/>}
                                    onClick={() => onCopyField(key)}
                                    // size="large"
                                    type="text"
                                />
                            </Row>
                        ) : null}
                        {onCopyField ? (
                            <Row>
                                <div {...provided.dragHandleProps}>
                                    <Button
                                        className={`inverted ${classes.logicFieldButton} cursor-grab`}
                                        icon={<MenuOutlined/>}
                                        type="text"
                                    />
                                </div>
                            </Row>
                        ) : null}
                    </Col>
                </Row>

                {inArray(field.type, ['select']) ? (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={24} offset={0}>
                            <FormItemWrapper
                                desc={t('maker.fields.placeholder.desc.' + field.type)}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <TextField
                                    name={fieldName + '.placeholder'}
                                    label={t('maker.fields.placeholder.label')}
                                    placeholder={t('maker.fields.placeholder.placeholder')}
                                    size="small"
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>
                    </Row>
                ) : null}

                {inArray(field.type, ['menu', 'tags']) ? (
                    <Row gutter={12}>
                        <Col xs={24} sm={8} offset={0}>
                            <FormItemWrapper
                                desc={t('maker.fields.data.desc')}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <MenuField
                                    name={fieldName + '.data'}
                                    label={t('maker.fields.data.label')}
                                    placeholder={t('maker.fields.data.placeholder')}
                                    values={{
                                        // 'manual': t('maker.fields.data.value.manual'),
                                        'list': t('maker.fields.data.value.list'),
                                        'chat': t('maker.fields.data.value.chat'),
                                        'channel': t('maker.fields.data.value.channel'),
                                        'field_text': t('maker.fields.data.value.field.text'),
                                        'field_float': t('maker.fields.data.value.field.float'),
                                        'product': t('maker.fields.data.value.product'),
                                        'global': t('maker.fields.data.value.global'),
                                        'currency': t('maker.fields.data.value.currency'),
                                        'resource': t('maker.fields.data.value.resource'),
                                        'achievement': t('maker.fields.data.value.achievement'),
                                        'webhook': t('maker.fields.data.value.webhook'),
                                        'integration': t('maker.fields.data.value.integration'),
                                        'template': t('maker.fields.data.value.template'),
                                        'pageShort': t('maker.fields.data.value.page'),
                                    }}
                                    // defaultValue="manual"
                                    size="small"
                                    className="ant-field-sm"
                                    localized={false}
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>

                        <Col xs={24} sm={8} offset={0}>
                            <FormItemWrapper
                                desc={t('maker.fields.features.desc.' + field.type)}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <TagsField
                                    form={form}
                                    name={fieldName + '.features'}
                                    label={t('maker.fields.features.label')}
                                    placeholder={t('maker.fields.features.placeholder')}
                                    values={field.type === 'tags' ? {
                                        'adding': t('maker.fields.features.value.adding'),
                                        'creating': t('maker.fields.features.value.creating'),
                                    } : {
                                        'creating': t('maker.fields.features.value.creating'),
                                    }}
                                    // defaultValue="manual"
                                    className="ant-field-sm"
                                    localized={false}
                                    size="small"
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>

                        <Col xs={24} sm={6} md={7} offset={0}>
                            <FormItemWrapper
                                desc={t('maker.fields.placeholder.desc.' + field.type)}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <TextField
                                    name={fieldName + '.placeholder'}
                                    label={t('maker.fields.placeholder.label')}
                                    placeholder={t('maker.fields.placeholder.placeholder')}
                                    size="small"
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>
                    </Row>
                ) : null}

                {inArray(field.type, ['images', 'upload']) ? (
                    <Row gutter={12}>
                        <Col xs={24} sm={11} md={12} offset={0}>
                            <FormItemWrapper
                                html={true}
                                desc={t('maker.fields.' + field.type + '.limit.desc')}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <NumberField
                                    name={fieldName + '.limit'}
                                    label={t('maker.fields.' + field.type + '.limit.label')}
                                    placeholder={t('maker.fields.' + field.type + '.limit.placeholder')}
                                    size="small"
                                    defaultValue={1}
                                    min={1}
                                    max={1000}
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>

                        {field.type === 'images' ? <Col xs={24} sm={11} md={11} offset={0}>
                            <FormItemWrapper
                                html={true}
                                desc={t('maker.fields.' + field.type + '.aspect.desc')}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <NumberField
                                    name={fieldName + '.aspect'}
                                    label={t('maker.fields.' + field.type + '.aspect.label')}
                                    placeholder={t('maker.fields.' + field.type + '.aspect.placeholder')}
                                    size="small"
                                    step={0.1}
                                    min={0}
                                    max={10}
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col> : <Col xs={24} sm={11} md={11} offset={0}>
                            <FormItemWrapper
                                html={true}
                                desc={t('maker.fields.' + field.type + '.accept.desc')}
                                className="margin-top-sm margin-bottom-ps"
                            >
                                <TextField
                                    name={fieldName + '.accept'}
                                    label={t('maker.fields.' + field.type + '.accept.label')}
                                    placeholder={t('maker.fields.' + field.type + '.accept.placeholder')}
                                    size="small"
                                    t={t}
                                />
                            </FormItemWrapper>
                        </Col>}
                    </Row>
                ) : null}
            </div>)}
        </Draggable>)

    }

    return fieldList
}

// =========== Form Fields ===========

export const TextAreaFieldBorderless = (
    {
        t,
        name = 'field',
        section = 'common',
        placeholder = null,
        max_length = 0,
        onBlur = () => null,
        onChange = () => null,
        className = '',
        rules = null,
        required = false,
        requiredMessage = '',
    }
) => {
    const rulesResult = required ? [{
        required: true,
        message: requiredMessage,
    }] : rules;

    return (
        <Form.Item name={getName(name)} className={className ?? 'margin-none'} rules={rulesResult}>
            <TextArea
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                showCount={!!max_length}
                maxLength={max_length ? max_length : null}
                autoSize={{minRows: 1, maxRows: 20}}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={fieldsHotkeys}
                bordered={false}
            />
        </Form.Item>
    );
}

export const HeadingEditableField = ({t, name, level = 3}) => {
    return <Title level={level}>
        <TextAreaFieldBorderless
            name={name}
            placeholder={t('common.form.label')}
            className={classes.logicHeadingFieldLabel}
            t={t}
        />
    </Title>
}






