import React, {memo, useEffect, useRef, useState, useContext} from 'react';
import {getEdgeCenter} from 'react-flow-renderer';
import "./Edge.scss";
import {DeleteOutlined} from "@ant-design/icons";
import {Button} from "antd";
import FlowContext from "../../FlowContext";

let buttonWidth = 45;
let buttonHeight = 45;

export default memo((props) => {
    // console.log('edge props', props)
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        data,
        label,
        style = {},
    } = props;

    let {onEdgesDelete} = useContext(FlowContext);

    const onDelete = (e) => {
        e.preventDefault()
        // console.log('edge: on context click')
        onEdgesDelete([props])
    }

    const onContext = (e) => {
        e.preventDefault()
    }

    const edgeColor = data ? data.type : 'default';

    const buttonRef = useRef();

    const [eCenterX, eCenterY] = getEdgeCenter({sourceX, sourceY, targetX, targetY});

    const sx = sourceX, sy = sourceY, ex = targetX, ey = targetY;
    let tmp = 20; // Сдвиг изгиба линии относительно стрелки. Изначально стояло 30
    let svgMin, sh = 5, x0, x1, x2, x3, y0, y1, y2, y3, offsetArrow = 5;   // чуток приблизим концы стрелки к блоку (по умолчанию = 0)
    if (ex - sx >= tmp) {
        svgMin = tmp;
    } else {
        svgMin = tmp - ((ex - sx) - tmp) / 10;
    }
    if (svgMin > 120) svgMin = 120;
    x0 = sx;
    x1 = sx + svgMin * 2;
    x2 = ex - svgMin * 2;
    x3 = ex - 5;
    y0 = sy;
    y1 = sy - sh + 3;
    y2 = ey + sh;
    y3 = ey;

    const edgePath = `M${x0},${y0} C${x1},${y1} ${x2},${y2 - 3} ${x3},${y3}`
    const arrowPath = `M${x3 + offsetArrow},${y3} L${x3 - 9 + offsetArrow},${y3 - 4} L${x3 - 5 + offsetArrow},${y3} L${x3 - 9 + offsetArrow},${y3 + 4} L${x3 + offsetArrow},${y3}`;

    const [foreignSize, setForeignSize] = useState({width: buttonWidth, height: buttonHeight})

    useEffect(() => {
        if (buttonRef.current) {
            buttonWidth = buttonRef.current.offsetWidth;
            buttonHeight = buttonRef.current.offsetHeight;
            setForeignSize({width: buttonWidth, height: buttonHeight})
            // console.log({width: buttonWidth, height: buttonHeight})
        }
    }, [sourceX, targetX])

    const edgeContainer = () => {
        const width = foreignSize.width;
        const height = foreignSize.height;

        if (data && data.buttons) {
            return (
                <>
                    <foreignObject
                        id={`label_wrapper_${id}`}
                        width={width}
                        height={height}
                        x={eCenterX - width / 2}
                        y={eCenterY - height / 2}
                        className={"edge-label-wrapper"}
                        requiredExtensions="http://www.w3.org/1999/xhtml"
                    >
                        {label ? <div id={`label_text_${id}`} className='edge-label'>
                                {label}
                            </div>
                            : <div className="buttons-wrapper" style={{width: width, height: height}}>
                                <Button ref={buttonRef} className="button" size="default" icon={<DeleteOutlined/>}/>
                                {/*{data.buttons}*/}
                            </div>
                        }
                    </foreignObject>
                </>
            )
        } else return null;
    }

    const edge = () => {
        return (
            <>
                <path
                    id={id + '_think'}
                    onContextMenu={onContext}
                    className={"react-flow__edge-path " + edgeColor}
                    style={style}
                    d={edgePath}
                />
                <path
                    id={id}
                    onContextMenu={onDelete}
                    className="react-flow__edge-path handle"
                    d={edgePath}
                />
                <path
                    id={id + '_arrow'}
                    onContextMenu={onContext}
                    className={"react-flow__edge-path arrow " + edgeColor}
                    d={arrowPath}
                />
            </>
        )
    }

    return (
        <>
            {edge()}
            {edgeContainer()}
        </>
    )
})
