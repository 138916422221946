import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {request} from "../../library/api";
import {deepGet, newTitleForCopy} from "../../library/functions";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {
    setPageItem,
    addPageToList,
    fillPageList,
    patchPageInList,
    removePageFromList,
    fillPageShortList,
    reorderPageInSite,
    setPageListCount,
    setPageShortListCount,
    fillPagePublicList,
    setPagePublicLayout,
    setPageShortListStatus,
} from "../reducers/PageReducer";
import {setLayoutItem} from "../reducers/LayoutReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.page;
const layout_base = backend_api.layout;

function* getPageList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillPageList(response.data.result))
            yield put(setPageListCount(response.data.count))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getPageShortList(arg) {
    try {
        // reset status before loading list
        yield put(setPageShortListStatus(false));

        // run request
        const response = yield request(arg.admin.token, base_path + '/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillPageShortList(response.data.result))
            yield put(setPageShortListCount(response.data.count))
            yield put(setPageShortListStatus(true));
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getPagePublicList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/public', 'get', arg.filters);
        if (response.data.ok) {
            yield put(setPagePublicLayout(arg.layout))
            yield put(fillPagePublicList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getPageItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setPageItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getPageAndLayout(arg) {
    let ok = true
    let layout_filters = arg.layout || {}
    let layout_name = layout_filters.name || null;

    let page_response = null;
    let layout_response = null;

    try {
        if (arg.id) {
            page_response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
            if (!page_response.data.ok) {
                ok = false;
                apiFalseNotice(page_response);
            } else {
                const page_item = page_response.data.result;
                // console.log('page_item', page_item)

                if (page_item && page_item.layout_name) {
                    layout_name = page_item.layout_name;
                    layout_filters = {
                        name: layout_name,
                        project_id: page_item.project_id,
                        deleted_at: 0,
                    }
                }
            }
        }

    } catch (error) {
        apiErrorNotice(error)
        return null
    }

    try {
        if (ok && layout_name) {
            layout_response = yield request(arg.admin.token, layout_base + '/item', 'get', layout_filters);
            if (!layout_response.data.ok) apiFalseNotice(layout_response)
        }

        if (layout_response && layout_response.data.ok) yield put(setLayoutItem(layout_response.data.result))
        else yield put(setLayoutItem({}))

        // set page only after layout (important)
        if (page_response && page_response.data.ok) yield put(setPageItem(page_response.data.result))
        else yield put(setPageItem({}))

    } catch (error) {
        apiErrorNotice(error)
        if (deepGet(error.response, ['data', 'error', 'const']) === "page.error.layout_not_found") {
            yield put(setLayoutItem({id: -1, name: layout_name}))

            // set page after layout
            if (page_response && page_response.data.ok) yield put(setPageItem(page_response.data.result))
            else yield put(setPageItem({}))
        }
        return null
    }
}

function* createPage(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addPageToList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updatePage(arg) {
    try {
        // yield put(blockPageSaga(true))
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchPageInList(response.data.result))
            // yield put(blockPageSaga(false))
            if (arg.show_notice) notice.success(l("page.notice.saved"))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updatePageOrder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data.item);

        if (response.data.ok) {
            const result = {item: response.data.result, list: arg.data.list}
            yield put(reorderPageInSite(result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyPage(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        newItem.is_main = false

        delete newItem.id
        delete newItem.name
        delete newItem.published_at
        delete newItem.is_free

        newItem.title = newTitleForCopy(newItem.title)
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(addPageToList(response.data.result))
        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restorePage(arg) {
    // console.log('restorePage', arg.data);
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);

        if (response.data.ok) {
            // console.log('response.data.result', arg.insertMode, response.data.result)
            yield put(setPageItem(response.data.result))
            // if (insertMode) yield put(addPageToList(response.data.result))
            if (arg.insertMode) yield put(fillPageShortList([]))
            else yield put(removePageFromList(response.data.result))

        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deletePage(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removePageFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* pageSaga() {
    yield takeEvery('getPageList', getPageList);
    yield takeEvery('getPagePublicList', getPagePublicList);
    yield takeEvery('getPageShortList', getPageShortList);
    yield takeEvery('getPageAndLayout', getPageAndLayout);
    yield takeEvery('getPageItem', getPageItem);
    yield takeEvery('createPage', createPage);
    yield takeEvery('copyPage', copyPage);
    yield takeEvery('updatePage', updatePage);
    yield takeEvery('updatePageOrder', updatePageOrder);
    yield takeEvery('restorePage', restorePage);
    yield takeEvery('deletePage', deletePage);
}

