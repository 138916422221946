import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Avatar, Button, Col, Form, InputNumber, Layout, PageHeader, Row, Tooltip, Typography} from "antd";
import classes from "./TemplateItem.module.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {dotenv, images, routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {
    createMarkup,
    createObjectFromObjectsArray,
    deepGet,
    roundPrice,
    ucFirst,
    toNum,
    getLabelForNumeric,
} from "../../library/functions";
import Preloader from "../System/Preloader";
import {
    ArrowLeftOutlined,
    DownloadOutlined,
    ShoppingOutlined,
} from "@ant-design/icons";
import {FormFields} from "../../components/Form/Field/FormFields";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {notice} from "../../library/notice";
import {setTemplateStatus} from "../../redux/reducers/TemplateReducer";
import {AdminBalance} from "../Admin/AdminBalance";
import {useTranslation} from "react-i18next";
import {reachGoal} from "../../library/metrics";
import {SimpleModal} from "../../components/Modal/SimpleModal";

const {Title, Text} = Typography;

const {Header, Content} = Layout;

const TemplateItem = () => {
    const section = 'template'
    const adminSection = 'template'

    // data from URL params
    const params = useParams()
    let project_id = toNum(params.project_id)

    let id, alias;
    const params_id = params.id
    if (params_id) id = toNum(params_id)
    else alias = params.alias

    // init hooks
    const {t} = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // set states and store
    const {admin, project, template} = useSelector(store => store)
    const projectList = [...project.list, ...project.shared];

    const [render, setRender] = useState(0)
    const [periodCount, setPeriodCount] = useState(1)
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({})
    const [projectItem, setProjectItem] = useState({})
    const [isVisibleConfirm, setVisibleConfirm] = useState(false);
    const item = template.item;

    let order_price = item.price ? item.price * periodCount : 0;

    const projectIsSet = !!(projectItem.id || formValues.project_id)
    const isEnoughMoney = !item.price || (item.price && projectItem.balance >= order_price);
    const isInstalled = item.parent_id > 0;
    const isSubscription = item.price > 0 && deepGet(item, 'params.subscription.is_on', false);
    const templateActionType = isSubscription && isInstalled ? 'renew' : 'install';

   // console.log('isEnoughMoney', isEnoughMoney, item.price, projectItem.balance, order_price, projectItem)

    useEffect(() => {
        const anchor = window.location.hash;
        if (anchor) {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);

    // get data from API first
    useLayoutEffect(() => {
        if (admin.authorized) {
            if (id) dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id})
            else if (alias) dispatch({type: 'get' + ucFirst(section) + 'OpenItem', admin, alias})

            // also get projects for install form
            if (!project_id) {
                // console.log('useLayoutEffect dispatch', project_id)
                dispatch({type: 'getProjectList', admin});
                dispatch({type: 'getSharedProjectList', admin});
            } else {
                // console.log('useLayoutEffect', project_id)
                form.setFieldsValue({project_id: String(project_id)})
            }
        }
        //eslint-disable-next-line
        setRender((value) => value + 1)
    }, [admin.authorized])

    useEffect(() => {
        // console.log('admin', render, admin)
        if (render && !admin.authorized) navigate(routes.auth)
        //eslint-disable-next-line
    }, [admin, render])

    useEffect(() => {
        if (template.status === 'installed' && item.id) {
            dispatch(setTemplateStatus('default'));
            notice.success(t('template.notice.installed'));
            navigate(`${routes.project_list}/${item.project_id}/${section}/data/${item.id}`)
        } else if (template.status === 'default') setLoading(false);
        //eslint-disable-next-line
    }, [template.status])

    useEffect(() => {
        const form_project_id = parseInt(formValues.project_id)
        if (form_project_id && projectList && form_project_id !== projectItem.id) {
            const new_project_item = projectList.find(item => item.id === form_project_id);
            setProjectItem(new_project_item);
        }
        //eslint-disable-next-line
    }, [projectList.length, formValues.project_id])

    useEffect(() => {
        if (project_id && project.item && project_id === project.item.id) {
            setProjectItem(project.item);
        }
        //eslint-disable-next-line
    }, [project.item])

    // install form
    const [form] = Form.useForm();
    let formFields = [
        {
            name: "project_id",
            type: "menu",
            data: 'project',
            values: projectList.length ? createObjectFromObjectsArray(projectList, 'id', 'title') : [],
            required: true,
        },
    ]

    if (isInstalled) {
        formFields[0].type = 'hidden';
    }

    const formOnChange = () => {
        let values = form.getFieldsValue();
        setFormValues(values);
    }

    const backToList = () => {
        if (alias && !id && projectItem.id) {
            navigate(`${routes.project_list}/${projectItem.id}/store`);
        } else if (id && projectItem.id) {
            navigate(`${routes.project_list}/${projectItem.id}/${adminSection}?folder=${item.folder_id}`);
        } else {
            navigate(`${dotenv.main_page}`);
        }
    }


    const onInstall = () => {
        // console.log('onPrimary', item.id, values);

        if (!project_id && formValues.project_id) project_id = formValues.project_id
        project_id = parseInt(project_id)

        if (!project_id) {
            notice.error(t('template.error.required.project_id'))
            return null;
        }
        if (!item.id) {
            notice.error(t('template.error.required.id'))
            return null;
        }
        setLoading(true);
        // console.log('onInstall', `${routes.project_list}/${project_id}/${section}/install/${item.id}`);

        // catch metrics goal
        reachGoal('Template' + ucFirst(templateActionType), {
            order_price: item.price,
            template_id: item.id,
            project_id: project_id
        })

        dispatch({
            type: templateActionType + 'Template', admin,
            data: {project_id: project_id, template_id: item.id, count: periodCount}
        })

        if (templateActionType === 'renew') backToList();
    }

    const showConfirm = () => {
        setVisibleConfirm(true);
    }

    const cancelConfirm = () => {
        setVisibleConfirm(false);
    }

    let ready = false;

    if (item && id && item.id === id) ready = true;
    else if (item && alias && item.alias === alias) ready = true;
    // console.log('item price', balance, coins, balance + coins / 10, item.price)

    return (<AppWrapper>
        {ready ? <Layout className="site-layout site-layout-background">
            <Header className="site-layout-background page-container-horizontal-padding has-banner">
                <PageHeader
                    title={item.title}
                    className="padding-none-horizontal heading-only-xs"
                    extra={[
                        // back
                        <div key="header-return-button-wrapper">
                            {projectItem.id ?
                                <Button key="header-return-button" type="text" icon={<ArrowLeftOutlined/>}
                                        className="hidden-sm"
                                        onClick={backToList}>
                                    {t('common.action.back')}
                                </Button> : null
                            }
                        </div>,
                        // primary
                        // (project_id && !isInstalled) ? <Button
                        //     key="header-primary-button"
                        //     type="primary"
                        //     className="hidden-sm"
                        //     onClick={showConfirm}
                        //     icon={<DownloadOutlined/>}
                        //     disabled={!projectIsSet || !item.is_open}
                        // >
                        //     {t('common.action.' + templateActionType)}
                        // </Button> : null,
                    ]}
                />
            </Header>

            <Content className={`page-container form-container site-layout-background ${classes.container}`}>
                {item.author ?
                    // <NavigateLink link={routes.admin.account} className={classes.current_user_info}>
                    <div className={classes.author_wrapper}>
                        <div className={`${classes.author_avatar} float-left`}>
                            <Avatar
                                shape='circle'
                                size='large'
                                src={item.author.photo_url ? item.author.photo_url : images.avatar.user}
                            />
                        </div>
                        <div className={classes.author_name}>{item.author.name}</div>
                    </div>
                    : null
                }
                <div dangerouslySetInnerHTML={createMarkup(item.product_info)}/>

                {item.price ? <>
                    <hr className="margin-top-pm" id="buy"/>
                    <div className="margin-top-pm font-size-md">
                        {deepGet(item, 'params.subscription.is_on') && deepGet(item, 'params.subscription.period') ?
                            <div>
                                <div>
                                    {t('template.item.period')}: {item.params.subscription.period} {
                                    getLabelForNumeric(item.params.subscription.period, [
                                        t('template.item.days.0'),
                                        t('template.item.days.1'),
                                        t('template.item.days.2')
                                    ])
                                }
                                </div>
                                <div className="margin-top-xs">
                                    <span className="margin-right-ps">{t('template.item.count')}:</span>
                                    <InputNumber
                                        min={1}
                                        step={1}
                                        size="small"
                                        className="inline always-show-arrows"
                                        placeholder='1'
                                        defaultValue={1}
                                        onChange={setPeriodCount}
                                        style={{width: 65}}
                                    />
                                </div>
                            </div> : null}
                        <div className="margin-top-xs">
                            {
                                (!periodCount || periodCount <= 1) ? t('template.item.price') : (isInstalled ? t('template.item.renew_price') : t('template.item.install_price'))
                            }: {roundPrice(order_price)} {t('common.money.symbol')}
                        </div>
                    </div>
                </> : null}

                {item.price ? <AdminBalance
                    className="margin-top-pm"
                    projectId={isInstalled ? project_id : formValues.project_id}
                /> : null}

                {(item.is_open || isSubscription) ? <div className="margin-top-pm">
                    <Title level={3}>
                        {t('template.' + templateActionType + '.title')}
                    </Title>
                    <Form
                        form={form}
                        onFinish={item.price ? showConfirm : onInstall}
                        onValuesChange={formOnChange}
                        className=""
                        name="settings"
                        layout="vertical"
                    >
                        <FormFields
                            t={t}
                            section={section + '.' + templateActionType}
                            fields={formFields}
                        />
                        {project_id ? <div className="margin-top-pm"/> : null}

                        <FormSubmit
                            submitIcon={isInstalled ? <ShoppingOutlined/> : <DownloadOutlined/>}
                            submitLabel={t('common.action.' + templateActionType)}
                            onCancel={backToList}
                            loading={loading}
                            disabled={(!projectIsSet && !project_id) || !isEnoughMoney}
                            className="margin-bottom-xp"
                            // disabled={item.price && (balance + coins / 10) < item.price}
                        />

                        {isEnoughMoney ? null : <Text type="danger">
                            <small>{t('template.error.price')}</small>
                        </Text>}
                    </Form>
                </div> : null}

                <SimpleModal
                    title={t('template.' + templateActionType + '.confirm.title')}
                    isVisible={isVisibleConfirm}
                    setVisible={setVisibleConfirm}
                    actionLabel="approve"
                    onOk={onInstall}
                    onCancel={cancelConfirm}
                    loadingOnOk={true}
                    disabled={(!projectIsSet && !project_id) || !isEnoughMoney}
                >
                    <div dangerouslySetInnerHTML={createMarkup(
                        t('template.' + templateActionType + '.confirm.desc', {
                            postProcess: 'sprintf', sprintf: [
                                roundPrice(order_price),
                                t('common.money.symbol'),
                                roundPrice(projectItem.balance - order_price),
                                t('common.money.symbol')
                            ]
                        })
                    )}/>
                </SimpleModal>
            </Content>
        </Layout> : <Preloader/>}
    </AppWrapper>)
}

export default TemplateItem