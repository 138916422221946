import {createSlice} from "@reduxjs/toolkit";

const SenlerBotReducer = createSlice({
    name: 'senlerBot',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillSenlerBotList(state, action) {
            state.list = action.payload
        },
        setSenlerBotListCount(state, action) {
            state.count = action.payload;
        },
        resetSenlerBotState(state) {
            state.list = [];
            state.item = {};
            state.count = 0;
        }
    }
})

export default SenlerBotReducer.reducer
export const {
    fillSenlerBotList,
    setSenlerBotListCount,
    resetSenlerBotState,
} = SenlerBotReducer.actions