import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import classes from './Container.module.scss'
import {Col, Form} from 'antd';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {
    deepCopyObject,
    inArray,
    momentFromUnix,
    deepSetOrig,
    deepGet,
    isArr,
} from "../../../../../../library/functions";
import {handleFieldSpecValues} from "../../../../../../library/forms";
import {
    getFullEffectByType,
    createSpecFromFields,
    setEffectSpec,
    renderFormRows
} from "../../../../../../library/effects";
import {CheckFieldDependsOn} from "../../../../../../components/Form/Field/FormFields";
import {EffectElement} from "./EffectElement";
import AccordPanel from "../AccordPanel/AccordPanel";
import FlowContext from "../../../../FlowContext";

export const handleNestedObjects = (effectFields, finalSpec, inputSpec, nestedFieldNames = []) => {
    if (!effectFields) return;

    for (const [key, value] of Object.entries(effectFields)) {
        const nestedFieldNamesCopy = [...nestedFieldNames, key];
        const specValue = inputSpec ? inputSpec[key] : null;

        if (moment.isMoment(value)) {
            // console.log('specValue', nestedFieldNamesCopy, specValue)
            const resultTime = momentFromUnix(specValue);
            // console.log('resultTime', resultTime)
            deepSetOrig(finalSpec, nestedFieldNamesCopy, specValue ? resultTime : '');
        } else if (typeof value === 'object' && !isArr(value)) {
            handleNestedObjects(value, finalSpec, specValue, nestedFieldNamesCopy);
        } else {
            // finalSpec[nestedFieldName] = inputSpec[nestedFieldName];
            deepSetOrig(finalSpec, nestedFieldNamesCopy, specValue);
        }
    }
};

const Container = (props) => {
    const {t} = useTranslation();
    const {nodeLocalId, localId, type, title, spec, isCollapsed, ownerLocalId, isIgnore} = props;
    const {nodesState, edgesState, updateNodeEffect, updateNodeEffectCondition} = useContext(FlowContext);
    const store = useSelector(store => store);

    const [specState, setSpecState] = useState(spec);
    const [effectState, setEffectState] = useState(null);

    let effect = getFullEffectByType(type);
    const effectFields = (effect && effect.fields) ? createSpecFromFields(effect.fields, true) : {};
    const [form] = Form.useForm();

    useEffect(() => {
        // console.log('=== Container useEffect ===', props);
        // console.log('effectFields', effectFields)
        if (effect) {
            let finalSpec = {};
            handleNestedObjects(effectFields, finalSpec, specState);
            // console.log('finalSpec', finalSpec)

            // console.log('+++++++++ Update Container Data ++++++++++', finalSpec)
            setEffectState(effect);
            form.setFieldsValue(finalSpec); // for ant forms
        }
        //eslint-disable-next-line
    }, [type])

    const saveCurrentSpec = (specNewValues = null) => {
        let specValues = setEffectSpec(form, setSpecState, specNewValues)

        // console.log('saveCurrentSpec Result:', ownerLocalId, localId,
        //     'old:', specState,
        //     'new', specValues
        // );
        setSpecState(specValues);

        if (ownerLocalId) updateNodeEffectCondition(nodeLocalId, ownerLocalId, localId, {spec: specValues});
        else updateNodeEffect(nodeLocalId, localId, specValues);
    }

    const renderField = (fieldCol, width = 24, key = 0) => {
        let field = deepCopyObject(fieldCol);
        const depend_result = CheckFieldDependsOn(field, specState);
        // console.log('spec', field, specState)

        // nodes field
        if (field && inArray(field.data, ['nodes', 'graphNode'])) {
            let store_items = (field.data === 'nodes' || specState.is_current) ? nodesState : store[field.data].list;
            const data_filters = (field.data_filters) ? field.data_filters : {'ui.hidden': false}
            // console.log('data_filters', data_filters, '\n mask', field.data_mask);
            let filtered_items = store_items.filter((node, index) => {
                // console.log('data_filters', data_filters, node)
                for (const [filterPath, filterValue] of Object.entries(data_filters)) {
                    const nodeValue = deepGet(node, filterPath)
                    // console.log('nodeValue', !!nodeValue, filterValue, filterPath)

                    // exclude UI only blocks
                    if (node.type === 'note') return false;

                    if (typeof filterValue === 'boolean') {
                        if (!nodeValue === filterValue) return false;
                    }
                    else if (isArr(filterValue)) {
                         if (!inArray(nodeValue, filterValue)) return false;
                    }
                    else if (nodeValue !== filterValue) return false;
                }
                return true;
            });

            // console.log('filtered_items', filtered_items)
            // field.values = createObjectFromObjectsArray(filtered_items, 'localId', 'text');

            let result = {};
            if (deepGet(field, ['data_mask', 'container', 'spec'], false)) result = [];

            for (const item of filtered_items) {
                // console.log('item', item)
                let itemLabel = t('graph.flow.node.' + item.type + '.title') + ' - ';
                if (item.text) itemLabel += item.text.substring(0, 50);
                else {
                    if (item.ui && item.ui.step) {
                        itemLabel += t('graph.flow.container.nodes_list.step', {
                            postProcess: 'sprintf',
                            sprintf: [item.ui.step]
                        });
                    } else {
                        itemLabel += t('graph.flow.container.nodes_list.block_only', {
                            postProcess: 'sprintf',
                            sprintf: [item.localId]
                        });
                    }
                }

                if (field.data_mask) {
                    if (inArray(item.type, ['event', 'message'])) {
                        itemLabel += ' (' + t('graph.flow.container.nodes_list.block_only', {
                            postProcess: 'sprintf',
                            sprintf: [item.localId]
                        }) + ')';
                    }

                    if (field.data_mask.container && field.data_mask.container.type && item.effects) {
                        for (const container of item.effects) {
                            t('graph.flow.container.nodes_list.block_only', {
                                postProcess: 'sprintf',
                                sprintf: [item.localId]
                            })

                            if (container.type === field.data_mask.container.type) {
                                if (field.data_mask.container.spec) {
                                    if (!container.spec) continue;
                                    let specVal = deepGet(container.spec, field.data_mask.container.spec);
                                    // console.log('container.spec', specVal, container.spec, field.data_mask.container)
                                    if (specVal) {
                                        specVal = specVal.replace(/(\r\n|\n|\r)/gm, "").trim();
                                        result.push(specVal);
                                    }
                                }
                                else result[container.localId] = '#' + container.localId + ' ... ' + itemLabel;
                            }
                        }
                    }
                    else if (inArray(field.data_mask.item, ['button', 'event', 'start'])) {
                        if (field.data_mask.item === 'start') {
                            if (edgesState.filter(edge => edge.dstNode === item.localId).length === 0) {
                                result[item.localId] = '#' + item.localId + ' ... ' + itemLabel;
                            }
                        } else if (item.type === 'event' && !deepGet(item, 'ui.hidden')) {
                            if (field.data_mask.item === 'event' || edgesState.filter(edge => edge.dstNode === item.localId).length > 0) {
                                result[item.localId] = '#' + item.localId + ' ... ' + itemLabel;
                            }
                        } else {
                            const btnEvents = deepGet(item, 'ui.related', []);
                            for (const btnEvent of btnEvents) {
                                if (btnEvent.type === 'event') {
                                    result[btnEvent.nodeLocalId] = '#' + btnEvent.nodeLocalId + ' ... ' + itemLabel;
                                }
                            }
                        }
                    }
                } else result[item.localId] = '#' + item.localId + ' ... ' + itemLabel;
            }
            field.values = result
        }
        // field data
        else {
            // handleFieldSpec and store final result to effect schema
            field = handleFieldSpecValues(t, store, field);
        }

        const condType = field.containerType ?? type;
        // console.log('specState', specState)
        return <Col className={depend_result ? '' : 'hide'} span={width} key={key}>
            <EffectElement
                t={t}
                index={key}
                form={form}
                admin={store.admin}
                project={store.project}
                field={field}
                localId={localId}
                effectType={condType}
                spec={specState}
                runSave={saveCurrentSpec}
                section="flow"
            />
        </Col>
    }

    const formOnChange = () => {
        let currentSpec = form.getFieldsValue();
        // console.log('formOnChange new SPEC: ', currentSpec);
        setSpecState(currentSpec); // for initialValues
    }

    const renderContainerForm = () => {
        return (
            <Form
                form={form}
                initialValues={specState}
                layout="vertical"
                // className=''
                onValuesChange={formOnChange}
                name={`form_${nodeLocalId}_${localId}`}
            >
                {renderFormRows(localId, effectState, renderField)}
            </Form>
        )
    }

    const formContainer = () => {
        if (ownerLocalId) {
            return (
                <div id={`effect_${localId}`} className={classes.effect_wrapper}>
                    {renderContainerForm()}
                </div>
            )
        } else {
            const empty = (!effectState.fields || !effectState.fields.length);
            return (
                <AccordPanel
                    effectType={type}
                    nodeLocalId={nodeLocalId}
                    icon={effectState.icon}
                    brandIcon={effectState.brandIcon}
                    title={title ? title : effectState.title}
                    localId={localId}
                    isCollapsed={isCollapsed}
                    isIgnore={isIgnore}
                    showContextButton={false}
                    empty={empty}
                >
                    {renderContainerForm()}
                </AccordPanel>
            )
        }
    }

    const showFormEffect = () => {
        if (effectState) {
            return formContainer();
        } else {
            return null;
        }
    }

    return (
        <>
            {showFormEffect()}
        </>
    )
}

export default Container