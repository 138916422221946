import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addProductCategoryToList,
    fillProductCategoryList,
    patchProductCategoryInList,
    removeProductCategoryFromList,
    setProductCategoryItem,
    setProductCategoryListCount,
} from "../reducers/ProductCategoryReducer";

// init api methods
const base_path = backend_api.category;

function* getProductCategoryList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillProductCategoryList(response.data.result))
            yield put(setProductCategoryListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getProductCategoryItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setProductCategoryItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createProductCategory(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addProductCategoryToList(response.data.result))
            yield put(setProductCategoryItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateProductCategory(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchProductCategoryInList(response.data.result))
            yield put(setProductCategoryItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyProductCategory(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setProductCategoryItem(response.data.result))
            yield put(addProductCategoryToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreProductCategory(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeProductCategoryFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteProductCategory(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeProductCategoryFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* productCategorySaga() {
    yield takeEvery('getProductCategoryList', getProductCategoryList);
    yield takeEvery('getProductCategoryItem', getProductCategoryItem);
    yield takeEvery('createProductCategory', createProductCategory);
    yield takeEvery('copyProductCategory', copyProductCategory);
    yield takeEvery('updateProductCategory', updateProductCategory);
    yield takeEvery('restoreProductCategory', restoreProductCategory);
    yield takeEvery('deleteProductCategory', deleteProductCategory);
}

