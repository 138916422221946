import React, {useEffect, useState} from 'react'
import {Card} from "antd";
import classes from './FieldsMaker.module.scss';
import {
    arrayReorder,
    arrayToObject,
    deepGet,
    inArray,
    isArr,
} from "../../../library/functions";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

import Fa from "../../../components/Icon/Fa";
import Preloader from "../../../containers/System/Preloader";
import {FieldsMakerHelper} from "./FieldsMakerHelper";
import cryptoRandomString from "crypto-random-string";

const fieldTypesMap = [
    {type: 'text', icon: 'input-text'}, // from Font Awesome icons library
    {type: 'textarea', icon: 'align-left'},
    {type: 'message', icon: 'message-lines'},
    {type: 'editor', icon: 'money-check-pen'},
    {type: 'menu', icon: 'list-dropdown'},
    {type: 'tags', icon: 'clipboard-list-check'},
    {type: 'images', icon: 'image'},
    {type: 'upload', icon: 'paperclip'},
    {type: 'switcher', icon: 'toggle-large-on'}, // {type: 'number', icon: 'numeric'},
    {type: 'hidden', icon: 'eye-slash'},
    {type: 'heading', icon: 'h3'},
    {type: 'instructions', icon: 'square-question'},
    // {type: 'checkbox', icon: 'square-check'},
    // {type: 'date', icon: 'calendar'},
    // {type: 'time', icon: 'clock'},
    // {type: 'datetime', icon: 'calendar-clock'},
]

const siteFieldTypesMap = [
    {type: 'text', icon: 'input-text'},
    // {type: 'message', icon: 'message-lines'},
    {type: 'textarea', icon: 'align-left'},
    {type: 'select', icon: 'list-dropdown'},
    // {type: 'tags', icon: 'clipboard-list-check'},
    // {type: 'images', icon: 'image'},
    {type: 'email', icon: 'at'},
    {type: 'phone', icon: 'phone'},
    {type: 'password', icon: 'key'},
    {type: 'hidden', icon: 'eye-slash'},
    {type: 'heading', icon: 'h3'},
    // {type: 'instructions', icon: 'square-question'},
    {type: 'checkbox', icon: 'square-check'},
    // {type: 'switcher', icon: 'toggle-large-on'}, // {type: 'number', icon: 'numeric'},
    // {type: 'date', icon: 'calendar'},
    // {type: 'time', icon: 'clock'},
    // {type: 'datetime', icon: 'calendar-clock'},
]

const FieldsMaker = (
    {
        t,
        id,
        item,
        form,
        propName = 'logic',
        section = 'common',
        localeSection = 'maker',
        containerSelector = null,
    }
) => {

    // itis form states
    const [formValues, setFormValues] = useState(null);
    const [disableAnimation, setDisableAnimation] = useState(false);

    // set field values to STATE if correct data received
    useEffect(() => {
        if (item && id && item.id === id) updateFormByValues(deepGet(item, propName, []) || []);
        else updateFormByValues([])
        //eslint-disable-next-line
    }, [item])

    const getFormFieldsFromLogic = (valuesList) => {
        // console.log('valuesList', valuesList);

        let fields = [];
        if (!valuesList || !isArr(valuesList) || !valuesList.length) return fields;

        for (const field of valuesList) {
            const fieldName = deepGet(field, 'name');
            if (typeof fieldName !== 'string') continue;

            let fieldObj = {
                name: fieldName,
                type: deepGet(field, 'type'),
                label: deepGet(field, 'label'),
                desc: deepGet(field, 'desc'),
                values: deepGet(field, 'values'),
                required: deepGet(field, 'required'),
                placeholder: deepGet(field, 'placeholder', ''),
                defaultValue: deepGet(field, 'defaultValue', null),
            }

            if (isArr(fieldObj.type, ['menu', 'tags'])) {
                fieldObj['data'] = deepGet(field, 'data');
                fieldObj['features'] = deepGet(field, 'features');
            }

            else if (fieldObj.type === 'images') {
                fieldObj['limit'] = deepGet(field, 'limit', 1);
                fieldObj['aspect'] = deepGet(field, 'aspect', 0);
            }

            else if (fieldObj.type === 'files') {
                fieldObj['limit'] = deepGet(field, 'limit', 1);
                fieldObj['accept'] = deepGet(field, 'accept', '');
            }

            fields.push(fieldObj)
        }
        return fields;
    }

    const updateFormByValues = (valuesList) => {
        // console.log('updateFormByValues', valuesList);
        let values = isArr(valuesList) ? valuesList : [];

        const fieldsValues = getFormFieldsFromLogic(values);
        setFormValues(fieldsValues);

        const valuesObject = arrayToObject(values);
        form.setFieldsValue(valuesObject);
    }

    const getActualValues = () => {
        const valuesObject = form.getFieldsValue();
        // console.log('getActualValues', valuesObject)
        return Object.values(valuesObject);
    }

    // ====== Fields ACTIONS ======

    const onDeleteField = (index) => {
        const currentValues = getActualValues();
        let newFields = [...currentValues];
        newFields.splice(index, 1);

        form.setFieldsValue({[index]: undefined});
        updateFormByValues(newFields);
    }

    const onCopyField = (key) => {
        const currentValues = getActualValues();
        const newField = {...currentValues[key], name: cryptoRandomString({length: 10})};
        const newFields = [...currentValues, newField];
        updateFormByValues(newFields);
    }

    const onAddField = (type) => {
        const currentValues = getActualValues();

        //console.log('onCardClick', type);
        const fieldName = cryptoRandomString({length: 10});
        let placeholder = t('common.placeholder.text');
        if (type === 'menu' || type === 'select') placeholder = t('common.placeholder.menu');
        else if (type === 'tags') placeholder = t('common.placeholder.tags');
        else if (inArray(type, ['images', 'avatar', 'upload'])) placeholder = t('common.action.upload');
        const newFields = [...currentValues, {
            name: fieldName, type: type, label: '', desc: '', placeholder: placeholder,
        }];
        updateFormByValues(newFields);
    }

    const onDragEnd = (result) => {
        // console.log('onDragEnd result', result);
        let currentValues = getActualValues();
        // console.log('currentValues', currentValues)

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newFields = arrayReorder(
            currentValues,
            result.source.index,
            result.destination.index
        );

        setDisableAnimation(true);
        updateFormByValues(newFields);

        setTimeout(() => setDisableAnimation(false), 100);
    }

    // ====== RENDER ======

    if (formValues === null || (id && item.id !== id)) return (<Preloader/>);
    const fieldsMap = section === 'site' ? siteFieldTypesMap : fieldTypesMap;

    return (<div className={disableAnimation ? 'disable-check-animation' : ''}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="logic-fields">
                {(provided) => ( // preloader ? <Preloader/> :
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {formValues.length ? <FieldsMakerHelper
                            t={t}
                            form={form}
                            section={section}
                            localeSection={localeSection}
                            formFields={formValues}
                            onDeleteField={onDeleteField}
                            onCopyField={onCopyField}
                            containerSelector={containerSelector}
                        /> : null}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

        <Card bordered={false} className={classes.cardListWrapper}>
            {fieldsMap.map((item, index) => {
                return <Card.Grid
                    key={index}
                    onClick={() => {
                        onAddField(item.type)
                    }}
                    className={`${classes.card} cursor-pointer align-center`}
                >
                    <div>
                        <Fa icon={item.icon} className={classes.cardIcon}/>
                    </div>
                    {t('maker.fields.field.' + item.type)}
                </Card.Grid>
            })}
        </Card>
    </div>)
}

export default FieldsMaker


// const template_logic_example = [{
//     "name": "text",
//     "type": "textarea",
//     "label": "Текстовое поле",
//     "description": "Здесь хранится список сообщений",
//     "placeholder": ""
// }, {
//     "name": "number",
//     "type": "number",
//     "label": "Числовое поле",
//     "description": "Здесь хранится ID крайнего сообщения",
//     "placeholder": ""
// }]