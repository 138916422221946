import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addFieldToList,
    fillFieldList,
    patchFieldInList,
    removeFieldFromList,
    setFieldItem,
    setFieldStatus,
} from "../reducers/FieldReducer";
import {setLoadingStatus} from "../reducers/StatusReducer";
import {getItemValues, deepGet} from "../../library/functions";
import cryptoRandomString from "crypto-random-string";
import {setCorrect} from "../reducers/AdminReducer";

// init api methods
const base_path = backend_api.field;

function* getFieldList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillFieldList(response.data.result))
            yield put(setLoadingStatus('ready'))
            yield put(setFieldStatus('ready'))
        }
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
        apiErrorNotice(error)
        return null
    }
}

function* getFieldItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (!response.data.ok || !deepGet(response,'data.result.0.id')) {
            apiFalseNotice(response)
            return null
        }

        yield put(setFieldItem(response.data.result[0]))
    } catch (error) {
        if (error.response.status === 401) {
            yield put(setCorrect(false))
        }
        apiErrorNotice(error)
        return null
    }
}

function* createField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addFieldToList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyField(arg) {
    try {
        const originalItem = yield request(
            arg.admin.token,
            base_path,
            'get',
            {project_id: arg.project_id, id: arg.id});
        if (!originalItem.data.ok || !deepGet(originalItem,'data.result.0.id')) {
            apiFalseNotice(originalItem)
            return null
        }

        let newItem = getItemValues(originalItem.data.result[0], [
            "project_id",
            "name",
            "data_type_admin",
            "title",
            "default_value",
            "system_field_type",
            "params",
            "photo_url",
            "icon_name",
            "folder_id",
        ])
        newItem.title += ' - Copy'
        newItem.name = cryptoRandomString({length: 15})
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) yield put(addFieldToList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);
        if (response.data.ok) {
            yield put(patchFieldInList(response.data.result))
            yield put(setFieldItem(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreField(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeFieldFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteField(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'delete', arg.filters);
        if (response.data.ok) yield put(removeFieldFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* fieldSaga() {
    yield takeEvery('getFieldList', getFieldList);
    yield takeEvery('getFieldItem', getFieldItem);
    yield takeEvery('copyField',    copyField);
    yield takeEvery('createField',  createField);
    yield takeEvery('updateField',  updateField);
    yield takeEvery('restoreField',  restoreField);
    yield takeEvery('deleteField',  deleteField);
}

