import React, {useContext} from 'react'
import {useTranslation} from "react-i18next";
import "./ContainerButtons.scss";
import classes from './ContainerHeader.module.scss';
import ContainerOperations from "../ContainerOperations/ContainerOperations";
import MailSettings from "../../../Blocks/svg/MailSettings";
import FlowContext from "../../../../FlowContext";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import {dotenv} from "../../../../../../config/config";
import {events, events_categories} from "../../../../../../schemas/frontend/events";
import {redirect} from "../../../../../../library/functions";
import Fa from "../../../../../../components/Icon/Fa";

const ContainerHeader = (
    {
        nodeLocalId,
        effectLocalId,
        title,
        type = '',
        showContextControl,
        idContextOpen = false,
        sorting = true,
        isIgnore = true,
    }
) => {
    const {t} = useTranslation();
    const {moveNodeContainer, forbidden} = useContext(FlowContext);
    let helpUrl = dotenv.docs_url

    if (type) {
        // https://help.multy.ai/graph/effect/core/chat/message-send
        // effect/core/chat/message/reaction

        let result = ['graph'];
        let parts = type.split('/');
        // add to result parts from 0 to 2:

        if (parts[0] === 'event') {
            const eventsMap = {}
            for (const event of events) {
                if (event.category && (event.category.length > 1 || parts[2] !== event.category[0])) {
                    eventsMap[event.type] = [...event.category];
                }
            }

            if (!events_categories[parts[1]][parts[2]]) {
                parts.splice(2, 0, eventsMap[type][0]);

                // const eventSchema = events.find((event) => event.type === type);
                //if (eventSchema && eventSchema.category && eventSchema.category.length) {
                //   parts.splice(2, 0, eventSchema.category[0]);
                //}
            }
            else if (eventsMap[type]) {
                // set correct category for some events:
                parts[2] = eventsMap[type][0];
            }
        }

        result = result.concat(parts.slice(0, 3));
        helpUrl += '/' + result.join('/') + '/' + parts.slice(3).join('-');
    }

    return (
        <>
            <div className={classes.wrapper_header_action}>
                <div className={classes.wrapper_header}>
                    <div className={classes.block_header}>
                        <div className={`${classes.header}${isIgnore ? ' text-secondary' : ''}`}>{title}</div>
                        <div className={classes.sub_header}>
                        <span className="hidden-xs">
                            {t('graph.flow.container.number')}: #{effectLocalId}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-center container-buttons" onClick={(e) => {
                // prevent accord panel events
                e.preventDefault();
                e.stopPropagation();
            }}>
                {showContextControl ? <Button
                    className="container-context-btn inverted"
                    icon={<MailSettings/>}
                    type={idContextOpen ? 'primary' : 'text'}
                /> : null}
                {sorting ? <>
                    <Tooltip title={t('graph.flow.container.operation.down')} getPopupContainer={() => {
                        return document.getElementById('effects-list-wrapper')
                    }}>
                        <Button
                            type="text"
                            icon={<ArrowDownOutlined/>}
                            className="container-arrow-down inverted"
                            onClick={() => {
                                moveNodeContainer(nodeLocalId, effectLocalId, true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={t('graph.flow.container.operation.up')} getPopupContainer={() => {
                        return document.getElementById('effects-list-wrapper')
                    }}>
                        <Button
                            type="text"
                            icon={<ArrowUpOutlined/>}
                            className="container-arrow-up inverted"
                            onClick={() => {
                                moveNodeContainer(nodeLocalId, effectLocalId, false);
                            }}
                        />
                    </Tooltip>
                </> : null}
                <Tooltip title={t('graph.flow.container.operation.help')} placement="topRight" getPopupContainer={() => {
                        return document.getElementById('effects-list-wrapper')
                    }}>
                    <Button
                        icon={<Fa icon="circle-question" antd={true} set="regular"/>}
                        className="inverted hidden-xs"
                        type="text"
                        href={helpUrl}
                        onClick={(e) => {
                            e.preventDefault();
                            redirect(helpUrl, '_blank');
                        }}
                    />
                </Tooltip>
                {forbidden ? null : <ContainerOperations
                    nodeLocalId={nodeLocalId}
                    effectLocalId={effectLocalId}
                    isIgnore={isIgnore}
                    helpUrl={helpUrl}
                />}
            </div>
        </>
    )
}

export default ContainerHeader