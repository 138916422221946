import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addUtmToList,
    fillUtmList,
    patchUtmInList,
    removeUtmFromList,
    setUtmItem,
    setUtmListCount,
} from "../reducers/UtmReducer";

// init api methods
const base_path = backend_api.utm;

function* getUtmList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUtmList(response.data.result))
            yield put(setUtmListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUtmItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setUtmItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addUtmToList(response.data.result))
            yield put(setUtmItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchUtmInList(response.data.result))
            yield put(setUtmItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyUtm(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setUtmItem(response.data.result))
            yield put(addUtmToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreUtm(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeUtmFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeUtmFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* utmSaga() {
    yield takeEvery('getUtmList', getUtmList);
    yield takeEvery('getUtmItem', getUtmItem);
    yield takeEvery('createUtm', createUtm);
    yield takeEvery('copyUtm', copyUtm);
    yield takeEvery('updateUtm', updateUtm);
    yield takeEvery('restoreUtm', restoreUtm);
    yield takeEvery('deleteUtm', deleteUtm);
}

