import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {dotenv, routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {SiteListTable} from "./SiteListTable";
import {objectLength, ucFirst} from "../../../library/functions";
import {ListFolderManager} from "../../../components/List/Folder/ListFolderManager";
import {useTranslation} from "react-i18next";
import {SitesModal} from "../Add/SitesModal";
import {setSiteItem} from "../../../redux/reducers/SiteReducer";
import {notice} from "../../../library/notice";
import {resetPageState} from "../../../redux/reducers/PageReducer";

const {Content} = Layout;

const SiteList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'site'
    const adminSection = 'site'

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store and state
    const {admin, project, site, sect} = useSelector(store => store)
    const [filters, setFilters] = useState({})
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [folderId, setFolderId] = useState(0);

    // init universal vars
    const project_item = project.item
    const list = site.list || []
    const isSagaBlocked = site.blockSaga
    const sorter = site.sorter
    // const rowsTotal = site.count || 0
    // const currentPage = site.page || 1
    // const pageSize = site.pageSize || 10

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                ordering: ordering,
                // page_number: currentPage,
                // page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            if (!sect.sites.length) {
                dispatch({type: 'getSectData', admin, section: 'sites', filters: {is_on: true, page_size: 0}});
            }

            if (!isSagaBlocked) {
                dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, isSagaBlocked, filters, sorter])

    // const recordEdit = (folder_id = 0) => {
    //     navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0?folder=${folder_id}`)
    // }

    const openPage = (site_id = 0, page_id = 0, layout_name = '') => {
        let url = `${routes.project_list}/${project_item.id}/${section}/page/${page_id}`;
        let tail = [];

        if (layout_name) tail.push(`layout_name=${layout_name}`)
        if (site_id) tail.push(`site_id=${site_id}`)
        if (tail.length) url += '?' + tail.join('&')

        console.log('openPage', url)
        navigate(url)
    }

    useEffect(() => {
        if (site.item.id && isLoading) {
            openPage(site.item.id, site.start_page.id, site.start_page.layout)
            setIsLoading(false)
        }

        // return () => {
        //     console.info('Site is Loading - useEffect return')
        //     setIsLoading(false)
        // }

        //eslint-disable-next-line
    }, [site.item.id, isLoading])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordCreate = (task, folder_id = 0) => {
        const siteSettings = sect.sites.find(item => item.name === task);
        if (!siteSettings) {
            notice.error(t('error.record.undefined'))
            return;
        }

        // console.log('task', task, sect.sites)

        const siteData = siteSettings.spec || {};
        // console.log('siteData', siteData)

        dispatch({
            type: 'installSite', admin, data: {
                project_id: project_item.id,
                title: t(section + '.item.title') + ' ' + (list.length + 1) + ' - ' + t(`sites.${task}.title`),
                params: {
                    folder_id: folder_id,
                    lang: dotenv.default_lang,
                    favicon_url: '',
                    pixel: {},
                },
                start_page_id: siteData.start_page_id || 0,
                site_id: siteData.site_id || 0,
                layouts: siteData.layouts,

                // type: siteData.type ?? 'common',
                // pages: siteData.pages,
                // pages_order: siteData.pages_order,
                // layouts: siteData.layouts,
            }
        });
    }

    const openModal = (folder_id = 0) => {
        setFolderId(folder_id)
        setVisibleModal(true)
    }

    const onInstall = (task) => {
        // console.log('Modal value', task)

        dispatch(resetPageState());
        dispatch(setSiteItem({}));
        setIsLoading(true);

        recordCreate(task, folderId)
        setFolderId(0)
    }

    const filterFields = [
        [
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'published',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={openModal}
                    primaryLabel={t('common.action.create') + ' ' + t(section + '.object.title')}
                />

                <Content className="page-container site-layout-background">
                    <SiteListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        adminSection={adminSection}
                        list={list}
                        filters={filters}
                        recordCreate={openModal}
                    />
                    <ListFolderManager section={section} project_item={project_item} list={list}/>
                </Content>

                <SitesModal
                    t={t}
                    project={project_item}
                    title={t('common.action.create') + ' ' + t(section + '.object.title')}
                    isLoading={isLoading}
                    onOk={onInstall}
                    isVisible={isVisibleModal}
                    setVisible={(value) => {
                        setVisibleModal(value);
                        if (!value) setIsLoading(false);
                    }}
                    list={sect.sites}
                    // list={[
                    //     {
                    //         id: 1,
                    //         name: 'landing',
                    //         layouts: ['landing'],  // [string]
                    //         pages: [0], // [integer]
                    //
                    //         title: 'Продающий лендинг',
                    //         icon_name: 'laptop',
                    //         description: 'Продающая страница для одного продукта, презентация услуги'
                    //     },
                    //     {
                    //         id: 2,
                    //         name: 'mini',
                    //         layouts: ['mini'],
                    //         pages: [],
                    //
                    //         title: 'Мини-лендинг',
                    //         icon_name: 'envelope',
                    //         description: 'Страница подписки или мульти-ссылка. Баннер, описание, кнопки'
                    //     },
                    //     {
                    //         id: 3,
                    //         name: 'course',
                    //         layouts: ['course'],
                    //         pages: [],
                    //
                    //         title: 'Учебный курс',
                    //         icon_name: 'graduation-cap',
                    //         description: 'Список закрытых уроков, доступных после покупки или подписки'
                    //     },
                    //     {
                    //         id: 4,
                    //         name: 'article',
                    //         layouts: ['article'],
                    //         pages: [],
                    //
                    //         title: 'Отдельная статья',
                    //         icon_name: 'file',
                    //         description: 'Текстовая страница без стилей и других блоков. Как в Telegraph'
                    //     },
                    // ]}
                />
            </Layout>
        </AppWrapper>
    )
}
export default SiteList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link