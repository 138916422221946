import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {createMarkup, createObjectFromObjectsArray, getItemValues, ucFirst} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemData} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const UserContactEdit = () => {
    const {t} = useTranslation()
    const section = 'access'
    const adminSection = 'user'

    // data from URL params
    const params = useParams()
    const user_id = Number(params.user_id)
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, access, page} = useSelector(store => store)
    const project_item = project.item
    const item = access.item
    const pages = createObjectFromObjectsArray(page.short.list);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "id",
            type: "hidden",
        },
        {
            name: "page_id",
            type: "menu",
            data: 'page',
            values: pages,
            placeholder: t('common.placeholder.menu'),
            required: true,
        },
        {
            name: "user_id",
            placeholder: t('common.placeholder.text'),
            // desc: (<span dangerouslySetInnerHTML={createMarkup(t(`${adminSection}.page.form.user_id.desc`))} />),
            type: "hidden",
        },
        {
            name: "value",
            type: "hidden",
            required: true,
            placeholder: t('common.placeholder.text'),
        },
    ]

    // init form state
    const [formValues, setFormValues] = useState({
        id: 0,
        page_id: null,
        user_id: user_id,
        value: '1',
    })

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id)

        // set field values if correct data received
        if (item.id === id && formValues.id) form.setFieldsValue(formValues);

        if (admin.authorized && project_item.id && !page.short.list.length) {
            dispatch({type: 'getPageShortList', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    // set fields values to STATE if correct data received
    useEffect(() => {
        if (item && item.id === id) {
            let formValuesNew = getItemValues(item, formFields)
            formValuesNew.page_id = String(formValuesNew.page_id)
            setFormValues(formValuesNew)
        }
        //eslint-disable-next-line
    }, [item])

    // set fields values to FORM if correct data received
    useEffect(() => {
        if (formValues.id === id) form.setFieldsValue(formValues)
        //eslint-disable-next-line
    }, [formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate(ucFirst(section), values, formFields, id, project_item, admin, dispatch)
        backToList()
    }
    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}/profile/${user_id}`)
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={'user.page'} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default UserContactEdit