import React from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"

import {Button, Tooltip, Typography} from 'antd';
import {images, routes} from "../../config/config";

import {TableAvatar} from "../../components/List/Table/TableAvatar";
import ListTable from "../../components/List/Table/ListTable";
import {getUserFullName, momentFromUnix, ucFirst} from "../../library/functions";
import {setUserStoreParam} from "../../redux/reducers/UserReducer";
import {l} from "../../library/locale";
import {SorterWrapper} from "../../components/List/Table/SorterWrapper";
import {SettingOutlined, FormOutlined} from "@ant-design/icons";

const {Text} = Typography;

export const UserListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 10,
    }
) => {

    // for data debugging
    window.list = list
    const Section = ucFirst(section)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // navigate
    // const recordOpen    = (id) => navigate(`${routes.project_list}/${project.id}/${section}/${id}`)
    const recordUrl = (id, action) => `${routes.project_list}/${project.id}/${section}/${action}/${id}`
    const recordEdit = (id) => navigate(recordUrl(id, 'edit'))
    const recordOpen = (id) => navigate(recordUrl(id, 'profile'))

    const columns = [
        {
            title: l('common.form.avatar'), // Аватар
            dataIndex: 'image',
            className: 'avatar',
            responsive: ['sm'],
            width: 70,
            render: (icon, record) => {
                return <a
                    className="full-width"
                    href={recordUrl(record.id, 'profile')}
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                ><TableAvatar imageUrl={record.image} icon="arrow-up-right-from-square"/></a>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'name',
            sorter: true,
            title: <SorterWrapper>{l('user.list.table.name')}</SorterWrapper>,
            className: 'table-row-title title-link padding-none-vertical',
            // render: (name, record) => {
            //     return <NavigateLink
            //         className="inline full-width" style={{minHeight: 45, lineHeight: '45px'}}
            //         link={`${routes.project_list}/${project.id}/${section}/edit/${record.id}`}>
            //         {record.status ? <Text>{name}</Text> : <Text type="danger">{name}</Text>}
            //     </NavigateLink>
            // },
            render: (name, record) => {
                return <a
                    className="inline full-width"
                    // style={{minHeight: 45, lineHeight: '45px'}}
                    href={recordUrl(record.id, 'profile')}
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                >
                    {record.status ? <Text>{name}</Text> : <Text type="danger">{name}</Text>}
                </a>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },

        {
            dataIndex: 'created_at',
            title: <SorterWrapper>{l(section + '.list.table.created_at')}</SorterWrapper>,
            className: 'table-row-title title-link',
            sorter: true,
            width: 170,
            responsive: ['sm'],
            render: (text, record) => <span className="text-secondary">
                {momentFromUnix(text, 'DD.MM.YYYY')}
            </span>,
        },
        {
            dataIndex: 'type',
            title: <SorterWrapper>{l(section + '.form.profile_type.label')}</SorterWrapper>,
            className: 'table-row-title title-link',
            sorter: true,
            width: 170,
            responsive: ['sm'],
            render: (text, record) => <span className="text-secondary">
                {l(section + '.form.profile_type.value.' + text)}
            </span>,
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            // responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('user.list.table.icon.open')}>
                        <Button
                            type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                recordOpen(record.id)
                            }}
                            size="large"
                            icon={<FormOutlined/>}
                            href={recordUrl(record.id, 'profile')}
                        />
                    </Tooltip>
                    <Tooltip title={l('user.list.table.icon.settings')} placement="topRight">
                        <Button
                            type="text"
                            onClick={(e) => {
                                e.preventDefault()
                                recordEdit(record.id)
                            }}
                            size="large"
                            icon={<SettingOutlined/>}
                            href={recordUrl(record.id, 'edit')}
                        />
                    </Tooltip>
                    {/*<Tooltip title={record.status ? l('table.icon.ban') : l('table.icon.unban')}>
                        {record.status ?
                            <Button
                                type="text"
                                size="large"
                                onClick={() => recordChangeStatus(record.id, false)}
                                icon={<UserDeleteOutlined/>}
                            /> :
                            <Button
                                type="text"
                                size="large"
                                onClick={() => recordChangeStatus(record.id, true)}
                                icon={<LikeOutlined/>}
                            />
                        }
                    </Tooltip>*/}
                    {/*{filters.is_deleted ?*/}
                    {/*    <Tooltip title={l('table.icon.restore')}>*/}
                    {/*        <Button*/}
                    {/*            type="text"*/}
                    {/*            size="large"*/}
                    {/*            onClick={() => recordRestore(record.id)}*/}
                    {/*            icon={<ExperimentOutlined/>}*/}
                    {/*        />*/}
                    {/*    </Tooltip> :*/}
                    {/*    <Tooltip title={l('table.icon.delete')}>*/}
                    {/*        <Button*/}
                    {/*            type="text"*/}
                    {/*            size="large"*/}
                    {/*            onClick={() => recordDelete(record.id)}*/}
                    {/*            icon={<DeleteOutlined/>}*/}
                    {/*        />*/}
                    {/*    </Tooltip>*/}
                    {/*}*/}
                </div>
            ),
        },
        // {
        //     key: 'menu',
        //     className: 'menu-column drag-hide',
        //     width: 55,
        //     render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        // },
    ];

    // const table_menu_items = [
    //     // {
    //     //     key: 'tableMenuOpen',
    //     //     label: l('table.menu.open'),
    //     //     action: (record) => recordOpen(record.id)
    //     // },
    //     {
    //         key: 'tableMenuEdit',
    //         label: l('table.menu.edit'),
    //         action: (record) => recordEdit(record.id)
    //     },
    //
    //     {
    //         key: 'tableMenuStatus',
    //         label: l('table.menu.status'),
    //         action: (record) => recordChangeStatus(record.id, !record.status)
    //     },
    //     // {
    //     //     key: 'tableMenuCopy',
    //     //     label: l('table.menu.duplicate'),
    //     //     action: (record) => recordCopy(record.id)
    //     // },
    //     {
    //         key: 'tableMenuDelete',
    //         label: l('table.menu.delete'),
    //         action: (record) => recordDelete(record.id)
    //     },
    // ]
    //
    // if (filters.is_deleted) {
    //     // change delete to restore
    //     table_menu_items.pop()
    //     table_menu_items.push({
    //         key: 'tableMenuRestore',
    //         label: l('table.menu.restore'),
    //         action: (record) => recordRestore(record.id)
    //     })
    // }

    const onTableChange = (pagination, filters, sorter) => {
        // console.log('pagination', pagination)
        // console.log('sorter', sorter)
        dispatch(setUserStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    let data = [];

    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        let default_photo_url = images.universe.sections.user;
        if (record.profile_type !== 'user') default_photo_url = images.universe.sections.cat
        data.push({
            key: record.id,
            id: record.id,
            image: record.photo_url ? record.photo_url : default_photo_url,
            name: getUserFullName(record),
            title: record.title,
            type: record.profile_type,
            status: !record.ignore,
            deleted: record.deleted_at > 0,
            created_at: record.created_at,
        });
    }

    return <ListTable
        admin={admin}
        dataSource={data}
        columns={columns}
        project={project}
        onTableChange={onTableChange}
        pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: rowsTotal,
            position: ['bottomLeft', 'bottomCenter'],
        }}
    />
}

