import React from 'react'
import classes from './ControlButtons.module.scss'
import {CheckOutlined, CloseOutlined, LoadingOutlined, ProfileOutlined} from "@ant-design/icons";
import {isMobile} from "../../../../../../library/isMobile";
import {Button, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {changeGraphStatus} from "../../../../../../redux/reducers/GraphReducer";

const ControlButtons = ({isPanelFixed, onPanelFixedChange, onSave, onClose, controlPanel}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(store => store.graph.status);
    const icon = status.editing ? <LoadingOutlined/> : <CheckOutlined/>;

    const onClick = () => {
        onSave(true);
        dispatch(changeGraphStatus({
            editing: true,
            edited: false,
            redrawn: false,
        }));
    }

    const showSaveButton = () => {
        return status.edited ?

            <Button className={classes.save_button} type="primary" icon={<CheckOutlined/>} onClick={onClick}>
                {t('common.action.save')}
            </Button> :

            <Button className={classes.save_status} type="text" icon={icon} onClick={onClick}>
                {t('common.status.neuter.saved')}
            </Button>
    }

    return (
        <>
            <Tooltip
                title={t('graph.flow.panel.header.fix_button')}
                placement="left"
                trigger={isMobile ? 'none' : 'hover'}>

                <button
                    id='open-sidebar'
                    className={`${classes.control_button} ${isPanelFixed ? classes.active : '' } hidden-xs`}
                    onClick={() => {
                        onPanelFixedChange(!isPanelFixed)
                    }}
                >
                    <ProfileOutlined/>
                </button>
            </Tooltip>

            <div className={classes.header_top_buttons}>
                {controlPanel === 'default' ? showSaveButton() : null}

                <Button
                    id='close-sidebar'
                    type="text"
                    size="large"
                    onClick={() => {
                        onClose();
                        onPanelFixedChange(false);
                    }}
                    icon={<CloseOutlined/>}
                />
            </div>

        </>
    )
}

export default ControlButtons