import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addSegmentToList,
    fillSegmentList,
    patchSegmentInList,
    removeSegmentFromList,
    setSegmentItem,
} from "../reducers/SegmentReducer";

// init api methods
const base_path = backend_api.segment;

function* getSegmentList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillSegmentList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSegmentItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');

        if (response.data.ok) yield put(setSegmentItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createSegment(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addSegmentToList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateSegment(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchSegmentInList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteSegment(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeSegmentFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* segmentSaga() {
    yield takeEvery('getSegmentList', getSegmentList);
    yield takeEvery('getSegmentItem', getSegmentItem);
    yield takeEvery('createSegment', createSegment);
    yield takeEvery('updateSegment', updateSegment);
    yield takeEvery('deleteSegment', deleteSegment);
}

