import {createSlice} from "@reduxjs/toolkit";

const UserUtmReducer = createSlice({
    name: 'userUtm',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillUserUtmList(state, action) {
            state.list = action.payload
        },
        setUserUtmListCount(state, action) {
            state.count = action.payload;
        },
        setUserUtmItem(state, action) {
            state.item = action.payload;
        },
        addUserUtmToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeUserUtmFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        resetUserUtmState(state) {
            state.list = [];
            state.item = {};
            state.count = 0;
        }
    }
})

export default UserUtmReducer.reducer

export const {
    fillUserUtmList,
    setUserUtmItem,
    setUserUtmListCount,
    addUserUtmToList,
    removeUserUtmFromList,
    resetUserUtmState,
} = UserUtmReducer.actions