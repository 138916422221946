import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {dotenv, routes} from "../../config/config";
import {Layout, Menu} from "antd";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {useTranslation} from "react-i18next";
import {UserListTable} from "./UserListTable";
import {ListHeader} from "../../components/List/Header/ListHeader";
import {
    compareObjects,
    createObjectFromObjectsArray,
    getSearchFilters,
    inArray,
    isArr,
    objectLength,
    setSearchFilters,
    ucFirst
} from "../../library/functions";
import {setUserStoreParam} from "../../redux/reducers/UserReducer";
import ImportModal from "../../components/Modal/ImportModal";
import {notice} from "../../library/notice";
import {UploadOutlined} from "@ant-design/icons";
import {contact_types} from "../../schemas/frontend/effects";


const {Content} = Layout;

const UserList = () => {
    // init section name
    const section = 'user'

    // init hook functions
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const searchFilters = getSearchFilters(searchParams);

    // store and state
    const {admin, project, user, field} = useSelector(store => store)
    const [filtersClickCount, setFiltersClickCount] = useState(0);
    const filters = user.filters

    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleModal, setVisibleModal] = useState(false);

    // init fields lists
    let lists = []
    let game_fields = []
    for (const fieldItem of field.list) {
        if (fieldItem.system_field_type === 'list') {
            lists.push(fieldItem)
        } else if (fieldItem.data_type === 'int' && !inArray(fieldItem.system_field_type, ['crm', 'form'])) {
            game_fields.push(fieldItem)
        }
    }

    // init universal vars
    const project_item = project.item
    const list = user.list
    const rowsTotal = user.count || 0
    const currentPage = user.page || 1
    const pageSize = user.pageSize || 10
    const sorter = user.sorter

    useLayoutEffect(() => {
        if (admin.authorized && project_item.id) {
            // get all project fields
            dispatch({type: 'getFieldList', admin, filters: {project_id: project_item.id, deleted_since: 0}})
        }
    }, [admin.authorized, project_item.id])

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                // console.log('useEffect sorter', sorter)
                let prefix = sorter.order === 'ascend' ? '' : '-'
                if (sorter.field === 'name') {
                    ordering = [prefix + 'first_name', prefix + 'title', prefix + 'last_name']
                } else if (sorter.field === 'type') {
                    ordering = prefix + 'profile_type'
                } else if (sorter.field === 'created_at') {
                    ordering = [prefix + 'id']
                } else {
                    ordering = prefix + sorter.field
                }
            }
            let filters_result = {
                project_id: project_item.id,
                // profile_types: ['user', 'bot', 'chat', 'channel'],
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) {
                // filters_result = {...filters_result, ...filters}
                for (const [key, val] of Object.entries(filters)) {
                    if (val === '') filters_result[key] = ' '
                    else filters_result[key] = val
                }
            }
            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize, filtersClickCount])
    // you can also add {user.imported} for update data after import

    useEffect(() => {
        if (!compareObjects(filters, searchFilters)) storeFilters(searchFilters)

        //eslint-disable-next-line
    }, [JSON.stringify(searchFilters)])

    useEffect(() => {
        if (user.imported) {
            notice.success(t(section + '.import.upload.sent', {
                postProcess: 'sprintf',
                sprintf: [user.imported]
            }))
            dispatch(setUserStoreParam({imported: 0}))
        }

        //eslint-disable-next-line
    }, [user.imported])

    const storeFilters = (newFilters) => {
        // for params contain _id covert type to array of ints:
        for (let key in newFilters) {
            if (key.includes('_id') || key.includes('type')) {
                if (!isArr(newFilters[key])) newFilters[key] = [newFilters[key]]
            }
        }
        dispatch(setUserStoreParam({filters: newFilters, page: 1}))
    }

    const onFiltersSubmit = (newFilters) => {
        setFilters(newFilters)
        setFiltersClickCount((prev) => prev + 1)
    }

    const setFilters = (newFilters) => {
        // console.log('setFilters', newFilters)
        setSearchFilters(newFilters, setSearchParams)
    }

    const searchHandler = (value) => {
        let filtersResult = {name: value}
        if (filters) filtersResult = {...filters, ...filtersResult}
        if (!value && filtersResult.name !== undefined) delete filtersResult.name;
        setFilters(filtersResult)
    }

    const recordCreate = () => {
        navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0`)
    }

    const filterFields = [
        [
            {
                name: 'contact_types',
                type: 'tags',
                section: section + '.filter',
                placeholder: t('common.placeholder.tags'),
                values: contact_types,
                localized: true,
            },
            {
                name: 'contact_value',
                type: 'text',
                section: section + '.filter',
                placeholder: t('common.placeholder.text'),
            },
            {
                name: 'list_id',
                type: 'tags',
                section: section + '.filter',
                placeholder: t('common.placeholder.tags'),
                values: createObjectFromObjectsArray(lists)
            },
            {
                name: 'gf_id',
                type: 'tags',
                section: section + '.filter',
                placeholder: t('common.placeholder.tags'),
                values: createObjectFromObjectsArray(game_fields)
            },
            {
                name: 'profile_types',
                type: 'tags',
                section: section + '.filter',
                placeholder: t('common.placeholder.tags'),
                values: ['user', 'bot', 'chat', 'channel'],
                localized: true,
            },
            {
                name: 'appeals',
                type: 'tags',
                section: section + '.filter',
                placeholder: t('common.placeholder.tags'),
                values: ['0', '1', '2'],
                localized: true,
            },
            {
                name: 'ignore',
                type: 'menu',
                section: section + '.filter',
                placeholder: t('common.placeholder.menu'),
                values: ['0', '1'],
                localized: true,
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            },
            {
                name: 'is_free',
                type: 'switcher'
            },
        ]
    ]

    if (project_item.id === dotenv.main_project) {
        filterFields[0].push({
            name: 'admin_project_id',
            type: 'text',
            section: section + '.filter',
            placeholder: t('common.placeholder.text'),
        })
        filterFields[0].push({
            name: 'admin_username',
            type: 'text',
            section: section + '.filter',
            placeholder: t('common.placeholder.text'),
        })
    }

    const recordImport = () => {
        // console.log('Import:', fileContent);
        dispatch({
            type: 'importUsers',
            admin,
            data: {
                project_id: project_item.id,
                content: fileContent,
            }
        });
    };

    const onMenuClick = (e) => {
        if (e.key === 'import') {
            setVisibleModal(true);
            setFileList([]);
            setFileContent('');
        }
    };

    const createMenu = (<Menu
            onClick={onMenuClick}
            items={[
                {
                    key: 'import',
                    label: t('common.action.import'),
                    icon: <UploadOutlined/>
                },
            ]}
        />
    );


    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={onFiltersSubmit}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    searchPlaceholder={t('page_header.search.name')}
                    onPrimaryWithFolder={recordCreate}
                    primaryMenu={createMenu}
                    addUtms={true}
                />
                <Content className="page-container site-layout-background padding-top-none users-list">
                    <div className="margin-bottom-sm margin-top-ps font-size-md color-secondary"
                         style={{paddingLeft: 3}}>
                        {objectLength(filters) ?
                            t(section + '.list.counter.filtered') :
                            t(section + '.list.counter.total')
                        }: {rowsTotal}
                    </div>
                    <UserListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                    />

                    <ImportModal
                        t={t}
                        onOk={recordImport}
                        section={section}
                        isVisibleModal={isVisibleModal}
                        setVisibleModal={setVisibleModal}
                        fileList={fileList}
                        setFileList={setFileList}
                        fileContent={fileContent}
                        setFileContent={setFileContent}
                        exampleUrl={dotenv.user_import_example_url}
                        addInstructions={true}
                    />
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default UserList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link