import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    fillPageShortList,
    setPageItemParam,
    setPageShortListStatus,
    setPageStoreParam
} from "../../../redux/reducers/PageReducer";

import app_classes from "../../Layouts/AppWrapper/AppWrapper.module.scss";
import dialogClasses from "../../Communication/Dialog/Dialogs.module.scss";
import classes from "./PageItem.module.scss";

import {routes} from "../../../config/config";
import {Button, Input, Layout, List, Result, Tree} from "antd";
import {
    CopyOutlined,
    DownOutlined,
    LeftOutlined,
    PlusOutlined,
    RightOutlined
} from "@ant-design/icons";

import Scrollbar from "react-scrollbars-custom";
import PageSettings from "./PageSettings";
import LeftPanel from "../../Layouts/AppWrapper/LeftPanel/LeftPanel";
import {compareObjects, deepGet, inArray, objectLength, toNum, ucFirst} from "../../../library/functions";
import {useTranslation} from "react-i18next";
import {LayoutsModal} from "../Add/LayoutsModal";

function cutString(str) {
    if (str.length > 40) {
        const lastChars = str.slice(-7);
        const firstChars = str.slice(0, 28);
        return `${firstChars} ... ${lastChars}`;
    } else {
        return str;
    }
}

const handleTitle = (title, item, id = 0, parentId = 0, renderExtra = null) => {
    let classes = ['label-span', 'inline', 'full-width', 'color-default'];
    let text = cutString(title);

    if (!item.is_on) classes.push("color-danger");
    else if (item.is_example) classes.push("color-secondary");
    if (item.is_main) classes.push("text-underline");

    let dropdown = null;

    if (renderExtra) {
        // dropdown = renderExtra(parentId);
        // console.log(
        //     'item.id', item.id,
        //     'id', id,
        //     'parentId', parentId,
        // )

        if (!id && parentId && parentId === item.id) {
            dropdown = renderExtra(parentId);
        } else if (id && id === item.id) {
            dropdown = renderExtra(item.id);
        }
        // else {
        //     TODO: click on item select it, but must just open dropdown menu
        //     dropdown = renderExtra(item.id);
        // }
    }

    let url = `${routes.project_list}/${item.project_id}/site/page/${item.id}?site_id=${item.site_id}`;

    // if (is_main) return <span><span className={classes.join(" ")}>{text}</span> <HomeOutlined/></span>;
    return <a href={url} className={classes.join(" ")} onClick={(e) => e.preventDefault()}>
        {dropdown}
        {text}
    </a>;
}

const expandAndFilter = (map, node, search, expanded) => {
    if (node.children.length) {
        node.children = node.children.filter(child => expandAndFilter(map, child, search, expanded));
        if (node.children.length) {
            expanded.push(node.key);
            return true;
        } else if (node.item.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
        }
    } else if (node.item.title.toLowerCase().includes(search.toLowerCase())) {
        return true;
    }

    return false;
};

const createPagesTree = (
    pagesList,
    site_id,
    page_id = 0,
    expanded = [],
    search = '',
    renderButtons = null,
    parentId = 0
) => {
    const map = {};
    let roots = [];

    const filter = search.trim();
    const isSearch = filter !== '';

    // Convert id to key and remove unwanted fields
    pagesList.forEach(page => {
        if (page.site_id !== site_id) return;

        let {id, title, parent_id} = page;
        title = handleTitle(title, page, page_id, parentId, renderButtons);

        const key = String(id);
        const children = [];

        map[key] = {key, title, item: page, children};
        // if (key === '618') console.log('map[key]', map[key])

        if (!parent_id) {
            roots.push(map[key]);
        } else {
            const parentKey = String(parent_id);

            if (!map[parentKey]) {
                const parentItem = pagesList.find(item => item.id === parent_id);
                if (!parentItem) return;

                map[parentKey] = {
                    key: parentKey,
                    title: handleTitle(parentItem.title, parentItem, page_id, parentId, renderButtons),
                    item: parentItem,
                    children: []
                };
            }

            map[parentKey].children.push(map[key]);

            // if (parentKey === '675') {
            //     console.log('parentKey', parentKey);
            //     console.log('map[parentKey]', map[parentKey].children);
            // }
        }
    });

    if (isSearch) {
        roots = roots.filter(root => expandAndFilter(map, root, filter, expanded));
    }
    // Add all parents to expanded list if page_id > 0 and matches
    else if (page_id > 0) {
        const currentPageKey = String(page_id);
        // console.log('page_id', page_id, map[currentPageKey], map)
        let currentParentKey = map[currentPageKey]?.item?.parent_id;

        while (currentParentKey) {
            currentParentKey = String(currentParentKey);
            if (!inArray(currentParentKey, expanded)) expanded.push(currentParentKey);
            currentParentKey = map[currentParentKey]?.item?.parent_id;
        }

        if (!expanded.includes(currentPageKey)) expanded.push(currentPageKey);
    }

    return roots;
};

const convertTreeToList = (tree, items = [], list = []) => {
    tree.forEach(node => {
        items.push(node.item);
        list.push(toNum(node.key));
        if (node.children && node.children.length) convertTreeToList(node.children, items, list);
    });

    return {items, list};
}

const findParentKey = (data, key) => {
    let parentKey = null;
    const traverse = (nodes, parent) => {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node.key === key) {
                parentKey = parent ? parent.key : null;
                break;
            }
            if (node.children) {
                traverse(node.children, node);
            }
        }
    };
    traverse(data, null);
    return parentKey;
};

const PageItem = () => {
    const section = 'page';
    const Section = ucFirst(section);
    const {t} = useTranslation();

    // data from URL params
    const params = useParams()
    const id = toNum(params.id)
    const project_id = toNum(params.project_id)

    // data from GET params
    const [searchParams, setSearchParams] = useSearchParams();
    const parent_id = toNum(searchParams.get('parent_id'))
    let site_id = toNum(searchParams.get('site_id'))
    let selectedKeys = [];
    if (id > 0) {
        selectedKeys = [String(id)];
    } else if (parent_id > 0) {
        selectedKeys = [String(parent_id)];
    }

    // console.log('parent_id', parent_id, selectedKeys)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {admin, page, site, layout} = useSelector(store => store);
    const list = page.short.list;
    const listStatus = page.short.status;
    const item = page.item;
    const siteItem = site.item;

    // if (list && list.length) console.log('list site', list[0].site_id)
    // if (page.item.site_id) site_id = page.item.site_id

    const [loading, setLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(false);
    const [listState, setListState] = useState([]);
    // const [pageTypes, setPageTypes] = useState([]);  // deprecated
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [search, setSearch] = useState('');
    const [showList, setShowList] = useState(true);
    const [isMoving, setIsMoving] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [parentId, setParentId] = useState(0);

    const recordCopy = (id) => {
        // console.log('recordCopy itemId', itemId)
        dispatch(setPageStoreParam({addItemToEnd: true}));
        dispatch({type: 'copy' + Section, admin, id})
    }

    const navToPage = (itemId, siteIdParam = 0, layoutNameParam = null, parentId = 0) => {
        // console.log('navToPage', itemId, site_id)
        let url = `${routes.project_list}/${project_id}/site/${section}/${itemId}?`

        // if (!itemId) url += 'tab=form'
        // if (!id) url += '&layout_name=landing'

        if (siteIdParam) url += `&site_id=${siteIdParam}`
        // else if (item.site_id) url += `&site_id=${item.site_id}`
        else if (site_id) url += `&site_id=${site_id}`

        if (layoutNameParam) url += `&layout_name=${layoutNameParam}`
        // else if (layout_name) url += `&layout_name=${layout_name}`

        if (parentId) url += `&parent_id=${parentId}`

        navigate(url);
    }

    // const onMenuClick = (e, parentId = 0) => {
    //     // console.log('e.key', e.key)
    //     navToPage(0, 0, e.key, parentId)
    // }

    const onAddChild = (e, parentId = 0) => {
        // console.log('onAddChild', parentId)
        setParentId(parentId)
        setVisibleModal(true)
    }

    const onModalClick = (layoutName) => {
        // console.log('layoutName', layoutName, parentId)
        navToPage(0, 0, layoutName, parentId)
        setParentId(0)
    }

    const onLayoutInstall = (layoutName) => {
        if (!layoutName) {
            navigate(`${routes.project_list}/${project_id}/layout/edit/0`)
            return
        }

        // console.log('layoutName', layoutName, parentId)
        dispatch({type: 'installLayout', admin, data: {
            project_id,
            name: layoutName,
        }})
    }

    const renderButtons = (itemId) => {
        return (<span className="float-right">
            <Button
                key="copy"
                type="text"
                size="small"
                className="inherit-icon-size inverted padding-left-none margin-left-xs"
                icon={<CopyOutlined/>}
                title={t(section + '.item.menu.copy')}
                onClick={() => recordCopy(itemId)}
            />
            <Button
                key="add"
                type="text"
                size="small"
                className="inherit-icon-size inverted padding-left-none margin-left-xs"
                icon={<PlusOutlined/>}
                title={t(section + '.item.menu.child')}
                onClick={(e) => {
                    onAddChild(e, itemId)
                }}
            />
            {/*<Dropdown
                key="newItemMenu"
                trigger="click"
                overlay={<Menu
                    onClick={(e) => {
                        onMenuClick(e, itemId)
                    }}
                    items={pageTypes}
                />}
                getPopupContainer={() => document.getElementById('item-left-menu')}
                placement="bottomRight"
            >
                    <Button
                        type="text"
                        size="small"
                        className="inherit-icon-size inverted padding-left-none margin-left-xs"
                        icon={<PlusOutlined/>}
                        title={t(section + '.item.menu.child')}
                    />
            </Dropdown>*/}
        </span>)
    }

    useLayoutEffect(() => {
        // console.log('useLayoutEffect', admin.authorized, id, site_id)
        if (admin.authorized && site_id) {
            // console.log('Get pages list - PAGE ITEM')
            dispatch({
                type: 'get' + Section + 'ShortList', admin, filters: {
                    project_id: project_id,
                    site_id: site_id,
                    ordering: 'custom',
                    category_page_id: 0
                }
            });

            // page types - base menu items for new page modal
            if (layout.short.list.length === 0 || layout.short.list[0].project_id !== project_id) {
                dispatch({type: 'getLayoutShortList', admin, filters: {project_id: project_id, ordering: 'id'}});
            }

            // page types - menu items for new page modal
            if (layout.public.list.length === 0) {
                // console.info('Get layout public list - PAGE ITEM')
                dispatch({type: 'getLayoutPublicList', admin, filters: {is_on: true, ordering: 'created_at'}});
            }

            return () => {
                // console.log('PAGE LIST UNMOUNT');
                // dispatch(fillPageShortList([]));
                setExpandedKeys([]);
                setLoadingItems(false);
                setLoading(true);
            }
        }
    }, [admin, site_id]);

    useEffect(() => {
        // console.log('Page useEffect', list.length, page.short.count)
        // reload list if wrong site or list was loaded partially
        if ((list.length !== page.short.count && !loadingItems) || (list.length && site_id !== list[0].site_id)) {
            // console.log('1) list.length', list, 'count', page.short.count, 'project_id', project_id, 'site_id', site_id)

            // setTimeout(() => {  // timeout for tests
                dispatch({
                    type: 'get' + Section + 'ShortList', admin, filters: {
                        project_id: project_id,
                        site_id: site_id,
                        ordering: 'custom',
                    }
                })
            // }, 2000);
            setLoadingItems(true);
        }
        // list handle logic
        else if (listStatus) {
            // console.log('2) list.length', list, 'count', page.short.count, 'project_id', project_id, 'site_id', site_id)
            setLoadingItems(false);

            let expanded = [...expandedKeys];
            const tree = createPagesTree(list, site_id, id, expanded, search, renderButtons, parent_id);

            if (expanded.length === 0 && selectedKeys.length > 0) {
                expanded = [selectedKeys[0]];
            }

            // console.log('expanded', expanded, 'selectedKeys', selectedKeys)
            // console.log('tree', deepGet(tree, '0.children.0.children'))
            // console.log('useEffect', site_id, id, search)
            // console.log('useEffect', list)

            setListState(tree);
            setExpandedKeys(expanded);

            if (!loading && !isDragging && (id < 0 || id === item.id) && list.length && list.length === page.short.count) {
                const listOrder = list.map(item => item.id);
                // update pages order on create or delete pages
                if (!compareObjects(listOrder, siteItem.pages_order)) {
                    console.log('Site Order', siteItem.pages_order, '\nList Order', listOrder)
                    dispatch({
                        type: 'updateSite', admin, data: {
                            id: site_id,
                            project_id: project_id,
                            pages_order: listOrder,
                        }
                    });
                }
            }

            if (loading && id < 0) {
                const next_page_id = list.length ? list[0].id : 0;
                navigate(`${routes.project_list}/${project_id}/site/${section}/${next_page_id}?site_id=${site_id}`);
            }

            setLoading(false);
            setIsDragging(false);
        }

    }, [id, list, search]);

    // useEffect(() => {
    //     let layouts = [];
    //
    //     for (const l of layout.short.list) {
    //         if ((!l.site_id || l.site_id === site_id) && l.is_on) {
    //             layouts.push({key: l.name, label: l.title});
    //         }
    //     }
    //
    //     layouts.push({key: '', label: t(section + '.item.menu.raw')});
    //
    //     setPageTypes(layouts);
    // }, [layout.short.list]);

    useEffect(() => {
        // const data = convertTreeToList(listState);
        const itemsList = list;

        // console.log('item.parent_id', item.parent_id, itemsList)

        if (isMoving) {
            // console.info('isMoving', isMoving)
            setIsMoving(false);
        }

        // visual divider
        else if (item.parent_id && itemsList.length) {
            const index = itemsList.findIndex(i => i.id === item.id);
            const parentIndex = itemsList.findIndex(i => i.id === item.parent_id);

            if (!itemsList[index] || !itemsList[parentIndex]) {
                console.info('Items has no index', index, 'or parentIndex', parentIndex)
            }

            if (index < parentIndex && itemsList[index] && itemsList[parentIndex]) {
                const fromZeroToPage = itemsList.slice(0, index);
                const fromPageToParent = itemsList.slice(index + 1, parentIndex);
                const fromParentToEnd = itemsList.slice(parentIndex + 1);

                // console.log(
                //     ' fromZeroToPage', fromZeroToPage,
                //     '\n fromPageToParent', fromPageToParent,
                //     '\n fromParentToEnd', fromParentToEnd
                // )

                const newList = [
                    ...fromZeroToPage,
                    ...fromPageToParent,
                    itemsList[parentIndex],
                    itemsList[index],
                    ...fromParentToEnd
                ];

                const newSiteList = newList.map(i => i.id);
                // console.log('newSiteList', newSiteList)

                // store new order in site param
                dispatch({
                    type: 'updateSite', admin, data: {
                        id: site_id,
                        project_id: project_id,
                        pages_order: newSiteList
                    }
                });

                // update list
                dispatch(fillPageShortList(newList));

                let expanded = [...expandedKeys];
                expanded.push(itemsList[parentIndex])
                setExpandedKeys(expanded);
            }
        }

    }, [item.parent_id]);

    const onDrop = (info) => {
        // console.log(info);
        setIsDragging(true);

        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };

        const data = [...listState];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        // console.log('dragObj', dragObj.key)
        // console.log('dropKey', dropKey)
        // console.log('info.dropToGap', info.dropToGap)

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item['children'] = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // console.log('item', item)
            });

            // console.log('Case 1: NOT dropToGap')
        } else if (
            (info.node.props.children || []).length > 0 &&
            // Has children
            info.node.props.expanded &&
            // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
            // console.log('Case 2: Expanded + dropPosition === 1')
        } else {
            let ar;
            let i;

            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });

            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }

            // console.log('Case 3: else')
        }

        const parent_key = findParentKey(data, dragObj.key);
        const parent_id = parent_key ? toNum(parent_key) : 0;
        const old_parent_id = deepGet(dragObj, ['item', 'parent_id']);
        // console.log('old_parent_id', old_parent_id, 'parent_id', parent_id)

        // add to expanded keys if not already there:
        if (!inArray(parent_key, expandedKeys)) {
            expandedKeys.push(parent_key);
            setExpandedKeys(expandedKeys);
        }

        const result = convertTreeToList(data);
        // console.log('Order Result', result)

        if (old_parent_id === undefined) {
            console.error('Old parent ID is undefined', dragObj);
        }
        // store new parent page id
        else if (old_parent_id !== parent_id) {
            const item_id = toNum(dragObj.key);
            if (item_id > 0) {
                // prevent multiple requests
                setIsMoving(true);

                dispatch({
                    type: 'update' + Section + 'Order', admin, data: {
                        item: {
                            id: item_id,
                            parent_id: parent_id,
                        },
                        list: result.items,
                    }
                });

                // change parent page id (moved to reducer)
                // if (item_id === id) {
                //     dispatch(setPageItemParam({parent_id: parent_id}));
                // }
            }
        } else {
            // console.log('result.items', result.items)
            // change sort order in redux
            dispatch(fillPageShortList(result.items));
        }

        // store new order in site param
        dispatch({
            type: 'updateSite', admin, data: {
                id: site_id,
                project_id: project_id,
                pages_order: result.list,
            }
        });

        setListState(data);
    };

    let onSelect = (selectedKeys) => {
        if (selectedKeys.length === 0) return;
        const newId = selectedKeys[0];
        navToPage(newId);
    };

    return (
        <Layout className="site-layout site-layout-background">
            <div className={app_classes.app_wrapper}>
                <div className={`${dialogClasses.leftPanel} ${id >= 0 ? classes.opened : classes.closed}`}>
                    <LeftPanel adaptive={false}/>
                </div>
                <div
                    className={`site-layout-background flex full-height ${classes.sidebar} 
                    ${id >= 0 ? classes.opened : classes.closed} 
                    ${showList ? classes.show : classes.hide}
                    `}
                >
                    <div className={`flex flex-column ${classes.listWrap}`}>
                        <List.Item>
                            <Input.Search
                                className={dialogClasses.search}
                                placeholder={t(section + '.list.search.placeholder')}
                                size="large"
                                style={{width: 200}}
                                onSearch={setSearch}
                                // onChange={value => console.log(value)}
                            />
                        </List.Item>
                        <Scrollbar>
                            <div id="item-left-menu">
                                <div className="padding-left-ps padding-right-ps book-tree">
                                    {/*{loading ? <div className="see-through-preloader">
                                        <LoadingOutlined />
                                    </div> : null}*/}

                                    {objectLength(listState) ? <Tree
                                        className={loadingItems ? "loading" : "draggable-tree"}
                                        onSelect={onSelect}
                                        switcherIcon={<DownOutlined/>}

                                        selectedKeys={selectedKeys}
                                        expandedKeys={expandedKeys}
                                        onExpand={(keys) => setExpandedKeys(keys)}
                                        defaultExpandParent={true}

                                        blockNode={true}
                                        draggable={{icon: false}}
                                        onDrop={onDrop}
                                        treeData={listState}
                                        showLine={false}
                                        showIcon={true}
                                    /> : null}
                                </div>

                                <div className="padding-left-sm padding-right-sm margin-bottom-md">
                                    {/*<Dropdown
                                        key="newItemMenu"
                                        className=""
                                        trigger="click"
                                        // type="primary"
                                        overlay={<Menu
                                            onClick={onMenuClick}
                                            items={pageTypes}
                                        />}
                                        getPopupContainer={() => document.getElementById('item-left-menu')}
                                        placement="bottom"
                                    >
                                        <div className="dropdown-wrapper inline-block">
                                            <Button
                                                type="text"
                                                className="margin-top-xp full-width align-left inverted padding-left-none"
                                                icon={<PlusOutlined/>}
                                                // onClick={() => navToPage(0)}
                                            >
                                                {t(section + '.item.menu.add')}
                                            </Button>
                                        </div>
                                    </Dropdown>*/}

                                    <Button
                                        type="text"
                                        className="margin-top-xp full-width align-left inverted padding-left-none"
                                        icon={<PlusOutlined/>}
                                        onClick={() => setVisibleModal(true)}
                                    >
                                        {t(section + '.item.menu.add')}
                                    </Button>
                                </div>
                            </div>
                        </Scrollbar>

                        <LayoutsModal
                            t={t}
                            title={t(section  + '.item.modal.title')}
                            isVisible={isVisibleModal}
                            setVisible={setVisibleModal}
                            onAdd={onModalClick}
                            onInstall={onLayoutInstall}
                            list={layout.short.list}
                            publicList={layout.public.list}
                            site_id={site_id}
                        />
                    </div>

                    <div className={classes.expandBtn}>
                        {showList ? <Button
                            type="default"
                            icon={<LeftOutlined/>}
                            onClick={() => setShowList(false)}
                        /> : <Button
                            type="primary"
                            icon={<RightOutlined/>}
                            onClick={() => setShowList(true)}
                        />}
                    </div>
                </div>
                <div className={`${classes.content} ${id >= 0 ? classes.opened : classes.closed}`}>
                    <Scrollbar>
                        {id >= 0 ?
                            <PageSettings
                                advanced={true}
                                onAddChild={onAddChild}
                                listFilters={{ordering: 'custom'}}
                            /> :
                            <Result
                                title={t(section + '.item.off.title')}
                                className={`margin-top-lg margin-bottom-lg`}
                                subTitle={<div>{t(section + '.item.off.desc')}</div>}
                            />
                        }
                    </Scrollbar>
                </div>
            </div>
        </Layout>
    )
}

export default PageItem