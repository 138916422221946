import {createSlice} from "@reduxjs/toolkit";

const SiteReducer = createSlice({
    name: 'site',
    initialState: {
        section: null,
        list: null,
        item: {},
        start_page: {},
        blockSaga: false,
    },
    reducers: {
        blockSiteSaga(state, action) {
            state.blockSaga = action.payload;
        },
        setSiteSection(state, action) {
            state.section = action.payload;
        },
        setSiteList(state, action) {
            state.list = action.payload;
        },
        fillSiteList(state, action) {
            state.list = [...action.payload]
        },
        setSiteItem(state, action) {
            state.item = action.payload;
        },
        setSiteStartPage(state, action) {
            state.start_page = action.payload;
        },
        setSiteItemById(state, action) {
            const index = state.list ? state.list.findIndex(o => o.id === action.payload) : -1;
            if (index !== -1) {
                state.item = state.list[index]
            }
        },
        addSiteToList(state, action) {
            state.item = action.payload;
            state.list = state.list ? [...state.list, action.payload] : [action.payload]
        },
        patchSiteInList(state, action) {
            const list = state.list ? [...state.list] : [];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        removeSiteFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        clearSiteItem(state) {
            state.item = {};
        },
        resetSiteState(state) {
            state.list = null;
            state.item = {};
        },
    }
})

export default SiteReducer.reducer
export const {
    blockSiteSaga,
    setSiteSection,
    setSiteList,
    fillSiteList,
    setSiteItem,
    setSiteItemById,
    addSiteToList,
    patchSiteInList,
    removeSiteFromList,
    setSiteStartPage,
    resetSiteState,
    clearSiteItem,
} = SiteReducer.actions