import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addThemeToList,
    fillThemeList,
    patchThemeInList,
    removeThemeFromList,
    setThemeItem,
    setThemeListCount,
    fillThemeShortList,
    setThemeShortListCount,
    clearThemeItem,
    fillThemePublicList,
    addThemeToProject,
} from "../reducers/ThemeReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.theme;

function* getThemeList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillThemeList(response.data.result))
            yield put(setThemeListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getThemeShortList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillThemeShortList(response.data.result))
            yield put(setThemeShortListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getThemePublicList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/public', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillThemePublicList(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getThemeItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setThemeItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getThemeByFilters(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/item', 'get', arg.filters);
        if (response.data.ok) yield put(setThemeItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        yield put(clearThemeItem())
        // apiErrorNotice(error)
        // return null
    }
}

function* installTheme(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/install', 'post', arg.data);

        if (response.data.ok) {
            if (arg.action === 'create') yield put(addThemeToList(response.data.result))
            else yield put(addThemeToProject(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createTheme(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addThemeToList(response.data.result))
            yield put(setThemeItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateTheme(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchThemeInList(response.data.result))
            yield put(setThemeItem(response.data.result))
            if (arg.show_notice) notice.success(l("theme.notice.saved"))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyTheme(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '_copy'

        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setThemeItem(response.data.result))
            yield put(addThemeToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreTheme(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeThemeFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteTheme(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeThemeFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* themeSaga() {
    yield takeEvery('getThemeList', getThemeList);
    yield takeEvery('getThemeShortList', getThemeShortList);
    yield takeEvery('getThemePublicList', getThemePublicList);
    yield takeEvery('getThemeByFilters', getThemeByFilters);
    yield takeEvery('getThemeItem', getThemeItem);
    yield takeEvery('installTheme', installTheme);
    yield takeEvery('createTheme', createTheme);
    yield takeEvery('copyTheme', copyTheme);
    yield takeEvery('updateTheme', updateTheme);
    yield takeEvery('restoreTheme', restoreTheme);
    yield takeEvery('deleteTheme', deleteTheme);
}

