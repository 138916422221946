import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    shared: [],
    item: {},
    searchValue: '',
    justCreated: 0,
    test: false
}

const ProjectReducer = createSlice({
    name: 'project',
    initialState,
    reducers: {
        fillProjectList(state, action) {
            state.list = action.payload
        },
        fillSharedProjectList(state, action) {
            state.shared = action.payload
        },
        addProjectToList(state, action) {
            state.list = [...state.list, action.payload]
        },
        setProjectItem(state, action) {
            state.item = action.payload
        },
        patchProjectItem(state, action) {
            state.item = {...action.payload}
        },
        patchProjectInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            } else {
                // also look in shared list:
                const shared = [...state.shared];
                const shared_index = shared.findIndex(o => o.id === oid);
                if (shared_index !== -1) {
                    shared[shared_index] = {...shared[shared_index], ...action.payload};
                    state.shared = shared;
                }
            }
            state.item = action.payload
        },
        removeProjectFromList(state, action) {
            const oid = action.payload.id;
            const own_index = state.list.findIndex(o => o.id === oid);
            const shared_index = state.shared.findIndex(o => o.id === oid);

            if (own_index !== -1) {
                const object_list = [...state.list];
                state.list.length = 0;  // обнуляем, чтобы список обновился и компонента перечиталась
                state.list = object_list.filter(p => p.id !== oid)
            } else if (shared_index !== -1) {
                const object_list = [...state.shared];
                state.shared.length = 0;  // обнуляем, чтобы список обновился и компонента перечиталась
                state.shared = object_list.filter(p => p.id !== oid)
            }
        },
        setProjectSearchValue(state, action) {
            state.searchValue = action.payload
        },
        setJustCreated(state, action) {
            state.justCreated = action.payload
        },
        // setProjectItemById(state, action) {
        //     let item = state.list.find(obj => obj.id === action.payload)
        //     if (item === undefined) item = state.shared.find(obj => obj.id === action.payload)
        //     state.item = item ?? {id: null}; // for redirect on wrong project opening
        // },
        // resetProjectState(state) {
        //     for (const [key, value] of Object.entries(initialState)) {
        //         state[key] = value;
        //     }
        // },
    }
})

export default ProjectReducer.reducer
export const {
    fillProjectList,
    fillSharedProjectList,
    addProjectToList,
    setProjectItem,
    setJustCreated,
    patchProjectItem,
    patchProjectInList,
    removeProjectFromList,
    setProjectSearchValue,
    // setProjectItemById,
    // resetProjectState,
} = ProjectReducer.actions