import React from 'react'
import {Input, Typography} from "antd";
const {Text} = Typography;

const InputTitleAnt = ({ fieldTitle='', fieldDesc='', fieldName, value='', placeholder='', required=false, id }) => {
    return (
        <form id={`form_${id}`} autoComplete="off" className="ant-form ant-form-vertical">
            <div className="ant-row ant-form-item ant-form-item-has-success" style={{rowGap: 0}}>
                <div className="ant-col ant-form-item-label" style={{display:fieldTitle?'':'none'}}>
                    <label htmlFor={`htmlFor_${id}`} className={required?'ant-form-item-required':''}>{fieldTitle}</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                            <Input placeholder={placeholder} id={`input_${id}`} name={fieldName} className="ant-input ant-input-status-success" type="text" value={value} onChange={() => {}}/>
                        </div>
                    </div>
                </div>

                <Text  type="secondary">{ fieldDesc }</Text>
            </div>
        </form>
    )
}

export default InputTitleAnt
