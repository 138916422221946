import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addUserUtmToList,
    fillUserUtmList,
    removeUserUtmFromList,
    setUserUtmItem,
    setUserUtmListCount,
} from "../reducers/UserUtmReducer";

// init api methods
const base_path = backend_api.user_utm;

function* getUserUtmList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUserUtmList(response.data.result))
            yield put(setUserUtmListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUserUtmItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setUserUtmItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createUserUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addUserUtmToList(response.data.result))
            yield put(setUserUtmItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateUserUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(setUserUtmItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreUserUtm(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeUserUtmFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteUserUtm(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeUserUtmFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* userUtmSaga() {
    yield takeEvery('getUserUtmList', getUserUtmList);
    yield takeEvery('getUserUtmItem', getUserUtmItem);
    yield takeEvery('createUserUtm', createUserUtm);
    yield takeEvery('updateUserUtm', updateUserUtm);
    yield takeEvery('restoreUserUtm', restoreUserUtm);
    yield takeEvery('deleteUserUtm', deleteUserUtm);
}

