import {createSlice} from "@reduxjs/toolkit";

const SenlerGroupReducer = createSlice({
    name: 'senlerGroup',
    initialState: {
        list: [],
        item: {},
        count: 0,
    },
    reducers: {
        fillSenlerGroupList(state, action) {
            state.list = action.payload
        },
        setSenlerGroupItem(state, action) {
            state.item = action.payload;
        },
        setSenlerGroupListCount(state, action) {
            state.count = action.payload;
        },
        resetSenlerGroupState(state) {
            state.list = [];
            state.item = {};
            state.count = 0;
        },
    }
})

export default SenlerGroupReducer.reducer
export const {
    fillSenlerGroupList,
    setSenlerGroupItem,
    setSenlerGroupListCount,
    resetSenlerGroupState,
} = SenlerGroupReducer.actions