import React from "react";

export const Hamburger = ({stroke}) => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 48 48" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.94977  8.9498H39.9498" strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.94977 23.9498H39.9498" strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.94977 38.9498H39.9498" strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
