import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import classes from "./BlockList.module.scss";
import {Button} from "antd";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {
    CopyOutlined,
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
} from "@ant-design/icons";

import {
    deepCopyObject,
    deepGet,
    objectLength,
} from "../../../../library/functions";
import {defaultBlockStyle} from "../StyleFields";
import {BlockList} from "./BlockList";
import {styleComposer} from "../StyleComposer";
import {setActive, setBlock} from "../../../../redux/reducers/BlockReducer";

export const BlockGroups = ({sectionId, container, onChange, getNewId, isOn}) => {
    const list = deepGet(container, 'items', []);
    const dispatch = useDispatch();

    const {pb} = useSelector(store => store)
    const active = pb.active;

    const openGroup = (item) => {
        let itemData = {...item, type: 'group'};
        if (!itemData.style || !objectLength(itemData.style)) itemData['style'] = {...defaultBlockStyle};
        if (!itemData.wrap || !objectLength(itemData.wrap)) itemData['wrap'] = {...defaultBlockStyle};

        dispatch(setBlock(itemData));
        dispatch(setActive({...active, sec: sectionId, con: container.id, group: item.id}));
    }

    const onCopy = (index) => {
        let newList = [...list];
        let newItem = deepCopyObject(list[index]);
        newItem.id = getNewId();
        newItem.items = newItem.items.map(item => {
            item.id = getNewId();
            return item;
        });
        newList.splice(index + 1, 0, newItem);
        onChange(newList);
    }

    const onDelete = (index) => {
        let newList = [...list];
        newList.splice(index, 1);
        onChange(newList);
    }

    const onGroupItemsChange = (itemsList, itemId) => {
        const groupIndex = list.findIndex(item => item.id === itemId);
        const newList = [...list];
        const currentItem = newList[groupIndex];

        newList[groupIndex] = {...currentItem, items: itemsList};
        onChange(newList);
    };

    const renderGroupControls = (item, index, provided) => {
        return <Button.Group className={[classes.groupControls].join(' ')}>
            <Button
                className={`system ${classes.groupControlButton}`}
                type="default"
                icon={<DragOutlined/>}
                size="small"
                {...provided.dragHandleProps}
            />

            <Button
                className={`system ${classes.groupControlButton}`}
                type="default"
                icon={<EditOutlined />}
                onClick={() => openGroup(item)}
                size="small"
            />

            <Button
                className={`system ${classes.groupControlButton}`}
                type="default"
                icon={<CopyOutlined/>}
                onClick={() => onCopy(index)}
                size="small"
            />
            <Button
                className={`system ${classes.groupControlButton}`}
                type="default"
                icon={<DeleteOutlined/>}
                onClick={() => onDelete(index)}
                size="small"
            />
        </Button.Group>
    }


    const [divWidths, setDivWidths] = useState({});

    useEffect(() => {
        const calculateWidths = () => {
            const widths = {};
            list.forEach((item) => {
                const divRef = divRefs[item.id];
                if (divRef) {
                    const {width} = divRef.getBoundingClientRect();
                    widths[item.id] = width;
                }
            });

            setDivWidths(widths);
        };

        calculateWidths();

        // Add event listener for window resize if needed
        window.addEventListener('resize', calculateWidths);

        return () => {
            // Remove event listener on component unmount
            window.removeEventListener('resize', calculateWidths);
        };
    }, [isOn, list]);

    const divRefs = {};
    // const DivWithRef = React.forwardRef((props, ref) => (<div ref={ref} {...props} />));

    return list.map((groupItem, groupKey) => (
        <Draggable
            index={groupKey}
            key={groupItem.id}
            draggableId={String(groupItem.id)}
        >
            {(provided, snapshot) => {

                let {classMap, styleMap} = styleComposer(
                    groupItem,

                    {
                        wrap: ['p-group-wrap', classes.group],
                        bg: ['p-group-bg'],
                        item: ['p-group'],
                    },

                    {
                        horizontal: false,
                        wrap: {},
                        bg: {},
                        item: {},
                    }
                );

                if (active.group === groupItem.id) classMap.wrap.push(classes.current);
                else if (snapshot.isDragging) classMap.wrap.push(...[classes.dragging, classes.current]);

                if (divWidths[groupItem.id] < 220) classMap.wrap.push(classes.smallWidth);

                return <div
                    data-id={groupItem.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={classMap.wrap.join(' ')}
                    // style={styles}
                >
                    <div
                        className={classMap.bg.join(' ')}
                        style={styleMap.bg}
                        ref={(el) => (divRefs[groupItem.id] = el)}
                    >
                        <Droppable
                            droppableId={'c' + groupItem.id}
                            direction={styleMap.horizontal ? 'horizontal' : 'vertical'}
                            type="block"
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    // className={classNames.join(' ')}
                                >
                                    <div className={classMap.item.join(' ')} style={styleMap.item}>
                                        <BlockList
                                            container={container}
                                            group={groupItem}
                                            getNewId={getNewId}
                                            sectionId={sectionId}
                                            onChange={(newItems) => onGroupItemsChange(newItems, groupItem.id)}
                                        />
                                        {/*<div className={classes.placeholder}/>*/}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>

                        <div className="float-right">{renderGroupControls(groupItem, groupKey, provided)}</div>
                    </div>
                </div>
            }}
        </Draggable>
    ))
}