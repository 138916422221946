import React, {useEffect} from 'react'
import {routes} from "../../config/config";
import {useParams} from "react-router-dom";


const AlertsSettings = () => {

    // data from URL params
    const params = useParams()
    const project_id = Number(params.project_id)

    // run effect
    useEffect(() => {
        window.location.replace(`${routes.project_list}/edit/${project_id}#alerts`);
        //eslint-disable-next-line
    }, [])
}

export default AlertsSettings