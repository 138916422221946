import {effects} from './effects'
import {conditions} from './conditions'
import {events} from './events'
import {blocks} from "../../containers/Site/Block/Templates/blocks";

export const schemes = {
    'condition': conditions,
    'effect': effects,
    'event': events,
    'block': blocks,
}
