import React from 'react'
import classes from './AddNodeMenu.module.scss'
import {nodeTypesProps} from "../../../library/flowFunctions";
import {useTranslation} from "react-i18next";
import {isMobile480} from "../../../../../library/isMobile";

const AddNodeMenu = ({options, onOptionsChange, createNewGraphNode}) => {
    const {t} = useTranslation();

    const createNodeByType = (name) => {
        hideMenu();
        createNewGraphNode(name, options.x, options.y);
    }
    const hideMenu = () => {
        onOptionsChange({...options, isVisible: false});
    }

    const renderMenuItems = () => {
        let items = [];

        // get all nodeTypes
        let nodeTypes = Object.keys(nodeTypesProps);

        // move message to start
        const message = nodeTypes[2]
        nodeTypes.splice(2, 1);
        nodeTypes = [message, ...nodeTypes];

        // loop types
        for (const nodeType of nodeTypes) {
            items.push(<div key={'creating-menu-' + nodeType}>
                    {nodeType === 'note' ?
                        <div className={classes.creating_menu_line_break}/>
                        : null
                    }
                    <div
                        className={classes.creating_menu_item}
                        onClick={createNodeByType.bind(this, nodeType)}
                    >
                        <div className={`${classes.creating_menu_icon} ${'icon-' + nodeType}`}>
                            {nodeTypesProps[nodeType].icon}
                        </div>
                        <div className={classes.creating_menu_label}><span>
                            {t('graph.flow.creating.menu.' + nodeType)}
                        </span></div>
                    </div>
                </div>
            )
        }
        return items;
    }

    return (
        <div
            onMouseLeave={hideMenu}
            className={`${classes.creating_menu_wrapper} ${options.isVisible && !isMobile480 ? '' : 'hide'} hidden-xs-important`}
            style={{
                left: (options.x - 45) + 'px',
                top: (options.y - 45) + 'px',
            }}
        >
            <div className={classes.creating_menu}>
                {renderMenuItems()}
            </div>
        </div>
    )
}

export default AddNodeMenu