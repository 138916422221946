import {createSlice} from "@reduxjs/toolkit";

const WebhookReducer = createSlice({
    name: 'webhook',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillWebhookList(state, action) {
            state.list = [...action.payload]
        },
        setWebhookItem(state, action) {
            state.item = action.payload;
        },
        addWebhookToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        removeWebhookFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0;  // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchWebhookInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
            // console.log('patchGraphInList state.item', state.item)
        },
        resetWebhookState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default WebhookReducer.reducer
export const {
    fillWebhookList,
    setWebhookItem,
    addWebhookToList,
    removeWebhookFromList,
    patchWebhookInList,
    resetWebhookState,
} = WebhookReducer.actions