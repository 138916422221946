import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {createMarkup, getItemValues, ucFirst} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemData} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";
import {contact_platforms, contact_types} from "../../schemas/frontend/effects";

const UserContactEdit = () => {
    const {t} = useTranslation()
    const section = 'contact'
    const adminSection = 'user'

    // data from URL params
    const params = useParams()
    const user_id = Number(params.user_id)
    const id = Number(params.id)

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, contact, integration} = useSelector(store => store)
    const project_item = project.item
    const item = contact.item
    const integration_items = {
        '0': t(`${adminSection}.${section}.form.bot_id.value.0`),
        ...createObjectFromIntegrations(t, integration.list)
    };

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "id",
            type: "hidden",
        },
        {
            name: 'type',
            type: 'menu',
            required: true,
            placeholder: t('common.placeholder.menu'),
            values: contact_types
        },
        {
            name: "value",
            type: "text",
            required: true,
            placeholder: t('common.placeholder.text'),
        },
        {
            name: "public_name",
            type: "text",
            placeholder: t('common.placeholder.text'),
            desc: (<span dangerouslySetInnerHTML={createMarkup(t(`${adminSection}.${section}.form.public_name.desc`))} />),
        },
        {
            name: "user_id",
            placeholder: t('common.placeholder.text'),
            desc: (<span dangerouslySetInnerHTML={createMarkup(t(`${adminSection}.${section}.form.user_id.desc`))} />),
            type: "text",
        },
        {
            name: "bot_id",
            type: "menu",
            data: 'integrations',
            values: integration_items,
        },
        {
            name: "is_allowed",
            type: "switcher",
        },
        {
            name: "is_free",
            type: "switcher",
        },
    ]

    // init form state
    const [formValues, setFormValues] = useState({
        id: 0,
        type: null,
        value: '',
        public_name: '',
        user_id: user_id,
    })

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id)

        // set field values if correct data received
        if (item.id === id) {
            form.setFieldsValue(formValues);
        }

        if (admin.authorized && project_item.id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id, platforms: contact_platforms}})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    // set fields values to STATE if correct data received
    useEffect(() => {
        if (item && item.id === id) {
            let formValuesNew = getItemValues(item, formFields)
            formValuesNew.bot_id = String(formValuesNew.bot_id)
            setFormValues(formValuesNew)
        }
        //eslint-disable-next-line
    }, [item])

    // set fields values to FORM if correct data received
    useEffect(() => {
        if (formValues.id === id) form.setFieldsValue(formValues)
        //eslint-disable-next-line
    }, [formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate(ucFirst(section), values, formFields, id, project_item, admin, dispatch)
        backToList()
    }
    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}/profile/${user_id}`)
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={'user.contact'} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default UserContactEdit