import React, {useState} from 'react';
import {
    Button,
    Card,
    Cascader,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    PageHeader,
    Radio,
    Row,
    Select,
    Switch,
    Typography,
} from 'antd';
import './Typography.scss'
import {DownloadOutlined, FilterOutlined, PlusOutlined, SearchOutlined,} from "@ant-design/icons";
import Fa from "../../components/Icon/Fa";
import {ResponsiveText} from "../../components/Common/ResponsiveText";
import ListTable from "../../components/List/Table/ListTable";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {l, Lang} from "../../library/locale";
import {AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineLink, AiOutlineSetting} from "react-icons/ai";
import {Collapse} from "react-collapse/lib/Collapse";
import {Banner} from "../../components/Banner/Banner";
import {notice} from "../../library/notice";
import {images} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import Checkbox from "antd/es/checkbox/Checkbox";
import InputTitleAnt from "../../components/Common/InputTitleAnt";

const {Text, Link, Title} = Typography;
const {Header, Footer, Content} = Layout;
const {Meta} = Card;

const recordOpen = (record) => {
    notice.info('Opened record: ' + record.id)
}
const recordEdit = (record) => {
    notice.warning('Edited record: ' + record.id)
}
const recordCopyUrl = (record) => {
    notice.success('URL Copied: ' + record.id)
}
const recordDelete = (record) => {
    notice.error('Deleted record: ' + record.id)
}

const table_menu_items = [
    {
        label: 'Открыть',
        action: (record) => {
            recordOpen(record)
        }
    },
    {
        label: 'Редактировать',
        action: (record) => {
            recordEdit(record)
        }
    },
    {
        label: 'Скопировать ссылку',
        action: (record) => {
            recordCopyUrl(record)
        }
    },
    {
        label: 'Удалить',
        action: (record) => {
            recordDelete(record)
        }
    },
    {
        label: 'Кнопка без эффекта'
    },
]

export const table_columns = [
    {
        dataIndex: 'image',
        title: l('common.form.avatar'), // title translation - main version
        className: 'cursor-pointer avatar',
        width: 70,
        render: (image) => <img src={image} alt={'Avatar'}/>,
        onCell: (record, rowIndex) => {
            return {
                onClick: (ev) => {
                    recordOpen(record)
                },
            };
        },
    },
    {
        dataIndex: 'title',
        title: 'Название проекта', // title translation - bad example!
        className: 'cursor-pointer table-row-title title-link',
        responsive: ['sm'],
        onCell: (record, rowIndex) => {
            return {
                onClick: (ev) => {
                    recordOpen(record)
                },
            };
        }
    },
    {
        dataIndex: 'title_xs',
        title: Lang('common.form.name.label'), // title translation - version 2
        responsive: ['xs'],
        onCell: (record, rowIndex) => {
            return {
                onClick: (ev) => {
                    recordOpen(record)
                },
            };
        }

    },
    {
        key: 'actions',
        title: 'Title',
        responsive: ['sm'],
        width: 105,
        render: (text, record) => (
            <div className="table-row-buttons">
                <Button type="text" onClick={() => {
                    recordCopyUrl(record)
                }}>
                    <AiOutlineLink/>
                </Button>
                <Button type="text" onClick={() => {
                    recordEdit(record)
                }}>
                    <AiOutlineSetting/>
                </Button>
            </div>
        ),
    },
    {
        key: 'menu',
        className: 'menu-column',
        width: 55,
        render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
    },
];

const table_data = [
    {
        key: 0,
        id: 1,
        image: images.universe.character.uki,
        title: 'Бот администратор',
        title_xs: 'Любимый проект Юки',
    },
    {
        key: 1,
        id: 2,
        image: images.universe.character.cash,
        title: 'Бот в беседу. Лучший в мире проект, потому что его делал Кэш + Еще одна некая очень сильно интересная запись. ',
        title_xs: 'Лучший в мире проект, потому что его делал Кэш + Еще одна некая очень сильно интересная запись. ',
    },
]

const table_data2 = [
    {
        key: 1,
        id: 1,
        image: images.universe.character.ai,
        title: 'Автоматическая обработка товаров',
        title_xs: 'Запись 1',
    }, {
        key: 2,
        id: 2,
        image: images.universe.character.uki,
        title: 'Геймификация со скидками',
        title_xs: 'Запись 1',
    },
    {
        key: 3,
        id: 3,
        image: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png',
        title: 'Меню запуска + Профиль игрока',
        title_xs: 'Запись 2',
    },
]




const TypographyContainer = () => {
    // form sizes
    const [componentSize, setComponentSize] = useState('sample');
    const onFormLayoutChange = ({size}) => {
        setComponentSize(size);
    };

    // left menu bar
    const [isMenuOpened, setMenuStatus] = useState('menu')
    const toggleMenu = () => {
        setMenuStatus(!isMenuOpened);
    };

    // filters panel
    const [isFiltersOpened, setFiltersStatus] = useState(false);
    const toggleFiltersStatus = () => {
        setFiltersStatus(!isFiltersOpened);
    };

    // filter card
    const [isFilterOpened, setFilterStatus] = useState(true);
    const toggleFilterStatus = () => {
        setFilterStatus(!isFilterOpened);
    };

    // add to head: :

    // const tg = window.Telegram;
    // return JSON.stringify(tg)

    return (
        <div className="typography-container-wrapper">
            <AppWrapper>
                <Layout className="site-layout">
                    {/*<Banner image="universe"/>*/}
                    <Banner image="tech"/>
                    <Header className="site-layout-background page-container-horizontal-padding has-banner">
                        <PageHeader
                            title={l('project.list.title')}
                            className={'padding-none-horizontal'}
                            extra={[
                                // search
                                <Button key="header-search-button"
                                        type="text"
                                        icon={<SearchOutlined/>}
                                        className="hidden-sm">
                                    {l('page_header.buttons.search.title')}
                                </Button>,
                                // filters
                                <Button
                                    key="header-filters-button"
                                    type="text"
                                    className={isFiltersOpened ? 'hidden-sm active' : 'hidden-sm'}
                                    icon={<FilterOutlined/>}
                                    onClick={toggleFiltersStatus}
                                > <ResponsiveText md={l('page_header.buttons.filter.title')}/></Button>,
                                // primary
                                <Button key="header-primary-button"
                                        type="primary"
                                        icon={<PlusOutlined/>}>
                                    <ResponsiveText
                                        sm={l('page_header.buttons.create.title')}
                                        md={l('project.list.button.create.title')}
                                    />
                                </Button>,
                            ]}
                        />
                        <Collapse isOpened={isFiltersOpened}>
                            <Card type="inner"
                                  title={(
                                      <div className={'filter-panel-header'}
                                           onClick={toggleFilterStatus}>
                                          {isFilterOpened ? (<AiOutlineCaretDown/>) : (
                                              <AiOutlineCaretRight/>)} {l('page_header.filters.section.label')}
                                      </div>
                                  )}>
                                <Collapse isOpened={isFilterOpened}>
                                    <Switch/> {l('page_header.filters.form.show_deleted.label')}
                                </Collapse>
                            </Card>
                            <p>
                                <Button
                                    type="text"
                                    size='large'
                                    className='inverted btn-xlg'
                                    icon={<PlusOutlined/>}
                                >{l('page_header.filters.section.button')}</Button>
                            </p>
                        </Collapse>
                    </Header>
                    <Content className="page-container site-layout-background">

                        <div className="page-section">
                            <ListTable
                                pagination={false}
                                rowSelection={false}
                                showHeader={false}
                                folder={{show: true, text: 'Блок-схемы без папки (общие)', opened: true}}
                                columns={table_columns}
                                dataSource={table_data}
                            />
                        </div>

                        <div className="page-section">
                            <ListTable
                                pagination={false}
                                rowSelection={false}
                                showHeader={false}
                                folder={{show: true, text: 'Интенсив Кира по клубам'}}
                                columns={table_columns}
                                dataSource={table_data2}
                            />
                        </div>
                        <div className="page-section">
                            <ListTable
                                pagination={false}
                                rowSelection={false}
                                showHeader={false}
                                folder={{show: true, text: 'Воронка продажи трипваера с лид-магнитом'}}
                                columns={table_columns}
                                dataSource={table_data}
                            />
                        </div>
                        <Divider plain>Таблица с обычной шапкой</Divider>
                        <ListTable
                            columns={table_columns}
                            dataSource={table_data2}
                        />

                        <Divider plain>Table no header</Divider>
                        <ListTable
                            pagination={false}
                            rowSelection={false}
                            showHeader={false}
                            columns={table_columns}
                            dataSource={table_data}
                        />

                        <Divider plain>Table Empty</Divider>
                        <ListTable
                            pagination={false}
                            rowSelection={false}
                            columns={table_columns}
                            dataSource={[]}
                        />

                        <Divider plain>Form</Divider>
                        <Row>
                            <Col span={16}>
                                <InputTitleAnt fieldTitle="Название поля"
                                               fieldDesc="Веселое и интересное описание для поля"
                                               fieldName="field_name"
                                               required={false}
                                               id={1}
                                               key={1}
                                />
                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        span: 14,
                                    }}
                                    layout="horizontal"
                                    initialValues={{
                                        size: componentSize,
                                    }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Form.Item label="Form Size"
                                               name="size">
                                        <Radio.Group>
                                            <Radio.Button value="small">Small</Radio.Button>
                                            <Radio.Button value="default">Default</Radio.Button>
                                            <Radio.Button value="large">Large</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="Input">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item label="Select">
                                        <Select>
                                            <Select.Option value="demo">Demo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Cascader">
                                        <Cascader
                                            options={[
                                                {
                                                    value: 'zhejiang',
                                                    label: 'Zhejiang',
                                                    items: [
                                                        {
                                                            value: 'hangzhou',
                                                            label: 'Hangzhou',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item label="DatePicker">
                                        <DatePicker/>
                                    </Form.Item>
                                    <Form.Item label="InputNumber">
                                        <InputNumber/>
                                    </Form.Item>
                                    <Form.Item label="Switch"
                                               valuePropName="checked">
                                        <Switch/>
                                    </Form.Item>
                                    <Form.Item label="Checkbox"
                                               valuePropName="checked">
                                        <Checkbox className={`fix-checkbox-align`}>Checkbox</Checkbox>
                                    </Form.Item>
                                    <Form.Item label="Button">
                                        <Button>Button</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                        <Divider plain>Text</Divider>
                        <Row>
                            <Col span={24}>
                                <p className="color-primary">Full width row</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Duis at tellus at urna condimentum
                                    mattis pellentesque. </p>
                                <p>Egestas erat imperdiet sed euismod nisi porta lorem mollis aliquam. A diam
                                    sollicitudin tempor id eu nisl. Auctor neque vitae tempus quam pellentesque nec nam.
                                    Quam id leo in vitae turpis massa sed elementum. In egestas erat imperdiet sed
                                    euismod. Cursus in hac habitasse platea dictumst. Arcu dui vivamus arcu felis. Nisi
                                    vitae suscipit tellus mauris a. </p>
                                <p>Senectus et netus et malesuada fames ac turpis egestas. Pulvinar mattis nunc sed
                                    blandit libero volutpat sed cras. Pharetra pharetra massa massa ultricies mi quis
                                    hendrerit dolor. Id donec ultrices tincidunt arcu. Vulputate odio ut enim blandit.
                                    In vitae turpis massa sed elementum. Ullamcorper velit sed ullamcorper morbi
                                    tincidunt ornare massa eget egestas.</p>
                            </Col>
                        </Row>

                        <Divider plain>Texts and buttons</Divider>
                        <Row>
                            <Col span={12}>
                                <Title>h1. Example Design</Title>
                                <Title level={2}>h2. Example Design</Title>
                                <Title level={3}>h3. Example Design</Title>
                                <Title level={4}>h4. Example Design</Title>
                                <Title level={5}>h5. Example Design</Title>

                                <p><Text>Example Design (default)</Text></p>
                                <p><Text type="secondary">Example Design (secondary)</Text></p>
                                <p><Text type="success">Example Design (success)</Text></p>
                                <p><Text type="warning">Example Design (warning)</Text></p>
                                <p><Text type="danger">Example Design (danger)</Text></p>
                                <p><Text disabled>Example Design (disabled)</Text></p>
                                <p><Text mark>Example Design (mark)</Text></p>
                                <p><Text code>Example Design (code)</Text></p>
                                <p><Text keyboard>Example Design (keyboard)</Text></p>
                                <p><Text underline>Example Design (underline)</Text></p>
                                <p><Text delete>Example Design (delete)</Text></p>
                                <p><Text strong>Example Design (strong)</Text></p>
                                <p><Text italic>Example Design (italic)</Text></p>
                                <p><Link href="/" target="_blank">
                                    Example Design (Link)
                                </Link></p>
                            </Col>
                            <Col span={12}>
                                <Button type="primary"
                                        icon={<DownloadOutlined/>}>Primary</Button>
                                <Button type="primary"
                                        disabled>
                                    Primary(disabled)
                                </Button>
                                <br/>
                                <Button icon={<SearchOutlined/>}>Default</Button>
                                <Button disabled>Default(disabled)</Button>
                                <br/>
                                <Button type="dashed">Dashed</Button>
                                <Button type="dashed"
                                        disabled>
                                    Dashed(disabled)
                                </Button>
                                <br/>
                                <Button type="text"
                                        icon={<SearchOutlined/>}>
                                    Text
                                </Button>
                                <Button type="text"
                                        disabled>
                                    Text(disabled)
                                </Button>
                                <br/>
                                <Divider plain>Divider</Divider>
                                <Button type="link">Link</Button>
                                <Button type="link" disabled>
                                    Link(disabled)
                                </Button>
                                <br/>
                                <Button danger>Danger Default</Button>
                                <Button danger disabled>
                                    Danger Default(disabled)
                                </Button>
                                <br/>
                                <Button danger type="text">
                                    Danger Text
                                </Button>
                                <Button danger type="text" disabled>
                                    Danger Text(disabled)
                                </Button>
                                <br/>
                                <Button type="link" danger>
                                    Danger Link
                                </Button>
                                <Button type="link" danger disabled>
                                    Danger Link(disabled)
                                </Button>
                            </Col>
                        </Row>

                        <Divider plain>Cards</Divider>
                        <Row>
                            <Col span={12}>
                                <Card
                                    hoverable
                                    style={{width: 240}}
                                    cover={<img alt="example"
                                                src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"/>}
                                >
                                    <Meta title="Europe Street beat" description="www.instagram.com"/>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title="Card Title">
                                    <Card.Grid className="grid-style"><Fa icon="cow"/></Card.Grid>
                                    <Card.Grid className="grid-style"><Fa icon="smile"/></Card.Grid>
                                    <Card.Grid className="grid-style"><Fa icon="apple-whole"/></Card.Grid>
                                    <Card.Grid className="grid-style"><Fa icon="cog"/></Card.Grid>
                                    <Card.Grid className="grid-style"><Fa icon="cogs"/></Card.Grid>
                                    <Card.Grid className="grid-style"><Fa icon="plus"/></Card.Grid>
                                    <Card.Grid className="grid-style" hoverable={false}>
                                        <Fa icon="check"/>
                                    </Card.Grid>
                                </Card>
                            </Col>
                        </Row>

                    </Content>
                    <Footer>Footer</Footer>
                </Layout>
            </AppWrapper>
        </div>
    )

}
export default TypographyContainer