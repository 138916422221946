import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import classes from "./Keyboard.module.scss"
import {ItemTypes} from "./Keyboard";
import {DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Button} from "antd";

export const KeyboardEmptyRow = ({index, rowIndex, moveCard, onDelete, isDragging}) => {
    const {t} = useTranslation();

    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.button,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            // get drag (source) item
            const oldIndex = item.index;
            const oldRow = item.rowIndex;

            // get drop (target) item
            const newIndex = index;
            const newRow = rowIndex;

            // Don't replace items with themselves
            if (oldRow === newRow) {
                return
            }

            // Time to actually perform the action
            moveCard(item.id, oldIndex, oldRow, newIndex, newRow)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = newIndex
        },
    })

    const onClick = () => {
        onDelete(rowIndex)
    }

    return (
        <Button
            ref={drop}
            data-handler-id={handlerId}
            type="text"
            className={`inverted full-width ${classes.empty} ${isDragging ? classes.dragging : ''}`}
            icon={<DeleteOutlined/>}
            onClick={onClick}
        > {t('graph.flow.keyboard.row.delete.label')} </Button>
    )
}
