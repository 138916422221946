import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Table, Tooltip, Typography} from 'antd';
import {useDispatch} from "react-redux";
import {dotenv, images, routes} from "../../config/config";
import {l} from "../../library/locale";
import {AiOutlineLink, AiOutlineSetting} from "react-icons/ai";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {copyToClipboard} from "../../library/clipboard";
import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {arrayMoveImmutable} from "array-move";
import {deepGet, getArrayFromObjectsField, momentFromUnix, objectLength} from "../../library/functions";
import {setUserField} from "../../redux/reducers/UserFieldReducer";
import {SorterWrapper} from "../../components/List/Table/SorterWrapper";
import {TableAvatar} from "../../components/List/Table/TableAvatar";

const {Text} = Typography;

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
    <MenuOutlined className="drag-handler"/>
));

export const ProjectListTable = ({admin, list, filters, orderFieldValue, orderParam = 'order'}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordOpen = (id) => navigate(`${routes.project_list}/${id}${routes.local.default}`)
    const recordEdit = (id) => navigate(`${routes.project_list}/edit/${id}`)
    const recordDelete = (id) => dispatch({type: 'deleteProject', admin, id})
    const recordRestore = (id) => {
        dispatch({type: 'restoreProject', admin, data: {id}})
        // let newFilters = filters
        // newFilters.is_deleted = false
        // setFilters(newFilters)
    }

    const columns = [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: l('common.form.avatar'), // Аватар
            dataIndex: 'image',
            className: 'cursor-pointer avatar',
            width: 70,
            render: (image) => <TableAvatar imageUrl={image} icon="link"/>,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            title: (<SorterWrapper>{l('project.form.title.label')}</SorterWrapper>), // Название проекта
            dataIndex: 'title',
            responsive: ['sm'],
            className: 'cursor-pointer table-row-title title-link', // TODO: + ant-table-column-sort
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
            sorter: {
                compare: (a, b) => a.title.localeCompare(b.title)
            },
        },
        {
            title: (<SorterWrapper>{l('common.form.data')}</SorterWrapper>), // Данные
            dataIndex: 'titleXs',
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['xs'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
            sorter: {
                compare: (a, b) => a.titleXs.localeCompare(b.titleXs)
            },
            render: (text, record) => record.status ? <span>{text}</span> : <Text type="danger">{text}</Text>,
        },
        {
            title: (<SorterWrapper>{l('common.form.created')}</SorterWrapper>),  // Создан
            dataIndex: 'createdAt',
            className: 'drag-hide',
            responsive: ['sm'],
            render: (createdAt) => {
                return (
                    <div>
                        {momentFromUnix(createdAt, "D MMM")} {l('common.at')} {momentFromUnix(createdAt,"H:mm")}
                    </div>
                )
            },
            sorter: (a, b) => a.createdAt - b.createdAt
        },
        {
            title: l('common.form.status'), // Статус
            dataIndex: 'status',
            className: 'drag-hide',
            responsive: ['sm'], // TODO add Deleted status
            render: (status) => status ? <span>{l('common.on')}</span> : <Text type="danger">{l('common.off')}</Text>,
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            className: 'drag-hide',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('project.list.table.icon.copy_url')}>
                        <Button
                            type="text"
                            onClick={() => copyToClipboard(routes.base + routes.project_list + '/' + record.id + routes.local.tariff, l('common.result.url_copied'))}>
                            <AiOutlineLink/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('project.list.table.icon.edit')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record.id)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column drag-hide',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuOpen',
            label: l('table.menu.open'),
            action: (record) => recordOpen(record.id)
        },
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuCopyUrl',
            label: l('project.list.table.menu.copy_url'),
            action: (record) => {
                copyToClipboard(routes.base + routes.project_list + '/' + record.id + '/graph', l('common.result.url_copied'))
                // copy(routes.base+routes.project_list+'/'+record.id).then(notice.info(l('common.result.copied.neuter')))
            }
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (orderParam === 'shared') {
        columns[4] = {
            title: l('common.table.header.owner'),  // Автор
            dataIndex: 'admin',
            className: 'drag-hide text-secondary',
            responsive: ['sm'],
        }

        delete table_menu_items[3]
    }

    const data = [];
    for (let i = 0; i < list.length; i++) {
        const project = list[i]
        data.push({
            id: project.id,
            key: project.id + '-projectRow',
            index: project.id + '-sortingRow',
            image: project.photo_url ? project.photo_url : images.avatar.project,
            title: project.title,
            titleXs: project.title,
            createdAt: project.created_at,
            status: project.is_on,
            admin: deepGet(project, 'admin.user.title'),
        });
    }

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) {
            const projectId = parseInt(projectKey)
            recordDelete(projectId)
        }
        showCheckboxButtons(false);
    }
    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) {
            recordRestore(parseInt(projectKey))
        }
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    let rowSelection = false;

    if (objectLength(filters)) {
        // turn on select button
        rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        // remove drag handler
        columns.shift()
    }

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // drag rows
    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            const sortedIds = getArrayFromObjectsField(newData, 'id')

            const newUserFieldData = {
                field_name: 'projects_list',
                value: {...orderFieldValue, [orderParam]: sortedIds}
            }

            // save to store by Reducer
            dispatch(setUserField(newUserFieldData))

            // save to db by Saga
            dispatch({
                type: 'storeUserField',
                admin,
                data: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    ...newUserFieldData
                }
            })
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="dragging table-row-dragging list-default-table"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <div className="list-default-table-wrapper">
            {/*<Button onClick={() => {*/}
            {/*    setOrdering('default')*/}
            {/*}}>Стандартная</Button>*/}
            <Table
                // showHeader={false}
                className="list-default-table"
                bordered={false}
                pagination={false}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                rowKey="index"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                {/*<div className="ant-btn-group">*/}
                    <Button
                        className={filters.is_deleted ? "hide": ""}
                        onClick={deleteSelected}
                        icon={<DeleteOutlined/>}>{l('common.action.delete')}</Button>
                    <Button
                        className={filters.is_deleted ? "": "hide"}
                        onClick={restoreSelected}
                        icon={<DeleteOutlined/>}>{l('common.action.restore')}</Button>
                {/*</div>*/}
            </div>
        </div>
    )
}

