import React from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"

import {Button, Tooltip, Typography} from 'antd';
import {
    AppstoreAddOutlined,
    ExperimentOutlined,
    FormOutlined,
    LinkOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {images, routes} from "../../config/config";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../library/clipboard";
import FoldersList from "../../components/List/Table/FoldersList";
import {momentFromUnix, ucFirst} from "../../library/functions";
import moment from "moment";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const TemplateListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        recordCreate,
    }
) => {
    const Section = ucFirst(section);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // navigate
    // const recordOpen    = (id) => navigate(`${routes.project_list}/${project.id}/${section}/${id}`)
    const recordOpen = (id) => navigate(`${routes.project_list}/${project.id}/${section}/data/${id}`)
    const recordItem = (id) => navigate(`${routes.project_list}/${project.id}/${section}/item/${id}#buy`)
    const recordEdit = (id) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${id}`)
    const recordLogic = (id) => navigate(`${routes.project_list}/${project.id}/${section}/fields/${id}`)
    const recordComponents = (id) => navigate(`${routes.project_list}/${project.id}/${section}/components/${id}`)
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id, project_id: project.id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id});
    const recordRestore = (id) => {
        let params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            title: t('common.form.avatar'), // Аватар
            dataIndex: 'image',
            className: 'cursor-pointer avatar',
            width: 70,
            render: (image) => <TableAvatar imageUrl={image} icon="pen-to-square"/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: t('common.form.title.label'),
            className: 'cursor-pointer table-row-title title-link',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            title: 'Expire',
            dataIndex: 'expire_at',
            className: 'cursor-pointer align-center',
            responsive: ['lg'],
            width: 150,
            render: (expire_at, record) => {
                const now = moment();
                let diffInSeconds = 1;
                let desc = t('template.list.expire.unset');

                if (expire_at) {
                    const expire_moment = momentFromUnix(expire_at);
                    diffInSeconds = expire_moment.diff(now, 'seconds');
                    desc = expire_moment.format('DD.MM.YYYY HH:mm');
                    desc = t('template.list.expire.desc') + ' ' + desc + '. ' + t('template.list.expire.open')
                }

                return <Tooltip title={desc}>{expire_at ?
                    <Text type={diffInSeconds > 0 ? 'default' : 'danger'}>
                        {t('common.before').toLowerCase()} {momentFromUnix(expire_at, 'DD MMMM')}
                    </Text> : <Text type="secondary"> ... </Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                if (record.expire_at) return {onClick: (ev) => recordItem(record.id)};
                else return {onClick: (ev) => recordOpen(record.id)};
            },
        },
        {
            title: t('common.form.status'),
            dataIndex: 'status',
            className: 'cursor-pointer',
            responsive: ['sm'],
            width: 80,
            render: (status) => {
                return <Tooltip title={t('common.action.click_for_toggle')}>{status ?
                    <Text>{t('common.on')}</Text> :
                    <Text type="danger">{t('common.off')}</Text>}
                </Tooltip>
            },
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordChangeStatus(record.id, !record.status)
                };
            },
        },
        {
            title: t('common.form.actions'),  // Действия
            key: 'actions',
            className: 'drag-hide',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons float-right">
                    {/*<Tooltip title={t('table.icon.duplicate')}>*/}
                    {/*    <Button*/}
                    {/*        size="large"*/}
                    {/*        type="text"*/}
                    {/*        onClick={() => recordCopy(record.id)}>*/}
                    {/*        <AiOutlineCopy/>*/}
                    {/*    </Button>*/}
                    {/*</Tooltip>*/}
                    {record.is_open ? <Tooltip title={t('table.icon.copy_public_url')}>
                        <Button
                            type="text"
                            size="large"
                            icon={<LinkOutlined/>}
                            onClick={() => copyToClipboard(record.url, t('common.result.url_copied'))}
                        />
                    </Tooltip> : null}

                    {record.is_installed ? <Tooltip title={t('template.list.icon.data')}>
                        <Button
                            type="text"
                            size="large"
                            icon={<FormOutlined/>}
                            onClick={() => recordOpen(record.id)}
                        />
                    </Tooltip> : <>
                        <Tooltip title={t('template.list.icon.fields')}>
                            <Button
                                type="text"
                                size="large"
                                icon={<ExperimentOutlined/>}
                                onClick={() => recordLogic(record.id)}
                            />
                        </Tooltip>
                        <Tooltip title={t('template.list.icon.components')}>
                            <Button
                                type="text"
                                size="large"
                                icon={<AppstoreAddOutlined/>}
                                onClick={() => recordComponents(record.id)}
                            />
                        </Tooltip>
                    </>}

                    <Tooltip title={t('table.icon.settings')}>
                        <Button
                            type="text"
                            size="large"
                            icon={<SettingOutlined />}
                            onClick={() => recordEdit(record.id)}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column drag-hide',
            width: 55,
            render: (text, record) => <ListTableRowMenu
                items={record.is_installed ? table_menu_installed : table_menu_items}
                record={record}
            />,
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuOpen',
            label: t('template.list.menu.fill'),
            action: (record) => recordOpen(record.id)
        },
        {
            key: 'tableMenuFields',
            label: t('template.list.menu.fields'),
            action: (record) => recordLogic(record.id)
        },
        {
            key: 'tableMenuComp',
            label: t('template.list.menu.components'),
            action: (record) => recordComponents(record.id)
        },
        {
            key: 'tableMenuEdit',
            label: t('table.menu.settings'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuItem',
            label: t('table.menu.open'),
            action: (record) => recordItem(record.id)
        },
        {
            key: 'tableMenuCopyUrl',
            label: t('template.list.menu.copy_url'),
            action: (record) => {
                copyToClipboard(record.url, t('common.result.url_copied'))
            }
        },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuCopy',
            label: t('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    const table_menu_installed = [
        {
            key: 'tableMenuItem',
            label: t('template.list.menu.open_desc'),
            action: (record) => recordItem(record.id),
            render: (record) => {
                if (record.expire_at > 0) return t('template.list.menu.sell')
                else return t('template.list.menu.open_desc')
            }
        },
        {
            key: 'tableMenuOpen',
            label: t('template.list.menu.fill'),
            action: (record) => recordOpen(record.id)
        },
        {
            key: 'tableMenuEdit',
            label: t('table.menu.settings'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuStatus',
            label: t('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id, record.name)
        })
    }

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]

        data.push({
            id: record.id,
            key: record.id + '-listRow',
            image: record.photo_url ? record.photo_url : images.universe.sections.template,
            title: record.title,
            status: record.is_on,
            folder: record.folder_id,
            deleted: record.deleted_at > 0,
            is_open: record.is_open,
            is_installed: !!record.parent_id,
            expire_at: record.expire_at,
            url: routes.base + '/' + section + '/' + record.alias
        });
    }

    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

