export const coreGame = {
    'achievement/list': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/game/achievement/list',
                            spec: {
                                show_title: true,
                                by_game: false,
                                cl: {
                                    item: [],
                                    title: [
                                        "text-h3",
                                        "mt-5"
                                    ],
                                    photo: [
                                        "inline-block",
                                        "rounded-xl",
                                        "w-32"
                                    ],
                                },
                            },
                            style: {
                                padding: {
                                    top: "4",
                                    left: "4",
                                    right: "4",
                                    bottom: "4"
                                },
                                flex: {
                                    gap: "8",
                                    direction: "row",
                                    justify: "evenly",
                                    wrap: true
                                },
                                grid: {
                                    "default": "4"
                                },
                                text: {
                                    align: "center"
                                },
                            }
                        }
                    ],
                    style: {}
                }
            ],
            style: {
                width: {
                    container: "md"
                },
                padding: {
                    top: "6",
                    left: "6",
                    right: "6",
                    bottom: "6"
                }
            }
        }]
    },
    'wheel': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: 'core/game/wheel',
                            spec: {
                                title: '{locale:container.example.wheel.title}',
                                photo_url: '{locale:container.example.wheel.photo_url}',
                                width: '300',
                                limit: '',
                                rate: '',
                                limit_selector: '', // '.wheel-limit',
                                stopped: {
                                    event: true,
                                    remember: true,
                                    show_photo: true,
                                    show_title: true
                                },
                                submit: {
                                    enable: true,
                                    label: '{locale:container.example.wheel.submit.label}',
                                    color: 'primary',
                                    variant: 'contained',
                                    desc: '<p>{locale:container.example.wheel.submit.desc}</p>',
                                    reached: '<p>{locale:container.example.wheel.submit.reached}</p>',
                                },
                                reset: {
                                    enable: true,
                                    label: '{locale:container.example.wheel.reset.label}',
                                    color: 'default',
                                    variant: 'contained',
                                },
                                stop: {
                                    enable: true,
                                    label: '{locale:container.example.wheel.stop.label}',
                                    color: 'default',
                                    variant: 'contained',
                                    desc: '<p>{locale:container.example.wheel.stop.desc}</p>',
                                    fail: '<p>{locale:container.example.wheel.stop.fail}</p>',
                                },
                                sectors: {
                                    nextKey: 3,
                                    tree: [
                                        {
                                            title: '{locale:container.example.wheel.sector.0.title}',
                                            desc: '<p>{locale:container.example.wheel.sector.0.desc}</p>',
                                            value: '1',
                                            chance: '50',
                                            key: 1,
                                        },
                                        {
                                            title: '{locale:container.example.wheel.sector.1.title}',
                                            desc: '<p>{locale:container.example.wheel.sector.1.desc}</p>',
                                            value: '2',
                                            chance: '50',
                                            key: 2,
                                        },
                                    ]
                                },
                                classes: {
                                    show: false,
                                    photo: ['text-white', 'flex', 'flex-col', 'justify-center', 'items-center', 'xs:items-end'],
                                    content: ['p-4', 'flex', 'flex-col', 'justify-center', 'items-center', 'xs:items-start', 'prose', 'dark:prose-invert', 'lg:prose-lg'],
                                }
                            },
                            style: {
                                flex: {
                                    gap: "8",
                                    direction: "grid",
                                    justify: "evenly",
                                    wrap: true,
                                },
                                grid: {
                                    default: "2",
                                },
                                padding: {
                                    top: "4",
                                    left: "4",
                                    right: "4",
                                    bottom: "4"
                                },
                                // text: {
                                //     align: "center"
                                // },
                            }
                        }
                    ],
                    style: {}
                }
            ],
            style: {
                width: {
                    container: "md"
                },
                padding: {
                    top: "6",
                    left: "6",
                    right: "6",
                    bottom: "6"
                }
            }
        }]
    },
}