import React from 'react'
import SectionsEvents from "./ContentEvents/SectionsEvents";
import CatalogHeader from "../../Header/CatalogHeader";

const Events = ({ children, isBlock = false }) => {
    return (
        <>
            { children }
            <CatalogHeader type="event"/>
            <SectionsEvents isBlock={isBlock}/>
        </>
    )
}

export default Events