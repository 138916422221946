import {createSlice} from "@reduxjs/toolkit";

const SmGroupReducer = createSlice({
    name: 'smGroup',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillSmGroupList(state, action) {
            state.list = action.payload
        },
        setSmGroupItem(state, action) {
            state.item = action.payload;
        },
        resetSmGroupState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default SmGroupReducer.reducer
export const {
    fillSmGroupList,
    setSmGroupItem,
    resetSmGroupState,
} = SmGroupReducer.actions