import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addAnswerToList,
    fillAnswerList,
    patchAnswerInList,
    removeAnswerFromList,
    setAnswerItem,
    clearAnswerItem,
} from "../reducers/AnswerReducer";

// init api methods
const base_path = backend_api.answer;

function* getAnswerList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) yield put(fillAnswerList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getAnswerItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');

        if (response.data.ok) yield put(setAnswerItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createAnswer(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addAnswerToList(response.data.result))
            yield put(clearAnswerItem()) // hide answer modal
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateAnswer(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchAnswerInList(response.data.result))
            yield put(clearAnswerItem()) // hide answer modal
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteAnswer(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeAnswerFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* answerSaga() {
    yield takeEvery('getAnswerList', getAnswerList);
    yield takeEvery('getAnswerItem', getAnswerItem);
    yield takeEvery('createAnswer', createAnswer);
    yield takeEvery('updateAnswer', updateAnswer);
    yield takeEvery('deleteAnswer', deleteAnswer);
}

