import React from 'react';

export const NoForm = ({children, className='', layout='vertical'}) => {
    let classes = "ant-form ant-form-"+layout;
    if (className) classes += ' '+className;

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

