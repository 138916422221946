import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {TagListTable} from "./TagListTable";
import {createObjectFromObjectsArray, objectLength, ucFirst} from "../../../library/functions";
import {ListFolderManager} from "../../../components/List/Folder/ListFolderManager";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const TagList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'tag'
    const adminSection = section
    const categorySection = 'site'

    // data from GET params
    const [searchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store receive
    const {admin, project, tag, site} = useSelector(store => store)
    const categoryList = site.list || []
    const categories = createObjectFromObjectsArray(categoryList);

    // states init
    const [filters, setFilters] = useState({})

    // init universal vars
    const project_item = project.item
    const list = tag.list || []
    const sorter = tag.sorter
    // const rowsTotal = tag.count || 0
    // const currentPage = tag.page || 1
    // const pageSize = tag.pageSize || 10
    const listValues = createObjectFromObjectsArray(list)

    useLayoutEffect(() => {
        if (site_id) setFilters({site_id: site_id, ...filters})
        //eslint-disable-next-line
    }, [site_id])

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                ordering: ordering,
                // page_number: currentPage,
                // page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordCreate = (folder_id = 0) => {
        let url = `${routes.project_list}/${project_item.id}/${section}/edit/0?folder=${folder_id}`
        if (filters.site_id) url += `&site_id=${filters.site_id}`
        navigate(url)
    }

    const filterFields = [
        [
            {
                name: 'site_id',
                type: 'menu',
                localized: false,
                values: categories,
                placeholder: t('common.placeholder.menu')
            },
            {
                name: 'parent_id',
                type: 'menu',
                localized: false,
                values: listValues,
                placeholder: t('common.placeholder.menu')
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off']
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range',
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    title={t(section + '.list.title') + (filters.site_id ? `: ${categories[filters.site_id] || '(?)'}` : '')}
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordCreate}
                />
                <Content className="page-container site-layout-background">
                    <TagListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        adminSection={adminSection}
                        list={list}
                        categoriesMap={categories}
                        listMap={listValues}
                        filters={filters}
                        recordCreate={recordCreate}
                    />
                    <ListFolderManager section={section} project_item={project_item} list={list}/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default TagList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link