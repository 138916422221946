import React, {useRef, useState} from 'react'
import {Button, Table, Tooltip} from 'antd';
import classes from './ListTable.module.scss'
import {AiOutlineDown, AiOutlinePlus, AiOutlineSetting, AiOutlineUp} from "react-icons/ai";
import {l} from "../../../library/locale";

const ListTable = (
    {
        className,
        dataSource,
        columns,
        rowSelection,
        pagination = false,
        showHeader = true,
        onTableChange = null,
        folder = {
            id: 0,
            text: '',
            deleted_at: false,
            show: false,
            opened: true,
            onOpen: false,
            folderEdit: false,
            recordCreate: false,
            createRecordComponent: false,
            type: 'folder',
        },
        rowsTotal = 0
    }
) => {
    // init table ref
    const tableRef = useRef(null);

    const folderType = folder.type ? folder.type : 'folder';
    let componentClasses = "list-default-table";
    if (className) componentClasses += ' ' + className;

    // const onSelectChangeCommon = selectedRowKeys => {
    //     setSelectedRowKeys(selectedRowKeys);
    //     onSelectChange(selectedRowKeys)
    // };
    //
    // const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // const rowSelectionConfig = {
    //     selectedRowKeys,
    //     onChange: onSelectChangeCommon,
    // };

    const [isFolderOpened, setTableWrapperStatus] = useState(folder.opened);
    const toggleableWrapperStatus = () => {
        setTableWrapperStatus(!isFolderOpened);
        if (folder.onOpen) {
            folder.onOpen(folder.id, !isFolderOpened)
        }
    };

    // console.log('list table folder', folder);
    // console.log('folder.createRecordComponent', folder)

    const folderControls = () => {
        return (<div className="table-title-wrapper cursor-pointer">
            <div className="title-controls table-row-buttons float-right">
                {folder.id && typeof (folder.id) === 'number' ? <Tooltip title={l(folderType + '.settings')}>
                    <Button type="text" onClick={() => {
                        folder.folderEdit(folder.id, folder.text)
                        // notice.warning('Category setting opened')
                    }}><AiOutlineSetting/> </Button>
                </Tooltip> : null}

                {folder.createRecordComponent ?? (folder.recordCreate ? <Tooltip title={l(folderType + '.add_item')} placement="topRight">
                    <Button type="text" onClick={() => {
                        folder.recordCreate(folder.id)
                        // notice.info('New row added')
                    }}> <AiOutlinePlus/> </Button>
                </Tooltip> : null)}

                <Tooltip
                    title={isFolderOpened ? l(folderType + '.collapse') : l(folderType + '.expand')}
                    placement="topRight"
                >
                    <Button type="text"
                            onClick={toggleableWrapperStatus}
                            className="btn-collapse">
                        {isFolderOpened ? (<AiOutlineUp/>) : (<AiOutlineDown/>)}
                    </Button>
                </Tooltip>
            </div>
            <h3
                onClick={toggleableWrapperStatus}
                className={folder.deleted_at > 0 ? 'color-danger' : ''}
            >{folder.text}</h3>
        </div>)
    }

    return (
        <div className={classes.wrapper_table}>
            <div className={isFolderOpened ? 'show-table' : 'hide-table'} ref={tableRef}>
                <Table
                    className={componentClasses}
                    title={folder.show ? folderControls : false}
                    rowSelection={rowSelection}
                    showHeader={showHeader}
                    pagination={pagination}
                    dataSource={dataSource}
                    onChange={onTableChange}
                    columns={columns}
                    bordered={false}
                    // scroll={{scrollToFirstRowOnChange: true}}
                />
            </div>
        </div>
    )
}

export default ListTable