import {message} from 'antd';

const notice_duration = 5

export const notice = {
    info: (text, duration = notice_duration) => {
        message.info(text, duration).then()
    },
    success: (text, duration = notice_duration) => {
        message.success(text, duration).then()
    },
    error: (text, duration = notice_duration) => {
        message.error(text, duration).then()
    },
    warning: (text, duration = notice_duration) => {
        message.warning(text, duration).then()
    },
    loading: (text, duration = notice_duration) => {
        message.loading(text, duration).then()
    },
}