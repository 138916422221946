import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {openRequest, request} from "../../library/api";
import {
    addUserToList,
    fillUserList,
    patchUserInList,
    removeUserFromList,
    setUserItem,
    setUserListCount,
    setUserStoreParam,
} from "../reducers/UserReducer";
import {fillContactList} from "../reducers/ContactReducer";
import {setFieldItem} from "../reducers/FieldReducer";

// init api methods
const base_path = backend_api.user;

function* getUserList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUserList(response.data.result))
            yield put(setUserListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUserRating(arg) {
    try {
        const response = yield openRequest(backend_api.user_field + '/rating', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillUserList(response.data.result))
            yield put(setFieldItem(response.data.params))
            yield put(setUserListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getUserItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get', arg.filters);
        if (response.data.ok) {
            yield put(setUserItem(response.data.result))
            yield put(fillContactList(response.data.result.contacts))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addUserToList(response.data.result))
            yield put(setUserItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* importUsers(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/import', 'post', arg.data);

        if (response.data.ok) {
            yield put(setUserStoreParam({
                imported: response.data.result
            }))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchUserInList(response.data.result))
            yield put(setUserItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyUser(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setUserItem(response.data.result))
            yield put(addUserToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreUser(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) {
            yield put(setUserItem(response.data.result))
            yield put(removeUserFromList(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteUser(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) {
            yield put(removeUserFromList(response.data.result))
            yield put(setUserItem(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* userSaga() {
    yield takeEvery('getUserList', getUserList);
    yield takeEvery('getUserItem', getUserItem);
    yield takeEvery('createUser', createUser);
    yield takeEvery('copyUser', copyUser);
    yield takeEvery('updateUser', updateUser);
    yield takeEvery('restoreUser', restoreUser);
    yield takeEvery('deleteUser', deleteUser);
    yield takeEvery('importUsers', importUsers);
    yield takeEvery('getUserRating', getUserRating);
}

