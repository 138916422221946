const screens = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
]

const gapValues = {
    '0': '0px',
    '2': '8px',
    '4': '16px',
    '6': '24px',
    '8': '32px',
    '10': '40px',
};

const colWidthValues = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
};

const htmlBlockTypes = {
    'header': '<header>',
    'footer': '<footer>',
    'section': '<section>',
    'article': '<article>',
    'aside': '<aside>',
    'main': '<main>',
    'nav': '<nav>',
    // 'form': '<form>',
    // 'search': '<search>',
};

export const defaultRowValues = {
    id: null,
    tag: null,
    gap: null,
    classes: [],
    container: null,
}

export const rowFields = {
    fields: [
        [{
            name: 'id',
            type: 'text',
        }],
        [{
            name: 'tag',
            type: 'menu',
            placeholder: '<div>',
            localized: false,
            values: htmlBlockTypes,
        }],
        [{
            name: 'classes',
            type: 'tags',
            features: ['adding'],
        }],
        [{
            name: 'wrapClasses',
            type: 'tags',
            features: ['adding'],
        }],
        [{
            name: 'block',
            type: 'heading',
        }],
        [{
            name: 'container',
            type: 'menu',
            localized: true,
            values: screens,
        }],
        [{
            name: 'gap',
            type: 'menu',
            placeholder: '16px',
            localized: false,
            values: gapValues,
        }],
    ]
};

export const defaultColValues = {
    id: null,
    tag: null,
    classes: [],
    width: {
        xs: null,
        sm: null,
        md: null,
        lg: null,
    },
}

export const colFields = {
    fields: [
        [{
            name: 'id',
            type: 'text',
        }],
        [{
            name: 'tag',
            type: 'menu',
            placeholder: '<div>',
            localized: false,
            values: htmlBlockTypes,
        }],
        [{
            name: 'classes',
            type: 'tags',
            features: ['adding'],
        }],
        [{
            name: 'width.title',
            type: 'heading',
        }],
        [
            {
                name: 'width.default',
                type: 'menu',
                placeholder: '12',
                localized: false,
                values: colWidthValues,
            },
            {
                name: 'width.sm',
                type: 'menu',
                localized: false,
                values: colWidthValues,
            },
            {
                name: 'width.md',
                type: 'menu',
                localized: false,
                values: colWidthValues,
            },
            {
                name: 'width.lg',
                type: 'menu',
                localized: false,
                values: colWidthValues,
            },
        ],
    ]
};

export const defaultSecValues = {
    id: null,
    tag: null,
    classes: [],
    editable: false,
    main: false,
}

export const secFields = {
    fields: [
        [{
            name: 'editable',
            type: 'switcher',
        }],
        [{
            name: 'id',
            type: 'text',
        }],
        [{
            name: 'tag',
            type: 'menu',
            placeholder: '<div>',
            localized: false,
            values: htmlBlockTypes,
        }],
        [{
            name: 'classes',
            type: 'tags',
            features: ['adding'],
        }],
    ]
};