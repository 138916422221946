import React, {memo, useContext, useEffect, useState} from 'react';
import nodeClasses from "./Node.module.scss";
import classes from "./Note.module.scss";
import {useTranslation} from "react-i18next";
import NodeButtonsWrapper from "../Blocks/NodeButtons/NodeButtonsWrapper";
import NoteControls from "../Blocks/NodeButtons/NoteControls";

export default memo(({id = 0, type = '', data = {}, dragging = true}) => {
    const {t} = useTranslation();

    if (!id) {
        console.error('[ID] is required field. Received: ', id);
        return null;
    }

    const nodeText = ('label' in data && data.label) ? data.label : t('graph.flow.node.note.default_text');

    return (
        <>
            <NodeButtonsWrapper style={{left: 0}}>
                <NoteControls nodeId={id} currentPreset={data.preset} fontSize={data.fontSize}/>
            </NodeButtonsWrapper>

            <div className={nodeClasses.graph_node}>
                <div className={`${nodeClasses.node_text} ${classes['note_preset_' + data.preset]}`} style={{fontSize: data.fontSize}}>
                    {nodeText}
                </div>
            </div>
            <div className={nodeClasses.node_selected}/>
        </>
    );
});
