import React, {useEffect} from 'react'
import {routes, dotenv} from "../../config/config";
import {useNavigate} from "react-router-dom";
import Preloader from "./Preloader";
import {useDispatch, useSelector} from "react-redux";

const AfterAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {admin} = useSelector(store => store)

    useEffect(() => {
        if (admin.authorized && admin.correct && !admin.logging) {
            navigate(dotenv.main_page)
            // dotenv.login_redirect
        }
        //eslint-disable-next-line
    }, [admin])

    // useEffect(() => {
    //     if (admin.authorized && admin.correct && !admin.logging) {
    //         dispatch({
    //             type: 'getUserField',
    //             admin,
    //             filters: {
    //                 project_id: dotenv.main_project,
    //                 user_id: admin.user.id,
    //                 field_name: 'boarding',
    //             }
    //         })
    //     }
    //     //eslint-disable-next-line
    // }, [admin])
    //
    // useEffect(() => {
    //     // console.log('AuthForm')
    //     if (admin.authorized && admin.token && admin.correct && !admin.logging && userField.boarding) {
    //         // console.log('userField.boarding', userField.boarding)
    //         let redirectUrl = dotenv.main_page
    //         if (!userField.boarding.value) redirectUrl += '?welcome=true'
    //         navigate(redirectUrl)
    //     }
    //     //eslint-disable-next-line
    // }, [admin, userField.boarding])

    return <Preloader/>
}

export default AfterAuth