import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {fillSenlerGroupList, setSenlerGroupItem, setSenlerGroupListCount} from "../reducers/SenlerGroupReducer";
import {fillSenlerBotList, setSenlerBotListCount} from "../reducers/SenlerBotReducer";
import {toNum} from "../../library/functions";

// init api methods
const base_path = backend_api.platform;

function* getSenlerGroupList(arg) {
    try {
        if (!toNum(arg.filters.integration_id)) return null;

        const response = yield request(arg.admin.token, base_path + '/senler/group/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillSenlerGroupList(response.data.result))
            yield put(setSenlerGroupListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSenlerGroupItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/senler/group/list', 'get', arg.filters);
        if (response.data.ok && response.data.result.length) yield put(setSenlerGroupItem(response.data.result[0]))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createSenlerGroup(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/senler/group/add', 'post', arg.data);
        if (response.data.ok) {
            yield put(fillSenlerGroupList(response.data.result))
            yield put(setSenlerGroupListCount(response.data.count))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSenlerBotList(arg) {
    try {
        if (!toNum(arg.filters.integration_id)) return null;
        const response = yield request(arg.admin.token, base_path + '/senler/bot/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillSenlerBotList(response.data.result))
            yield put(setSenlerBotListCount(response.data.count))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* senlerSaga() {
    yield takeEvery('getSenlerGroupList', getSenlerGroupList);
    yield takeEvery('getSenlerGroupItem', getSenlerGroupItem);
    yield takeEvery('createSenlerGroup', createSenlerGroup);
    yield takeEvery('getSenlerBotList', getSenlerBotList);
}

