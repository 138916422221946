import React from 'react'
import Fa from "../../../../../../components/Icon/Fa";

export const BlockIcon = (spec) => {
    if (!spec) return null;

    return <Fa
        icon={spec.icon}
        set={spec.set ?? 'regular'}
    />
}