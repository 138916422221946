export const second_graph = {
    "nextLocalId": 4,
    "nextStepId": 2,
    "nodes": [
      {
        "type": "event",
        "start_mode": "reopen",
        "ui": {
          "position": {
            "x": 164,
            "y": 164
          },
          "step": 1
        },
        "localId": 1,
        "ports": [
          {
            "name": "defaultInput",
            "localId": 2,
            "type": "input",
            "group": "waitAll"
          },
          {
            "name": "defaultOutput",
            "localId": 3,
            "type": "output"
          }
        ],
        "text": ""
      }
    ],
    "edges": [],
    "ui": {
      "position": {
        "x": 0,
        "y": 0,
        "zoom": 1
      },
      "chosenNodeId": 1
    }
  }
