import React from 'react'
import classes from "./StoreCard.module.scss";
import {NavigateLink} from "../../Navigate/Navigate";
import {EyeOutlined} from "@ant-design/icons";
import {Card, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {deepGet, getLabelForNumeric} from "../../../library/functions";

const {Title} = Typography;


export const StoreCard = (
    {
        section,
        item = {},
        url = null,
        actions = null,
        price = null,
        params = null,
        active = false
    }
) => {
    const {t} = useTranslation();
    // let classNames = `site-layout-background ${classes.card}`;
    // if (className) classNames += ' ' + className

    const cover = <div
        className={classes.photo_wrapper}
        style={item.photo_url ? {backgroundImage: 'url(' + item.photo_url + ')'} : null}
    >
        <EyeOutlined className={classes.open_icon}/>
        <div className={classes.photo_overlay}/>
        {/*<img alt="Template photo" src={}/>*/}
    </div>

    let priceText = '';
    if (price && params && deepGet(params, 'subscription.is_on')) {
        const period = deepGet(params, 'subscription.period', 0);
        priceText = t(section + '.store.list.card.price.subscription', {
            postProcess: 'sprintf',
            sprintf: [
                price,
                period,
                getLabelForNumeric(period, [
                    t('template.item.days.0'),
                    t('template.item.days.1'),
                    t('template.item.days.2')
                ])
            ]
        });
    } else if (price) {
        priceText = t(section + '.store.list.card.price.amount', {
            postProcess: 'sprintf',
            sprintf: [price]
        });
    }

    return (
        <Card
            key={section + '-' + item.id}
            section={section}
            item={item}
            className={`template-card ${classes.card} ${active ? classes.active : ''}`}
            actions={actions}
            cover={url ? <NavigateLink>{cover}</NavigateLink> : cover}
        >

            <Title level={3} className={classes.title}>
                <span>{item.title}</span>
            </Title>

            {(item.type && item.type !== '0') ? <div className={`${classes.type} float-right`}>
                {t(section + '.form.type.value.' + item.type)}
            </div> : null}

            {price === null ? null : <div className={classes.price}>
                {price ? priceText : t(section + '.store.list.card.price.free')
                }
            </div>}

        </Card>
    )
}


