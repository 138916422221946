import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import {useReactFlow, useStoreApi, useStoreState, useViewport} from 'react-flow-renderer';
import classes from './ButtonsZoom.module.scss';
import btnClasses from '../ButtonsGroup/ButtonsGroup.module.scss';
import {Tooltip} from "antd";
import {
    DragOutlined,
    FullscreenExitOutlined,
    LockOutlined,
    MinusOutlined,
    PlusOutlined, UnlockOutlined,
    ZoomInOutlined
} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useHotkeys} from "react-hotkeys-hook";

const ButtonsZoom = memo(({nodes, setDraggable, blockDraggable}) => {
    const {t} = useTranslation();
    const viewport = useViewport();
    const {zoomIn, zoomOut, setViewport} = useReactFlow();

    const zoom = viewport.zoom;
    const [isOpen, setIsOpen] = useState(false);

    const zoomMinus = () => {
        zoomOut({duration: 100});
    }
    const zoomPlus = () => {
        zoomIn({duration: 100});
    }
    const zoomReset = useCallback((nodes) => {
        let x = 0, y = 0;
        if (nodes.length) {
            x = 1000000000;
            y = 1000000000;

            nodes.forEach(node => {
                if (node.position.x < x) x = node.position.x;
                if (node.position.y < y) y = node.position.y;
            })
        }
        setViewport({x: -x + 160, y: -y + 160, zoom: 1}, {duration: 200})
    }, [setViewport]);

    useHotkeys('*', e => {
        switch (e.key) {
            case 'Home':
                zoomReset(nodes)
                break;
            case '-':
                zoomMinus()
                break;
            case '+':
                zoomPlus()
                break;
            case '=':
                zoomPlus()
                break;
            default:
                break;
        }
    }, [nodes]);

    const onOpen = (e) => setIsOpen(!isOpen);
    const onContextMenu = (e) => e.preventDefault();

    return (
        <div className={classes.buttons_zoom_wrapper}>
            <div className={`${classes.buttons_zoom} ${btnClasses.group_wrapper}`}>
                <Tooltip title={t('graph.flow.zoom.title')} placement="left" trigger='hover'>
                    <button
                        className={`${btnClasses.button} ${btnClasses.control} ${isOpen ? btnClasses.active : ''}`}
                        onContextMenu={onContextMenu}
                        onClick={onOpen}
                    ><ZoomInOutlined/></button>
                </Tooltip>
                {isOpen ? <>
                    <Tooltip title={blockDraggable ? t('graph.flow.zoom.lock') : t('graph.flow.zoom.unlock')}
                             placement="left" trigger='hover'>
                        <button
                            className={`${btnClasses.button} ${(!blockDraggable) ? btnClasses.active : ''}`}
                            onClick={setDraggable} onContextMenu={onContextMenu}
                        >
                            {blockDraggable ? <UnlockOutlined/> : <LockOutlined/>}
                        </button>
                    </Tooltip>
                    <Tooltip title={t('graph.flow.zoom.out')} placement="left" trigger='hover'>
                        <button
                            className={`${btnClasses.button} ${(zoom < 1) ? btnClasses.active : ''}`}
                            onClick={zoomMinus} onContextMenu={onContextMenu}
                        >
                            <MinusOutlined/>
                        </button>
                    </Tooltip>
                    <Tooltip title={t('graph.flow.zoom.in')} placement="left" trigger='hover'>
                        <button
                            className={`${btnClasses.button} ${(zoom > 1) ? btnClasses.active : ''}`}
                            onClick={zoomPlus} onContextMenu={onContextMenu}
                        >
                            <PlusOutlined/>
                        </button>
                    </Tooltip>
                    <Tooltip title={t('graph.flow.zoom.reset')} placement="left" trigger='hover'>
                        <button
                            className={btnClasses.button}
                            onClick={zoomReset.bind(this, nodes)} onContextMenu={onContextMenu}
                        >
                            <FullscreenExitOutlined/>
                        </button>
                    </Tooltip>
                </> : null}
            </div>
        </div>
    )
})

export default ButtonsZoom


