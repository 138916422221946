import React, {useEffect, useState} from 'react'
import {Form, Input, Layout, Select} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {l, label} from "../../library/locale";
import {dotenv, routes} from "../../config/config";
import {getItemValues, deepGet, objectLength, parseBool, setFormField} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormTitle} from "../../components/Form/FormTitle";
import {AvatarUpload} from "../../components/Form/Field/AvatarUpload";
import {LabelSwitch} from "../../components/Form/Field/LabelSwitch";
import {setUserField} from "../../redux/reducers/UserFieldReducer";
import TinyMCE from "../../components/Form/Editor/TinyMCE";

const {Option} = Select;
const {Content} = Layout;

const AdminSettings = () => {
    const section = 'admin'
    const backToPath = `${routes.admin.account}`

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    // sync with store
    const {admin, userField} = useSelector(store => store)
    const item = admin.user

    // Upload field
    const [fileList, setFileList] = useState([]);
    const storeImageUrl = (image_url) => setFormField(form, "photo_url", image_url)

    // get form and set values
    const [form] = Form.useForm()
    const userData = [
        'photo_url',
        'first_name',
        'last_name',
        'title',
        'appeal',
    ]
    const userFields = [
        'bio',
        'about',
        'status',
        'contacts',
    ]
    // set form fields and its values
    const formFields = [...userData, ...userFields]
    let formValues = getItemValues(
        {
            ...item,
            bio: deepGet(userField, 'bio.value', ''),
            about: deepGet(userField, 'about.value', ''),
            status: parseBool(deepGet(userField, 'status.value', false))
        }, formFields)


    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (!admin.authorized) return
        else dispatch({type: 'getAdminItem', admin}); // get data once

        // set field values if correct data received
        if (item.id) {
            form.setFieldsValue(formValues);
            if (formValues.photo_url) {
                setFileList([{
                    uid: '-1',
                    name: 'avatar.jpg',
                    status: 'done',
                    url: formValues.photo_url,
                }]);
            }
        }
        //eslint-disable-next-line
    }, [admin.authorized, item.id])


    // editor init values
    // const [editorValue, setEditorValue] = useState('');
    useEffect(() => {
        if (admin.authorized) {
            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: ['status','bio','about'],
                }
            })
        }

        let form_values = {};
        if (userField.status)  form_values.status = userField.status.value;
        if (userField.bio) form_values.bio = userField.bio.value;
        if (userField.about)  form_values.about = userField.about.value;
        if (objectLength(form_values)) form.setFieldsValue(form_values);
        //eslint-disable-next-line
    }, [admin.authorized])

    const backToList = () => {
        navigate(backToPath);
    }
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        const data = getItemValues(values, formFields)
        const userValues = getItemValues(values, userData)
        const userFieldsValues = getItemValues(values, userFields)

        dispatch({type: 'updateAdminUser', admin, data: userValues});
        form.setFieldsValue(data)

        // console.log('userFieldsValues', userFieldsValues)

        for (let [field, value] of Object.entries(userFieldsValues)) {
            if (value === undefined) continue
            if (typeof value === 'boolean') value = value ? 1 : 0

            // init field post params
            const newUserFieldData = {
                field_name: field,
                value: value
            }

            // save to store by Reducer
            dispatch(setUserField(newUserFieldData))

            // save to backend by Saga
            dispatch({
                type: 'storeUserField',
                admin,
                data: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    ...newUserFieldData
                }
            })
        }

        // console.log('Admin User Values', userValues)
        // console.log('Admin User Fields', userFieldsValues)
        // console.log('Admin User All Data', data)
        navigate(backToPath);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    className="" // old-school-container
                    name="settings"
                    layout="vertical"
                >
                    <FormTitle onBack={backToList}>
                        {l('admin.header.settings')}
                    </FormTitle>

                    <Content className="page-container form-container site-layout-background">

                        <FormItemWrapper>
                            <AvatarUpload
                                admin={admin}
                                project_id={0} // no project for admin avatar
                                fileList={fileList}
                                setFileList={setFileList}
                                storeImageUrl={storeImageUrl}
                                siteSection={section}
                                imageType="user"
                                limit={1}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper className='hide'>
                            <Form.Item label={label(section, 'photo_url', 'label')} name='photo_url'>
                                <Input placeholder={'https://cloud.example.com/image/file.jpg'}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={label(section, 'first_name', 'desc')}>
                            <Form.Item
                                label={label(section, 'first_name', 'label')}
                                name='first_name'
                                rules={[
                                    {
                                        required: true,
                                        message: l("error.validation.required")
                                    }
                                ]}
                            >
                                <Input placeholder={t(section + '.form.first_name.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={label(section, 'last_name', 'desc')}>
                            <Form.Item label={label(section, 'last_name', 'label')} name='last_name'>
                                <Input placeholder={t(section + '.form.last_name.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={label(section, 'title', 'desc')}>
                            <Form.Item label={label(section, 'title', 'label')} name='title'>
                                <Input placeholder={t(section + '.form.title.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper>
                            <Form.Item label={label(section, 'appeal', 'label')} name='appeal'>
                                <Select placeholder={t(section + '.form.appeal.placeholder')}>
                                    <Option value={0}>{label(section, 'appeal', 'value.none')}</Option>
                                    <Option value={1}>{label(section, 'appeal', 'value.ms')}</Option>
                                    <Option value={2}>{label(section, 'appeal', 'value.mr')}</Option>
                                </Select>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={label(section, 'status', 'desc')}>
                            <Form.Item name="status" valuePropName="checked" className="allow-overflow">
                                <LabelSwitch label={label(section, 'status', 'label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper>
                            <Form.Item name="bio" label={label(section, 'bio', 'label')}>
                                <TinyMCE
                                    // EditorMCE
                                    t={t}
                                    form={form}
                                    parentId={section}
                                    fieldName="bio"
                                    initialValue={formValues.bio}
                                    section={section}
                                />
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper>
                            <Form.Item name="about" label={label(section, 'about', 'label')}>
                                <TinyMCE
                                    t={t}
                                    form={form}
                                    parentId={section}
                                    fieldName="about"
                                    initialValue={formValues.about}
                                    section={section}
                                    extended={true}
                                />
                            </Form.Item>
                        </FormItemWrapper>

                        <FormSubmit onCancel={backToList}/>
                    </Content>
                </Form>
            </Layout>
        </AppWrapper>
    )
}

export default AdminSettings