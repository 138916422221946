export const coreFeatures = {
    'icon/simple': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {

                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "hand",
                            },
                            wrap: {
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    size: "7xl",
                                    color: "indigo-500"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "regular",
                                icon: "rocket",
                            },
                            style: {
                                text: {
                                    size: "7xl",
                                    color: "red-500"
                                },
                            },
                            wrap: {
                                margin: {
                                    bottom: "5",
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "shield-check",
                            },
                            style: {
                                text: {
                                    size: "7xl",
                                    color: "green-500"
                                },
                            },
                            wrap: {
                                margin: {
                                    bottom: "5",
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },
    'icon/bg': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "hand",
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "indigo-500",
                                    place: "item"
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "6xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "120"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "120"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "hand",
                                name: "hand"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "red-500",
                                    place: "item"
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "6xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "120"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "120"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "rocket-launch",
                                name: "hand"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "blue-500",
                                    place: "item"
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "6xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "120"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "120"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.reactive.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.reactive.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "shield-check",
                                name: "hand"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "green-500",
                                    place: "item"
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "6xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "120"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "120"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }],
    },
    'icon/image': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "hand",
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "black",
                                    opacity: "50",
                                    place: "item",
                                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_04-13-10_5qr.jpg",
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "7xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "150"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            spec: {
                                set: "solid",
                                icon: "rocket",
                            },
                            type: "core/media/icon",
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "black",
                                    opacity: "50",
                                    place: "item",
                                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_05-23-18_Hml.jpg",
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "7xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "150"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/icon",
                            spec: {
                                set: "solid",
                                icon: "shield-check",
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                bg: {
                                    color: "black",
                                    opacity: "50",
                                    place: "item",
                                    url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-34-08_QkA.jpg",
                                },
                                flex: {
                                    direction: "col",
                                    content: "center",
                                    justify: "center",
                                },
                                text: {
                                    size: "7xl",
                                    color: "white",
                                },
                                width: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                                height: {
                                    amount: "150"
                                },
                            }
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }],
    },

    'image/round': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.handy.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-55-27_Q5A.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.fast.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_03-53-12_QIZ.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.safe.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-54-55_h45.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        width: {
                            amount: "250",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },

    'card/clean': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.handy.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-55-27_Q5A.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        border: {
                            color: "neutral-200",
                            radius: "lg",
                            shadow: "lg",
                            width: "1",
                        },
                        padding: {
                            bottom: "10",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.fast.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_03-53-12_QIZ.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        border: {
                            color: "neutral-200",
                            radius: "lg",
                            shadow: "lg",
                            width: "1",
                        },
                        padding: {
                            bottom: "10",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.safe.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-54-55_h45.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        border: {
                            color: "neutral-200",
                            radius: "lg",
                            shadow: "lg",
                            width: "1",
                        },
                        padding: {
                            bottom: "10",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },
    'card/image': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.handy.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-55-27_Q5A.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                border: {
                                    radius: "full",
                                    width: "2",
                                    color: "white",
                                },
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_04-13-10_5qr.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.fast.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_03-53-12_QIZ.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                                border: {
                                    radius: "full",
                                    width: "2",
                                    color: "white",
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_05-23-18_Hml.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/media/image",
                            spec: {
                                alt: "{locale:container.example.feature.safe.title}",
                                photo_url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-54-55_h45.jpg"
                            },
                            wrap: {
                                flex: {
                                    direction: "row",
                                    content: "center",
                                    justify: "center",
                                },
                                margin: {
                                    bottom: "5",
                                },
                            },
                            style: {
                                border: {
                                    radius: "full",
                                    width: "2",
                                    color: "white",
                                },
                                text: {
                                    color: "transparent"
                                },
                                width: {
                                    amount: "150"
                                },
                                height: {
                                    amount: "150"
                                },
                            },
                        },
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "4"
                                },
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-34-08_QkA.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                        width: {
                            amount: "298",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                text: {
                    align: "center",
                    color: "white",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },
    'card/text': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.handy.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "3"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                        }
                    ],
                    wrap: {
                        span: {
                            default: '12',
                            sm: '4',
                        },
                    },
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_04-13-10_5qr.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.fast.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "3"
                                }
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.fast.desc}"
                            },
                        }
                    ],
                    wrap: {
                        span: {
                            default: '12',
                            sm: '4',
                        },
                    },
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_05-23-18_Hml.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                    }
                },
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/text/heading",
                            spec: {
                                level: "h3",
                                content: "{locale:container.example.feature.safe.title}"
                            },
                            style: {
                                padding: {
                                    bottom: "3"
                                },
                            }
                        },
                        {
                            type: "core/text/desc",
                            spec: {
                                content: "{locale:container.example.feature.safe.desc}"
                            },
                        }
                    ],
                    wrap: {
                        span: {
                            default: '12',
                            sm: '4',
                        },
                    },
                    style: {
                        bg: {
                            color: "black",
                            opacity: "50",
                            place: "item",
                            url: "https://multyai.storage.yandexcloud.net/project/3/flow/2/2023-10-30_13-34-08_QkA.jpg",
                        },
                        border: {
                            radius: "xl",
                            shadow: "lg",
                        },
                        padding: {
                            bottom: "6",
                            left: "6",
                            right: "6",
                            top: "6",
                        },
                    }
                }
            ],
            style: {
                flex: {
                    direction: "grid",
                    items: "stretch",
                    gap: "6",
                    wrap: true,
                },
                grid: {
                    default: "12",
                },
                grow: {
                    content: true,
                },
                text: {
                    align: "center",
                    color: "white",
                },
                padding: {
                    top: '10',
                    bottom: '10',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },

    'icon/text': {
        content: [{
            type: 'con',
            items: [
                {
                    type: 'group',
                    items: [
                        {
                            type: "core/text/icon",
                            spec: {
                                icon: {
                                    name: "check",
                                    set: "light",
                                },
                                classes: {
                                    title: ['select-none', 'cursor-pointer', 'mb-3', 'text-h4', 'flex', 'flex-row', 'gap-4'],
                                    icon: ['block', 'pl-2', 'pr-2'],
                                    desc: ['prose', 'dark:prose-invert'],
                                },
                                title: "{locale:container.example.feature.handy.title}",
                                content: "{locale:container.example.feature.handy.desc}"
                            },
                            style: {
                                padding: {
                                    top: '6',
                                    bottom: '6',
                                    left: "6",
                                    right: "6",
                                },
                                flex: {
                                    direction: "row",
                                    items: "stretch",
                                    justify: "center",
                                    gap: "6",
                                },
                            }
                        },
                    ],
                    style: {
                        // bg: {
                        //     color: "gray-100",
                        // },
                        // border: {
                        //     radius: "lg"
                        // },
                    },
                },
            ],
            style: {
                width: {
                    container: 'lg',
                },
                flex: {
                    direction: "row",
                    items: "stretch",
                    content: "center",
                    justify: "center",
                    gap: "6",
                    wrap: true,
                },
                padding: {
                    top: '6',
                    bottom: '6',
                    left: "6",
                    right: "6",
                },
            }
        }]
    },
}