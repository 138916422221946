import React from 'react'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"

import {Button, Tooltip} from 'antd';
import {images, routes} from "../../config/config";
import {l} from "../../library/locale";
import {AiOutlineCopy, AiOutlineSetting} from "react-icons/ai";

import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import FoldersList from "../../components/List/Table/FoldersList";
import {copyToClipboard} from "../../library/clipboard";

export const GroupListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        recordCreate,
    }
) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // navigate
    const recordOpen = (id) => navigate(`${routes.project_list}/${project.id}${routes.local.user_list}?list_id=${id}`)
    const recordEdit = (id) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${id}`)
    // const recordOpen = (id) => recordEdit(id)
    // saga
    const recordDelete = (id) => dispatch({type: 'deleteField', admin, filters: {id: id, project_id: project.id}})
    const recordCopy = (id) => dispatch({type: 'copyField', admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restoreField', admin, data: params})
    }

    const columns = [
        {
            title: l('common.form.avatar'), // Аватар
            dataIndex: 'image',
            className: 'cursor-pointer avatar',
            responsive: ['sm'],
            width: 70,
            render: (icon, record) => <TableAvatar imageUrl={record.image} icon="user-group"/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'title',
            title: l('common.form.title.label'),
            className: 'cursor-pointer table-row-title title-link',
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record.id)
                };
            },
        },
        {
            dataIndex: 'name',
            title: l('common.form.name.label'),
            className: 'text-secondary max-width-270',
            width: 170,
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.variable),
                    onContextMenu: (ev) => {
                        ev.preventDefault()
                        copyToClipboard(record.name)
                    },
                };
            },
            render: (text, record) => (
                <Tooltip title={l('common.action.click_for_copy_var')}>
                    {record.name}
                </Tooltip>
            ),
        },
        {
            title: l('common.form.actions'),  // Действия
            key: 'actions',
            className: 'drag-hide',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={l('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}>
                            <AiOutlineCopy/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={l('table.icon.settings')}>
                        <Button type="text" onClick={() => {
                            recordEdit(record.id)
                        }}><AiOutlineSetting/></Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column drag-hide',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        // {
        //     key: 'tableMenuOpen',
        //     label: l('table.menu.open'),
        //     action: (record) => recordOpen(record.id)
        // },
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record.id)
        },
        {
            key: 'tableMenuCopy',
            label: l('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            icon: record.icon_name ? record.icon_name : 'user-check',
            image: record.photo_url ? record.photo_url : images.universe.sections.list,
            title: record.title,
            name: record.name,
            variable: '{field:'+record.name+'}',
            type: record.system_field_type,
            folder: record.folder_id,
            deleted: record.deleted_at > 0,
        });
    }

    return <FoldersList
        section={section}
        sectionSaga={'Field'}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
        onDelete={recordDelete}
        onRestore={recordRestore}
    />
}

