import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../config/config";
import {getItemValues, objectLength, objectToQueryString, ucFirst} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemData} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const UserEdit = () => {
    const {t} = useTranslation()
    const section = 'user'
    const adminSection = 'user'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    const [avatarUrl, setAvatarUrl] = useState('')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, user} = useSelector(store => store)
    const item = user.item
    const project_item = project.item
    const filters = user.filters

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "id",
            type: "hidden",
        },
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'field',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            desc: null,
        },
        {
            name: "first_name",
            placeholder: t('common.status.neuter.unset'),
            desc: '',
            type: "text",
        },
        {
            name: "last_name",
            placeholder: t('common.status.neuter.unset'),
            desc: '',
            type: "text",
        },
        {
            name: "title",
            placeholder: t('common.status.neuter.unset'),
            type: "text",
        },
        {
            placeholder: '',
            name: 'appeal',
            type: 'menu',
            values: [0, 1, 2]
        },
        {
            placeholder: '',
            name: 'profile_type',
            type: 'menu',
            values: ['user', 'bot', 'chat', 'channel']
        },
        {
            name: "password",
            type: "password",
        },
        // {
        //     name: "manager_user_id",
        //     type: "number",
        //     label: t('common.form.manager_user_id.label'),
        //     desc: t('common.form.manager_user_id.desc'),
        //     placeholder: true,
        // },
        {
            name: "source_id",
            type: "number",
            label: t('common.form.source_id.label'),
            desc: t('common.form.source_id.desc'),
            placeholder: '0',
        },
        {
            name: 'ignore',
            type: 'switcher',
            desc: null
        },
    ]

    // itis form state
    // TODO: add receiving from search params
    const [formValues, setFormValues] = useState({
        photo_url: '',
        profile_type: 'user',
        ignore: false,
        appeal: 0,
        // manager_user_id: 0,
        // source_id: 0,
    })

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id)

        // set field values if correct data received
        if (item.id === id) {
            form.setFieldsValue(formValues);
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    // set fields values to STATE if correct data received
    useEffect(() => {
        if (item) {
            const formValuesNew = getItemValues(item, formFields)
            setFormValues(formValuesNew)
        }
        //eslint-disable-next-line
    }, [item])

    // set fields values to FORM if correct data received
    useEffect(() => {
        if (formValues.id === id) {
            form.setFieldsValue(formValues)
            setAvatarUrl(formValues.photo_url) // for upload field
            // console.log('formValues.photo_url', formValues.photo_url)
        }
        //eslint-disable-next-line
    }, [formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate(ucFirst(section), values, formFields, id, project_item, admin, dispatch)
        backToList()
    }
    const backToList = () => {
        let suffix = ''
        if (objectLength(filters) > 0) suffix = '?' + objectToQueryString(filters)
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}${suffix}`);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default UserEdit