import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addLayoutToList,
    fillLayoutList,
    patchLayoutInList,
    removeLayoutFromList,
    setLayoutItem,
    setLayoutListCount,
    fillLayoutShortList,
    setLayoutShortListCount,
    clearLayoutItem,
    fillLayoutPublicList,
    addLayoutToProject,
} from "../reducers/LayoutReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

// init api methods
const base_path = backend_api.layout;

function* getLayoutList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillLayoutList(response.data.result))
            yield put(setLayoutListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getLayoutShortList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/list', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillLayoutShortList(response.data.result))
            yield put(setLayoutShortListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getLayoutPublicList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/public', 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillLayoutPublicList(response.data.result))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getLayoutItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) yield put(setLayoutItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getLayoutByFilters(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/item', 'get', arg.filters);
        if (response.data.ok) yield put(setLayoutItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        yield put(clearLayoutItem())
        // apiErrorNotice(error)
        // return null
    }
}

function* installLayout(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/install', 'post', arg.data);

        if (response.data.ok) {
            if (arg.action === 'create') yield put(addLayoutToList(response.data.result))
            else yield put(addLayoutToProject(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createLayout(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addLayoutToList(response.data.result))
            yield put(setLayoutItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateLayout(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchLayoutInList(response.data.result))
            yield put(setLayoutItem(response.data.result))
            if (arg.show_notice) notice.success(l("layout.notice.saved"))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyLayout(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '_copy'

        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setLayoutItem(response.data.result))
            yield put(addLayoutToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreLayout(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeLayoutFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteLayout(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeLayoutFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* layoutSaga() {
    yield takeEvery('getLayoutList', getLayoutList);
    yield takeEvery('getLayoutShortList', getLayoutShortList);
    yield takeEvery('getLayoutPublicList', getLayoutPublicList);
    yield takeEvery('getLayoutByFilters', getLayoutByFilters);
    yield takeEvery('getLayoutItem', getLayoutItem);
    yield takeEvery('installLayout', installLayout);
    yield takeEvery('createLayout', createLayout);
    yield takeEvery('copyLayout', copyLayout);
    yield takeEvery('updateLayout', updateLayout);
    yield takeEvery('restoreLayout', restoreLayout);
    yield takeEvery('deleteLayout', deleteLayout);
}

