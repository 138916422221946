import React from 'react'
import {Layout} from "antd";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import PageSettings from "./PageSettings";

const PageEdit = () => {
    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <PageSettings />
            </Layout>
        </AppWrapper>
    )
}

export default PageEdit