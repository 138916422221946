import React from 'react';
import {createMarkup} from "../../library/functions";

export const FormItemWrapper = ({children, className='', desc='', style={}, html = false}) => {
    let classes = 'form-item-wrapper';
    if (className) classes += ' ' + className;
    const classDesc = desc ? 'form-item-desc' : 'hide';

    return (
        <div className={classes} style={style}>
            {children}
            {html ? <div dangerouslySetInnerHTML={createMarkup(desc)} className={classDesc}/> :
            <div className={classDesc}>{desc}</div> }
        </div>
    );
};

