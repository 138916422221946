import React, {useEffect, useRef, useState} from 'react';
import classes from "./SearchBox.module.scss"
import {Button} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import {l} from "../../library/locale";
import {useTranslation} from "react-i18next";

export const SearchBox = (
    {
        searchHandler,
        showInputDefault = false,
        className = 'hidden-xs',
        searchPlaceholder = null,
        wrapperClasses = '',
        showCloseButton = true,
    }
) => {

    const {t} = useTranslation();
    const [value, setValue] = useState('')
    const [showInput, setVisible] = useState(showInputDefault)
    const searchRef = useRef()

    useEffect(() => {
        if (showInput && showCloseButton) searchRef.current.focus()
    }, [showInput])

    const handleClickSearchValue = (value) => {
        searchHandler(value)
    }

    const handleClickCloseValue = () => {
        if (value === '') {
            setVisible(false)
        }
        searchHandler('')

        setValue('')
        if (showInput) searchRef.current.focus()
    }

    const handleChangeSearchValue = (e) => {
        let value = e.currentTarget.value
        setValue(value)
    }

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            handleClickSearchValue(value)
        }
    }

    const handleFormSubmit = (e) => {
        // do not redirect to form action
        if (e.cancelable) e.preventDefault();


        // let value = e.text
        // console.log('e', e)
        // console.log('e.text', e.text)

        // handleClickSearchValue(value)
    }

    let placeholder = searchPlaceholder
    if (!placeholder) placeholder = t('page_header.search.title')

    let wrapperClassesResult = 'search-input-wrapper inline'
    if (wrapperClasses) wrapperClassesResult += ' ' + wrapperClasses

    return (
        <div className={wrapperClassesResult}>
            <Button
                type="text"
                icon={<SearchOutlined/>}
                className={showInput ? "hide" : className}
                onClick={() => setVisible(true)}>
                {l('page_header.buttons.search.title')}
            </Button>

            <form className={showInput ? 'show' : "hide"} onSubmit={handleFormSubmit}>
                <div className={classes.input__inner}>
                    <input
                        onKeyDown={onKeyPressHandler}
                        className={classes.input}
                        placeholder={placeholder}
                        onChange={handleChangeSearchValue}
                        // autoFocus={true}
                        type="search"
                        ref={searchRef}
                        value={value} />
                    <div className={showCloseButton?classes.input__search:classes.input__search_only}>
                        <SearchOutlined onClick={() => handleClickSearchValue(value)}/>
                    </div>
                    {showCloseButton?<div className={classes.input__close}>
                        <CloseOutlined onClick={handleClickCloseValue}/>
                    </div>:null}
                </div>
            </form>
        </div>
    );
};

