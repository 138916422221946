import {createSlice} from "@reduxjs/toolkit";

const SmProductReducer = createSlice({
    name: 'smProduct',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillSmProductList(state, action) {
            state.list = action.payload
        },
        setSmProductItem(state, action) {
            state.item = action.payload;
        },
        resetSmProductState(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default SmProductReducer.reducer
export const {
    fillSmProductList,
    setSmProductItem,
    resetSmProductState,
} = SmProductReducer.actions