import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    list: [],
    item: {},
    count: 0,
    page: 0,
    pageSize: 100,
    sorter: null,
    filters: {},
}

const EmployeeReducer = createSlice({
    name: 'employee',
    initialState: initialState,
    reducers: {
        fillEmployeeList(state, action) {
            state.list = action.payload
        },
        setEmployeeItem(state, action) {
            state.item = action.payload;
        },
        setEmployeeListCount(state, action) {
            state.count = action.payload;
        },
        setEmployeeStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addEmployeeToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        removeEmployeeFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchEmployeeInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetEmployeeState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default EmployeeReducer.reducer
export const {
    fillEmployeeList,
    setEmployeeItem,
    resetEmployeeState,
    setEmployeeListCount,
    setEmployeeStoreParam,
    patchEmployeeInList,
    addEmployeeToList,
    removeEmployeeFromList
} = EmployeeReducer.actions