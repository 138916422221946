import React, {useEffect, useState} from "react";
import {Button, Form} from 'antd';
import {createObjectFromObjectsArray, deepGet, is, formRequiredRules} from "../../../library/functions";
import {FormFields, getName, SetFieldInfo} from "./FormFields";
import Fa from "../../Icon/Fa";
import {
    addTreeItemByParentKey,
    defaultTreeData,
    DraggableTree,
    getTreeItemByKey,
    removeTreeItemByKey,
    updateTreeItemByKey
} from "../../Tree/Tree";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SimpleModal} from "../../Modal/SimpleModal";
import {useSelector} from "react-redux";


export const SectorsField = (
    {
        t,
        form,
        formValues = null,
        name = 'tree',
        section = 'common',
        localeRoot = null,
        label = null,
        placeholder = null,
        required = false,
        onBlur = null,
    }
) => {
    const modalSection = 'block.core.game.wheel';

    if (!localeRoot) localeRoot = section;
    const fieldName = getName(name);

    const {page} = useSelector(store => store)
    const pages = createObjectFromObjectsArray(page.short.list)

    const [modalForm] = Form.useForm();
    const [activeKey, setActiveKey] = useState(null);
    const [parentKey, setParentKey] = useState(0);
    const [expanded, setExpanded] = useState([]);
    const modalDefaultValues = {
        title: '',
        desc: '',
        value: '',
        chance: '',
    }

    const modalFields = [
        {
            name: "title",
            type: "text",
            placeholder: true,
            required: true,
        },
        {
            name: "chance",
            type: "text",
            placeholder: '0',
        },
        {
            name: "value",
            type: "text",
            placeholder: '123',
        },
        {
            name: "desc",
            type: "editor",
            placeholder: true,
            form: modalForm,
            formValues: modalForm.getFieldValue('desc'),
            extended: true,
        },
    ]

    useEffect(() => {
        if (formValues) {
            const newValue = deepGet(formValues, fieldName, null);
            form.setFieldValue(fieldName, newValue);
        }
    }, [formValues]);

    const setActive = (keys, event) => {
        const key = (keys.length) ? keys[0] : deepGet(event, ['node', 'key'], null);
        // console.log('setActive', keys[0], keys, event.node);

        setActiveKey(key);
        setParentKey(key);
    }

    // console.log('NavTreeField value', form.getFieldValue(fieldName));

    // const onChange = (value) => {
    //     form.setFieldValue(fieldName, value);
    //     if (onBlur) onBlur(value);
    // }

    // let value = form.getFieldValue(fieldName);
    // console.log('value', value);

    useEffect(() => {
        // console.log('activeKey, menuName', activeKey, menuName)

        if (activeKey === null) {
            // console.log('reset modalForm')
            modalForm.setFieldsValue(modalDefaultValues);
        } else {
            const currentValue = form.getFieldValue(fieldName) || defaultTreeData;
            // console.log('useEffect - Key', activeKey, 'Value', currentValue);

            const currentItem = getTreeItemByKey(currentValue, activeKey);
            // console.log('useEffect - Current item', activeKey, currentItem);

            modalForm.setFieldsValue(currentItem);
        }
    }, [activeKey])

    const saveMenuItem = () => {
        let newValue = null;
        if (activeKey === null) return false;

        let newMenuItem = modalForm.getFieldsValue();
        const currentValue = form.getFieldValue(fieldName) || defaultTreeData;

        if (!is(newMenuItem.title) && newMenuItem.page_id) {
            newMenuItem.title = pages[Number(newMenuItem.page_id)] || '';
        }

        // console.log('saveMenuItem', newMenuItem, currentValue);
        if (activeKey) newValue = updateTreeItemByKey(currentValue, activeKey, newMenuItem);
        else {
            newValue = addTreeItemByParentKey(currentValue, parentKey, newMenuItem);
            setExpanded([...expanded, parentKey]);
        }

        // console.log('save - New value', fieldName, newValue.tree);
        form.setFieldValue(fieldName, newValue);

        setActiveKey(null);
        setParentKey(0);

        // const newValues = form.getFieldsValue();
        // console.log('saveMenuItem - New values', newValues.params.global.menu.main);
        // setFormValues(newValues);

        if (onBlur) onBlur(newValue);

    }

    const removeMenuItem = () => {
        if (activeKey === null) return false;
        const currentValue = form.getFieldValue(fieldName);
        // console.log('Remove - Old value', currentValue);

        const newValue = removeTreeItemByKey(currentValue, activeKey);
        form.setFieldValue(fieldName, newValue);
        // console.log('Remove - New value', newValue);

        setActiveKey(null);
        setParentKey(0);
    }

    const nodeRenderer = (nodeData) => {
        const nodeClasses = deepGet(nodeData, ['classes'], []);
        return <span className={nodeClasses.join(' ').includes('hidden') ? 'color-secondary' : null}>
            {nodeData.icon && <Fa
                icon={nodeData.icon}
                set="regular"
                wcn="margin-right-xp font-size-sm color-secondary"
            />}
            <span>{nodeData.title}</span>
        </span>;
    }

    return (<div className="margin-bottom-xs">
        <Form.Item
            name={fieldName}
            rules={required ? formRequiredRules : null}
            label={SetFieldInfo(t, localeRoot, name, label, 'label')}
            className="min-h-0"
        >
            <DraggableTree
                onBlur={onBlur}
                onSelect={setActive}
                renderer={nodeRenderer}

                expanded={expanded}
                oneLevel={true}

                // value={value}
                // onChange={onChange}
            />
        </Form.Item>

        <Button
            type="text"
            size="small"
            icon={<PlusOutlined/>}
            onClick={() => setActive([0])}
            className="font-size-sm margin-top-xs"
        >
            {SetFieldInfo(t, localeRoot, name, placeholder, 'placeholder')}
        </Button>

        <SimpleModal
            title={t(modalSection + '.menu.modal.title.' + (activeKey ? 'edit' : 'add'))}
            isVisible={activeKey !== null}
            setVisible={() => setActiveKey(null)}
            loadingOnOk={true}
            actionLabel="apply"
            onOk={saveMenuItem}
            hideCancelXs={true}
            maskClosable={true}
            // width={980}
            className="compact-modal scrollable"
            // getContainer="#app-page-wrapper"
            leftFooterText={<Button
                onClick={removeMenuItem}
                icon={<DeleteOutlined/>}
                className="padding-none-horizontal"
                target="_blank"
                type="text"
            >{t('common.action.delete')}</Button>}
        >
            <Form
                form={modalForm}
                className="margin-top-xp z-101"
                layout="vertical"
                // style={{minHeight: '300px'}}
            >
                <FormFields
                    t={t}
                    section={modalSection + '.menu.modal'}
                    fields={modalFields}
                    form={modalForm}
                    // formValues={modalFormValues}
                />
            </Form>
        </SimpleModal>
    </div>)
};
