export const coreTariffs = {
    'button/url': {
        content: [
            {
                type: 'con',
                wrap: {
                    classes: {
                        custom: []
                    }
                },
                items: [
                    {
                        type: 'group',
                        items: [
                            {
                                type: "core/text/heading",
                                spec: {
                                    level: "h2",
                                    content: "{locale:container.example.tariff.title.basic}"
                                },
                                style: {
                                    text: {
                                        align: "center",
                                        weight: "medium",
                                    },
                                    padding: {
                                        bottom: "4"
                                    }
                                }
                            },
                            {
                                type: "core/text/desc",
                                spec: {
                                    content: "<ul>" +
                                        "<li>{locale:container.example.tariff.item} 1</li>" +
                                        "<li>{locale:container.example.tariff.item} 2</li>" +
                                        "<li>{locale:container.example.tariff.item} 3</li>" +
                                        "</ul>"
                                },
                                style: {
                                    text: {
                                        align: "left",
                                    },
                                    classes: {
                                        prose: true,
                                    },
                                    padding: {
                                        bottom: "3"
                                    }
                                }
                            },
                            {
                                type: "core/form/button/url",
                                spec: {
                                    icon: {
                                        set: "regular"
                                    },
                                    size: "medium",
                                    color: "primary",
                                    label: "{locale:container.example.tariff.button}",
                                    variant: "contained",
                                    is_external: true
                                },
                                wrap: {
                                    margin: {
                                        top: "auto"
                                    },
                                },
                                style: {
                                    width: {
                                        amount: "100%"
                                    },
                                }
                            }
                        ],
                        style: {
                            flex: {
                                wrap: true,
                                items: "stretch",
                                layout: "vertical",
                                justify: "start",
                                direction: "col"
                            },
                            width: {
                                min: "200"
                            },
                            border: {
                                color: "neutral-200",
                                width: "1",
                                radius: "lp",
                                shadow: "lg"
                            },
                            padding: {
                                top: "6",
                                left: "6",
                                right: "6",
                                bottom: "6"
                            }
                        },
                        wrap: {
                            span: {
                                default: '12',
                                sm: '4',
                            },
                        },
                    },
                    {
                        type: 'group',
                        items: [
                            {
                                type: "core/text/heading",
                                spec: {
                                    level: "h2",
                                    content: "{locale:container.example.tariff.title.premium}"
                                },
                                style: {
                                    text: {
                                        align: "center",
                                        weight: "medium",
                                    },
                                    padding: {
                                        bottom: "4"
                                    }
                                }
                            },
                            {
                                type: "core/text/desc",
                                spec: {
                                    content: "<ul>" +
                                        "<li>{locale:container.example.tariff.item} 1</li>" +
                                        "<li>{locale:container.example.tariff.item} 2</li>" +
                                        "<li>{locale:container.example.tariff.item} 3</li>" +
                                        "<li>{locale:container.example.tariff.item} 4</li>" +
                                        "<li>{locale:container.example.tariff.item} 5</li>" +
                                        "</ul>"
                                },
                                style: {
                                    text: {
                                        align: "left",
                                    },
                                    classes: {
                                        prose: true,
                                    },
                                    padding: {
                                        bottom: "3"
                                    }
                                }
                            },
                            {
                                type: "core/form/button/url",
                                spec: {
                                    icon: {
                                        set: "regular"
                                    },
                                    size: "medium",
                                    color: "primary",
                                    label: "{locale:container.example.tariff.button}",
                                    variant: "contained",
                                    is_external: true
                                },
                                wrap: {
                                    margin: {
                                        top: "auto"
                                    },
                                },
                                style: {
                                    width: {
                                        amount: "100%"
                                    },
                                }
                            }
                        ],
                        style: {
                            flex: {
                                wrap: true,
                                items: "stretch",
                                layout: "vertical",
                                justify: "start",
                                direction: "col"
                            },
                            width: {
                                min: "200"
                            },
                            border: {
                                color: "neutral-200",
                                width: "1",
                                radius: "lp",
                                shadow: "lg"
                            },
                            padding: {
                                top: "6",
                                left: "6",
                                right: "6",
                                bottom: "6"
                            }
                        },
                        wrap: {
                            span: {
                                default: '12',
                                sm: '4',
                            },
                        },
                    },
                    {
                        type: 'group',
                        items: [
                            {
                                type: "core/text/heading",
                                spec: {
                                    level: "h2",
                                    content: "{locale:container.example.tariff.title.vip}"
                                },
                                style: {
                                    text: {
                                        align: "center",
                                        weight: "medium",
                                    },
                                    padding: {
                                        bottom: "4"
                                    }
                                }
                            },
                            {
                                type: "core/text/desc",
                                spec: {
                                    content: "<ul>" +
                                        "<li>{locale:container.example.tariff.item} 1</li>" +
                                        "<li>{locale:container.example.tariff.item} 2</li>" +
                                        "<li>{locale:container.example.tariff.item} 3</li>" +
                                        "<li>{locale:container.example.tariff.item} 4</li>" +
                                        "</ul>"
                                },
                                style: {
                                    text: {
                                        align: "left",
                                    },
                                    classes: {
                                        prose: true,
                                    },
                                    padding: {
                                        bottom: "3"
                                    }
                                }
                            },
                            {
                                type: "core/form/button/url",
                                spec: {
                                    icon: {
                                        set: "regular"
                                    },
                                    size: "medium",
                                    color: "primary",
                                    label: "{locale:container.example.tariff.button}",
                                    variant: "contained",
                                    is_external: true
                                },
                                wrap: {
                                    margin: {
                                        top: "auto"
                                    },
                                },
                                style: {
                                    width: {
                                        amount: "100%"
                                    },
                                }
                            }
                        ],
                        style: {
                            flex: {
                                wrap: true,
                                items: "stretch",
                                layout: "vertical",
                                justify: "start",
                                direction: "col"
                            },
                            width: {
                                min: "200"
                            },
                            border: {
                                color: "neutral-200",
                                width: "1",
                                radius: "lp",
                                shadow: "lg"
                            },
                            padding: {
                                top: "6",
                                left: "6",
                                right: "6",
                                bottom: "6"
                            }
                        },
                        wrap: {
                            span: {
                                default: '12',
                                sm: '4',
                            },
                        },
                    },
                ],
                style: {
                    flex: {
                        direction: "grid",
                        items: "stretch",
                        gap: "6",
                        wrap: true,
                    },
                    grid: {
                        default: "12",
                    },
                    width: {
                        container: "lg"
                    },
                    padding: {
                        top: "10",
                        left: "6",
                        right: "6",
                        bottom: "10"
                    }
                }
            }
        ]
    },
}