import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import cryptoRandomString from "crypto-random-string";
import {
    createObjectFromObjectsArray,
    momentFromUnix,
    objectLength,
    ucFirst,
    unixFromMoment
} from "../../../library/functions";
import {routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {SetItemEditFormOrPreloader} from "../../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import {notice} from "../../../library/notice";

const DomainEdit = () => {
    const {t} = useTranslation()
    const section = 'domain'
    const Section = ucFirst(section)
    const adminSection = section
    const categorySection = 'site'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder
    let site_id = searchParams.get('site_id')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, domain, site, folder} = useSelector(store => store)
    const item = domain.item
    const project_item = project.item;
    const categoryList = site.list || []
    const categories = createObjectFromObjectsArray(categoryList);

    // set first state
    const [isDomain, setIsDomain] = useState(false);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "name",
            type: "text",
            required: true,
            isVisible: isDomain,
        },
        {
            name: "subdomain",
            type: "text",
            required: true,
            isVisible: !isDomain,
        },
        {
            name: "type",
            type: "hidden",
        },
        // {
        //     name: "type",
        //     type: "menu",
        //     placeholder: '',
        //     values: [
        //         'subdomain',
        //         'domain',
        //     ],
        //     localized: true,
        // },
        {
            name: "site_id",
            type: "menu",
            data: 'site',
            values: categories,
            required: true,
        },
        {
            name: "is_on",
            type: "switcher",
            label: t('common.form.is_on.label'),
        },
        {
            name: "is_main",
            type: "switcher",
        },
        {
            placeholder: t('common.placeholder.text'),
            name: "title",
            type: "text",
        },
        {
            name: "params.folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
    ]

    // itis form state
    const [folderId, setFolderId] = useState(0)
    const [formValues, setFormValues] = useState({
        title: '',
        name: '',
        subdomain: cryptoRandomString({length: 7}),
        type: 'subdomain',
        site_id: site_id || null,
        is_on: true,
        params: {
            folder_id: folder_id,
        }
    })

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !categoryList.length) {
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}});
        }

        //eslint-disable-next-line
    }, [admin.authorized, objectLength(item), project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}
            if (!item.parent_id) values.parent_id = null

            if (values.params === null) values.params = {}
            if (values.site_id) values.site_id = String(values.site_id);
            if (values.type === 'subdomain') values.subdomain = values.name;

            form.setFieldsValue(values);
            if (values.params.folder_id !== undefined) setFolderId(values.params.folder_id || 0)
            if (values.type !== undefined)  setIsDomain(values.type === 'domain')
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        let url = `${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`
        if (site_id) url += `&site_id=${site_id}`
        navigate(url);
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        if (!values.name && values.subdomain) values.name = values.subdomain;
        if (!values.name) {
            notice.error(t(section + '.error.required_name'));
            return false;
        }
        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        backToList(parseInt(values.params.folder_id))
    }

    const onValuesChange = (changedValues, allValues) => {
        const isDomainStatus = allValues.type === 'domain';
        if (isDomainStatus !== isDomain) setIsDomain(isDomainStatus);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                    onValuesChange={onValuesChange}
                />
            </Layout>
        </AppWrapper>
    )
}

export default DomainEdit