import React, {useContext} from 'react'
import FontSize from "../svg/FontTsize";
import buttonsClasses from "./NodeButtons.module.scss";
import noteColorPresets from "../../Node/Note.module.scss";
import {useTranslation} from "react-i18next";
import FlowContext from "../../../FlowContext";
import {notice} from "../../../../../library/notice";
import {Button} from "antd";

const max_note_font_size = 48;
const min_note_font_size = 12;

const NoteControls = ({nodeId, currentPreset, fontSize}) => {
    const classes = buttonsClasses;
    const {t} = useTranslation();
    let {onUpdate} = useContext(FlowContext);

    const changeColor = (preset) => {
        onUpdate({
            localId: nodeId,
            // selected: true
        }, {preset: preset, fontSize: fontSize});
    }

    const changeFontParams = (delta = 2) => {
        let newFontSize = fontSize;
        let message = '';
        if (delta < 0) {
            if (fontSize > min_note_font_size) {
                newFontSize += delta
            } else {
                message = t('graph.flow.error.note.min_size');
            }
        } else {
            if (fontSize < max_note_font_size) {
                newFontSize += delta
            } else {
                message = t('graph.flow.error.note.max_size');
            }
        }
        console.log('newFontSize', fontSize, newFontSize)
        if (newFontSize !== fontSize) {
            onUpdate({localId: nodeId, selected: true}, {preset: currentPreset, fontSize: newFontSize});
        } else if (message) notice.warning(message).then();
    }

    return (
        <>
            <Button.Group className={classes.node_button_group} style={{marginRight: 10}}>
                <Button
                    className={classes.node_button}
                    onClick={changeFontParams.bind(this, 2)}
                    type="default"
                    size="small"
                    style={{paddingTop: 0.5, paddingLeft: 8}}
                ><FontSize size={15}/></Button>
                <Button
                    className={classes.node_button}
                    onClick={changeFontParams.bind(this, -2)}
                    type="default"
                    size="small"
                    style={{paddingLeft: 8}}
                ><FontSize size={12}/></Button>
            </Button.Group>

            <Button.Group className={classes.node_button_group}>
                {['primary', 'default', 'inverse', 'success', 'danger', 'note'].map(preset => {
                    return <Button
                        key={'note-preset-' + preset}
                        className={`${classes.node_button}`} // ${preset === currentPreset ? classes.active : ''}
                        onClick={changeColor.bind(this, preset)}
                        type="default"
                        size="small"
                    >
                        <span className={`${classes.color_circle} ${noteColorPresets['note_preset_' + preset]}`}/>
                    </Button>
                })}
            </Button.Group>
        </>
    )
}

export default NoteControls