import {createSlice} from "@reduxjs/toolkit";
import {deepGet} from "../../library/functions";

const getFieldName = (action) => {
    const field_name_dash = deepGet (action.payload, 'field_name')
    const field_name = deepGet (action.payload, 'field.name')
    const field_id = deepGet (action.payload, 'field_id')

    if (field_name_dash) return field_name_dash
    else if (field_name) return field_name
    else if (field_id) return 'field_' + field_id;
    else return 'undefined'
}

const UserFieldReducer = createSlice({
    name: 'userField',
    initialState: {},
    reducers: {
        fillUserFieldSet(state, action) {
            for (const item of action.payload){
                state[item['name']] = item
            }
        },
        setUserField(state, action) {
            const fieldName = getFieldName(action)
            state[fieldName] = {...state[fieldName], ...action.payload}
        },
        removeUserField(state, action) {
            let fields = {...state}
            delete fields[getFieldName(action)]
            state = fields
        }
    }
})

export default UserFieldReducer.reducer
export const {fillUserFieldSet, setUserField, removeUserField} = UserFieldReducer.actions