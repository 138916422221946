import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CheckOutlined, DeleteOutlined, PlusOutlined, RollbackOutlined} from "@ant-design/icons";
import {Button, Modal, Row} from "antd";
import {notice} from "../../../library/notice";

import {NoForm} from "../../Form/NoForm";
import {FormInput} from "../../Form/FormInput";
import {clearSiteItem, setSiteItem} from "../../../redux/reducers/SiteReducer";
import {filterArrayByFieldValue} from "../../../library/functions";
import {useTranslation} from "react-i18next";

export const ListGameManager = ({list}) => {
    // init hook functions
    const {t} = useTranslation();
    const dispatch = useDispatch()

    // init store
    const {admin, project, site} = useSelector(store => store)
    const project_item = project.item
    const sites = site.list
    const item = site.item

    // init states
    const [isModalOpen, setModalStatus] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef()

    useLayoutEffect(() => {
        // set modal options to default if opened on sites list update
        if (isModalOpen) {
            hideModal()
        }
        //eslint-disable-next-line
    }, [sites && sites.length])

    useEffect(() => {
        // set modal options to default if opened

        if (item.id === undefined) {
            hideModal()
        }
        else{
            setInputValue(item.title)
            showModal()
        }

        //eslint-disable-next-line
    }, [item.id])

    const siteCreate = (title = 'New Site') => {
        const data = {
            project_id: project_item.id,
            title: title,
            description: 'Created automatically as folder',
            type: 'game',
        }
        dispatch({type: 'createSite', admin, data, folder_mode: true});
    }

    const siteUpdate = (id = 0, title = 'Site') => {
        const data = {
            project_id: project_item.id,
            id: id,
            title: title,
        }
        dispatch({type: 'updateSite', admin, data, folder_mode: true});
    }

    const siteCreateOpen = () => {
        dispatch(setSiteItem({id: 0}))
        setInputValue('')
        showModal()
    }

    // ====== USE THIS IN CONTAINERS ======
    // const siteEditOpen = (id) => {
    //     dispatch(setSiteItemById(id))
    // }

    const showModal = () => {
        setModalStatus(true)

        setTimeout(() => {
            if (inputRef && inputRef.current) {
                const {input} = inputRef.current
                input.focus()
            }
        }, 500)
    }

    const hideModal = () => {
        dispatch(clearSiteItem())
        setInputValue('')
        setModalStatus(false);
        setConfirmLoading(false);
    }

    const handleOk = () => {
        setConfirmLoading(true);

        if (site.item.id && inputValue) siteUpdate(site.item.id, inputValue)
        else if (inputValue) siteCreate(inputValue)
        else {
            notice.warning(t('error.validation.required_name'));
            showModal();
        }

        setTimeout(() => {
            setConfirmLoading(false);
        }, 1000);
    };

    const handleCancel = () => {
        hideModal();
    };

    const onModalInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const onModalFormSubmit = (e) => {
        onModalInputChange(e)
        handleOk()
    }

    const onModalInputKeyDown = (e) => {
        if (e.code === 'Enter') {
            onModalFormSubmit(e)
        }
    }

    const deleteSite = () => {
        const siteItems = filterArrayByFieldValue(list, 'folder_id', site.item.id)
        if (siteItems.length > 0) {
            notice.error(t('game_folder.error.delete_empty'))
        } else {
            dispatch({type: 'deleteSite', admin, id: site.item.id});
        }
    }

    const restoreItem = () => {
        const data = {
            project_id: site.item.project_id,
            id: site.item.id,
            deleted_at: 0,
        }
        dispatch({type: 'updateSite', admin, data});
    }

    const deleted = site.item.deleted_at > 0;

    return (
        <>
            <Row className="float-right margin-top-ps">
                <Button
                    type="text"
                    size="large"
                    className="inverted"
                    onClick={siteCreateOpen}
                    icon={<PlusOutlined/>}
                >{t('game_folder.add')}</Button>
            </Row>
            <Modal
                className="modal-clean"
                title={t('game_folder.settings')}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="user"
                        type="text"
                        className={site.item.id ? 'float-left inverted' : 'hide'}
                        onClick={deleted ? restoreItem : deleteSite }
                        icon={deleted ? <RollbackOutlined/> : <DeleteOutlined/>}>
                        {deleted ? t('game_folder.restore') : t('game_folder.delete')}
                    </Button>,
                    <Button
                        key="modal-back"
                        type="text"
                        onClick={handleCancel}
                        className="inverted"
                    >{t('common.action.cancel')} </Button>,
                    <Button
                        key="modal-submit"
                        type="primary"
                        onClick={handleOk}
                        loading={confirmLoading}
                        icon={<CheckOutlined/>}
                    >{site.item.id ? t('common.action.apply') : t('common.action.create')}</Button>
                ]}
            >

                <NoForm>
                    <FormInput
                        inputId="projectTitleInput"
                        className="margin-none"
                        // label={t('common.form.name.label')}
                        placeholder={site.item.id ? t('game_folder.enter_edit.placeholder') : t('game_folder.enter_new.placeholder')}
                        desc={t('game_folder.form.title.desc')}
                        autoFocus={true}
                        inputRef={inputRef}
                        inputValue={inputValue} // must be state
                        onChange={onModalInputChange}
                        onKeyDown={onModalInputKeyDown}
                    />
                </NoForm>
            </Modal>
        </>
    )
}