import {getItemValues, is, ucFirst} from "./functions";

export const directCreateOrUpdate = (itemName, data, admin, dispatch) => {
    if ('id' in data && data.id) {
        dispatch({type: 'update' + itemName, admin, data});
    } else {
        delete data['id'];
        dispatch({type: 'create' + itemName, admin, data});
    }
}

export const formCreateOrUpdate = (
    itemName,
    values,
    formFields,
    id,
    project,
    admin,
    dispatch,
    direct = false,
    show_notice = null
) => {
    let data;

    if (direct) data = values;
    else data = getItemValues(values, formFields)
    // console.log('formCreateOrUpdate data', data)
    data.project_id = project.id

    // console.log('data', data)
    if (id) {
        data.id = id;
        dispatch({type: 'update' + itemName, admin, id, data, show_notice});
    } else {
        dispatch({type: 'create' + itemName, admin, data});
    }
}

export const dispatchItemData = (dispatch, admin, project_item, section, id, filters = null) => {
    // console.log('dispatchItemData', project_item.id, admin.authorized, id)
    if (project_item.id && admin.authorized && id) {
        dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id, filters})
    }
}

export const dispatchItemDataWithFolders = (dispatch, admin, project_item, section, adminSection, id) => {
    if (project_item.id && admin.authorized) {
        dispatch({type: 'getFolderList', admin, filters: {project_id: project_item.id, section: adminSection}})

        if (id) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id})
        }
    }
}

export const dispatchItemWithFolders = (dispatch, admin, project_item, section, adminSection, id) => {
    if (project_item.id && admin.authorized) {
        dispatch({type: 'getFolderList', admin, filters: {project_id: project_item.id, section: adminSection}})

        if (id) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, filters: {id, project_id: project_item.id}})
        }
    }
}

export const getItemWithGames = (dispatch, admin, project_item, section, adminSection, id) => {
    if (project_item.id && admin.authorized) {
        dispatch({type: 'getSiteList', admin, filters: {project_id: project_item.id, types: ['game']}})

        if (id) {
            dispatch({
                type: 'get' + ucFirst(section) + 'Item',
                admin,
                filters: {id, project_id: project_item.id, system_field_type: adminSection}
            })
        }
    }
}

export const getNotEmptyValues = (inputValues) => {
    // console.log('inputValues', inputValues)

    let values = {}
    if (!inputValues) return values;

    for (const sec in inputValues) {
        if (typeof inputValues[sec] === 'object' && !Array.isArray(inputValues[sec])) {
            for (const key in inputValues[sec]) {
                if (is(inputValues[sec][key])) {
                    if (!values[sec]) values[sec] = {};
                    values[sec][key] = inputValues[sec][key];
                }
            }
        } else if (is(inputValues[sec])) {
            values[sec] = inputValues[sec];
        }
    }

    // console.log('values', values)
    return values;
}

export const handleContainers = (inputContainers) => {
    let items = []

    for (const containerData of inputContainers) {
        let container = {
            ...containerData,
            items: [],
            style: getNotEmptyValues(containerData.style),
            wrap: getNotEmptyValues(containerData.wrap),
        }

        for (const groupData of containerData.items) {
            let group = {
                ...groupData,
                items: [],
                style: getNotEmptyValues(groupData.style),
                wrap: getNotEmptyValues(groupData.wrap),
            }

            for (const blockData of groupData.items) {
                let block = {
                    ...blockData,
                    style: getNotEmptyValues(blockData.style),
                    wrap: getNotEmptyValues(blockData.wrap),
                }

                group.items.push(block);
            }

            container.items.push(group);
        }

        items.push(container);
    }

    return items;
}

export const handleBlockSections = (inputSections) => {
    let sections = [];

    for (const section of inputSections) {
        let newSection = {
            ...section,
            items: handleContainers(section.items),
        }

        sections.push(newSection);
    }

    return sections;
}


// TODO function: get localed value from text
// const regex = /^{locale:([a-z._-]+)}$/;
//
// // Alternative syntax using RegExp constructor
// // const regex = new RegExp('^{locale:([a-z._-]+)}$', '')
//
// const str = `{locale:segment.education.title}
// {locale:segment.education.title}`;
// let m;
//
// if ((m = regex.exec(str)) !== null) {
//     // The result can be accessed through the `m`-variable.
//     m.forEach((match, groupIndex) => {
//         console.log(`Found match, group ${groupIndex}: ${match}`);
//     });
// }