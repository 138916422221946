import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classes from "./BlockDrawers.module.scss";
import Scrollbar from "react-scrollbars-custom";
import {Button, Col, Drawer, Form, Tabs, Tooltip} from "antd";
import {
    BgColorsOutlined,
    CloudUploadOutlined,
    DownloadOutlined,
    EyeOutlined,
    HighlightOutlined,
    SettingOutlined
} from "@ant-design/icons";

import {EffectElement} from "../../Flow/components/ControlPanel/components/Container/EffectElement";
import {CheckFieldDependsOn} from "../../../components/Form/Field/FormFields";

import {setBlock, resetActive} from "../../../redux/reducers/BlockReducer";
import {deepCopyObject, downloadFileFromText, inArray, objDeepExtend, objectLength} from "../../../library/functions";
import {findAndDispatchData, renderContainerForm} from "../../../library/effects";
import {getNotEmptyValues} from "../../../library/containers";
import {handleFieldSpecValues} from "../../../library/forms";

import {dotenv} from "../../../config/config";
import {blocks} from "./Templates/blocks";
import {
    containerStyleFields,
    defaultBlockStyle,
    blockStyleFields,
    blockWrapFields,
    blockLogicFields,
    defaultBlockLogic,
} from "./StyleFields";

export const BlockDrawers = ({page, section, sectionList, sectionItems, savePage, onChange}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // data from GET params
    const [searchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')

    const store = useSelector(store => store);
    const admin = store['admin'];
    const pb = store['pb'];
    const block = pb.block;
    const active = pb.active;

    const [tab, setTab] = useState('settings');
    const [rowTab, setRowTab] = useState('style');
    // const [formValues, setFormValues] = useState({});  // crutch for upload field saving

    const getBlockByType = (type) => {
        if (!type) return {fields: []};
        const res = blocks.find(item => item.type === type);

        if (res) return res;
        return {fields: []};
    }

    let blockFields = {fields: []};
    let blockTypeLocale = block.type;

    const isContainer = inArray(block.type, ['con', 'group']);
    if (!isContainer) {
        blockFields = getBlockByType(block.type);
        blockTypeLocale = block.type ? block.type.split('/').join('.') : 'undefined';
    }

    const [form] = Form.useForm();
    const [styleForm] = Form.useForm();
    const [wrapForm] = Form.useForm();
    const [logicForm] = Form.useForm();

    // for every click on page but not block run setActiveBlock(0):
    useEffect(() => {
        // console.timeEnd("runtime");

        if (block.id) {
            // let specValues = {};
            if (!isContainer) {
                form.setFieldsValue(block.spec);
                // specValues['spec'] = block.spec;
            }

            styleForm.setFieldsValue(block.style);
            // specValues['style'] = block.style;

            wrapForm.setFieldsValue(block.wrap);
            // specValues['wrap'] = block.wrap;

            logicForm.setFieldsValue(block.logic);

            // crutch for upload field saving
            // setFormValues(specValues);
            // console.log('block.spec', block.spec)

            if (admin && page.project_id && store['field'].list && store['field'].list.filter(item => item.system_field_type === 'list').length === 0) {
                dispatch({type: 'getFieldList', admin, filters: {project_id: page.project_id, deleted_since: 0, system_field_types: ['list']}})
            }

            // dispatch menu and tags lists from DB
            if (page.project_id) findAndDispatchData(
                dispatch,
                admin,
                page.project_id,
                block.type,
                block.spec,
                'block',
                site_id,
            );
        }

        const onAnyClick = (e) => {
            if (!e.target.closest('.p-block-wrap') && block.id === undefined && objectLength(active)) {
                dispatch(resetActive());
            }
        }

        document.addEventListener('click', onAnyClick);
        return () => {
            document.removeEventListener('click', onAnyClick);

            // reset form values:
            styleForm.setFieldsValue({...defaultBlockStyle});
            wrapForm.setFieldsValue({...defaultBlockStyle});
            logicForm.setFieldsValue({...defaultBlockLogic});
            form.resetFields();
        }

    }, [admin, page, block.id]);

    const onClose = () => {
        dispatch(setBlock({}));
    };

    const onBlur = (additionalValues, currentForm, paramName = 'spec') => {
        // console.log('formValues', block[paramName])
        // let inputValues = deepCopyObject(block[paramName] || {}) || {};  // crutch for upload field saving

        // objDeepExtend(inputValues, formNewValues);
        let inputValues = currentForm.getFieldsValue();
        // console.log('onBlur', formNewValues)

        if (additionalValues) {
            objDeepExtend(inputValues, additionalValues);
            currentForm.setFieldsValue(inputValues);
        }

        // TODO: replace to page save function!
        let values = getNotEmptyValues(inputValues);

        if (onChange) {
            let containers = [];
            if (sectionItems) {
                // small optimization: section items sets on drawer open (in LayoutMaker)
                containers = sectionItems;
            }
            else {
                // for page settings find correct section by ID:
                const sectionIndex = sectionList.findIndex(item => item.id === active.sec);
                if (!sectionList[sectionIndex] || !sectionList[sectionIndex].items) {
                    console.debug('Section not found by ID: ', active.sec, 'sectionItems: ', sectionList);
                    return;
                }

                containers = [...sectionList[sectionIndex].items];
            }

            const containerIndex = containers.findIndex(item => item.id === active.con);
            const containerList = [...containers];

            if (!containerList[containerIndex] || !containerList[containerIndex].items) {
                console.debug('Container not found by ID: ', active.con, 'sectionItems: ', containerList);
                return;
            }
            const currentRow = {...containerList[containerIndex]};
            // console.log('onBlur block', paramName, block.type, block);

            if (block.type === 'con') {
                // save container styles
                containerList[containerIndex] = {...currentRow, [paramName]: values};
            } else {
                const groupList = [...currentRow.items];
                const groupIndex = currentRow.items.findIndex(item => item.id === active.group);

                if (!groupList[groupIndex] || !groupList[groupIndex].items) {
                    console.debug('Group not found by ID: ', active.group, 'sectionItems: ', groupList);
                    return;
                }
                const currentCol = {...groupList[groupIndex]};

                if (block.type === 'group') {
                    // save group styles
                    groupList[groupIndex] = {...currentCol, [paramName]: values};
                    containerList[containerIndex] = {...currentRow, items: groupList};
                } else {
                    // save block styles or spec
                    const blocklist = [...groupList[groupIndex].items];
                    const blockIndex = currentCol.items.findIndex(item => item.id === block.id);

                    if (!blocklist[blockIndex]) return;

                    blocklist[blockIndex] = {...block, [paramName]: values};
                    groupList[groupIndex] = {...currentCol, items: blocklist};
                    containerList[containerIndex] = {...currentRow, items: groupList};
                }
            }
            // newList[sectionIndex] = {...sectionItems[sectionIndex], items: containerList};

            // console.log('setBlock', block.id, paramName, containerList[containerIndex])
            dispatch(setBlock({...block, [paramName]: values}));
            onChange(containerList, active.sec);
        }
    };

    const onBlockBlur = (additionalValues) => {
        onBlur(additionalValues, form, 'spec');
    }

    const onBlockStyleBlur = (additionalValues) => {
        onBlur(additionalValues, styleForm, 'style');
    }

    const onBlockWrapBlur = (additionalValues) => {
        onBlur(additionalValues, wrapForm, 'wrap');
    }

    const onBlockLogicBlur = (additionalValues) => {
        onBlur(additionalValues, logicForm, 'logic');
    }

    const renderCommonField = (localId, defaultLocale, form, specState, runSave, fieldCol, width, key, container) => {
        let field = deepCopyObject(fieldCol);
        // if (field.data) console.log('render renderCommonField', field)
        const depend_result = CheckFieldDependsOn(field, specState);
        // console.log('spec', depend_result, field, specState)

        // nodes field
        field = handleFieldSpecValues(t, store, field);

        return <Col className={depend_result ? '' : 'hide'} span={width} key={key}>
            <EffectElement
                t={t}
                index={key}
                form={form}
                admin={store['admin']}
                project={store['project']}
                field={field}
                localId={localId}
                effectType={defaultLocale}
                // localeRoot={defaultLocale.split('/').join('.')}
                spec={specState}
                runSave={runSave}
                container={container}
                section="page"
            />
        </Col>
    }

    const renderRowStyleField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/style',
            styleForm,
            block.style,
            onBlockStyleBlur,
            fieldCol,
            width,
            key,
            'container-settings'
        );
    }

    const renderStyleField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/style',
            styleForm,
            block.style,
            onBlockStyleBlur,
            fieldCol,
            width,
            key,
            'block-settings'
        );
    }

    const renderRowWrapField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/style',
            wrapForm,
            block.wrap,
            onBlockWrapBlur,
            fieldCol,
            width,
            key,
            'container-settings'
        );
    }

    const renderBlockWrapField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/style',
            wrapForm,
            block.wrap,
            onBlockWrapBlur,
            fieldCol,
            width,
            key,
            'block-settings'
        );
    }

    const renderBlockLogicField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/logic',
            logicForm,
            block.logic,
            onBlockLogicBlur,
            fieldCol,
            width,
            key,
            'block-settings'
        );
    }

    const renderSpecField = (fieldCol, width = 24, key = 0) => {
        return renderCommonField(
            block.id,
            'block/' + block.type,
            form,
            block.spec,
            onBlockBlur,
            fieldCol,
            width,
            key,
            'block-settings'
        );
    }

    const exportContainer = () => {
        let containers = [];
        if (sectionItems) {
            // small optimization: section items sets on drawer open (in LayoutMaker)
            containers = sectionItems;
        }
        else {
            // for page settings find correct section by ID:
            const sectionIndex = sectionList.findIndex(item => item.id === active.sec);
            if (!sectionList[sectionIndex] || !sectionList[sectionIndex].items) {
                console.debug('Section not found by ID: ', active.sec, 'sectionItems: ', sectionList);
                return;
            }

            containers = [...sectionList[sectionIndex].items];
        }

        // console.info('exportContainer', active.con, active.group, active.block, containers);

        const containerIndex = containers.findIndex(item => item.id === active.con);
        const containerData = containers[containerIndex];

        let container = {
            type: 'con',
            items: [],
            style: getNotEmptyValues(containerData.style),
            wrap: getNotEmptyValues(containerData.wrap),
            logic: getNotEmptyValues(containerData.logic),
        }

        for (const contItem of containerData.items) {
            let group = {
                type: 'group',
                items: [],
                style: getNotEmptyValues(contItem.style),
                wrap: getNotEmptyValues(contItem.wrap),
                logic: getNotEmptyValues(contItem.logic),
            }

            for (const groupItem of contItem.items) {
                let block = {
                    type: groupItem.type,
                    spec: getNotEmptyValues(groupItem.spec),
                    style: getNotEmptyValues(groupItem.style),
                    wrap: getNotEmptyValues(groupItem.wrap),
                    logic: getNotEmptyValues(groupItem.logic),
                }

                if (groupItem.ignore) block['ignore'] = groupItem.ignore;
                group.items.push(block);
            }

            container.items.push(group);
        }

        const itemDataJson = JSON.stringify([container]);
        const fileName = dotenv.app_name + '-container-' + page.id + '-' + active.con + '-' + Date.now();
        downloadFileFromText(itemDataJson, fileName, 'json');
    }

    const renderDrawerTitle = (localeConst, itemId, prefix = '') => {
        return <span>
            {t(localeConst)} #<span className="copy-on-click">{prefix}{itemId}</span>
            {prefix === 'c' ? <Tooltip title={t('page.item.menu.export_container')} placement="left">
                <Button
                    type="text"
                    icon={<DownloadOutlined/>}
                    className="inverted float-right margin-right-xp ant-drawer-close"
                    onClick={exportContainer}
                    style={{marginTop: -3}}
                />
            </Tooltip> : null}
            {savePage ? <Tooltip title={t(section + '.item.menu.save')} placement="left">
                <Button
                    type="text"
                    icon={<CloudUploadOutlined />}
                    className="inverted float-right margin-right-xs ant-drawer-close"
                    onClick={savePage}
                    style={{marginTop: -3}}
                />
            </Tooltip> : null}
        </span>
    }

    return (<>
            <Drawer
                title={renderDrawerTitle(
                    'block.drawer.title.' + blockTypeLocale,
                    block.id,
                    (block.type || 'n')[0],
                )}
                className={`container-settings ${classes.drawer} ${classes.raw}`}
                open={isContainer && block.id}
                destroyOnClose={false}
                onClose={onClose}
                width={570}
            >
                <Tabs
                    activeKey={rowTab}
                    onChange={setRowTab}
                    className="padding-left-sm padding-right-sm"
                    // className="tabs-buttons"
                    size="medium"
                    items={[
                        {
                            label: <span>
                                <BgColorsOutlined/>
                                <span className="hidden-xs">{t('block.drawer.tab.style')}</span>
                            </span>,
                            key: 'style',
                        },
                        {
                            label: <span>
                                <HighlightOutlined />
                                <span className="hidden-xs">{t('block.drawer.tab.wrap')}</span>
                            </span>,
                            key: 'wrap',
                        },
                    ]}
                />

                <Scrollbar>
                    <div id="container-settings" className="padding_default">
                        <div className={rowTab === 'style' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                styleForm,
                                block.style,
                                containerStyleFields,
                                renderRowStyleField
                            )}
                        </div>

                        <div className={rowTab === 'wrap' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                wrapForm,
                                block.wrap,
                                blockWrapFields,
                                renderRowWrapField
                            )}
                        </div>
                    </div>
                </Scrollbar>
            </Drawer>

            <Drawer
                title={renderDrawerTitle('block.' + blockTypeLocale + '.title', block.id, 'b')}
                className={`block-settings ${classes.drawer} ${classes.raw}`}
                open={!isContainer && block.id}
                onClose={onClose}
                destroyOnClose={true}
                width={570}
            >
                <Tabs
                    activeKey={tab}
                    onChange={setTab}
                    className="padding-left-sm padding-right-sm"
                    // className="tabs-buttons"
                    size="medium"
                    items={[
                        {
                            label: <span>
                                <SettingOutlined/>
                                <span className="hidden-xs">{t('block.drawer.tab.settings')}</span>
                            </span>,
                            key: 'settings',
                        },
                        {
                            label: <span>
                                <BgColorsOutlined/>
                                <span className="hidden-xs">{t('block.drawer.tab.style')}</span>
                            </span>,
                            key: 'style',
                        },
                        {
                            label: <span>
                                <HighlightOutlined />
                                <span className="hidden-xs">{t('block.drawer.tab.wrap')}</span>
                            </span>,
                            key: 'wrap',
                        },
                        {
                            label: <span>
                                <EyeOutlined />
                                <span className="hidden-xs">
                                    {
                                        'Функции'
                                        // t('block.drawer.tab.wrap')
                                    }
                                </span>
                            </span>,
                            key: 'logic',
                        },
                    ]}
                />

                <Scrollbar>
                    <div id="block-settings" className="padding_default">
                        <div className={tab === 'settings' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                form,
                                block.spec,
                                blockFields,
                                renderSpecField
                            )}
                        </div>

                        <div className={tab === 'style' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                styleForm,
                                block.style,
                                blockStyleFields,
                                renderStyleField
                            )}
                        </div>

                        <div className={tab === 'wrap' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                wrapForm,
                                block.wrap,
                                blockWrapFields,
                                renderBlockWrapField
                            )}
                        </div>

                        <div className={tab === 'logic' ? null : 'hide'}>
                            {renderContainerForm(
                                page.id,
                                block.id,
                                logicForm,
                                block.logic,
                                blockLogicFields,
                                renderBlockLogicField
                            )}
                        </div>
                    </div>
                </Scrollbar>
            </Drawer>
        </>
    )
}