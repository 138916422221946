import {createSlice} from "@reduxjs/toolkit";

const AnswerReducer = createSlice({
    name: 'answer',
    initialState: {
        list: [],
        item: {},
    },
    reducers: {
        fillAnswerList(state, action) {
            state.list = [...action.payload]
        },
        setAnswerItem(state, action) {
            state.item = action.payload;
        },
        addAnswerToList(state, action) {
            state.item = action.payload;
            state.list = [...state.list, action.payload]
        },
        patchAnswerInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
        },
        removeAnswerFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = [...state.list];
            state.list.length = 0; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        clearAnswerItem(state) {
            state.item = {};
        },
        clearAnswerReducer(state) {
            state.list = [];
            state.item = {};
        },
    }
})

export default AnswerReducer.reducer
export const {
    fillAnswerList,
    setAnswerItem,
    addAnswerToList,
    patchAnswerInList,
    removeAnswerFromList,
    clearAnswerItem,
} = AnswerReducer.actions