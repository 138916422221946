import React, {useContext, useLayoutEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import Scrollbar from "react-scrollbars-custom";
import {QuestionOutlined} from "@ant-design/icons";
import classes from "./ControlPanel.module.scss";
import {nodeTypesProps} from "../../library/flowFunctions";
import Header from "./components/Header/Header";
import NodeTitle from "./components/NodeTitle/NodeTitle";
import ContainerList from "./components/ContainerList/ContainerList";
import Actions from "./components/Catalog/Actions/Actions";
import Events from "./components/Catalog/Events/Events";
import Conditions from "./components/Catalog/Conditions/Conditions";
import FlowContext from "../../FlowContext";

const ControlPanel = ({children, chosenNode, isPanelFixed = false, isInline = false}) => {
    const {t} = useTranslation();
    const scrollRef = useRef();
    const {controlPanel, setControlPanel, isBlock} = useContext(FlowContext);

    if (!chosenNode) return null;

    let isPanelOpen = !!(chosenNode && chosenNode.localId) || controlPanel === 'history';
    if (isPanelFixed) isPanelOpen = true;

    const wrapperClassName = isInline ? classes.control_panel_inline : classes.control_panel_wrapper;
    const controlPanelClasses = ['effects-list', chosenNode.type + '-panel', wrapperClassName];
    if (chosenNode && chosenNode.type === 'note') controlPanelClasses.push(classes.control_panel_mini);

    // if (chosenNode && chosenNode.type) {
    //     // Open containers buttons if node has no containers (except Block mode)
    //     if (chosenNode.type === 'event' && (!chosenNode.events || !chosenNode.events.length) && !isBlock) {
    //         setControlPanel('events');
    //     } else if (chosenNode.type === 'action' && (chosenNode.effects.length < 2) && !isBlock) {
    //         setControlPanel('actions');
    //     }
    // }

    const ContainerListWrapper = () => {
        let nodeLocalId = 0, nodeType = 'none', nodeIcon = <QuestionOutlined/>;
        if (chosenNode && chosenNode.localId) {
            nodeLocalId = chosenNode.localId;
            nodeType = chosenNode.type;
            nodeIcon = nodeTypesProps[nodeType].icon;
        }

        const nodeTitle = ('title' in chosenNode && chosenNode.title) ? chosenNode.title : t('graph.flow.node.' + nodeType + '.title');

        return isBlock ? <ContainerList/> :
            <>
                <Header nodeLocalId={nodeLocalId} title={nodeTitle} icon={nodeIcon} type={nodeType}/>
                <div className={classes.control_panel}>
                    {nodeType === 'note' ?
                        <div className={classes.note_title_wrapper}>
                            <NodeTitle chosenNode={chosenNode}/>
                        </div> :
                        <Scrollbar ref={scrollRef}>
                            {(nodeLocalId && nodeType !== 'none') ?
                                <div className={classes.dash_board_wrapper} data-node-type={nodeType}>
                                    {/*{ dashBoard() }*/}
                                    <NodeTitle chosenNode={chosenNode}/>
                                </div> : null}

                            {/*<DashBoard chosenNode={chosenNode}/>*/}
                            <ContainerList/> {/*instead of <Content showPanel={panel}/>*/}
                        </Scrollbar>}
                </div>
            </>
    }

    const ControlPanelContent = () => {
        switch (controlPanel) {
            case 'actions':
                return <Actions isBlock={isBlock}/>;
            case 'events':
                return <Events isBlock={isBlock}/>;
            case 'conditions':
                return <Conditions isBlock={isBlock}/>;
            case 'history':
                return 'Sorry. Not ready yet';
            default:
                return ContainerListWrapper();
        }
    }

    return (
        <div id='graph-control-panel' className={controlPanelClasses.join(' ')} style={isPanelOpen ? {right: 0} : {}}>
            {children} {/* zoom buttons */}
            {ControlPanelContent()}
        </div>
    );
}

export default ControlPanel;