import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout, Menu} from "antd";
import {UploadOutlined} from "@ant-design/icons";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {LayoutListTable} from "./LayoutListTable";
import {
    createObjectFromObjectsArray,
    inArray,
    objectLength,
    onFolderOpen,
    toNum,
    ucFirst
} from "../../../library/functions";
import {ListFolderManager} from "../../../components/List/Folder/ListFolderManager";
import {useTranslation} from "react-i18next";
import ImportModal from "../../../components/Modal/ImportModal";
import {notice} from "../../../library/notice";
import {LayoutsModal} from "../Add/LayoutsModal";

const {Content} = Layout;

const LayoutList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'layout'
    const Section = ucFirst(section)

    const adminSection = section
    const categorySection = 'site'

    // data from GET params
    const [searchParams, setSearchParams] = useSearchParams();
    const site_id = searchParams.get('site_id')
    const folder_id = toNum(searchParams.get('folder'));

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store receive
    const {admin, project, layout, site} = useSelector(store => store)
    const categoryList = site.list || []
    const categories = createObjectFromObjectsArray(categoryList);

    // states init
    const [filters, setFilters] = useState({})
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [folderId, setFolderId] = useState(folder_id)

    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleImportModal, setVisibleImportModal] = useState(false);

    const filterFields = [
        [
            {
                name: "types",
                type: "tags",
                placeholder: t('common.placeholder.menu'),
                section: section,
                values: [
                    'page',
                    'landing',
                    'form',
                    'category',
                    'section',
                    'system',
                ],
                localized: true,
            },
            {
                name: 'site_id',
                type: 'menu',
                localized: false,
                values: categories,
                placeholder: t('common.placeholder.menu')
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off']
            },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range',
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    // init universal vars
    const project_item = project.item
    const list = layout.list || []
    const sorter = layout.sorter
    // const rowsTotal = layout.count || 0
    // const currentPage = layout.page || 1
    // const pageSize = layout.pageSize || 10

    useLayoutEffect(() => {
        if (site_id) setFilters({site_id: site_id, ...filters})
        //eslint-disable-next-line
    }, [site_id])

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                ordering: ordering,
                page_size: 0,
                // page_number: currentPage,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}})

            // for installation modal
            if (layout.short.list.length === 0 || layout.short.list[0].project_id !== project_item.id) {
                dispatch({type: 'getLayoutShortList', admin, filters: {project_id: project_item.id, ordering: 'id'}});
            }

            // page types - menu items for new page modal
            if (layout.public.list.length === 0) {
                // console.info('Get layout public list - PAGE ITEM')
                dispatch({type: 'get' + Section +  'PublicList', admin, filters: {is_on: true, ordering: 'created_at'}});
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordEdit = (folder_id = 0, itemId = 0) => {
        let url = `${routes.project_list}/${project_item.id}/${section}/edit/${itemId}?folder=${folder_id}`
        if (filters.site_id) url += `&site_id=${filters.site_id}`
        navigate(url)
    }

    const recordAdd = (folder_id = 0) => {
        setFolderId(folder_id)
        setVisibleModal(true)
    }

    const onLayoutAdd = (layoutName) => {
        console.log('layoutName', layoutName)
    }

    const onLayoutInstall = (layoutName) => {
        if (!layoutName) {
            recordEdit(folderId, 0)
            return
        }

        // console.log('layoutName', layoutName, parentId)
        dispatch({type: 'install' + Section, admin, action: 'create', data: {
            project_id: project_item.id,
            folder_id: folderId,
            site_id: filters.site_id ?? 0,
            name: layoutName,
        }})

        onFolderOpen(folderId, true, searchParams, setSearchParams)
        setVisibleModal(false)
        setFolderId(0)
    }

    const recordCreate = (folder_id = 0, data = null) => {
        if (!data || typeof data !== 'object') {
            notice.info(t('common.import.error.empty'));
            return;
        }

        data['project_id'] = project_item.id;
        data['folder_id'] = folder_id;
        data['name'] = null;  // allow import one layout many times
        data['is_on'] = true;

        dispatch({type: 'create' + Section, admin, data});

        // and open folder after create
        if (typeof folder_id !== 'number') folder_id = 0
        onFolderOpen(folder_id, true, searchParams, setSearchParams)
    }

    const recordImport = () => {
        if (!fileContent) {
            notice.info(t('common.import.error.empty'));
            return;
        }
        try {
            const data = JSON.parse(String(fileContent));
            if (inArray(undefined, [data.form, data.logic, data.layout, data.params])) {
                notice.error(t('common.import.error.content'));
                return;
            }

            // console.log('Import Content', data);
            recordCreate(folder_id, data);
        } catch (e) {
            console.log('Import data parse error', e);
            notice.error(t('common.import.error.format'));
        }
    };

    const onMenuClick = (e) => {
        if (e.key === 'import') {
            setVisibleImportModal(true);
            setFileList([]);
            setFileContent('');
        }
    };

    const createMenu = (<Menu
            onClick={onMenuClick}
            items={[
                {
                    key: 'import',
                    label: t('common.action.import'),
                    icon: <UploadOutlined/>
                },
            ]}
        />
    );

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    title={t(section + '.list.title') + (filters.site_id ? `: ${categories[filters.site_id] || '(?)'}` : '')}
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    primaryLabel={t('common.action.add') + ' ' + t(section + '.object.title')}
                    onPrimaryWithFolder={recordAdd}
                    onSearch={searchHandler}
                    primaryMenu={createMenu}
                />
                <Content className="page-container site-layout-background">
                    <LayoutListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        adminSection={adminSection}
                        list={list}
                        categoriesMap={categories}
                        filters={filters}
                        recordCreate={recordAdd}
                    />
                    <ListFolderManager section={section} project_item={project_item} list={list}/>
                </Content>

                <LayoutsModal
                    t={t}
                    section={section}
                    title={t(section  + '.item.modal.title')}
                    isVisible={isVisibleModal}
                    setVisible={setVisibleModal}
                    onAdd={onLayoutAdd}
                    onInstall={onLayoutInstall}
                    list={layout.short.list}
                    publicList={layout.public.list}
                />

                <ImportModal
                    t={t}
                    onOk={recordImport}
                    section="common"
                    isVisibleModal={isVisibleImportModal}
                    setVisibleModal={setVisibleImportModal}
                    fileList={fileList}
                    setFileList={setFileList}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    fileFormat="json"
                />
            </Layout>
        </AppWrapper>
    )
}
export default LayoutList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link