import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import classes from './ButtonsPublishExit.module.scss'
import DropBox from "./DropBox";
import {CheckOutlined, DoubleLeftOutlined, LoadingOutlined, SettingOutlined, CaretRightOutlined} from "@ant-design/icons";
import SearchNode from "../SearchNode/SearchNode";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {images, dotenv, routes} from "../../../../../config/config";
import {objectLength} from "../../../../../library/functions";
import {NavigateBtn, NavigateLink} from "../../../../../components/Navigate/Navigate";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import {changeGraphStatus} from "../../../../../redux/reducers/GraphReducer";

let logo = images.logo_icon;
let origTitle = '', currentTitle = '';
const ButtonsPublishExit = ({nodes, flowNodes, admin, project, folderId, graph, graph_id, saveGraph, createNewGraph}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [editTitle, setEditTitle] = useState(false);

    const graph_list = graph.list;
    const graph_item = graph.item;
    const graph_status = graph.status;
    const savingIcon = graph_status.editing ? <LoadingOutlined/> : <CheckOutlined className="color-success"/>;

    const graph_list_url = `${routes.project_list}/${project.item.id}${routes.local.graph_list}/?folder=${folderId}`;
    const graph_edit_url = `${routes.project_list}/${project.item.id}${routes.local.graph_list}/edit/${graph_item.id}?folder=${folderId}`;

    let project_photo = images.avatar.project;
    if (objectLength(project.item) && project.item.photo_url) project_photo = project.item.photo_url;

    useEffect(() => {
        if (editTitle) {
            const input_element = getInputElement()
            input_element.focus();
            input_element.scrollIntoView();
        }
    }, [editTitle])

    // можно список процессов для данной папки получить таким ↓ образом. но это лишний ререндер.
    // dispatch({type: 'getGraphList', admin, filters: { project_id: process.process_id.project_id, folder_ids: [folderId] }})

    // в store.graph уже есть список всех графов и у нас есть id папки - лучше выберем список нужных графов обычным образом
    let graphsList = [], dropMenuId = 0;

    if (graph_list.length) {     // иначе, ошибки, типа "id from undefined"
        graph_list.forEach(p => {
            if (p.folder_id === folderId) {
                graphsList.push({id: p.id, title: p.title, is_on: p.is_on, icon_name: p.icon_name, pid: p.project_id});
            }
        });
        dropMenuId = graphsList.findIndex(pl => pl.id === Number(graph_id));
    }

    const onKeyUp = (e) => {
        if (editTitle) {
            e.preventDefault();
            if (e.keyCode === 13) buttonOk();
            if (e.keyCode === 27) buttonCancel();
        }
    }

    const onContextMenu = (e) => e.preventDefault()

    const getInputElement = () => {
        return document.getElementById('input_process_title_editor');
    }

    const onClickEdit = () => {
        if (editTitle) {
            setEditTitle(false);                                // снова кликнули по шестеренке ничего не делая
        } else {
            const input_element = getInputElement()
            if (input_element && graphsList[dropMenuId].title) {
                input_element.value = graphsList[dropMenuId].title;
                origTitle = graphsList[dropMenuId].title;
                currentTitle = graphsList[dropMenuId].title;
                setEditTitle(true);
            }
        }
    }

    const onChangeTitle = (e) => {
        currentTitle = e.target.value;
    }

    const buttonOk = () => {
        if (editTitle && currentTitle !== origTitle) {
            const data = {
                id: graph_item.id,
                project_id: graph_item.project_id,
                title: currentTitle,
            }
            dispatch({type: 'updateGraph', admin, data});
        }
        setEditTitle(false);
    }

    const buttonCancel = () => {
        setEditTitle(false);
    }

    const publishGraph = () => {
        const data = {
            id: graph_item.id,
            project_id: graph_item.project_id,
            is_on: true,
        }
        dispatch({type: 'updateGraph', admin, data});
    }

    const onSaveClick = () => {
        saveGraph(true);
        dispatch(changeGraphStatus({
            editing: true,
            edited: false,
            redrawn: false,
        }));
    }

    const SavePublishButton = () => {
        if (graph_status.redrawn) {
            return <Button
                className="float-right"
                type="primary"
                size="large"
                icon={<CheckOutlined/>}
                onClick={onSaveClick}
            >
                <span className={classes.hidden_lg}>{t('common.action.save')}</span>
            </Button>
        } else if (!graph_item.is_on) {
            return <Button
                size="large"
                type="primary"
                className="btn-colored btn-success"
                htmlType="submit"
                onClick={publishGraph}
                icon={<CaretRightOutlined />}
            >
                <span className={classes.hidden_lg}>{t('common.action.turn_on')}</span>
            </Button>
        } else {
            return <Button className="float-right" type="text" size="large" icon={savingIcon} onClick={onSaveClick}>
                <span className={classes.hidden_lg}>{t('common.status.neuter.saved')}</span>
            </Button>
        }
    }

    return (
        <>
            <div className={classes.wrapper_title_editor} style={{display: editTitle ? 'flex' : 'none'}}>
                <div className={classes.wrapper_input}>
                    <input id='input_process_title_editor' className={classes.input_title} type='text' autoFocus={true}
                           tabIndex='-1' autoComplete='off'
                           onChange={onChangeTitle} onKeyUp={onKeyUp}/>
                </div>
                <div className={classes.wrapper_button}>
                    <div className={classes.button_confirm} onClick={buttonOk}><AiOutlineCheck
                        style={{width: 18, height: 18, color: 'green'}}/></div>
                    <div className={classes.button_confirm} onClick={buttonCancel}><AiOutlineClose
                        style={{width: 18, height: 18, color: 'red'}}/></div>
                </div>
            </div>

            <div className={classes.wrap_group_buttonlt} onContextMenu={onContextMenu}>
                <div className={classes.left_box}>
                    <NavigateBtn
                        link={graph_list_url}
                        title={t('graph.flow.back.title')}
                        className={classes.icon_button}
                    >
                        <DoubleLeftOutlined/>
                    </NavigateBtn>
                </div>
                <div className={`${classes.middle_box} ${classes.hidden_lg_plus}`}>
                    <NavigateLink
                        link={dotenv.main_page}
                        title={t('graph.flow.logo.title')}
                        className={classes.wrap_logo}
                    >
                        <img className={classes.plane_pict} src={logo} alt={'logo'}/>
                        <div className={classes.plane_text}>{dotenv.public_name}</div>
                    </NavigateLink>
                    <NavigateLink
                        link={graph_list_url}
                        title={t('graph.flow.project.title')}
                        className={classes.block_icon}
                    >
                        <img id='left_box_icon_avatar' className={classes.avatar} src={project_photo} alt="Проект"/>
                    </NavigateLink>
                </div>

                <div className={classes.middle_box}>
                    <DropBox dropMenuId={dropMenuId} processList={graphsList} createNewGraph={createNewGraph}/>
                    <NavigateBtn
                        link={graph_edit_url}
                        // onContextMenu={onClickEdit}
                        id='setting_button_middle_box'
                        className={classes.setting_button_middle_box}
                        title={t('graph.flow.settings.title')}
                    >
                        <SettingOutlined/>
                    </NavigateBtn>
                </div>

                <div className={classes.right_box}>
                    <div className={classes.left_div}/>
                    <div className={`${classes.right_div} ${classes.hidden_lg}`}/>
                    <SearchNode
                        nodes={nodes}
                        elements={flowNodes}
                        title={t('graph.flow.search.title')}
                    />
                </div>

                {SavePublishButton()}
            </div>
        </>
    )
}
export default ButtonsPublishExit
