import React from 'react'
import classes from './Universe.module.scss'
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Col, Layout, Row, Typography} from "antd";
import {Banner} from "../../components/Banner/Banner";
import {Content} from "antd/es/layout/layout";
import {l} from "../../library/locale";
import {routes, images, dotenv} from "../../config/config";
import {useLocation} from "react-router-dom";
import {NavigateLink} from "../../components/Navigate/Navigate";

const {Title} = Typography;

const Universe = () => {
    const pathname = useLocation().pathname;
    const projects_link = routes.project_list;
    const account_link = routes.admin.account;

    if (pathname === routes.auth) {
        window.history.replaceState(null, "Universe", dotenv.main_page);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background account-item">
                <Banner image="universe"/>
                {/*<CustomHeader*/}
                {/*    key="universe-header"*/}
                {/*    className={'padding-none-horizontal'}*/}
                {/*    title={l('universe.title')}*/}
                {/*/>*/}
                <Content className="page-container site-layout-background">
                    <Row gutter={20} justify="center" className={`${classes.row} margin-top-md`}>
                        <Col xs={12} sm={8} className={classes.col}>
                            <NavigateLink link={account_link} className={classes.link}>
                                <img src={images.universe.planet.account} alt="Account"/>
                            </NavigateLink>
                            <Title className={`${classes.title} margin-top-sm`}
                                   level={3}>{l('universe.planet.account.title')}</Title>
                            <p className={classes.description}>{l('universe.planet.account.desc')}</p>
                        </Col>
                        <Col xs={12} sm={8} className={classes.col}>
                            <NavigateLink link={projects_link} className={classes.link}>
                                <img src={images.universe.planet.projects} alt="Projects"/>
                            </NavigateLink>
                            <Title className={`${classes.title} margin-top-sm`}
                                   level={3}>{l('universe.planet.projects.title')}</Title>
                            <p className={classes.description}>{l('universe.planet.projects.desc')}</p>
                        </Col>
                        <Col xs={12} sm={8} className={classes.col}>
                            <a href={dotenv.help_url} target="_blank" className={classes.link}>
                                <img src={images.universe.planet.knowledge} alt="Knowledge"/>
                            </a>
                            <Title className={`${classes.title} margin-top-sm`}
                                   level={3}>{l('universe.planet.help.title')}</Title>
                            <p className={classes.description}>{l('universe.planet.help.desc')}</p>
                        </Col>
                        {/*<Col xs={24} sm={8} gutter={1}  className="align-center" >*/}
                        {/*    <NavigateLink link={null} className={classes.link}>*/}
                        {/*        <img src={images.universe.planet.rating} alt="Rating"/>*/}
                        {/*    </NavigateLink>*/}
                        {/*    <Title className="margin-top-sm" level={3}>{l('universe.planet.rating.title')}</Title>*/}
                        {/*    <p className={classes.description}>{l('universe.planet.rating.desc')}</p>*/}
                        {/*</Col>*/}
                    </Row>

                    {/*{welcome ? <VideoModal
                        url={dotenv.welcome_video}
                        buttonStyle="dark"
                        title={l('universe.welcome.video.title')}
                        isVisibleDefault={true}
                        controls={false}
                        onOk={storeBoardingStatus}
                        onCancel={storeBoardingStatus}
                    />: null}*/}

                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default Universe