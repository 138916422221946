import React from 'react'
import {useDispatch} from "react-redux";
import ListTable from "./ListTable";
import {setFolderItemById} from "../../../redux/reducers/FolderReducer";
import {setSiteItemById} from "../../../redux/reducers/SiteReducer";

const FolderTable = (
    {
        folder,
        items,
        columns,
        rowSelection,
        recordCreate,
        onFolderOpen,
        className = '',
        folderType = 'folder',
        opened = true,
        showHeader = false,
        pagination = false,
        createRecordComponent = null,
    }
) => {

    const dispatch = useDispatch();

    const folderEdit = (id) => {
        if (folderType === 'game_folder') dispatch(setSiteItemById(id));
        else if (folderType === 'folder') dispatch(setFolderItemById(id));
    }

    return <ListTable
        showHeader={showHeader}
        pagination={pagination}
        rowSelection={rowSelection}
        className={className}
        folder={{
            show: true,
            id: folder.id,
            text: folder.title,
            deleted_at: folder.deleted_at,
            opened: opened,
            recordCreate: recordCreate,
            folderEdit: folderEdit,
            createRecordComponent: createRecordComponent,
            onOpen: onFolderOpen,
            type: folderType,
        }}
        columns={columns}
        dataSource={items}
    />
}

export default FolderTable