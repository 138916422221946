import React from 'react'
import {Select, Typography} from 'antd';
import Fa from "./Fa";
import {Icons, Brands} from "./List";

const {Text} = Typography;
const {Option} = Select;

// let icons = []
// let symbol_icons = []
// let number_icons = []
//
// // prepare icons list once
// for (const [icon, icon_object] of Object.entries(Icons)) {
//     // numbers to the end
//     if (parseInt(icon) >= 0){
//         number_icons.push(icon);
//         continue;
//     }
//
//     // symbols to the end too
//     if (icon.length === 1){
//         symbol_icons.push(icon);
//         continue;
//     }
//
//     // add icon to array
//     icons.push(icon);
//
//     const synonyms = icon_object[2].filter(function (s) {
//         return typeof s === 'string' && parseInt(s) < 0
//     });
//
//     for (const synonym of synonyms) {
//         icons.push(synonym);
//     }
// }
//
// // prettify list and combine arrays
// icons = icons.sort();
// icons = icons.concat(symbol_icons, number_icons, ['100']);
// window.iconsSet = icons

export const IconsOptions = (set = 'regular') => {
    let rows = [];
    const icons = set === 'brands' ? Brands : Icons;
    for (const i of icons) {
        rows.push(
            <Option value={i} key={`select-icon-${i}`}>
                <Fa icon={i} set={set} />
                <Text type="secondary" style={{marginLeft: 12}}>{i}</Text>
            </Option>
        );
    }
    return rows
}

const mainColors = [
    // "slate",
    // "zinc",
    'gray',
    "neutral",
    "stone",

    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "green",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "blue",
    "indigo",
    "violet",
    "purple",
    "fuchsia",
    "pink",
    "rose"
];

const systemColors = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
]

const ColorsTonesMap = {
    base: {
        colors: ['transparent', 'white', 'black'],
        tones: [''],
    },
    system: {
        colors: systemColors,
        tones: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    },
    main: {
        colors: mainColors,
        tones: [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950],
    },
}

export const ColorOptions = (t) => {
    let rows = [];

    for (const pack of Object.keys(ColorsTonesMap)) {
        const obj = ColorsTonesMap[pack];
        for (const color of obj.colors) {
            for (const tone of obj.tones) {
                let colorName = color;
                if (tone) colorName += '-' + tone;

                rows.push(
                    <Option className="tw" value={colorName} key={`select-color-${color}-${tone}`}>
                        <Fa
                            icon="circle"
                            set="solid"
                            className={`text-${colorName}${pack === 'base' ? ' border-1-dark' : ''}`}
                        />
                        <Text type="secondary" style={{marginLeft: 12}}>
                            {t('common.form.color.value.' + color)}{tone ? ' ' + tone : ''}
                        </Text>
                    </Option>
                );
            }
        }
    }

    return rows
}

