import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Divider, Tooltip, Typography} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined, 
    EyeInvisibleOutlined, 
    EyeOutlined, 
    SettingOutlined,
} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {routes} from "../../config/config";
import {ucFirst} from "../../library/functions";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import ListTable from "../../components/List/Table/ListTable";
import {TableAvatar} from "../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../library/clipboard";
import {SorterWrapper} from "../../components/List/Table/SorterWrapper";
import {setSectStoreParam} from "../../redux/reducers/SectReducer";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const SectionListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 10,
    }
) => {
    const Section = ucFirst(section)
    const {t} = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${record.id}`)
    const recordOpen = (record) => recordEdit(record)
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id})
    const recordRestore = (id) => dispatch({type: 'restore' + Section, admin, data: {id}})

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar',
            title: <SorterWrapper>&nbsp;&nbsp;{t('common.table.header.id')}</SorterWrapper>,
            width: 70,
            sorter: true,
            responsive: ['sm'],
            render: (value, record) => <TableAvatar
                imageUrl={record.photo_url}
                className="w-34"
                icon={record.icon}
                title={value}
                isOn={record.status}
            />,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            title: <SorterWrapper>{t('common.table.header.label')}</SorterWrapper>,
            sorter: true,
            render: (title, record) => (<>
                <Text className="block" type={record.status ? "default" : "danger"}>{title}</Text>
                <Text type="secondary" className="block font-size-sm">{record.name}</Text>
            </>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },

        {
            dataIndex: 'section',
            className: 'cursor-pointer text-secondary',
            title: t(section + '.list.header.section'),
            responsive: ['xl'],
            width: 110,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.section, t('common.result.copied.neuter'))
                };
            },
        },
        {
            dataIndex: 'category',
            className: 'cursor-pointer text-secondary ',
            title: <SorterWrapper>{t(section + '.list.header.category')}</SorterWrapper>,
            sorter: true,
            responsive: ['lg'],
            width: 110,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.category, t('common.result.copied.neuter'))
                };
            },
        },
        {
            dataIndex: 'order',
            className: 'cursor-pointer text-secondary align-center',
            title: <SorterWrapper>{t(section + '.list.header.number')}</SorterWrapper>,
            sorter: true,
            // responsive: ['md'],
            width: 110,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.order, t('common.result.copied.neuter'))
                };
            },
        },
        {
            key: 'actions',
            responsive: ['sm'],
            width: 105,
            render: (text, record) => (
                <div className="table-row-buttons">
                    <Tooltip title={record.status ? t('table.icon.off') : t('table.icon.on')}>
                        <Button
                            type="text"
                            onClick={() => recordChangeStatus(record.id, !record.status)}
                            icon={record.status ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        />
                    </Tooltip>
                    <Tooltip title={t('table.icon.duplicate')}>
                        <Button
                            type="text"
                            onClick={() => recordCopy(record.id)}
                            icon={<CopyOutlined/>}
                        />
                    </Tooltip>
                    <Tooltip title={t('table.menu.edit')}>
                        <Button
                            type="text"
                            icon={<SettingOutlined/>}
                            onClick={() => {
                                recordEdit(record)
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuCopy',
            label: t('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        {
            type: 'divider',
            render: (record) => <Divider className="margin-none-vertical" type="horizontal"/>,
            action: (record) => null
        },
        {
            key: 'tableMenuDelete',
            label: t('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        const stat = record.stat || {}
        data.push({
            id: record.id,
            key: record.id + '-listRow',

            title: record.title,
            name: record.name,
            icon: record.icon_name,
            photo_url: record.photo_url || record.icon_url,

            section: record.section,
            category: record.category,
            order: record.order,

            status: record.is_on,
            deleted: record.deleted_at > 0,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setSectStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.restore')}</Button>
            </div>
        </div>
    )
}

