import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

export const NavigateLink = ({children, link = '', external = false, restricted = false, ...rest}) => {
    const navigate = useNavigate()

    const navToLink = (e) => {
        e.preventDefault()
        if (restricted) {
            notice.warning(l('error.access'))
            return
        }
        navigate(link);
    }

    if (!link) return children
    return (
        <a href={link} onClick={external ? null : navToLink} target={external ? '_blank' : '_self'} {...rest}>
            {children}
        </a>
    );
};


export const NavigateBtn = ({children, link = '', restricted = false, ...rest}) => {
    const navigate = useNavigate()

    const navToLink = (e) => {
        if (rest['target'] === '_blank') return;

        e.preventDefault()
        // console.log('NavigateBtn', link)

        if (restricted) notice.warning(l('error.access'))
        else navigate(link);
    }

    return (
        <Button href={restricted?null:link} onClick={navToLink} {...rest}>
            {children}
        </Button>
    );
};