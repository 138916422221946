import {createSlice} from "@reduxjs/toolkit";

const initialState = {
        item: {
            id: null,
            // messages
            list: [],
            count: 0,
            page: 1,
            pageSize: 100,
            sorter: null,
        },
        // dialogs
        list: [],
        count: 0,
        page: 1,
        pageSize: 100,
        sorter: null,
        filters: {},
    }

const DialogReducer = createSlice({
    name: 'dialog',
    initialState: initialState,
    reducers: {
        fillDialogList(state, action) {
            state.list = action.payload
        },
        fillDialogMessages(state, action) {
            state.item.list = action.payload
        },
        setDialogItem(state, action) {
            if (action.payload.id) {
                state.item.id = action.payload.id;
            }
            state.item.list = action.payload.list;
            state.item.count = action.payload.count;

            if (action.payload.page) state.item.page = action.payload.page;
            if (action.payload.pageSize) state.item.pageSize = action.payload.pageSize;
            if (action.payload.sorter) state.item.sorter = action.payload.sorter;
        },
        setDialogListCount(state, action) {
            state.count = action.payload;
        },
        setDialogStoreParam(state, action) {
            for (const [key, value] of Object.entries(action.payload)) {
                state[key] = value;
            }
        },
        addDialogToList(state, action) {
            state.item = action.payload;
            state.list = [action.payload, ...state.list]
        },
        addDialogMessageToList(state, action) {
            state.item.list = [action.payload, ...state.item.list]
        },
        removeDialogFromList(state, action) {
            const deleted_item = action.payload;
            const object_list = state.list ? [...state.list] : [];
            state.list = []; // clean the list for trigger automatic update
            state.list = object_list.filter(p => p.id !== deleted_item.id)
        },
        patchDialogInList(state, action) {
            const list = [...state.list];
            const oid = action.payload.id;
            const index = list.findIndex(o => o.id === oid);
            if (index !== -1) {
                list[index] = {...list[index], ...action.payload};
                state.list = list;
            }
            state.item = action.payload
        },
        resetDialogState(state) {
            for (const [key, value] of Object.entries(initialState)) {
                state[key] = value;
            }
        },
    }
})

export default DialogReducer.reducer
export const {
    fillDialogList,
    fillDialogMessages,
    setDialogItem,
    resetDialogState,
    setDialogListCount,
    setDialogStoreParam,
    patchDialogInList,
    addDialogToList,
    addDialogMessageToList,
    removeDialogFromList
} = DialogReducer.actions