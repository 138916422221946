import React from 'react';
import {Button, Form} from "antd";
import {l} from "../../library/locale";
import {CheckOutlined} from "@ant-design/icons";

export const FormSubmit = (
    {
        size = 'middle', // large, middle, small
        onSubmit = null,
        onCancel = null,
        submitIcon = null,
        submitLabel = null,
        cancelLabel = null,
        loading = false,
        disabled = false,
        className = null,
    }) => {
    return (
        <Form.Item className={className}>
            <Button
                size={size}
                type="primary"
                htmlType="submit"
                icon={submitIcon ?? <CheckOutlined/>}
                loading={loading}
                disabled={disabled}
                onClick={onSubmit}
            >{submitLabel ?? l('common.action.save')}</Button>

            {onCancel?<Button size={size} type="text" onClick={onCancel}>
                {cancelLabel ?? l('common.action.cancel')}
            </Button>:null}
        </Form.Item>
    );
};

