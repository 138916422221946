import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addOrderToList,
    setOrderFormLoading,
    fillOrderList,
    patchOrderInList,
    removeOrderFromList,
    setOrderItem,
    setOrderListCount,
    setOrderUrl,
    setOrderStoreParam,
} from "../reducers/OrderReducer";
import {patchProjectInList, patchProjectItem} from "../reducers/ProjectReducer";
import {setUserField} from "../reducers/UserFieldReducer";

// init api methods
const base_path = backend_api.order;

function* getOrderList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillOrderList(response.data.result))
            yield put(setOrderListCount(response.data.count))
            yield put(setOrderStoreParam({
                count: response.data.count,
                price: response.data.price,
                amount: response.data.amount
            }))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getOrderItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setOrderItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createOrder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addOrderToList(response.data.result))
            yield put(setOrderItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateOrder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchOrderInList(response.data.result))
            yield put(setOrderItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyOrder(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(addOrderToList(response.data.result))
            yield put(setOrderItem(response.data.result))
        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreOrder(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeOrderFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteOrder(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeOrderFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createAppOrder(arg) {
    try {
        yield put(setOrderFormLoading({balance: true}))
        const response = yield request(arg.admin.token, base_path + '/app', 'post', arg.data);

        if (response.data.ok) {
            yield put(setOrderUrl(response.data.result.payment_url))
        } else apiFalseNotice(response)

        yield put(setOrderFormLoading({
            balance: false,
            tariff: false,
            promo: false,
            project: false,
        }))
    } catch (error) {
        apiErrorNotice(error)
        yield put(setOrderFormLoading({balance: false}))

        return null
    }
}

function* increaseProjectBalance(arg) {
    try {
        yield put(setOrderFormLoading({balance: true}))
        const response = yield request(arg.admin.token, backend_api.project + '/balance', 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchProjectItem(response.data.result.project))
            yield put(patchProjectInList(response.data.result.project))
            yield put(setUserField(response.data.result.balance))

            yield put(setOrderFormLoading({
                balance: false,
                tariff: false,
                ai: false,
            }))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* increaseProjectAiBalance(arg) {
    try {
        yield put(setOrderFormLoading({ai: true}))
        const response = yield request(arg.admin.token, backend_api.project + '/ai_balance', 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchProjectItem(response.data.result.project))
            yield put(patchProjectInList(response.data.result.project))
            yield put(setUserField(response.data.result.balance))

            yield put(setOrderFormLoading({
                balance: false,
                tariff: false,
                ai: false,
            }))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* purchaseAppTariff(arg) {
    try {
        yield put(setOrderFormLoading({messages: true}))
        const response = yield request(arg.admin.token, backend_api.project + '/tariff', 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchProjectItem(response.data.result.project))
            yield put(patchProjectInList(response.data.result.project))
            yield put(setUserField(response.data.result.balance))
            yield put(setUserField(response.data.result.coins))

            yield put(setOrderFormLoading({messages: false}))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* setAppTariff(arg) {
    try {
        yield put(setOrderFormLoading({tariff: true}))
        const response = yield request(arg.admin.token, backend_api.project + '/tariff', 'post', arg.data);

        if (response.data.ok) {
            yield put(patchProjectItem(response.data.result))
            yield put(patchProjectInList(response.data.result))

            yield put(setOrderFormLoading({tariff: false}))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* orderSaga() {
    yield takeEvery('getOrderList', getOrderList);
    yield takeEvery('getOrderItem', getOrderItem);
    yield takeEvery('createOrder', createOrder);
    yield takeEvery('copyOrder', copyOrder);
    yield takeEvery('updateOrder', updateOrder);
    yield takeEvery('restoreOrder', restoreOrder);
    yield takeEvery('deleteOrder', deleteOrder);

    yield takeEvery('createAppOrder', createAppOrder);
    yield takeEvery('setAppTariff', setAppTariff);
    yield takeEvery('purchaseAppTariff', purchaseAppTariff);
    yield takeEvery('increaseProjectBalance', increaseProjectBalance);
    yield takeEvery('increaseProjectAiBalance', increaseProjectAiBalance);
}

