import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import classes from "./BlockList.module.scss";
import {Button} from "antd";
import {
    deepCopyObject,
    objectLength,
} from "../../../../library/functions";
import {
    CopyOutlined,
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
} from "@ant-design/icons";

import {Draggable, Droppable} from "react-beautiful-dnd";
import {defaultBlockStyle} from "../StyleFields";

import {BlockGroups} from "./BlockGroups";
import {styleComposer} from "../StyleComposer";
import {setActive, setBlock} from "../../../../redux/reducers/BlockReducer";


export const BlockContainers = ({rows, sectionId, onChange, getNewId, isOn}) => {
    const dispatch = useDispatch();

    const {pb} = useSelector(store => store)
    const active = pb.active;

    const openRow = (item) => {
        let itemData = {...item, type: 'con'};
        if (!itemData.style || !objectLength(itemData.style)) itemData['style'] = {...defaultBlockStyle};
        if (!itemData.wrap || !objectLength(itemData.wrap)) itemData['wrap'] = {...defaultBlockStyle};

        dispatch(setBlock(itemData));
        dispatch(setActive({sec: sectionId, con: itemData.id}));
    }

    const onCopyRow = (index) => {
        let newList = [...rows];
        let newItem = deepCopyObject(newList[index]);
        newItem.id = getNewId();

        for (let colItem of newItem.items) {
            colItem.id = getNewId();

            for (let blockItem of colItem.items) {
                blockItem.id = getNewId();
            }
        }

        newList.splice(index + 1, 0, newItem);
        onChange(newList);
    }

    const onDeleteRow = (index) => {
        let newList = [...rows];
        newList.splice(index, 1);
        onChange(newList);
    }

    const onRowItemsChange = (colList, rowId) => {
        const rowIndex = rows.findIndex(item => item.id === rowId);
        const newList = [...rows];
        const currentRow = newList[rowIndex];
        newList[rowIndex] = {...currentRow, items: colList};
        onChange(newList);
    };

    const renderRowControls = (item, index, provided) => {
        return <Button.Group className={[classes.controls, classes.conControls].join(' ')}>
            <Button
                className={`system ${classes.conControlButton}`}
                type="default"
                icon={<DragOutlined/>}
                size="small"
                {...provided.dragHandleProps}
            />

            <Button
                className={`system ${classes.conControlButton}`}
                type="default"
                icon={<EditOutlined />}
                onClick={() => openRow(item)}
                size="small"
            />

            <Button
                className={`system ${classes.conControlButton}`}
                type="default"
                icon={<CopyOutlined/>}
                onClick={() => onCopyRow(index)}
                size="small"
            />
            <Button
                className={`system ${classes.conControlButton}`}
                type="default"
                icon={<DeleteOutlined/>}
                onClick={() => onDeleteRow(index)}
                size="small"
            />
        </Button.Group>
    }

    return rows.map((rowItem, rowKey) => (
        <Draggable key={rowItem.id} draggableId={String(rowItem.id)} index={rowKey}>
            {(provided, snapshot) => {

                let {classMap, styleMap} = styleComposer(
                    rowItem,

                    {
                        wrap: ['p-con-wrap', classes.con],
                        bg: ['p-con-bg'],
                        item: ['p-con'],
                    },

                    {
                        horizontal: false,
                        item: {},
                        bg: {},
                        wrap: {},
                    }
                );

                if (active.con === rowItem.id) classMap.wrap.push(classes.current);
                else if (snapshot.isDragging) classMap.wrap.push(...[classes.dragging, classes.current]);

                return <div
                    data-id={rowItem.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={classMap.wrap.join(' ')}
                >

                    <div className={classMap.bg.join(' ')} style={styleMap.bg}>

                        <Droppable
                            droppableId={'r' + rowItem.id}
                            direction={styleMap.horizontal ? 'horizontal' : 'vertical'}
                            type="group"
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    // className={classMap.item.join(' ')}
                                >

                                    <div className={classMap.item.join(' ')} style={styleMap.item}>
                                        <BlockGroups
                                            container={rowItem}
                                            rowKey={rowKey}
                                            getNewId={getNewId}
                                            sectionId={sectionId}
                                            onChange={(newItems) => onRowItemsChange(newItems, rowItem.id)}
                                            isOn={isOn}
                                        />
                                        {/*<div className={classes.placeholder}/>*/}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>

                        {renderRowControls(rowItem, rowKey, provided)}
                    </div>
                </div>
            }}
        </Draggable>
    ))
}