import React, {useContext} from 'react'
import classes from './AddContainerButtons.module.scss';
import {l} from "../../../../../../library/locale";
import {BranchesOutlined, ClockCircleOutlined, MessageOutlined, PlusOutlined} from "@ant-design/icons";
import FlowContext from "../../../../FlowContext";
import {condition_jump_effect, popularEffectsTypes} from "../../../../../../schemas/frontend/effects";
import {Button, Tooltip} from "antd";
import Fa from "../../../../../../components/Icon/Fa";

const AddContainerButtons = ({chosenNode}) => {
    const {setControlPanel, addNodeEffect, isBlock, forbidden} = useContext(FlowContext);
    const effectType = {
        message_send: popularEffectsTypes.message_send,
        message_edit: 'effect/core/chat/message/edit',
        photo_send: 'effect/core/chat/photo/send',
        photo_edit: 'effect/core/chat/photo/edit',
        attachment: 'effect/core/chat/attachment/send',
        delay_run: popularEffectsTypes.delay_run,
        list_add: 'effect/core/crm/list/add',
        list_remove: 'effect/core/crm/list/remove',
        text_set: 'effect/core/var/text/set',
        number_set: 'effect/core/var/number/set',
        field_set: 'effect/core/crm/field/set',
        condition: condition_jump_effect.type,
    }

    const addButtonPlus = (event, nodeType) => {
        switch (nodeType || chosenNode.type) {
            case 'event':
                setControlPanel('events');
                break;
            case 'action':
                setControlPanel('actions');
                break;
            case 'condition':
                addNodeEffect(effectType.condition);
                break;
            case 'message':
                addNodeEffect(effectType.message_send);
                break;
            case 'timer':
                addNodeEffect(effectType.delay_run);
                break;
            default:
                break;
        }
    }

    const getIcon = () => {
        switch (chosenNode.type) {
            case 'condition':
                return <BranchesOutlined/>;
            case 'message':
                return <MessageOutlined/>;
            case 'timer':
                return <ClockCircleOutlined/>
            default:
                return <PlusOutlined/>;
        }
    }

    const getEffectTitleByType = (type) => {
        return l(type.split('/').join('.') + '.title')
    }

    if (!chosenNode.localId) return null;
    return (<>
        {forbidden ? null : <div className={isBlock ? classes.button_row_wrapper_block : classes.button_row_wrapper}>
            <Button
                type="default"
                size="large"
                icon={<PlusOutlined/>}
                onClick={addButtonPlus}
                className={classes.button_row}
            >{l('graph.flow.panel.add.' + chosenNode.type)}</Button>
        </div>}

        {isBlock ? null : <div className={classes.wrapper_button_plus}>
            <div className={classes.wrapper_buttons}>
                {/*<div className={classes.button_plus} onClick={() => { addButtonPlus() }}><Plus className={classes.button_icon_plus}/> </div>*/}
                {/*<Button*/}
                {/*    type="primary"*/}
                {/*    size="medium"*/}
                {/*    icon={<PlusOutlined/>}*/}
                {/*    onClick={addButtonPlus}*/}
                {/*    className={classes.button_plus}*/}
                {/*> {l('common.action.add')} </Button>*/}
                <Tooltip title={l('graph.flow.panel.add.' + chosenNode.type)} placement="topLeft">
                    <Button
                        type="primary"
                        size="large"
                        icon={getIcon()}
                        onClick={addButtonPlus}
                        className={classes.button_plus}
                    />
                </Tooltip>

                {/*<div className={classes.buttons_action_add} style={{display:visible?'':'none'}}>*/}
                {chosenNode.type === 'action' ?
                    <div className={classes.buttons_action_add}>
                        <Tooltip title={getEffectTitleByType(effectType.message_send)}>
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.message_send);
                            }}>
                                <Fa icon="message-check" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.delay_run)}>
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.delay_run);
                            }}>
                                <Fa icon="timer" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.text_set)}>
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.text_set);
                            }}>
                                <Fa icon="rectangle-ad" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.number_set)}>
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.number_set);
                            }}>
                                <Fa icon="circle-1" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.list_add)}>
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.list_add);
                            }}>
                                <Fa icon="user-plus" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.list_remove)} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.list_remove);
                            }}>
                                <Fa icon="user-slash" className={classes.button_icon}/>
                            </div>
                        </Tooltip>
                    </div>
                    : null
                }
                {chosenNode.type === 'message' ?
                    <div className={classes.buttons_action_add}>

                        <Tooltip title={l('graph.flow.panel.add.action')} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addButtonPlus(null, 'action');
                            }}>
                                <Fa icon="ellipsis-h" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.message_edit)} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.message_edit);
                            }}>
                                <Fa icon="pen-to-square" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.photo_send)} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.photo_send);
                            }}>
                                <Fa icon="image" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.photo_edit)} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.photo_edit);
                            }}>
                                <Fa icon="camera-rotate" className={classes.button_icon}/>
                            </div>
                        </Tooltip>

                        <Tooltip title={getEffectTitleByType(effectType.attachment)} placement="topRight">
                            <div className={classes.button} onClick={() => {
                                addNodeEffect(effectType.attachment);
                            }}>
                                <Fa icon="paperclip" className={classes.button_icon}/>
                            </div>
                        </Tooltip>
                    </div>
                    : null
                }
            </div>
        </div>}
    </>)
}

export default AddContainerButtons