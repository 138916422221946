import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'
import {backend_api, dotenv} from "./config";
import sprintf from "i18next-sprintf-postprocessor";
// import LanguageDetector from 'i18next-browser-languagedetector'

const loadLocale = async () => {
    const localeModule = await import(`../schemas/locales/${dotenv.default_lang}/locale`);
    return localeModule.default;
};

let i18n_config = {
    fallbackLng: dotenv.default_lang,
    debug: false,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    },
    initImmediate: true,
}


if (dotenv.language_source_mode === 'backend') {
    const resources = await loadLocale();

    i18n_config['backend'] = {
        loadPath: backend_api.language + '/{{lng}}',
        allowMultiLoading: false,
        crossDomain: true,
        preload: true,
    }

    i18n_config.backend['parse'] = function (data) {
        const response = JSON.parse(data)
        return {...resources, ...response.result}
    };
}

else {
    i18n_config['resources'] =  {
        [dotenv.default_lang]: {
            translation: await loadLocale()
        }
    }
}


i18n
    // connect backend for i18next
    .use(Backend)
    // automatic language detection
    // .use(LanguageDetector)
    // initialize react-i18next
    .use(sprintf)
    .use(initReactI18next)
    .init(i18n_config)

export default i18n

