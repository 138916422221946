import React, {useEffect, useState} from 'react';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/themes/prism.css';

// {onChange, form, id, value, fieldName = 'text', initialValue='', onExitEdit}
const CodeEditor = (props) => {
    // console.log('props', props)
    const [editorValue, setEditorValue] = useState('');

    useEffect(() => {
        let initialValue = props.initialValue ?? '';
        setEditorValue(initialValue);
        if (props.form) updateFormValue(initialValue);
    }, [props.initialValue])

    const updateFormValue = (newValue) => {
        props.form.setFieldValue(props.fieldName, newValue)
    }

    const onEditorChange = (newValue) => {
        if (props.form) updateFormValue(newValue)
        if (props.onChange) props.onChange(newValue);
        setEditorValue(newValue)
    }

    const onBlur = () => {
        if (props.onBlur) props.onBlur();
    }

    const language = props.language ?? 'javascript';

    return <Editor
        // ref={editorRef}
        className="ant-input code-editor"
        value={editorValue}
        placeholder={props.placeholder}
        onBlur={onBlur}
        onValueChange={onEditorChange}
        highlight={value => highlight(value, languages[language], language)}
        padding={11}
        style={{fontFamily: '"Fira code", "Fira Mono", monospace'}}
    />
}

export default CodeEditor