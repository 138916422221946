import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import classes from './NodeTitle.module.scss'
import {Input} from "antd";
import FlowContext from "../../../../FlowContext";
import {fieldsHotkeys} from "../../../../../../components/Form/Field/FormFields";
import {changeGraphStatus} from "../../../../../../redux/reducers/GraphReducer";
import {NodeOperations} from "../DashBoard/NodeOperations";
import {isMobile} from "../../../../../../library/isMobile";

const {TextArea} = Input;
const title_max_length = 2048;

const NodeTitle = ({chosenNode}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {updateNodeTitle, setAutoSave} = useContext(FlowContext);
    const [text, setText] = useState('');

    useEffect(() => {
        setText(chosenNode.text);
    }, [chosenNode.text]);

    const onBlur = () => {
        updateNodeTitle(chosenNode.localId, text);
        if (text !== chosenNode.text) dispatch(changeGraphStatus({edited: true, redrawn: true}));
        // setAutoSave('node.title'); // graph auto saving after title edit
    }

    const onKeyDown = (e) => {
        fieldsHotkeys(e);
    }

    const onChangeText = (e) => {
        setText(e.target.value);
    };

    const autoSizeParams = isMobile ? {minRows: 1, maxRows: 6} : {minRows: 1};

    if (chosenNode) {
        return (<div style={{position: 'relative'}}>
            <div className="flex flex-center">
                <div className={classes.node_title_control_wrapper}>
                    <TextArea
                        className={classes.node_title_control}
                        value={text}
                        placeholder={t('common.form.block_title.placeholder')}
                        maxLength={title_max_length}
                        autoSize={autoSizeParams}
                        onBlur={onBlur}
                        onChange={onChangeText}
                        onKeyDown={onKeyDown}
                    />
                </div>
                <div>
                    <div className="flex flex-center">
                        <NodeOperations nodeLocalId={chosenNode.localId} nodeType={chosenNode.type}/>
                    </div>
                </div>
            </div>
        </div>)
    } else return null;
}

export default NodeTitle