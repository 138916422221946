import React, {useEffect} from 'react'
import {Avatar, Col, Layout, Pagination, Row, Table, Typography} from 'antd';
import classes from './Rating.module.scss'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {deepGet, getSearchFilters, toNum} from "../../library/functions";
import {useDispatch, useSelector} from "react-redux";
import {images} from "../../config/config";

const {Content} = Layout;
const {Title} = Typography;

const UserRating = () => {
    // init hook functions
    const dispatch = useDispatch()
    const params = useParams()
    const project_id = toNum(params.project_id)
    const page_number = toNum(params.page)
    const field_code = params.code
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const searchFilters = getSearchFilters(searchParams);
    const current_user_id = toNum(searchFilters.user_id)

    const {field, user} = useSelector(store => store)
    const item = field.item
    const list = user.list
    const total = user.count
    const page_size = 100
    const banner = deepGet(item, 'params.banner.url', null)

    // get data from API
    useEffect(() => {
        if (project_id && field_code && page_number) {
            let filters_result = {
                project_id: project_id,
                code: field_code,
                page_number: page_number,
                page_size: page_size,
            }
            dispatch({type: 'getUserRating', filters: filters_result})
        }

        //eslint-disable-next-line
    }, [project_id, field_code, page_number])

    const openPage = (page) => {
        let url = `/project/${project_id}/rating/${field_code}/${page}`
        if (current_user_id) url += `?user_id=${current_user_id}`
        navigate(url)
    }

    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            className: classes.number,
            render: (index) => `${index}.`,
            // width: 70,
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: 'avatar',
            className: classes.avatar,
            render: (avatar) => <Avatar src={avatar}/>,
            // width: 50,
        },
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            // className: classes.name,
            render: (text, record) => <div className={record.current ? classes.current : ''}>{text}</div>,
        },
        {
            title: 'User Value',
            dataIndex: 'value',
            key: 'value',
            className: classes.value,
            render: (text) => <div>{text}</div>,
            width: 70,
        },
        // Add more columns as needed
    ];

    const data = list.map((user, index) => ({
        key: user.id,
        index: page_size * (page_number - 1) + index + 1,
        avatar: user.photo_url || images.avatar.user,
        name: user.name,
        value: user.value,
        current: user.id === current_user_id,
    }));


    return (
        <Layout className={`site-layout site-layout-transparent ${classes.layout}`}>
            <Content className="page-container container-with-cards padding-none-vertical">
                <div className={classes.container}>
                    {banner ? <img className={classes.banner} src={banner} alt="Cover" /> : null}
                    <div className={classes.content}>
                        <Title level={2} className={classes.title}>
                            {item.title ?? ''}
                        </Title>
                        <Row gutter={30} align="stretch">
                            <Col xs={24} xl={24} className="margin-top-pm">
                                <Table
                                    showHeader={false}
                                    columns={columns}
                                    dataSource={data}
                                    className={classes.table}
                                    pagination={false}
                                />
                                {total > page_size && (
                                    <Pagination
                                        current={page_number}
                                        total={total}
                                        pageSize={page_size}
                                        onChange={openPage}
                                        className={`margin-top-md align-center ${classes.pagination}`}

                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default UserRating;
