import {dotenv, routes} from "../config/config";
import {deepGet, getUtcDateTime} from "./functions";
import {notice} from "./notice";

export const isTariffDeveloper = (project) => {
    return deepGet(project, 'params.tariff.type', 'business') === 'developer';
}

export const checkAccessOrRedirect = (project, section, navigate, t) => {
    if (dotenv.is_app_free) return true

    if (!project || !project.id) navigate(dotenv.main_page);

    let is_section_allowed = false
    const now = getUtcDateTime()

    if (project.allowed_sections) {
        for (const allowedSection of project.allowed_sections) {
            if (allowedSection && allowedSection.section === section) {
                if (allowedSection.expire_at > now) is_section_allowed = true;
                break
            }
        }
    }

    if (!is_section_allowed) {
        notice.warning(t('error.section_access'))
        navigate(`${routes.project_list}/${project.id}/tariff`);
        return false
    }

    return true
}