import React from 'react'
import './Page404.scss'

const Page404 = () => {
    return (
        <div className="page-404">
            <div className="text">404</div>
            <div className="container">
                <div className="caveman">
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"/>
                        </div>
                    </div>
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"/>
                        </div>
                    </div>
                    <div className="shape">
                        <div className="circle"/>
                        <div className="circle"/>
                    </div>
                    <div className="head">
                        <div className="eye">
                            <div className="nose"/>
                        </div>
                        <div className="mouth"/>
                    </div>
                    <div className="arm-right">
                        <div className="club"/>
                    </div>
                </div>
                <div className="caveman">
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"/>
                        </div>
                    </div>
                    <div className="leg">
                        <div className="foot">
                            <div className="fingers"/>
                        </div>
                    </div>
                    <div className="shape">
                        <div className="circle"/>
                        <div className="circle"/>
                    </div>
                    <div className="head">
                        <div className="eye">
                            <div className="nose"/>
                        </div>
                        <div className="mouth"/>
                    </div>
                    <div className="arm-right">
                        <div className="club"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page404