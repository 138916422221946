import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addTemplateToList,
    fillTemplateComponentsList,
    fillTemplateList,
    fillTemplateStoreList,
    patchTemplateInList,
    removeTemplateFromList,
    setTemplateItem,
    setTemplateListCount,
    setTemplateStatus,
} from "../reducers/TemplateReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";
import {setCorrect} from "../reducers/AdminReducer";

// init api methods
const base_path = backend_api.template;
const components_path = backend_api.template_component;

function* getTemplateList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillTemplateList(response.data.result))
            yield put(setTemplateListCount(response.data.count))
            yield put(setTemplateStatus('ready'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getTemplateStoreList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillTemplateStoreList(response.data.result))
            yield put(setTemplateListCount(response.data.count))
            yield put(setTemplateStatus('ready'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getTemplateItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (response.data.ok) {
            yield put(setTemplateItem(response.data.result))
            yield put(setTemplateStatus('ready'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getTemplateOpenItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/item/' + arg.alias, 'get');
        if (response.data.ok) yield put(setTemplateItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect(false))
        else apiErrorNotice(error)
        return null
    }
}

function* installTemplate(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/install', 'post', arg.data);

        if (response.data.ok) {
            yield put(addTemplateToList(response.data.result))
            yield put(setTemplateItem(response.data.result))
            yield put(setTemplateStatus('installed'))
        } else {
            yield put(setTemplateStatus('default'))
            apiFalseNotice(response)
        }
    } catch (error) {
        yield put(setTemplateStatus('default'))
        apiErrorNotice(error)
        return null
    }
}

function* renewTemplate(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/renew', 'post', arg.data);
        yield put(setTemplateStatus('default'))

        if (response.data.ok) {
            yield put(patchTemplateInList(response.data.result))
            yield put(setTemplateItem(response.data.result))
        } else {
            apiFalseNotice(response)
        }
    } catch (error) {
        yield put(setTemplateStatus('default'))
        apiErrorNotice(error)
        return null
    }
}

function* createTemplate(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addTemplateToList(response.data.result))
            yield put(setTemplateItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateTemplate(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchTemplateInList(response.data.result))
            yield put(setTemplateItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyTemplate(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '_copy'
        newItem.project_id = arg.project_id

        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setTemplateItem(response.data.result))
            yield put(addTemplateToList(response.data.result))
        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreTemplate(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeTemplateFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteTemplate(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeTemplateFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

// ===== components =====

function* getTemplateComponents(arg) {
    try {
        const response = yield request(arg.admin.token, components_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillTemplateComponentsList(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* setTemplateComponents(arg) {
    try {
        const response = yield request(arg.admin.token, components_path + '/batch', 'post', arg.data);

        if (response.data.ok) {
            yield put(fillTemplateComponentsList(response.data.result))
            notice.success(l('template.result.components'))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* templateSaga() {
    yield takeEvery('getTemplateList', getTemplateList);
    yield takeEvery('getTemplateStoreList', getTemplateStoreList);
    yield takeEvery('getTemplateItem', getTemplateItem);
    yield takeEvery('getTemplateOpenItem', getTemplateOpenItem);
    yield takeEvery('createTemplate', createTemplate);
    yield takeEvery('copyTemplate', copyTemplate);
    yield takeEvery('updateTemplate', updateTemplate);
    yield takeEvery('restoreTemplate', restoreTemplate);
    yield takeEvery('deleteTemplate', deleteTemplate);
    yield takeEvery('installTemplate', installTemplate);
    yield takeEvery('renewTemplate', renewTemplate);
    yield takeEvery('getTemplateComponents', getTemplateComponents);
    yield takeEvery('setTemplateComponents', setTemplateComponents);
}

