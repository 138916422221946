import React, {useEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createObjectFromObjectsArray,
    deepGet,
    objectLength,
    toNum,
    ucFirst,
} from "../../../library/functions";
import {routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate} from "../../../library/containers";
import {SetItemEditFormOrPreloader} from "../../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";
import {notice} from "../../../library/notice";

const TagEdit = () => {
    const {t} = useTranslation()
    const section = 'tag'
    const Section = ucFirst(section)
    const adminSection = section
    const categorySection = 'site'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder
    let site_id = searchParams.get('site_id')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, tag, site, folder} = useSelector(store => store)
    const project_item = project.item;
    const item = tag.item;
    const list = tag.list;
    const categoryList = site.list || []
    const categories = createObjectFromObjectsArray(categoryList);
    const parents = createObjectFromObjectsArray(list);

    const [activePhotoUrl, setActivePhotoUrl] = useState('')
    const [defaultPhotoUrl, setDefaultPhotoUrl] = useState('')

    // itis form state
    const [folderId, setFolderId] = useState(0)
    const [formValues, setFormValues] = useState({
        title: '',
        site_id: site_id || null,
        parent_id: null,
        is_on: true,
        params: {
            folder_id: folder_id,
        }
    })

    const addText = (value) => {
        let oldValue = formValues.description;
        if (oldValue) oldValue += '\n\n';
        setFormValues({...formValues, description: oldValue + value})
        form.setFieldValue('description', oldValue + value)
    }

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            placeholder: t('common.placeholder.text'),
            name: "title",
            type: "text",
            required: true,
        },
        {
            name: "site_id",
            type: "menu",
            data: 'site',
            values: categories,
            // required: true,
        },
        {
            name: "parent_id",
            type: "menu",
            data: 'tag',
            values: parents,
        },
        {
            name: "is_on",
            type: "switcher",
            label: t('common.form.is_on.label'),
        },
        {
            name: "params.photo_url.default",
            type: "avatar",
            form: form,
            imageType: 'field',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: defaultPhotoUrl,
            placeholder: t(section + '.form.params.photo_url.default.label'),
            // placeholder: t('common.action.upload'),
            aspect: 0,
        },
        {
            name: "params.photo_url.active",
            type: "avatar",
            form: form,
            imageType: 'field',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: activePhotoUrl,
            placeholder: t(section + '.form.params.photo_url.active.label'),
            aspect: 0,
        },
        {
            name: "description",
            type: "editor",
            placeholder: true,
            form: form,
            extended: true,
            formValues: formValues,
            // imgFormat: 'webp',
        },
        {
            name: "generate_text",
            type: "ai",
            section: section,
            admin: admin,
            form: form,
            project_id: project_item.id,
            formValues: formValues,
            prompt: 'Always act as TinyMCE editor assistant. Answer ONLY in HTML markup format! Wrap paragraphs with `<p>` tag.',
            onFinish: addText,
        },
        {
            name: "params.folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
    ]

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        let filters = {project_id: project_item.id}

        if (admin.authorized && project_item.id && !categoryList.length) {
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}});
        }

        if (site_id) filters.site_id = site_id

        if (admin.authorized && project_item.id && !list.length) {
            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters});
        }

        //eslint-disable-next-line
    }, [admin.authorized, objectLength(item), project_item.id])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}

            values.parent_id = values.parent_id ? String(values.parent_id) : null;
            values.site_id = values.site_id ? String(values.site_id) : null;

            if (values.params === null) values.params = {}

            setDefaultPhotoUrl(deepGet(values, 'params.photo_url.default')) // for upload field
            setActivePhotoUrl(deepGet(values, 'params.photo_url.active')) // for upload field

            form.setFieldsValue(values);
            if (values.params.folder_id !== undefined) setFolderId(values.params.folder_id || 0)
        }
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        let url = `${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`
        if (site_id) url += `&site_id=${site_id}`
        navigate(url);
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        if (!values.title) {
            notice.error(t('error.validation.fill_required'));

            for (const key of ['title']) {
                if (!values[key]) form.setFields([{name: key, errors: [t('error.validation.required')]}]);
            }

            return;
        }

        values.site_id = toNum(values.site_id);
        values.parent_id = toNum(values.parent_id);

        formCreateOrUpdate(Section, values, formFields, id, project_item, admin, dispatch, true)
        backToList(parseInt(values.params.folder_id))
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default TagEdit