import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addInvoiceToList,
    fillInvoiceList,
    patchInvoiceInList,
    removeInvoiceFromList,
    setInvoiceItem,
    setInvoiceListCount,
} from "../reducers/InvoiceReducer";

// init api methods
const base_path = backend_api.invoice;

function* getInvoiceList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillInvoiceList(response.data.result))
            yield put(setInvoiceListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getInvoiceItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setInvoiceItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createInvoice(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addInvoiceToList(response.data.result))
            yield put(setInvoiceItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateInvoice(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchInvoiceInList(response.data.result))
            yield put(setInvoiceItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copyInvoice(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id
        newItem.title += ' - Copy'
        const response = yield request(arg.admin.token, base_path, 'post', newItem);

        if (response.data.ok) {
            yield put(setInvoiceItem(response.data.result))
            yield put(addInvoiceToList(response.data.result))
        }
        else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreInvoice(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeInvoiceFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteInvoice(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeInvoiceFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* invoiceSaga() {
    yield takeEvery('getInvoiceList', getInvoiceList);
    yield takeEvery('getInvoiceItem', getInvoiceItem);
    yield takeEvery('createInvoice', createInvoice);
    yield takeEvery('copyInvoice', copyInvoice);
    yield takeEvery('updateInvoice', updateInvoice);
    yield takeEvery('restoreInvoice', restoreInvoice);
    yield takeEvery('deleteInvoice', deleteInvoice);
}

