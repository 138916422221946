import React, {useState} from 'react'
import classes from '../../../../Styles/SmallButtons.module.scss';
import isNumeric from 'isnumeric'
import {getItemStorage, inArray, setItemStorage} from "../../../../../../../../../library/functions";
import List from "./List/List";
import {events} from "../../../../../../../../../schemas/frontend/events";
import {useTranslation} from 'react-i18next';
import {dotenv} from "../../../../../../../../../config/config";
import {useParams} from "react-router-dom";

const sectionType = 'event';

const Category = ({section, catList}) => {
    const {t} = useTranslation()
    const activeMode = getItemStorage(`${section}_${sectionType}`);
    const [activeButton, setActiveButton] = useState(activeMode ?? 0);

    // path params
    const params = useParams()
    const project_id = Number(params.project_id);

    const blockButtons = [];
    const categoryList = ['all', ...Object.keys(catList)];

    categoryList.forEach((cat, index) => {
        let containers;

        if (cat === 'all') containers = events.filter(container => section === container.type.split('/')[1]);
        // TODO: add auto setting cat from type when container.category is undefined (for space economy)
        else containers = events.filter(container => section === container.type.split('/')[1] && inArray(cat, container.category));

        // system section
        if (cat === 'app' && project_id !== dotenv.main_project) return;

        if (containers.length) {
            blockButtons.push({
                title: t(`${sectionType}.${section}.${cat}.title`),
                active: !index ? 'true' : 'false',
                module: <List containers={containers}/>
            })
        }
    })

    if (!blockButtons.length) return null;               // для какой-то из секций нет ни одного события

    const changeActiveButton = (index_button) => {
        setItemStorage(`${section}_${sectionType}`, index_button);
        setActiveButton(index_button);
    }

    const onClickButton = (e) => {
        const index_button = Number(e.target.getAttribute('data-button'));
        if (isNumeric(index_button)) {
            changeActiveButton(index_button);
        }
    }

    const buttons = () => {
        blockButtons.forEach(bt => bt.active = 'false');
        if (Number(activeButton) > blockButtons.length - 1) {
            changeActiveButton(0);
            blockButtons[0].active = 'true';
        } else {
            blockButtons[Number(activeButton)].active = 'true';
        }
        return blockButtons.map((bt, index) => {
            return <div
                className={classes.small_button}
                data-button={index}
                data-active={bt.active}
                onClick={() => {changeActiveButton(index)}}
                onTouchEnd={() => {changeActiveButton(index)}}
                // onTouchEnd={onClickButton}
                key={bt.title}
            >{bt.title}</div>
        })
    }

    return (
        <div className={classes.block_buttons}>
            {buttons()}
            {blockButtons[activeButton].module}
        </div>
    )
}

export default Category