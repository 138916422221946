import React, {useEffect} from 'react'
import {Form, Input, Layout} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {l, label} from "../../library/locale";
import {routes} from "../../config/config";
import {getItemValues} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormTitle} from "../../components/Form/FormTitle";
import {copyValueToClipboard} from "../../library/clipboard";

const {Content} = Layout;

const PasswordForm = () => {
    const section = 'admin'
    const backToPath = `${routes.project_list}`

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    // sync with store
    const {admin} = useSelector(store => store)
    const item = admin.item
    const user = admin.user

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        'username',
        'password',
        'email',
    ]
    let formValues = getItemValues(item, formFields)

    const contacts = user.contacts || []
    if (contacts.length) {
        const emails = contacts.filter(item => item.type === 'email')
        if (emails.length) formValues['email'] = emails[0].value
    }

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized) {
            dispatch({type: 'getAdminItem', admin}); // get data once
        }

        // set field values if correct data received
        if (user.id) {
            form.setFieldsValue(formValues)
        }

        //eslint-disable-next-line
    }, [admin.authorized, user.id])


    const backToList = () => {
        navigate(backToPath);
    }
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        let data = getItemValues(values, formFields)
        delete data['email']

        // console.log('data', data)
        dispatch({type: 'updateAdmin', admin, data});

        data.password = ''
        form.setFieldsValue(data)
        // backToList()
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    className="" // old-school-container
                    name="settings"
                    layout="vertical"
                >
                    <FormTitle onBack={backToList}>
                        {l('admin.header.security')}
                    </FormTitle>

                    <Content className="page-container form-container site-layout-background">
                        <FormItemWrapper desc={label(section, 'email', 'desc')}>
                            <Form.Item
                                label={label(section, 'email', 'label')}
                                name='email'
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    readOnly={true}
                                    onClick={copyValueToClipboard}
                                />
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={label(section, 'username', 'desc')}>
                            <Form.Item
                                label={label(section, 'username', 'label')}
                                name='username'
                                rules={[
                                    {
                                        required: true,
                                        message: l("error.validation.required")
                                    }
                                ]}
                            >
                                <Input placeholder={t(section + '.form.username.label')}/>
                            </Form.Item>
                        </FormItemWrapper>


                        <FormItemWrapper desc={label(section, 'password', 'desc')}>
                            <Form.Item
                                name="password"
                                label={label(section, 'password', 'label')}
                            >
                                <Input.Password placeholder={t(section + '.form.password.label')} />
                            </Form.Item>
                        </FormItemWrapper>

                        <FormSubmit/>
                    </Content>
                </Form>
            </Layout>
        </AppWrapper>
    )
}

export default PasswordForm